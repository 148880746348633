import React, { ReactNode, useEffect, useState } from "react";

// third party
import { useSelector } from "react-redux";

// custom component
import { LogoutComponent } from "./Logout";

// redux
import { RootState } from "../../../redux/store";

interface PrivateRouteProps {
  children: ReactNode;
  role?: "admin" | "venue_manager" | "retreat_host" | "retreat_guest"; // Role for the route
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, role }) => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );
  const [authenticated, setAuthenticated] = useState(false);

  const userRole = userProfileData?.user_type;
  const profilePending = userProfileData?.profileApiPending;

  useEffect(() => {
    const isAuthorized = () => {
      // If no user data or profile is pending, don't allow access
      if (!userProfileData || userProfileData.profileApiPending) {
        return false;
      }

      // If the route requires a specific role, check if the user's role matches
      if (role && userRole !== role) return false;
      return true; // If roles match (or no role is required), access is granted
    };

    setAuthenticated(isAuthorized());
  }, [userProfileData, role, userRole, profilePending]);

  // If authenticated, render children; otherwise, redirect to login/logout page
  return authenticated ? (
    <>{children}</>
  ) : (
    <LogoutComponent pageRedirect="sessionExpired" /> // Redirect to logout or any other route as needed
  );
};

export default PrivateRoute;
