import React, { useState } from "react";

//third party
import { LogOut } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

//common component
import SmallText from "../../../UI/SmallText";

// redux
import {
  setPaymentData,
  setCancelReason,
} from "../../../../redux/features/cancelVenueReservation";
import { RootState } from "../../../../redux/store";

// network
import { cancelReservation } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

//types
import { FormFooterProps } from "../../../../types/retreat";
import { useNavigate } from "react-router-dom";

const SendMessage: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reservation = useSelector((state: RootState) => state.reservation.data);
  const cancelReasonSelector = useSelector(
    (state: RootState) => state.cancelVenue.cancelReason
  );
  const cancelRoutSelector = useSelector(
    (state: RootState) => state.cancelVenue.lastRoute
  );
  const { call } = useApiCall();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleReservationCancel = () => {
    setLoading(true);
    const dataToSend = {
      reason: cancelReasonSelector.reason,
      message_to_retreat_host: message,
    };
    call(
      () =>
        cancelReservation({
          reservationId: reservation.id as string,
          data: dataToSend,
        }),
      (res) => {
        const {
          data: { data },
        } = res;
        dispatch(setPaymentData(data));
        handleNextStep();
        setLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to cancel");
        setLoading(false);
      }
    );
  };

  return (
    <div className="d-flex flex-column bg-white p-5 step-box-shadow steps-gap">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-3">
          <SmallText
            text="Tell retreat host why you need to cancel."
            color="#19191C"
            fontWeight={700}
            fontSize={32}
            lineHeight={37.6}
          />
          <SmallText
            text="Send a message to the retreat host to let them know why you need to cancel."
            color="#58585B"
            fontWeight={400}
            fontSize={16}
            lineHeight={23}
          />
        </div>

        <div className="d-flex flex-column gap-2">
          <textarea
            rows={8}
            className="form-control"
            placeholder="Write your message"
            onChange={(e) => {
              const value = e.target.value;
              if (value.trim()) {
                setMessage(value);
                dispatch(setCancelReason({ field: "message", value }));
              } else {
                setMessage("");
                dispatch(setCancelReason({ field: "message", value: "" }));
              }
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="exit-btn"
          onClick={() => navigate(cancelRoutSelector)}
        >
          <div className="d-flex align-items-center justify-content-center gap-1">
            <LogOut
              color="#2771A3"
              width={16}
              height={16}
              className="reverse-icon"
            />
            <SmallText
              text="Exit"
              color="#2771A3"
              fontWeight={500}
              fontSize={14}
              lineHeight={16.45}
            />
          </div>
        </button>
        <div className="d-flex gap-4">
          <button
            className="back-btn"
            onClick={handlePreviousStep}
            style={{ width: "102px" }}
          >
            Back
          </button>

          <button
            className="submit-btn"
            style={{ width: "120px" }}
            onClick={handleReservationCancel}
            disabled={loading || !message}
          >
            continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
