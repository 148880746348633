// third party
import { jwtDecode } from "jwt-decode";
import dayjs, { Dayjs } from "dayjs";
import { useLocation } from "react-router-dom";
import {
  endAt,
  get,
  orderByChild,
  query,
  ref,
  startAt,
} from "firebase/database";

// network
import { db } from "../network/firebaseConfig";

// types
import {
  FileType,
  MessageUserReservationDetails,
  UserDetailsAddOnService,
} from "../types";
import { JwtPayload } from "../types/user";
import { VideoMetadata } from "../types/property-manager";

// constants
import { LANGUAGE_CODE } from "../constants/Admin";

export const blankFormFieldsMessage = (value: string) => {
  return `${value} is required !!!`;
};

export const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const handleLocalStorage = (
  key: any,
  value: any,
  storageName: string,
  setLocalStorageData: (data: any) => void
) => {
  const localData = localStorage.getItem(storageName);
  if (localData) {
    const data = JSON.parse(localData);
    data[key] = value;
    localStorage.setItem(storageName, JSON.stringify(data));
    setLocalStorageData(data);
  }
};

export const getDatesForCurrentMonth = (currentMonth: Dayjs): Date[] => {
  const dates: Date[] = [];
  const firstDay = currentMonth.startOf("month").toDate();
  const lastDay = currentMonth.endOf("month").toDate();

  for (
    let day = new Date(firstDay);
    day <= lastDay;
    day.setDate(day.getDate() + 1)
  ) {
    dates.push(new Date(day));
  }

  return dates;
};

export const changeUTC = (time: Dayjs, period: "AM" | "PM") => {
  let adjustedTime = time;

  if (period === "PM" && time?.hour() < 12) {
    adjustedTime = time.add(12, "hour");
  } else if (period === "AM" && time?.hour() === 12) {
    adjustedTime = time.subtract(12, "hour"); // Adjust for 12 AM (midnight case)
  }

  return adjustedTime?.format("HH:mm:ss.SSS[Z]");
};

export const convert24To12 = (
  time: string,
  setPeriod: React.Dispatch<React.SetStateAction<"AM" | "PM">>
) => {
  let time24 = dayjs(time ? time : "00:00:00", "HH:mm:ss");

  if (time24.hour() >= 12) {
    setPeriod("PM");
    if (time24.hour() > 12) {
      time24 = time24.subtract(12, "hour");
    }
  } else {
    setPeriod("AM");
    if (time24.hour() === 0) {
      time24 = time24.add(12, "hour"); // Handle midnight (12 AM)
    }
  }

  // return time and period
  return time24;
};

export const formatTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export const convertDateFormat = (inputDate: string): string | undefined => {
  if (inputDate) {
    const date = new Date(inputDate);
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString(LANGUAGE_CODE.EN_US, options);
  }
};

export const convertDateFormatRange = (start: string, end: string): string => {
  try {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = startDate.toLocaleString("default", { month: "short" });
    const endMonth = endDate.toLocaleString("default", { month: "short" });
    const year = startDate.getFullYear();

    // Check for month and year differences
    if (
      startMonth === endMonth &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      return `${startDay}-${endDay} ${startMonth}, ${year}`;
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
      return `${startDay} ${startMonth} - ${endDay} ${endMonth}, ${year}`;
    } else {
      return `${startDay} ${startMonth}, ${startDate.getFullYear()} - ${endDay} ${endMonth}, ${endDate.getFullYear()}`;
    }
  } catch (error) {
    return "";
  }
};

// Formats the numbers with commas
export const formatNumberWithCommas = (num: string): string => {
  return parseInt(num).toLocaleString(LANGUAGE_CODE.EN_US);
};

export const handleEnterKey = (e: React.KeyboardEvent) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Prevent form submission on Enter
  }
};

// Get Queryparams from URL
export const GetQueryParamsFromURL = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

// Convert string to title case
export const convertToTitleCase = (input: string): string => {
  if (!input) return "";
  return input
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const allowKeys = (
  e:
    | React.KeyboardEvent<HTMLTextAreaElement>
    | React.KeyboardEvent<HTMLInputElement>
) => {
  if (
    ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Home", "End"].includes(
      e.key
    )
  ) {
    e.stopPropagation();
  }
};

export const calculateTotalAddOnsPrice = (
  reservationDetails: MessageUserReservationDetails,
  check_in_date: Date,
  check_out_date: Date
): number => {
  if (!reservationDetails?.add_on_services) return 0;

  const daysDiff = Math.max(
    0,
    check_out_date.getDate() - check_in_date.getDate()
  );

  return Object.values(reservationDetails.add_on_services).reduce(
    (total: number, service: UserDetailsAddOnService) => {
      switch (service.service_type) {
        case "Per day":
          return total + service.charges * daysDiff;
        case "Per guest":
          return total + service.charges * (service.tickets || 0);
        case "Per group":
          return (
            total + service.charges * (reservationDetails?.group_size || 1)
          );
        default:
          return total;
      }
    },
    0
  );
};

export const getMessagePromises = async (
  filteredUser: any[],
  currentUserUid: string,
  startTimestamp: number,
  endTimestamp: number
) => {
  const messagePromises = filteredUser.map(async (user) => {
    const chatId = [currentUserUid, user?.uid].sort().join("_");
    const messagesRef = ref(db, `Chats/${chatId}/messages`);
    const messagesQuery = query(
      messagesRef,
      orderByChild("timestamp"),
      startAt(startTimestamp),
      endAt(endTimestamp)
    );

    const snapshot = await get(messagesQuery);
    return { ...user, hasMessages: snapshot.exists() };
  });

  return Promise.all(messagePromises);
};

export const decodeAndCheckToken = (
  token: string
): { isAuthenticated: boolean; userData: any } => {
  const defaultResponse = { isAuthenticated: false, userData: {} };
  try {
    // Decode the JWT token with a proper type
    const decodedData = jwtDecode<JwtPayload>(token);

    if (!decodedData || !decodedData.exp) {
      return defaultResponse;
    }

    // Get the current time in seconds (Unix timestamp)
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token has expired
    if (decodedData.exp > currentTime) {
      return {
        ...defaultResponse,
        isAuthenticated: true,
        userData: decodedData,
      };
    } else {
      return defaultResponse;
    }
  } catch (error) {
    return defaultResponse;
  }
};

export const fetchVideoMetadata = async (
  url: string
): Promise<VideoMetadata | null> => {
  try {
    const domain = new URL(url).hostname;
    let videoId: string | null = null;
    let metadata: VideoMetadata | null = null;

    if (domain.includes("youtube.com") || domain.includes("youtu.be")) {
      videoId = getYouTubeVideoId(url);
      if (videoId) {
        const oembedUrl = `https://www.youtube.com/oembed?url=${encodeURIComponent(
          url
        )}&format=json`;
        const response = await fetch(oembedUrl);
        const data = await response.json();
        metadata = {
          title: data.title,
          channelName: data.author_name,
          domain,
          thumbnailUrl: `https://img.youtube.com/vi/${videoId}/0.jpg`,
        };
      }
    } else if (domain.includes("vimeo.com")) {
      videoId = getVimeoId(url);
      if (videoId) {
        const oembedUrl = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
          url
        )}`;
        const response = await fetch(oembedUrl);
        const data = await response.json();
        metadata = {
          title: data.title,
          channelName: data.author_name,
          domain,
          thumbnailUrl: data.thumbnail_url,
        };
      }
    }

    return metadata;
  } catch (error) {
    throw new Error("Failed to fetch video metadata");
  }
};

export const getYouTubeVideoId = (url: string): string | null => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/)|youtu\.be\/)(?<id>[^&?/]+)/
  );
  return match ? match.groups?.id || null : null;
};

export const getVimeoId = (url: string): string | null => {
  // disable
  const match = url.match(
    new RegExp(
      "(?:https?:\\/\\/)?(?:www\\.)?vimeo\\.com\\/(?:.*#|.*/videos/)?(\\d+)"
    )
  );
  return match ? match[1] : null;
};

export const numberSeparator = (
  number: number,
  fixed: boolean = false
): string => {
  return fixed
    ? number.toLocaleString("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : Intl.NumberFormat("en-GB").format(number);
};

export const calculateAddOnPrice = (
  service: UserDetailsAddOnService,
  checkInDate: Date,
  checkOutDate: Date,
  groupSize?: number
): number | "Free" => {
  const daysDiff = Math.max(0, checkOutDate.getDate() - checkInDate.getDate());

  switch (service.service_type) {
    case "Per day":
      return service.charges * daysDiff;
    case "Per guest":
      return service.charges * (service.tickets || 1);
    case "Per group":
      return service.charges * groupSize!;
    default:
      return "Free";
  }
};

export const renderAddOnPrice = (
  key: string,
  reservationDetails: MessageUserReservationDetails,
  checkInDate: Date,
  checkOutDate: Date
): string => {
  const service = reservationDetails.add_on_services![key];
  if (!service) return "N/A"; // Handle case where service doesn't exist

  const price = calculateAddOnPrice(
    service,
    checkInDate,
    checkOutDate,
    reservationDetails.group_size
  );

  return typeof price === "number" ? `$${price.toFixed(2)}` : price;
};

export function getAddOnServiceNames(
  addOnServices: Record<string, { name: string }> | undefined
): string {
  if (!addOnServices) return "";

  return Object.keys(addOnServices)
    .map((key) => addOnServices[key].name)
    .join(", ");
}
