import React from "react";

// third party
import { useSelector } from "react-redux";

// custom component
import AboutRetreatSection from "./AboutRetreatSection";

// redux
import { RootState } from "../../../redux/store";

// helpers
import { renderAddOnPrice } from "../../../library";

// images
import { images } from "../../../assets/images";

const UserVenueDetails: React.FC<{
  reservationDetails?: any;
}> = ({ reservationDetails }) => {
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const check_in_date = new Date(reservationDetails?.check_in_date);
  const check_out_date = new Date(reservationDetails?.check_out_date);

  return (
    <div className="upcoming-reservation-detail-modal">
      <div className="title d-flex gap-2 align-items-center">
        <img src={images.NO_VENUES_IMG} alt="" height={"40px"} width={"40px"} />
        <div>
          <p className="common-md-bold-dark-text">
            {reservationDetails?.venue?.name}
          </p>
          <span className="common-xs-light-text">
            {reservationDetails?.venue?.city}
            {reservationDetails?.venue?.country &&
              ", " + reservationDetails?.venue?.country}
          </span>
        </div>
      </div>
      <div className="upcoming-reservation-detail">
        <div className="about-retreat-container">
          <AboutRetreatSection
            title="Dates"
            icon={images.CALENDAR_DOT}
            isDateRange
            checkInDate={reservationDetails?.check_in_date}
            checkOutDate={reservationDetails?.check_out_date}
          />
          <AboutRetreatSection
            title="Group Size"
            icon={images.USERS_THREE}
            data={reservationDetails?.group_size}
          />

          <AboutRetreatSection
            title="Reservation Number"
            icon={images.RESERVATION_TAG}
            data={reservationDetails?.reservation_id}
          />
          <AboutRetreatSection
            title="Special requests"
            icon={images.CALENDAR_CHECK}
            data={reservationDetails?.special_request}
          />
          <AboutRetreatSection
            title="Status"
            icon={images.CALENDAR_CHECK}
            isStatusTag
            statusLabel="Approved"
            statusBackgroundColor="#CEF5DC"
            statusColor="#398E58"
          />
        </div>
        <div className="add-on-service-container">
          <div className="common-sm-bold-dark-text">Included Add-ons</div>
          <table className="booked-venue-detail-table">
            <thead>
              <tr>
                <th className="service-name">Services</th>
                <th className="price">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {reservationDetails?.add_on_services &&
                Object.keys(reservationDetails.add_on_services).map((key) => {
                  const icon = icons?.find(
                    (icon) =>
                      icon.id ===
                      reservationDetails.add_on_services[key].icon_id
                  );
                  return (
                    <tr key={`service-${key}`}>
                      <td>
                        <div className="d-flex gap-2 align-items-center common-sm-dark-text">
                          <img src={icon?.icon_url} alt="" />
                          {reservationDetails.add_on_services[key].name}
                        </div>
                      </td>
                      <td>
                        <span className="common-sm-dark-text">
                          {renderAddOnPrice(
                            key,
                            reservationDetails,
                            check_in_date,
                            check_out_date
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <hr />
        {/* TODO: Add payment details 
        <div className="payment-details about-retreat-container">
          <div className="common-sm-bold-dark-text">Payment Details</div>
          <div className="d-flex flex-column gap-2 ">
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                Accomodation
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $ 12,260
              </div>
            </div>
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                Add-on-Services
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $ {totalAddOnsPrice}
              </div>
            </div>
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                Taxes
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $ 120
              </div>
            </div>
            <hr />
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title payment-details-total">
                Total Payout
              </div>
              <div className="about-retreat-data payment-details-total ">
                $ {12260 + totalAddOnsPrice + 120}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default UserVenueDetails;
