import React from "react";

// third party
import { ConfigProvider, Tabs } from "antd";
import type { TabsProps } from "antd";

// custom components
import CardComponent from "../../../UI/Card";
import CarouselComponent from "../../../UI/CarouselComponent";
import NoDataFound from "../../../UI/NoDataFound";

// types
import { FetchedSpacesType } from "../../../../types/property-manager";

//images
import { images } from "../../../../assets/images";

const VenueSpaces: React.FC<{
  spacesData?: FetchedSpacesType;
}> = ({ spacesData }) => {
  // No Data Found Component with text props
  const noDataFound = (text: string) => {
    return (
      <>
        <div className="d-flex flex-column bg-white venue-selection">
          <NoDataFound boxStyle={{ height: 120 }} iconSize={32} text={text} />
        </div>
      </>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `All (${
        (spacesData?.common_spaces?.length || 0) +
        (spacesData?.rooms?.length || 0)
      })`,
      children: (
        <>
          {spacesData?.common_spaces &&
          spacesData.common_spaces.length === 0 &&
          spacesData?.rooms &&
          spacesData.rooms.length === 0 ? (
            noDataFound("No Spaces")
          ) : (
            <CarouselComponent
              buttonClass="spaces-carousel-button"
              carouselClass="spaces-carousel"
            >
              {spacesData?.rooms.map((item, index) => (
                <CardComponent
                  key={`room-${index}`}
                  image={
                    item.image_urls.length > 0
                      ? item.image_urls[0].image_url
                      : images.NO_IMAGE
                  }
                  title={item.name}
                  description={item.description || ""}
                />
              ))}
              {spacesData?.common_spaces.map((item, index) => (
                <CardComponent
                  key={`space-${index}`}
                  image={
                    item.image_urls.length > 0
                      ? item.image_urls[0].image_url
                      : images.NO_IMAGE
                  }
                  title={item.name}
                  description={item.description || ""}
                />
              ))}
            </CarouselComponent>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: `Bedroom (${spacesData?.rooms.length})`,
      children: (
        <>
          {spacesData?.rooms && spacesData.rooms.length === 0 ? (
            noDataFound("No Rooms")
          ) : (
            <CarouselComponent
              buttonClass="spaces-carousel-button"
              carouselClass="spaces-carousel"
            >
              {spacesData?.rooms.map((item, index) => (
                <CardComponent
                  key={`room-${index}`}
                  image={
                    item.image_urls.length > 0
                      ? item.image_urls[0].image_url
                      : images.NO_IMAGE
                  }
                  title={item.name}
                  description={item.description || ""}
                />
              ))}
            </CarouselComponent>
          )}
        </>
      ),
    },

    {
      key: "3",
      label: `Other Spaces (${spacesData?.common_spaces.length})`,
      children: (
        <>
          {spacesData?.common_spaces &&
          spacesData.common_spaces.length === 0 ? (
            noDataFound("No Spaces")
          ) : (
            <CarouselComponent
              buttonClass="spaces-carousel-button"
              carouselClass="spaces-carousel"
            >
              {spacesData?.common_spaces.map((item, index) => (
                <CardComponent
                  key={`space-${index}`}
                  image={
                    item.image_urls.length > 0
                      ? item.image_urls[0].image_url
                      : images.NO_IMAGE
                  }
                  title={item.name}
                  description={item.description || ""}
                />
              ))}
            </CarouselComponent>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="venue-spaces-container" id="rooms-and-bathrooms">
      <div className="venue-details-title">Spaces</div>
      <div className="carousel-button-group"> </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "var(--deep-sea) !important",
              inkBarColor: "var(--deep-sea) !important",
              itemHoverColor: "var(--deep-sea) !important",
            },
          },
        }}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </ConfigProvider>
    </div>
  );
};

export default VenueSpaces;
