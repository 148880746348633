import React from "react";

// third party
import { Spin } from "antd";

// custom component
import UserVenueDetails from "./UserVenueDetails";

type ReservationDetailsSidebarProps = {
  loading: boolean;
  reservationDetails: any;
};

const ReservationDetailsSidebar: React.FC<ReservationDetailsSidebarProps> = ({
  loading,
  reservationDetails,
}) =>
  loading ? (
    <div className="message-venue-detail-sidebar">
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Spin />
      </div>
    </div>
  ) : (
    reservationDetails && (
      <div className="message-venue-detail-sidebar">
        <UserVenueDetails reservationDetails={reservationDetails} />
      </div>
    )
  );

export default ReservationDetailsSidebar;
