/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Radio, TimePicker } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom component
import FormLabel from "../../../../UI/FormLabel";
import GuestCounter from "../../../../UI/GuestCounter";
import CreateVenueFooter from "../CreateVenueFooter";

// network
import { postBookingTerms } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// helpers
import {
  changeUTC,
  convert24To12,
  handleEnterKey,
} from "../../../../../library";

// types
import {
  BookingTermsFormProps,
  BookingTermsType,
} from "../../../../../types/property-manager";

dayjs.extend(utc);

const BookingTermsForm: React.FC<BookingTermsFormProps> = ({
  venueId,
  bookingTermsData,
  handleNextStep,
  handlePreviousStep,
}) => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [checkInPeriod, setCheckInPeriod] = useState<"AM" | "PM">("AM");
  const [checkOutPeriod, setCheckOutPeriod] = useState<"AM" | "PM">("AM");
  const [quietHoursStartPeriod, setQuietHoursStartPeriod] = useState<
    "AM" | "PM"
  >("AM");
  const [quietHoursEndPeriod, setQuietHoursEndPeriod] = useState<"AM" | "PM">(
    "AM"
  );

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const { call } = useApiCall();

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<BookingTermsType>({
    defaultValues: {
      check_in_period: checkInPeriod,
      check_out_period: checkOutPeriod,
      quiet_hours_start_period: quietHoursStartPeriod,
      quiet_hours_end_period: quietHoursEndPeriod,
    },
  });

  const prepareDataToSend = (data: BookingTermsType) => {
    const checkInUtc = data.check_in
      ? changeUTC(data.check_in, checkInPeriod)
      : null;
    const checkOutUtc = data.check_out
      ? changeUTC(data.check_out, checkOutPeriod)
      : null;

    const quietHoursStartUtc = data.quiet_hours_start
      ? changeUTC(data.quiet_hours_start, quietHoursStartPeriod)
      : null;
    const quietHoursEndUtc = data.quiet_hours_end
      ? changeUTC(data.quiet_hours_end, quietHoursEndPeriod)
      : null;

    const result: any = {
      check_in: checkInUtc,
      check_out: checkOutUtc,
      min_night_stays: data.night_stays,
      max_guest: data.guest_number,
    };

    if (quietHoursStartUtc && quietHoursStartUtc !== "Invalid Date") {
      result.quiet_hours_start = quietHoursStartUtc;
    }

    if (quietHoursEndUtc && quietHoursEndUtc !== "Invalid Date") {
      result.quiet_hours_end = quietHoursEndUtc;
    }

    return result;
  };

  const sendBookingTerms = async (dataToSend: any, isSubmit: boolean) => {
    if (disableSaveButton) return;
    setSubmitting(true);

    call(
      () =>
        postBookingTerms({
          venue_id: venueId,
          data: dataToSend,
        }),
      (res) => {
        toast.success(
          res.data?.message || "Booking terms created successfully"
        );
        if (isSubmit) {
          handleNextStep(venueId);
        } else {
          navigate("/venues/my-venues");
        }
        setSubmitting(false);
        setDisableSaveButton(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to create booking terms"
        );
        setSubmitting(false);
        setDisableSaveButton(false);
      }
    );
  };

  // On form submit
  const onFinish = (data: BookingTermsType) => {
    const dataToSend = prepareDataToSend(data);
    sendBookingTerms(dataToSend, true); // true indicates form submission
  };

  // On save details
  const onSaveDetails = (data: BookingTermsType) => {
    if (disableSaveButton) return;
    setDisableSaveButton(true);
    const dataToSend = prepareDataToSend(data);
    sendBookingTerms(dataToSend, false); // false indicates save action
  };

  useEffect(() => {
    bookingTermsData?.check_in_date !== ""
      ? setValue(
          "check_in",
          convert24To12(bookingTermsData?.check_in_date, setCheckInPeriod)
        )
      : setValue("check_in", dayjs("11:00 AM", "hh:mm A"));
    bookingTermsData?.check_out_date !== ""
      ? setValue(
          "check_out",
          convert24To12(bookingTermsData?.check_out_date, setCheckOutPeriod)
        )
      : setValue("check_out", dayjs("11:00 AM", "hh:mm A"));
    bookingTermsData?.quiet_hours_start !== ""
      ? setValue(
          "quiet_hours_start",
          convert24To12(
            bookingTermsData?.quiet_hours_start,
            setQuietHoursStartPeriod
          )
        )
      : setValue("quiet_hours_start", null);
    bookingTermsData?.quiet_hours_end !== ""
      ? setValue(
          "quiet_hours_end",
          convert24To12(
            bookingTermsData?.quiet_hours_end,
            setQuietHoursEndPeriod
          )
        )
      : setValue("quiet_hours_end", null);
  }, [bookingTermsData, setValue]);

  useEffect(() => {
    // Set values explicitly to make sure they are recognized for validation
    setValue("night_stays", bookingTermsData?.night_stays || 1);
    setValue("guest_number", bookingTermsData?.guest_number || 1);

    // Trigger validation after setting values
    trigger(["night_stays", "guest_number"]);
  }, [bookingTermsData, setValue, trigger]);

  return (
    <div>
      <form
        className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="form-white-bg w-100">
          <div className="booking-time-container">
            <div className="form-group">
              <FormLabel label="Check in" />
              <div className="time-block-container d-flex">
                <Controller
                  name="check_in"
                  control={control}
                  rules={{
                    required: "Please select check-in time",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        showSecond={false}
                        suffixIcon={false}
                        minuteStep={30}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => {
                          onChange(time);
                          trigger("check_in");
                        }}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                        type="object"
                      />
                    );
                  }}
                />
                <Controller
                  name="check_in_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setCheckInPeriod(e.target.value); // Set AM/PM
                      }}
                      value={checkInPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.check_in && (
                <span className="error">{errors.check_in.message}</span>
              )}
            </div>
            <div className="form-group">
              <FormLabel label="Check out" />
              <div className="time-block-container d-flex">
                <Controller
                  name="check_out"
                  control={control}
                  rules={{
                    required: "Please select check-out time",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        showSecond={false}
                        suffixIcon={false}
                        minuteStep={30}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => {
                          onChange(time);
                          trigger("check_out");
                        }}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                      />
                    );
                  }}
                />
                <Controller
                  name="check_out_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setCheckOutPeriod(e.target.value);
                      }}
                      value={checkOutPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.check_out && (
                <span className="error">{errors.check_out.message}</span>
              )}
            </div>
            <div className="form-group">
              <FormLabel label="Quiet hours from" />
              <div className="time-block-container d-flex">
                <Controller
                  name="quiet_hours_start"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        showSecond={false}
                        suffixIcon={false}
                        minuteStep={30}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => onChange(time)}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                      />
                    );
                  }}
                />
                <Controller
                  name="quiet_hours_start_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setQuietHoursStartPeriod(e.target.value); // Set AM/PM
                      }}
                      value={quietHoursStartPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.quiet_hours_start && (
                <span className="error">
                  {errors.quiet_hours_start.message}
                </span>
              )}
            </div>
            <div className="form-group">
              <FormLabel label="Quiet hours until" />
              <div className="time-block-container d-flex">
                <Controller
                  name="quiet_hours_end"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        showSecond={false}
                        suffixIcon={false}
                        minuteStep={30}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => onChange(time)}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                      />
                    );
                  }}
                />
                <Controller
                  name="quiet_hours_end_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setQuietHoursEndPeriod(e.target.value); // Set AM/PM
                      }}
                      value={quietHoursEndPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.quiet_hours_end && (
                <span className="error">{errors.quiet_hours_end.message}</span>
              )}
            </div>
            <div className="form-group">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <FormLabel label="Minimum Number of Nights" />
                </div>
                <div className="time-block-container d-flex">
                  <Controller
                    name="night_stays"
                    control={control}
                    defaultValue={bookingTermsData?.night_stays || 1}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <GuestCounter
                          value={value}
                          onChange={onChange}
                          maxGuests={50}
                          className="justify-content-between"
                          defaultValue={bookingTermsData?.night_stays || 1}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <FormLabel label="Max Guests Capacity" />
                </div>
                <div className="time-block-container d-flex">
                  <Controller
                    name="guest_number"
                    control={control}
                    defaultValue={bookingTermsData?.guest_number || 1}
                    render={({ field: { value, onChange } }) => (
                      <GuestCounter
                        value={value}
                        onChange={onChange}
                        maxGuests={50}
                        className="justify-content-between"
                        defaultValue={bookingTermsData?.guest_number || 1}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-white-bg w-100">
          <div>
            <p className="common-sm-medium-dark-text m-0">
              Cancellation Policy
            </p>
            <p
              className="common-sm-light-grey-text mt-2"
              style={{ color: "#949397" }}
            >
              Venue Managers canceling a confirmed booking on LAIGO may face
              penalties affecting past and future listings. It's recommended to
              discuss changes with Retreat Hosts via the site's chat feature
              before canceling. A valid payment method is required, and fees may
              apply depending on the timing of the cancellation.
            </p>
            {/* TODO: Add cancellation policy - future point */}
            {/* <p className="mt-3">
              <Link to={"#"} style={{ color: "var(--deep-sea)" }}>
                <SmallText text="Review Cancellation Policy" />
              </Link>
            </p> */}
          </div>
        </div>
        <CreateVenueFooter
          disabled={!isValid || submitting}
          saveVenueDetails={() => {
            onSaveDetails(getValues());
          }}
          handleBackButton={handlePreviousStep}
        />
      </form>
    </div>
  );
};

export default BookingTermsForm;
