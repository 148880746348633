import React from "react";

//third party library
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

//common component
import Button from "../../../UI/Button";

//constant
import { HostHeaderTab ,items } from "../../../../constants/RetreatHost";

//css
import "../../../../assets/css/header.css";
import "../../../../assets/css/hostVenueDetail.css";

const VenueDetailHeader: React.FC = () => {

  return (
    <nav className="navbar navbar-expand-lg retreatVenueHeader">
      <div className="container">
        <div className="d-flex justify-content-between gap-4 w-100">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse tabsInDetail" id="navbarSupportedContent" style={{maxWidth :"900px"}}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 " >
              {HostHeaderTab.map((tab, index) => (
                <li className="nav-item" key={index}>
                  <Link to={tab.link} className="nav-link">
                    {tab.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex align-items-center gap-3">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <div className="me-xl-3 me-lg-3 me-md-3 me-sm-3 me-0 packageType">
                <div className="packageDetail d-flex ">
                  <span className="text-truncate">Single Package: Mirage</span>
                  <DownOutlined />
                </div>
                <span className="packageDetail ">$1,200</span>{" "}
                <del className="actualPrice ">$1,400</del>
              </div>
            </Dropdown>

            <Button
              type="button"
              label="Select this package"
              className="selectedPackage"
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default VenueDetailHeader;
