/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third Party
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfigProvider, Tabs, TabsProps } from "antd";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";
import Loader from "../../../UI/Loader";
import DetailsNotFound from "../../../UI/DetailsNotFound";
import PersonalInfoTabContent from "./components/PersonalInfoTabContent";
import GlobalPreferencesTabContent from "./components/GlobalPreferencesTabContent";
import LegalDocumentsTabContent from "./components/LegalDocumentsTabContent";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { userDetailById } from "../../../../network";

// Constants
import { USER_TYPE_ROLE } from "../../../../constants/Admin";

// Utility
import { convertToTitleCase } from "../../../../library";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const UserDetail: React.FC = () => {
  const { call } = useApiCall();
  const { user_id } = useParams();

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [userDetailData, setUserDetailData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<string>("personal_info");
  // const [isErrorRaised, setIsErrorRaised] = useState<boolean>(false);
  const isErrorRaised = false;
  const userDetailsTabsList: TabsProps["items"] = [
    { key: "personal_info", label: "Personal Info" },
    {
      key: "global_preferences",
      label: "Global Preferences",
    },
    {
      key: "legal_documents",
      label: "Legal Documents",
    },
  ];

  // APIs
  const getUserDetails = async (userId: string) => {
    setLoading(true);
    call(
      () => userDetailById(userId),
      (res) => {
        setUserDetailData(res.data.data);
        setLoading(false);
      },
      (err) => {
        toast.error("Failed to retrieve the user details.");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    Object.keys(userDetailData).length === 0 && getUserDetails(user_id || "");
  }, [selectedTab]);

  return (
    <>
      <AdminDashboardLayout
        pageTitle={`Welcome, ${convertToTitleCase(
          userProfileData.first_name
        )} 👋`}
        additionalClassNames="mt-4"
      >
        {!user_id || isErrorRaised ? (
          <DetailsNotFound userType={USER_TYPE_ROLE.ADMIN.key} />
        ) : (
          <>
            <div className="admin-users">
              <ConfigProvider>
                <Tabs
                  items={userDetailsTabsList}
                  defaultActiveKey={selectedTab}
                  onChange={setSelectedTab}
                />
              </ConfigProvider>
            </div>
            {loading ? (
              <Loader />
            ) : selectedTab === "personal_info" ? (
              <PersonalInfoTabContent userData={userDetailData} />
            ) : selectedTab === "global_preferences" ? (
              <GlobalPreferencesTabContent userData={userDetailData} />
            ) : (
              <LegalDocumentsTabContent userData={userDetailData} />
            )}
          </>
        )}
      </AdminDashboardLayout>
    </>
  );
};

export default UserDetail;
