import React, { useEffect, useState } from "react";

// Third party
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// custom component
import Button from "../../../../UI/Button";
import ModalComponent from "../../../../UI/ModalComponent";
import AddAmenitiesModal from "./AddAmenitiesModal";
import CreateVenueFooter from "../CreateVenueFooter";

//  redux
import { setShowModal } from "../../../../../redux/features/component-slice";

// network
import { updateAmenities } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import {
  VenueAmenities,
  AmenitySpaceFormProps,
  GroupedAmenities,
} from "../../../../../types/property-manager";

// images
import { images } from "../../../../../assets/images";

const SpacesForm: React.FC<AmenitySpaceFormProps> = ({
  venueAmenities,
  venueId,
  fetchAmenityDetails,
  handleNextStep,
  handlePreviousStep,
}) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const navigate = useNavigate();
  const [basicAmenities, setBasicAmenities] = useState<VenueAmenities[]>([]);

  const [remainingBasicAmenities, setRemainingBasicAmenities] = useState<
    string[]
  >([]);

  const [initialBasicAmenities, setInitialBasicAmenities] = useState<string[]>(
    []
  );

  const [disableSaveButton, setDisableSaveButton] =
    React.useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [groupAmenities, setGroupAmenities] = useState<{
    [key: string]: {
      id: string;
      name: string;
      icon_id: string;
      type_of_aminity: string;
    }[];
  }>({});

  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const removeAmenity = (amenity_id: string, type_of_aminity: string) => {
    const updatedAmenities = groupAmenities[type_of_aminity].filter(
      (amenity) => amenity.id !== amenity_id
    );
    // Only update the amenities of the specific type, keeping other groups intact
    setGroupAmenities((prevGroupAmenities) => ({
      ...prevGroupAmenities,
      [type_of_aminity]: updatedAmenities,
    }));

    // Update remainingBasicAmenities by filtering out the removed amenity's ID
    setRemainingBasicAmenities((prevRemaining) =>
      prevRemaining.filter((id) => id !== amenity_id)
    );
  };

  const saveAmenities = async ({ isSaving = true }: { isSaving: boolean }) => {
    if (disableSaveButton) return;

    isSaving ? setDisableSaveButton(true) : setSubmitting(true);
    call(
      () =>
        updateAmenities({ venue_id: venueId, data: remainingBasicAmenities }),
      (res) => {
        toast.success(res.data?.message || "Amenities updated successfully");
        isSaving ? navigate("/venues/my-venues") : handleNextStep(venueId);
        setDisableSaveButton(false);
        setSubmitting(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to update amenities"
        );
        setDisableSaveButton(false);
        setSubmitting(false);
      }
    );
  };

  const continueOnclick = async () => {
    const checkSameAmenities = initialBasicAmenities.filter(
      (item) => !remainingBasicAmenities.includes(item)
    );

    if (checkSameAmenities.length > 0) {
      await saveAmenities({ isSaving: false });
    } else {
      handleNextStep(venueId);
    }
  };

  useEffect(() => {
    if (!venueAmenities || venueAmenities.length === 0) return;
    setBasicAmenities(venueAmenities);
    setRemainingBasicAmenities(venueAmenities.map((amenity) => amenity.id));
    setInitialBasicAmenities(venueAmenities.map((amenity) => amenity.id));

    const groupedAmenities = venueAmenities.reduce((acc, amenity) => {
      const category = amenity.type_of_aminity || "Comfort and convenience"; // Default to 'Comfort and convenience' if no category is provided
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(amenity);
      return acc;
    }, {} as GroupedAmenities);

    setGroupAmenities(groupedAmenities);
  }, [venueAmenities]);

  return (
    <div className="amenities-spaces d-flex flex-column h-100 mb-5">
      <div className="spaces-dropdown-container d-flex justify-content-between align-items-center gap-2 position-relative">
        <section className="create-venue-page-title">
          <h1 className="title">Amenities</h1>
          <h4 className="subtitle">
            Highlight your venue’s unique features to inspire hosts and their
            guests.
          </h4>
        </section>

        {venueAmenities?.length !== 0 && (
          <Button
            label="+ New Amenities"
            onClick={() => openModal("add-amenities")}
          />
        )}
        <ModalComponent
          modalName="add-amenities"
          title="Amenities"
          className="modal-space-info"
          outsideClickClosable={false}
        >
          <AddAmenitiesModal
            venueId={venueId}
            fetchDetails={() => fetchAmenityDetails()}
            basicAmenities={basicAmenities}
          />
        </ModalComponent>
      </div>
      <div
        className={`flex-grow-1 d-flex  ${
          venueAmenities?.length === 0
            ? `flex-column mt-5`
            : `align-content-start flex-wrap mt-3 gap-4`
        }`}
      >
        {venueAmenities?.length === 0 && (
          <div className="empty-spaces d-flex flex-column align-items-center">
            <img src={images.ADD_ONS_BACKGROUND_IMAGE} alt="" />
            <div className="text-center mt-3">
              <h3 className="common-lg-bold-dark-text">Add Amenities</h3>
            </div>

            <Button
              label="+ New Amenities"
              onClick={() => openModal("add-amenities")}
              className="mt-4"
              style={{ width: "260px" }}
            />
          </div>
        )}
        {venueAmenities?.length > 0 &&
          Object.keys(groupAmenities).map((category, index) => (
            <div
              className="form-white-bg w-100"
              style={{ height: "fit-content" }}
              key={`basicAmenities-${index}`}
            >
              <div className="d-flex flex-column w-100 gap-3">
                <div className="spaces-form-sub-title">
                  {category.split("_").join(" ")}
                </div>
                <div className="d-flex flex-wrap gap-3 w-100 align-items-start">
                  {groupAmenities[category].map((item, index) => {
                    const icon = icons?.find(
                      (icon) => icon.id === item.icon_id
                    );
                    return (
                      <div
                        className="select-tag"
                        style={{
                          backgroundColor: "#E9F1F6",
                          border: "1px solid var(--deep-sea)",
                        }}
                        key={`basicAmenities-${index}`}
                      >
                        {icon ? (
                          <img src={icon?.icon_url} alt={item.icon_id} />
                        ) : (
                          <img
                            src={images.AIR_CONDITIONING_BLUE_ICON}
                            alt={item.icon_id}
                          />
                        )}
                        {item.name}
                        <button
                          type="button"
                          style={{ border: "none", background: "transparent" }}
                          onClick={() => {
                            removeAmenity(item.id, item.type_of_aminity);
                          }}
                          className="common-sm-light-grey-text"
                        >
                          <img
                            src={images.CROSS_GREY_ICON}
                            alt="cross-icon"
                            height={20}
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>
      <CreateVenueFooter
        continueButtonType="button"
        nextPage={`/${venueId}/add-on-services`}
        venueId={venueId}
        disabled={
          venueAmenities?.length === 0 ||
          remainingBasicAmenities.length === 0 ||
          submitting
        }
        saveVenueDetails={() => {
          saveAmenities({ isSaving: true });
        }}
        continueOnclick={continueOnclick}
        handleBackButton={handlePreviousStep}
      />
    </div>
  );
};

export default SpacesForm;
