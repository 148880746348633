import React from "react";

// Third party
import { useSelector } from "react-redux";
import { Flex, Tag } from "antd";

//  redux
import { RootState } from "../../../redux/store";

// types
import { VenueTag } from "../../../types/property-manager";

// styles
import "./style.css";

const ClickableTags: React.FC<VenueTag> = ({
  selectionMode,
  tagList,
  property_id,
  onChange,
  preSelectedTypes,
}) => {
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const handleChange = (tag_id: string, checked: boolean) => {
    if (selectionMode === "single") {
      onChange(checked ? tag_id : "");
    } else {
      const nextSelectedTags = checked
        ? [...(Array.isArray(property_id) ? property_id : []), tag_id]
        : (Array.isArray(property_id) ? property_id : []).filter(
            (t) => t !== tag_id
          );
      onChange(nextSelectedTags as string[]);
    }
  };

  return (
    <Flex gap={4} wrap align="center">
      {tagList?.map((type, index) => {
        const icon = icons?.find((icon) => icon.id === type.icon_id);

        const isChecked =
          selectionMode === "single"
            ? property_id === type.id
            : Array.isArray(property_id)
            ? property_id.includes(type.id)
            : preSelectedTypes?.includes(type.id);

        return (
          <Tag.CheckableTag
            key={`tag-${index}`}
            checked={isChecked as boolean}
            onChange={(checked) => handleChange(type.id, checked)}
          >
            <div className="select-tag">
              {icon ? (
                <img src={icon?.icon_url} alt={type.icon_id} />
              ) : type.iconImage ? (
                <img src={type.iconImage} alt="" />
              ) : null}
              {type.name}
            </div>
          </Tag.CheckableTag>
        );
      })}
    </Flex>
  );
};

export default ClickableTags;
