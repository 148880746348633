import React from "react";

// custom components
import Message from "../../components/core/Message";

const Messages: React.FC = () => {
  return <Message />;
};

export default Messages;
