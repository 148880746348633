import React from "react";

//common component
import SmallText from "../../../../UI/SmallText";

//types
import { ReservationProps } from "../../../../../types/retreat";

//images
import { images } from "../../../../../assets/images";
import { demo_images } from "../../../../../assets/images/demo-images";

const RetreatCard: React.FC<ReservationProps> = ({ ...retreat }) => {
  //for formate the data in month and date
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
  }
  const handleRetreat = (id: string) => {
    console.log(id);
  };
  return (
    <div
      className="card retreat-card flex-grow-1 position-relative"
      style={{ maxWidth: "378px" }}
      key={retreat.id}
      onClick={() => handleRetreat(retreat.id)}
    >
      <img
        src={demo_images.DEMO_IMAGE_1}
        className="card-img-top"
        alt="..."
        height={249}
      />
      <div className="card-body d-flex flex-column gap-1">
        <div className="text-truncate">
          <SmallText
            text={`${retreat.venue[Object.keys(retreat.venue)[0]].name}`}
            color="#19191C"
            fontSize={18}
            fontWeight={500}
            lineHeight={21.15}
          />
        </div>
        <div className="d-flex align-items-center">
          <SmallText
            text={`${retreat.venue[Object.keys(retreat.venue)[0]].city ?? ""}${
              retreat.venue[Object.keys(retreat.venue)[0]].country
            } • ${formatDate(retreat.check_in_date)} - ${formatDate(
              retreat.check_out_date
            )}`}
            color="#949397"
            fontSize={14}
            fontWeight={400}
            lineHeight={16.45}
          />
        </div>
        <div className="d-flex gap-1 align-items-center">
          <SmallText
            text={`${
              retreat.venue[Object.keys(retreat.venue)[0]].rooms
            } Bedrooms • ${
              retreat.venue[Object.keys(retreat.venue)[0]].bath_rooms
            } Baths • Sleeps ${
              retreat.venue[Object.keys(retreat.venue)[0]].sleeps
            }`}
            color="#949397"
            fontSize={14}
            fontWeight={400}
            lineHeight={16.45}
          />
        </div>
      </div>
      <div className="retreat-status d-flex w-100 flex-wrap gap-2">
        <div
          className={`status-value ${
            retreat.status === "Rejected"
              ? "rejected"
              : retreat.status === "Approved"
              ? "approved"
              : "pending"
          } `}
        >
          <img src={images.YOGA} alt="yoga" width={16} height={16} />
          <SmallText
            text="Meditation"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
          />
        </div>
        {retreat.is_draft ? (
          <div className="status-value pending">
            <SmallText
              text="Draft"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </div>
        ) : (
          <div
            className={`status-value ${
              retreat.status === "Rejected"
                ? "rejected"
                : retreat.status === "Approved"
                ? "approved"
                : "pending"
            } `}
          >
            <SmallText
              text={`${retreat.status}`}
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RetreatCard;
