/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

// custom component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
import NoDataFound from "../../../UI/NoDataFound";

// redux
import { RootState } from "../../../../redux/store";

// types
import { AddOnServicesDataType } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const AddOnServices: React.FC<AddOnServicesDataType> = ({
  openModal,
  addOnServicesData,
}) => {
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const [isAddOnServiceData, setIsAddOnServiceData] = useState<boolean>(false);

  useEffect(() => {
    addOnServicesData.length && setIsAddOnServiceData(true);
  }, []);

  return (
    <div className="add-on-services-container" id="add-on-services">
      <div className="d-flex justify-content-between align-items-center">
        <div className="venue-details-title">Add-on services</div>
        {isAddOnServiceData && (
          <button
            className="modal-button"
            type="button"
            onClick={() => openModal("addOnServices")}
          >
            <SmallText text="See all details" />
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        )}
      </div>
      {isAddOnServiceData ? (
        <div className="add-on-services-cards-container">
          <div className="add-on-services-cards">
            {addOnServicesData.slice(0, 3).map((service, index) => {
              const icon = icons?.find((icon) => icon.id === service.icon_id);
              return (
                <div className="service-card" key={`service-${index}`}>
                  <div className="title">
                    <img
                      src={icon?.icon_url || images.COMPASS_ICON}
                      alt=""
                      height="28px"
                    />
                    <Tooltip title={service.name}>
                      <SmallText
                        text={service.name}
                        color="#2F206A"
                        fontSize={18}
                        fontWeight={400}
                        lineHeight={21.15}
                        className="tex-overflow-handle"
                      />
                    </Tooltip>
                  </div>
                  <div
                    className="description"
                    style={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                    }}
                  >
                    {service.description}
                  </div>
                  <div>
                    {service.charges > 0 ? (
                      <div>
                        <span className="price">${service.charges}</span>
                        <span className="price-label">
                          {`/${service?.service_type.split("_").join(" ")}`}
                        </span>
                      </div>
                    ) : (
                      <span className="price">Free</span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <div>
              <ModalComponent modalName="addOnServices" title="Add-on services">
                <div className="venue-details-services service-cards">
                  {addOnServicesData.map((service, addOnServiceDataIndex) => {
                    const icon = icons?.find(
                      (icon) => icon.id === service.icon_id
                    );

                    return (
                      <div className="service-card" key={addOnServiceDataIndex}>
                        <div className="title">
                          <img
                            src={icon?.icon_url || images.COMPASS_ICON}
                            alt=""
                            height="28px"
                          />
                          <SmallText
                            text={service.name}
                            color="#2F206A"
                            fontSize={18}
                            fontWeight={400}
                            lineHeight={21.15}
                          />
                        </div>
                        <div className="description">{service.description}</div>
                        {service.charges > 0 ? (
                          <div>
                            <span className="price">${service.charges}</span>
                            <span className="price-label">
                              {`/${service?.service_type.split("_").join(" ")}`}
                            </span>
                          </div>
                        ) : (
                          <span className="price">Free</span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </ModalComponent>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column bg-white venue-selection">
            <NoDataFound
              boxStyle={{ height: 120 }}
              iconSize={32}
              text="No add-on Services"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AddOnServices;
