/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

// third party
import { EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

// custom components
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
import UpcomingPayoutModal from "../Home/UpcomingPayoutModal";
import TableComponent from "../../../UI/TableComponent";
import StatusTags from "../../../UI/StatusTags";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { numberSeparator } from "../../../../library";

// types
import { payoutTableColumnType } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const { Text } = Typography;

const PayoutsTable: React.FC<{
  onPageChange?: (page: number, pageSize?: number) => void;
  total?: number;
  payouts?: any;
  loading?: boolean;
}> = ({ onPageChange, total, payouts, loading }) => {
  const dispatch = useDispatch();
  const [payoutsData, setPayoutsData] = useState<payoutTableColumnType[]>([]);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const payoutsColumns: ColumnsType<payoutTableColumnType> = [
    {
      key: "payoutId",
      dataIndex: "payoutId",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.ID_ICON} alt="" height={"16px"} />
          <span>Payout ID</span>
        </div>
      ),
      width: 130,
      render: (_, { payoutId }) => (
        <Text style={{ width: 130 }} ellipsis={{ tooltip: payoutId }}>
          {payoutId}
        </Text>
      ),
    },
    {
      key: "payoutType",
      dataIndex: "payoutType",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span>Payout Type</span>
        </div>
      ),
      width: 136,
      render: (_, { payoutType }) => {
        return payoutType ? (
          <div
            className={`payoutTypeData${
              payoutType === "deposit" ? "Outlined" : ""
            }`}
          >
            {payoutType === "deposit" ? "Deposit" : "Final"}
          </div>
        ) : null;
      },
    },
    {
      key: "payoutDate",
      dataIndex: "payoutDate",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span>Payout Date</span>
        </div>
      ),
      width: 160,

      render: (_, { payoutDate }) => {
        return (
          <div>
            {dayjs(payoutDate as string).format("MMM DD")},
            {dayjs(payoutDate as string).format("YYYY")}
          </div>
        );
      },
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.COIN_ICON} alt="" height={"16px"} />
          <span>Amount</span>
        </div>
      ),
      width: 127,

      render: (_, { amount }) => `$ ${numberSeparator(amount as number, true)}`,
    },
    {
      key: "payoutStatus",
      dataIndex: "payoutStatus",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.STAR_BLACK_ICON} alt="" height={"16px"} />
          <span>Payout Status</span>
        </div>
      ),
      width: 164,
      render: (_, { payoutStatus }) => (
        <StatusTags
          top="0"
          left="0"
          buttonPosition="relative"
          label={
            payoutStatus === "paid"
              ? "Completed"
              : payoutStatus === "failed"
              ? "Failed"
              : "Pending"
          }
          backgroundColor={
            payoutStatus === "paid"
              ? "#CEF5DC"
              : payoutStatus === "failed"
              ? "#FDA9A0"
              : "#F7ECE2"
          }
          color={
            payoutStatus === "paid"
              ? "#398E58"
              : payoutStatus === "failed"
              ? "#F22007"
              : "#DC7618"
          }
        />
      ),
    },
    {
      key: "currency",
      dataIndex: "currency",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.DOLLAR_ICON} alt="" height={"16px"} />
          <span>Currency</span>
        </div>
      ),
      width: 120,
    },
    {
      key: "venueListing",
      dataIndex: "venueListing",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.HOUSE_ICON} alt="" height={"16px"} />
          <span>Venue Listing</span>
        </div>
      ),
      width: 271,
      render: (_, { venueListing }) => venueListing,
    },
    {
      key: "retreatHost",
      dataIndex: "retreatHost",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.PERSON_ICON_BLACK} alt="" height={"16px"} />
          <span>Retreat Host</span>
        </div>
      ),
      width: 194,
    },
    {
      key: "action",
      dataIndex: "action",
      className: "action-button",
      width: 123,
      render: (_, id) => {
        return (
          <div className="payout-details-button">
            <button
              type="button"
              className="bordered-button detail-button"
              onClick={() => openModal(`payout-details-${id.payoutId}`)}
            >
              <EyeOutlined /> Details
            </button>
            <ModalComponent
              title={<img src={images.LAIGO_LOGO} alt="logo" height={34} />}
              modalName={`payout-details-${id.payoutId}`}
            >
              <div
                className="text-center position-absolute"
                style={{ top: "40px", right: "225px" }}
              >
                <SmallText
                  text="Your Payout Summary"
                  color="#19191C"
                  fontSize={18}
                  fontWeight={700}
                  lineHeight={21.15}
                />
              </div>
              <UpcomingPayoutModal payoutId={id.payoutId as string} />
            </ModalComponent>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (payouts) {
      const formattedData = payouts.map((item: any, index: number) => {
        // Parse the reservation JSON if it exists

        return {
          key: item.id, // Use unique identifier
          payoutId: item.id,
          payoutType: item?.meta_data?.payout_type,
          payoutDate: item.date,
          amount: numberSeparator(item.amount, true), // Assuming amount is in cents
          payoutStatus: item.status,
          currency: item.currency.toUpperCase(),
          venueListing: item?.meta_data?.venue_name,
          retreatHost: (
            <div className="reservation-host-name d-flex align-items-center gap-1">
              <img
                src={
                  item?.meta_data?.retreat_host_image || images.PERSON_AVATAR
                }
                alt=""
                height={"40px"}
              />
              <div className="name">
                <p className="common-sm-dark-text tex-overflow-handle">
                  {item?.meta_data?.retreat_host}
                </p>
                <span className="common-xs-light-text">
                  {item?.meta_data?.venue_city}
                </span>
              </div>
            </div>
          ),
          action: item.id,
        };
      });
      setPayoutsData(formattedData);
    }
  }, [payouts]);

  return (
    <TableComponent
      columns={payoutsColumns}
      data={payoutsData}
      pageSize={5}
      scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
      tableLayout="fixed"
      pagination={false}
      loading={loading}
      locale={{
        emptyText: "No information to display",
      }}
    />
  );
};

export default PayoutsTable;
