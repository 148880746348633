import React from "react";

// common component
import SmallText from "../../../../UI/SmallText"; 

//images
import { demo_images } from "../../../../../assets/images/demo-images";

export type ImageNameType = keyof typeof demo_images;
export interface PackageCardProps {
  imageName?: ImageNameType;
  title: string;
  description: string;
  price: string;
  actualPrice?: string;
  index: number;
}

const PackageSelect: React.FC<PackageCardProps> = ({
  imageName,
  title,
  description,
  price,
  actualPrice,
  index,
}) => {
  return (
    <div
      className="d-flex p-3"
      style={{ gap: "12px", border: "1px solid #E8E7EC", borderRadius: "8px" }}
      key={index}
    >
      {imageName && (
        <img
          src={demo_images[imageName]}
          alt="img"
          className="rounded"
          width={80}
          height={80}
        />
      )}

      <div className="d-flex flex-column" style={{ gap: "9px" }}>
        <div className="d-flex  flex-column gap-2">
          <SmallText
            text={title}
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <SmallText
            text={description}
            color="#58585B"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
          />
        </div>
        <div className="d-flex gap-2 align-items-center">
          <SmallText
            text={price}
            color="#19191C"
            lineHeight={23.5}
            fontSize={20}
            fontWeight={500}
          />
          {actualPrice && (
            <del>
              <SmallText
                text={actualPrice}
                color="#949397"
                lineHeight={18.8}
                fontSize={16}
                fontWeight={400}
              />
            </del>
          )}
        </div>
      </div>
      <div style={{ minWidth: "88px" }}>
        <select className="form-select" aria-label="Default select example">
          <option selected>1</option>
          <option value="1">2</option>
          <option value="2">3</option>
          <option value="3">4</option>
        </select>
      </div>
    </div>
  );
};

export default PackageSelect;
