import React from "react";

//third party
import { useSearchParams } from "react-router-dom";
import { HeartOutlined } from "@ant-design/icons";

//common component
import SmallText from "../../../UI/SmallText";

//types
import { Property, PropertyState } from "../../../../types/retreat";
//images
import { demo_images } from "../../../../assets/images/demo-images";

const VenueCard: React.FC<PropertyState> = ({ properties }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (id: string) => {
    setSearchParams({ id: id });
  };

  return (
    <>
      <div className="d-flex flex-wrap gap-4 venue-card mb-3">
        {properties.map((venue: Property, index: number) => {
          return (
            <div
              key={index}
              className="card gap-2 "
              style={{
                width: "305px",
                border: "none",
              }}
              onClick={() => handleClick(venue.id)}
            >
              <div className="position-relative img-container">
                <img
                  src={
                    venue.venue_images
                      ? venue.venue_images[0]
                      : demo_images.DEMO_IMAGE_1
                  }
                  className="img-fluid d-block h-100 w-100 "
                  alt="venue_img not found"
                />
                <div id="overlay"></div>
                <div className="wishlist">
                  <HeartOutlined />
                </div>
              </div>

              <div className="d-flex flex-column gap-3 p-3 card-body">
                <div className="d-flex flex-column gap-1">
                  <SmallText
                    text={venue.name}
                    fontSize={18}
                    lineHeight={21.15}
                    color="#19191C"
                    fontWeight={500}
                    className="text-truncate"
                  ></SmallText>
                  <SmallText
                    text={`${venue.city}, ${venue.country}`}
                    fontSize={14}
                    lineHeight={16.45}
                    color="#949397"
                    fontWeight={400}
                    className="text-truncate"
                  ></SmallText>
                  <div className="d-flex gap-1 align-items-center room-detail">
                    <span>{venue.number_of_bed_rooms} Bedrooms</span>
                    <span>.</span>
                    <span>{venue.number_of_bath_rooms} Baths</span>
                    <span>.</span>
                    <span>Sleeps {venue.sleeps}</span>
                  </div>
                </div>
                <div className="d-flex gap-1 ">
                  <SmallText
                    text={`$${venue.base_price}`}
                    color="#19191C"
                    fontSize={16}
                    fontWeight={500}
                    lineHeight={18.8}
                  />
                  <SmallText
                    text={`night`}
                    color="#19191C"
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={16.45}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default VenueCard;
