// Components
import SmallText from "../../../../UI/SmallText";
import { demo_images } from "../../../../../assets/images/demo-images";
import Input from "../../../../UI/InputElement/Input";
import PhoneInput from "react-phone-input-2";

const PersonalInfoTabContent = ({ userData }: { userData: any }) => {
  const userRoleTitle = {
    venue_manager: "Venue Manager",
    retreat_host: "Retreat Host",
    retreat_guest: "Retreat Guest",
  };

  return (
    <>
      <div className="d-flex flex-column gap-3 user-detail-scroller">
        <div className="d-flex bg-white p-4 gap-4 flex-column common-border">
          <div className="d-flex gap-5 align-items-center">
            <div className="position-relative ">
              <img
                src={userData?.profile_image || demo_images.DEMO_IMAGE_1}
                alt="img"
                width={111}
                height={111}
                className="rounded"
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <SmallText
                text={userData?.full_name}
                color="#000000"
                lineHeight={37.6}
                fontWeight={700}
                fontSize={32}
              />
              <SmallText
                text={
                  userData?.user_type
                    ? userRoleTitle[
                        userData.user_type as keyof typeof userRoleTitle
                      ]
                    : ""
                }
                color="#787878"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-4">
              <div className="flex-grow-1">
                <Input
                  label="Business Name"
                  disabled={true}
                  value={userData?.business_name}
                />
              </div>
              <div className="flex-grow-1 ">
                <Input
                  label="Title"
                  disabled={true}
                  value={
                    userData?.user_type
                      ? userRoleTitle[
                          userData.user_type as keyof typeof userRoleTitle
                        ]
                      : ""
                  }
                />
              </div>
            </div>
            <div className="d-flex gap-4">
              <div className="flex-grow-1">
                <Input
                  label="First name"
                  disabled={true}
                  value={userData?.first_name}
                />
              </div>
              <div className="flex-grow-1 ">
                <Input
                  label="Last name"
                  disabled={true}
                  value={userData?.last_name}
                />
              </div>
            </div>
            <div className="d-flex gap-4">
              <div className="flex-grow-1">
                <Input
                  label="E-mail"
                  type="email"
                  disabled={true}
                  value={userData?.email}
                />
              </div>
              <div className="flex-grow-1 d-flex flex-column gap-1">
                <SmallText
                  text="Phone numbers"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <PhoneInput
                  country={"gb"}
                  enableAreaCodes={true}
                  specialLabel={""}
                  buttonClass={"custom-phone-button"}
                  dropdownClass={"custom-dropdown"}
                  searchClass={"search-field"}
                  disabled={true}
                  value={userData?.phone_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
          <SmallText
            text="Bio"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <div className="d-flex flex-column gap-1">
            <SmallText
              text="Descriptions"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={500}
              fontSize={14}
            />
            <textarea
              rows={4}
              className="form-control"
              disabled={true}
              value={userData?.bio || ""}
            ></textarea>
          </div>
        </div>
        <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
          <SmallText
            text="Social"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <div className="d-flex  gap-4">
            <div className="flex-grow-1">
              <Input
                label="Website"
                type="url"
                disabled={true}
                value={userData?.social?.website}
              />
            </div>
            <div className="flex-grow-1">
              <Input
                label="Instagram"
                type="url"
                disabled={true}
                value={userData?.social?.instagram}
              />
            </div>
          </div>
          <div className="d-flex  gap-4">
            <div className="flex-grow-1">
              <Input
                label="Facebook"
                type="url"
                disabled={true}
                value={userData?.social?.twitter}
              />
            </div>
            <div className="flex-grow-1">
              <Input
                label="X"
                type="url"
                disabled={true}
                value={userData?.social?.facebook}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
          <SmallText
            text="Bio"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <div className="d-flex  gap-3">
            <div className="flex-grow-1">
              <Input
                label="Country"
                disabled={true}
                value={userData?.country}
              />
            </div>
            <div className="flex-grow-1">
              <Input label="state" value={userData?.state} disabled={true} />
            </div>
            <div className="flex-grow-1">
              <Input label="City" value={userData?.city} disabled={true} />
            </div>
          </div>
          <div className="d-flex  gap-3">
            <div className="flex-grow-1">
              <Input
                label="ZIP Code"
                value={userData?.zip_code}
                disabled={true}
              />
            </div>
          </div>
          <div className="d-flex  gap-3">
            <div className="flex-grow-1">
              <SmallText
                text="Address"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <textarea
                rows={1}
                disabled={true}
                className="form-control"
                value={userData?.address}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfoTabContent;
