import React from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

// redux
import { setShowModal } from "../../../redux/features/component-slice";
import { RootState } from "../../../redux/store";

// types
import { ModalType } from "../../../types";

// styles
import "./style.css";

const ModalComponent: React.FC<ModalType & { modalName: string }> = ({
  title,
  children,
  modalName,
  className,
  maskClosable,
  outsideClickClosable = true,
  ...props
}) => {
  const showModal = useSelector(
    (state: RootState) => state.modal.modals[modalName]
  );
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        title={title}
        centered
        open={showModal ? true : false}
        onCancel={() => dispatch(setShowModal({ modalName, visible: false }))}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        className={`${className}`}
        {...props}
        maskClosable={outsideClickClosable}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalComponent;
