/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  ConfigProvider,
  Dropdown,
  MenuProps,
  Popover,
  Spin,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { RootState } from "../../../../redux/store";
import { EllipsisVertical, MessageSquare } from "lucide-react";

// custom components
import Button from "../../../UI/Button";
import StatisticCard from "../../../UI/StatisticCard";
import SmallText from "../../../UI/SmallText";
import TableComponent from "../../../UI/TableComponent";
import TotalRevenueChart from "./TotalRevenueChart";
import ModalComponent from "../../../UI/ModalComponent";
import UpcomingReservationDetailModal from "./UpcomingReservationDetailModal";
import Loader from "../../../UI/Loader";
import ReservationList from "./ReservationList";
import UpcomingPayoutModal from "./UpcomingPayoutModal";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import {
  setPayoutStatus,
  setReservationStatus,
} from "../../../../redux/features/queryParamSlice";
import { setReservation } from "../../../../redux/features/reservationSlice";
import { setLastRoute } from "../../../../redux/features/cancelVenueReservation";

// network
import {
  fetchHomeStatistic,
  fetchReservationStatistic,
  getChartAndPayouts,
  getReservationRequestDetails,
  getUpcomingReservationRequest,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// helpers
import { getAddOnServiceNames, numberSeparator } from "../../../../library";

// types
import {
  upcomingReservationTableType,
  UpcomingPayoutTypes,
  fetchReservationTypes,
  FetchChartPayoutTypes,
  Transaction,
} from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

// styles
import "../../../../assets/css/user-home.css";

const { Text } = Typography;

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useApiCall();
  const userSelector = useSelector((state: RootState) => state.user);
  const [selectedChatFilter, setSelectedChatFilter] =
    useState<string>("Past 3 months");

  const [reservationRequests, setReservationRequest] = useState<any>();
  const [upcomingReservations, setUpcomingReservations] = useState<any>();
  const [requestToShow, setRequestToShow] = useState<any>();
  const [
    upcomingReservationRequestLoader,
    setUpcomingReservationRequestLoader,
  ] = useState<boolean>(true);
  const [upcomingReservationLoader, setUpcomingReservationLoader] =
    useState<boolean>(true);

  const [reservationRequestDetailsLoader, setReservationRequestDetailsLoader] =
    useState<boolean>(false);

  const [payouts, setPayouts] = useState<Transaction[]>([]);

  const [payoutTableData, setPayoutTableData] = useState<UpcomingPayoutTypes[]>(
    []
  );
  const [totalReservations, setTotalReservations] = useState<number>(0);

  const [totalRevenue, setTotalRevenue] = useState<number>(0);

  const [currentYearRevenue, setCurrentYearRevenue] = useState<number>(0);

  const [avgLengthStay, setAvgLengthStay] = useState<number>(0);
  const [revenuePercentageChange, setRevenuePercentageChange] =
    useState<number>(0);

  const [availableDates, setAvailableDates] = useState<number>(0);
  const [currentYearState, setCurrentYearDates] = useState<number>(0);

  const [reservationLoader, setReservationLoader] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);

  const [revenueLoader, setRevenueLoader] = useState<boolean>(true);
  const [pendingReservationCount, setPendingReservationCount] = useState(0);
  const [totalLengthStay, setTotalLengthStay] = useState<number>(0);
  const [yearStayLength, setYearStayLength] = useState<number>(0);

  const [chartData, setChartData] = useState<{
    labels: string[];
    dataPoints: number[];
  }>();
  const [longestStay, setLongestStay] = useState<number>(0);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past 3 months")}
        >
          <SmallText text="Past 3 months" />
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past 6 months")}
        >
          <SmallText text="Past 6 months" />
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past year")}
        >
          <SmallText text="Past year" />
        </button>
      ),
    },
    {
      key: "4",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("All years")}
        >
          <SmallText text="All years" />
        </button>
      ),
    },
  ];

  const payoutsColumns: ColumnsType<UpcomingPayoutTypes> = [
    {
      key: "payoutId",
      dataIndex: "payoutId",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.ID_ICON} alt="" height={"16px"} />
          <span className="column-title">Payout ID</span>
        </div>
      ),
      fixed: "left",
      width: 125,
      render: (_, { payoutId }) => (
        <Text
          style={{ width: 130 }}
          ellipsis={{ tooltip: payoutId }}
          className="common-sm-dark-text tex-overflow-handle"
        >
          {payoutId}
        </Text>
      ),
    },
    {
      key: "venueListing",
      dataIndex: "venueListing",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 flex-wrap">
          <img src={images.HOUSE_ICON} alt="" height={"16px"} />
          <span className="column-title">Venue</span>
        </div>
      ),
      width: 146,
      render: (_, { venueListing }) => venueListing,
    },
    {
      key: "payoutDate",
      dataIndex: "payoutDate",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span className="column-title">Payout Date</span>
        </div>
      ),
      width: 118,
      render: (_, { payoutDate }) => {
        return (
          <div>
            {dayjs(payoutDate as string).format("MMM DD")},
            {dayjs(payoutDate as string).format("YYYY")}
          </div>
        );
      },
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.COIN_ICON} alt="" height={"16px"} />
          <span className="column-title">Amount</span>
        </div>
      ),
      width: 117,
      render: (_, { amount }) => numberSeparator(amount as number),
    },

    {
      key: "action",
      dataIndex: "action",
      className: "action-button",
      width: 10,
      render: (_, details) => {
        return (
          <div className="payout-details-button" style={{ width: "10px" }}>
            <button
              type="button"
              className="bg-white border-0"
              onClick={() => openModal(`payout-details-${details.payoutId}`)}
            >
              <EllipsisVertical color="#19191C" width={20} height={20} />
            </button>
            <ModalComponent
              title={<img src={images.LAIGO_LOGO} alt="logo" height={34} />}
              modalName={`payout-details-${details.payoutId}`}
            >
              <div
                className="text-center position-absolute"
                style={{ top: "40px", right: "225px" }}
              >
                <SmallText
                  text="Your Payout Summary"
                  color="#19191C"
                  fontSize={18}
                  fontWeight={700}
                  lineHeight={21.15}
                />
              </div>
              <UpcomingPayoutModal payoutId={details.payoutId as string} />
            </ModalComponent>
          </div>
        );
      },
    },
  ];

  const reservationData: upcomingReservationTableType[] =
    upcomingReservations?.map((reservation: any, index: number) => {
      const content = (
        <div className="actions-container">
          <button
            type="button"
            className="border-0 bg-white"
            onClick={() => {
              fetchUpcomingReservationDetails(reservation?.id).then(() => {
                openModal("reservation-details");
              });
            }}
          >
            <img src={images.EYE_ICON} alt="" height={"24px"} />
            <span className="w-100 text-start">View Details</span>
          </button>
          <ModalComponent modalName="reservation-details">
            <UpcomingReservationDetailModal
              reservationDetails={requestToShow}
              reservationPage={true}
            />
          </ModalComponent>
          <button
            type="button"
            className="border-0 bg-white"
            onClick={() => {
              navigate("/venues/messages");
            }}
          >
            <MessageSquare width={30} height={30} color="#2771A3" />
            <span className="w-100 text-start">Message Guest</span>
          </button>

          <button
            type="button"
            className="border-0 bg-white"
            onClick={() => {
              fetchUpcomingReservationDetails(reservation?.id).finally(() => {
                dispatch(setReservation({ data: requestToShow }));
                dispatch(setLastRoute("/venues/home"));
                navigate(`/venues/cancel`);
              });
            }}
          >
            <img src={images.CROSS_ICON} alt="" height={"24px"} />
            <span className="w-100 text-start">Cancel Reservation</span>
          </button>
        </div>
      );
      return {
        key: index,
        retreatHost: (
          <div className="reservation-host-name d-flex align-items-center gap-1">
            <img
              src={
                reservation?.retreat_host?.profile_picture_url ||
                images.PERSON_AVATAR
              }
              alt=""
              height={"40px"}
            />
            <div className="name">
              <Text
                style={{ width: 130 }}
                ellipsis={{ tooltip: reservation?.retreat_host?.name }}
                className="common-sm-dark-text tex-overflow-handle"
              >
                {reservation?.retreat_host?.name}
              </Text>

              <span className="common-xs-light-text">
                {reservation?.retreat_host?.city}
              </span>
            </div>
          </div>
        ),
        venue: (
          <Text
            style={{ width: 108 }}
            ellipsis={{ tooltip: reservation?.venue_name }}
            className="common-sm-dark-text tex-overflow-handle"
          >
            {reservation?.venue_name}
          </Text>
        ),
        payout: numberSeparator(reservation?.total_price, true),
        retreatDates: (
          <div className="d-flex justify-content-between align-items-center position-relative">
            <span>
              {dayjs(reservation?.check_in_date).format("MMM DD")} -{" "}
              {dayjs(reservation?.check_out_date).format("DD")},
              {dayjs(reservation?.check_in_date).format("YYYY")}
            </span>

            <Popover content={content} trigger="click" rootClassName="popups">
              <button type="button" className="bg-white border-0">
                <EllipsisVertical color="#19191C" width={20} height={20} />
              </button>
            </Popover>
          </div>
        ),
      };
    });
  const reservationColumns: ColumnsType<upcomingReservationTableType> = [
    {
      key: "retreatHost",
      dataIndex: "retreatHost",
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.PERSON_ICON_BLACK} alt="" height={"16px"} />
          <span className="column-title">Retreat Host</span>
        </div>
      ),
      width: 144,
      fixed: "left",
    },
    {
      key: "venue",
      dataIndex: "venue",
      title: (
        <div className="d-flex gap-2 align-items-center text-truncate">
          <img src={images.HOUSE_ICON} alt="" height={"16px"} />
          <span className="column-title">Venue</span>
        </div>
      ),
      width: 104,
      render: (_, { venue }) => (
        <Text style={{ width: 108 }} ellipsis={{ tooltip: venue }}>
          {venue}
        </Text>
      ),
    },
    {
      key: "payout",
      dataIndex: "payout",
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.DOUBLE_COINS_ICON} alt="" height={"16px"} />
          <span className="column-title">Payout</span>
        </div>
      ),
      width: 88,
    },
    {
      key: "retreatDates",
      dataIndex: "retreatDates",
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.CALENDAR_ICON} alt="" height={"16px"} />
          <span className="column-title">Retreat Dates</span>
        </div>
      ),
      width: 130,
    },
  ];

  const reservationRequestListTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "All",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          onClick={(id) => fetchReservationRequestsDetails(id)}
        />
      ),
    },
    {
      key: "2",
      label: "Requests",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Pending"
          onClick={(id) => fetchReservationRequestsDetails(id)}
        />
      ),
    },
    {
      key: "3",
      label: "Approved",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Approved"
          onClick={(id) => fetchReservationRequestsDetails(id)}
        />
      ),
    },
    {
      key: "4",
      label: "Completed",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Complete"
          onClick={(id) => fetchReservationRequestsDetails(id)}
        />
      ),
    },
    {
      key: "5",
      label: "Canceled",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Canceled"
          onClick={(id) => fetchReservationRequestsDetails(id)}
        />
      ),
    },
  ];

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const fetchUpcomingReservations = ({
    approval_status,
    limit,
  }: fetchReservationTypes) => {
    setUpcomingReservationLoader(true);
    call(
      () =>
        getUpcomingReservationRequest({
          limit,
          approval_status,
        }),
      (res) => {
        setUpcomingReservations(res.data.data);
        setPendingReservationCount(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservations"
        );
        setLoading(false);
      }
    );
  };

  const fetchReservationRequests = () => {
    call(
      () => getUpcomingReservationRequest({}),
      (res) => {
        setReservationRequest(res.data.data);
        res?.data?.data[0]?.id &&
          fetchReservationRequestsDetails(res?.data?.data[0]?.id);
        setUpcomingReservationRequestLoader(false);
      },
      (err) => {
        setUpcomingReservationRequestLoader(false);
      }
    );
  };

  const fetchReservationRequestsDetails = async (reservationId: string) => {
    setReservationRequestDetailsLoader(true);
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        setRequestToShow(res?.data?.data);
        setReservationRequestDetailsLoader(false);
      },
      (err) => {
        setReservationRequestDetailsLoader(false);
      }
    );
  };

  const fetchUpcomingReservationDetails = async (reservationId: string) => {
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        setRequestToShow(res?.data?.data);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservation details"
        );
      }
    );
  };

  const fetchHomeStatistics = async () => {
    call(
      () => fetchHomeStatistic(),
      (res) => {
        const data = res.data.data;
        setAvailableDates(data.available_dates ? data.available_dates : 1);
        setCurrentYearDates(
          data.current_year_available_dates
            ? data.current_year_available_dates
            : 1
        );
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservation details"
        );
      }
    );
  };

  const fetchReservationStatistics = async () => {
    setReservationLoader(true);
    call(
      () => fetchReservationStatistic(),
      (res) => {
        const data = res.data.data;
        setTotalReservations(data.total_reservations);
        setAvgLengthStay(data.average_stay_length);
        setLongestStay(data.longest_stay_length);
        setTotalLengthStay(data.sum_of_stay_length);
        setYearStayLength(data.current_year_stay_length);
        setReservationLoader(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservation details"
        );
        setReservationLoader(false);
      }
    );
  };

  const getChartDetailsAndPayouts = async ({
    stats_start_date,
    stats_end_date,
    payout_start_date,
    payout_end_date,
    offset,
    limit,
    chartFilter,
  }: FetchChartPayoutTypes) => {
    setRevenueLoader(true);
    call(
      () =>
        getChartAndPayouts({
          stats_start_date,
          stats_end_date,
          payout_start_date,
          limit,
          offset,
          payout_end_date,
          status: "pending",
        }),
      (res) => {
        const {
          data: { data },
        } = res;
        const stats = data?.statistics.payouts || [];
        const selectedTimeFilter = chartFilter;
        let revenueData: { [key: string]: number } = {};

        setPayouts(data?.payouts.payouts);

        const generatePeriods = (periods: number, format: string) =>
          Array.from({ length: periods }, (_, index) =>
            dayjs().subtract(index, "month").format(format)
          ).reverse();

        const calculateRevenue = (dateFormat: string) => {
          stats.forEach((payout: any) => {
            const period = dayjs(payout.date).format(dateFormat);
            if (revenueData[period] !== undefined) {
              revenueData[period] += payout.amount;
            }
          });
        };

        switch (selectedTimeFilter) {
          case "Past 3 months":
            revenueData = generatePeriods(3, "MMM").reduce(
              (acc, month) => ({ ...acc, [month]: 0 }),
              {}
            );
            calculateRevenue("MMM");
            break;
          case "Past 6 months":
            revenueData = generatePeriods(6, "MMM").reduce(
              (acc, month) => ({ ...acc, [month]: 0 }),
              {}
            );
            calculateRevenue("MMM");
            break;
          case "Past year":
            revenueData = generatePeriods(12, "MMM YYYY").reduce(
              (acc, month) => ({ ...acc, [month]: 0 }),
              {}
            );
            calculateRevenue("MMM YYYY");
            break;
          case "All years":
            stats.forEach((payout: any) => {
              const year = dayjs(payout.date).format("YYYY");
              revenueData[year] = (revenueData[year] || 0) + payout.amount;
            });
            break;
          default:
            break;
        }

        const labels = Object.keys(revenueData);
        const dataPoints = labels.map((label) => revenueData[label]);

        setTotalRevenue(data?.statistics.total_revenue);
        setCurrentYearRevenue(data?.statistics.current_year_revenue);
        setRevenuePercentageChange(data?.statistics?.percentage_change);

        setChartData({
          dataPoints,
          labels,
        });
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch revenue details"
        );
        setRevenueLoader(false);
      }
    ).finally(() => setRevenueLoader(false));
  };

  useEffect(() => {
    fetchUpcomingReservations({
      limit: 4,
      approval_status: "Pending",
    });
    fetchReservationRequests();
    getChartDetailsAndPayouts({ chartFilter: selectedChatFilter });
    fetchHomeStatistics();
  }, []);

  useEffect(() => {
    if (payouts) {
      const formattedData = payouts?.map((data, index: number) => {
        return {
          key: index + 1,
          payoutId: data.id,
          payoutType: data?.meta_data.payout_type,
          payoutDate: data.date,
          amount: numberSeparator(data.amount, true), // Assuming amount is in cents
          payoutStatus: data.status,
          currency: data.currency.toUpperCase(),
          venueListing: data?.meta_data?.venue_name,
          action: data as any,
        };
      });
      setPayoutTableData(formattedData);
    }
  }, [payouts]);

  useEffect(() => {
    fetchReservationStatistics();
  }, [availableDates]);

  useEffect(() => {
    if (!upcomingReservationLoader && !upcomingReservationRequestLoader) {
      setLoading(false);
    }
  }, [upcomingReservationLoader, upcomingReservationRequestLoader]);

  useEffect(() => {
    const options: {
      stats_start_date?: string;
      stats_end_date?: string;
      chartFilter: string;
    } = { stats_end_date: dayjs().format("YYYY-MM-DD"), chartFilter: "" };

    switch (selectedChatFilter) {
      case "Past 3 months":
        options.stats_start_date = dayjs()
          .subtract(3, "month")
          .format("YYYY-MM-DD");
        options.chartFilter = "Past 3 months";
        break;
      case "Past 6 months":
        options.stats_start_date = dayjs()
          .subtract(6, "month")
          .format("YYYY-MM-DD");
        options.chartFilter = "Past 6 months";
        break;
      case "Past year":
        options.stats_start_date = dayjs()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        options.chartFilter = "Past year";
        break;
      case "All years":
        delete options.stats_start_date;
        delete options.stats_end_date;
        options.chartFilter = "All years";
        break;
      default:
        return;
    }

    getChartDetailsAndPayouts(options);
  }, [selectedChatFilter]);

  return loading ? (
    <Loader />
  ) : (
    <div className="user-home-container d-flex flex-column gap-3">
      <div className="d-flex gap-3">
        <div className="flex-grow-1">
          <h3>Welcome, {userSelector?.userProfile.first_name}👋</h3>
          <p className="common-sm-dark-text m-0">
            <span className="common-sm-light-text" style={{ color: "#58585B" }}>
              You have
            </span>{" "}
            {pendingReservationCount} reservation requests to approve
          </p>
        </div>
        <div>
          <Link to="/venues/create-venue" style={{ marginRight: "8px" }}>
            <button type="button" className="custom-button">
              <img
                src={images.PLUS_WHITE}
                alt=""
                width={"16px"}
                height={"16px"}
              />{" "}
              Add Venue
            </button>
          </Link>
        </div>
      </div>
      <div className="user-home-content d-flex mb-2 justify-content-between position-relative">
        <div className="user-home-statistics d-flex flex-column gap-3 w-100">
          <div
            className="user-home-statistic-card-container d-flex flex-wrap"
            style={{ gap: "14px" }}
          >
            <StatisticCard
              title="Total Reservations"
              totalCount={`${totalReservations}`}
              description={`${pendingReservationCount} year-to-date`}
              loading={reservationLoader}
            />
            <StatisticCard
              title="Total Revenue"
              totalCount={`$ ${numberSeparator(totalRevenue, true)}`}
              changeDescription="Last year"
              description={`$${currentYearRevenue} year-to-date`}
              percentageChange={`${revenuePercentageChange}%`}
              color="#14CB74"
              loading={reservationLoader}
            />
            <StatisticCard
              title="Occupancy Rate"
              totalCount={
                isFinite(totalLengthStay / availableDates) &&
                totalLengthStay / availableDates > 0
                  ? `${((totalLengthStay / availableDates) * 100).toFixed(2)}%`
                  : "0%"
              }
              loading={reservationLoader}
              description={` ${
                isFinite(yearStayLength / currentYearState) &&
                yearStayLength / currentYearState > 0
                  ? ((yearStayLength / currentYearState) * 100).toFixed(2)
                  : "-"
              } % year to-date
                 `}
            />

            <StatisticCard
              title="Average Length of Stay"
              totalCount={`${avgLengthStay}`}
              loading={reservationLoader}
              description={`Longest stay is ${longestStay} days`}
            />
          </div>
          <div className="user-home-graph-tables ">
            <div className="chart-container">
              <div className="w-100 d-flex justify-content-between align-items-center flex-wrap">
                <p className="common-md-medium-dark-text m-0 mb-md-2">
                  Total Revenue
                </p>
                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: ["1"],
                  }}
                  trigger={["click"]}
                  className="chart-filter text-dark"
                  prefixCls="chart-filter-dropdown"
                >
                  <Typography.Link>
                    <span>{selectedChatFilter}</span>
                    <DownOutlined />
                  </Typography.Link>
                </Dropdown>
              </div>
              <div>
                <TotalRevenueChart
                  dataPoints={chartData?.dataPoints as number[]}
                  labels={chartData?.labels as string[]}
                  loading={revenueLoader}
                />
              </div>
            </div>
            <div className="user-home-table-container d-flex gap-3">
              <div className="user-home-table upcoming-table flex-grow-1 bg-white">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="common-md-medium-dark-text m-0">
                    Upcoming Payouts
                  </h4>
                  {payoutTableData.length > 0 && (
                    <button
                      className="border-0 bg-white"
                      onClick={() => {
                        dispatch(setPayoutStatus("pending"));
                        navigate("/venues/payouts");
                      }}
                    >
                      <SmallText text="View all" color="#5D3FD3" />
                    </button>
                  )}
                </div>
                <div>
                  <TableComponent
                    columns={payoutsColumns}
                    data={payoutTableData}
                    className="user-home-table-content"
                    pagination={false}
                    scroll={{ x: "max-content" }}
                  />
                </div>
              </div>
              <div className="user-home-table upcoming-table flex-grow-1 bg-white">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="common-md-medium-dark-text m-0">
                    Upcoming Reservations
                  </h4>
                  {reservationData?.length > 0 && (
                    <button
                      className="border-0 bg-white"
                      onClick={() => {
                        dispatch(setReservationStatus("Pending"));
                        navigate("/venues/reservations");
                      }}
                    >
                      <SmallText text="View all" color="#5D3FD3" />
                    </button>
                  )}
                </div>
                <div>
                  <TableComponent
                    columns={reservationColumns}
                    data={reservationData}
                    className="user-home-table-content"
                    pagination={false}
                    scroll={{ x: "max-content " }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-sticky top-0">
          <div className="user-home-reservation-sidebar overflow-hidden">
            <div>
              <p className="common-md-medium-dark-text m-0">
                Reservation Requests
              </p>
              {/* TODO: Add new request */}
              {/* <span className="common-xs-light-text">1 new request</span> */}
            </div>
            {requestToShow && (
              <div className="request-details">
                {reservationRequestDetailsLoader ? (
                  <div
                    className="h-full d-flex justify-content-center align-items-center m-auto "
                    style={{ minHeight: "220px" }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div className="d-flex flex-column gap-4">
                      <div className="reservation-host-name d-flex align-items-center gap-1">
                        <img
                          src={
                            requestToShow?.host?.profile_picture_url
                              ? requestToShow?.host?.profile_picture_url
                              : images.New_Avatar
                          }
                          alt=""
                          height={"40px"}
                        />
                        <div className="name">
                          <p className="common-sm-dark-text tex-overflow-handle">
                            {requestToShow?.host?.name}
                          </p>
                          <span className="common-xs-light-text">
                            From{" "}
                            {dayjs(requestToShow?.check_in_date).format(
                              "MMM DD, YYYY"
                            )}{" "}
                            to{" "}
                            {dayjs(requestToShow?.check_out_date).format(
                              "MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div>
                          <div className="common-sm-medium-light-text mb-1">
                            Group size:
                          </div>
                          <div className="common-sm-dark-text">
                            {requestToShow?.group_size}
                          </div>
                        </div>
                        {Object.keys(requestToShow?.add_on_services || {})
                          .length > 0 && (
                          <div>
                            <div className="common-sm-medium-light-text mb-1">
                              Add on services:
                            </div>
                            <div className="common-sm-dark-text">
                              <div className="common-sm-dark-text">
                                {getAddOnServiceNames(
                                  requestToShow?.add_on_services
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="common-lg-medium-dark-text">
                        ${" "}
                        {requestToShow?.total_price &&
                          numberSeparator(requestToShow?.total_price, true)}
                      </div>
                      <div className="d-flex gap-2">
                        <Button
                          label="See Details"
                          className="bordered-button"
                          style={{ width: "166px" }}
                          onClick={() => openModal("reservation-details")}
                        />
                        <Button
                          label={`${
                            requestToShow?.status === "Pending"
                              ? "Approve"
                              : requestToShow?.status === "Rejected"
                              ? "Rejected"
                              : "Approved"
                          }`}
                          style={{ width: "166px" }}
                          onClick={() => openModal("reservation-details")}
                          disabled={requestToShow?.status !== "Pending"}
                        />
                        <ModalComponent modalName="reservation-details">
                          <UpcomingReservationDetailModal
                            reservationDetails={requestToShow}
                            handleReservation={fetchReservationRequests}
                          />
                        </ModalComponent>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="reservation-request-list">
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      itemSelectedColor: "var(--deep-sea) !important",
                      inkBarColor: "var(--deep-sea) !important",
                      itemHoverColor: "var(--deep-sea) !important",
                    },
                  },
                }}
              >
                <Tabs defaultActiveKey="1" items={reservationRequestListTabs} />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
