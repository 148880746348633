import React, { useState } from "react";

// Third Party
import { ColumnsType } from "antd/es/table";
import { toast } from "react-toastify";

// Components
import { images } from "../../../../assets/images";
import TableComponent from "../../../UI/TableComponent";
import ModalComponent from "../../../UI/ModalComponent";
import SmallText from "../../../UI/SmallText";
import FormBtn from "../../../UI/InputElement/FormButton";

// Utils
import { convertToTitleCase } from "../../../../library";

// Styles
import "../../../../assets/css/admin-users.css";
import { Popover } from "antd";
import { useApiCall } from "../../../../hooks/useApiCall";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../../../redux/features/component-slice";
import { setPropertyId } from "../../../../redux/features/retreatVenue";

// APIs
import { adminUserDeactivateApproval } from "../../../../network";
import { RootState } from "../../../../redux/store";

// Firebase
import { get, query, ref, update } from "firebase/database";
import { db } from "../../../../network/firebaseConfig";
import { deleteUserFirebase } from "../../../../utils/firebase";

interface userTableType {
  key: React.Key;
  fullName: React.ReactNode;
  email: React.ReactNode;
  phoneNumber: React.ReactNode;
  city: React.ReactNode;
  role: React.ReactNode;
  action: React.ReactNode;
}

const columnsArray: ColumnsType<userTableType> = [
  {
    key: "fullName",
    dataIndex: "fullName",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.PERSON_ICON_BLACK} alt="" height={"16px"} />
        <span>Name</span>
      </div>
    ),
    width: 200,
  },
  {
    key: "email",
    dataIndex: "email",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.PERSON_ICON_BLACK} alt="" height={"16px"} />
        <span>Email</span>
      </div>
    ),
    width: 250,
  },
  {
    key: "phoneNumber",
    dataIndex: "phoneNumber",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.ID_ICON} alt="" height={"16px"} />
        <span>Phone Number</span>
      </div>
    ),
    width: 200,
  },
  {
    key: "city",
    dataIndex: "city",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.SIDEBAR_BUILDING} alt="" height={"16px"} />
        <span>City</span>
      </div>
    ),
    width: 150,
  },
  {
    key: "role",
    dataIndex: "role",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.GROUP} alt="" height={"16px"} />
        <span>Role</span>
      </div>
    ),
    width: 150,
  },
  {
    key: "action",
    dataIndex: "action",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <span></span>
      </div>
    ),
    width: 50,
    fixed: "right",
  },
];

const UserListingTable = ({
  role,
  userData,
  totalItems,
  currentPage,
  pageSize = 10,
  onPageChange,
  loading = false,
}: {
  role?: string;
  userData?: any;
  totalItems: number;
  currentPage: number;
  pageSize?: number;
  onPageChange: (page: number, pageSize: number) => void;
  loading?: boolean;
}) => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );
  const { call } = useApiCall();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clickedItemData, setClickedItemData] = useState<any>({});
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  // Modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const handleConversation = (id: string) => {
    const chatId = [userProfileData.user_id, id].sort().join("_");

    const messageRef = ref(db, `Chats/${chatId}/messages`);

    get(query(messageRef)).then(async (snapshot) => {
      if (snapshot.exists()) {
        navigate(`/admin/messages`);
      } else {
        const updates: { [key: string]: any } = {};
        updates[`users/${id}`] = {
          email: clickedItemData.email,
          name: clickedItemData.name,
          userRole: clickedItemData.user_role,
          uid: id,
        };
        updates[`Chats/${chatId}/messages`] = 0;
        updates[`Chats/${chatId}/unseenCount/${userProfileData.user_id}`] = {
          count: 0,
          last_message_timestamp: Date.now(),
        };
        updates[`Chats/${chatId}/unseenCount/${id}`] = {
          count: 0,
          last_message_timestamp: Date.now(),
        };
        await update(ref(db), updates);
        navigate(`/admin/messages`);
      }
    });
  };

  const handleMessageRedirection = (id: string) => {
    dispatch(setPropertyId(id));
    handleConversation(id);
  };

  // Dropdown List Data
  const dropDownContent = (userId: string) => (
    <div className={`actions-container venue-cards-action-container`}>
      <button
        className="border-0 bg-white"
        onClick={() => {
          navigate(`/admin/users/${userId}`);
        }}
      >
        <img src={images.EYE_ICON} alt="" height={"24px"} />
        <span>Details</span>
      </button>
      <button
        className="border-0 bg-white"
        onClick={() => handleMessageRedirection(clickedItemData.id)}
      >
        <img src={images.MENU_MESSAGES_ICON} alt="" height={"24px"} />
        <span>Message</span>
      </button>
      {role === "is_deactivation" ? (
        <>
          <button
            type="button"
            className="border-0 bg-white"
            onClick={() => {
              openModal("deactivate");
            }}
          >
            <img src={images.DUPLICATE_ICON} alt="" height={"24px"} />
            <span>Deactivate</span>
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );

  // APIs
  const updateUserDeactivateApprovalAPI = async (userId: string) => {
    call(
      () => adminUserDeactivateApproval(userId),
      (res) => {
        const index: number = userData.findIndex(
          (item: any) => item?.user_id === userId
        );

        // Check the index and if found, then remove that item from array
        if (index !== -1) {
          userData.splice(index, 1);
        }

        toast(res.data.message);
        setTableLoading(false);

        // Remove User from Firebase
        try {
          deleteUserFirebase(userId);
        } catch (err) {}
      },
      (err) => {
        toast.error(err.response.data?.message);
        setTableLoading(false);
      }
    );
  };

  const handleDeactivateBtnClick = (userId: string) => {
    setTableLoading(true);
    dispatch(setShowModal({ modalName: "deactivate", visible: false }));
    updateUserDeactivateApprovalAPI(userId);
  };

  const formattedUserData = userData.map((user: any) => ({
    key: user.user_id,
    fullName: user.full_name,
    email: user.email,
    phoneNumber: user.phone_number ? `+${user.phone_number}` : "",
    city: user.city,
    role: convertToTitleCase(user.user_type),
    action: (
      <div className="d-flex justify-content-between">
        <div>
          <Popover
            content={() => dropDownContent(user.user_id)}
            trigger="click"
            rootClassName="popups"
          >
            <button
              type="button"
              className="bg-white border-0"
              onClick={() => {
                setClickedItemData({
                  id: user.user_id,
                  name: user.full_name,
                  email: user.email,
                  user_role: user.user_type,
                  reason: user.reason,
                });
              }}
            >
              <img
                src={images.THREE_VERTICAL_DOT_BLACK}
                alt=""
                height={"20px"}
              />
            </button>
          </Popover>
        </div>
      </div>
    ),
  }));

  return (
    <>
      <TableComponent
        data={formattedUserData}
        columns={columnsArray}
        showPagination={true}
        pageSize={pageSize}
        paginationData={{
          current: currentPage,
          total: totalItems,
          onChange: onPageChange,
        }}
        loading={loading || tableLoading}
        scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
        tableLayout="fixed"
      />

      {/* Modal for Deactivation */}
      <ModalComponent modalName="deactivate" title="User Deactivation Request">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-2">
            <SmallText
              text="User :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={`${clickedItemData.name || "-"} (${clickedItemData.email})`}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div className="d-flex gap-2">
            <SmallText
              text="User Type:"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={clickedItemData.user_role}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div className="d-flex gap-2">
            <SmallText
              text="Reason By User:"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={clickedItemData.reason}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div>
            <SmallText
              text="Are you sure you want to deactivate this user?"
              color="#949397"
              fontSize={18}
              lineHeight={50}
              fontWeight={400}
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <FormBtn
              text="Deactivate"
              className="submit-btn"
              onClick={() => {
                handleDeactivateBtnClick(clickedItemData.id);
              }}
            />
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default UserListingTable;
