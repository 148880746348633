import React from "react";

// third party
import { useNavigate } from "react-router-dom";

// custom components
import Welcome from "../../../UI/WelcomePage/Welcome";

// constants
import {
  STEPS_OF_CREATE_VENUE,
  TITLE_OF_CREATE_VENUE,
  SUBTITLE_OF_CREATE_VENUE,
} from "../../../../constants/property-manager";

// images
import { images } from "../../../../assets/images";

const WelComeCreateVenue: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/venues/create-venue/venue-details");
  };
  return (
    <Welcome
      description={SUBTITLE_OF_CREATE_VENUE}
      title={TITLE_OF_CREATE_VENUE}
      steps={STEPS_OF_CREATE_VENUE}
      image={true}
      logoRequired={false}
      cancelButtonRequired={true}
      handleClick={handleClick}
      imgSrc={images.CREATE_VENUE_IMG}
    />
  );
};

export default WelComeCreateVenue;
