import React from "react";
import PayoutListing from "../../../components/core/Admin/Payouts/PayoutListing";

const AdminPayoutList: React.FC = () => {
  return (
    <div>
      <PayoutListing />
    </div>
  );
};

export default AdminPayoutList;
