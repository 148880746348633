import React from "react";
//common component
import StepFormHeader from "../../../UI/StepForm/StepFormHeader";
import SmallText from "../../../UI/SmallText";
import CKEditorComponent from "../../../UI/CKEditor/CKEditorComponent";
//images
import { images } from "../../../../assets/images";

const GuestInfo: React.FC = () => {
  return (
    <div className="container" style={{ marginTop: "40px" }}>
      <div className="d-flex flex-column gap-5">
        <StepFormHeader
          title="Guest info"
          description="Add fields you need information about from your guests and write a welcome message"
        />
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-1">
            <SmallText
              text="Welcome message"
              color="#58585B"
              lineHeight={16.45}
              fontSize={14}
              fontWeight={500}
            />
            <CKEditorComponent placeholder="Enter text here" />
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-3">
              <div style={{ width: "400px" }}>
                <SmallText
                  text="Name of input"
                  color="#58585B"
                  fontSize={14}
                  lineHeight={16.45}
                  fontWeight={400}
                />
              </div>
              <div style={{ width: "271px" }}>
                <SmallText
                  text="Input Size"
                  color="#58585B"
                  fontSize={14}
                  lineHeight={16.45}
                  fontWeight={400}
                />
              </div>
              <div style={{ width: "271px" }}>
                <SmallText
                  text="Mandatory"
                  color="#58585B"
                  fontSize={14}
                  lineHeight={16.45}
                  fontWeight={400}
                />
              </div>
            </div>
            <hr />
          </div>
          <button
            className="custom-btn"
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <img src={images.PLUS} alt="plus" width={16} height={16} />
            New Input
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuestInfo;
