import LOCATION_ICON from "./location_icon.png";
import VENUE_TYPE_ICON from "./venue-type-icon.png";
import SHARE_ICON from "./share_icon.png";
import HEART_ICON from "./heart_icon.png";
import HOME_ICON from "./home_icon.png";
import BED_ICON from "./bed_icon.png";
import SHOWER_ICON from "./shower_icon.png";
import PERSON_ICON from "./person_icon.png";
import FITNESS_ICON from "./fitness_icon.png";
import YOGA_ICON from "./yoga_icon.png";
import FAMILY_RETREAT_ICON from "./family_retreat_icon.png";
import CREATIVE_ICON from "./creative_icon.png";
import ECO_ICON from "./eco_icon.png";
import YOUTH_ICON from "./youth_icon.png";
import SMILE_ICON from "./smile_icon.png";
import BUTTERFLY_ICON from "./butterfly_icon.png";
import TREE_ICON from "./tree_icon.png";
import RIGHT_ARROW_ICON from "./right_arrow_icon.png";
import CALENDAR_ICON from "./calender_icon.png";
import DOWN_ARROW_ICON from "./down_arrow_icon.png";
import UP_ARROW_GREEN_ICON from "./arrow_up_green.png";
import NIGHT_ICON from "./night_icon.png";
import IMG_GALLERY from "./Vector.png";
import AIR_CONDITIONING_ICON from "./air_conditioner_icon.png";
import CONFERENCE_ROOM_ICON from "./conference_room_icon.png";
import HAIR_DRYER_ICON from "./hair_dryer_icon.png";
import WIFI_ICON from "./wifi_icon.png";
import HEATER_ICON from "./heater_icon.png";
import LIVING_ROOM_ICON from "./living_room_icon.png";
import OVEN_ICON from "./oven_icon.png";
import PET_ICON from "./pet_icon.png";
import POOL_ICON from "./pool_icon.png";
import TV_ICON from "./tv_icon.png";
import WASHING_MACHINE_ICON from "./washing_machine_icon.png";
import COMPASS_ICON from "./compass_icon.png";
import VERIFICATION_ICON from "./verification_icon.png";
import THUMBS_UP_ICON from "./thumbs_up_icon.png";
import CHAT_ICON from "./chat_icon.png";
import CLOCK_ICON from "./clock_icon.png";
import CAR_ICON from "./car_icon.png";
import SECURITY_CAMERA_ICON from "./security_camera_icon.png";
import PARKING_ICON from "./parking_icon.png";
import TOWEL_ICON from "./towel_icon.png";
import DRESSER_ICON from "./dresser_icon.png";
import COFFEE_ICON from "./coffee_icon.png";
import GARDEN_ICON from "./garden_icon.png";
import PROJECTOR_ICON from "./projector_icon.png";
import CAMPFIRE_ICON from "./campfire_icon.png";
import CLOSE_ICON from "./close_icon.svg";
import MENU_ICON from "./menu_icon.svg";
import PHOTOS_ICON from "./photos_icon.png";
import CLOCK_ICON2 from "./clock_icon2.png";
import FARM_ICON from "./ph_barn.png";
import CASTLE_ICON from "./ph_castle-turret.png";
import GUESTHOUSE_ICON from "./ph_house-simple.png";
import APARTMENT_ICON from "./ph_building-apartment.png";
import HOTEL_ICON from "./ph_building.png";
import BUILDING_ICON from "./ph_buildings.png";
import BUNGALOW_ICON from "./ph_tipi.png";
import CAMPING_ICON from "./ph_tent.png";
import HOUSELINE_ICON from "./ph_house-line.png";
import BIN_ICON from "./bin_icon.png";
import SPACIOUS_ICON from "./ph_arrows-out.png";
import HEEL_ICON from "./ph_high-heel.png";
import MOUNTAIN_ICON from "./ph_mountains.png";
import BUTTERFLY_GREEN_ICON from "./ph_butterfly.png";
import UPLOAD_BUTTON_ICON from "./upload_button.png";
import SIGN_OUT_ICON from "./ph_sign-out.png";
import PAINT_BRUSH_ICON from "./ph_paint-brush.png";
import SPACES_BACKGROUND_IMAGE from "./spaces_background.png";
import DEFAULT_IMG from "./default_image.png";
import SUN_ICON from "./ph_sun.png";
import PIN_ICON from "./ph_push-pin.png";
import ADD_ONS_BACKGROUND_IMAGE from "./addons_background.png";
import LEFT_ARROW_ICON from "./ph_caret-left.png";
import YOGA1 from "./yoga1.png";
import YOGA from "./yoga.png";
import AERO_PLANE from "./plane.png";
import CALENDAR from "./calendar.png";
import BEDROOM from "./Vectorroom.png";
import ROOM from "./room.png";
import BATH from "./bath.png";
import GROUP from "./group.png";
import EXIT from "./Vectorexit.png";
import UP from "./Vectorup.png";
import STAR from "./iconstar.png";
import ToolTip from "./VectorTooltip.png";
import COMPASS from "./iconCompass.png";
import BELL from "./iconRequest.png";
import LaigoLogo from "./VectorLaigo.png";
import CreateRetreat from "./CreateRetreat.png";
import SUCCESS from "./done.png";
import IslandRoom from "./islandRoom.png";
import COIN from "./coin.png";
import MarkUp from "./Markup.png";
import BasePrice from "./basePrice.png";
import Tickets from "./tickets.png";
import PLUS from "./Plus.png";
import SUN from "./sun.png";
import USER from "./User.png";
import WALLET from "./wallet.png";
import LABEL from "./label.png";
import CANCEL from "./Cancel.png";
import RetreatCoin from "./RetreatCoin.png";
import WORLD from "./world.png";
import Discount from "./discount.png";
import EarlyBird from "./earlyBird.png";
import EDIT from "./EDIT.png";
import CALENDAR1 from "./iconCalendar.png";
import GROUP2 from "./iconGroup.png";
import PAYMENT from "./Payment.png";
import SUCCESS_ICON from "./success_icon.png";
import DEFAULT_CARD_IMG from "./default_card_img.png";
import NO_VENUES_IMG from "./no_venues.png";
import Package from "./Package.png";
import MENU_HOME_ICON from "./menu-home.svg";
import MENU_VENUES_ICON from "./menu-venues.svg";
import MENU_MESSAGES_ICON from "./menu-message.svg";
import NO_IMAGE from "./No_Image_Available.jpg";
import WARNING_IMG from "./warning-img.png";
import AVATAR_IMG from "./avatar.png";
import FILTER_ICON from "./filter-icon.png";
import THREE_VERTICAL_DOTS from "./three-vertical-dots.png";
import SEARCH_ICON from "./search-icon.png";
import MESSAGES_BACKGROUND_IMAGE from "./messages-background.png";
import ATTACHMENT_ICON from "./attachment-icon.png";
import SEND_MESSAGE_ICON from "./send-message-icon.png";
import METRICS from "./VectorMetricks.svg";
import MENU_ROOM from "./Vector.svg";
import EXPLORE from "./VectorExplore.svg";
import HOME_BELL from "./bell.png";
import Question from "./question.png";
import Retreat from "./myRetreat.svg";
import SEND_MESSAGE from "./send-message.svg";
import GREEN_BED_ICON from "./green-bed-icon.png";
import LAIGO_LOGO from "./laigo-logo.png";
import RESERVATION_ICON from "./reservation-icon.png";
import PAYOUT_ICON from "./payout-icon.png";
import GEAR_ICON from "./gear-icon.png";
import HOUSE_ICON from "./house-icon.png";
import TABLE_CALENDAR_ICON from "./calendar-icon.png";
import COIN_ICON from "./coin-icon.png";
import ID_ICON from "./id-icon.png";
import DOUBLE_COINS_ICON from "./double-coins-icon.png";
import PERSON_ICON_BLACK from "./person-icon-black.png";
import PERSON_AVATAR from "./person_avatar.png";
import MESSAGE_SENT from "./message-sent.png";
import MESSAGE_SEEN from "./message-seen.png";
import QUESTION_ICON from "./question-icon.png";
import PAGINATION_ARROW_LEFT from "./arrow-left.png";
import PAGINATION_ARROW_RIGHT from "./arrow-right.png";
import CURRENCY_DOLLAR_CIRCLE from "./currency-dollar-circlecurrency.png";
import USERS_THREE from "./users-three.png";
import RESERVATION_TAG from "./reservation-tag.png";
import CALENDAR_DOT from "./calendar-dot.png";
import CALENDAR_CHECK from "./calendar-check.png";
import CROSS_ICON from "./cross-icon.png";
import EYE_ICON from "./eye-icon.png";
import DOLLAR_ICON from "./dollar.png";
import EXPORT_ICON from "./export-icon.png";
import THREE_VERTICAL_DOT_BLACK from "./three-vertical-dot-black.png";
import TWO_PEOPLE_ICON from "./two-people-icon.png";
import BATH_ICON from "./bath-icon.png";
import BED_ICON_BLUE from "./bed-icon.png";
import DUPLICATE_ICON from "./duplicate-icon.png";
import PAUSE_ICON from "./pause-icon.png";
import TRASH_ICON from "./trash-icon.png";
import CREATE_VENUE_IMG from "./create-venue-img.png";
import VENUE_LOCATION from "./venue-location.png";
import VENUE_SPACES from "./venue-space.png";
import VENUE_AMENITIES from "./venue-amenities.png";
import VENUE_ADDONS from "./venue-addon.png";
import VENUE_TERMS from "./venue-terms.png";
import VENUE_PRICING from "./venue-pricing.png";
import UPLOAD_BLACK_ICON from "./upload-black-icon.png";
import DELETE_IMG from "./delete-img.png";
import LOCK_ICON from "./lock-icon.png";
import CALENDAR4 from "./starCalender.png";
import PLUS_WHITE from "./plus-white.png";
import SPINNER from "./spinner.png";
import BINARY_ICON from "./binary.png";
import CREDIT_CARD_ICON from "./credit-card.png";
import CROSS_ICON_BLACK from "./cross-icon-black.png";
import STAR_BLACK_ICON from "./star-black.png";
import EDIT_ICON from "./edit-icon.png";
import TRASH_RED from "./trash-red.png";
import MY_PROFILE_USER from "./my-profile-user-icon.png";
import INFO_ICON from "./info-circle-icon.png";
import LOGOUT_RED_ICON from "./logout-red-icon.png";
import TICK from "./tick-img.png";
import SEARCH_GREY from "./search-grey.png";
import UP_ARROW_ICON from "./up-arrow-icon.png";
import SIDEBAR_BUILDING from "./sidebar-building.png";
import New_Avatar from "./new_avatar.png";
import GLOBE_ICON from "./globe-icon.png";
import CROSS_GREY_ICON from "./cross-grey-icon.png";
import PERSON_BLUE_ICON from "./person-blue-icon.png";
import LINK_ICON from "./link-icon.png";
import CALENDAR_EMPTY_ICON from "./calendar-icon-empty.png";
import AIR_CONDITIONING_BLUE_ICON from "./airconditioner-green-icon.svg";
import PAGE_NOT_FOUND1 from "./404-page-not-found.svg";

export const images = {
  PAGE_NOT_FOUND1,
  AIR_CONDITIONING_BLUE_ICON,
  CALENDAR_EMPTY_ICON,
  LINK_ICON,
  PERSON_BLUE_ICON,
  CROSS_GREY_ICON,
  GLOBE_ICON,
  SIDEBAR_BUILDING,
  UP_ARROW_ICON,
  SEARCH_GREY,
  TICK,
  LOGOUT_RED_ICON,
  INFO_ICON,
  MY_PROFILE_USER,
  TRASH_RED,
  EDIT_ICON,
  STAR_BLACK_ICON,
  CROSS_ICON_BLACK,
  CREDIT_CARD_ICON,
  BINARY_ICON,
  SPINNER,
  PAGINATION_ARROW_RIGHT,
  PAGINATION_ARROW_LEFT,
  PLUS_WHITE,
  LOCK_ICON,
  DELETE_IMG,
  UPLOAD_BLACK_ICON,
  VENUE_PRICING,
  VENUE_TERMS,
  VENUE_ADDONS,
  VENUE_AMENITIES,
  VENUE_SPACES,
  VENUE_LOCATION,
  CREATE_VENUE_IMG,
  TRASH_ICON,
  PAUSE_ICON,
  DUPLICATE_ICON,
  BED_ICON_BLUE,
  BATH_ICON,
  TWO_PEOPLE_ICON,
  THREE_VERTICAL_DOT_BLACK,
  EXPORT_ICON,
  DOLLAR_ICON,
  EYE_ICON,
  CROSS_ICON,
  CALENDAR_CHECK,
  CALENDAR_DOT,
  RESERVATION_TAG,
  USERS_THREE,
  QUESTION_ICON,
  MESSAGE_SEEN,
  MESSAGE_SENT,
  PERSON_AVATAR,
  PERSON_ICON_BLACK,
  DOUBLE_COINS_ICON,
  ID_ICON,
  COIN_ICON,
  TABLE_CALENDAR_ICON,
  HOUSE_ICON,
  GEAR_ICON,
  PAYOUT_ICON,
  RESERVATION_ICON,
  LAIGO_LOGO,
  GREEN_BED_ICON,
  SEND_MESSAGE,
  SEND_MESSAGE_ICON,
  ATTACHMENT_ICON,
  MESSAGES_BACKGROUND_IMAGE,
  SEARCH_ICON,
  THREE_VERTICAL_DOTS,
  FILTER_ICON,
  HOME_BELL,
  Retreat,
  Question,
  AVATAR_IMG,
  WARNING_IMG,
  NO_IMAGE,
  METRICS,
  EXPLORE,
  MENU_ROOM,
  MENU_MESSAGES_ICON,
  MENU_VENUES_ICON,
  MENU_HOME_ICON,
  SUCCESS_ICON,
  NO_VENUES_IMG,
  DEFAULT_CARD_IMG,
  LEFT_ARROW_ICON,
  ADD_ONS_BACKGROUND_IMAGE,
  PIN_ICON,
  SUN_ICON,
  DEFAULT_IMG,
  SPACES_BACKGROUND_IMAGE,
  PAINT_BRUSH_ICON,
  SIGN_OUT_ICON,
  UPLOAD_BUTTON_ICON,
  BUTTERFLY_GREEN_ICON,
  MOUNTAIN_ICON,
  HEEL_ICON,
  SPACIOUS_ICON,
  BIN_ICON,
  HOUSELINE_ICON,
  CASTLE_ICON,
  GUESTHOUSE_ICON,
  APARTMENT_ICON,
  HOTEL_ICON,
  BUILDING_ICON,
  BUNGALOW_ICON,
  CAMPING_ICON,
  FARM_ICON,
  CLOCK_ICON2,
  PHOTOS_ICON,
  MENU_ICON,
  CLOSE_ICON,
  CAMPFIRE_ICON,
  PROJECTOR_ICON,
  GARDEN_ICON,
  COFFEE_ICON,
  DRESSER_ICON,
  TOWEL_ICON,
  PARKING_ICON,
  SECURITY_CAMERA_ICON,
  CAR_ICON,
  CLOCK_ICON,
  THUMBS_UP_ICON,
  CHAT_ICON,
  LOCATION_ICON,
  VENUE_TYPE_ICON,
  SHARE_ICON,
  HEART_ICON,
  HOME_ICON,
  BED_ICON,
  SHOWER_ICON,
  PERSON_ICON,
  FITNESS_ICON,
  YOGA_ICON,
  FAMILY_RETREAT_ICON,
  CREATIVE_ICON,
  ECO_ICON,
  YOUTH_ICON,
  SMILE_ICON,
  BUTTERFLY_ICON,
  TREE_ICON,
  RIGHT_ARROW_ICON,
  CALENDAR_ICON,
  DOWN_ARROW_ICON,
  UP_ARROW_GREEN_ICON,
  NIGHT_ICON,
  IMG_GALLERY,
  AIR_CONDITIONING_ICON,
  CONFERENCE_ROOM_ICON,
  HAIR_DRYER_ICON,
  WIFI_ICON,
  HEATER_ICON,
  LIVING_ROOM_ICON,
  OVEN_ICON,
  PET_ICON,
  POOL_ICON,
  TV_ICON,
  WASHING_MACHINE_ICON,
  COMPASS_ICON,
  VERIFICATION_ICON,
  YOGA,
  YOGA1,
  AERO_PLANE,
  CALENDAR,
  BEDROOM,
  ROOM,
  BATH,
  GROUP,
  EXIT,
  UP,
  STAR,
  ToolTip,
  COMPASS,
  BELL,
  LaigoLogo,
  CreateRetreat,
  SUCCESS,
  IslandRoom,
  COIN,
  MarkUp,
  BasePrice,
  Tickets,
  PLUS,
  SUN,
  USER,
  WALLET,
  LABEL,
  CANCEL,
  RetreatCoin,
  WORLD,
  EarlyBird,
  Discount,
  EDIT,
  CALENDAR1,
  GROUP2,
  Package,
  PAYMENT,
  CURRENCY_DOLLAR_CIRCLE,
  CALENDAR4,
  New_Avatar,
};
