import React from "react";

const NonRefundPolicy : React.FC= () => {
  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">Rigid Non-Refundable Policy</p>
      <div>
        <li className="section-description">
          Cancellation: Cancellations made at any time after booking will not be
          eligible for any refund.
        </li>
        <li className="section-description">
          Changes: No changes to the booking (including dates or participant
          names) are allowed once the reservation is confirmed.
        </li>
        <li className="section-description">
          Transfer: Bookings are not transferable to another person or a future
          retreat.
        </li>
      </div>
    </div>
  );
};

export default NonRefundPolicy;
