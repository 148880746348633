/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spin } from "antd";
import dayjs from "dayjs";

// custom component
import Button from "../../../UI/Button";
import StatusTags from "../../../UI/StatusTags";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { fetchPayoutDetails } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// images
import { images } from "../../../../assets/images";

const UpcomingPayoutModal: React.FC<{
  payoutId?: string;
  payoutData?: any;
}> = ({ payoutId, payoutData }) => {
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const [payoutDetails, setPayoutDetails] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [reservationDetails, setReservationDetails] = useState<{
    date: string[];
    reservationNumber: string;
    retreatHost: string;
  } | null>();

  const getPayoutDetails = async () => {
    setLoading(true);
    call(
      () =>
        fetchPayoutDetails({
          payout_id: payoutId as string,
        }),
      (res) => {
        setPayoutDetails(res.data.data);
        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch payout details"
        );
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (payoutData) {
      setLoading(false);
      setPayoutDetails(payoutData);
    } else {
      getPayoutDetails();
    }
  }, []);

  useEffect(() => {
    if (!payoutDetails) return;
    if (payoutDetails.metadata?.reservation) {
      try {
        // Fix the improperly formatted date string in reservation
        const fixedReservation = payoutDetails?.metadata?.reservation.replace(
          /"date"\s*:\s*([0-9]{4}-[0-9]{2}-[0-9]{2},[0-9]{4}-[0-9]{2}-[0-9]{2})/,
          '"date": "$1"'
        );

        // Parse the reservation JSON safely
        const reservation = JSON.parse(fixedReservation);
        setReservationDetails({
          date: reservation?.date.split(","),
          reservationNumber: reservation?.reservation_number,
          retreatHost: reservation?.retreat_host,
        });
      } catch (error) {
        console.error("Error parsing reservation JSON:", error);
      }
    }
  }, []);

  return loading ? (
    <div className="d-flex justify-content-center w-100 mt-2">
      <Spin />
    </div>
  ) : (
    <div className="upcoming-reservation-detail-modal">
      <div className="title d-flex gap-2 align-items-center">
        <img src={images.NO_IMAGE} alt="" height={"40px"} width={"40px"} />
        <div>
          <p className="common-md-bold-dark-text">
            {payoutDetails?.metadata?.venue_name ||
              payoutDetails?.venue_details?.venue_name}
          </p>
          <span className="common-xs-light-text">
            {payoutDetails?.venue_details?.city +
              ", " +
              payoutDetails?.venue_details?.country}
          </span>
        </div>
      </div>
      <div className="upcoming-reservation-detail">
        <div className="about-retreat-container">
          <div className="common-sm-medium-dark-text mb-3">
            Reservation Details
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.RESERVATION_TAG} alt="" height={"16px"} />
              <span>Reservation Number</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.reservation_details?.reservation_number ||
                reservationDetails?.reservationNumber}
            </div>
          </div>
          <div className="about-retreat d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.PERSON_ICON_BLACK} alt="" height={"16px"} />
              Retreat Host
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {reservationDetails?.retreatHost ||
                payoutDetails?.reservation_details?.retreat_host}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
              <span>Retreat Dates</span>
            </div>
            {
              <div className="about-retreat-data common-sm-medium-dark-text">
                {payoutDetails?.reservation_details?.start_date &&
                  dayjs(payoutDetails?.reservation_details?.start_date).format(
                    "MMM DD"
                  ) + `-`}
                {payoutDetails?.reservation_details?.end_date &&
                  dayjs(payoutDetails?.reservation_details?.end_date).format(
                    "DD"
                  ) + `,`}
                {payoutDetails?.reservation_details?.start_date &&
                  dayjs(payoutDetails?.reservation_details?.start_date).format(
                    "YYYY"
                  )}
              </div>
            }
          </div>
        </div>
        <div className="about-retreat-container">
          <div className="common-sm-medium-dark-text mb-3">Payout Summary</div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.SPINNER} alt="" height={"16px"} />
              <span>Payout Status</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              <StatusTags
                top="0"
                left="0"
                buttonPosition="relative"
                label={
                  payoutDetails?.status === "paid" ? "Complete" : "Pending"
                }
                backgroundColor={
                  payoutDetails?.status === "paid" ? "#CEF5DC" : "#F7ECE2"
                }
                color={payoutDetails?.status === "paid" ? "#398E58" : "#DC7618"}
              />
            </div>
          </div>
          <div className="about-retreat d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.BINARY_ICON} alt="" height={"16px"} />
              Total Payout
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              ${payoutDetails?.amount || payoutDetails?.payout_details?.payout}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CURRENCY_DOLLAR_CIRCLE} alt="" height={"16px"} />
              <span>Currency</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.currency?.toUpperCase() ||
                payoutDetails?.payout_details.currency.toUpperCase()}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.BINARY_ICON} alt="" height={"16px"} />
              <span>Payout ID</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.id}
            </div>
          </div>
          {!payoutData && (
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.CREDIT_CARD_ICON} alt="" height={"16px"} />
                <span>Method</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                {payoutDetails?.method.toUpperCase() ||
                  payoutDetails?.source_type.toUpperCase()}
              </div>
            </div>
          )}
        </div>

        {(payoutDetails?.metadata?.payout_type ||
          payoutDetails?.payout_details?.payout_type) === "deposit" && (
          <div className="about-retreat-container">
            <div className="common-sm-medium-dark-text mb-3">
              Deposit Payout
            </div>
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.SPINNER} alt="" height={"16px"} />
                <span>Status</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                <StatusTags
                  top="0"
                  left="0"
                  buttonPosition="relative"
                  label={
                    payoutDetails?.status === "paid" ? "Complete" : "Pending"
                  }
                  backgroundColor={
                    payoutDetails?.status === "paid" ? "#CEF5DC" : "#F7ECE2"
                  }
                  color={
                    payoutDetails?.status === "paid" ? "#398E58" : "#DC7618"
                  }
                />
              </div>
            </div>
            <div className="about-retreat d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.COIN} alt="" height={"16px"} />
                Amount
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $
                {payoutDetails?.amount || payoutDetails?.payout_details?.payout}
              </div>
            </div>
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img
                  src={images.CURRENCY_DOLLAR_CIRCLE}
                  alt=""
                  height={"16px"}
                />
                <span>Currency</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                {payoutDetails?.payout_details?.currency.toUpperCase() ||
                  payoutDetails?.currency.toUpperCase()}
              </div>
            </div>
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.CALENDAR_ICON} alt="" height={"16px"} />
                <span>Payout Date</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                {payoutDetails?.date}
              </div>
            </div>
          </div>
        )}

        {(payoutDetails?.metadata?.payout_type ||
          payoutDetails?.payout_details?.payout_type) !== "deposit" && (
          <div className="about-retreat-container">
            <div className="common-sm-medium-dark-text mb-3">Final Payout</div>
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.SPINNER} alt="" height={"16px"} />
                <span>Payout Status</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                <StatusTags
                  top="0"
                  left="0"
                  buttonPosition="relative"
                  label={
                    payoutDetails?.status === "paid" ? "Complete" : "Pending"
                  }
                  backgroundColor={
                    payoutDetails?.status === "paid" ? "#CEF5DC" : "#F7ECE2"
                  }
                  color={
                    payoutDetails?.status === "paid" ? "#398E58" : "#DC7618"
                  }
                />
              </div>
            </div>
            <div className="about-retreat d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.COIN} alt="" height={"16px"} />
                Amount
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                ${payoutDetails?.amount}
              </div>
            </div>
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img
                  src={images.CURRENCY_DOLLAR_CIRCLE}
                  alt=""
                  height={"16px"}
                />
                <span>Currency</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                {payoutDetails?.payout_details?.currency.toUpperCase() ||
                  payoutDetails?.currency.toUpperCase()}
              </div>
            </div>
            <div className="d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                <img src={images.CALENDAR_ICON} alt="" height={"16px"} />
                <span>Payout Date</span>
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                {payoutDetails?.date}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
          <div className="d-flex gap-2">
            <Button
              type="button"
              label="Close"
              className="bordered-button"
              onClick={() => {
                dispatch(
                  setShowModal({
                    modalName: `payout-details-${payoutId}`,
                    visible: false,
                  })
                );
              }}
            />
            {/* Left for future implementation */}
            {/* <Button type="button" label={"Download"} onClick={() => {}} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingPayoutModal;
