import React from 'react'

const Previews : React.FC = () => {
  return (
    <div>
      Preview of the data.
    </div>
  )
}

export default Previews
