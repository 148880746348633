// third party
import { configureStore } from "@reduxjs/toolkit";

// redux
import modalReducer from "./features/component-slice";
import userReducer from "./features/user-slice";
import { retreatVenueSlice } from "./features/retreatVenue";
import { venueSlice } from "./features/venue-slice";
import { venueFilter } from "./features/venue-filter";
import { CommonSlice } from "./features/common-slice";
import { createRetreatSlice } from "./features/createRetreat-slice";
import { venueDataSlice } from "./features/venueDataSlice";
import { editVenueOperationsSlice } from "./features/editVenueOperationsSlice";
import { paginationSlice } from "./features/paginationSlice";
import { QueryParamSlice } from "./features/queryParamSlice";
import { payoutsPaginationSlice } from "./features/payoutsPaginationSlice";
import { CancelReservationSlice } from "./features/cancelVenueReservation";
import { ReservationSlice } from "./features/reservationSlice";
import { fetchedVenueSlice } from "./features/fetchedVenueSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    retreatVenue: retreatVenueSlice.reducer,
    commonReducer: CommonSlice.reducer,
    allVenue: venueSlice.reducer,
    filteredVenue: venueFilter.reducer,
    createRetreat: createRetreatSlice.reducer,
    venueData: venueDataSlice.reducer,
    editVenueOperations: editVenueOperationsSlice.reducer,
    pagination: paginationSlice.reducer,
    queryParam: QueryParamSlice.reducer,
    payoutsPagination: payoutsPaginationSlice.reducer,
    cancelVenue: CancelReservationSlice.reducer,
    reservation: ReservationSlice.reducer,
    fetchedVenue: fetchedVenueSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
