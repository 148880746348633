/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//third party
import { Plus } from "lucide-react";

//component
import UploadSection from "./UploadSection";
import Loader from "../../../UI/Loader";

//common component
import SmallText from "../../../UI/SmallText";

//api call
import { getVenueByUserId } from "../../../../network";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

const UploadDocument: React.FC = () => {
  const { call } = useApiCall();
  const [venueList, setVenueList] = useState<any[]>([]);
  const [uploadSections, setUploadSections] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [selectedVenue, setSelectedVenueId] = useState<{
    [key: string]: string;
  }>({});
  const handleChangeId = (e: string, value: number) => {
    setSelectedVenueId((pre: { [key: string]: string }) => ({
      ...pre,
      [value]: e,
    }));
  };

  const venueData: any = venueList.map((venue: any) => ({
    value: venue.id,
    label: venue.name,
  }));
  const getFilteredVenues = () => {
    const selectedVenueIds = Object.values(selectedVenue).filter(Boolean);
    return venueData.filter(
      (venue: any) => !selectedVenueIds.includes(venue.value)
    );
  };

  const handleAddUploadSection = () => {
    setUploadSections([...uploadSections, {}]);
  };
  const getVenues = () => {
    setLoading(true);
    call(
      () => getVenueByUserId(),
      (res) => {
        setVenueList(res.data.data);
        setLoading(false);
      },
      (err) => {
        ToastFail(err.message);
        setLoading(false);
      }
    );
  };

  const handleDeleteSection = (id: string) => {
    const newUploadSections = [...uploadSections];
    newUploadSections.splice(parseInt(id), 1);
    setUploadSections(newUploadSections);
    const index = Object.keys(selectedVenue).findIndex(
      (key) => selectedVenue[key] === id
    );

    if (index !== -1) {
      setSelectedVenueId((prev) => {
        const updatedSelectedVenue = { ...prev };
        delete updatedSelectedVenue[index];
        return updatedSelectedVenue;
      });
    }
  };
  //get venues list
  useEffect(() => {
    getVenues();
  }, []);

  return (
    <div
      className="d-flex flex-column gap-5 pb-4 document-upload"
      style={{ maxWidth: "1240px" }}
    >
      <div className="d-flex flex-column gap-3">
        <SmallText
          text="Upload Documents"
          lineHeight={23.5}
          fontSize={20}
          fontWeight={600}
          color="#000000"
        />
        {loading ? (
          <div className="document-loader ">
            <Loader />
          </div>
        ) : venueList.length > 0 ? (
          <UploadSection
            key={`upload-section`}
            venueOption={getFilteredVenues()}
            handleVenueId={handleChangeId}
            indexValue={-1}
            isSecond={false}
          />
        ) : (
          "You have not added any venue."
        )}
        {uploadSections.map((_, index) => (
          <UploadSection
            key={`upload-section-${index}`}
            venueOption={getFilteredVenues()}
            handleVenueId={handleChangeId}
            indexValue={index}
            isSecond={true}
            handleDocumentSection={handleDeleteSection}
          />
        ))}
      </div>
      <button
        className="submit-btn"
        onClick={handleAddUploadSection}
        disabled={
          venueList.length < 1 || venueList.length - 1 === uploadSections.length
        }
      >
        <div className="d-flex gap-1 align-items-center">
          <Plus width={18} height={18} />
          <SmallText
            text="Add Venue"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
            color="white"
          />
        </div>
      </button>
    </div>
  );
};

export default UploadDocument;
