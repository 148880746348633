// third party
import { createSlice } from "@reduxjs/toolkit";

type QueryType = {
  payoutStatus?: string | null;
  reservationStatus?: string | null;
};

const initialState: QueryType = {
  payoutStatus: "",
};

export const QueryParamSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setPayoutStatus: (state, action) => {
      state.payoutStatus = action.payload;
    },
    setReservationStatus: (state, action) => {
      state.reservationStatus = action.payload;
    },
  },
});

export const { setPayoutStatus, setReservationStatus } =
  QueryParamSlice.actions;

export default QueryParamSlice.reducer;
