import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Property, PropertyState } from "../../types/retreat";

const initialState: PropertyState = {
  properties: [],
};

export const venueSlice = createSlice({
  name: "venue",
  initialState,
  reducers: {
    setVenues: (state, action: PayloadAction<Property[]>) => {
      state.properties = action.payload;
    },
  },
});

export const { setVenues } = venueSlice.actions;

export default venueSlice.reducer;
