import { images } from "../../assets/images";

export const ADMIN_SIDEBAR_ITEM = [
  {
    icon: images.MENU_HOME_ICON,
    label: "Dashboard",
    url: "/admin/dashboard",
  },
  {
    icon: images.USERS_THREE,
    label: "Users",
    url: "/admin/users",
  },
  {
    icon: images.SIDEBAR_BUILDING,
    label: "Venues",
    url: "/admin/venues",
  },
  {
    icon: images.Retreat,
    label: "Retreat",
    url: "/admin/retreats",
  },
  {
    icon: images.RESERVATION_ICON,
    label: "Reservation",
    url: "/admin/reservations",
  },
  {
    icon: images.CALENDAR_ICON,
    label: "Bookings",
    url: "/admin/bookings",
  },
  {
    icon: images.PAYOUT_ICON,
    label: "Payouts",
    url: "/admin/payouts",
  },
  {
    icon: images.MENU_MESSAGES_ICON,
    label: "Messages",
    url: "/admin/messages",
  },
];

export const LANGUAGE_CODE = { EN_US: "en-US", DA_DK: "da-DK" };

export const USER_TYPE_ROLE = {
  ADMIN: { key: "admin", label: "Admin" },
  VENUE_MANAGER: { key: "venue_manager", label: "Property Manager" },
  RETREAT_HOST: { key: "retreat_host", label: "Retreat Host" },
  RETREAT_GUEST: { key: "retreat_guest", label: "Retreat Guest" },
};

export const AdminRequestTypes = {
  venueApprovalRequest: "Venue_Approval",
  venueDeactivationRequest: "Venue_Deactivation",
  userDeactivationRequest: "User_Deactivation",
};

export const AdminRequestedByTypes = {
  manager: "Venue Manager",
  host: "Retreat Host",
  guest: "Retreat Guest",
};

// Reservations
// NOTES : This might be useful in FUTURE
// export const RESERVATIONS_FILTER_TAB_LIST = {
//   APPROVED: { key: "approved", label: "Approved" },
//   COMPLETED: { key: "completed", label: "Completed" },
//   CANCELED: { key: "canceled", label: "Canceled" },
//   DECLINED: { key: "declined", label: "Declined" },
// };

// Reservations
export const RESERVATIONS_FILTER_TAB_LIST = {
  APPROVED: { key: "Approved", label: "Approved" },
  REJECTED: { key: "Rejected", label: "Rejected" },
  PENDING: { key: "Pending", label: "Pending" },
};
