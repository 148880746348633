import React from "react";

// types
import { MainLayoutProps } from "../../../types";

// styles
import "../../../assets/css/main-layout.css";

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="container main-layout-container">
      <div className="main">{children}</div>
    </div>
  );
};

export default MainLayout;
