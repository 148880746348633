import React, { useEffect, useState } from "react";
//third party
import { useSelector } from "react-redux";
//components
import HostHeader from "./HostHeader";
import RetreatTabs from "./RetreatTabs";
import RetreatCard from "../components/RetreatCard";

//common component
import Loader from "../../../../UI/Loader";
import SmallText from "../../../../UI/SmallText";

//redux store
import { RootState } from "../../../../../redux/store";

//custom hook
import { useApiCall } from "../../../../../hooks/useApiCall";

//types
import { ReservationProps } from "../../../../../types/retreat";

//api call
import { getAllRetreat } from "../../../../../network";

//utils
import { ToastFail } from "../../../../../utils/TostMessage";

const HostRetreat = () => {
  const { call } = useApiCall();
  const [properties, setProperty] = useState<ReservationProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryParams = useSelector(
    (state: RootState) => state.filteredVenue.queryParams
  );

  //get all reservation og host
  const handleRetreat = () => {
    setIsLoading(true);
    call(
      () => getAllRetreat("3fa85f64-5717-4562-b3fc-2c963f66afa6", queryParams),
      (res) => {
        setProperty(res.data.data);
        setIsLoading(false);
      },
      (err) => {
        ToastFail(err.response.data.message);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    handleRetreat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  return (
    <div className="d-flex h-100 gap-4 flex-column host-retreat">
      <HostHeader title="My retreats" />
      <RetreatTabs />
      <div
        className="h-100 w-100 d-flex flex-grow-1 flex-wrap gap-4"
        style={{ overflowY: "auto" }}
      >
        {isLoading ? (
          <Loader />
        ) : !isLoading && properties.length === 0 ? (
          <div className="text-center w-100">
            <SmallText text="No retreat found" />
          </div>
        ) : (
          <>
            {properties.map((property) => (
              <RetreatCard {...property} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default HostRetreat;
