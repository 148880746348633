import React from "react";
import UserDetail from "../../../components/core/Admin/Users/UserDetail";

const AdminUserDetail: React.FC = () => {
    return (
        <div>
            <UserDetail />
        </div>
    );
};

export default AdminUserDetail;
