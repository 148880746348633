import React from "react";
//component
import AboutPackage from "./AboutPackage";
import AboutPayment from "./AboutPayment";

const AboutVenue : React.FC = () => {
  return (
    <div className="d-flex flex-lg-row flex-md-column gap-4">
      <div className="flex-grow-1">
        <AboutPackage />
      </div>
      <div>
        <AboutPayment />
      </div>
    </div>
  );
};

export default AboutVenue;
