import React, { useState } from "react";
//third party
import { Heart } from "lucide-react";

//common component
import SmallText from "../../../UI/SmallText";

//images
import { images } from "../../../../assets/images";

interface PageTitleProps {
  title: string;
  city: string | undefined;
  country: string | undefined;
  isAdmin?: boolean;
}
const VenuePageTitle: React.FC<PageTitleProps> = ({
  title,
  city,
  country,
  isAdmin = false,
}) => {
  const [fillIcon, setFillIcon] = useState<boolean>(false);
  return (
    <div className="d-flex venueDetailTitleContainer">
      <div className="flex-grow-1 d-flex flex-column gap-2">
        <div className="venueTitle text-break">{title}</div>
        <div className="d-flex gap-4 flex-wrap align-middle ">
          <div className="venueIcon d-flex text-center align-middle  align-items-center gap-2">
            <img src={images.LOCATION_ICON} alt="location_icon" />
            <p className="venueIconTitle m-0">
              {city}, {country}
            </p>
          </div>
        </div>
      </div>
      {isAdmin ? (
        <></>
      ) : (
        <div className="d-flex   gap-2" style={{ marginTop: "12px" }}>
          <div className="d-flex gap-2">
            <img
              src={images.SHARE_ICON}
              alt="share icon"
              width={16}
              height={16}
            />
            <SmallText text="Share" />
          </div>
          <div
            className="d-flex gap-2"
            onClick={() => {
              setFillIcon(!fillIcon);
            }}
            style={{ cursor: "pointer" }}
          >
            <Heart
              color="#2771A3"
              width={16}
              height={16}
              fill={fillIcon ? "#2771A3" : "transparent"}
            />
            <SmallText text="Save" />
          </div>
        </div>
      )}
    </div>
  );
};

export default VenuePageTitle;
