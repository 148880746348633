import React from "react";

//third party
import { useNavigate } from "react-router-dom";
//common component
import SmallText from "../../../../UI/SmallText";
//images
import { images } from "../../../../../assets/images";

interface IHostHeaderProps {
  title: string;
  dateFilter?: () => void;
  infoButton?: () => void;
  bellButton?: () => void;
}
const HostHeader: React.FC<IHostHeaderProps> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="w-100">
      <div className="d-flex justify-content-between text-center">
        <div className="d-flex align-items-center">
          <SmallText
            text={title}
            color="#19191C"
            lineHeight={28.2}
            fontWeight={600}
            fontSize={24}
          />
        </div>
        <div className="d-flex gap-2">
          <button className="host-btn">
            <img src={images.Question} alt="que" width={16} height={16} />
          </button>
          <button className="host-btn">
            <img src={images.HOME_BELL} alt="bell" width={16} height={16} />
          </button>
          <button
            onClick={() => navigate("/host/retreat")}
            className="create-btn"
          >
            New Retreat
          </button>
        </div>
      </div>
    </div>
  );
};

export default HostHeader;
