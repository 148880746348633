import React from "react";

// Third party
import { images } from "../../../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";

// custom component
import SmallText from "../../../../UI/SmallText";
import Button from "../../../../UI/Button";
import ModalComponent from "../../../../UI/ModalComponent";
import CreateVenueFooter from "../CreateVenueFooter";
import RoomInfoModal from "./AddOnServicesModal";
import EditAddOnServiceModal from "./EditAddOnServiceModal";

//  redux
import { setShowModal } from "../../../../../redux/features/component-slice";
import { RootState } from "../../../../../redux/store";

// types
import { AddonServicesSpaceFormProps } from "../../../../../types/property-manager";

const { Text } = Typography;

const SpacesForm: React.FC<AddonServicesSpaceFormProps> = ({
  addOnServices,
  venueId,
  fetchAddOnServices,
  formStep,
  handleNextStep,
  handlePreviousStep,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  return (
    <div className="add-on-services-spaces d-flex flex-column h-100 mb-5 spaces-container">
      <div className="spaces-dropdown-container d-flex justify-content-between align-items-center gap-2 position-relative">
        <section className="create-venue-page-title">
          <h1 className="title">Add-On Services</h1>
          <h4 className="subtitle">
            Promote extra services to enhance the retreat experience.
          </h4>
        </section>

        {addOnServices.length > 0 && (
          <Button
            label="+ New Add-on"
            onClick={() => openModal("add-on-services")}
          />
        )}
        <ModalComponent
          modalName="add-on-services"
          title="Add-on Service"
          className="modal-space-info add-on-service-modal"
          outsideClickClosable={false}
        >
          <RoomInfoModal venueId={venueId} fetchDetails={fetchAddOnServices} />
        </ModalComponent>
      </div>
      <div
        className={`flex-grow-1 d-flex  ${
          addOnServices.length === 0
            ? `flex-column mt-5`
            : `justify-content-start flex-wrap mt-3 gap-4`
        }`}
      >
        {addOnServices.length === 0 && (
          <div className="empty-spaces d-flex flex-column align-items-center">
            <img src={images.ADD_ONS_BACKGROUND_IMAGE} alt="" />
            <div className="text-center mt-3">
              <h3 className="common-lg-bold-dark-text">
                List Your Add-On Services
              </h3>
            </div>
            <Button
              label="+ New Add-on"
              onClick={() => openModal("add-on-services")}
              className="mt-4"
            />
          </div>
        )}
        {addOnServices.length > 0 && (
          <div className="add-on-service-card-container form-white-bg w-100">
            <div className="service-cards w-100 d-flex flex-wrap flex-row">
              {addOnServices.map((item, index) => {
                const icon = icons?.find((icon) => icon.id === item.icon_id);
                return (
                  <>
                    <ModalComponent
                      modalName={`edit-add-on-services-${item.id}`}
                      title="Add-on Service"
                      className="modal-space-info add-on-service-modal"
                      outsideClickClosable={false}
                    >
                      <EditAddOnServiceModal
                        venueId={venueId}
                        fetchDetails={fetchAddOnServices}
                        addOnServices={item}
                      />
                    </ModalComponent>
                    <div
                      className="service-card position-relative"
                      key={`service-card-${index}`}
                      onClick={() =>
                        openModal(`edit-add-on-services-${item.id}`)
                      }
                    >
                      <div className="title justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <img src={icon?.icon_url} alt="" height="28px" />
                          <Text
                            style={{ width: 234 }}
                            ellipsis={{ tooltip: item.name }}
                          >
                            <SmallText
                              text={item.name}
                              color="#2F206A"
                              fontSize={18}
                              fontWeight={400}
                              lineHeight={21.15}
                            />
                          </Text>
                        </div>
                        {item.external_link && (
                          <Link
                            to={item.external_link}
                            className="external-link"
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                setShowModal({
                                  modalName: `edit-add-on-services-${item.id}`,
                                  visible: false,
                                })
                              );
                            }}
                          >
                            <img
                              src={images.LINK_ICON}
                              alt="link-icon"
                              height={24}
                            />
                          </Link>
                        )}
                      </div>
                      <Typography.Paragraph
                        ellipsis={{
                          rows: 3,
                          expandable: false,
                        }}
                        className="m-0"
                      >
                        <div className="description">{item.description}</div>
                      </Typography.Paragraph>
                      <div>
                        {item.charges > 0 ? (
                          <>
                            <span className="common-lg-medium-dark-text">
                              ${item.charges}
                            </span>
                            <span className="price-label">
                              {`/${item?.service_type.split("_").join(" ")}`}
                            </span>
                          </>
                        ) : (
                          <span className="price">Free</span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <CreateVenueFooter
        continueButtonType="button"
        disabled={(formStep && formStep < 4) || addOnServices.length === 0}
        venueId={venueId}
        continueOnclick={() => handleNextStep(venueId)}
        saveVenueDetails={() => {
          navigate(`/venues/my-venues`);
        }}
        handleBackButton={handlePreviousStep}
      />
    </div>
  );
};

export default SpacesForm;
