/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// redux
import { useDispatch } from "react-redux";
import { setShowModal } from "../../../../redux/features/component-slice";

// Component
import AdminDashboardLayout from "../AdminDashboardLayout";
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
import Loader from "../../../UI/Loader";
import DetailsNotFound from "../../../UI/DetailsNotFound";
import ImageComponent from "../../../UI/Images/ImageComponent";
import SectionDivide from "../../../UI/Divider/SectionDivide";
import FormBtn from "../../../UI/InputElement/FormButton";
import Header from "../../Header";
import AddOnServices from "../../PropertyManager/VenuePage/AddOnServices";
import AboutOwner from "../../PropertyManager/VenuePage/AboutOwner";
import InfoNeedToKnow from "../../PropertyManager/VenuePage/InfoNeedToKnow";
import VenueSpaces from "../../PropertyManager/VenuePage/VenueSpaces";
import SingleVenuePageHeader from "../../PropertyManager/VenuePage/VenuePageHeader";
import VenuePageTitle from "../../RetreatHost/VenueDetail/VenuePageTitle";
import AboutSection from "../../RetreatHost/VenueDetail/AboutSection";
import LocationComponent from "../../PropertyManager/VenuePage/VenueLocation";
import DocumentDisplay from "./components/VenueDocuments";
import {
  AddOnServiceType,
  FetchedSpacesType,
  HouseRuleType,
  IconType,
  VenueDetailsDataType,
} from "../../../../types/property-manager";

// Constants
import { USER_TYPE_ROLE } from "../../../../constants/Admin";

// API
import { useApiCall } from "../../../../hooks/useApiCall";
import {
  adminVenueApproval,
  adminVenueDeactivateApproval,
  fetchDocumentsByRefId,
  fetchRoomDetails,
  fetchVenue,
} from "../../../../network";

// Styles
import "../../../../assets/css/venue-page.css";
import "../../../../assets/css/header.css";

const VenueDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const { venueId } = useParams();

  // Venue Details State
  const [venue, setVenue] = useState<any>();
  const [venueDetails, setVenueDetails] = useState<VenueDetailsDataType>({
    venueDetail: {
      venueType: "",
      sleeps: 0,
      bedRooms: 0,
      bathRooms: 0,
    },
    venueHighlights: [],
    aboutVenue: "",
    customParagraph: [],
    typesOfRetreats: [],
  });
  const [venueOwnerData, setVenueOwnerData] = useState<any>({});
  const [amenities, setAmenities] = useState<IconType[]>([]);
  const [addOnServices, setAddOnServices] = useState<AddOnServiceType[]>([]);
  const [houseRule, setHouseRule] = useState<HouseRuleType>({
    checkInOut: [],
    quiteHour: [],
  });
  const [venueSpaces, setVenueSpaces] = useState<FetchedSpacesType>({
    rooms: [],
    common_spaces: [],
  });
  const [venueImages, setVenueImages] = useState<
    {
      id: string;
      image_url: string;
    }[]
  >([]);
  const [documentList, setDocumentList] = useState([]);

  const [spacesLoading, setSpacesLoading] = useState(true);
  const [venueLoading, setVenueLoading] = useState(true);
  const [documentLoading, setDocumentLoading] = useState(true);

  const [isRejectClicked, setIsRejectClicked] = useState<boolean>(false);
  const [rejectionReason, setRejectionReason] = useState<string>("");

  // Modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
    setIsRejectClicked(false);
  };

  // APIs
  const fetchVenueDetails = async (venueId: string) => {
    setVenueLoading(true);
    call(
      () => fetchVenue(venueId),
      (res) => {
        const venueData = res.data?.data;

        setVenue(venueData);
        setVenueDetails({
          venueDetail: {
            venueType: venueData.venue_type?.name,
            sleeps: venueData.sleeps,
            bedRooms: venueData.number_of_rooms,
            bathRooms: venueData.number_of_bathrooms,
          },
          venueHighlights: venueData.highlights,
          aboutVenue: venueData.property_description,
          customParagraph: venueData.custom_paragraphs,
          typesOfRetreats: venueData.recommended_retreats,
        });
        setVenueImages(res.data.data.venue_images ?? []);
        setAmenities(venueData.amenities.amenities);
        setHouseRule({
          checkInOut: [venueData.check_in_time, venueData.check_in_out],
          quiteHour: [venueData.quite_hours_start, venueData.quite_hours_end],
        });
        setAddOnServices(venueData.add_on_services);
        setVenueOwnerData({
          id: venueData.owner_id,
          name: venueData.owner_name,
          email: venueData.owner_email,
          profile_picture: venueData.profile_picture,
        });
        setVenueLoading(false);
      },
      (err) => {
        setVenueLoading(false);
      }
    );
  };

  const fetchVenueSpaces = async (venueId: string) => {
    setSpacesLoading(true);
    call(
      () => fetchRoomDetails(venueId),
      (res) => {
        setVenueSpaces(res.data.data);
        setSpacesLoading(false);
      },
      (err) => {
        setSpacesLoading(false);
      }
    );
  };

  const updateVenueApprovals = async (venueId: string, data: any) => {
    call(
      () => adminVenueApproval(venueId, data),
      (res) => {
        toast(res.data.message);
      },
      (err) => {
        toast.error(err.response.data?.message);
      }
    );
  };

  const updateVenueDeactivateApprovals = async (venueId: string) => {
    call(
      () => adminVenueDeactivateApproval(venueId),
      (res) => {
        toast(res.data.message);
      },
      (err) => {
        toast.error(err.response.data?.message);
      }
    );
  };

  const fetchVenueDocuments = async (venueId: string) => {
    call(
      () => fetchDocumentsByRefId(venueId),
      (res) => {
        setDocumentList(res.data.data);
        setDocumentLoading(false);
      },
      (err) => {
        setDocumentLoading(false);
      }
    );
  };

  // Venue Listing Modal Handling
  const handleConfirmRejection = () => {
    if (!rejectionReason) {
      toast.error("Please enter a reason for rejection");
    } else {
      updateVenueApprovals(venueId || "", {
        status: "Rejected",
        reason_if_rejected: rejectionReason,
      });
      dispatch(setShowModal({ modalName: "approval", visible: false }));
    }
  };

  const handleApproveBtnClick = () => {
    updateVenueApprovals(venueId || "", { status: "Approved" });
    dispatch(setShowModal({ modalName: "approval", visible: false }));
  };

  // Venue Deactivate Modal Handling
  const handleDeactivateBtnClick = () => {
    updateVenueDeactivateApprovals(venueId || "");
    dispatch(setShowModal({ modalName: "deactivate", visible: false }));
  };

  // useEffect
  useEffect(() => {
    if (venueId) {
      fetchVenueDetails(venueId);
      fetchVenueSpaces(venueId);
      fetchVenueDocuments(venueId);
    }
    setIsRejectClicked(false);
  }, [venueId]);

  return venueLoading || spacesLoading || documentLoading ? (
    <Loader />
  ) : !venue ? (
    <DetailsNotFound userType={USER_TYPE_ROLE.ADMIN.key} />
  ) : (
    <>
      <AdminDashboardLayout
        pageTitle="Venue Details"
        additionalClassNames="no-padding"
      >
        <div className="position-relative venue-detail-main-div admin-venue-listing">
          {/* Header */}
          <Header containerClassName="container">
            <SingleVenuePageHeader admin={true} venueData={venue} />
          </Header>
          <div className="detail-section">
            <div className="container d-flex gap-4 flex-column mt-4 pb-5">
              {/* Venue Images */}
              {venueImages.length > 0 && (
                <ImageComponent images={venueImages} />
              )}
              <VenuePageTitle
                title={venue.venue_title}
                city={venue.city}
                country={venue.country}
                isAdmin={true}
              />
              <SectionDivide />
              <div className="d-flex flex-column gap-5">
                <AboutSection
                  aboutTitle="About the venue"
                  aboutDescription={venueDetails.aboutVenue}
                  venueType={venueDetails.venueHighlights}
                  sectionId="venue-details"
                  isThereContent={Boolean(
                    venueDetails?.venueHighlights?.length > 0 || 0
                  )}
                />
                <AboutSection
                  aboutTitle="Types of Retreats"
                  venueType={venueDetails.typesOfRetreats}
                  onlyAmenities={true}
                  isThereContent={Boolean(
                    venueDetails?.typesOfRetreats?.length > 0 || 0
                  )}
                />
                {venueDetails?.customParagraph?.map((item, index) => {
                  return (
                    <AboutSection
                      key={`about-section-${index}`}
                      aboutTitle={item.title}
                      aboutDescription={item.content}
                      isThereContent={Boolean(
                        venueDetails?.customParagraph?.length > 0 || 0
                      )}
                    />
                  );
                })}
              </div>
              <SectionDivide />
              <VenueSpaces spacesData={venueSpaces} />
              <AboutSection
                aboutTitle={"Amenities"}
                onlyAmenities={true}
                venueType={amenities}
                sectionId="amenities"
                isThereContent={Boolean(amenities.length > 0 || 0)}
              />
              <AddOnServices
                openModal={openModal}
                addOnServicesData={addOnServices}
              />
              <LocationComponent postalCode={venue?.zip_code || ""} />
              <DocumentDisplay documentList={documentList} />
              <AboutOwner ownerData={venueOwnerData} />
              <InfoNeedToKnow openModal={openModal} houseRule={houseRule} />
            </div>
          </div>
        </div>
        <ModalComponent modalName="approval" title="Venue Listing Approval">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2">
              <SmallText
                text="Venue Name :"
                color="#949397"
                fontSize={18}
                lineHeight={21.15}
                fontWeight={400}
              />

              <SmallText
                text={venue.venue_title}
                color="#19191C"
                fontSize={18}
                lineHeight={21.15}
                fontWeight={400}
              />
            </div>
            {isRejectClicked ? (
              <div className="d-flex flex-column gap-1">
                <SmallText
                  text="Reason of Rejection :"
                  color="#949397"
                  fontSize={18}
                  lineHeight={21.15}
                  fontWeight={400}
                />
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Write your reason for rejection"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                ></textarea>
              </div>
            ) : (
              <></>
            )}

            <div className="d-flex justify-content-end gap-3">
              <FormBtn
                text="Approve"
                className="submit-btn"
                onClick={handleApproveBtnClick}
              />
              <FormBtn
                text={`${isRejectClicked ? "Confirm Reject" : "Reject"}`}
                className="btn btn-danger"
                onClick={() =>
                  isRejectClicked
                    ? handleConfirmRejection()
                    : setIsRejectClicked(true)
                }
              />
            </div>
          </div>
        </ModalComponent>
        <ModalComponent
          modalName="deactivate"
          title="Venue Deactivate Approval"
        >
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2">
              <SmallText
                text="Venue Name :"
                color="#949397"
                fontSize={18}
                lineHeight={21.15}
                fontWeight={400}
              />

              <SmallText
                text={venue.venue_title}
                color="#19191C"
                fontSize={18}
                lineHeight={21.15}
                fontWeight={400}
              />
            </div>
            <div className="d-flex justify-content-end gap-3">
              <FormBtn
                text="Deactivate"
                className="submit-btn"
                onClick={handleDeactivateBtnClick}
              />
            </div>
          </div>
        </ModalComponent>
      </AdminDashboardLayout>
    </>
  );
};

export default VenueDetail;
