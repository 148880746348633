import React from 'react'
//component
import BasicDetail from './BasicDetail'

const PersonalInfo : React.FC = () => {
  return (
    <div className='basic-detail  pb-4'> 
      <BasicDetail/>
    </div>
  )
}

export default PersonalInfo
