import React, { useEffect, useRef, useState } from "react";

//third party
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, Input, Tabs, TabsProps } from "antd";
import {
  UserOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";

//component
import AvailableVenue from "./AvailableVenue";

//common component
import Loader from "../../../UI/Loader";

//redux store
import { setQueryParams } from "../../../../redux/features/venue-filter";
import { setVenues } from "../../../../redux/features/venue-slice";
import { RootState } from "../../../../redux/store";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//api call
import { highlightedVenue, venueHighlights } from "../../../../network";

//utils
import { regions } from "../../../../constants/create-retreat";
import { images } from "../../../../assets/images";
import { TabProps } from "../../../../types/retreat";
import { ToastFail } from "../../../../utils/TostMessage";

//css
import "../../../../assets/css/createRetreat.css";

const SelectVenue: React.FC = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const { call } = useApiCall();

  const queryParams = useSelector(
    (state: RootState) => state.filteredVenue.queryParams
  );
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [adults, setAdults] = useState<number>(0);
  const [children, setChildren] = useState<number>(0);
  const [pets, setPets] = useState<number>(0);
  const [groupModal, setGroupModal] = useState<boolean>(false);
  const [placeModal, setPlaceModal] = useState<boolean>(false);
  const [placeName, setPlaceName] = useState<string>("");
  const [displayDate, setDisplayDate] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState<string>("");
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [dates, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(
    null
  );
  const minDate = dayjs().startOf("day");
  const groupModalRef = useRef<HTMLDivElement>(null);
  const placeModalRef = useRef<HTMLDivElement>(null);

  let groupValue = `${adults ? `${adults} Adults` : ""} ${
    children ? `, ${children}children` : ""
  } ${pets ? `, ${pets} pets` : ""} `;

  const totalGuest = adults + children + pets;
  const items: TabsProps["items"] = tabs.map((tab) => ({
    key: tab.id,
    label: (
      <div
        className="d-flex align-items-center gap-2"
        onClick={() => handleVenue(tab.id)}
      >
        <img
          src={
            icons.filter((icon) => icon.id === tab.icon_id)[0]?.icon_url || ""
          }
          alt={tab.name || "tab image"}
          style={{
            width: "15px",
            height: "15px",
          }}
        />
        <div>{tab.name}</div>
      </div>
    ),
  }));

  const handleCountry = (countryName: string) => {
    setPlaceName(countryName);
    setPlaceModal(false);
  };
  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current.isBefore(minDate);
  };
  const handleSearch = () => {
    dispatch(
      setQueryParams({
        group_size: totalGuest,
        search: placeName,
        start_date: dates ? dates[0]?.format("YYYY-MM-DD") : "",
        end_date: dates ? dates[1]?.format("YYYY-MM-DD") : "",
      })
    );
  };

  const handleVenue = (id: string) => {
    dispatch(setQueryParams({ venue_highlights: id }));
  };
  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const getVenueHighlight = () => {
    setLoading(true);
    call(
      () => venueHighlights(),
      (res) => {
        setTabs(res.data.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        ToastFail("Error while fetch venue highlights");
      }
    );
  };

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      dispatch(setVenues([]));
      call(
        () => highlightedVenue(queryParams),
        (res) => {
          dispatch(setVenues(res.data.data));
        },
        (err) => {
          ToastFail("Error while fetch retreat venue detail");
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getVenueHighlight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        groupModalRef.current &&
        !groupModalRef.current.contains(event.target as Node)
      ) {
        setGroupModal(false);
      }
      if (
        placeModalRef.current &&
        !placeModalRef.current.contains(event.target as Node)
      ) {
        setPlaceModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="container select-venue">
      <div className=" d-flex justify-content-center align-items-center mx-auto venue-page ">
        <div className="venue-filter">
          <div className="group-size position-relative" ref={placeModalRef}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "24px", height: "24px", padding: "3px" }}
            >
              <img
                src={images.AERO_PLANE}
                alt="location"
                className="img-fluid w-100 h-100"
              />
            </div>
            <Input
              placeholder="Where are you going?"
              value={placeName}
              onFocus={() => setPlaceModal(true)}
            />
            <div className={`place-box ${placeModal ? "d.block" : "d-none"}`}>
              <p>Search by regions</p>
              <div className="d-flex gap-3 flex-wrap place-name">
                {regions.map((country, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleCountry(country.option)}
                    >
                      {country.option}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="vr"></div>
          <div className="group-size" style={{ width: "30%" }}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "24px", height: "24px", padding: "2.5px" }}
            >
              <img
                src={images.CALENDAR}
                alt="location"
                className="img-fluid w-100 h-100"
              />
            </div>

            <RangePicker
              onFocus={() => setDisplayDate(false)}
              placeholder={displayDate ? ["When are you ", "going?"] : ["", ""]}
              onBlur={() => setDisplayDate(true)}
              onChange={(dates) => setDateRange(dates)}
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
            />
          </div>
          <div className="vr"></div>
          <div
            ref={groupModalRef}
            className="group-size position-relative"
            style={{ width: "30%" }}
          >
            <UserOutlined />

            <Input
              placeholder="Group size"
              value={adults || children || pets ? groupValue : ""}
              onFocus={() => setGroupModal(true)}
            />
            <div
              className={`select-group d-flex flex-column gap-3 ${
                groupModal ? "d-block" : "d-none"
              }`}
            >
              <div className="d-flex justify-content-between align-items-center ">
                <div className="group-name">Adults</div>
                <div
                  className="d-flex justify-content-between align-items-center flex-grow-1"
                  style={{ maxWidth: "100px" }}
                >
                  <button
                    className={`group-btn ${adults === 0 ? "disable-btn" : ""}`}
                    disabled={adults === 0}
                    onClick={() => setAdults((a) => Math.max(0, a - 1))}
                  >
                    -
                  </button>
                  <div className="group-value">{adults}</div>
                  <button
                    className="group-btn "
                    onClick={() => setAdults((a) => a + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="group-name">
                  Children
                  <p>0 to 17 years</p>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center flex-grow-1"
                  style={{ maxWidth: "100px" }}
                >
                  <button
                    className={`group-btn ${
                      children === 0 ? "disable-btn" : ""
                    }`}
                    disabled={children === 0}
                    onClick={() => setChildren((a) => Math.max(0, a - 1))}
                  >
                    -
                  </button>
                  <div className="group-value">{children}</div>
                  <button
                    className="group-btn "
                    onClick={() => setChildren((a) => a + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="group-name">Pets</div>
                <div
                  className="d-flex justify-content-between align-items-center flex-grow-1"
                  style={{ maxWidth: "100px" }}
                >
                  <button
                    className={`group-btn ${pets === 0 ? "disable-btn" : ""}`}
                    disabled={pets === 0}
                    onClick={() => setPets((a) => Math.max(0, a - 1))}
                  >
                    -
                  </button>
                  <div className="group-value">{pets}</div>
                  <button
                    className="group-btn "
                    onClick={() => setPets((a) => a + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="vr"></div>
          <div className="venue-btn">
            <Button
              style={{ borderRadius: "0 5px 5px 0" }}
              onClick={handleSearch}
              disabled={isLoading}
            >
              <div className="search-icon">
                <SearchOutlined />
              </div>
            </Button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="tab-section position-relative">
            <div>
              <Tabs
                items={items}
                activeKey={activeKey}
                onChange={handleTabChange}
              />
            </div>

            <button className="d-flex filter-btn">
              <FilterOutlined />
              Filters
            </button>
          </div>

          <AvailableVenue />
        </>
      )}
    </div>
  );
};

export default SelectVenue;
