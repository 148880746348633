import React from "react";
import VenueListing from "../../../components/core/Admin/Venues/VenueListing";

const AdminVenueList: React.FC = () => {
    return (
        <div>
            <VenueListing />
        </div>
    );
};

export default AdminVenueList;
