import React from "react";
import "./index.css"
interface IFormBtnProps {
  isLoading?: boolean;
  text: string;
  type?: "button" | "submit" | "reset";
  align?: "left" | "right";
  validForm ? : boolean;
}

interface IFormBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const FormBtn = ({ isLoading, text, align,validForm, ...props }: IFormBtnProps) => {
  return (
    <button
      className={`btn common-btn form-btn ${
        align === "left" && "btn-align-left"
      } ${align === "right" && "btn-align-right"}`}
      disabled={isLoading || validForm}
      {...props}
    >
      {isLoading ? (
        <div className="loading-wrapper">
          <div className="btn-loader"></div>
        </div>
      ) : (
        <>{text}</>
      )}
    </button>
  );
};

export default FormBtn;
