//api
import createApiInstance from "./api";

//utils
import { GET_URL } from "../library/config";
import { makeQueryParams } from "../utils/filterObject";

//types
import { VenueQueryParams } from "../types/retreat";
import { CreateRetreat } from "../types/retreat";
import {
  CancelReservationType,
  FetchChartPayoutTypes,
  fetchPayoutsType,
  fetchReservationTypes,
} from "../types/property-manager";
import { UserQueryParams } from "../types/user";
import {
  PayoutQueryParams,
  ReservationQueryParams,
  RetreatQueryParams,
} from "../types/admin";

const venue_api = createApiInstance(GET_URL.venueApi as string);
const imageAPI = createApiInstance(GET_URL.imageAPI as string);
const retreatAPI = createApiInstance(GET_URL.retreatVenueApi as string);
const paymentAPI = createApiInstance(GET_URL.paymentApi as string);
const usersAPI = createApiInstance(GET_URL.usersApi as string);

// TODO : Remove below endpoints before deployment
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localVenueAPI = createApiInstance(GET_URL.localVenueApi as string);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localRetreatAPI = createApiInstance(GET_URL.localRetreatApi as string);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localImageAPI = createApiInstance(GET_URL.localImageApi as string);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localUserAPI = createApiInstance(GET_URL.localUserApi as string);

// fetch all venues
export const fetchVenues = async ({
  limit = 10,
  offset = 0,
  is_draft,
  approval_status,
  is_deleted,
  is_active,
}: {
  limit?: number;
  offset?: number;
  is_draft?: string;
  approval_status?: string;
  is_deleted?: string;
  is_active?: string;
}) => {
  const queryParam = makeQueryParams({
    limit,
    offset,
    is_draft,
    approval_status,
    is_deleted,
    is_active,
  });

  const res = await venue_api.get(`/venue${queryParam}`);
  return res;
};

export const getOwnerVenues = async ({
  limit = 10,
  offset = 0,
  is_draft,
  approval_status,
  is_deleted,
  is_active,
  ownerId,
}: {
  limit?: number;
  offset?: number;
  is_draft?: string;
  approval_status?: string;
  is_deleted?: string;
  is_active?: string;
  ownerId?: string;
}) => {
  const queryParam = makeQueryParams({
    limit,
    offset,
    is_draft,
    approval_status,
    is_deleted,
    is_active,
  });

  const res = await venue_api.get(`/venues${queryParam}`);
  return res;
};

// fetch single venue
export const fetchVenue = async (id: string) => {
  const res = await venue_api.get(`/${id}/venue`);
  return res;
};

// create new venue
export const createVenue = async (data: any) => {
  const res = await venue_api.post(`/venue`, data);
  return res;
};

// update venue
export const updateVenue = async ({
  venue_id,
  data,
}: {
  venue_id?: string;
  data: any;
}) => {
  const res = await venue_api.patch(
    `/venue${venue_id ? `?venue_id=${venue_id}` : ""}`,
    data
  );
  return res;
};

// fetch property types for create venue of property manager
export const fetchPropertyTypes = async () => {
  const res = await venue_api.get("/property-type");
  return res;
};

// fetch retreats host types for create venue of property manager
export const fetchRetreatsHostTypes = async () => {
  const res = await venue_api.get("/retreat-host");
  return res;
};

// fetch retreats types for create venue of property manager
export const fetchHighlights = async () => {
  const res = await venue_api.get("/venue-highlights");
  return res;
};

// fetch languages for create venue of property manager
export const fetchLanguages = async () => {
  const res = await venue_api.get("/languages");
  return res;
};

// fetch icons
export const fetchAllIcons = async () => {
  const res = await imageAPI.get("/icons");
  return res;
};

// upload images
export const uploadImage = async (data: any) => {
  const res = await imageAPI.post(`/images/upload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

// create venue
export const fetchRetreatVenue = async (id: string) => {
  const res = await retreatAPI.get(`/retreat/${id}`);
  return res;
};

// Create room
export const createRoom = async ({
  venue_id,
  data,
}: {
  venue_id: string;
  data: any;
}) => {
  const res = await venue_api.post(`/${venue_id}/rooms`, data);
  return res;
};

export const fetchAmenities = async () => {
  const res = await venue_api.get("/amenities");
  return res;
};

export const fetchRoomAmenities = async () => {
  const res = await venue_api.get("/rooms-amenities");
  return res;
};

export const fetchCommonSpaceAmenities = async () => {
  const res = await venue_api.get("/common-spaces/amenities");
  return res;
};

// get bed types
export const fetchBedTypes = async () => {
  const res = await venue_api.get("/beds");
  return res;
};

// fetch room details with venue id
export const fetchRoomDetails = async (venue_id: string) => {
  const res = await venue_api.get(`/${venue_id}/spaces`);
  return res;
};

// get room images
export const fetchReferenceImages = async ({
  reference_id,
}: {
  reference_id: string;
}) => {
  const res = await imageAPI.get(`/images/${reference_id}`);
  return res;
};

// post venue amenities
export const postVenueAmenities = async ({
  venue_id,
  data,
}: {
  venue_id: string;
  data: string[];
}) => {
  const res = await venue_api.post(`/${venue_id}/venue-amenities`, data);
  return res;
};

// updated amenities
export const updateAmenities = async ({
  venue_id,
  data,
}: {
  venue_id: string;
  data: string[];
}) => {
  const res = await venue_api.patch(`/${venue_id}/amenity`, data);
  return res;
};

// fetch venue amenities
export const fetchVenueAmenities = async (venue_id: string) => {
  const res = await venue_api.get(`/${venue_id}/amenities`);
  return res;
};

// fetch add on services of venue
export const fetchAddOnServices = async (venue_id: string) => {
  const res = await venue_api.get(`/${venue_id}/add-on-services`);
  return res;
};

// post add on services
export const postAddOnServices = async ({
  venue_id,
  data,
}: {
  venue_id: string;
  data: any;
}) => {
  const res = await venue_api.post(`/${venue_id}/venue-add-on-service`, data);
  return res;
};

// post booking terms
export const postBookingTerms = async ({
  venue_id,
  data,
}: {
  venue_id: string;
  data: any;
}) => {
  const res = await venue_api.post(`/venue/${venue_id}/booking-term`, data);
  return res;
};

// fetch booking terms
export const fetchBookingTerms = async (venue_id: string) => {
  const res = await venue_api.get(`/venue/${venue_id}/booking-term`);
  return res;
};

// post availability and pricing
export const postAvailabilityAndPricing = async ({
  venue_id,
  data,
}: {
  venue_id: string;
  data: any;
}) => {
  const res = await venue_api.post(`/${venue_id}/price-availability`, data);
  return res;
};

//get all addon service
export const allAddonService = async (id: string) => {
  const res = await venue_api.get(`/${id}/add-on-services`);
  return res;
};
//get all venue highlights
export const venueHighlights = async () => {
  try {
    const res = await venue_api.get("/venue-highlights");
    return res;
  } catch (err) {
    throw err;
  }
};

// get venue based on query parmas

export const highlightedVenue = async (queryParamsObj: VenueQueryParams) => {
  try {
    const res = await venue_api.get(`venue/${makeQueryParams(queryParamsObj)}`);
    return res;
  } catch (err) {
    throw err;
  }
};

// delete room
export const deleteRoom = async ({
  space_id,
  space_type,
}: {
  space_id: string;
  space_type: string;
}) => {
  const res = await venue_api.delete(
    `/${space_id}/space?space_type=${space_type}`
  );
  return res;
};

//get all rooms
export const allRooms = async (id: string) => {
  const res = await venue_api.get(`/${id}/rooms`);
  return res;
};

//create retreat
export const createRetreatAPI = async (data: CreateRetreat) => {
  const res = await retreatAPI.post(`/reservation`, data);
  return res;
};

//get retreat detail

export const handleRetreatStep = async (id: string) => {
  const res = await retreatAPI.get(`/reservation/${id}`);
  return res;
};

// upload venue images
export const uploadVenueImage = async ({
  reference_id,
  data,
}: {
  reference_id: string;
  data: string[];
}) => {
  const res = await imageAPI.post(`/images/${reference_id}`, data);

  return res;
};

// delete add on service
export const deleteAddOnService = async ({ id }: { id: string }) => {
  const res = await venue_api.delete(`/${id}/add-on-service`);
  return res;
};

// fetch month availability
export const fetchMonthAvailability = async ({
  venue_id,
  month,
}: {
  venue_id: string;
  month: string;
}) => {
  const res = await venue_api.get(
    `/${venue_id}/price-availability?month=${month}`
  );
  return res;
};
//get all reservation by host id and query params
export const getAllRetreat = async (
  id: string,
  queryParamsObj: VenueQueryParams
) => {
  const res = await retreatAPI.get(
    `/reservation/${id}/host/${makeQueryParams(queryParamsObj)}`
  );
  return res;
};

// fetch create venue options data
export const fetchCreateVenueOptionsData = async () => {
  const res = await venue_api.get("/venue-data");
  return res;
};

// update space details
export const updateSpaceDetails = async ({
  space_id,
  data,
}: {
  space_id: string;
  data: any;
}) => {
  const res = await venue_api.patch(`/spaces/${space_id}`, data);
  return res;
};

// get upcoming reservation request
export const getUpcomingReservationRequest = async ({
  check_in_date,
  check_out_date,
  group_size,
  limit,
  offset = 0,
  approval_status,
  start_date,
  end_date,
  venue_id,
  min_amount,
  max_amount,
  property_name,
  reservation_date,
  is_cancelled,
  is_completed,
  host_id,
}: fetchReservationTypes) => {
  const queryParam = makeQueryParams({
    check_in_date,
    check_out_date,
    group_size,
    limit,
    offset,
    approval_status,
    start_date,
    end_date,
    venue_id,
    min_amount,
    max_amount,
    property_name,
    reservation_date,
    is_cancelled,
    is_completed,
    host_id,
  });
  const res = await retreatAPI.get(`/reservations/owner${queryParam}`);
  return res;
};

// get reservation request details
export const getReservationRequestDetails = async (reservationId: string) => {
  const res = await retreatAPI.get(`/${reservationId}/reservation`);
  return res;
};

// approve reservation request
export const approveReservationRequest = async ({
  reservationId,
  data,
}: {
  reservationId: string;
  data: any;
}) => {
  const res = await retreatAPI.patch(`/${reservationId}/approve`, data);
  return res;
};

export const deleteBulkImages = async (data: any) => {
  const res = await imageAPI.delete(`/images/delete-bulk-images`, {
    data: data,
  });
  return res;
};

// fetch payouts
export const fetchPayouts = async ({
  status,
  start_date,
  end_date,
  starting_after,
  ending_before,
  limit = 4,
  offset,
}: fetchPayoutsType) => {
  const queryParam = makeQueryParams({
    status,
    start_date,
    end_date,
    starting_after,
    ending_before,
    limit,
    offset,
  });
  const res = await paymentAPI.get(`/venue/manager/payouts${queryParam}`);
  return res;
};

// fetch payout details
export const fetchPayoutDetails = async ({
  payout_id,
}: {
  payout_id: string;
}) => {
  const res = await paymentAPI.get(`/venue/manager/${payout_id}/payout`);
  return res;
};

// Create tax
export const createTax = async ({
  data,
  venueId,
}: {
  data: any;
  venueId: string;
}) => {
  const res = await venue_api.post(`/${venueId}/taxes`, data);
  return res;
};

// update tax
export const updateTax = async ({
  data,
  taxId,
}: {
  data: any;
  taxId: string;
}) => {
  const res = await venue_api.patch(`/${taxId}/taxes`, data);
  return res;
};

// deleteTax
export const deleteTax = async ({ taxId }: { taxId: string }) => {
  const res = await venue_api.delete(`/${taxId}/taxes`);
  return res;
};

// get taxes
export const fetchTax = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.get(`/${venueId}/taxes`);
  return res;
};

// create discount code
export const createDiscount = async ({
  data,
  venueId,
}: {
  data: {
    discount_code: string;
    discount_type: string;
    discount_for: string;
    discount_value: number | null;
    expiry_date: string;
    is_public: boolean;
  };
  venueId: string;
}) => {
  const res = await venue_api.post(`/${venueId}/discount-code`, data);
  return res;
};

// get discount codes
export const getDiscountCodes = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.get(`/${venueId}/discount-codes`);
  return res;
};

// update discount code
export const updateDiscountCode = async ({
  discountCodeId,
  data,
}: {
  discountCodeId: string;
  data: any;
}) => {
  const res = await venue_api.patch(`/${discountCodeId}/discount-code`, data);
  return res;
};

// delete discount code
export const deleteDiscountCode = async ({
  discountCodeId,
}: {
  discountCodeId: string;
}) => {
  const res = await venue_api.delete(`/${discountCodeId}/discount-code`);
  return res;
};

// fetch home statistic
export const fetchHomeStatistic = async () => {
  const res = await venue_api.get(`/home-statistics`);
  return res;
};

// fetch reservation statistic
export const fetchReservationStatistic = async () => {
  const res = await retreatAPI.get(`/reservation/statistics`);
  return res;
};

// fetch chart details
export const fetchChartDetails = async ({
  start_date,
  end_date,
}: {
  start_date?: string;
  end_date?: string;
}) => {
  const queryParam = makeQueryParams({
    start_date,
    end_date,
  });
  const res = await paymentAPI.get(`/home/total/revenue${queryParam}`);
  return res;
};

// duplicate venue for property manager
export const duplicateVenue = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.post(`/${venueId}/duplicate`);
  return res;
};

// delete venue for property manager
export const deleteVenue = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.delete(`/${venueId}/delete-venue`);
  return res;
};

// deactivate venue for property manager
export const deactivateVenue = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.post(`/${venueId}/venue-deactivate-request`);
  return res;
};

// ==============================================================================
// ADMIN SECTION START
// ==============================================================================
export const adminDashboardStatistics = async () => {
  // TODO : Need to remove local venue api services
  const res = await venue_api.get(`/admin/statistics`);
  return res;
};

export const adminVenueList = async ({
  queryParamsObj,
}: {
  queryParamsObj: VenueQueryParams;
}) => {
  const res = await venue_api.get(`/venue${makeQueryParams(queryParamsObj)}`);
  return res;
};

export const adminVenueApproval = async (venueId: string, data: any) => {
  const res = await venue_api.patch(`/admin/${venueId}/approve-venue`, data);
  return res;
};

export const adminVenueDeactivateApproval = async (venueId: string) => {
  const res = await venue_api.patch(`/admin/${venueId}/venue-deactivate`);
  return res;
};

// Fetch list of All request for Admin
export const adminRequestList = async ({
  queryParamsObj,
}: {
  queryParamsObj: VenueQueryParams;
}) => {
  const res = await venue_api.get(
    `/admin/admin-requests${makeQueryParams(queryParamsObj)}`
  );
  return res;
};

// Admin Users APIs
export const usersList = async ({
  queryParamsObj,
}: {
  queryParamsObj: UserQueryParams;
}) => {
  const res = await usersAPI.get(
    `/admin/user/list${makeQueryParams(queryParamsObj)}`
  );
  return res;
};

export const userDetailById = async (userId: string) => {
  const res = await usersAPI.get(`admin/user/${userId}/profile`);
  return res;
};

export const adminUserDeactivateApproval = async (userId: string) => {
  // Below should be changed when server is updated
  const res = await usersAPI.patch(`/admin/${userId}/user-deactivate`);
  return res;
};

// Admin Payouts APIs
export const adminPayoutsList = async ({
  queryParamsObj,
}: {
  queryParamsObj: PayoutQueryParams;
}) => {
  const res = await paymentAPI.get(
    `/payment/admin/payouts${makeQueryParams(queryParamsObj)}`
  );
  return res;
};

export const adminPayoutsDetailById = async (payoutId: string) => {
  const res = await paymentAPI.get(`payment/admin/${payoutId}/payout`);
  return res;
};

// Reservations
export const adminReservationList = async ({
  queryParamsObj,
}: {
  queryParamsObj: ReservationQueryParams;
}) => {
  const res = await retreatAPI.get(
    `/admin/reservation${makeQueryParams(queryParamsObj)}`
  );
  return res;
};

// Retreat
export const adminRetreatList = async ({
  queryParamsObj,
}: {
  queryParamsObj: RetreatQueryParams;
}) => {
  const res = await retreatAPI.get(
    `/admin/retreats${makeQueryParams(queryParamsObj)}`
  );
  return res;
};

// ==============================================================================
// ADMIN SECTION END
// ==============================================================================

// ==============================================================================
// AUTHENTICATION SECTION START
// ==============================================================================
// Get User Profile Data
export const userProfileData = async () => {
  const res = await usersAPI.get(`/user/profile/details`);
  return res;
};

// Create User Profile
export const createUserProfile = async ({ token }: { token: string }) => {
  const data: any = { token: token };
  const res = await usersAPI.post(`/user/profile`, data);
  return res;
};

// ==============================================================================
// AUTHENTICATION SECTION END
// ==============================================================================

export const fetchDocumentsByRefId = async (refId: string) => {
  const res = await imageAPI.get(`/${refId}/documents`);
  return res;
};

//edit detail of user
export const editDetailById = async (data: any) => {
  const res = await usersAPI.patch(`/user/profile`, data);
  return res;
};

//delete user account
export const deleteUserAccount = async (reason: string) => {
  const res = await usersAPI.post(`/user/delete-request`, { reason: reason });
  return res;
};

//get user venue
export const getVenueByUserId = async () => {
  const res = await venue_api.get(`/venues`);
  return res;
};

//add document
export const uploadDocument = async (file: any) => {
  const res = await imageAPI.post(`/documents`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getDocumentById = async (id: string) => {
  const res = await imageAPI.get(`/${id}/documents`);
  return res;
};

export const cancelReservation = async ({
  reservationId,
  data,
}: {
  reservationId: string;
  data: { reason: string; message_to_retreat_host: string };
}) => {
  const res = await retreatAPI.patch(`/${reservationId}/cancel-request`, data);
  return res;
};

// create payment checkout session
export const createCheckoutSession = async ({
  data,
}: {
  data: CancelReservationType;
}) => {
  const res = await paymentAPI.post(`/penalty/venue-manager`, data);
  return res;
};

export const fetchTaxDiscount = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.get(`/${venueId}/taxes-discount-codes`);
  return res;
};

// get venue statistics
export const getVenueStatistics = async ({ venueId }: { venueId: string }) => {
  const res = await venue_api.get(`/venue/${venueId}/statistics`);
  return res;
};

// get venue resrevation statistics
export const getVenueReservationStatistics = async ({
  venueId,
}: {
  venueId: string;
}) => {
  const res = await retreatAPI.get(`/reservation/venue/${venueId}/statistics`);
  return res;
};

// get venue chart details
export const getVenueChartDetails = async ({
  start_date,
  end_date,
  venueId,
}: {
  start_date?: string;
  end_date?: string;
  venueId?: string;
}) => {
  const queryParam = makeQueryParams({
    start_date,
    end_date,
  });
  const res = await paymentAPI.get(
    `/home/${venueId}/edit-overview/statistics${queryParam}`
  );
  return res;
};

// get list of all venues name
export const getVenuesList = async () => {
  const res = await venue_api.get(`/all-venues`);
  return res;
};

export const getChartAndPayouts = async ({
  stats_start_date,
  stats_end_date,
  payout_start_date,
  payout_end_date,
  status,
  offset,
  limit,
}: FetchChartPayoutTypes) => {
  const queryParam = makeQueryParams({
    stats_start_date,
    stats_end_date,
    payout_start_date,
    payout_end_date,
    status,
    offset,
    limit,
  });
  const res = await paymentAPI.get(`/home/payouts/stats${queryParam}`);
  return res;
};

// update add on service
export const updateAddOnService = async ({
  id,
  data,
}: {
  id: string;
  data: any;
}) => {
  const res = await venue_api.patch(`/${id}/add-on-service`, data);
  return res;
};

export const createStripeAccount = async () => {
  const res = await paymentAPI.get("/venue/manager/stripe/account");
  return res;
};

export const editDocumentByID = async (id: string, data: any) => {
  const res = await imageAPI.patch(`/${id}/documents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const deleteDocumentByID = async (id: string) => {
  const res = await imageAPI.delete(`/${id}/documents`);
  return res;
};

// publish venue
export const publishVenue = async (venueId: string) => {
  const res = await venue_api.post(`/${venueId}/publish`);
  return res;
};

// get pending document venues
export const getPendingDocumentVenues = async () => {
  const res = await venue_api.get(`/admin/pending-approval-venues`);
  return res;
};
