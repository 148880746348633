import React from "react";
import VenueList from "../../../components/core/PropertyManager/VenueList";

const MyVenues: React.FC = () => {
  return (
    <div>
      <VenueList />
    </div>
  );
};

export default MyVenues;
