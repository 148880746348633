import React from 'react'

const HostMessage = () => {
  return (
    <div>
        This is an message section.      
    </div>
  )
}

export default HostMessage
