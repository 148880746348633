/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

// third party
import {
  onValue,
  ref,
  push,
  runTransaction,
  update,
  query,
  orderByChild,
  equalTo,
  get,
} from "firebase/database";
import { db } from "../../../network/firebaseConfig";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";

// custom component
import MessageInput from "./MessageInput";
import Loader from "../../UI/Loader";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";

// Constants
import { userRoles } from "../../../constants";

// types
import { MessageType, FirebaseUser } from "../../../types";

// styles
import "../../../assets/css/message.css";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setPropertyId } from "../../../redux/features/retreatVenue";

const AdminMessage: React.FC = () => {
  const dispatch = useDispatch();
  const { propertyId } = useSelector((state: RootState) => state.retreatVenue);

  const [conversations, setConversations] = useState<MessageType>({});

  const [users, setUsers] = useState<FirebaseUser[]>([]);
  const [rawUsers, setRawUsers] = useState<FirebaseUser[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [chatsLoading, setChatsLoading] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<FirebaseUser | null>(null);

  const [lastMessages, setLastMessages] = useState<{
    [key: string]: {
      text: string;
      sender: string;
      timestamp: number;
      seen: boolean;
    };
  }>({});

  const [unseenCounts, setUnseenCounts] = useState<{
    [key: string]: { count: number; last_message_timestamp: any };
  }>({});

  const [isChatWindowActive, setIsChatWindowActive] = useState<{
    [key: string]: boolean;
  }>({});

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const handleSendMessage = (text: string) => {
    const chatId = [userProfileData.user_id, selectedUser?.uid]
      .sort()
      .join("_");

    const messageRef = ref(db, `Chats/${chatId}/messages`);
    const message = {
      text: text,
      sender: userProfileData.user_id || "",
      timestamp: Date.now(),
      seen: false,
    };

    push(messageRef, message);

    runTransaction(
      ref(db, `Chats/${chatId}/unseenCount/${selectedUser?.uid}`),
      (currentCount) => {
        return {
          last_message_timestamp: Date.now(),
          count: 0,
        };
      }
    );

    incrementUnseenCount(chatId, userProfileData.user_id || "");
  };

  const incrementUnseenCount = (chatId: string, recipientId: string) => {
    const unseenCountRef = ref(
      db,
      `Chats/${chatId}/unseenCount/${recipientId}`
    );

    runTransaction(unseenCountRef, (currentCount) => {
      return {
        count: (currentCount?.count || 0) + 1,
        last_message_timestamp: Date.now(),
      };
    });
  };

  const markMessagesAsSeen = (chatId: string, currentUserId: string) => {
    const messagesRef = ref(db, `Chats/${chatId}/messages`);

    onValue(messagesRef, (messageDataSnapshot) => {
      const data = messageDataSnapshot.val();
      if (data) {
        const updates: { [key: string]: any } = {};

        Object.keys(data).forEach((key) => {
          if (!data[key].seen && data[key].sender !== currentUserId) {
            updates[`Chats/${chatId}/messages/${key}/seen`] = true;
          }
        });

        const unseenCountRef = ref(
          db,
          `Chats/${chatId}/unseenCount/${currentUserId}`
        );

        onValue(unseenCountRef, (unseenCountDataSpanshot) => {
          const unseenCount = unseenCountDataSpanshot.val();
          updates[`Chats/${chatId}/unseenCount/${currentUserId}`] = {
            ...unseenCount,
            count: 0,
          };

          update(ref(db), updates);
        });
      }
    });
  };

  const fetchLastMessagesAndUnseenCounts = (users: FirebaseUser[]) => {
    const userUnseenCounts: { [key: string]: number } = {};

    users.forEach((user) => {
      const chatId = [userProfileData.user_id, user.uid].sort().join("_");
      const messagesRef = ref(db, `Chats/${chatId}/messages`);
      const unseenCountRef = ref(
        db,
        `Chats/${chatId}/unseenCount/${userProfileData.user_id}`
      );

      // Fetch unseen counts
      onValue(unseenCountRef, (snapshot) => {
        const unseenCount = snapshot.val();
        userUnseenCounts[user.uid] = unseenCount || 0;

        // Update the unseen counts state
        setUnseenCounts((prevState) => ({
          ...prevState,
          [user.uid]: unseenCount,
        }));
      });

      // Fetch last message
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messages = Object.keys(data).map((key) => data[key]);
          const lastMessage = messages[messages.length - 1];

          // Update last message state incrementally
          setLastMessages((prevState) => ({
            ...prevState,
            [user.uid]: lastMessage,
          }));
        }
      });
    });
  };

  // Handle search with debounce
  const handleSearch = useCallback(
    debounce((value: string, rawUsers: FirebaseUser[]) => {
      const filteredRawUsers = rawUsers.reduce((acc, user) => {
        const duplicate = acc.find((item) => item.uid === user.uid);
        if (!duplicate) {
          acc.push(user);
        }
        return acc;
      }, [] as FirebaseUser[]);

      if (!value) {
        setUsers(filteredRawUsers);
      } else {
        const filteredUsers = filteredRawUsers.filter((user) => {
          return (
            user?.name?.toLowerCase().includes(value.toLowerCase()) ||
            user?.email?.toLowerCase().includes(value.toLowerCase())
          );
        });
        setUsers(filteredUsers);
      }
      setChatsLoading(false);
    }, 700),
    []
  );

  const fetchUserData = () => {
    setLoading(true);

    const usersRef = ref(db, "users/");

    // Define queries for managers and hosts
    const managerUsersQuery = query(
      usersRef,
      orderByChild("userRole"),
      equalTo(userRoles.VENUE_MANAGER)
    );
    const hostUsersQuery = query(
      usersRef,
      orderByChild("userRole"),
      equalTo(userRoles.RETREAT_HOST)
    );

    Promise.all([get(managerUsersQuery), get(hostUsersQuery)])
      .then(([managerSnapshot, hostSnapshot]) => {
        let fetchedUsers: Record<string, Omit<FirebaseUser, "uid">> = {};
        if (managerSnapshot.exists())
          fetchedUsers = { ...fetchedUsers, ...managerSnapshot.val() };
        if (hostSnapshot.exists())
          fetchedUsers = { ...fetchedUsers, ...hostSnapshot.val() };

        if (Object.keys(fetchedUsers).length) {
          const usersData = Object.keys(fetchedUsers).map((key) => ({
            uid: key,
            ...fetchedUsers[key],
          })) as FirebaseUser[];

          // Process each user's chat data with real-time updates
          const usersWithChats: any[] = [];
          usersData.forEach((user) => {
            const chatId = [userProfileData.user_id, user.uid].sort().join("_");
            const messagesRef = ref(db, `Chats/${chatId}/messages`);
            const unseenCountRef = ref(
              db,
              `Chats/${chatId}/unseenCount/${user.uid}`
            );

            // Set up real-time listeners for messages and unseen count
            onValue(messagesRef, (messagesSnapshot) => {
              if (messagesSnapshot.exists()) {
                const messages = Object.values(messagesSnapshot.val());
                const lastMessage = messages[messages.length - 1] || {};

                onValue(unseenCountRef, (unseenCountSnapshot) => {
                  const unseenCount = unseenCountSnapshot.val()?.count || 0;
                  const lastMessageTimestamp =
                    unseenCountSnapshot.val()?.last_message_timestamp || 0;

                  const userWithChatData = {
                    ...user,
                    count: unseenCount,
                    lastMessage,
                    lastMessageTimestamp,
                  };

                  // Update usersWithChats with the latest data for this user
                  const existingUserIndex = usersWithChats.findIndex(
                    (u) => u.uid === user.uid
                  );
                  if (existingUserIndex !== -1) {
                    usersWithChats[existingUserIndex] = userWithChatData;
                  } else {
                    usersWithChats.push(userWithChatData);
                  }

                  // Sort users by last message timestamp and update state
                  const sortedUsers = [...usersWithChats].sort(
                    (a, b) => b?.lastMessageTimestamp - a?.lastMessageTimestamp
                  );

                  const initialActiveState = sortedUsers.reduce((acc, user) => {
                    return { ...acc, [user.uid]: false };
                  }, {});

                  if (!isChatWindowActive && sortedUsers.length > 0) {
                    setIsChatWindowActive({
                      ...initialActiveState,
                      [propertyId || selectedUser?.uid || sortedUsers[0].uid]:
                        true,
                    });
                  }

                  const findPropertyIdUser = sortedUsers.find(
                    (user) => user.uid === propertyId
                  );

                  dispatch(setPropertyId(""));
                  setSelectedUser(findPropertyIdUser || sortedUsers[0]);
                  setUsers(sortedUsers);
                  setRawUsers(sortedUsers);
                  setChatsLoading(false);
                });
              }
            });
          });
        }
      })
      .catch((error) => {
        setChatsLoading(false);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!users.length) {
      return setLoading(false);
    }

    const chatId = [userProfileData.user_id, users[0].uid].sort().join("_");

    const messagesRef = ref(db, `Chats/${chatId}/messages`);
    onValue(messagesRef, (snapshot) => {
      const messageData = snapshot.val();
      if (messageData) {
        const messageArray = Object.keys(messageData).map(
          (key) => messageData[key]
        );
        propertyId
          ? setConversations({
              [propertyId as string]: messageArray,
            } as MessageType)
          : setConversations({
              [users[0].uid as string]: messageArray,
            } as MessageType);
      }
      setLoading(false);
    });
    fetchLastMessagesAndUnseenCounts(users);
  }, [users]);

  const fetchMessageForSelectedUser = (user: FirebaseUser | null) => {
    setSelectedUser(user);
    if (!user) return;

    const chatId = [userProfileData.user_id, user?.uid].sort().join("_");
    const messagesRef = ref(db, `Chats/${chatId}/messages`);

    // Fetch messages for the selected user
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageArray = Object.keys(data).map((key) => data[key]);
        setConversations({
          [user?.uid as string]: messageArray,
        } as MessageType);
      }
    });

    if (isChatWindowActive[user.uid]) {
      markMessagesAsSeen(chatId, user.uid || "");
    }
  };

  useEffect(() => {
    fetchMessageForSelectedUser(selectedUser);
  }, [selectedUser]);

  return (
    <>
      <div className="chat-app">
        <div className="chat-sidebar">
          <ChatList
            newUsersList={users}
            usersList={users}
            selectedChatUserId={selectedUser?.uid}
            setSelectedUser={setSelectedUser}
            lastMessages={lastMessages}
            unseenCounts={unseenCounts}
            onFocus={setIsChatWindowActive}
            onSearch={(value) => {
              setChatsLoading(true);
              handleSearch(value, rawUsers);
            }}
            chatsLoading={chatsLoading}
            fetchMessageForSelectedUser={fetchMessageForSelectedUser}
          />
        </div>
        <div className="chat-content">
          {loading ? (
            <Loader />
          ) : selectedUser ? (
            <>
              <ChatWindow
                selectedUser={selectedUser}
                messages={conversations}
              />
              <MessageInput onSend={handleSendMessage} />
            </>
          ) : (
            <div className="chat-placeholder">
              <h3>No user selected</h3>
            </div>
          )}
        </div>
        {/* NOTES : This might be useful in FUTURE */}
        {/* <div className="message-venue-detail-sidebar">
          {reservationRequestDetailsLoader ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              {" "}
              <Spin />
            </div>
          ) : (
            <UserVenueDetails reservationDetails={[]} />
          )}
        </div> */}
      </div>
    </>
  );
};

export default AdminMessage;
