import React from "react";
import RetreatListing from "../../../components/core/Admin/Retreats/RetreatListing";

const AdminRetreatList: React.FC = () => {
  return (
    <div>
      <RetreatListing />
    </div>
  );
};

export default AdminRetreatList;
