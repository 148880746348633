import React, { useEffect } from "react";

// Third Party
import { Navigate } from "react-router-dom";

// custom components

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Home: React.FC = () => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  useEffect(() => {
    if (!userProfileData)
      window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  }, [userProfileData]);

  const getNavigateRoute = () => {
    switch (userProfileData.user_type) {
      case "venue_manager":
        return "/venues/home";
      case "admin":
        return "/admin/dashboard";
      case "retreat_host":
        return "/host/dashboard";
      // TODO : Need to change below path, once retreat page is created
      case "retreat_guest":
        return "/venues/home";
      default:
        return "/venues/home";
    }
  };

  return <Navigate to={getNavigateRoute()} />;
};

export default Home;
