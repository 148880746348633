/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// third party
import { DatePicker, Skeleton, Typography } from "antd";
import { format, getDay, parse, startOfWeek } from "date-fns";
import enUS from "date-fns/locale/en-US";
import dayjs, { Dayjs } from "dayjs";
import {
  Event as BigCalendarEvent,
  Calendar,
  dateFnsLocalizer,
  SlotInfo,
} from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom component
import CreateVenueFooter from "../CreateVenueFooter";
import CalendarSettings from "./CalendarSettings";
import IndividualDateAvailabilitySidebar from "./IndividualDateAvailabilitySidebar";
import SmallText from "../../../../UI/SmallText";

// network
import {
  fetchMonthAvailability,
  postAvailabilityAndPricing,
} from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// helpers
import { getDatesForCurrentMonth } from "../../../../../library";

// images
import { images } from "../../../../../assets/images";

// styles
import "react-big-calendar/lib/css/react-big-calendar.css";

const { Text } = Typography;

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface CalendarEvent extends BigCalendarEvent {
  title: React.ReactNode | string;
  start: Date;
  end: Date;
  allDay: boolean;
  availability?: string;
  price?: number;
}

const AvailabilityCalender: React.FC<{
  venueId: string;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}> = ({ venueId, handleNextStep, handlePreviousStep }) => {
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(dayjs().add(3, "month"));
  const [basePrice, setBasePrice] = useState<number>();
  const [defaultRate, setDefaultRate] = useState<{ [key: string]: number }>({});
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [calendarStatus, setCalendarStatus] = useState<{
    [key: string]: string;
  }>({
    [dayjs().add(3, "month").format("YYYY-MM")]: "open",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );
  const [closureDate, setClosureDate] = useState<string[]>([]);

  const [individualDateAvailability, setIndividualDateAvailability] = useState<{
    [key: string]: number;
  }>({});

  const [dayPriceId, setDayPriceId] = useState<{
    [key: string]: string | null;
  }>({});

  const [monthPriceData, setMonthPriceData] = useState<{
    [key: string]: number;
  }>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [acceptReservations, setAcceptReservations] = useState<{
    [key: string]: boolean;
  }>({});

  const [defaultBasePrice, setDefaultBasePrice] = useState<number>(0);

  const [advanceBooking, setAdvanceBooking] = useState<{
    value: number;
    timeFormat: "month" | "day";
  }>({
    value: 3,
    timeFormat: "month",
  });

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const { call } = useApiCall();

  const handleMonthChange = (date: Dayjs | null) => {
    if (date) {
      setCurrentMonth(date.startOf("month"));

      const monthKey = date.format("YYYY-MM");
      const status = calendarStatus[monthKey] || "open";

      if (status !== "closed") {
        setCalendarStatus((prevStatus) => ({
          ...prevStatus,
          [monthKey]: "open",
        }));

        onAvailabilityChange("open");
      }

      setIsSidebarOpen(false);
    }
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
    setIsSidebarOpen(false);
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
    setIsSidebarOpen(false);
  };

  const onAvailabilityChange = (availability: string) => {
    if (availability === "open") {
      handleOpenCalendar(
        defaultRate[currentMonth.format("YYYY-MM")] || 0,
        currentMonth.format("YYYY-MM")
      );
    } else if (availability === "closed") {
      handleClosedCalendar();
    }
  };

  const handleOpenCalendar = (
    rate: number,
    month: string,
    individualDateAvailability?: { [key: string]: number },
    closureDate?: string[]
  ) => {
    const currentMonthDates = getDatesForCurrentMonth(currentMonth);
    const updatedEvents: CalendarEvent[] = currentMonthDates.map((date) => {
      const dateKey = dayjs(date).format("YYYY-MM-DD");
      const price =
        monthPriceData[dateKey] !== undefined
          ? monthPriceData[dateKey]
          : rate || defaultBasePrice;

      if (closureDate?.includes(dateKey)) {
        return {
          title: "Closed",
          start: date,
          end: date,
          allDay: true,
          availability: "closed",
          price,
        };
      } else if (
        individualDateAvailability &&
        individualDateAvailability[dateKey] !== undefined
      ) {
        return {
          title: `$${individualDateAvailability[dateKey]}`,
          start: date,
          end: date,
          allDay: true,
          availability: "open",
          price: individualDateAvailability[dateKey],
        };
      }
      return {
        title:
          price === 0 ? (
            ""
          ) : (
            <Text
              style={{ width: 84 }}
              className="calendar-title-text"
              ellipsis={{ tooltip: price }}
            >{`$${price}`}</Text>
          ),
        start: date,
        end: date,
        allDay: true,
        availability: "open",
        price,
      };
    });

    setEvents(updatedEvents);
    setDefaultRate((prev) => ({
      ...prev,
      [month]: rate,
    }));
    setCalendarStatus((prevStatus) => ({
      ...prevStatus,
      [currentMonth.format("YYYY-MM")]: "open",
    }));
  };

  const handleClosedCalendar = () => {
    const currentMonthDates = getDatesForCurrentMonth(currentMonth);
    const updatedEvents: CalendarEvent[] = currentMonthDates.map((date) => ({
      title: "Closed",
      start: date,
      end: date,
      allDay: true,
      availability: "closed",
      price: 0,
    }));
    setEvents(updatedEvents);

    setCalendarStatus((prevStatus) => ({
      ...prevStatus,
      [currentMonth.format("YYYY-MM")]: "closed",
    }));
  };

  const handleSelectDate = (slotInfo: SlotInfo) => {
    const isSameDateSelected =
      selectedDate && dayjs(slotInfo.start).isSame(dayjs(selectedDate), "day");

    if (isSameDateSelected) {
      // If the same date is clicked again, unselect it
      setSelectedDate(null);
      setSelectedEvent(null);
      setIsSidebarOpen(false); // Close the sidebar if the date is unselected
    } else if (
      dayjs().subtract(1, "day") < dayjs(slotInfo.start) &&
      dayjs(slotInfo.start).toDate() <= currentMonth.endOf("month").toDate()
    ) {
      // Select the new date
      const selected = events.find(
        (event) => event.start.toDateString() === slotInfo.start.toDateString()
      );

      setSelectedDate(slotInfo.start);
      setSelectedEvent(selected || null);

      // Toggle the sidebar open/close
      setIsSidebarOpen(true);
    }
  };

  // TODO

  const handleSaveForDate = (
    date: Date,
    availability: string,
    price: number
  ) => {
    const dateKey = dayjs(date).format("YYYY-MM-DD");

    if (availability === "closed") {
      setIndividualDateAvailability((prev) => {
        const { [dateKey]: _, ...rest } = prev;
        return rest;
      });
      setClosureDate((prev) => {
        if (!prev.includes(dateKey)) {
          return [...prev, dateKey];
        }
        return prev;
      });
    } else {
      setIndividualDateAvailability((prev) => ({
        ...prev,
        [dateKey]: price,
      }));
      setClosureDate(
        closureDate.filter((closedDate) => closedDate !== dateKey)
      );
    }

    // Update the event list with the new values
    const updatedEvents = events.map((event) =>
      event.start.toDateString() === date.toDateString()
        ? {
            ...event,
            title:
              availability === "closed"
                ? "Closed"
                : `${price === 0 ? "" : `$${price}`}`,
            availability,
            price,
          }
        : event
    );

    setEvents(updatedEvents);
  };

  const dayPropGetter = (date: Date) => {
    const isSelected =
      selectedDate && dayjs(date).isSame(dayjs(selectedDate), "day");

    if (dayjs(date).isBefore(dayjs(), "day")) {
      return {
        className: "disabled-date",
        style: {
          backgroundColor: "#f0f0f0",
          pointerEvents: "none" as const,
        },
      };
    }

    if (isSelected) {
      return {
        className: "selected-date",
      };
    }

    return {};
  };

  const fetchMonthPriceAvailability = async () => {
    setLoading(true);
    call(
      () =>
        fetchMonthAvailability({
          venue_id: venueId,
          month: currentMonth.format("YYYY-MM"),
        }),
      (res) => {
        const monthKey = currentMonth.format("YYYY-MM");
        const monthPrices = res?.data?.data.month_price || 0;
        const dayPrice = res?.data?.data?.day.price || {};
        const closureDates = res?.data?.data?.day.closure_dates || [];
        const fetchedDayPriceId = res?.data?.data?.day.id;
        let fetchedBasePrice = res?.data?.data.base_price;

        setBasePrice(res?.data?.data.base_price || 0);
        setIndividualDateAvailability((prev) => ({
          ...prev,
          ...dayPrice,
        }));

        if (defaultBasePrice === 0) {
          setDefaultBasePrice(fetchedBasePrice);
        }

        fetchedDayPriceId &&
          setDayPriceId((prev) => ({
            ...prev,
            [monthKey]: fetchedDayPriceId,
          }));

        setClosureDate((prev) => [...prev, ...closureDates]);

        // set calendarStatus closed if all the dates of current month are closed
        const currentMonthDates = getDatesForCurrentMonth(currentMonth);

        const fetchedMonthPriceData = {
          ...monthPriceData,
          [monthKey]: monthPrices === 0 ? fetchedBasePrice : monthPrices,
        };

        setMonthPriceData(fetchedMonthPriceData);
        const currentStatus = calendarStatus[monthKey]
          ? calendarStatus[monthKey]
          : closureDates.length !== currentMonthDates.length
          ? "open"
          : "closed";

        if (currentStatus === "open") {
          handleOpenCalendar(
            fetchedMonthPriceData[monthKey] ||
              (defaultBasePrice === monthPrices ||
              fetchedBasePrice ||
              defaultRate[monthKey]
                ? defaultRate[monthKey]
                : defaultBasePrice) ||
              (defaultBasePrice === fetchedBasePrice
                ? fetchedMonthPriceData[monthKey]
                : monthPrices) ||
              (defaultBasePrice !== 0 ? defaultBasePrice : fetchedBasePrice),
            monthKey,
            {
              ...individualDateAvailability,
              ...dayPrice,
            },
            [...closureDate, ...closureDates]
          );
        } else {
          handleClosedCalendar();
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleSave = ({ saveAndExit }: { saveAndExit: boolean }) => {
    if (defaultBasePrice === 0) {
      toast.warning("Please enter Global Default Nightly Price");
      return;
    }

    setSubmitting(true);
    // TODO: Commented for future implementation
    saveAndExit && setDisableSaveButton(true);
    // Group individual date prices by month

    let dayPriceMonths: string[] = [];

    const dayPriceByMonth: { [month: string]: { [date: string]: number } } = {};
    Object.entries(individualDateAvailability).forEach(([date, price]) => {
      const month = dayjs(date).format("YYYY-MM");
      if (!dayPriceByMonth[month]) {
        dayPriceByMonth[month] = {};
      }
      dayPriceByMonth[month][date] = price;
      dayPriceMonths.push(month);
    });

    const filteredDefaultRate = Object.entries(defaultRate)
      .filter(([month, rate]) => {
        const fetchedMonthPrice =
          monthPriceData[month] || basePrice || defaultBasePrice || 0;
        return rate !== fetchedMonthPrice;
      })
      .reduce((acc, [month, rate]) => {
        acc[month] = rate;
        return acc;
      }, {} as { [key: string]: number });

    const monthsWithClosureDates = Object.entries(calendarStatus)
      .filter(([month, status]) => status === "closed")
      .map(([month]) => {
        dayPriceMonths.push(month);
        return month;
      });

    // closure dates for remaining months of closure dates which are not included in dayPriceMonths create object of remaining closure dates by month as key
    let remainingClosureDates: {
      [key: string]: string[];
    } = {};

    closureDate.forEach((date) => {
      const month = dayjs(date).format("YYYY-MM");
      if (!dayPriceMonths.includes(month)) {
        if (!remainingClosureDates[month]) {
          remainingClosureDates[month] = [];
        }
        remainingClosureDates[month].push(date);
      }
    });

    const day_price = [
      ...Object.entries(dayPriceByMonth).map(([month, day_price]) => {
        const isClosed = calendarStatus[month] === "closed";

        return {
          id: dayPriceId[month],
          month,
          day_price,
          reserved_dates: [], // Modify if you have reserved dates to include
          closure_dates: isClosed
            ? getDatesForCurrentMonth(dayjs(month)).map((date) =>
                dayjs(date).format("YYYY-MM-DD")
              ) // All dates of the month for closed months
            : closureDate.filter(
                (date) => dayjs(date).format("YYYY-MM") === month
              ),
        };
      }),

      ...monthsWithClosureDates
        .filter((month) => !dayPriceByMonth[month]) // Filter months that don't already have day prices
        .map((month) => ({
          id: dayPriceId[month],
          month,
          day_price: {}, // No prices for the month
          reserved_dates: [],
          closure_dates: getDatesForCurrentMonth(dayjs(month)).map((date) =>
            dayjs(date).format("YYYY-MM-DD")
          ),
        })),

      ...Object.entries(remainingClosureDates).map(([month, closureDates]) => ({
        id: dayPriceId[month],
        month,
        day_price: {}, // No prices for the month
        reserved_dates: [],
        closure_dates: closureDates,
      })),
    ];

    const dataToSend = {
      start_date: dayjs()
        .add(3, "month")
        .add(advanceBooking.value, advanceBooking.timeFormat)
        .format("YYYY-MM-DD"),
      end_date: dayjs()
        .add(3, "month")
        .add(
          advanceBooking.value + advanceBooking.timeFormat === "day" ? 365 : 12,
          advanceBooking.timeFormat
        )
        .format("YYYY-MM-DD"),
      month_price: filteredDefaultRate, // Object of default rates per month
      day_price, // Array of day_price objects as described
      base_price: defaultBasePrice,
      advance_booking_time: {
        value: advanceBooking.value,
        time_format: advanceBooking.timeFormat,
      },
    };

    call(
      () => postAvailabilityAndPricing({ venue_id: venueId, data: dataToSend }),
      (res) => {
        toast.success("Pricing and availability saved successfully");
        setSubmitting(false);
        saveAndExit ? navigate("/venues/my-venues") : handleNextStep();

        saveAndExit && setDisableSaveButton(false);
      },
      (err) => {
        toast.error(
          err?.data?.message || "Failed to save pricing and availability"
        );
        setSubmitting(false);

        saveAndExit && setDisableSaveButton(false);
      }
    );
  };

  useEffect(() => {
    fetchMonthPriceAvailability();
  }, [currentMonth]);

  useEffect(() => {
    !Object.keys(acceptReservations).includes(currentMonth.format("YYYY-MM")) &&
      setAcceptReservations({
        ...acceptReservations,
        [currentMonth.format("YYYY-MM")]: true,
      });
  }, [currentMonth, calendarStatus[currentMonth.format("YYYY-MM")]]);

  useEffect(() => {
    handleOpenCalendar(
      defaultRate[currentMonth.format("YYYY-MM")],
      currentMonth.format("YYYY-MM")
    );
  }, [acceptReservations]);

  useEffect(() => {
    handleOpenCalendar(
      monthPriceData[currentMonth.format("YYYY-MM")] || defaultBasePrice,
      currentMonth.format("YYYY-MM"),
      individualDateAvailability,
      closureDate
    );
  }, [defaultBasePrice]);

  useEffect(() => {
    if (calendarStatus[currentMonth.format("YYYY-MM")] === "closed") {
      const eventsForCurrentMonth = events.filter((event) =>
        dayjs(event.start).isSame(currentMonth, "month")
      );

      const allEventsClosed =
        eventsForCurrentMonth.length > 0 &&
        eventsForCurrentMonth.every((event) => event.availability === "closed");

      if (allEventsClosed) {
        setCalendarStatus((prevStatus) => ({
          ...prevStatus,
          [currentMonth.format("YYYY-MM")]: "closed",
        }));
      } else {
        setCalendarStatus((prevStatus) => ({
          ...prevStatus,
          [currentMonth.format("YYYY-MM")]: "open",
        }));
      }
    }
  }, [individualDateAvailability]);

  useEffect(() => {
    // Set closure date on changing month
    if (calendarStatus[currentMonth.format("YYYY-MM")] === "open") {
      events.forEach((event) => {
        if (event.availability === "closed") {
          setClosureDate((prevClosureDate) => {
            const newDate = dayjs(event.start).format("YYYY-MM-DD");
            return prevClosureDate.includes(newDate)
              ? prevClosureDate
              : [...prevClosureDate, newDate];
          });
        } else {
          setClosureDate((prevClosureDate) => {
            const removeDate = dayjs(event.start).format("YYYY-MM-DD");
            return prevClosureDate.filter((date) => date !== removeDate);
          });
        }
      });
    }
  }, [calendarStatus, currentMonth, events]);

  return (
    <div className="form-white-bg w-100">
      <div className="availability-calender-container create-venue-availability">
        <div className="d-flex align-items-center mb-2 position-relative">
          <button
            type="button"
            onClick={handlePreviousMonth}
            className="direction-button"
            disabled={dayjs().isSame(currentMonth, "month")}
          >
            <img src={images.LEFT_ARROW_ICON} alt="" />
          </button>

          <DatePicker
            inputReadOnly
            picker="month"
            value={currentMonth}
            defaultValue={currentMonth}
            onChange={handleMonthChange}
            allowClear={false}
            style={{ margin: "0 16px", maxWidth: 108 }}
            suffixIcon={false}
            popupClassName="availability-calendar-date-picker availability-calender-date-picker"
            disabledDate={(date) => date && date < dayjs().startOf("month")}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
          />
          <button
            type="button"
            onClick={handleNextMonth}
            className="direction-button"
          >
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        </div>
        <div className="d-flex gap-4 calendar-sidebar-container">
          <div className="flex-grow-1 w-100">
            {loading ? (
              <Skeleton />
            ) : (
              <Calendar
                localizer={localizer}
                events={events}
                onNavigate={(date) => setCurrentMonth(dayjs(date))}
                selectable={acceptReservations[currentMonth.format("YYYY-MM")]}
                views={["month"]}
                onSelectSlot={(value) => {
                  handleSelectDate(value);
                }}
                style={{ height: 500 }}
                startAccessor="start"
                endAccessor="end"
                date={currentMonth.toDate()}
                dayPropGetter={dayPropGetter}
                slotPropGetter={dayPropGetter}
                components={{
                  event: ({ event }) => {
                    if (event.title === "Closed") {
                      return (
                        <div className="closed-event">
                          <span role="img" aria-label="lock">
                            <img
                              src={images.LOCK_ICON}
                              alt=""
                              height={"16px"}
                            />
                          </span>
                          <SmallText text="Closed" />
                        </div>
                      );
                    }
                    return <span>{event.title}</span>;
                  },
                }}
              />
            )}
          </div>

          {!isSidebarOpen && (
            <CalendarSettings
              setDefaultBasePrice={setDefaultBasePrice}
              defaultBasePrice={defaultBasePrice}
              onAvailabilityChange={onAvailabilityChange}
              onRateChange={(rate) => {
                if (calendarStatus[currentMonth.format("YYYY-MM")] === "open") {
                  handleOpenCalendar(
                    rate[currentMonth.format("YYYY-MM")] || 0,
                    currentMonth.format("YYYY-MM")
                  );
                }

                if (
                  monthPriceData[currentMonth.format("YYYY-MM")] ===
                  rate[currentMonth.format("YYYY-MM")]
                ) {
                  const newRate = { ...defaultRate };
                  delete newRate[currentMonth.format("YYYY-MM")];
                  setDefaultRate(newRate);
                }
              }}
              basePrice={defaultRate[currentMonth.format("YYYY-MM")]}
              month={currentMonth.format("YYYY-MM")}
              availabilityStatus={
                calendarStatus[currentMonth.format("YYYY-MM")]
              }
              setAcceptReservations={setAcceptReservations}
              acceptReservations={acceptReservations}
              createVenue={true}
              setAdvanceBooking={setAdvanceBooking}
              advanceBooking={advanceBooking}
            />
          )}

          {isSidebarOpen && selectedDate && (
            <IndividualDateAvailabilitySidebar
              date={selectedDate}
              event={selectedEvent}
              onSave={(date, availability, price) => {
                handleSaveForDate(date, availability, price);
              }}
              defaultRate={
                individualDateAvailability[currentMonth.format("YYYY-MM-DD")] ||
                0
              }
              setIsSidebarOpen={setIsSidebarOpen}
              createVenue={true}
              basePrice={basePrice}
              setMonthAvailability={setCalendarStatus}
            />
          )}
        </div>
        <CreateVenueFooter
          continueButtonType="button"
          submitting={submitting}
          continueOnclick={() => handleSave({ saveAndExit: false })}
          disabled={submitting}
          saveVenueDetails={() => {
            handleSave({ saveAndExit: true });
            navigate("/venues/my-venues");
          }}
          handleBackButton={handlePreviousStep}
          disableSaveButton={disableSaveButton}
        />
      </div>
    </div>
  );
};

export default AvailabilityCalender;
