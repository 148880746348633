
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VenueType {
  icon_id: string;
  name: string;
}

interface RecommendedRetreat {
  icon_id: string;
  name: string;
}

interface VenueAddOnService {
  icon_id: string;
  name: string;
  service_type: string;
  charges: number;
  description: string;
}

interface RetreatVenue {
  id: string;
  host_id: string;
  venue_id: string;
  title: string;
  description: string;
  included: string;
  not_included: string;
  branding_logo: string;
  site_url: string;
  advance_booking_time: string | null;
  enable_wait_list: boolean | null;
  cancellation_policy_id: string;
  welcome_message: string | null;
  guest_input_ids: string[];
}

export interface RetreatPropertyDetail {
  id ?: string;
  base_price: number;
  venue_id: string;
  venue_title: string;
  property_description: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zip_code: string;
  venue_type: VenueType;
  recommended_retreats: RecommendedRetreat[];
  languages: string[];
  highlights: VenueType[];
  venue_amenities: any[];
  venue_add_on_services: VenueAddOnService[];
  is_available: boolean;
  venue_images: string[];
  sleeps: number;
  number_of_rooms: number;
  number_of_bathrooms: number;
  check_in_time: string;
  check_in_out: string;
  custom_paragraphs: string[];
  quite_hours_start: string;
  quite_hours_end: string;
}
interface RetreatVenue {
  id: string;
  host_id: string;
  venue_id: string;
  title: string;
  description: string;
  included: string;
  not_included: string;
  branding_logo: string;
  site_url: string;
  advance_booking_time: string | null;
  enable_wait_list: boolean | null;
  cancellation_policy_id: string;
  welcome_message: string | null;
  guest_input_ids: string[];
}

interface RetreatVenueState {
  retreatVenueDetail: RetreatVenue | null;
  retreatPropertyDetail: RetreatPropertyDetail | null;
  propertyId: string;
}

const initialState: RetreatVenueState = {
  retreatVenueDetail: null,
  retreatPropertyDetail: null,
  propertyId: ''
};

export const retreatVenueSlice = createSlice({
  name: 'retreatVenue',
  initialState,
  reducers: {
    setRetreatVenueDetail(state, action: PayloadAction<RetreatVenue | null>) {
      state.retreatVenueDetail = action.payload;
    },
    setRetreatPropertyDetail(state, action:  PayloadAction<RetreatPropertyDetail | null>) {
      state.retreatPropertyDetail = action.payload;
    },
    setPropertyId(state, action: PayloadAction<string>) {
      state.propertyId = action.payload;
    }
  }
});

export const { setRetreatVenueDetail, setRetreatPropertyDetail, setPropertyId } = retreatVenueSlice.actions;

export default retreatVenueSlice.reducer;
