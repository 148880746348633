import React, { useEffect, useState } from "react";

//third-party
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//component
import StepFormFooter from "./StepFormFooter";
import AddOnCard from "./AddOnCard";
import PriceSection from "./PriceSection";

//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
import Loader from "../../../UI/Loader";

//custom hooks
import { useApiCall } from "../../../../hooks/useApiCall";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
import { setRetreat } from "../../../../redux/features/createRetreat-slice";
import { RootState } from "../../../../redux/store";

//api call
import {
  allAddonService,
  createRetreatAPI,
  handleRetreatStep,
} from "../../../../network";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

//types
import {
  AddonServiceProps,
  Card,
  CreateRetreat,
  FormFooterProps,
} from "../../../../types/retreat";

//images
import { images } from "../../../../assets/images";

const VenueCostForm: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addOnService, setAddOnService] = useState<AddonServiceProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addonDetail, setAddonDetail] = useState<boolean>(true);
  const [optional, setOptional] = useState<boolean>(true);
  const [request, setRequest] = useState<boolean>(true);
  const [selectedCards, setSelectedCards] = useState<Card[]>([]);
  const [specialRequest, setSpecialRequest] = useState<string>("");

  const { propertyId } = useSelector((state: RootState) => state.retreatVenue);
  const retreat = useSelector((state: RootState) => state.createRetreat);

  // submit when user click on next button
  const retreatValues = {
    host_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    venue_id: retreat.venue_id,
    check_in_date: retreat.check_in_date,
    check_out_date: retreat.check_out_date,
    group_size: retreat.group_size,
    total_price: retreat.total_price,
    venue_add_on_services: selectedCards.map((card) => card.id),
    special_request: specialRequest,
    partial: false,
    form_step: "add_on",
  };

  // submit when user click on save and exit button
  const exitValues = {
    host_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    venue_id: retreat.venue_id,
    check_in_date: retreat.check_in_date,
    check_out_date: retreat.check_out_date,
    group_size: retreat.group_size,
    total_price: retreat.total_price,
    venue_add_on_services: selectedCards.map((card) => card.id),
    special_request: specialRequest,
    partial: true,
    form_step: "add_on",
  };
  //open modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  //logic for save and exit
  const saveAndExit = async (values: CreateRetreat) => {
    call(
      () => createRetreatAPI(values),
      (res) => {
        navigate("/host/retreat");
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };
  //logic for select card
  const handleCardSelection = (name: string, charges: number, id: string) => {
    const existingCardIndex = selectedCards.findIndex((card) => card.id === id);
    if (existingCardIndex !== -1) {
      const newSelectedCards = [...selectedCards];
      newSelectedCards.splice(existingCardIndex, 1);
      setSelectedCards(newSelectedCards);
    } else {
      setSelectedCards([...selectedCards, { name, charges, id }]);
    }
  };

  //logic for modal and next step
  const handleModal = () => {
    dispatch(setShowModal({ modalName: "success", visible: false }));
    handleNextStep();
  };

  //api call for get all add on services
  const getAddonService = () => {
    setIsLoading(true);
    call(
      () => allAddonService(propertyId),
      (res) => {
        setAddOnService(res.data.data);
        setIsLoading(false);
      },
      (err) => {
        ToastFail(err.message);
        setIsLoading(false);
      }
    );
  };

  //get previously selected retreat detail
  const getDetailOfAddOn = () => {
    call(
      () => handleRetreatStep("8c0179f6-f6de-49dc-a629-97224587cdef"),
      (res) => {
        dispatch(
          setRetreat({
            id: res.data.data.id,
            venue_id: res.data.data.venue_id,
            check_in_date: res.data.data.check_in_date,
            check_out_date: res.data.data.check_out_date,
            group_size: res.data.data.group_size,
            total_price: res.data.data.total_price,
            add_on_services_id: res.data.data.add_on_services_id,
            special_request: res.data.data.special_request,
          })
        );
        setSpecialRequest(res.data.data.special_request);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  //api call for store data in create retreat
  const addAddonService = async (values: CreateRetreat) => {
    call(
      () => createRetreatAPI(values),
      (res) => {
        getDetailOfAddOn();
        openModal("success");
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  useEffect(() => {
    getAddonService();
    getDetailOfAddOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {retreat ? (
        <>
          {" "}
          <div className="container">
            <div className="add-on-service">
              <div style={{ marginBottom: "32px" }}>
                <h1 className="m-0 add-on-title">Add-ons</h1>
                <p className="m-0 add-on-description">
                  Customize your package with amenities, add ons and request
                  items if necessary.
                </p>
              </div>
              <div className="d-flex gap-4 ">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="flex-grow-1 d-flex flex-column gap-4">
                    <div className="d-flex flex-column justify-content-between gap-4 add-on-detail">
                      <div className="d-flex justify-content-between align-items-center flex-grow-1">
                        <div className="d-flex algin-items-center justify-content-center gap-1">
                          <div>
                            <img
                              src={images.STAR}
                              alt="icon"
                              width={28}
                              height={28}
                            />
                          </div>

                          <h4 className="m-0 align-self-center">
                            Add-on Service
                          </h4>

                          <div className="align-self-center">
                            <img
                              src={images.ToolTip}
                              alt="icon"
                              width={13}
                              height={13}
                            />
                          </div>
                        </div>
                        <div
                          className={`img-container ${
                            addonDetail ? "active" : ""
                          }`}
                          onClick={() => setAddonDetail(!addonDetail)}
                        >
                          <img src={images.UP} alt="up" className="img-fluid" />
                        </div>
                      </div>
                      <div
                        className={`${
                          addonDetail ? "d-block" : "d-none"
                        } d-flex flex-wrap gap-4`}
                      >
                        {isLoading &&
                        addOnService?.add_on_services.length === 0 ? (
                          <Loader />
                        ) : addOnService?.add_on_services.length === 0 ? (
                          <p className="text-center">
                            Add-on services not available for this venue
                          </p>
                        ) : (
                          addOnService?.add_on_services
                            .filter((card) => card.type === "Mandatory")
                            .map((card, index) => (
                              <AddOnCard
                                {...card}
                                index={index}
                                onCardSelect={handleCardSelection}
                              />
                            ))
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between gap-4 add-on-detail optional-service">
                      <div className="d-flex justify-content-between align-items-center flex-grow-1">
                        <div className="d-flex algin-items-center justify-content-center gap-1">
                          <div>
                            <img
                              src={images.STAR}
                              alt="icon"
                              width={28}
                              height={28}
                            />
                          </div>

                          <h4 className="m-0 align-self-center">
                            Optional add-on service
                          </h4>

                          <div className="align-self-center">
                            <img
                              src={images.ToolTip}
                              alt="icon"
                              width={13}
                              height={13}
                            />
                          </div>
                        </div>
                        <div
                          className={`img-container ${
                            optional ? "active" : ""
                          }`}
                          onClick={() => setOptional(!optional)}
                        >
                          <img src={images.UP} alt="up" className="img-fluid" />
                        </div>
                      </div>
                      <div
                        className={`${
                          optional ? "d-block" : "d-none"
                        } d-flex flex-wrap gap-4`}
                      >
                        {isLoading &&
                        addOnService?.add_on_services.length === 0 ? (
                          <Loader />
                        ) : addOnService?.add_on_services.length === 0 ? (
                          <p className="text-center">
                            Add-on services not available for this venue
                          </p>
                        ) : (
                          addOnService?.add_on_services
                            .filter((card) => card.type === "Optional")
                            .map((card, index) => (
                              <AddOnCard
                                {...card}
                                index={index}
                                onCardSelect={handleCardSelection}
                              />
                            ))
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-4 add-on-detail">
                      <div className="d-flex justify-content-between align-items-center detail-header flex-grow-1">
                        <div className="d-flex algin-items-center justify-content-center gap-1">
                          <div>
                            <img
                              src={images.BELL}
                              alt="icon"
                              width={28}
                              height={28}
                            />
                          </div>

                          <h4 className="m-0 align-self-center">
                            Special Request
                          </h4>

                          <div className="align-self-center">
                            <img
                              src={images.ToolTip}
                              alt="icon"
                              width={13}
                              height={13}
                            />
                          </div>
                        </div>
                        <div
                          className={`img-container ${request ? "active" : ""}`}
                          onClick={() => setRequest(!request)}
                        >
                          <img src={images.UP} alt="up" className="img-fluid" />
                        </div>
                      </div>
                      <div className={`${request ? "d-block" : "d-none"}`}>
                        <textarea
                          className="form-control"
                          placeholder="enter your text here"
                          id="exampleFormControlTextarea1"
                          rows={1}
                          value={specialRequest}
                          onChange={(e) => setSpecialRequest(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <PriceSection />
              </div>
            </div>
          </div>
          <ModalComponent modalName="success" title="">
            <div
              className="d-flex h-full align-items-center justify-content-center"
              style={{ height: "600px" }}
            >
              <div className="d-flex flex-column gap-4">
                <div className="d-flex align-items-center justify-content-center">
                  <img src={images.SUCCESS} alt="" width={150} height={150} />
                </div>
                <div className="text-center d-flex flex-column gap-2">
                  <SmallText
                    text="Application Sent for Approval"
                    fontSize={24}
                    lineHeight={28.2}
                    fontWeight={700}
                    color="#19191C"
                  />
                  <SmallText
                    text="Start configuring and creating important details that will guarantee an incredible experience for your guests."
                    fontSize={16}
                    fontWeight={400}
                    lineHeight={18.8}
                    color="#58585B"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex gap-3">
                <button className="back-btn">Do it later</button>
                <button
                  className="submit-btn"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Start Setup
                </button>
              </div>
            </div>
          </ModalComponent>
          <StepFormFooter
            handleExit={() => saveAndExit(exitValues)}
            handleNextStep={() => addAddonService(retreatValues)}
            handlePreviousStep={handlePreviousStep}
            btnDisable={
              selectedCards.length || specialRequest?.length > 0 ? false : true
            }
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default VenueCostForm;
