import React from "react";

// custom components
import WelComeCreateVenue from "../../../components/core/PropertyManager/CreateVenue/WelComeCreateVenue";

const CreateVenuePage: React.FC = () => {
  return <WelComeCreateVenue />;
};

export default CreateVenuePage;
