import { useEffect, useState } from "react";
//third party
import { useDispatch, useSelector } from "react-redux";
import { Steps } from "antd";

//component
import SelectPackage from "./CheckoutModal/SelectPackage";
import CheckoutAddon from "./CheckoutModal/CheckoutAddon";
import CheckoutInfo from "./CheckoutModal/CheckoutInfo";
import CheckoutPayment from "./CheckoutModal/CheckoutPayment";
import CheckoutModalFooter from "./CheckoutModal/CheckoutModalFooter";

//common component
import SmallText from "../../../UI/SmallText";
import MultiSelectCheckbox from "../../../UI/MultiSelection/MultiSelect";
import ModalComponent from "../../../UI/ModalComponent";

//custom hooks
import { useApiCall } from "../../../../hooks/useApiCall";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
import { RootState } from "../../../../redux/store";

//api calls
import { allRooms } from "../../../../network";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

//css
import "../../../../assets/css/hostVenueDetail.css";
import "../../../../assets/css/stepForm.css";

const AboutPayment = () => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const { Step } = Steps;
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [ rooms , setRooms] = useState([]);

  const { propertyId } = useSelector((state: RootState) => state.retreatVenue);

  const getAllRooms = () => {
    call(
      () => allRooms(propertyId),
      (res) => {
        setRooms(res.data.data);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  const closeModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: false }));
  };

  const handleSelect = (value: string) => {
    setSelectedOptions([...selectedOptions, value]);
  };

  const handleDeselect = (value: string) => {
    setSelectedOptions(selectedOptions.filter((option) => option !== value));
  };

  const handleNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      closeModal("checkout-modal");
      setCurrentStep(0);
    }
  };
  const handlePreviousStep = () => {
    currentStep > 0
      ? setCurrentStep(currentStep - 1)
      : closeModal("checkout-modal");
  };

  const steps = [
    {
      title: "Choose your package",
      content: <SelectPackage />,
    },
    {
      title: "Add ons",
      content: <CheckoutAddon />,
    },
    {
      title: "Guest info",
      content: <CheckoutInfo />,
    },
    {
      title: "Payment",
      content: <CheckoutPayment />,
    },
  ];

  useEffect(()=>{
    getAllRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <div className="retreat-payment">
        <div className="text-center d-flex flex-column align-items-center gap-1">
          <div className="d-flex flex-column">
            <SmallText
              text="From"
              color="#58585B"
              lineHeight={18.8}
              fontWeight={400}
              fontSize={16}
            />
            <SmallText
              text="$1,200.00"
              color="#19191C"
              lineHeight={37.6}
              fontWeight={700}
              fontSize={32}
            />
          </div>
          <div className="d-flex">
            <SmallText
              text="Deposit:"
              color="#58585B"
              lineHeight={18.8}
              fontWeight={400}
              fontSize={16}
            />

            <SmallText
              text="$380"
              color="#58585B"
              lineHeight={18.8}
              fontWeight={700}
              fontSize={16}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1 flex-column gap-3">
          <button className="payment-button">
            Early bird Discount: $200 off Until Aug 1
          </button>
          <div
            className="d-flex flex-grow-1 flex-column overflow-y-scroll"
            style={{ maxHeight: "477px" }}
          >
            <MultiSelectCheckbox
              verticalCardProps={rooms}
              selectedOptions={selectedOptions}
              onSelect={handleSelect}
              onDeselect={handleDeselect}
              cardWidth="100%"
            />
          </div>
          <div className="d-flex flex-column gap-2 text-center">
            <button
              className="booking-btn"
              onClick={() => {
                openModal("checkout-modal");
              }}
            >
              Continue with this package
            </button>
            <SmallText
              text="You won't be charged yet"
              fontSize={14}
              fontWeight={400}
              lineHeight={16.45}
              color="#58585B"
            />
          </div>
        </div>
      </div>
      <ModalComponent
        modalName="checkout-modal"
        className="custom-width custom-modal"
      >
        <Steps current={currentStep} type="navigation">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
        <CheckoutModalFooter
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </ModalComponent>
    </>
  );
};

export default AboutPayment;
