export const makeQueryParams = (obj: any) => {
  return (
    '?' +
    Object.keys(obj)
      .filter(key => obj[key] !== undefined && obj[key] !== null && obj[key] !== "" && obj[key] !== 0)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&')
  );
  };


  export const preventScroll =  (e : any) =>{
    e.target.blur();
  }

 export const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
