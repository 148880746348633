import React from "react";
import RetreatDetail from "../../../components/core/Admin/Retreats/RetreatDetail";

const AdminRetreatDetail: React.FC = () => {
  return (
    <div>
      <RetreatDetail />
    </div>
  );
};

export default AdminRetreatDetail;
