/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

// third party
import { EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { useDispatch } from "react-redux";

// custom components
import ModalComponent from "../../../UI/ModalComponent";
import TableComponent from "../../../UI/TableComponent";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// images
import { images } from "../../../../assets/images";
import PayoutDetail from "./PayoutDetail";

// Types
import { adminPayoutTableColumnType } from "../../../../types/admin";

// Utility
import { formatNumberWithCommas } from "../../../../library";

const { Text } = Typography;

interface MetaData {
  user_type?: string;
  venue_name?: string;
  retreat_name?: string;
  venue_manager_name?: string;
  retreat_host_name?: string;
}

const getFormattedValue = (
  metaData: MetaData
): { name: string; user: string } => {
  switch (metaData?.user_type) {
    case "venue_manager":
      return {
        ...metaData,
        name: metaData.venue_name || "-",
        user: metaData.venue_manager_name || "-",
      };
    case "retreat_host":
      return {
        ...metaData,
        name: metaData.retreat_name || "-",
        user: metaData.retreat_host_name || "-",
      };
    default:
      return {
        ...metaData,
        name: "-",
        user: "-",
      };
  }
};

const PayoutListingTable = ({
  payoutData,
  totalItems,
  currentPage,
  pageSize = 10,
  onPageChange,
  loading = false,
}: {
  payoutData?: any;
  totalItems: number;
  currentPage: number;
  pageSize?: number;
  onPageChange: (page: number, pageSize: number, type?: string) => void;
  loading?: boolean;
}) => {
  const dispatch = useDispatch();
  const [payoutsData, setPayoutsData] = useState<adminPayoutTableColumnType[]>(
    []
  );
  const [clickedItemId, setClickedItemId] = useState<string>("");

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const handleModal = (itemId: any) => {
    setClickedItemId(itemId);
    openModal(`payout-details`);
  };

  const payoutsColumns: ColumnsType<adminPayoutTableColumnType> = [
    {
      key: "payoutId",
      dataIndex: "payoutId",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.ID_ICON} alt="icon" height={"16px"} />
          <span>Payout ID</span>
        </div>
      ),
      width: 130,
      render: (_, { payoutId }) => (
        <Text style={{ width: 130 }} ellipsis={{ tooltip: payoutId }}>
          {payoutId}
        </Text>
      ),
    },
    {
      key: "payoutType",
      dataIndex: "payoutType",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="icon" height={"16px"} />
          <span>Payout Type</span>
        </div>
      ),
      width: 136,
      render: (_, { payoutType }) => {
        return (
          <div
            className={`payoutTypeData${
              payoutType === "deposit" ? "Outlined" : ""
            }`}
          >
            {payoutType === "deposit" ? "Deposit" : "Final"}
          </div>
        );
      },
    },
    {
      key: "payoutDate",
      dataIndex: "payoutDate",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="icon" height={"16px"} />
          <span>Payout Date</span>
        </div>
      ),
      width: 160,
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.COIN_ICON} alt="icon" height={"16px"} />
          <span>Amount</span>
        </div>
      ),
      width: 127,
      render: (_, { amount }) =>
        `$ ${formatNumberWithCommas(amount?.toString() || "0")}`,
    },
    {
      key: "currency",
      dataIndex: "currency",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.DOLLAR_ICON} alt="icon" height={"16px"} />
          <span>Currency</span>
        </div>
      ),
      width: 120,
    },
    {
      key: "name",
      dataIndex: "name",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.HOUSE_ICON} alt="icon" height={"16px"} />
          <span>Name</span>
        </div>
      ),
      width: 271,
      render: (_, { name }) => `${name}`,
    },
    {
      key: "user",
      dataIndex: "user",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.PERSON_ICON_BLACK} alt="icon" height={"16px"} />
          <span>User</span>
        </div>
      ),
      width: 194,
      render: (_, { user }) => (
        <div className="reservation-host-name d-flex align-items-center gap-1">
          {/* <img src={images.PERSON_AVATAR} alt="icon" height={"40px"} /> */}
          <div className="name">
            <p className="common-sm-dark-text tex-overflow-handle">{user}</p>
            {/* <span className="common-xs-light-text">{user}</span> */}
          </div>
        </div>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      className: "action-button",
      width: 123,
      render: (_, payoutObject) => {
        return (
          <div className="payout-details-button">
            <button
              type="button"
              className="bordered-button detail-button"
              onClick={(e) => handleModal(payoutObject.payoutId)}
            >
              <EyeOutlined /> Details
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (payoutData) {
      const formattedData = payoutData.map((item: any, index: number) => {
        const { name, user } = getFormattedValue(item?.meta_data);

        return {
          key: item.id,
          payoutId: item.id,
          payoutType: item?.meta_data?.payout_type,
          payoutDate: item.date,
          amount: (item.amount / 100).toFixed(2),
          currency: item.currency.toUpperCase(),
          name: name,
          user: user,
          action: item.payoutId,
        };
      });
      setPayoutsData(formattedData);
    }
  }, [payoutData]);

  return (
    <>
      <TableComponent
        columns={payoutsColumns}
        data={payoutsData}
        pageSize={pageSize}
        scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
        tableLayout="fixed"
        paginationData={{
          total: totalItems,
          onChange: (page, pageSize) => {
            onPageChange(page, pageSize);
          },
          current: currentPage,
        }}
        loading={loading}
        showPagination={true}
      />
      <ModalComponent modalName={"payout-details"}>
        <PayoutDetail payoutId={clickedItemId as string} />
      </ModalComponent>
    </>
  );
};

export default PayoutListingTable;
