import React, { useEffect, useState } from "react";
//common component
import SmallText from "../SmallText";

//types
import { CheckBoxAccordianProps } from "../../../types";

//css
import "./index.css";

const CheckBoxAccordian: React.FC<CheckBoxAccordianProps> = ({
  title,
  description,
  children,
  isTrue = false,
}) => {
  const [isChecked, setIsChecked] = useState(isTrue);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(isTrue);
  }, [isTrue]);

  return (
    <div className="d-flex flex-column gap-4 switch-box">
      <div className="d-flex gap-4">
        <div className="d-flex gap-1">
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            <SmallText
              text={title}
              color="#19191C"
              fontWeight={400}
              lineHeight={18.8}
              fontSize={16}
            />
            <SmallText
              text={description}
              color="#58585B"
              fontWeight={400}
              lineHeight={18.8}
              fontSize={16}
            />
          </label>
        </div>
        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isChecked}
              disabled={!isTrue}
              onChange={handleChange}
              style={{ height: "20px", width: "40px" }}
            />
          </div>
        </div>
      </div>
      {isChecked && <div>{children}</div>}
    </div>
  );
};

export default CheckBoxAccordian;
