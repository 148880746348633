import React from "react";

// third party
import { Link } from "react-router-dom";

// images
import { images } from "../../../../assets/images";

const NoVenues: React.FC = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 gap-3">
      <img src={images.NO_VENUES_IMG} alt="" width={"317px"} height={"200px"} />
      <h3 className="m-0 common-lg-bold-dark-text">No venues added yet</h3>
      <p className="m-0">
        Your list of venues will be displayed here. Create your first location
        by clicking{" "}
        <Link
          to="/venues/create-venue"
          className="text-decoration-none"
          style={{ color: "var(--deep-sea)" }}
        >
          New venue
        </Link>
      </p>
      <Link to="/venues/create-venue" style={{ marginRight: "8px" }}>
        <button
          type="button"
          className="custom-button"
          style={{ width: "260px" }}
        >
          <img src={images.PLUS_WHITE} alt="" width={"16px"} height={"16px"} />{" "}
          Add Venue
        </button>
      </Link>
    </div>
  );
};

export default NoVenues;
