import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaginationState {
  currentPage: number;
  startingAfter?: string;
  endingBefore?: string;
  history: string[]; // Stack to keep track of cursors
}

const initialState: PaginationState = {
  currentPage: 1,
  history: [],
};

export const payoutsPaginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setStartingAfter(state, action: PayloadAction<string>) {
      state.startingAfter = action.payload;
      state.history.push(action.payload);
    },
    setEndingBefore(state, action: PayloadAction<string>) {
      state.endingBefore = action.payload;
      if (state.history.length > 0) {
        state.history.pop();
      }
    },
    resetPagination(state) {
      state.currentPage = 1;
      state.startingAfter = undefined;
      state.endingBefore = undefined;
      state.history = [];
    },
  },
});

export const {
  setCurrentPage,
  setStartingAfter,
  setEndingBefore,
  resetPagination,
} = payoutsPaginationSlice.actions;

export default payoutsPaginationSlice.reducer;
