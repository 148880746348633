/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { toast } from "react-toastify";
import Loader from "../../../UI/Loader";

// custom component
import StatusTags from "../../../UI/StatusTags";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { adminPayoutsDetailById } from "../../../../network";

// images
import { images } from "../../../../assets/images";

// Utility
import {
  convertDateFormatRange,
  convertToTitleCase,
  formatNumberWithCommas,
  formatTimestampToDate,
} from "../../../../library";

interface MetaData {
  user_type?: string;
  venue_name?: string;
  venue_manager_name?: string;
  venue_image?: string;
  retreat_name?: string;
  retreat_host_name?: string;
  retreat_image?: string;
  retreat_start_date?: string;
  retreat_end_date?: string;
}

const getFormattedValue = (
  metaData: MetaData
): {
  name: string;
  user: string;
  image: string | boolean;
  formatted_user_type: string;
  formatted_date_range: string;
} => {
  const formattedDateRange =
    metaData.retreat_start_date &&
    metaData.retreat_end_date &&
    convertDateFormatRange(
      metaData.retreat_start_date || "",
      metaData.retreat_end_date || ""
    );

  switch (metaData?.user_type) {
    case "venue_manager":
      return {
        ...metaData,
        name: metaData.venue_name || "-",
        user: metaData.venue_manager_name || "-",
        image: metaData.venue_image || false,
        formatted_user_type: "Venue Manager",
        formatted_date_range: formattedDateRange || "-",
      };
    case "retreat_host":
      return {
        ...metaData,
        name: metaData.retreat_name || "-",
        user: metaData.retreat_host_name || "-",
        image: metaData.retreat_image || false,
        formatted_user_type: "Retreat Host",
        formatted_date_range: formattedDateRange || "-",
      };
    default:
      return {
        ...metaData,
        name: "-",
        user: "-",
        image: false,
        formatted_user_type: "-",
        formatted_date_range: "-",
      };
  }
};

const PayoutDetail: React.FC<{
  payoutId?: string;
}> = ({ payoutId }) => {
  const { call } = useApiCall();
  const [payoutDetails, setPayoutDetails] = useState<any>();
  const [loading, setLoading] = useState(true);

  const getPayoutDetails = async () => {
    setLoading(true);
    call(
      () => adminPayoutsDetailById(payoutId || ""),
      (res) => {
        let payoutData = res?.data?.data;

        payoutData.metadata = getFormattedValue(payoutData?.metadata);

        setPayoutDetails(payoutData);
        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch payout details"
        );
      }
    );
  };

  useEffect(() => {
    getPayoutDetails();
  }, [payoutId]);

  return loading ? (
    <Loader />
  ) : (
    <div className="upcoming-reservation-detail-modal">
      <div className="title d-flex gap-2 align-items-center">
        <img
          src={payoutDetails?.metadata?.image || images.NO_IMAGE}
          alt="venue images"
          height={"40px"}
          width={"40px"}
        />
        <div>
          <p className="common-md-bold-dark-text">
            {payoutDetails?.metadata?.name}
          </p>
        </div>
      </div>
      <div className="upcoming-reservation-detail">
        <div className="about-retreat-container">
          <div className="common-sm-medium-dark-text mb-3">Payout Details</div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img
                src={images.RESERVATION_TAG}
                alt="reservation tag "
                height={"16px"}
              />
              <span>Reservation Number</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.metadata?.reservation_number || "-"}
            </div>
          </div>
          <div className="about-retreat d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img
                src={images.PERSON_ICON_BLACK}
                alt="person"
                height={"16px"}
              />
              User
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.metadata?.user !== "-"
                ? `${payoutDetails?.metadata?.user} (${convertToTitleCase(
                    payoutDetails?.metadata?.user_type || "-"
                  )})`
                : "-"}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CALENDAR_DOT} alt="calendar" height={"16px"} />
              <span>Dates</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.metadata?.formatted_date_range || "-"}
            </div>
          </div>
        </div>
        <div className="about-retreat-container">
          <div className="common-sm-medium-dark-text mb-3">Payout Summary</div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.SPINNER} alt="spinner" height={"16px"} />
              <span>Payout Type</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.metadata?.payout_type ? (
                <StatusTags
                  top="0"
                  left="0"
                  buttonPosition="relative"
                  label={convertToTitleCase(
                    payoutDetails?.metadata?.payout_type
                  )}
                  backgroundColor={"#F7ECE2"}
                  color={"#DC7618"}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="about-retreat d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CALENDAR_ICON} alt="calendar" height={"16px"} />
              <span>Payout Date</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {formatTimestampToDate(payoutDetails?.created) || "-"}
            </div>
          </div>
          <div className="about-retreat d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.COIN} alt="coin" height={"16px"} />
              Total Payout
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              ${formatNumberWithCommas(payoutDetails?.amount)}
            </div>
          </div>

          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img
                src={images.CURRENCY_DOLLAR_CIRCLE}
                alt="dollar"
                height={"16px"}
              />
              <span>Currency</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.currency.toUpperCase() || "-"}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.BINARY_ICON} alt="coin" height={"16px"} />
              <span>Payout ID</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.id || "-"}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CREDIT_CARD_ICON} alt="card" height={"16px"} />
              <span>Method</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {payoutDetails?.source_type.toUpperCase() || "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutDetail;
