/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Controller, useForm } from "react-hook-form";
import { InputNumber, Switch, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// custom components
import ModalComponent from "../../../UI/ModalComponent";
import Button from "../../../UI/Button";
import FormLabel from "../../../UI/FormLabel";
import InputComponent from "../../../UI/InputComponent";
import SelectOption from "../../../UI/SelectOption";
import TextareaComponent from "../../../UI/TextareaComponent";
import ClickableTags from "../../../UI/ClickableTags";
import SmallText from "../../../UI/SmallText";
import EditAddOnServiceModal from "../CreateVenue/CreateVenueFormStep4/EditAddOnServiceModal";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import { RootState } from "../../../../redux/store";

// network
import { fetchVenue, postAddOnServices } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  AddOnServicesFormTypes,
  AddOnServiceType,
  TagListType,
} from "../../../../types/property-manager";

// constants
import {
  DEFAULT_NUMBER_OPTION,
  PRICE_LABELS,
} from "../../../../constants/property-manager";

// images
import { images } from "../../../../assets/images";

const { Text } = Typography;

const AddOnServices: React.FC<{
  addOnServices?: AddOnServiceType[];
  venueId: string;
}> = ({ addOnServices, venueId }) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<AddOnServicesFormTypes>({
    defaultValues: {
      name: "",
      description: "",
      externalUrl: "",
      price: null,
      priceLabel: "",
      tickets: 1,
    },
  });

  const [submitting, setSubmitting] = useState(false);
  const [ticketsEnable, setTicketsEnable] = useState(false);
  const [priceEnable, setPriceEnable] = useState(false);

  const [addOnServicesList, setAddOnServicesList] = useState<
    AddOnServiceType[]
  >([]);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName: modalName, visible: true }));
  };
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const allIcons: TagListType[] = [];

  icons.forEach((icon) => {
    allIcons.push({
      id: icon.id,
      name: "",
      iconImage: icon.icon_url,
    });
  });

  const fetchDetails = async () => {
    call(
      () => fetchVenue(venueId),
      (res) => {
        const venueData = res.data?.data;
        setAddOnServicesList(venueData.add_on_services);
      },
      (err) => {
        toast.error(err.data.message || "Failed to fetch add on services");
      }
    );
  };

  const onFinish = async (data: AddOnServicesFormTypes) => {
    setSubmitting(true);
    if (!ticketsEnable) {
      data.tickets = null;
    }
    if (!priceEnable) {
      data.price = 0;
      data.priceLabel = "Free";
    }
    const dataToSend = {
      icon_id: data.icons,
      name: data.name,
      description: data.description,
      external_link: data.externalUrl || "",
      charges: data.price,
      service_type: data.priceLabel,
      type: "Optional",
      tickets: data.tickets,
    };

    call(
      () =>
        postAddOnServices({
          venue_id: venueId as string,
          data: dataToSend,
        }),
      (res) => {
        reset({
          name: "",
          description: "",
          externalUrl: "",
          price: null,
          priceLabel: "",
        });
        dispatch(
          setShowModal({
            modalName: "edit-add-on-services",
            visible: false,
          })
        );
        toast.success(
          res.data?.message || "Add-on services created successfully"
        );
        fetchDetails();
        reset({
          name: "",
          description: "",
          externalUrl: "",
          price: null,
          priceLabel: "",
        });
        setSubmitting(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to create add-on services"
        );
        setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    setAddOnServicesList(addOnServices || []);
  }, []);

  useEffect(() => {
    reset({
      price: null,
    });
  }, [priceEnable]);

  return (
    <div className="form-white-bg edit-addon-service-modal">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between gap-2 align-items-center ">
          <span className="editTabTitles">Add-on Services</span>
          <div className="d-flex gap-2">
            <button
              type="button"
              onClick={() => openModal("edit-add-on-services")}
              className="custom-button"
            >
              <img
                src={images.PLUS_WHITE}
                alt=""
                width={"16px"}
                height={"16px"}
              />
            </button>

            <ModalComponent
              modalName="edit-add-on-services"
              title="Add-on Service"
              className="modal-space-info edit-space-info"
              outsideClickClosable={false}
            >
              <div className="room-modal-subtitle">
                Create your add on and customize it as you wish
              </div>
              <div className="room-modal-info-container ">
                <form
                  className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4"
                  onSubmit={handleSubmit(onFinish)}
                >
                  <div className="form-group mt-3">
                    <FormLabel label="Name of venue" />
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please provide the venue name.",
                        minLength: {
                          value: 3,
                          message: "Name must be at least 3 characters long.",
                        },
                        validate: (value) => {
                          if (value?.trim() === "") {
                            return "Please provide the venue name.";
                          }
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <InputComponent
                          type="text"
                          placeholder="Write the name here"
                          style={{ width: "100%" }}
                          value={value || ""}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            onChange(inputValue);
                            trigger("name");
                          }}
                        />
                      )}
                    />
                    {errors.name && (
                      <span className="error">{errors.name.message}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <FormLabel label="Tickets" />
                      <Switch
                        checked={ticketsEnable}
                        onChange={() => setTicketsEnable(!ticketsEnable)}
                      />
                    </div>
                    <Controller
                      name="tickets"
                      control={control}
                      rules={{
                        required: !ticketsEnable
                          ? "Please provide tickets."
                          : false,
                      }}
                      disabled={!ticketsEnable}
                      render={({ field: { value, onChange } }) => (
                        <SelectOption
                          disabled={!ticketsEnable}
                          options={DEFAULT_NUMBER_OPTION}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <FormLabel label="Description" />
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: "Please provide a description.",
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextareaComponent
                          placeholder="enter your text here"
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value; // Extract the value
                            onChange(inputValue);
                            trigger("description");
                          }}
                        />
                      )}
                    />
                    {errors.description && (
                      <span className="error">
                        {errors.description?.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <FormLabel label="External URL (Optional)" />
                    <Controller
                      name="externalUrl"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <InputComponent
                          type="url"
                          placeholder="enter URL here"
                          style={{ width: "100%" }}
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value; // Extract the value
                            onChange(inputValue);
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <FormLabel label="Price" />
                      <Switch
                        checked={priceEnable}
                        onChange={() => setPriceEnable(!priceEnable)}
                      />
                    </div>
                    <Controller
                      name="price"
                      control={control}
                      rules={{
                        required: !priceEnable
                          ? "Please provide a price."
                          : false,
                        pattern: {
                          value: /^[0-9]+$/, // Only numbers allowed
                          message: "Price should contain only numbers.",
                        },
                      }}
                      disabled={!priceEnable}
                      render={({ field: { value, onChange } }) => (
                        <InputNumber
                          changeOnBlur={false}
                          className="input-component input-number pt-1 p-0"
                          changeOnWheel={false}
                          controls={false}
                          disabled={!priceEnable}
                          placeholder="0.00"
                          prefix="$"
                          value={value as number}
                          onChange={(e) => {
                            const inputValue = e; // Extract the value
                            onChange(inputValue);
                            trigger("price");
                          }}
                          onKeyPress={(e) => {
                            // Prevent non-numeric characters
                            if (!/^[0-9]$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    {errors.price && (
                      <span className="error">{errors.price.message}</span>
                    )}
                    {priceEnable && (
                      <Controller
                        name="priceLabel"
                        control={control}
                        rules={{ required: "Please select a type of venue." }}
                        render={({ field: { value, onChange } }) => (
                          <ClickableTags
                            selectionMode="single"
                            tagList={PRICE_LABELS}
                            property_id={value}
                            onChange={(value) => {
                              onChange(value);
                              trigger("priceLabel");
                            }}
                          />
                        )}
                      />
                    )}
                    {errors.priceLabel && (
                      <span className="error">{errors.priceLabel.message}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <FormLabel label="Icons (optional)" />
                    </div>
                    <Controller
                      name="icons"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ClickableTags
                          selectionMode="single"
                          tagList={allIcons}
                          property_id={value}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end"
                    style={{ bottom: "0" }}
                  >
                    <div>
                      <Button
                        type="button"
                        label="Cancel"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid var(--deep-sea)",
                          backgroundColor: "var(--pure-white)",
                          fontSize: "14px",
                          lineHeight: "16.45px",
                          color: "var(--deep-sea)",
                          fontWeight: "500",
                          padding: "12px 16px",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          reset({
                            name: "",
                            description: "",
                            externalUrl: "",
                            price: null,
                            priceLabel: "",
                          });
                          dispatch(
                            setShowModal({
                              modalName: "edit-add-on-services",
                              visible: false,
                            })
                          );
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        label={submitting === true ? "Saving..." : "Save"}
                        onClick={() => onFinish}
                        disabled={!isValid || submitting === true}
                        className={
                          submitting || !isValid ? "disabled-button" : ""
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </ModalComponent>
          </div>
        </div>
        <div
          className={`flex-grow-1 d-flex  ${
            addOnServices?.length === 0
              ? `flex-column justify-content-center`
              : `justify-content-start flex-wrap mt-3 gap-4`
          }`}
        >
          {addOnServicesList?.length === 0 && (
            <div className="empty-spaces d-flex flex-column align-items-center justify-content-center">
              <img src={images.ADD_ONS_BACKGROUND_IMAGE} alt="" />
              <div className="text-center">
                <h3>You haven't added any add-on services yet</h3>
                <p>
                  Your add ons will appear here, click on the '+' to start
                  adding them
                </p>
              </div>
            </div>
          )}
          <div className="service-cards w-100 d-flex flex-wrap flex-row">
            {addOnServicesList.length > 0 && (
              <div className="add-on-service-card-container  w-100">
                <div className="service-cards w-100 d-flex flex-wrap flex-row">
                  {addOnServicesList.map((item, index) => {
                    const icon = icons?.find(
                      (icon) => icon.id === item.icon_id
                    );
                    return (
                      <>
                        <ModalComponent
                          modalName={`edit-add-on-services-${item.id}`}
                          title="Add-on Service"
                          className="modal-space-info add-on-service-modal"
                          outsideClickClosable={false}
                        >
                          <EditAddOnServiceModal
                            venueId={venueId}
                            fetchDetails={fetchDetails}
                            addOnServices={item}
                          />
                        </ModalComponent>
                        <div
                          className="service-card position-relative"
                          key={`service-card-${index}`}
                          onClick={() =>
                            openModal(`edit-add-on-services-${item.id}`)
                          }
                        >
                          <div className="title justify-content-between">
                            <div className="d-flex align-items-center gap-2">
                              <img src={icon?.icon_url} alt="" height="28px" />
                              <Text
                                style={{ width: 450 }}
                                ellipsis={{ tooltip: item.name }}
                              >
                                <SmallText
                                  text={item.name}
                                  color="#2F206A"
                                  fontSize={18}
                                  fontWeight={400}
                                  lineHeight={21.15}
                                />
                              </Text>
                            </div>
                            {item.external_link && (
                              <Link
                                to={item.external_link}
                                className="external-link"
                                target="_blank"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    setShowModal({
                                      modalName: `edit-add-on-services-${item.id}`,
                                      visible: false,
                                    })
                                  );
                                }}
                              >
                                <img
                                  src={images.LINK_ICON}
                                  alt="link-icon"
                                  height={24}
                                />
                              </Link>
                            )}
                          </div>
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 3,
                              expandable: false,
                            }}
                            className="m-0"
                          >
                            <div className="description">
                              {item.description}
                            </div>
                          </Typography.Paragraph>
                          <div>
                            {item.charges > 0 ? (
                              <>
                                <span className="common-lg-medium-dark-text">
                                  ${item.charges}
                                </span>
                                <span className="price-label">
                                  {`/${item?.service_type
                                    .split("_")
                                    .join(" ")}`}
                                </span>
                              </>
                            ) : (
                              <span className="price">Free</span>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOnServices;
