export const GET_URL = {
  venueApi: process.env.REACT_APP_VENUE_API,
  imageAPI: process.env.REACT_APP_GET_ICONS_API,
  createVenueApi: process.env.REACT_APP_CREATE_VENUE_API,
  retreatVenueApi: process.env.REACT_APP_RETREAT_API,
  paymentApi: process.env.REACT_APP_PAYMENT_API,
  usersApi: process.env.REACT_APP_USERS_API,
  stripTestAPIKey: process.env.REACT_APP_STRIPE_TEST_API_KEY,

  // TODO : Remove below endpoints before production
  localVenueApi: process.env.REACT_APP_LOCAL_VENUE_API,
  localRetreatApi: process.env.REACT_APP_LOCAL_RETREAT_API,
  localImageApi: process.env.REACT_APP_IMAGE_API,
  localUserApi: process.env.REACT_APP_LOCAL_USER_API,
};
