import React from "react";

// third party
// import Carousel from "react-multi-carousel";

// custom components
import ButtonGroup from "./ButtonGroup";

// constants
import { responsiveCarousel } from "../../../constants";

// styles
import "./style.css";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

interface CarouselComponentProps {
  children: React.ReactNode;
  buttonClass?: string;
  carouselClass?: string;
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({
  children,
  buttonClass,
  carouselClass,
}) => {
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsiveCarousel}
      ssr={false}
      infinite={false}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      customButtonGroup={
        <ButtonGroup
          carouselState={null as any}
          next={() => {}}
          previous={() => {}}
          goToSlide={() => {}}
          className={buttonClass}
        />
      }
      renderButtonGroupOutside={true}
      className={carouselClass}
    >
      {children}
    </Carousel>
  );
};

export default CarouselComponent;
