import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchedVenueDetailsType } from "../../types/property-manager";

const initialState: FetchedVenueDetailsType = {
  add_on_services: [],
  address: "",
  city: "",
  country: "",
  amenities: {
    amenities: [],
  },
  base_price: 0,
  check_in_out: "",
  check_in_time: "",
  custom_paragraphs: [],
  form_state: {
    completed_status: false,
    step: 0,
  },
  highlights: [],
  is_approved: false,
  is_available: false,
  is_deactivation_request: false,
  is_venue_approval_requested: false,
  languages: [],
  media_url: "",
  number_of_bathrooms: 0,
  number_of_rooms: 0,
  owner_id: "",
  owner: "",
  owner_city: "",
  owner_country: "",
  owner_email: "",
  owner_name: "",
  owner_type: "",
  profile_picture: "",
  property_description: "",
  recommended_retreats: [],
  sleeps: 0,
  state: "",
  venue_images: [],
  zip_code: "",
  quite_hours_end: "",
  quite_hours_start: "",
  venue_id: "",
  venue_title: "",
  venue_type: {
    id: "",
    icon_id: "",
    name: "",
  },
};

export const fetchedVenueSlice = createSlice({
  name: "createRetreat",
  initialState,
  reducers: {
    setFetchedVenueData(state, action: PayloadAction<FetchedVenueDetailsType>) {
      return action.payload;
    },
  },
});

export const { setFetchedVenueData } = fetchedVenueSlice.actions;
export default fetchedVenueSlice.reducer;
