/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

//third party
import { EllipsisVertical, MessageSquare } from "lucide-react";
import { Popover, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

//common component
import TableComponent from "../../../UI/TableComponent";
import ModalComponent from "../../../UI/ModalComponent";
import UpcomingReservationDetailModal from "../Home/UpcomingReservationDetailModal";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import { setReservation } from "../../../../redux/features/reservationSlice";
import { setLastRoute } from "../../../../redux/features/cancelVenueReservation";

// network
import { getReservationRequestDetails } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// library
import { numberSeparator } from "../../../../library";

// types
import {
  Reservation,
  ReservationTableType,
  reservationTableColumnType,
  RetreatHost,
} from "../../../../types/property-manager";

//images
import { images } from "../../../../assets/images";

const { Text } = Typography;

const ReservationTable: React.FC<{
  reservations: Reservation[];
  loading: boolean;
  totalReservations?: number;
}> = ({ reservations, loading, totalReservations }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useApiCall();

  const [reservationData, setReservationData] = useState<
    reservationTableColumnType[]
  >([]);
  const [requestToShow, setRequestToShow] = useState<ReservationTableType>();
  const [loadingRequest, setLoadingRequest] = useState(false);

  const reservationColumns: ColumnsType<reservationTableColumnType> = [
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.PERSON_ICON_BLACK} alt="" height={16} />{" "}
          <span>Retreat Host</span>
        </div>
      ),
      dataIndex: "retreatHost",
      key: "retreatHost",
      render: (retreatHost: RetreatHost) => {
        return (
          <div className="d-flex gap-2 py-2 pe-2">
            <img
              src={retreatHost?.profile_picture_url || images.PERSON_AVATAR}
              alt="host"
              width={40}
              height={40}
              className="rounded"
            />
            <div className="d-flex flex-column justify-content-between">
              <p>{retreatHost?.name}</p>
              <p className="common-xs-light-text">{retreatHost?.city}</p>
            </div>
          </div>
        );
      },
      width: "212px",
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.USERS_THREE} alt="" height={16} />{" "}
          <span>Group Size</span>
        </div>
      ),
      dataIndex: "groupSize",
      key: "groupSize",
      width: "144px",
      render: (text: any) => (
        <div className="d-flex gap-2 align-items-center justify-content-center ">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.CALENDAR_DOT} alt="" height={16} />{" "}
          <span> Check In</span>
        </div>
      ),
      dataIndex: "checkIn",
      key: "checkIn",
      width: "141px",
      render: (_, { checkIn }) => {
        return (
          <div>
            {dayjs(checkIn as string).format("MMM DD")},
            {dayjs(checkIn as string).format("YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.CALENDAR_DOT} alt="" height={16} />{" "}
          <span> Check Out</span>
        </div>
      ),
      dataIndex: "checkOut",
      key: "checkOut",
      width: "167px",
      render: (_, { checkOut }) => {
        return (
          <div>
            {dayjs(checkOut as string).format("MMM DD")},
            {dayjs(checkOut as string).format("YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.STAR_BLACK_ICON} alt="" height={16} />
          <span> Status</span>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        const tag =
          text === "Pending"
            ? "Requested"
            : text === "Approved"
            ? "Scheduled"
            : text === "Canceled"
            ? "Canceled"
            : text === "Rejected"
            ? "Declined"
            : "Concluded";
        return <span className={`status ${text.toLowerCase()}`}>{tag}</span>;
      },
      width: "150px",
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.COIN_ICON} alt="" height={16} />
          <span> Total Payout</span>
        </div>
      ),
      dataIndex: "totalPayout",
      key: "totalPayout",
      width: "168px",
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.HOUSE_ICON} alt="" height={16} />{" "}
          <span> Venue Listing </span>{" "}
        </div>
      ),
      dataIndex: "venueListing",
      key: "venueListing",
      width: "291px",
      render: (text: any) => (
        <div className="d-flex gap-2 align-items-center ">
          <Text style={{ width: 262 }} ellipsis={{ tooltip: text }}>
            <span>{text}</span>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <img src={images.ID_ICON} alt="" height={16} />{" "}
          <span> Reservation ID </span>
        </div>
      ),
      dataIndex: "reservationId",
      key: "reservationId",
      render: (text: any) => (
        <div className="d-flex justify-content-between gap-3">
          <Text style={{ width: 157 }} ellipsis={{ tooltip: text }}>
            <span>{text}</span>
          </Text>
        </div>
      ),
      width: "157px",
    },
    {
      key: "action",
      dataIndex: "action",
      className: "action-button",
      width: "61px",
      render: (reservation: ReservationTableType) => {
        const content = (
          <div
            className={`actions-container venue-cards-action-container reservation-popup`}
          >
            <button
              type="button"
              className="border-0 bg-white"
              disabled={loadingRequest}
              onClick={() =>
                handleReservationDetails({ reservationId: reservation.id })
              }
            >
              <img src={images.EYE_ICON} alt="" height={"24px"} />
              <span>View Details</span>
            </button>
            <ModalComponent modalName={`reservation-details-${reservation.id}`}>
              <UpcomingReservationDetailModal
                reservationDetails={requestToShow}
                reservationPage={true}
              />
            </ModalComponent>
            {reservation.status === "Approved" &&
              !reservation.is_cancelled &&
              !reservation.is_completed && (
                <button
                  type="button"
                  className="border-0 bg-white"
                  disabled={loadingRequest}
                  onClick={() =>
                    handleReservationDetails({
                      cancelReservation: true,
                      reservationId: reservation.id,
                    })
                  }
                >
                  <img src={images.CROSS_ICON} alt="" height={"24px"} />
                  <span>Cancel Reservation</span>
                </button>
              )}
          </div>
        );
        return (
          <div className="d-flex gap-2 align-items-center justify-content-center">
            {reservation?.retreat_host?.user_id && (
              <button
                type="button"
                className="bg-transparent border-0 p-0"
                onClick={() => {
                  navigate("/venues/messages");
                }}
              >
                <MessageSquare width={20} height={20} color="#2771A3" />
              </button>
            )}
            <Popover
              placement="left"
              content={content}
              trigger="click"
              rootClassName="popups"
            >
              <EllipsisVertical width={17} height={20} />
            </Popover>
          </div>
        );
      },
    },
  ];

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const handleReservationDetails = ({
    reservationId,
    cancelReservation = false,
  }: {
    reservationId: string;
    cancelReservation?: boolean;
  }) => {
    setLoadingRequest(true);
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        setLoadingRequest(false);
        setRequestToShow(res?.data?.data);

        const data: ReservationTableType = res?.data?.data;

        const reservationData: ReservationTableType = {
          id: data?.id,
          add_on_services: data?.add_on_services,
          check_in_date: data?.check_in_date,
          check_out_date: data?.check_out_date,
          group_size: data?.group_size,
          total_price: data?.total_price,
          approval_date: data?.approval_date,
          desired_premium: data?.desired_premium,
          discount_code_details: data?.discount_code_details,
          early_bird_discount_details: data?.early_bird_discount_details,
          form_step: data?.form_step,
          guest_cancellation_policy: data?.guest_cancellation_policy,
          guest_payment_plan: data?.guest_payment_plan,
          host: data?.host,
          included_add_ons: data?.included_add_ons,
          is_cancelled: data?.is_cancelled,
          is_deactivated: data?.is_deactivated,
          is_draft: data?.is_draft,
          is_share_option: data?.is_share_option,
          reservation_id: data?.reservation_id,
          special_request: data?.special_request,
          status: data?.status,
          retreat_pricing: data?.retreat_pricing,
          third_party_marketing_sites: data?.third_party_marketing_sites,
          venue: data?.venue,
        };

        if (!cancelReservation) {
          openModal(`reservation-details-${reservationId}`);
        } else {
          dispatch(setLastRoute("/venues/reservations"));
          dispatch(setReservation({ data: reservationData }));
          navigate(`/venues/cancel`);
        }
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        setLoadingRequest(false);
      }
    );
  };

  useEffect(() => {
    const formattedData = reservations?.map((item: any, index: number) => ({
      key: index + 1,
      retreatHost: item.retreat_host,
      groupSize: item.group_size,
      checkIn: item.check_in_date,
      checkOut: item.check_out_date,
      status: !item.is_cancelled
        ? item?.is_completed
          ? "Completed"
          : item.status
        : "Canceled",
      totalPayout: `$ ${numberSeparator(item.total_price, true)}`,
      venueListing: item.venue_name,
      reservationId: item.reservation_id,
      action: item,
    }));

    setReservationData(formattedData);
  }, [reservations]);

  return (
    <TableComponent
      columns={reservationColumns}
      data={reservationData}
      pageSize={10}
      scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
      tableLayout="fixed"
      paginationData={{
        total: totalReservations,
      }}
      loading={loading}
      locale={{
        emptyText: "No information to display",
      }}
    />
  );
};

export default ReservationTable;
