import React from "react";
//third party
import { Spin } from "antd";

const Loader: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center m-auto w-100 vh-100 overflow-hidden loader">
      <Spin size="large" />
    </div>
  );
};

export default Loader;
