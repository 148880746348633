/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party
import {
  Form,
  FormProps,
  Image,
  Space,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

// custom component
import InputComponent from "../../../../UI/InputComponent";
import FormLabel from "../../../../UI/FormLabel";
import Button from "../../../../UI/Button";
import SelectOption from "../../../../UI/SelectOption";
import TextareaComponent from "../../../../UI/TextareaComponent";
import ModalComponent from "../../../../UI/ModalComponent";
import SmallText from "../../../../UI/SmallText";

// redux
import { setShowModal } from "../../../../../redux/features/component-slice";
import { RootState } from "../../../../../redux/store";

// network
import {
  deleteBulkImages,
  deleteRoom,
  fetchBedTypes,
  fetchRoomAmenities,
  updateSpaceDetails,
  uploadImage,
} from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// helpers
import { getBase64 } from "../../../../../library";

// types
import { FileType } from "../../../../../types";
import {
  EditRoomInfoModalProps,
  RoomModalFormTypes,
} from "../../../../../types/property-manager";

// images
import { images } from "../../../../../assets/images";

// constants
import { DEFAULT_NUMBER_OPTION } from "../../../../../constants/property-manager";

const EditRoomInfo: React.FC<EditRoomInfoModalProps> = ({
  fetchDetails,
  roomData,
}) => {
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [amenities, setAmenities] = useState<string[]>([]);
  const [rooms_quantity, setRoomsQuantity] = useState(1);

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [roomAmenities, setRoomAmenities] = useState<
    {
      amenity_id: string;
      name: string;
      icon_id: string;
    }[]
  >([]);
  const [beds, setBeds] = useState<{ bed_type: string; bed_id: string }[]>([]);
  const [roomImages, setRoomImages] = useState<
    {
      id: string;
      image_url: string;
    }[]
  >([]);

  const [hoveredImage, setHoveredImage] = useState<number | null>(null);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const { call } = useApiCall();

  const imageUploadProps: UploadProps = {
    name: "images",
    multiple: true,
    fileList: fileList,
    listType: "picture-card",
    accept: "image/*",
    beforeUpload(file) {
      const isImage = file.type && file.type.startsWith("image/");

      if (!isImage) {
        toast.error("You can only upload image files.");
        return Upload.LIST_IGNORE;
      }
      setFileList((prevFileList) => {
        const updatedFileList = [...prevFileList, file];
        form.setFieldsValue({ roomImages: updatedFileList }); // Update form field
        return updatedFileList;
      });
      return false;
    },

    onChange({ fileList: newFileList }) {
      const filteredFileList = newFileList.filter(
        (file) => file.type && file.type.startsWith("image/")
      );
      setFileList(filteredFileList);
      form.validateFields(["roomImages"]).then(() => {
        form.setFields([
          {
            name: "roomImages",
            errors: [],
          },
        ]);
      });
    },

    onRemove(file) {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      form.setFieldsValue({ roomImages: newFileList });
    },

    onPreview: async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
  };

  const uploadButton = (
    <button className="border-0 bg-white" type="button">
      <img src={images.UPLOAD_BUTTON_ICON} alt="add_photo_icon" />
    </button>
  );

  const handleMouseEnter = (index: number) => {
    setHoveredImage(index);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const handleFormChange = () => {
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    setIsFormValid(!hasErrors); // Disable if errors or fields aren't touched
  };

  const getAmenities = async () => {
    call(
      () => fetchRoomAmenities(),
      (res) => {
        setRoomAmenities(res.data.data);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch room amenities"
        );
      }
    );
  };

  const getBeds = async () => {
    call(
      () => fetchBedTypes(),
      (res) => {
        setBeds(res.data.data);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch bed types"
        );
      }
    );
  };

  const handleDeleteImage = async () => {
    call(
      () => deleteBulkImages({ reference_ids: imagesToDelete }),
      (res) => {
        setImagesToDelete([]);
      }
    );
  };

  const handleDeleteRoom = async (roomId: string) => {
    setSubmitting(true);
    call(
      () => deleteRoom({ space_id: roomId, space_type: "Room" }),
      (res) => {
        dispatch(
          setShowModal({
            modalName: `delete-room-${roomId}`,
            visible: false,
          })
        );
        dispatch(
          setShowModal({
            modalName: roomId as string,
            visible: false,
          })
        );
        toast.success(res.data?.message || "Room deleted successfully");
        setSubmitting(false);
        fetchDetails();
      },
      () => {
        toast.error("Failed to delete room");
        setSubmitting(false);
      }
    );
  };

  const handleStoreImagesToDelete = (id: string) => {
    setImagesToDelete([...imagesToDelete, id]);
    setRoomImages(roomImages.filter((image) => image.id !== id));
  };

  const handleFinalSubmit = async (
    values: RoomModalFormTypes,
    images?: string[]
  ) => {
    const allImages = [
      ...roomImages.map((image) => image.id), // existing images (just their IDs)
      ...(images || []), // new images
    ];

    values.images = allImages;
    values.amenities = amenities;
    values.rooms_quantity = rooms_quantity;

    const data = {
      name: values.name,
      rooms_quantity: values.rooms_quantity,
      room_description: values.room_description,
      beds: values.beds,
      images: images,
      amenities: values.amenities,
      space_type: "Room",
    };

    if (imagesToDelete.length > 0) {
      handleDeleteImage();
    }

    call(
      () =>
        updateSpaceDetails({
          space_id: roomData?.id as string,
          data: data,
        }),
      (res) => {
        form.resetFields();
        setFileList([]);
        dispatch(setShowModal({ modalName: roomData?.id, visible: false }));
        toast.success(res.data?.message || "Room created successfully");
        setSubmitting(false);
        fetchDetails();
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to create room");
        setSubmitting(false);
      }
    );
  };

  const onFinish: FormProps<RoomModalFormTypes>["onFinish"] = async (
    values
  ) => {
    setSubmitting(true);
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.map(async (file) => {
        formData.append("files", file.originFileObj as any);
      });

      call(
        () => uploadImage(formData),
        (res) => {
          handleFinalSubmit(values, res.data.data);
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to upload images"
          );
          setSubmitting(false);
        }
      );
    } else {
      handleFinalSubmit(values);
    }
  };

  useEffect(() => {
    getAmenities();
    getBeds();

    setAmenities(roomData?.amenities.map((amenity) => amenity?.id || "") || []);
  }, []);

  // Assuming roomData.image_urls is your API response array
  useEffect(() => {
    if (!roomData) return;

    setRoomImages(roomData.image_urls);
  }, [roomData]);

  useEffect(() => {
    if (
      (roomImages.length > 0 || fileList.length > 0) &&
      form.getFieldValue("name") &&
      form.getFieldValue("rooms_quantity") &&
      form.getFieldValue("room_description") &&
      form.getFieldValue("beds") &&
      form.getFieldValue("amenities")
    ) {
      setIsFormValid(true);
    }
    if (roomImages.length === 0 && fileList.length === 0) {
      setIsFormValid(false);
    }
  }, [roomImages, form.getFieldsValue()]);

  return (
    <div className="room-modal-info-container">
      <div className="room-modal-subtitle">
        Edit and create the necessary information about the space
      </div>
      <Form
        form={form}
        className="room-modal-form-container"
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
      >
        <div className="room-modal-form">
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                  initialValue={roomData?.name}
                >
                  <div className="d-flex flex-column gap-1">
                    <FormLabel label="Room Name" />
                    <InputComponent
                      type="text"
                      placeholder="Enter space name"
                      width={"100%"}
                      defaultValue={roomData?.name}
                    />
                  </div>
                </Form.Item>
              </div>
              <div style={{ minWidth: "112px" }}>
                <div className="d-flex flex-column gap-1">
                  <FormLabel label="Quantity" />
                  <Form.Item
                    name="rooms_quantity"
                    rules={[
                      {
                        // if rooms quantity is less null or 0 then show error
                        required: true,
                        message: "Quantity is required",
                      },
                    ]}
                    initialValue={roomData?.quantity}
                  >
                    <SelectOption
                      onChange={(value) => {
                        setRoomsQuantity(value);
                      }}
                      defaultValue={
                        roomData?.quantity && roomData?.quantity > 0
                          ? roomData?.quantity
                          : 1
                      }
                      options={DEFAULT_NUMBER_OPTION}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <Form.Item
                  name="room_description"
                  rules={[
                    { required: true, message: "Description is required" },
                  ]}
                  initialValue={roomData?.description}
                >
                  <div className="d-flex flex-column gap-1">
                    <FormLabel label="Room Description" />
                    <TextareaComponent
                      placeholder="Enter your text here"
                      defaultValue={roomData?.description}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="typeOfBedContainer w-100">
              <div className="d-flex gap-2 w-100">
                <div className="flex-grow-1">
                  <FormLabel label="Bed Type" />
                </div>
                <div style={{ minWidth: "112px" }}>
                  <FormLabel label="Quantity" />
                </div>
                <div style={{ width: "44px" }}></div>
              </div>
              <Form.List
                name="beds"
                initialValue={
                  roomData?.beds?.map((item) => {
                    return {
                      bed_type: item?.bed_type,
                      quantity: item?.quantity,
                      bed_id: item?.bed_id, // Include bed_id here
                    };
                  }) || [{ bed_type: "", quantity: null, bed_id: "" }]
                }
                rules={[
                  {
                    validator(value) {
                      if (value.len === 0) {
                        return Promise.reject(new Error("Missing bed type"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <div className="d-flex gap-2 w-100">
                          <div className="flex-grow-1">
                            <Form.Item
                              {...restField}
                              name={[name, "bed_type"]}
                              rules={[
                                { required: true, message: "Missing bed type" },
                              ]}
                            >
                              <SelectOption
                                options={beds?.map((item) => {
                                  return {
                                    value: item.bed_type,
                                    label: item.bed_type,
                                  };
                                })}
                                onChange={(selectedBedType) => {
                                  const selectedBed = beds.find(
                                    (bed) => bed.bed_type === selectedBedType
                                  );
                                  // Automatically set the bed_id when a bed_type is selected
                                  form.setFieldsValue({
                                    beds: form
                                      .getFieldValue("beds")
                                      .map((bed: any, idx: number) => {
                                        if (idx === name) {
                                          return {
                                            ...bed,
                                            bed_id: selectedBed?.bed_id || "",
                                          };
                                        }
                                        return bed;
                                      }),
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div style={{ minWidth: "112px" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "quantity"]}
                              rules={[
                                { required: true, message: "Missing quantity" },
                              ]}
                            >
                              <SelectOption options={DEFAULT_NUMBER_OPTION} />
                            </Form.Item>
                          </div>
                          <button
                            type="button"
                            onClick={() => remove(name)}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            disabled={fields.length === 1}
                          >
                            <img src={images.BIN_ICON} alt="delete_icon" />
                          </button>
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <button
                        type="button"
                        onClick={() => add()}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <SmallText text="+ Add More" />
                      </button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100 create-venue-file-upload">
              <div className="flex-grow-1">
                <div className="d-flex flex-column gap-1 spaces-image-upload">
                  <FormLabel label="Upload Room Photos" />
                  <Form.Item name="roomImages">
                    {fileList.length === 0 && roomImages.length === 0 && (
                      <Dragger {...imageUploadProps} defaultFileList={[]}>
                        <p className="ant-upload-text">
                          <img
                            src={images.UPLOAD_BLACK_ICON}
                            alt="upload icon"
                            height={24}
                          />
                          <br />
                          Upload a photo or{" "}
                          <SmallText text="click here" fontSize={16} />
                        </p>
                      </Dragger>
                    )}
                    {(fileList.length > 0 || roomImages.length > 0) && (
                      <Upload
                        {...imageUploadProps}
                        style={{ marginTop: "12px !important" }}
                      >
                        {fileList.length >= 24 ? null : uploadButton}
                      </Upload>
                    )}
                    {previewImage && (
                      <>
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      </>
                    )}
                    <div className="d-flex gap-3 flex-wrap mt-3">
                      {roomImages?.map((image, index) => (
                        <div
                          className="image-cards"
                          key={`image-${index}`}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img
                            src={image.image_url}
                            alt=""
                            className="upload-image"
                          />
                          {hoveredImage === index && (
                            <div className="image-card-hover">
                              <button
                                type="button"
                                onClick={() => {
                                  setPreviewImage(image.image_url);
                                  setPreviewOpen(true);
                                }}
                              >
                                <EyeOutlined style={{ color: "#fff" }} />
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  handleStoreImagesToDelete(image.id)
                                }
                              >
                                <DeleteOutlined style={{ color: "#fff" }} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      {previewImage && (
                        <>
                          <Image
                            wrapperStyle={{ display: "none" }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                              afterOpenChange: (visible) =>
                                !visible && setPreviewImage(""),
                            }}
                            src={previewImage}
                          />
                        </>
                      )}
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <div className="d-flex flex-column gap-1">
                  <FormLabel label="Room Amenities" />
                  <div
                    className={`d-flex flex-column gap-1 recommended_retreats`}
                  >
                    <Form.Item
                      name="amenities"
                      rules={[
                        { required: true, message: "Amenities are required" },
                      ]}
                      initialValue={roomData?.amenities.map((item) => {
                        const icon = icons?.find(
                          (icon) => icon.id === item.icon_id
                        );
                        return {
                          value: item.id,
                          label: (
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  icon?.icon_url ||
                                  images.AIR_CONDITIONING_BLUE_ICON
                                }
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span style={{ marginLeft: "8px" }}>
                                {item.name}
                              </span>
                            </div>
                          ),
                        };
                      })}
                    >
                      <SelectOption
                        showSearch={false}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Write or select"
                        onChange={(value) => {
                          setAmenities(value);
                        }}
                        defaultValue={roomData?.amenities.map((item) => {
                          const icon = icons?.find(
                            (icon) => icon.id === item.icon_id
                          );
                          return {
                            value: item.id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    icon?.icon_url ||
                                    images.AIR_CONDITIONING_BLUE_ICON
                                  }
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "8px" }}>
                                  {item.name}
                                </span>
                              </div>
                            ),
                          };
                        })}
                        options={roomAmenities?.map((item) => {
                          const icon = icons?.find(
                            (icon) => icon.id === item.icon_id
                          );
                          return {
                            value: item.amenity_id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    icon?.icon_url ||
                                    images.AIR_CONDITIONING_BLUE_ICON
                                  }
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "8px" }}>
                                  {item.name}
                                </span>
                              </div>
                            ),
                          };
                        })}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-between">
            <Button
              type="button"
              label="Cancel"
              className="bordered-button"
              onClick={() => {
                dispatch(
                  setShowModal({ modalName: roomData?.id, visible: false })
                );
              }}
            />
            <div className="d-flex gap-2">
              <Button
                type="button"
                label="Delete"
                style={{
                  border: "1px solid red",
                  color: "red",
                }}
                className="bordered-button"
                onClick={() => {
                  dispatch(
                    setShowModal({
                      modalName: `delete-room-${roomData?.id}`,
                      visible: true,
                    })
                  );
                }}
              />

              <ModalComponent
                modalName={`delete-room-${roomData?.id}`}
                title=""
                className="modal-space-info"
              >
                <div className="delete-modal d-flex flex-column">
                  <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={images.DELETE_IMG}
                      alt="Delete Icon"
                      height={"96px"}
                    />
                    <h2 className="common-xl-bold-dark-text">
                      Delete shared space
                    </h2>
                    <p className="common-sm-light-grey-text">
                      Are you sure you want to delete this space?
                    </p>
                    <p
                      className="common-sm-bold-dark-text"
                      style={{
                        color: "#F63030",
                      }}
                    >
                      This action is irreversible.
                    </p>
                  </div>
                  <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
                    <Button
                      type="button"
                      label="Cancel"
                      className="bordered-button"
                      onClick={() => {
                        dispatch(
                          setShowModal({
                            modalName: `delete-room-${roomData?.id}`,
                            visible: false,
                          })
                        );
                      }}
                    />

                    <Button
                      type="submit"
                      label={
                        submitting === true
                          ? "Deleting space..."
                          : "Delete space"
                      }
                      onClick={() => {
                        handleDeleteRoom(roomData?.id as string);
                      }}
                      disabled={submitting === true}
                      className={"btn-delete"}
                    />
                  </div>
                </div>
              </ModalComponent>

              <Button
                type="submit"
                label={submitting === true ? "Saving..." : "Save"}
                onClick={() => onFinish}
                disabled={submitting || !isFormValid}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditRoomInfo;
