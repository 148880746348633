import React, { useState } from "react";

//third party
import { DeleteOutlined } from "@ant-design/icons";
//common component
import SmallText from "../../../UI/SmallText";
import StepFormHeader from "../../../UI/StepForm/StepFormHeader";
import CKEditorComponent from "../../../UI/CKEditor/CKEditorComponent";
import ImagePicker from "../../../UI/ImagePicker/ImagePicker";
import StepFormFooter from "./StepFormFooter";
//types
import { FormFooterProps } from "../../../../types/retreat";
//images
import { images } from "../../../../assets/images";
//css
import "../../../../assets/css/about-retreat.css";

const AboutRetreat: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
  handleExit,
}) => {
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [sections, setSections] = useState<{}[]>([]);

  const handleAddSection = () => {
    setSections([...sections, {}]);
  };

  const handleRemoveSection = (index: number) => {
    setSections(sections.filter((_, i) => i !== index));
  };

  return (
    <>
      <div
        className="container about-retreat"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <div className="d-flex flex-column gap-5">
          <StepFormHeader
            title="Retreat booking page"
            description="Fill in the title and description of your retreat, list what's included and what's not, and add extra photos."
          />
          <div className="d-flex  flex-column gap-5">
            <div className="d-flex flex-column gap-1">
              <SmallText
                text="Retreat Title"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Write the name here"
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <SmallText
                text="Describe about  retreat"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              {!showEditor ? (
                <textarea
                  className="form-control"
                  placeholder="Talk about your retreat"
                  rows={1}
                  onClick={() => setShowEditor(true)}
                ></textarea>
              ) : (
                <CKEditorComponent placeholder="Talk about your retreat" />
              )}
            </div>
            <div className="d-flex flex-column gap-3">
              {sections.length === 0 ? (
                <div className="d-flex flex-column gap-1">
                  <SmallText
                    text="Custom paragraph"
                    color="#58585B"
                    lineHeight={18.8}
                    fontWeight={500}
                    fontSize={16}
                  />
                  <SmallText
                    text="Include detailed information tailored to your audience or objective."
                    color="#B4B3B7"
                    lineHeight={18.8}
                    fontWeight={400}
                    fontSize={16}
                  />
                </div>
              ) : (
                sections.map((section, index) => (
                  <div key={index} className="d-flex flex-column gap-3 ">
                    <div className="d-flex justify-content-between">
                      <SmallText
                        text={`Custom paragraph ${index + 1}`}
                        color="#58585B"
                        fontSize={18}
                        fontWeight={400}
                        lineHeight={21.15}
                      />
                      <button
                        className="custom-btn"
                        onClick={() => handleRemoveSection(index)}
                      >
                        <DeleteOutlined />
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <SmallText
                        text="Title"
                        color="#58585B"
                        lineHeight={16.45}
                        fontWeight={500}
                        fontSize={14}
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write the title here"
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <SmallText
                        text="Description"
                        color="#58585B"
                        lineHeight={16.45}
                        fontWeight={500}
                        fontSize={14}
                      />
                      <CKEditorComponent placeholder="Enter text here" />
                    </div>
                  </div>
                ))
              )}

              <button className="custom-btn" onClick={handleAddSection}>
                <img src={images.PLUS} alt="plus" width={16} height={16} /> New
                custom paragraph
              </button>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-1">
                <SmallText
                  text="Branding"
                  color="#19191C"
                  lineHeight={21.15}
                  fontWeight={500}
                  fontSize={18}
                />
                <SmallText
                  text="Personalize your retreat's visual identity by uploading your logo and defining a custom URL"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={400}
                  fontSize={14}
                />
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex gap-4">
                  <div>
                    <SmallText
                      text="Your logo"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                    <ImagePicker />
                  </div>
                  <div>
                    <SmallText
                      text="Brochure"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                    <ImagePicker />
                  </div>
                  <div>
                    <SmallText
                      text="Additional photos"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                    <ImagePicker />
                  </div>
                </div>
                <div className="d-flex align-items-end  gap-4  w-100">
                  <div className="d-flex flex-column flex-grow-1 gap-1">
                    <SmallText
                      text="Site URL"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                    <input type="url" className="form-control" />
                  </div>
                  <button className="disable-submit-btn">
                    /laigoretreats.com
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepFormFooter
        handleExit={handleExit}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
        buttonText="continue"
      />
    </>
  );
};

export default AboutRetreat;
