/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./style.css";

const GuestCounter: React.FC<{
  value: number;
  onChange: (value: number) => void;
  maxGuests?: number;
  className?: string;
  defaultValue?: number;
}> = ({ value, onChange, maxGuests, defaultValue, className }) => {
  const increment = () => onChange(value + 1);
  const decrement = () => onChange(value > 1 ? value - 1 : 1);

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      className={`guest-counter d-flex align-items-center w-100 ${className}`}
    >
      <button type="button" onClick={decrement} disabled={value <= 1}>
        -
      </button>
      <span style={{ margin: "0 10px" }}>{value}</span>
      <button
        type="button"
        onClick={increment}
        disabled={maxGuests ? (value >= maxGuests ? true : false) : false}
      >
        +
      </button>
    </div>
  );
};

export default GuestCounter;
