//third party
import { CollapseProps, TableColumnsType } from "antd";
//common component
import SmallText from "../../components/UI/SmallText";

//images
import { images } from "../../assets/images";
import { demo_images as DemoImages } from "../../assets/images/demo-images";

export const HostHeaderTab = [
  {
    title: "Photos",
    link: "#photos",
  },
  {
    title: "Overview",
    link: "#overview",
  },
  {
    title: "Schedule",
    link: "#schedule",
  },
  {
    title: "Accommodation",
    link: "#accommodation",
  },
  {
    title: "Cancelation Policy",
    link: "#cancelationPolicy",
  },
  {
    title: "Amenities & Add-on Packages",
    link: "#amenitiesAddOnPackages",
  },
  {
    title: "Location",
    link: "#location",
  },
  {
    title: "Retreat Host",
    link: "#retreatHost",
  },
  {
    title: "Instructors",
    link: "#instructors",
  },
];

export const moneyItems = [
  { label: "item 1", key: "item-1" },
  { label: "item 2", key: "item-2" },
];

export const ABOUT_VENUE = [
  {
    image: images.SMILE_ICON,
    title: "Calm",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Unique",
  },
  {
    image: images.TREE_ICON,
    title: "Peaceful",
  },
];

export const AccommodationImage = [
  {
    image: DemoImages.DEMO_IMAGE_1,
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    actualPrice: "$1000",
    discountPrice: "$800",
  },
  {
    image: DemoImages.DEMO_IMAGE_2,
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    actualPrice: "$1000",
    discountPrice: "$800",
  },
  {
    image: DemoImages.DEMO_IMAGE_3,
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    actualPrice: "$1000",
    discountPrice: "$800",
  },
  {
    image: DemoImages.DEMO_IMAGE_4,
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    actualPrice: "$1000",
    discountPrice: "$800",
  },
];

export const RetreatAmenitiesIcon = [
  {
    image: images.SMILE_ICON,
    title: "Calm",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Unique",
  },
  {
    image: images.TREE_ICON,
    title: "Peaceful",
  },
  {
    image: images.SMILE_ICON,
    title: "Calm",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Unique",
  },
  {
    image: images.TREE_ICON,
    title: "Peaceful",
  },
  {
    image: images.SMILE_ICON,
    title: "Calm",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Unique",
  },
  {
    image: images.TREE_ICON,
    title: "Peaceful",
  },
  {
    image: images.SMILE_ICON,
    title: "Calm",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Unique",
  },
  {
    image: images.TREE_ICON,
    title: "Peaceful",
  },
];

export const RetreatAmenitiesPackageIcon = [
  {
    image: images.BUTTERFLY_ICON,
    title: "Airport Transfer",
    descriptions:
      "We offer private transportation to and from Bern Airport, ensuring a smooth journey to your accommodation.",
    extraPrice: "Free",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Airport Transfer",
    descriptions:
      "We offer private transportation to and from Bern Airport, ensuring a smooth journey to your accommodation.",
    extraPrice: "$300",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Airport Transfer",
    descriptions:
      "We offer private transportation to and from Bern Airport, ensuring a smooth journey to your accommodation.",
    extraPrice: "$740",
  },
  {
    image: images.BUTTERFLY_ICON,
    title: "Airport Transfer",
    descriptions:
      "We offer private transportation to and from Bern Airport, ensuring a smooth journey to your accommodation.",
    extraPrice: "$120",
  },
];
export const RetreatInstructor = [
  {
    image: DemoImages.DEMO_IMAGE_1,
    name: "Joana Miler",
  },
  {
    image: DemoImages.DEMO_IMAGE_2,
    name: "Leo Doe",
  },
];
export const RetreatInstructor1 = [
  {
    image: DemoImages.DEMO_IMAGE_1,
    name: "Joana Miler",
  },
];

const text = `
Check in starts at 2PM. The retreat officially starts at 4:30PM. Plan to be at the venue close to 2PM so you have time to settle in and join us for the opening ceremony and dinner.Check out for the retreat is at 11:30AM on the last day
`;
export const dropDownItems: CollapseProps["items"] = [
  {
    key: "1",
    label: "Do I need to have experience with yoga?",
    children: <p className="section-description">{text}</p>,
  },
  {
    key: "2",
    label: "What time is check in?",
    children: <p className="section-description">{text}</p>,
  },
  {
    key: "3",
    label: "What should I bring to the retreat?",
    children: <p className="section-description">{text}</p>,
  },
];
export const items = [
  { label: "item 1", key: "item-1" },
  { label: "item 2", key: "item-2" },
];

export interface DataType {
  key: string;
  room: React.ReactNode;
  basePrice: React.ReactNode;
  tickets: React.ReactNode;
  markup: React.ReactNode;
  value: React.ReactNode;
}

export const columns: TableColumnsType<DataType> = [
  {
    title: (
      <div className="d-flex">
        <div className="d-flex gap-2 flex-shrink-0">
          <img src={images.IslandRoom} alt="room" width={16} height={16} />
          <SmallText
            text="Room"
            color="#58585B"
            fontWeight={400}
            lineHeight={16.45}
            fontSize={14}
          />
        </div>
      </div>
    ),
    dataIndex: "room",
    key: "room",
    ellipsis: true,
  },
  {
    title: (
      <div className="d-flex">
        <div className="d-flex gap-2 flex-shrink-0">
          <img src={images.BasePrice} alt="room" width={16} height={16} />
          <SmallText
            text="Base Price"
            color="#58585B"
            fontWeight={400}
            lineHeight={16.45}
            fontSize={14}
          />
        </div>
      </div>
    ),
    dataIndex: "basePrice",
    key: "basePrice",
    ellipsis: true,
  },
  {
    title: (
      <div className="d-flex">
        <div className="d-flex gap-2 flex-shrink-0">
          <img src={images.MarkUp} alt="room" width={16} height={16} />
          <SmallText
            text="Price Markup"
            color="#58585B"
            fontWeight={400}
            lineHeight={16.45}
            fontSize={14}
          />
        </div>
      </div>
    ),
    dataIndex: "markup",
    key: "markup",
    ellipsis: true,
  },
  {
    title: (
      <div className="d-flex">
        <div className="d-flex gap-2 flex-shrink-0">
          <img src={images.COIN} alt="room" width={16} height={16} />
          <SmallText
            text="Value"
            color="#58585B"
            fontWeight={400}
            lineHeight={16.45}
            fontSize={14}
          />
        </div>
      </div>
    ),
    dataIndex: "value",
    key: "value",
    ellipsis: true,
  },
  {
    title: (
      <div className="d-flex">
        <div className="d-flex gap-2 flex-shrink-0">
          <img src={images.Tickets} alt="room" width={16} height={16} />
          <SmallText
            text="#Tickets"
            color="#58585B"
            fontWeight={400}
            lineHeight={16.45}
            fontSize={14}
          />
        </div>
      </div>
    ),
    dataIndex: "tickets",
    key: "tickets",
    ellipsis: true,
  },
];
export const retreatTypeOptions = [
  {
    value: "true",
    label: "Yes, it is",
    description: "Only one organization will be paying for the entire retreat.",
  },
  {
    value: "false",
    label: "No, it's not",
    description:
      "The retreat will be priced per person and booked individually.",
  },
];

export const paymentsPlan = [
  {
    value: "No Deadline",
    label: "No deadline",
    description: "No time constraints, access at standard pricing.",
  },
  {
    value: "30",
    label: "30 Days in Advance",
    description: "10% early bird discount",
  },
  {
    value: "14",
    label: "14 Days in Advance",
    description: "Priority access to exclusive workshops and activities",
  },
  {
    value: "7",
    label: "7 Days in Advance",
    description: "Welcome package with additional materials",
  },
  {
    value: "Last Minutes",
    label: "Last-Minute Booking",
    description: "Subject to availability, no additional benefits",
  },
  {
    value: "Flexible Booking",
    label: "Flexible Booking",
    description:
      "Flexibility for last-minute changes, subject to additional fees",
  },
];

export const policyPlans = [
  {
    value: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
    label: "Rigid Non-Refundable Policy",
    description:
      "Cancellation: Cancellations made at any time after booking will not be eligible for any refund.Changes: No changes to the booking (including dates or participant names) are allowed once the reservation is confirmed.Transfer: Bookings are not transferable to another person or a future retreat.",
  },
  {
    value: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    label: "Flexible Partial Refund Policy",
    description:
      "Cancellation: More than 30 days before the retreat: 80% refund of the total booking amount.14-30 days before the retreat: 50% refund of the total booking amount. Less than 14 days before the retreat: No refund available.Changes: More than 14 days before the retreat: Changes to the booking (including dates or participant names) are allowed with a small administrative fee (e.g., $50).Less than 14 days before the retreat: No changes allowed.Transfer: Bookings can be transferred to another person up to 14 days before the retreat for a small administrative fee (e.g., $50).",
  },
];

export const shareOption = [
  {
    value: "Public",
    label: "Public",
    description: "Only people I invite via the private link",
  },
  {
    value: "Private",
    label: "Private",
    description:
      "The trip is indexes by search engines and shown on your profile",
  },
];

export const shareOptionValue = [
  {
    label: "BookRetreats.com",
    value: "3fa85f64-5717-4562-b3fc-2c963f66afa9",
    description: "(all retreat types)",
  },
  {
    label: "GotoRetreats.com",
    value: "3fa85f64-5717-4562-b3fc-2c963f66afb5",
    description: "(only yoga & mediation retreats)",
  },
  {
    label: "BookYogaRetreats.com",
    value: "3fa85f64-5717-4562-b3fc-2c963f66afb6",
    description: "(only women's retreats)",
  },
  {
    label: "ZenBooking.com",
    value: "3fa85f64-5717-4562-b3fc-2c963f66afb1",
    description: "(all retreat types)",
  },
  {
    label: "Shefari.com",
    value: "3fa85f64-5717-4562-b3fc-2c963f66afb3",
    description: "(all retreat types)",
  },
  {
    label: "WellnessRetreats.com",
    value: "3fa85f64-5717-4562-b3fc-2c963f66afb2",
    description: "(all retreat types)",
  },
];

export const titleOfRetreatPage =
  " Agatha, We know planning a retreat is time consuming";
export const descriptionOfRetreatPage = [
  "That is why LAIGO is here to help. Our easy-to-use software removes the hassle out of retreat planning, saving you time and money. ",
  "Before we get started, let's take a few moments to learn more about each other!",
];
export const stepsOfRetreatHost = [
  {
    title: "About your company",
    stepDescription:
      "Share a summary of your company, name, logo to personalize the booking page",
  },
  {
    title: "Retreat Types",
    stepDescription:
      "Tell us about the types of retreats you offer, such as yoga, meditation or wellness.",
  },
  {
    title: "Destination",
    stepDescription:
      "Detail the locations where your retreats take place, covering destinations and regions",
  },
  {
    title: "Expected Activity",
    stepDescription:
      "Describe the main activities participants can expect on your retreats",
  },
];

export const checkoutModalPackage = [
  {
    imageName: "DEMO_IMAGE_1",
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    price: "$1,800",
    actualPrice: "$2,000",
  },
  {
    imageName: "DEMO_IMAGE_2",
    title: "Shared Triple Room: Aurora",
    description:
      "Perfect for friends who want to share a retreat experience. It offers two single beds with high-quality mattresses to ensure a good night's sleep. The bathroom, shared only with one other room, is modern and well-equipped. It has a spacious shower, a double sink and cupboards for storing toiletries. ",
    price: "$1,800",
    actualPrice: "$2,000",
  },
  {
    imageName: "DEMO_IMAGE_3",
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    price: "$1,800",
    actualPrice: "$2,000",
  },
  {
    imageName: "DEMO_IMAGE_1",
    title: "Shared Triple Room: Aurora",
    description:
      "Shared room and bathroom with 2 others people. Perfect for couples and friends",
    price: "$1,800",
    actualPrice: "$2,000",
  },
];
export const CheckoutAddonDetail = [
  {
    title: "One-on-One Yoga",
    description:
      "Exclusive time with qualified instructors to deepen your practice, receive personalized guidance, and achieve your personal wellness goals",
    price: "$1,800",
    actualPrice: "$2,000",
  },
  {
    title: "Ashtanga Vinyasa: Extra ",
    description:
      "This class is ideal for advanced practitioners who want to intensify their practice and explore the full potential of Ashtanga Vinyasa.",
    price: "$1,800",
  },
  {
    title: "Extra Parking",
    description:
      "This class is ideal for advanced practitioners who want to intensify their practice and explore the full potential of Ashtanga Vinyasa.",
    price: "$1,800",
  },
  {
    title: "Massage sessions",
    description:
      "This class is ideal for advanced practitioners who want to intensify their practice and explore the full potential of Ashtanga Vinyasa.",
    price: "$1,800",
  },
];

export const paymentType = [
  {
    title: "Credit Card",
    value: "creditCard",
  },
  {
    title: "Paypal",
    value: "paypal",
  },
];

export const cardDetail = [
  {
    title: "Per Day",
    image: images.SUN,
    value: "Per day",
  },
  {
    title: "Per Guest",
    image: images.USER,
    value: "Per guest",
  },
  {
    title: "Per Group",
    image: images.USER,
    value: "Per group",
  },
  {
    title: "Per Room",
    image: images.ROOM,
    value: "Per room",
  },
];

export const retreat_dashboard = [
  {
    icon: images.MENU_HOME_ICON,
    label: "Home",
    url: "/host/dashboard",
  },
  // {
  //   icon: images.Retreat,
  //   label: "My Retreats",
  //   url: "/host/my-retreats",
  // },
  // {
  //   icon: images.EXPLORE,
  //   label: "Explore",
  //   url: "/host/explore",
  // },
  {
    icon: images.MENU_MESSAGES_ICON,
    label: "Messages",
    url: "/host/messages",
  },
  // {
  //   icon: images.METRICS,
  //   label: "Metrics",
  //   url: "/host/metrics",
  // },
];

export const retreatTab = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "Pending",
    name: "Pending approval",
  },
  {
    id: "true",
    name: "Draft",
  },
  {
    id: "Approved",
    name: "Published",
  },
  {
    id: "false",
    name: "Completed",
  },
  {
    id: "Rejected",
    name: "Cancelled",
  },
];
