import React from "react";

// Third Party
import { useNavigate } from "react-router-dom";

// custom component
import Button from "../Button";

// Constants
import { USER_TYPE_ROLE } from "../../../constants/Admin";
import { images } from "../../../assets/images";

// style
import "./style.css";

interface DetailsNotFoundProps {
  message?: string;
  userType?: string;
}

const DetailsNotFound: React.FC<DetailsNotFoundProps> = ({
  message,
  userType = USER_TYPE_ROLE.VENUE_MANAGER.key,
}) => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    switch (userType) {
      case USER_TYPE_ROLE.ADMIN.key:
        navigate("/admin/dashboard");
        break;
      case USER_TYPE_ROLE.VENUE_MANAGER.key:
        navigate("/venues/home");
        break;
      default:
        navigate("venues/home");
        break;
    }
  };

  return (
    <div className="not-found-page w-100 vh-100 d-flex flex-column align-items-center gap-3">
      <div className="not-found-image">
        <img src={images.PAGE_NOT_FOUND1} alt="" />
      </div>
      <p className="not-found-text">Page not found</p>
      <Button label="Back to Home" onClick={handleBackToHome} />
    </div>
  );
};

export default DetailsNotFound;
