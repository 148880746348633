import React, { useState, useEffect } from "react";

//third-party
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

//third party css
import "yet-another-react-lightbox/styles.css";

//css
import "./Image.css";

interface ImageProps {
  image_url: string;
  id: string;
}
type ImagePropsType = ImageProps[];

const ImageComponent: React.FC<{ images: ImagePropsType }> = ({ images }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [sliderImages, setSliderImages] = useState<{ src: string }[]>([]);
  //handle Lightbox
  const handleOpenLightBox = (index: number) => {
    setCurrentImageIndex(index);
    setLightboxIsOpen(true);
  };

  //close light box
  const closeLightbox = () => {
    setLightboxIsOpen(false);
    document.body.removeAttribute("style");
    document.body.removeAttribute("class");
  };

  //set images in slider
  useEffect(() => {
    const slides = images.map((image) => ({
      src: image.image_url,
    }));
    setSliderImages(slides);
  }, [images]);

  return (
    <div className="image-gallery" id="photos">
      <div className="main-image" onClick={() => handleOpenLightBox(0)}>
        <img src={images[0].image_url} alt="Main view" />
      </div>
      {images.length > 1 && (
        <div className={`${images.length === 2 ? "two-thumbnails" : "thumbnails"}`}>
          {images.slice(1, 5).map((image, index) => (
            <div
              key={image.id}
              className="thumbnail"
              onClick={() => handleOpenLightBox(index + 1)}
            >
              <img src={image.image_url} alt={`Thumbnail ${index + 1}`} />
            </div>
          ))}
        </div>
      )}

      {images.length > 5 && (
        <div className="more-thumbnails " onClick={() => handleOpenLightBox(4)}>
          +{images.length - 5}
        </div>
      )}

      <Lightbox
        slides={sliderImages}
        open={lightboxIsOpen}
        close={closeLightbox}
        index={currentImageIndex}
        plugins={[Zoom]}
      />
    </div>
  );
};

export default ImageComponent;
