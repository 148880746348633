/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";

// third party
import { Spin } from "antd";
import { Link } from "react-router-dom";

// types
import {
  VideoMetadata,
  VideoPreviewProps,
} from "../../../../../types/property-manager";

// helpers
import { fetchVideoMetadata } from "../../../../../library";

const VideoPreview: React.FC<VideoPreviewProps> = ({ url }) => {
  const [metadata, setMetadata] = useState<VideoMetadata | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(null);

    fetchVideoMetadata(url)
      .then((metadata) => setMetadata(metadata))
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, [url]);

  if (!url) return null;

  if (loading)
    return (
      <div>
        <Spin />
      </div>
    );

  if (error) return <div>Error: {error}</div>;

  if (metadata) {
    const { title, channelName, domain, thumbnailUrl } = metadata;
    return (
      <Link to={url} target="_blank" className="text-decoration-none">
        <div className="video-preview bg-gray-100 rounded-md d-flex gap-3 mt-2">
          {thumbnailUrl && (
            <img
              src={thumbnailUrl}
              alt="Video thumbnail"
              className="w-full rounded-md"
              width={184}
              height={100}
              loading="lazy"
              style={{
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          )}
          <div className="d-flex flex-column justify-content-between gap-3">
            <div>
              <h3 className="common-lg-light-text">{title}</h3>
              <p className="common-sm-dark-text">{channelName}</p>
            </div>
            <p className="common-sm-light-grey-text">{domain}</p>
          </div>
        </div>
      </Link>
    );
  }

  return null;
};

export default VideoPreview;
