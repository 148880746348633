import React from "react";
//common component
import SmallText from "../SmallText";
//types
import { RadioCardProps } from "../../../types";
//css
import "./index.css";

const RadioCard: React.FC<RadioCardProps> = ({
  title,
  options,
  onSelect,
  selectedOption,
  cardWidth,
}) => {
  return (
    <div className="selection-container d-flex flex-column gap-3">
      {title && (
        <SmallText
          text={title}
          color="#19191C"
          lineHeight={18.8}
          fontWeight={400}
          fontSize={16}
        />
      )}

      <div className="d-flex flex-wrap gap-4">
        {options.map((option, index) => (
          <div
            key={`${index}-${option.label}`}
            className={`option d-flex    ${
              selectedOption === option.value ? "active" : ""
            }`}
            style={{
              maxWidth: `${cardWidth}`,
              width: `${cardWidth}`,
              maxHeight: "max-content",
            }}
          >
            <div className="form-check">
              <input
                type="radio"
                id={`${index}-${option.label}`}
                className="form-check-input"
                name={title}
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => onSelect(option.value)}
              />
            </div>
            <div>
              <div key={`${index}-${option.label}`}>
                <label htmlFor={`${index}-${option.label}`}>
                  <div className="d-flex flex-column gap-2">
                    <SmallText
                      text={option.label}
                      color="#19191C"
                      lineHeight={18.8}
                      fontWeight={400}
                      fontSize={16}
                    />

                    <SmallText
                      text={option.description}
                      color="#58585B"
                      lineHeight={18.8}
                      fontWeight={400}
                      fontSize={16}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioCard;
