/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

// custom component
import ClickableTags from "../../../../UI/ClickableTags";
import Button from "../../../../UI/Button";

//  redux
import { setShowModal } from "../../../../../redux/features/component-slice";

// network
import {
  fetchAmenities,
  postVenueAmenities,
  updateAmenities,
} from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import {
  RoomModalType,
  AddAmenitiesTypes,
} from "../../../../../types/property-manager";

const AddAmenitiesModal: React.FC<RoomModalType> = ({
  venueId,
  fetchDetails,
  basicAmenities,
}) => {
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<AddAmenitiesTypes>({
    defaultValues: {
      amenities: basicAmenities?.map((amenity) => amenity.id) || [],
    },
  });

  const dispatch = useDispatch();
  const [roomAmenities, setRoomAmenities] = useState<
    {
      id: string;
      name: string;
      icon_id: string;
      type_of_amenity: string;
    }[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [preSelectedBasicAmenities, setPreSelectedBasicAmenities] = useState<
    string[]
  >([]);
  const [groupAmenities, setGroupAmenities] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { call } = useApiCall();

  const handleResponse = (res: any, successMessage: string) => {
    toast.success(res.data?.message || successMessage);
    dispatch(setShowModal({ modalName: "add-amenities", visible: false }));
    fetchDetails();
    setSubmitting(false);
  };

  const handleError = (err: any, errorMessage: string) => {
    toast.error(err?.response?.data?.message || errorMessage);
    setSubmitting(false);
  };

  // Debounce search function
  const debouncedSearch = debounce((value: string) => {
    const filteredAmenities = roomAmenities.filter((amenity) =>
      amenity.name.toLowerCase().includes(value.toLowerCase())
    );
    // Optionally, group the filtered amenities
    const groupedAmenities = filteredAmenities.reduce((acc, amenity) => {
      const category = amenity.type_of_amenity || "Comfort and convenience";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(amenity);
      return acc;
    }, {} as { [key: string]: { id: string; name: string; icon_id: string }[] });

    setGroupAmenities(groupedAmenities);
  }, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const onFinish = async (data: AddAmenitiesTypes) => {
    setSubmitting(true);

    const apiCall =
      basicAmenities?.length === 0
        ? () =>
            postVenueAmenities({
              venue_id: venueId,
              data: data.amenities,
            })
        : () =>
            updateAmenities({
              venue_id: venueId,
              data: data.amenities,
            });

    const successMessage =
      basicAmenities?.length === 0
        ? "Amenities added successfully"
        : "Amenities updated successfully";

    const errorMessage =
      basicAmenities?.length === 0
        ? "Failed to add amenities"
        : "Failed to update amenities";

    call(
      apiCall,
      (res) => handleResponse(res, successMessage),
      (err) => handleError(err, errorMessage)
    );
  };

  const getAmenities = async () => {
    setLoading(true);
    call(
      () => fetchAmenities(),
      (res) => {
        const data: {
          id: string;
          name: string;
          icon_id: string;
          type_of_amenity: string;
        }[] = res.data.data;

        setRoomAmenities([]);
        data.map((item) => {
          return setRoomAmenities((prev) => [
            ...prev,
            {
              id: item.id,
              name: item.name,
              icon_id: item.icon_id,
              type_of_amenity: item.type_of_amenity,
            },
          ]);
        });

        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch room amenities"
        );
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getAmenities();
  }, []);

  useEffect(() => {
    basicAmenities &&
      setPreSelectedBasicAmenities(basicAmenities.map((amenity) => amenity.id));
  }, [basicAmenities]);

  useEffect(() => {
    const groupedAmenities = roomAmenities.reduce((acc, amenity) => {
      const category = amenity.type_of_amenity || "Comfort and convenience"; // Default to 'Other' if no category is provided
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(amenity);
      return acc;
    }, {} as { [key: string]: { id: string; name: string; icon_id: string }[] });

    setGroupAmenities(groupedAmenities);
  }, [roomAmenities]);

  return (
    <div className="room-modal-info-container">
      <div className="room-modal-subtitle">
        Select amenities for your venue.
      </div>
      <form
        className="room-modal-form-container amenities-form-modal w-100"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="w-100">
          <Input
            prefix={
              <SearchOutlined
                style={{ color: "#E8E7EC", height: "16px", width: "16px" }}
              />
            }
            placeholder="Search amenity"
            className="input-component w-100"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div
          className="form-group d-flex flex-column gap-3 room-modal-form"
          style={{ paddingBottom: "7rem" }}
        >
          {loading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          ) : (
            <>
              {Object.keys(groupAmenities).map((category) => (
                <div className="form-white-bg w-100">
                  <div key={category} className="amenities-category">
                    <p className="common-xs-medium-grey-text mb-2">
                      {category.split("_").join(" ")}
                    </p>
                    <div className="amenities-list">
                      <Controller
                        name="amenities"
                        control={control}
                        rules={{ required: "Please select amenities." }}
                        render={({ field: { value, onChange } }) => (
                          <ClickableTags
                            selectionMode="multiple"
                            tagList={groupAmenities[category]}
                            property_id={value}
                            onChange={(selectedValue) => {
                              onChange(selectedValue);
                              trigger("amenities");
                            }}
                            preSelectedTypes={preSelectedBasicAmenities}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {errors.amenities && (
            <span className="error">{errors.amenities.message}</span>
          )}
        </div>

        <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end pb-1">
          <div>
            <Button
              type="button"
              label="Cancel"
              style={{
                borderRadius: "4px",
                border: "1px solid var(--deep-sea)",
                backgroundColor: "var(--pure-white)",
                fontSize: "14px",
                lineHeight: "16.45px",
                color: "var(--deep-sea)",
                fontWeight: "500",
                padding: "12px 16px",
                textDecoration: "none",
              }}
              onClick={() => {
                reset({
                  amenities: preSelectedBasicAmenities || [],
                });
                dispatch(
                  setShowModal({
                    modalName: "add-amenities",
                    visible: false,
                  })
                );
              }}
            />
          </div>
          <div>
            <Button
              type="submit"
              label={submitting === true ? "Saving..." : "Save"}
              onClick={() => onFinish}
              disabled={
                !isValid || getValues("amenities").length === 0 || submitting
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAmenitiesModal;
