import React, { useState } from "react";

//third party
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";

//component
import PriceSection from "./PriceSection";

//common component
import StepFormHeader from "../../../UI/StepForm/StepFormHeader";
import StepFormAccordian from "../../../UI/StepForm/StepFormAccordian";
import CheckBoxAccordian from "../../../UI/StepForm/CheckBoxAccordian";
import RadioCard from "../../../UI/SelectGroup/RadioCard";
import MultiSelectCheckbox from "../../../UI/MultiSelection/MultiSelect";
import StepFormFooter from "./StepFormFooter";
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
import { RootState } from "../../../../redux/store";

//constant
import {
  shareOption,
  shareOptionValue,
} from "../../../../constants/RetreatHost";

//types
import { FormFooterProps } from "../../../../types/retreat";

//utils
import { preventScroll } from "../../../../utils/filterObject";

//images
import { images } from "../../../../assets/images";

//css
import "../../../../assets/css/marketing.css";
import { ToastFail } from "../../../../utils/TostMessage";
import { useApiCall } from "../../../../hooks/useApiCall";
import { createRetreatAPI, handleRetreatStep } from "../../../../network";
import { useNavigate } from "react-router-dom";

const Marketing: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const navigate = useNavigate();
  const [isOption, setIsOption] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>("GBP");
  const [selectedDiscount, setSelectedDiscount] = useState<string>("GBP");
  const [earlyDiscount, setEarlyDiscount] = useState<number>(0);
  const [discountValue, setDiscountValue] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [couponName, setCouponName] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);

  const retreat = useSelector((state: RootState) => state.createRetreat);
  //open modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  const closeModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: false }));
    handleNextStep();
  };
  //handle select marketing option
  const handleSelect = (value: string) => {
    setSelectedOptions([...selectedOptions, value]);
  };

  //handle deselect marketing option
  const handleDeselect = (value: string) => {
    setSelectedOptions(selectedOptions.filter((option) => option !== value));
  };

  //block invalid characters in number field
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleMarketing = (isPartial: boolean) => {
    if (!isPartial && isOption === "Public" && selectedOptions.length === 0) {
      ToastFail("Please select market places.");
      return;
    }
    const retreatValues = {
      host_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      venue_id: retreat.venue_id,
      check_in_date: retreat.check_in_date,
      check_out_date: retreat.check_out_date,
      group_size: retreat.group_size,
      total_price: retreat.total_price,
      is_share_option: isOption === "Public" ? true : false,
      third_party_marketing_sites:
        isOption === "Public" ? selectedOptions : [""],
      early_bird_discount_details: {
        amount: earlyDiscount,
        unit: selectedCurrency,
        early_bird_deadline_date: date,
      },
      partial: isPartial,
      form_step: "marketing",
    };

    call(
      () => createRetreatAPI(retreatValues),
      (res) => {
        getDetailOfRetreat();
        if (isPartial) {
          navigate("/host/retreat");
        } else {
          openModal("retreat");
        }
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  const getDetailOfRetreat = () => {
    call(
      () => handleRetreatStep("8c0179f6-f6de-49dc-a629-97224587cdef"),
      (res) => {
        console.log(res.data.data);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };
  return (
    <>
      <div className="container marketing" style={{ marginTop: "40px" }}>
        <div className="d-flex flex-column gap-5">
          <div>
            <StepFormHeader
              title="Marketing"
              description="Configure the view of your site and discounts"
            />
          </div>
          <div className="d-flex gap-4">
            <div className="d-flex gap-4 flex-column flex-grow-1 accordian-section">
              <StepFormAccordian
                title="Share options"
                atStart={true}
                IsAdd={false}
                ImageName="WORLD"
              >
                <div className="d-flex flex-column gap-4">
                  <RadioCard
                    options={shareOption}
                    onSelect={setIsOption}
                    selectedOption={isOption}
                    cardWidth="350px"
                  />
                  <CheckBoxAccordian
                    title="Would you like us to list your retreat on third party marketplaces to boost your bookings?"
                    description="We will add your retreats to your preferred marketplaces so you don't have to. The marketplace listings will go live 3-5 days after you publish your retreat booking page."
                    isTrue={isOption === "Private" ? false : true}
                  >
                    <MultiSelectCheckbox
                      options={shareOptionValue}
                      selectedOptions={selectedOptions}
                      onSelect={handleSelect}
                      onDeselect={handleDeselect}
                    />
                  </CheckBoxAccordian>
                </div>
              </StepFormAccordian>
              <StepFormAccordian
                title="Early bird"
                optional="(Optional)"
                atStart={false}
                IsAdd={false}
                isCheckBox={true}
                ImageName="EarlyBird"
              >
                <div className="d-flex flex-column gap-3">
                  <SmallText
                    text="Are you offering an early bird discount? Choose the percentage or amount and whether you have a deadline"
                    color="#58585B"
                    fontSize={16}
                    fontWeight={400}
                    lineHeight={18.8}
                  />
                  <div className="d-flex gap-4">
                    <div className="d-flex gap-2 align-items-end">
                      <div className="d-flex flex-column gap-1">
                        <SmallText
                          text="Amount / percentage"
                          color="#58585B"
                          lineHeight={16.45}
                          fontWeight={500}
                          fontSize={14}
                        />

                        <div className="d-flex gap-2">
                          <input
                            type="number"
                            placeholder="$0.00"
                            className="form-control"
                            onKeyDown={blockInvalidChar}
                            min={0}
                            onWheel={(e) => preventScroll(e)}
                            onChange={(e) =>
                              setEarlyDiscount(
                                e.target.value ? Number(e.target.value) : 0
                              )
                            }
                            value={earlyDiscount}
                          />
                        </div>
                      </div>
                      <div>
                        <select
                          className="form-select"
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                          value={selectedCurrency}
                        >
                          <option value={"GBP"}>GBP</option>
                          <option value={"USD"}>USD</option>
                          <option value={"EUR"}>EUR</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column gap-1"
                      style={{ width: "256px" }}
                    >
                      <SmallText
                        text="Early Bird Deadline"
                        color="#58585B"
                        lineHeight={16.45}
                        fontWeight={500}
                        fontSize={14}
                      />

                      <DatePicker
                        placeholder="No deadline set"
                        format={"YYYY-MM-DD"}
                        minDate={dayjs(new Date())}
                        onChange={(e) =>
                          setDate(e ? e.format("YYYY-MM-DD") : "")
                        }
                      />
                    </div>
                  </div>
                </div>
              </StepFormAccordian>
              <StepFormAccordian
                title="Discount Codes"
                optional="(Optional)"
                atStart={false}
                IsAdd={false}
                isCheckBox={true}
                ImageName="Discount"
              >
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-1">
                    <SmallText
                      text="Name"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                    <input
                      type="text"
                      className="form-control w-50"
                      placeholder="Enter Name Discount Code"
                      maxLength={10}
                      value={couponName ? couponName : ""}
                      onChange={(e) => setCouponName(e.target.value)}
                    />
                  </div>
                  <div className="d-flex gap-4">
                    <div className="d-flex gap-2 align-items-end">
                      <div className="d-flex flex-column gap-1">
                        <SmallText
                          text="Amount / percentage"
                          color="#58585B"
                          lineHeight={16.45}
                          fontWeight={500}
                          fontSize={14}
                        />

                        <div className="d-flex gap-2">
                          <input
                            type="number"
                            placeholder="$0.00"
                            className="form-control"
                            onKeyDown={blockInvalidChar}
                            min={0}
                            onWheel={(e) => preventScroll(e)}
                            onChange={(e) =>
                              setDiscount(
                                e.target.value ? Number(e.target.value) : 0
                              )
                            }
                            value={discount ? discount : ""}
                          />
                        </div>
                      </div>
                      <div>
                        <select
                          className="form-select"
                          value={selectedDiscount}
                          onChange={(e) => setSelectedDiscount(e.target.value)}
                        >
                          <option value={"GBP"}>GBP</option>
                          <option value={"USD"}>USD</option>
                          <option value={"EUR"}>EUR</option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-end">
                      <div className="d-flex flex-column gap-1">
                        <SmallText
                          text="How often can this code be used?"
                          color="#58585B"
                          lineHeight={16.45}
                          fontWeight={500}
                          fontSize={14}
                        />
                        <select
                          className="form-select"
                          value={discountValue}
                          onChange={(e) => setDiscountValue(e.target.value)}
                        >
                          <option value={"unlimited"}>Unlimited</option>
                          <option value={"onlyOnce"}>Only once</option>
                          <option value={"twice"}>Twice</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </StepFormAccordian>
            </div>
            <PriceSection />
          </div>
        </div>
      </div>
      <StepFormFooter
        handlePreviousStep={handlePreviousStep}
        handleNextStep={() => handleMarketing(false)}
        handleExit={() => handleMarketing(true)}
        buttonText="Create Retreat"
      />
      <ModalComponent modalName="retreat" title="">
        <div
          className="d-flex h-full align-items-center justify-content-center"
          style={{ height: "600px" }}
        >
          <div className="d-flex flex-column gap-4">
            <div className="d-flex align-items-center justify-content-center">
              <img src={images.SUCCESS} alt="" width={150} height={150} />
            </div>
            <div className="text-center d-flex flex-column gap-2">
              <SmallText
                text="Your retreat has been successfully created"
                fontSize={24}
                lineHeight={28.2}
                fontWeight={700}
                color="#19191C"
              />
              <SmallText
                text="We'll organize all your information on a Booking page so you can share it with the retreat participants"
                fontSize={16}
                fontWeight={400}
                lineHeight={18.8}
                color="#58585B"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="d-flex gap-3">
            <button className="back-btn">Do it later</button>
            <button
              className="submit-btn"
              onClick={() => {
                closeModal("retreat");
              }}
            >
              Create booking page
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default Marketing;
