import React, { useState } from "react";

//third party
import { useSearchParams } from "react-router-dom";
import { Steps } from "antd";

//component
import Header from "../../Header";
import VenuePreview from "./VenuePreview";
import SelectVenue from "./SelectVenue";
import RetreatPackage from "./RetreatPackage";
import AboutRetreat from "./AboutRetreat";
import VenueCostForm from "./VenueCostForm";
import GuestInfo from "./GuestInfo";
import Marketing from "./Marketing";
import Previews from "./Preview";

//css
import "../../../../assets/css/stepForm.css";

const { Step } = Steps;

const CreateRetreat = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const handleNextStep = () => {
    setSearchParams("");
    setCurrentStep(currentStep + 1);
  };
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleExit = () => {};
  const steps = [
    {
      title: "Select Venue",
      content: <SelectVenue />,
    },
    {
      title: "Venue cost",
      content: (
        <VenueCostForm
          handleExit={handleExit}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      title: "Retreat packages",
      content: (
        <RetreatPackage
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      title: "Marketing",
      content: (
        <Marketing
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      title: "About retreat",
      content: (
        <AboutRetreat
          handleExit={handleExit}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    { title: "Guest info", content: <GuestInfo /> },
    { title: "Preview", content: <Previews /> },
  ];

  return (
    <div className="create-retreat">
      <Header containerClassName="container">
        <Steps current={currentStep} type="navigation">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </Header>
      {id ? (
        <VenuePreview
          handleNextStep={handleNextStep}
          setCurrentStep={setCurrentStep}
        />
      ) : (
        <div className="steps-content">{steps[currentStep].content}</div>
      )}
    </div>
  );
};

export default CreateRetreat;
