// Firebase
import { ref, set, update, remove } from "firebase/database";
import { db } from "../network/firebaseConfig";

// Types
import { FirebaseUser } from "../types";

export const createUserFirebase = async (userData: FirebaseUser) => {
  const userRef = ref(db, `users/${userData.uid}`);

  // Add the new user to the Firebase Realtime Database
  set(userRef, userData)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export const updateUserDetailFirebase = async (
  uid: string,
  newUserData: {}
) => {
  const userRef = ref(db, `users/${uid}`);

  try {
    await update(userRef, { ...newUserData });
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteUserFirebase = async (uid: string) => {
  const userRef = ref(db, `users/${uid}`);

  try {
    await remove(userRef);
    return true;
  } catch (error) {
    console.error("Error deleting user:", error);
    return false;
  }
};
