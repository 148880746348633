import React from "react";
//common component
import SmallText from "../../../UI/SmallText";

//images
import { images } from "../../../../assets/images";

//css
import "../../../../assets/css/hostVenueDetail.css";
const HighlightedDetail = () => {
  return (
    <div className="d-flex flex-wrap gap-3">
      <div className="highlighted-card">
        <img src={images.YOGA_ICON} alt="icon" width={28} height={28} />
        <div className="d-flex flex-column gap-1">
          <SmallText
            text="Retreat type"
            color="#949397"
            fontSize={14}
            fontWeight={400}
            lineHeight={16.45}
          />
          <SmallText
            text="Yoga"
            color="#58585B"
            fontSize={16}
            fontWeight={500}
            lineHeight={18.8}
          />
        </div>
      </div>
      <div className="highlighted-card">
        <img src={images.GROUP2} alt="icon" width={28} height={28} />
        <div className="d-flex flex-column gap-1">
          <SmallText
            text="Group size"
            color="#949397"
            fontSize={14}
            fontWeight={400}
            lineHeight={16.45}
          />
          <SmallText
            text="Up to 12 "
            color="#58585B"
            fontSize={16}
            fontWeight={500}
            lineHeight={18.8}
          />
        </div>
      </div>
      <div className="highlighted-card">
        <img src={images.CALENDAR1} alt="icon" width={28} height={28} />
        <div className="d-flex flex-column gap-1">
          <SmallText
            text="Dates"
            color="#949397"
            fontSize={14}
            fontWeight={400}
            lineHeight={16.45}
          />
          <div>
            <SmallText
              text="Aug 15 - 19, 2024"
              color="#58585B"
              fontSize={16}
              fontWeight={500}
              lineHeight={18.8}
            />
          </div>
        </div>
      </div>
      <div className="highlighted-card">
        <img src={images.NIGHT_ICON} alt="icon" width={28} height={28} />
        <div className="d-flex flex-column gap-1">
          <SmallText
            text="Amount of nights"
            color="#949397"
            fontSize={14}
            fontWeight={400}
            lineHeight={16.45}
          />
          <SmallText
            text="4 nights"
            color="#58585B"
            fontSize={16}
            fontWeight={500}
            lineHeight={18.8}
          />
        </div>
      </div>
    </div>
  );
};

export default HighlightedDetail;
