import React from 'react'

//common component
import SmallText from '../SmallText'

const StepFormHeader = ({title, description}: {
    title:string,
    description:string
}) => {
  return (
    <div className='d-flex flex-column gap-2'>
        <SmallText
          text={title}
          color="#19191C"
          fontWeight={700}
          fontSize={32}
          lineHeight={37.6}
          />
          <SmallText
          text={description}
          color="#58585B"
          fontWeight={400}
          fontSize={18}
          lineHeight={21.15}
          />
      
    </div>
  )
}

export default StepFormHeader
