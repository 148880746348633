/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//third party
import { Tabs, TabsProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

//component
import UploadData from "./UploadData";
import PersonalInfo from "./PersonalInfo";
import Preferences from "./Preferences";
import PayoutDetail from "./PayoutDetail";

//css
import "../../../../assets/css/Profile.css";

const MyProfile: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>("1");
  const { hash } = useLocation();
  const navigate = useNavigate();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Personal Info",
      children: <PersonalInfo />,
    },
    {
      key: "2",
      label: "Global Preferences",
      children: <Preferences />,
    },
    {
      key: "3",
      label: "Payout",
      children: <PayoutDetail />,
    },
    {
      key: "4",
      label: "Legal Documents ",
      children: <UploadData />,
    },
  ];
  const handleTabChange = (id: string) => {
    setActiveKey(id);

    switch (id) {
      case "1":
        navigate(`#personal-info`);
        break;
      case "2":
        navigate(`#global-preferences`);
        break;
      case "3":
        navigate(`#payout`);
        break;
      case "4":
        navigate(`#legal-documents`);
        break;
      default:
        navigate(`#personal-info`);
    }
  };

  useEffect(() => {
    if (hash) {
      switch (hash) {
        case "#personal-info":
          setActiveKey("1");
          break;
        case "#global-preferences":
          setActiveKey("2");
          break;
        case "#payout":
          setActiveKey("3");
          break;
        case "#legal-documents":
          setActiveKey("4");
          break;
        default:
          setActiveKey("1");
      }
    } else {
      navigate(`#personal-info`);
    }
  }, [hash]);

  return (
    <Tabs
      items={items}
      activeKey={activeKey}
      defaultActiveKey="1"
      onChange={handleTabChange}
    />
  );
};

export default MyProfile;
