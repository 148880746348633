/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Link, useParams } from "react-router-dom";
import { ConfigProvider, Tabs, TabsProps } from "antd";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

// custom components
import Loader from "../../../UI/Loader";
import Overview from "./Overview";
import Description from "./Description";
import PriceAndAvailability from "./PriceAndAvailability";
import ManageSpaces from "./ManageSpaces";
import UploadPhotos from "./UploadPhotos";
import Amenities from "./Amenities";
import AddOnServices from "./AddOnServices";
import Button from "../../../UI/Button";
import EditBookingTerms from "./EditBookingTerms";

// redux
import { RootState } from "../../../../redux/store";
import {
  setVenueData,
  setInitialVenueData,
  setInitialAmenitiesData,
  setAmenitiesData,
  setPhotosData,
  setInitialPhotosData,
} from "../../../../redux/features/venueDataSlice";
import {
  setActiveTab,
  setEnableEditButton,
  setFetchAmenities,
} from "../../../../redux/features/editVenueOperationsSlice";

// network
import {
  deleteBulkImages,
  fetchCreateVenueOptionsData,
  fetchReferenceImages,
  fetchVenue,
  fetchVenueAmenities,
  postAvailabilityAndPricing,
  postBookingTerms,
  updateAmenities,
  updateVenue,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// helpers
import { changeUTC, getDatesForCurrentMonth } from "../../../../library";

// types
import {
  AddOnServiceType,
  FormValues,
  highlights,
  languages,
  propertyTypes,
  retreatsHostTypes,
  venueDataPrefilledType,
} from "../../../../types/property-manager";

// constants
import { ACCEPT_BOOKING_TIME_TYPE } from "../../../../constants/property-manager";

// styles
import "../../../../assets/css/my-venues.css";
import "../../../../assets/css/edit-venue.css";

// images
import { images } from "../../../../assets/images";

const EditVenue: React.FC = () => {
  const { call } = useApiCall();
  const { venue_id } = useParams();

  const dispatch = useDispatch();
  const editVenueOperationsSelector = useSelector(
    (state: RootState) => state.editVenueOperations
  );
  const venueDataSelector = useSelector((state: RootState) => state.venueData);
  const [venue, setVenue] = useState<FormValues>();

  const [fetchHighlightOption, setFetchHighlightOption] = React.useState<
    highlights[]
  >([]);
  const [fetchLanguageOption, setFetchLanguageOption] = React.useState<
    languages[]
  >([]);
  const [fetchPropertyTypeOption, setFetchPropertyTypeOption] = React.useState<
    propertyTypes[]
  >([]);
  const [fetchRetreatsHostTypeOption, setFetchRetreatsHostTypeOption] =
    React.useState<retreatsHostTypes[]>([]);

  const [addOnService, setAddOnServices] = React.useState<AddOnServiceType[]>(
    []
  );
  const [venueLoading, setVenueLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Overview",
      children: <Overview venueId={venue_id as string} />,
    },
    {
      key: "2",
      label: "Description",
      children: (
        <Description
          propertyTypes={fetchPropertyTypeOption}
          highlights={fetchHighlightOption}
          languages={fetchLanguageOption}
          retreatsHostTypes={fetchRetreatsHostTypeOption}
          venueData={venue}
          venueIdExisting={venue_id ? venue_id : undefined}
        />
      ),
    },
    {
      key: "3",
      label: "Price and Availability",
      children: <PriceAndAvailability venueId={venue_id || ""} />,
    },
    {
      key: "4",
      label: "Spaces",
      children: <ManageSpaces />,
    },
    {
      key: "5",
      label: "Amenities",
      children: <Amenities venueId={venue_id as string} />,
    },
    {
      key: "6",
      label: "Add-ons",
      children: (
        <AddOnServices addOnServices={addOnService} venueId={venue_id || ""} />
      ),
    },
    {
      key: "7",
      label: "Photos",
      children: <UploadPhotos venueId={venue_id as string} />,
    },
    {
      key: "8",
      label: "Terms and Conditions",
      children: <EditBookingTerms venueId={venue_id as string} />,
    },
  ];

  const fetchCreateVenueOptions = async () => {
    call(
      () => fetchCreateVenueOptionsData(),
      (res) => {
        setFetchHighlightOption(res.data.data.venue_highlights);
        setFetchLanguageOption(res.data.data.languages);
        setFetchPropertyTypeOption(res.data.data.property_type);
        setFetchRetreatsHostTypeOption(res.data.data.retreat_hosts);
      },
      (err) => {
        toast.error(err.message || "Failed to fetch retreats host types");
      }
    );
  };

  const fetchVenueDetails = async (venueId: string) => {
    setVenueLoading(true);
    call(
      () => fetchVenue(venueId),
      (res) => {
        const venueData = res.data?.data;

        setVenue(venueData);
        setAddOnServices(venueData.add_on_services);
        setVenueLoading(false);
      },
      (err) => {
        setVenueLoading(false);
      }
    );
  };

  const fetchAmenitiesDetails = async () => {
    call(
      () => fetchVenueAmenities(venue_id as string),
      (res) => {
        dispatch(setInitialAmenitiesData(res.data.data?.amenities));
        dispatch(setAmenitiesData(res.data.data?.amenities));
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch amenities"
        );
      }
    );
  };

  const fetchVenueImages = async () => {
    call(
      () => fetchReferenceImages({ reference_id: venue_id as string }),
      (res) => {
        dispatch(setPhotosData(res.data.data));
        dispatch(setInitialPhotosData(res.data.data));
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch images");
      }
    );
  };

  const onEditVenue = async () => {
    setSubmitLoading(true);
    if (editVenueOperationsSelector.activeTab === "2") {
      const data = {
        ...venueDataSelector.venueData,

        partial: true,
        base_price: 500,
      };

      call(
        () => updateVenue({ data, venue_id: venue_id as string }),
        (res) => {
          fetchVenueDetails(venue_id as string);
          toast.success(res.data?.message || "Venue created successfully");
        },
        (err) => {
          toast.error(err?.response?.data?.message || "Failed to create venue");
        }
      ).finally(() => setSubmitLoading(false));
    } else if (editVenueOperationsSelector.activeTab === "3") {
      const data = preparePriceAvailabilityData();
      call(
        () =>
          postAvailabilityAndPricing({
            venue_id: venue_id as string,
            data: data,
          }),
        (res) => {
          toast.success("Pricing and availability saved successfully");
        },
        (err) => {
          toast.error(
            err?.data?.message || "Failed to save pricing and availability"
          );
        }
      ).finally(() => setSubmitLoading(false));
    } else if (editVenueOperationsSelector.activeTab === "5") {
      const data = venueDataSelector.amenitiesData.map((item) => item.id);
      call(
        () => updateAmenities({ venue_id: venue_id as string, data: data }),
        (res) => {
          fetchAmenitiesDetails();
          dispatch(setFetchAmenities(true));
          toast.success(res.data?.message || "Amenities updated successfully");
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to update amenities"
          );
        }
      ).finally(() => setSubmitLoading(false));
    } else if (editVenueOperationsSelector.activeTab === "7") {
      const imageData = venueDataSelector.photosData.map((item) => item.id);

      call(
        () => deleteBulkImages({ reference_ids: imageData }),
        (res) => {
          fetchVenueImages();
          toast.success(res.data?.message || "Images deleted successfully");
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to delete images"
          );
        }
      ).finally(() => setSubmitLoading(false));
    } else if (editVenueOperationsSelector.activeTab === "8") {
      const data = prepareBookingTermDataToSend();

      call(
        () => postBookingTerms({ venue_id: venue_id as string, data: data }),
        (res) => {
          fetchVenueDetails(venue_id as string);
          toast.success(
            res.data?.message || "Booking terms updated successfully"
          );
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to update booking terms"
          );
        }
      ).finally(() => setSubmitLoading(false));
    }
  };

  const prepareBookingTermDataToSend = () => {
    const data = venueDataSelector.bookingTerms;

    const checkInUtc = changeUTC(
      dayjs(`1970-01-01 ${data.check_in}`),
      data.check_in_period
    );
    const checkOutUtc = changeUTC(
      dayjs(`1970-01-01 ${data.check_out}`),
      data.check_out_period
    );

    const quietHoursStartUtc = changeUTC(
      dayjs(`1970-01-01 ${data.quiet_hours_start}`),
      data.quiet_hours_start_period
    );
    const quietHoursEndUtc = changeUTC(
      dayjs(`1970-01-01 ${data.quiet_hours_end}`),
      data.quiet_hours_end_period
    );

    const result: any = {
      check_in: checkInUtc,
      check_out: checkOutUtc,
      min_night_stays: data.night_stays,
      max_guest: data.guest_number,
    };

    if (quietHoursStartUtc !== "Invalid Date") {
      result.quiet_hours_start = quietHoursStartUtc;
    }

    if (quietHoursEndUtc !== "Invalid Date") {
      result.quiet_hours_end = quietHoursEndUtc;
    }

    return result;
  };

  const preparePriceAvailabilityData = () => {
    const data = venueDataSelector.priceAvailabilityData;
    const initialData = venueDataSelector.initialPriceAvailabilityData;
    const dayPriceByMonth: { [month: string]: { [date: string]: number } } = {};
    Object.entries(data.individualDateAvailability).forEach(([date, price]) => {
      const month = dayjs(date).format("YYYY-MM");
      if (!dayPriceByMonth[month]) {
        dayPriceByMonth[month] = {};
      }
      dayPriceByMonth[month][date] = price;
    });

    const filteredDefaultRate = Object.entries(data.defaultRate)
      .filter(([month, rate]) => {
        // const fetchedMonthPrice = data.monthPriceData[month] || data.basePrice;
        return rate !== data.basePrice;
      })
      .reduce((acc, [month, rate]) => {
        acc[month] = rate;
        return acc;
      }, {} as { [key: string]: number });

    const monthsWithClosureDates = Object.entries(data.calendarStatus)
      .filter(([month, status]) => status === "closed")
      .map(([month]) => month);

    const day_price = [
      ...Object.entries(dayPriceByMonth).map(([month, day_price]) => {
        const isClosed = data.calendarStatus[month] === "closed";

        return {
          id: data.dayPriceId[month],
          month,
          day_price,
          reserved_dates: [], // Modify if you have reserved dates to include
          closure_dates: isClosed
            ? getDatesForCurrentMonth(dayjs(month)).map((date) =>
                dayjs(date).format("YYYY-MM-DD")
              ) // All dates of the month for closed months
            : data.closureDate.filter(
                (date) => dayjs(date).format("YYYY-MM") === month
              ),
        };
      }),
      // Add any months with closure dates but no day prices
      ...monthsWithClosureDates
        .filter((month) => !dayPriceByMonth[month]) // Filter months that don't already have day prices
        .map((month) => ({
          id: data.dayPriceId[month],
          month,
          day_price: {}, // No prices for the month
          reserved_dates: [],
          closure_dates: getDatesForCurrentMonth(dayjs(month)).map((date) =>
            dayjs(date).format("YYYY-MM-DD")
          ), // All dates of the month for closed months
        })),
    ];

    const startDateToSend =
      initialData.advanceBooking.value !== data.advanceBooking.value
        ? dayjs(data.startDate)
            .subtract(
              initialData.acceptBookingForNext ===
                ACCEPT_BOOKING_TIME_TYPE.month3
                ? 3
                : initialData.acceptBookingForNext ===
                  ACCEPT_BOOKING_TIME_TYPE.month6
                ? 6
                : 12,
              "month"
            )
            .add(data.advanceBooking.value, data.advanceBooking.timeFormat)
            .format("YYYY-MM-DD")
        : data.startDate;

    const endDateToSend =
      initialData.acceptBookingForNext !== data.acceptBookingForNext
        ? dayjs(startDateToSend)
            .add(
              data.acceptBookingForNext === ACCEPT_BOOKING_TIME_TYPE.month3
                ? 3
                : data.acceptBookingForNext === ACCEPT_BOOKING_TIME_TYPE.month6
                ? 6
                : 12,
              "month"
            )
            .format("YYYY-MM-DD")
        : data.endDate;

    const dataToSend = {
      start_date: startDateToSend,
      end_date: endDateToSend,
      month_price: filteredDefaultRate, // Object of default rates per month
      day_price, // Array of day_price objects as described
      base_price: data.defaultBasePrice,
      advance_booking_time: {
        value: data.advanceBooking.value,
        time_format: data.advanceBooking.timeFormat,
      },
    };

    return dataToSend;
  };

  const onTabChange = (tabKey: string) => {
    dispatch(setActiveTab(tabKey));
  };

  useEffect(() => {
    fetchCreateVenueOptions();
    fetchVenueDetails(venue_id as string);
  }, []);

  useEffect(() => {
    if (!venue) {
      return;
    }

    const retreat_host_type: string[] = [];
    const venueHighlights: string[] = [];
    const preLanguages: string[] = [];

    const localRetreatHostType: {
      icon_id: string;
      id: string;
      name: string;
    }[] = [];

    const locaHighlights: {
      icon_id: string;
      id: string;
      name: string;
    }[] = [];

    venue?.recommended_retreats?.forEach((retreat) => {
      fetchRetreatsHostTypeOption?.forEach((type) => {
        if (retreat.name === type.name) {
          retreat_host_type.push(type.id);
          localRetreatHostType.push({
            icon_id: type.icon_id,
            id: type.id,
            name: type.name,
          });
        }
      });
    });

    venue?.highlights?.forEach(
      (highlight: { icon_id: string; name: string }) => {
        fetchHighlightOption?.forEach((type) => {
          if (highlight?.name === type.name) {
            venueHighlights.push(type.id);
            locaHighlights.push({
              icon_id: type.icon_id,
              id: type.id,
              name: type.name,
            });
          }
        });
      }
    );

    venue?.languages?.forEach((lang) => {
      fetchLanguageOption?.forEach((item) => {
        if (item.name === lang) {
          preLanguages.push(item.id);
        }
      });
    });

    const newData: venueDataPrefilledType = {
      type_of_property_id: venue?.venue_type?.id || "",
      number_of_rooms: venue?.number_of_rooms || 1,
      number_of_bathrooms: venue?.number_of_bathrooms || 1,
      sleeps: venue?.sleeps || 1,
      custom_paragraphs: venue?.custom_paragraphs || [],
      venue_highlights_id: venueHighlights || [],
      recommended_retreats: retreat_host_type || [],
      language_id: preLanguages || [],
      zip_code: venue?.zip_code || "",
      country: venue?.country || "",
      property_name: venue?.venue_title || "",
      city: venue?.city || "",
      address: venue?.address || "",
      property_description: venue?.property_description || "",
      media_url: venue?.media_url || "",
    };

    // Dispatch each key-value pair to setVenueData
    Object.entries(newData).forEach(([field, value]) => {
      dispatch(
        setVenueData({
          field: field as keyof typeof newData,
          value,
        })
      );
    });

    // Dispatch each key-value pair to setInitialVenueData
    Object.entries(newData).forEach(([field, value]) => {
      dispatch(
        setInitialVenueData({
          field: field as keyof typeof newData,
          value,
        })
      );
    });
  }, [venue, dispatch]);

  useEffect(() => {
    const { activeTab } = editVenueOperationsSelector;
    const {
      venueData,
      initialVenueData,
      initialAmenitiesData,
      amenitiesData,
      initialPhotosData,
      photosData,
      initialBookingTerms,
      bookingTerms,
      initialPriceAvailabilityData,
      priceAvailabilityData,
    } = venueDataSelector;

    const shouldEnableEditButton = () => {
      switch (activeTab) {
        case "2":
          return JSON.stringify(venueData) !== JSON.stringify(initialVenueData);
        case "3":
          return (
            JSON.stringify(initialPriceAvailabilityData) !==
            JSON.stringify(priceAvailabilityData)
          );
        case "5":
          return initialAmenitiesData !== amenitiesData;
        case "7":
          return initialPhotosData !== photosData;
        case "8":
          return (
            JSON.stringify(initialBookingTerms) !== JSON.stringify(bookingTerms)
          );
        default:
          return false;
      }
    };

    // Dispatch the result
    dispatch(setEnableEditButton(shouldEnableEditButton()));
  }, [venueDataSelector]);

  return venueLoading ? (
    <Loader />
  ) : (
    <div>
      <div className="edit-tabs-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <img
            src={
              venue?.venue_images && venue?.venue_images.length > 0
                ? venue?.venue_images[0].image_url
                : images.NO_IMAGE
            }
            alt=""
            width={"60px"}
            height={"60px"}
          />
        </div>
        <div className="flex-grow-1">
          <h4>{venue?.venue_title}</h4>
          <span>
            {venue?.city + ","} {venue?.country}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            label={submitLoading ? "Submitting..." : "Edit Venue"}
            type="button"
            onClick={onEditVenue}
            disabled={
              !editVenueOperationsSelector.enableEditButton || submitLoading
            }
          />
          <Link
            to={`/venues/${venue_id}`}
            className="bordered-buttons text-decoration-none"
            target="_blank"
          >
            Preview Listing
          </Link>
        </div>
      </div>
      <div className="flex-grow-1 edit-tabs-container">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemSelectedColor: "var(--deep-sea) !important",
                inkBarColor: "var(--deep-sea) !important",
                itemHoverColor: "var(--deep-sea) !important",
              },
            },
          }}
        >
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onTabChange}
            className="my-venues"
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default EditVenue;
