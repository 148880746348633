import { createSlice } from "@reduxjs/toolkit";

type paginationSliceType = {
  currentPage: number;
};

const initialState: paginationSliceType = {
  currentPage: 1,
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentPage } = paginationSlice.actions;

export default paginationSlice.reducer;
