/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// third party
import { DatePicker, Skeleton, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { Calendar, momentLocalizer, SlotInfo } from "react-big-calendar";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// Custom component
import CalendarSettings from "./CalendarSettings";
import IndividualDateAvailabilitySidebar from "./IndividualDateAvailabilitySidebar";
import SmallText from "../../../UI/SmallText";

// redux
import {
  setInitialPriceAndAvailability,
  setPriceAndAvailability,
} from "../../../../redux/features/venueDataSlice";
import { RootState } from "../../../../redux/store";

// network
import {
  fetchMonthAvailability,
  fetchTaxDiscount,
  getUpcomingReservationRequest,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// helpers
import { getDatesForCurrentMonth } from "../../../../library";

// types
import {
  BookingEvent,
  FetchedDiscountType,
  FetchedTaxType,
  fetchReservationTypes,
  Reservations,
  ReservedDateType,
} from "../../../../types/property-manager";

// constants
import { ACCEPT_BOOKING_TIME_TYPE } from "../../../../constants/property-manager";

// images
import { images } from "../../../../assets/images";

// styles
import "react-big-calendar/lib/css/react-big-calendar.css";

const { Text } = Typography;

const localizer = momentLocalizer(moment);

const PriceAndAvailability: React.FC<{ venueId: string }> = ({ venueId }) => {
  const dispatch = useDispatch();

  const priceAvailabilitySelector = useSelector(
    (state: RootState) => state.venueData
  );

  const [currentMonth, setCurrentMonth] = useState(dayjs().add(3, "month"));

  const [defaultRate, setDefaultRate] = useState<{ [key: string]: number }>({});
  const [events, setEvents] = useState<BookingEvent[]>([]);
  const [calendarStatus, setCalendarStatus] = useState<{
    [key: string]: string;
  }>({
    [dayjs().add(3, "month").format("YYYY-MM")]: "open",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<BookingEvent | null>(null);
  const [closureDate, setClosureDate] = useState<string[]>([]);
  const [selectedDateRequests, setSelectedDateRequests] =
    useState<Reservations>([]);
  const [selectedDateReserved, setSelectedDateReserved] =
    useState<Reservations>([]);
  const [individualDateAvailability, setIndividualDateAvailability] = useState<{
    [key: string]: number;
  }>({});

  const [monthPriceData, setMonthPriceData] = useState<{
    [key: string]: number;
  }>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [acceptReservations, setAcceptReservations] = useState<{
    [key: string]: boolean;
  }>({});

  const [defaultBasePrice, setDefaultBasePrice] = useState<number>(0);

  const [advanceBooking, setAdvanceBooking] = useState<{
    value: number;
    timeFormat: "month" | "day";
  }>({
    value: 3,
    timeFormat: "month",
  });

  const [requestedDates, setRequestedDates] = useState<string[]>([]);
  const [requestedEvents, setRequestedEvents] = useState<BookingEvent[]>([]);

  const [reservedDates, setReservedDates] = useState<string[]>([]);
  const [reservedEvents, setReservedEvents] = useState<BookingEvent[]>([]);

  const [taxes, setTaxes] = useState<FetchedTaxType>();
  const [discounts, setDiscounts] = useState<FetchedDiscountType[]>([]);

  const [taxLoading, setTaxLoading] = useState<boolean>(false);

  const [reservedData, setReservedData] = useState<ReservedDateType>([]);

  const { call } = useApiCall();

  const handleMonthChange = (date: Dayjs | null) => {
    if (date) {
      setCurrentMonth(date.startOf("month"));

      const monthKey = date.format("YYYY-MM");
      const status = calendarStatus[monthKey] || "open";

      if (status !== "closed") {
        setCalendarStatus((prevStatus) => ({
          ...prevStatus,
          [monthKey]: "open",
        }));

        dispatch(
          setPriceAndAvailability({
            field: "calendarStatus",
            value: { ...calendarStatus, [monthKey]: "open" },
          })
        );

        onAvailabilityChange("open");
      }

      setIsSidebarOpen(false);
    }
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
    setIsSidebarOpen(false);
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
    setIsSidebarOpen(false);
  };

  const onAvailabilityChange = (availability: string) => {
    if (availability === "open") {
      handleOpenCalendar(
        defaultRate[currentMonth.format("YYYY-MM")] || 0,
        currentMonth.format("YYYY-MM")
      );
    } else if (availability === "closed") {
      handleClosedCalendar();
    }
  };

  const getDateRange = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const fetchUpcomingReservations = ({
    approval_status,
    check_in_date,
    check_out_date,
    start_date,
    reservation_date,
    venue_id,
  }: fetchReservationTypes) => {
    setLoading(true);
    call(
      () =>
        getUpcomingReservationRequest({
          approval_status,
          check_in_date,
          check_out_date,
          start_date,
          reservation_date,
          venue_id,
        }),
      (res) => {
        const reservations = res.data.data;
        const allDates: string[] = [...requestedDates];
        const updatedEvents: BookingEvent[] = [...requestedEvents];
        const reservationMap: { [key: string]: BookingEvent } = {}; // Map for easier lookup

        reservations.forEach((reservation: any) => {
          const {
            reservation_id,
            check_in_date,
            check_out_date,
            total_price,
            group_size,
            venue_name,
            id,
          } = reservation;

          const dateRange = getDateRange({
            startDate: check_in_date,
            endDate: check_out_date,
          });
          allDates.push(
            ...dateRange.filter((date) => !allDates.includes(date))
          );

          // Skip duplicate reservation IDs
          if (reservationMap[reservation_id]) return;

          const newRequest = {
            id,
            reservation_id,
            check_in_date,
            check_out_date,
            total_price,
            group_size,
            venue_name,
            status: "pending",
            retreat_host: {
              user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
              name: "John Doe",
              profile_picture_url: "",
              city: "New York",
            },
          };

          const newEvent: BookingEvent = {
            start: new Date(check_in_date),
            end: new Date(check_out_date),
            title: (
              <Text
                style={{ width: 157, color: "#fff" }}
                ellipsis={{
                  tooltip: `Requested - $${total_price}`,
                }}
              >
                Requested - ${total_price}
              </Text>
            ),
            type: "requested",
            allDay: true,
            requests: [newRequest],
          };

          // check if any event with id of new request already exists in requested events
          const existingEvent = updatedEvents.find((event) =>
            event.requests?.some((request) => request.id === id)
          );

          reservationMap[reservation_id] = newEvent;
          !existingEvent && updatedEvents.push(newEvent); // Push event if unique
        });

        // Set requested dates
        setRequestedDates(allDates);
        // Batch update events
        setRequestedEvents(updatedEvents);
        setEvents(updatedEvents);

        fetchMonthPriceAvailability({ requests: updatedEvents });
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservations"
        );
        setLoading(false);
      }
    );
  };

  const handleOpenCalendar = (
    rate: number,
    month: string,
    individualDateAvailability?: { [key: string]: number },
    closureDate?: string[],
    requests?: BookingEvent[],
    reserved?: BookingEvent[]
  ) => {
    const currentMonthDates = getDatesForCurrentMonth(currentMonth);
    // Create a map of requested events to avoid duplicates

    const allDates: string[] = [];

    requests?.forEach((request) => {
      const { start, end } = request;
      const dateRange = getDateRange({
        startDate: dayjs(start).format("YYYY-MM-DD"),
        endDate: dayjs(end).format("YYYY-MM-DD"),
      });

      allDates.push(...dateRange.filter((date) => !allDates.includes(date)));
    });

    const updatedEvents: BookingEvent[] = currentMonthDates
      .map((date) => {
        const dateKey = dayjs(date).format("YYYY-MM-DD");
        const price = individualDateAvailability?.[dateKey] || rate;

        if (closureDate?.includes(dateKey)) {
          return {
            title: "Closed",
            start: date,
            end: date,
            allDay: true,
            type: "closed",
            price: 0,
          };
        }
        if (
          individualDateAvailability?.[dateKey] !== undefined &&
          individualDateAvailability?.[dateKey] !== null
        ) {
          return {
            title: `$${individualDateAvailability[dateKey]}`,
            start: date,
            end: date,
            allDay: true,
            type: "open",
            price: individualDateAvailability[dateKey],
          };
        }
        if (
          !requestedDates.includes(dateKey) &&
          !reservedDates.includes(dateKey) &&
          !allDates.includes(dateKey)
        ) {
          return {
            title: `$${price}`,
            start: date,
            end: date,
            allDay: true,
            type: "open",
            price: price,
          };
        }

        return null;
      })
      .filter((item) => item !== null) as BookingEvent[];

    // Merge requested events without duplicating them

    setEvents(
      updatedEvents
        .concat(requestedEvents.length > 0 ? requestedEvents : requests || [])
        .concat(reservedEvents) || updatedEvents
    );

    setDefaultRate((prev) => ({
      ...prev,
      [month]: rate,
    }));

    if (
      !Object.keys(
        priceAvailabilitySelector.initialPriceAvailabilityData.defaultRate
      ).includes(month)
    ) {
      dispatch(
        setInitialPriceAndAvailability({
          field: "defaultRate",
          value: {
            ...defaultRate,
            [month]: rate,
          },
        })
      );
    }

    dispatch(
      setPriceAndAvailability({
        field: "defaultRate",
        value: {
          ...defaultRate,
          [month]: rate,
        },
      })
    );

    if (
      closureDate &&
      closureDate.length > 0 &&
      currentMonthDates.every((date) =>
        closureDate.includes(dayjs(date).format("YYYY-MM-DD"))
      )
    ) {
      setCalendarStatus((prevStatus) => ({
        ...prevStatus,
        [currentMonth.format("YYYY-MM")]: "closed",
      }));

      dispatch(
        setInitialPriceAndAvailability({
          field: "calendarStatus",
          value: {
            ...calendarStatus,
            [currentMonth.format("YYYY-MM")]: "closed",
          },
        })
      );

      dispatch(
        setPriceAndAvailability({
          field: "calendarStatus",
          value: {
            ...calendarStatus,
            [currentMonth.format("YYYY-MM")]: "closed",
          },
        })
      );
    } else {
      setCalendarStatus((prevStatus) => ({
        ...prevStatus,
        [currentMonth.format("YYYY-MM")]: "open",
      }));

      dispatch(
        setInitialPriceAndAvailability({
          field: "calendarStatus",
          value: {
            ...calendarStatus,
            [currentMonth.format("YYYY-MM")]: "open",
          },
        })
      );

      dispatch(
        setPriceAndAvailability({
          field: "calendarStatus",
          value: {
            ...calendarStatus,
            [currentMonth.format("YYYY-MM")]: "open",
          },
        })
      );
    }
  };

  const handleClosedCalendar = () => {
    const currentMonthDates = getDatesForCurrentMonth(currentMonth);
    const updatedEvents: BookingEvent[] = currentMonthDates.map((date) => ({
      title: "Closed",
      start: date,
      end: date,
      allDay: true,
      availability: "closed",
      price: 0,
      type: "closed",
    }));
    setEvents(updatedEvents);

    // Update the calendar status for the current month
    setCalendarStatus((prevStatus) => ({
      ...prevStatus,
      [currentMonth.format("YYYY-MM")]: "closed",
    }));
    dispatch(
      setPriceAndAvailability({
        field: "calendarStatus",
        value: {
          ...calendarStatus,
          [currentMonth.format("YYYY-MM")]: "closed",
        },
      })
    );
  };

  const handleSelectDate = (slotInfo: SlotInfo) => {
    const clickedDate = moment(slotInfo.start).startOf("day").toDate();

    if (selectedDate && moment(selectedDate).isSame(clickedDate, "day")) {
      setSelectedDate(null); // Deselect the date
      setSelectedEvent(null); // Reset the selected event
      setSelectedDateRequests([]); // Reset the requests
      setIsSidebarOpen(false);
      return;
    }

    // Rename the variable used for comparison to avoid conflict with state variable
    const selectedMoment = moment(slotInfo.start).startOf("day");
    setSelectedDate(clickedDate);

    const requestsForDate: Reservations = [];
    const reservedForDate: Reservations = [];
    let bookedEventDetails: BookingEvent | null = null;

    let reservedEvent: BookingEvent | null = null;

    let openEvent: BookingEvent | null = null;

    let closedEvent: BookingEvent | null = null;

    // TODO:
    // let isDateClosed = false;

    events.forEach((event) => {
      const eventStart = moment(event.start).startOf("day");
      const eventEnd = moment(event.end).startOf("day");
      if (
        event.type === "closed" &&
        selectedMoment.isBetween(eventStart, eventEnd, undefined, "[]")
      ) {
        // TODO
        // isDateClosed = true;
      }

      if (event.type === "requested" && event.requests) {
        if (selectedMoment.isBetween(eventStart, eventEnd, undefined, "[]")) {
          event.requests.forEach((request) => {
            requestsForDate.push(request);
          });
          bookedEventDetails = event;
        }
      }

      if (event.type === "booked" && event.requests) {
        if (selectedMoment.isBetween(eventStart, eventEnd, undefined, "[]")) {
          event.requests.forEach((request) => {
            reservedForDate.push(request);
          });
        }
        reservedEvent = event;
      }

      if (event.type === "open") {
        if (selectedMoment.isSame(eventStart, "day")) {
          openEvent = event;
        }
      }

      if (event.type === "closed") {
        if (selectedMoment.isSame(eventStart, "day")) {
          closedEvent = event;
        }
      }
    });

    setIsSidebarOpen(true);

    if (bookedEventDetails || reservedEvent) {
      setSelectedEvent(bookedEventDetails || reservedEvent);
      setSelectedDateRequests(requestsForDate);
      setSelectedDateReserved(reservedForDate);
      setIsSidebarOpen(true);
    } else if (openEvent || closedEvent) {
      setSelectedEvent(openEvent || closedEvent);
    } else {
      setSelectedDateRequests(requestsForDate);
      setSelectedDateReserved(reservedForDate);
      setSelectedEvent(null);
    }
  };

  const handleSaveForDate = (
    date: Date,
    availability: string,
    price: number
  ) => {
    const dateKey = dayjs(date).format("YYYY-MM-DD");

    // Check if the date falls between any requested events
    const isDateInRequestedEvent = events.some((event) => {
      if (event.type === "requested") {
        const eventStart = moment(event.start).startOf("day");
        const eventEnd = moment(event.end).startOf("day");
        // Check if the date falls between the start and end of this requested event
        return moment(date).isBetween(eventStart, eventEnd, undefined, "[]");
      }
      return false;
    });

    // If the date is in a requested event, do not set the event
    if (isDateInRequestedEvent) {
      toast.warning(
        "You cannot set an event on a date that has a pending request."
      );
      return;
    }

    // Update price and closure logic remains the same
    if (price !== defaultRate[currentMonth.format("YYYY-MM")]) {
      setIndividualDateAvailability((prev) => ({
        ...prev,
        [dateKey]: price,
      }));
      dispatch(
        setPriceAndAvailability({
          field: "individualDateAvailability",
          value: {
            ...individualDateAvailability,
            [dateKey]: price,
          },
        })
      );
    }

    // Closure date logic
    if (availability === "closed") {
      setClosureDate((prev) => {
        if (!prev.includes(dateKey)) {
          return [...prev, dateKey];
        }
        return prev;
      });

      dispatch(
        setPriceAndAvailability({
          field: "closureDate",
          value: !closureDate.includes(dateKey)
            ? [...closureDate, dateKey]
            : closureDate,
        })
      );
    } else {
      setClosureDate(
        closureDate.filter((closedDate) => closedDate !== dateKey)
      );

      dispatch(
        setPriceAndAvailability({
          field: "closureDate",
          value: closureDate.filter((closedDate) => closedDate !== dateKey),
        })
      );
    }

    // Update the event list with the new values
    const updatedEvents = events.map((event) =>
      event.start.toDateString() === date.toDateString()
        ? {
            ...event,
            title: availability === "closed" ? "Closed" : `$${price}`,
            availability,
            price,
            type: availability,
          }
        : event
    );

    setEvents(updatedEvents);
  };

  const dayPropGetter = (date: Date) => {
    const isSelected =
      selectedDate && dayjs(date).isSame(dayjs(selectedDate), "day");

    if (isSelected) {
      return {
        className: "selected-date",
      };
    }

    return {};
  };

  const fetchMonthPriceAvailability = async ({
    requests,
  }: {
    requests: BookingEvent[];
  }) => {
    call(
      () =>
        fetchMonthAvailability({
          venue_id: venueId,
          month: currentMonth.format("YYYY-MM"),
        }),
      (res) => {
        const {
          data: { data },
        } = res;
        const monthKey = currentMonth.format("YYYY-MM");
        const monthPrices = data.month_price || 0;
        const dayPrice = data?.day.price || {};
        const closureDates = data?.day.closure_dates || [];
        const allDates: string[] = [...reservedDates];
        const updatedEvents: BookingEvent[] = [...reservedEvents];
        const reservationMap: { [key: string]: BookingEvent } = {};
        const advanceBooingTime = data?.advance_booking_time;
        const fetchedDayPriceId = data?.day.id;
        let fetchedBasePrice = data.base_price;

        setReservedData(data.reserved_dates || []);

        // dispatch start and end date
        dispatch(
          setPriceAndAvailability({
            field: "startDate",
            value: data.start_date,
          })
        );
        dispatch(
          setPriceAndAvailability({
            field: "endDate",
            value: data.end_date,
          })
        );
        dispatch(
          setInitialPriceAndAvailability({
            field: "startDate",
            value: data.start_date,
          })
        );
        dispatch(
          setInitialPriceAndAvailability({
            field: "endDate",
            value: data.end_date,
          })
        );

        // dispatch accepting booking for next
        // difference between start and end date
        const differenceInStartAndEndDate = dayjs(data.start_date).diff(
          dayjs(data.end_date),
          "day"
        );

        // Helper function to handle dispatch logic
        const updateBookingTime = (value: string) => {
          priceAvailabilitySelector.initialPriceAvailabilityData
            .acceptBookingForNext === "" &&
            dispatch(
              setPriceAndAvailability({
                field: "acceptBookingForNext",
                value,
              })
            );
          dispatch(
            setInitialPriceAndAvailability({
              field: "acceptBookingForNext",
              value,
            })
          );
        };

        switch (differenceInStartAndEndDate) {
          case -365:
            updateBookingTime(ACCEPT_BOOKING_TIME_TYPE.year);
            break;

          case -91:
          case -92:
          case -90:
            updateBookingTime(ACCEPT_BOOKING_TIME_TYPE.month3);
            break;

          case -182:
          case -183:
          case -184:
            updateBookingTime(ACCEPT_BOOKING_TIME_TYPE.month6);
            break;
          default:
            break;
        }

        // dispatch base price
        dispatch(
          setInitialPriceAndAvailability({
            field: "basePrice",
            value: data.base_price || 0,
          })
        );

        dispatch(
          setPriceAndAvailability({
            field: "basePrice",
            value: data.base_price || 0,
          })
        );

        setAdvanceBooking({
          timeFormat: advanceBooingTime.time_format,
          value: advanceBooingTime.value,
        });

        // dispatch advance booking
        dispatch(
          setInitialPriceAndAvailability({
            field: "advanceBooking",
            value: {
              timeFormat: advanceBooingTime.time_format,
              value: advanceBooingTime.value,
            },
          })
        );
        dispatch(
          setPriceAndAvailability({
            field: "advanceBooking",
            value: {
              timeFormat: advanceBooingTime.time_format,
              value: advanceBooingTime.value,
            },
          })
        );

        setIndividualDateAvailability((prev) => ({
          ...prev,
          ...dayPrice,
        }));

        // dispatch individual date availability
        dispatch(
          setInitialPriceAndAvailability({
            field: "individualDateAvailability",
            value: {
              ...individualDateAvailability,
              ...dayPrice,
            },
          })
        );
        dispatch(
          setPriceAndAvailability({
            field: "individualDateAvailability",
            value: {
              ...individualDateAvailability,
              ...dayPrice,
            },
          })
        );

        if (defaultBasePrice === 0) {
          setDefaultBasePrice(fetchedBasePrice);
          dispatch(
            setPriceAndAvailability({
              field: "defaultBasePrice",
              value: fetchedBasePrice,
            })
          );
          dispatch(
            setInitialPriceAndAvailability({
              field: "defaultBasePrice",
              value: fetchedBasePrice,
            })
          );
        }

        reservedData.forEach((event) => {
          const { id, checkInDate, checkOutDate, reservationId } = event;

          const dateRange = getDateRange({
            startDate: event.checkInDate,
            endDate: event.checkOutDate,
          });
          allDates.push(
            ...dateRange.filter((date) => !allDates.includes(date))
          );

          if (reservationMap[id]) return;

          const newRequest = {
            id,
            check_in_date: event.checkInDate,
            check_out_date: event.checkOutDate,
            reservation_id: reservationId,
            status: "pending",
            retreat_host: {
              user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
              name: "John Doe",
              profile_picture_url: "",
              city: "New York",
            },
          };

          const newEvent: BookingEvent = {
            start: new Date(checkInDate),
            end: new Date(checkOutDate),
            title: (
              <Text
                style={{ width: 157, color: "#fff" }}
                ellipsis={{
                  tooltip: `Booked`,
                }}
              >
                Booked
              </Text>
            ),
            type: "booked",
            allDay: true,
            requests: [newRequest],
          };

          // check if any event with id of new request already exists in requested events
          const existingEvent = updatedEvents.find((event) =>
            event.requests?.some((request) => request.id === id)
          );

          reservationMap[id] = newEvent;
          !existingEvent && updatedEvents.push(newEvent); // Push event if unique
        });

        setReservedDates(allDates);
        // Batch update events
        setReservedEvents(updatedEvents);

        // dispatch day price id
        fetchedDayPriceId &&
          dispatch(
            setInitialPriceAndAvailability({
              field: "dayPriceId",
              value: {
                ...dayPrice,
                [monthKey]: fetchedDayPriceId,
              },
            })
          );

        dispatch(
          setPriceAndAvailability({
            field: "dayPriceId",
            value: {
              ...dayPrice,
              [monthKey]: fetchedDayPriceId,
            },
          })
        );

        setClosureDate((prev) => [...prev, ...closureDates]);

        // dispatch closure date
        dispatch(
          setPriceAndAvailability({
            field: "closureDate",
            value: [...closureDate, ...closureDates],
          })
        );

        dispatch(
          setInitialPriceAndAvailability({
            field: "closureDate",
            value: [...closureDate, ...closureDates],
          })
        );

        const fetchedMonthPriceData = {
          ...monthPriceData,
          [monthKey]: monthPrices === 0 ? fetchedBasePrice : monthPrices,
        };

        setMonthPriceData(fetchedMonthPriceData);

        // dispatch month price data
        dispatch(
          setInitialPriceAndAvailability({
            field: "monthPriceData",
            value: fetchedMonthPriceData,
          })
        );

        dispatch(
          setPriceAndAvailability({
            field: "monthPriceData",
            value: fetchedMonthPriceData,
          })
        );
        const currentStatus = calendarStatus[monthKey] || "open";
        if (currentStatus === "open") {
          handleOpenCalendar(
            (defaultBasePrice === fetchedBasePrice || defaultRate[monthKey]
              ? defaultRate[monthKey]
              : defaultBasePrice) ||
              (defaultBasePrice === fetchedBasePrice
                ? fetchedMonthPriceData[monthKey]
                : monthPrices) ||
              (defaultBasePrice !== 0 ? defaultBasePrice : fetchedBasePrice),
            monthKey,
            {
              ...individualDateAvailability,
              ...dayPrice,
            },
            [...closureDate, ...closureDates],
            requests,
            updatedEvents
          );
        } else {
          handleClosedCalendar();
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const eventStyleGetter = (event: BookingEvent) => {
    let backgroundColor;
    let borderStyle = "none";
    let className = "";

    if (event.type === "booked") {
      backgroundColor = "#5cb85c !important";
    } else if (event.type === "requested") {
      backgroundColor = "#3174ad !important";
    } else if (event.type === "closed") {
      backgroundColor = "transparent !important";
    }

    switch (event.type) {
      case "booked":
        className = "booked-event";
        break;
      case "requested":
        className = "requested-event";
        break;
      case "closed":
        className = "closed-event";
        break;

      default:
        className = "open-date-event";
        break;
    }

    return {
      className: className,
      style: {
        backgroundColor,
        borderRadius: "14px",
        opacity: 0.8,
        color: "white",
        border: borderStyle,
        display: "block",
        padding: "2px 5px",
        fontSize: "16px",
      },
    };
  };

  const fetchTaxesAndDiscounts = async () => {
    setTaxLoading(true);
    call(
      () => fetchTaxDiscount({ venueId: venueId as string }),
      (res) => {
        const { taxes: taxData, discount_codes } = res?.data?.data;
        Object.keys(taxData).length > 0
          ? setTaxes({
              accommodationTaxRegistrationNumber:
                taxData?.accomodation_tax_registration_number,
              businessTaxId: taxData?.business_tax_id,
              exemptionsForLongTermStays:
                taxData?.exemptions_for_long_term_stays,
              id: taxData?.id,
              referenceId: taxData?.reference_id,
              termsAgreed: taxData?.terms_agreed,
              taxAmount: taxData?.tax_amount,
              taxFor: taxData?.tax_for,
              taxType: taxData?.tax_type,
              typeOfCharge: taxData?.type_of_charge,
            })
          : setTaxes({});
        setDiscounts(
          discount_codes?.map((discount: any) => {
            return {
              id: discount?.id,
              referenceId: discount?.reference_id,
              discountCode: discount?.discount_code,
              discountType: discount?.discount_type,
              discountFor: discount?.discount_for,
              discountValue: discount?.discount_value,
              expiryDate: discount?.expiry_date,
              isPublic: discount?.is_public,
            };
          })
        );
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch taxes");
      }
    ).finally(() => setTaxLoading(false));
  };

  useEffect(() => {
    const fetchMonthData = async () => {
      // Fetch upcoming reservations
      fetchUpcomingReservations({
        reservation_date: currentMonth.startOf("month").format("YYYY-MM-DD"),
        approval_status: "Pending",
        venue_id: venueId as string,
      });
    };

    fetchMonthData();
  }, [currentMonth]);

  useEffect(() => {
    handleOpenCalendar(
      monthPriceData[currentMonth.format("YYYY-MM")] || defaultBasePrice,
      currentMonth.format("YYYY-MM"),
      individualDateAvailability,
      closureDate,
      requestedEvents,
      reservedEvents
    );
  }, [defaultBasePrice]);

  useEffect(() => {
    fetchTaxesAndDiscounts();
  }, []);

  useEffect(() => {
    // set closure date on changing month
    if (calendarStatus[currentMonth.format("YYYY-MM")] === "open") {
      events.map((event) => {
        if (event.availability === "closed") {
          setClosureDate(
            closureDate.includes(dayjs(event.start).format("YYYY-MM-DD"))
              ? closureDate
              : [...closureDate, dayjs(event.start).format("YYYY-MM-DD")]
          );
          dispatch(
            setPriceAndAvailability({
              field: "closureDate",
              value: closureDate.includes(
                dayjs(event.start).format("YYYY-MM-DD")
              )
                ? closureDate
                : [...closureDate, dayjs(event.start).format("YYYY-MM-DD")],
            })
          );
        } else {
          setClosureDate(
            closureDate.filter(
              (date) => date !== dayjs(event.start).format("YYYY-MM-DD")
            )
          );
          dispatch(
            setPriceAndAvailability({
              field: "closureDate",
              value: closureDate.filter(
                (date) => date !== dayjs(event.start).format("YYYY-MM-DD")
              ),
            })
          );
        }
        return event;
      });
    }
  }, [calendarStatus]);

  useEffect(() => {
    !Object.keys(acceptReservations).includes(currentMonth.format("YYYY-MM")) &&
      setAcceptReservations({
        ...acceptReservations,
        [currentMonth.format("YYYY-MM")]: true,
      });
  }, [currentMonth, calendarStatus[currentMonth.format("YYYY-MM")]]);

  return (
    <div className="form-white-bg w-100">
      <div className="availability-calender-container create-venue-availability edit-venue-availability">
        <div className="d-flex align-items-center mb-2">
          <button
            type="button"
            onClick={handlePreviousMonth}
            className="direction-button"
          >
            <img src={images.LEFT_ARROW_ICON} alt="" />
          </button>
          <DatePicker
            inputReadOnly
            picker="month"
            value={currentMonth}
            defaultValue={currentMonth}
            onChange={handleMonthChange}
            allowClear={false}
            style={{ margin: "0 16px", maxWidth: 108 }}
            suffixIcon={false}
            popupClassName="availability-calender-date-picker"
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
          />
          <button
            type="button"
            onClick={handleNextMonth}
            className="direction-button"
          >
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        </div>
        <div className="d-flex gap-4 calendar-sidebar-container">
          <div className="flex-grow-1 w-100">
            {loading ? (
              <Skeleton />
            ) : (
              <Calendar
                localizer={localizer}
                events={events}
                onNavigate={(date) => setCurrentMonth(dayjs(date))}
                selectable={
                  acceptReservations[currentMonth.format("YYYY-MM")] || false
                }
                views={["month"]}
                onSelectSlot={(value) => {
                  handleSelectDate(value);
                }}
                style={{ height: 500 }}
                startAccessor="start"
                endAccessor="end"
                date={currentMonth.toDate()}
                dayPropGetter={dayPropGetter}
                slotPropGetter={dayPropGetter}
                eventPropGetter={eventStyleGetter}
                components={{
                  event: ({ event }) => {
                    if (event.title === "Closed") {
                      return (
                        <div className="closed-event">
                          <span role="img" aria-label="lock">
                            <img
                              src={images.LOCK_ICON}
                              alt=""
                              height={"16px"}
                            />
                          </span>
                          <SmallText text="Closed" />
                        </div>
                      );
                    }
                    return <span>{event.title}</span>;
                  },
                }}
                popup
                showMultiDayTimes
              />
            )}
          </div>

          {!isSidebarOpen && (
            <CalendarSettings
              setDefaultBasePrice={setDefaultBasePrice}
              defaultBasePrice={defaultBasePrice}
              onAvailabilityChange={onAvailabilityChange}
              onRateChange={(rate) => {
                if (calendarStatus[currentMonth.format("YYYY-MM")] === "open") {
                  handleOpenCalendar(
                    rate[currentMonth.format("YYYY-MM")] || 0,
                    currentMonth.format("YYYY-MM")
                  );
                }

                if (
                  monthPriceData[currentMonth.format("YYYY-MM")] ===
                  rate[currentMonth.format("YYYY-MM")]
                ) {
                  const newRate = { ...defaultRate };
                  setDefaultRate(newRate);

                  dispatch(
                    setPriceAndAvailability({
                      field: "defaultRate",
                      value: newRate,
                    })
                  );
                }
              }}
              basePrice={defaultRate[currentMonth.format("YYYY-MM")]}
              month={currentMonth.format("YYYY-MM")}
              availabilityStatus={
                calendarStatus[currentMonth.format("YYYY-MM")]
              }
              setAcceptReservations={setAcceptReservations}
              acceptReservations={acceptReservations}
              createVenue={false}
              setAdvanceBooking={setAdvanceBooking}
              advanceBooking={advanceBooking}
              venueId={venueId}
              fetchTax={fetchTaxesAndDiscounts}
              fetchDiscountCodes={fetchTaxesAndDiscounts}
              fetchedTax={taxes}
              fetchedDiscountCodes={discounts}
              taxLoading={taxLoading}
              discountLoading={taxLoading}
            />
          )}

          {isSidebarOpen && selectedDate && (
            <IndividualDateAvailabilitySidebar
              date={selectedDate}
              event={selectedEvent}
              onSave={(date, availability, price) => {
                handleSaveForDate(date, availability, price);
              }}
              defaultRate={
                individualDateAvailability[currentMonth.format("YYYY-MM-DD")] ||
                0
              }
              setIsSidebarOpen={setIsSidebarOpen}
              createVenue={false}
              requests={selectedDateRequests}
              reserved={selectedDateReserved}
              basePrice={defaultBasePrice}
              setMonthAvailability={setCalendarStatus}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceAndAvailability;
