import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  VenueAmenities,
  venueDataPrefilledType,
} from "../../types/property-manager";
import dayjs from "dayjs";

type venueDataType = venueDataPrefilledType;

export type ReduxBookingTermsTypes = {
  check_in: string;
  check_out: string;
  night_stays: number;
  guest_number: number;
  quiet_hours_start: string;
  quiet_hours_end: string;
  check_in_period: "AM" | "PM";
  check_out_period: "AM" | "PM";
  quiet_hours_start_period: "AM" | "PM";
  quiet_hours_end_period: "AM" | "PM";
};

export type PriceAndAvailabilityType = {
  individualDateAvailability: {
    [key: string]: number;
  };
  defaultRate: { [key: string]: number };
  monthPriceData: {
    [key: string]: number;
  };
  calendarStatus: {
    [key: string]: string;
  };
  dayPriceId: {
    [key: string]: string | null;
  };
  defaultBasePrice: number;
  advanceBooking: {
    value: number;
    timeFormat: "month" | "day";
  };
  closureDate: string[];
  basePrice: number;
  acceptBookingForNext?: string;
  startDate: string;
  endDate: string;
};

interface VenueState {
  venueData: venueDataType;
  initialVenueData: venueDataType;
  initialPriceAvailabilityData: PriceAndAvailabilityType;
  priceAvailabilityData: PriceAndAvailabilityType;
  amenitiesData: VenueAmenities[];
  initialAmenitiesData: VenueAmenities[];
  photosData: {
    id: string;
    image_url: string;
  }[];
  initialPhotosData: {
    id: string;
    image_url: string;
  }[];
  initialBookingTerms: ReduxBookingTermsTypes;
  bookingTerms: ReduxBookingTermsTypes;
}

const initialState: VenueState = {
  venueData: {
    type_of_property_id: "",
    number_of_rooms: 1,
    number_of_bathrooms: 1,
    sleeps: 1,
    custom_paragraphs: [],
    venue_highlights_id: [],
    recommended_retreats: [],
    zip_code: "",
    country: "",
    property_name: "",
    city: "",
    address: "",
    property_description: "",
    language_id: [],
    media_url: "",
  },
  initialVenueData: {
    type_of_property_id: "",
    number_of_rooms: 1,
    number_of_bathrooms: 1,
    sleeps: 1,
    custom_paragraphs: [],
    venue_highlights_id: [],
    recommended_retreats: [],
    zip_code: "",
    country: "",
    property_name: "",
    city: "",
    address: "",
    property_description: "",
    language_id: [],
    media_url: "",
  },
  amenitiesData: [],
  initialAmenitiesData: [],
  photosData: [],
  initialPhotosData: [],
  initialBookingTerms: {
    check_in: "",
    check_out: "",
    night_stays: 1,
    guest_number: 1,
    quiet_hours_start: dayjs().toISOString(),
    quiet_hours_end: dayjs().toISOString(),
    check_in_period: "AM",
    check_out_period: "AM",
    quiet_hours_start_period: "AM",
    quiet_hours_end_period: "AM",
  },
  bookingTerms: {
    check_in: dayjs().toISOString(), // Store as ISO string
    check_out: dayjs().toISOString(),
    night_stays: 1,
    guest_number: 1,
    quiet_hours_start: dayjs().toISOString(),
    quiet_hours_end: dayjs().toISOString(),
    check_in_period: "AM",
    check_out_period: "AM",
    quiet_hours_start_period: "AM",
    quiet_hours_end_period: "AM",
  },
  initialPriceAvailabilityData: {
    individualDateAvailability: {},
    defaultRate: {},
    monthPriceData: {},
    calendarStatus: {},
    dayPriceId: {},
    defaultBasePrice: 0,
    advanceBooking: {
      value: 0,
      timeFormat: "month",
    },
    closureDate: [],
    basePrice: 0,
    acceptBookingForNext: "",
    startDate: "",
    endDate: "",
  },
  priceAvailabilityData: {
    individualDateAvailability: {},
    defaultRate: {},
    monthPriceData: {},
    calendarStatus: {},
    dayPriceId: {},
    defaultBasePrice: 0,
    advanceBooking: {
      value: 0,
      timeFormat: "month",
    },
    closureDate: [],
    basePrice: 0,
    acceptBookingForNext: "",
    startDate: "",
    endDate: "",
  },
};

export const venueDataSlice = createSlice({
  name: "venueData",
  initialState,
  reducers: {
    setVenueData: <K extends keyof VenueState["venueData"]>(
      state: VenueState,
      action: PayloadAction<{ field: K; value: VenueState["venueData"][K] }>
    ) => {
      state.venueData[action.payload.field] = action.payload.value;
    },
    setInitialVenueData: <K extends keyof VenueState["initialVenueData"]>(
      state: VenueState,
      action: PayloadAction<{
        field: K;
        value: VenueState["initialVenueData"][K];
      }>
    ) => {
      state.initialVenueData[action.payload.field] = action.payload.value;
    },
    setAmenitiesData: (state, action: PayloadAction<VenueAmenities[]>) => {
      state.amenitiesData = action.payload;
    },
    setInitialAmenitiesData: (
      state,
      action: PayloadAction<VenueAmenities[]>
    ) => {
      state.initialAmenitiesData = action.payload;
    },
    setPhotosData: (state, action: PayloadAction<any>) => {
      state.photosData = action.payload;
    },
    setInitialPhotosData: (state, action: PayloadAction<any>) => {
      state.initialPhotosData = action.payload;
    },
    setInitialBookingTerms: <K extends keyof VenueState["initialBookingTerms"]>(
      state: VenueState,
      action: PayloadAction<{
        field: K;
        value: VenueState["initialBookingTerms"][K];
      }>
    ) => {
      state.initialBookingTerms[action.payload.field] = action.payload.value;
    },
    setBookingTerms: <K extends keyof VenueState["bookingTerms"]>(
      state: VenueState,
      action: PayloadAction<{ field: K; value: VenueState["bookingTerms"][K] }>
    ) => {
      state.bookingTerms[action.payload.field] = action.payload.value;
    },
    setInitialPriceAndAvailability: <
      K extends keyof VenueState["priceAvailabilityData"]
    >(
      state: VenueState,
      action: PayloadAction<{
        field: K;
        value: VenueState["priceAvailabilityData"][K];
      }>
    ) => {
      state.initialPriceAvailabilityData[action.payload.field] =
        action.payload.value;
    },
    setPriceAndAvailability: <
      K extends keyof VenueState["priceAvailabilityData"]
    >(
      state: VenueState,
      action: PayloadAction<{
        field: K;
        value: VenueState["priceAvailabilityData"][K];
      }>
    ) => {
      state.priceAvailabilityData[action.payload.field] = action.payload.value;
    },
  },
});

export const {
  setVenueData,
  setAmenitiesData,
  setInitialVenueData,
  setInitialAmenitiesData,
  setPhotosData,
  setInitialPhotosData,
  setInitialBookingTerms,
  setBookingTerms,
  setInitialPriceAndAvailability,
  setPriceAndAvailability,
} = venueDataSlice.actions;

export default venueDataSlice.reducer;
