import React from "react";
//common component
import SmallText from "../../../UI/SmallText";
//types
import { FormFooterProps } from "../../../../types/retreat";
//images
import { images } from "../../../../assets/images";
//css
import "../../../../assets/css/createRetreat.css";
const StepFormFooter: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
  handleExit,
  buttonText,
  btnDisable
}) => {
  
  return (
    <div className="step-form-footer">
      <div className="container footer-detail d-flex justify-content-between align-items-center">
        <button
          style={{
            border: "none",
            background: "transparent",
            height: "fit-content",
            padding: "12px 16px ",
          }}
          type="button"
          onClick={handleExit}
        >
          <div className="d-flex align-items-center gap-1">
            <img src={images.EXIT} alt="" width={12} height={12} />
            <SmallText
              text="Save and exit"
              lineHeight={16.45}
              fontWeight={500}
            />
          </div>
        </button>

        <div className="d-flex align-items-center gap-3">
          <button className="back-btn" onClick={handlePreviousStep}>
            Back
          </button>
          <button
            className={`${btnDisable ? "disable-submit-btn" : "submit-btn "}`}
            onClick={() => handleNextStep()}
            disabled={btnDisable}
          >
            {buttonText ? buttonText : "Submit reservation"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepFormFooter;
