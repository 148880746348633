import React from "react";

// third party
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// custom components
import SmallText from "../../../UI/SmallText";
import Button from "../../../UI/Button";

// image
import { images } from "../../../../assets/images";

// types
import { CreateVenueFooterType } from "../../../../types/property-manager";

const CreateVenueFooter: React.FC<CreateVenueFooterType> = ({
  disabled = false,
  disableSaveButton = false,
  submitting,
  continueButtonType,
  saveVenueDetails,
  venueId,
  previousPage,
  nextPage,
  continueOnclick,
  handleBackButton,
  showBackButton = true,
  continueButtonLabel,
}) => {
  const navigate = useNavigate();

  return (
    <div className="create-venue-footer-container">
      <footer className="create-venue-footer container d-flex justify-content-between">
        <button
          style={{
            border: "none",
            background: "transparent",
            padding: 0,
            gap: "4px",
          }}
          type="button"
          className="d-flex align-items-center"
          onClick={
            saveVenueDetails
              ? saveVenueDetails
              : () => {
                  navigate("/");
                  toast.success("Details saved successfully");
                }
          }
          disabled={disableSaveButton}
        >
          <img src={images.SIGN_OUT_ICON} alt="" />
          <SmallText text="Save and exit" />
        </button>

        <div className="d-flex gap-3 align-items-center">
          {showBackButton && (
            <div>
              <Button
                type="button"
                label="Back"
                className="bordered-button"
                onClick={handleBackButton}
              />
            </div>
          )}
          <div>
            <Button
              type={continueButtonType ? continueButtonType : "submit"}
              label={
                submitting ? "Submitting..." : continueButtonLabel || "Continue"
              }
              disabled={disabled}
              onClick={continueOnclick}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CreateVenueFooter;
