import React from "react";

// third party
import { NavLink } from "react-router-dom";

// types
import { SidebarProps } from "../../../types";

// images
import { images } from "../../../assets/images";

// styles
import "./style.css";

// Define the props for the Sidebar component

const Sidebar: React.FC<SidebarProps> = ({ items, footerContent }) => {
  return (
    <div className="sidebar">
      <div className="menu">
        <div className="logo">
          <img src={images.LAIGO_LOGO} alt="" />
        </div>
        {items.map((item, index) => (
          <div key={index} className={`menu-item`}>
            <NavLink
              to={item.url ? item.url : "/venues/home"}
              key={index}
              className="menu-item"
            >
              <div className="icon">
                <img src={item.icon} alt="" width={24} height={24} />
              </div>
              <div className="label">{item.label}</div>
            </NavLink>
          </div>
        ))}
      </div>
      {footerContent && <div className="footer">{footerContent}</div>}
    </div>
  );
};

export default Sidebar;
