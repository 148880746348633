import React, { useEffect, useState } from "react";

//third party
import { useSelector } from "react-redux";

//common component
import SmallText from "../../../UI/SmallText";

//redux store
import { RootState } from "../../../../redux/store";

//types
import { AddonService } from "../../../../types/retreat";

//images
import { images } from "../../../../assets/images";

const AddOnCard: React.FC<AddonService> = ({
  charges,
  description,
  external_link,
  tickets,
  icon_id,
  id,
  name,
  index,
  type,
  service_type,
  onCardSelect,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const retreat = useSelector((state: RootState) => state.createRetreat);
  const handleCard = (name: string, charges: number, id: string) => {
    onCardSelect!(name, charges, id);
    setIsSelected(!isSelected);
  };
  
  
  useEffect(() => {
    if (retreat.add_on_services_id?.includes(id)) {
      setIsSelected(true);
      handleCard(name, charges, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retreat.add_on_services_id, id,name,charges]);  


  return (
    <div
      className={`add-on-card ${isSelected ? "selected" : ""}`}
      key={id}
      
      onClick={() => {
        handleCard(name, charges, id);
      }}
    >
      <div className="d-flex gap-1 align-items-center">
        <img
          src={icons.find((icon) => icon.id === icon_id)?.icon_url || images.STAR}
          alt="icon"
          width={28}
          height={28}
        />
        <h1 className="m-0 card-title align-self-center"> {name} </h1>
      </div>
      <div>
        <p
          className="m-0 card-description"
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
          }}
        >
          {description}
        </p>
      </div>
      <div className="d-flex flex-grow-1 align-items-end">
        <h2 className="card-price m-0">{charges === 0 ? "Free" : charges}</h2>
        {charges !== 0 ? (
          <span>
            <SmallText
              text={`/${service_type}`}
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddOnCard;
