//third-party
// custom components
import SmallText from "../../../UI/SmallText";

// types
import { PageTitleProps } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const PageTitle = ({
  venueName,
  venueCountry,
  // venueType,
  venueCity,
  venueTypeIcon,
}: PageTitleProps) => {
  return (
    <div className="venue-page-header">
      <div className="title-container flex-grow-1">
        {venueName && <div className="venue-title">{venueName}</div>}
        <div className="venue-page-location-and-type d-flex">
          {venueCountry && (
            <div className="venue-location icon-container d-flex align-items-center">
              <img src={images.LOCATION_ICON} alt="location_icon" />
              {venueCity && <span>{venueCity},</span>}
              <span>{venueCountry}</span>
            </div>
          )}
        </div>
      </div>
      <div className="share-save-button d-flex ">
        <div>
          <img src={images.SHARE_ICON} alt="share icon" width={16} />
          <SmallText text="Share" />
        </div>
        <div>
          <img src={images.HEART_ICON} alt="heart icon" width={16} />
          <SmallText text="Save" />
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
