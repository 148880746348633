import { useEffect, useState } from "react";

// third party
import { Input, Switch } from "antd";
import dayjs from "dayjs";

// custom component
import FormLabel from "../../../../UI/FormLabel";
import StatusTags from "../../../../UI/StatusTags";
import SmallText from "../../../../UI/SmallText";

// types
import { SidebarProps } from "../../../../../types/property-manager";

// images
import { images } from "../../../../../assets/images";

// constants
import { BOOKED_VENUE_DETAIL_TABLE } from "../../../../../constants";

const IndividualDateAvailabilitySidebar: React.FC<SidebarProps> = ({
  date,
  event,
  onSave,
  defaultRate,
  setIsSidebarOpen,
  createVenue = false,
  basePrice,
  setMonthAvailability,
}) => {
  const [availability, setAvailability] = useState<string>("open");
  const [price, setPrice] = useState<number>(defaultRate || 0);
  const [showInput, setShowInput] = useState<boolean>(true);

  const handleSave = (newPrice: number, availability: string) => {
    const newPriceValue = newPrice === 0 ? basePrice || 0 : newPrice;
    setPrice(newPrice);
    onSave(date, availability, newPriceValue);
  };

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    if (event) {
      setAvailability(event.availability || "open");
    }
    setPrice(event?.price || defaultRate || 0);
    if (
      event?.availability?.includes("booked") ||
      event?.availability?.includes("requested")
    ) {
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  }, [event, defaultRate]);

  return (
    <div
      className="default-availability-container"
      style={{ maxWidth: createVenue ? "396px" : "400px", gap: "24px" }}
    >
      <div>
        <h3>{`${formattedDate}`}</h3>
        <FormLabel
          label={`${date.toLocaleDateString("en-US", { weekday: "long" })}`}
        />
      </div>
      {showInput && (
        <div className="d-flex flex-column" style={{ gap: "24px" }}>
          <div className="d-flex justify-content-between">
            <FormLabel label="Accepting Reservations" />
            <Switch
              checked={availability === "open"}
              onChange={(checked) => {
                setAvailability(checked ? "open" : "closed");
                handleSave(price, checked ? "open" : "closed");
                checked &&
                  setMonthAvailability &&
                  setMonthAvailability((prevStatus) => ({
                    ...prevStatus,
                    [dayjs(date.toDateString()).format("YYYY-MM")]: "open",
                  }));
              }}
            />
          </div>
          {availability === "open" && (
            <>
              <SmallText text="Default Nightly Rate" color="#58585B" />
              <Input
                type="number"
                prefix="$"
                value={price}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (isNaN(inputValue)) {
                    e.target.value = "0";
                  }
                  handleSave(isNaN(inputValue) ? 0 : inputValue, availability);
                }}
                min={0}
                className="mt-2"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </>
          )}
        </div>
      )}

      {!showInput && (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <img src={images.AVATAR_IMG} height={"40px"} alt="" />
            </div>
            <div>
              <div className="common-sm-dark-text">Cameron Williamson</div>
              <div className="common-xs-light-text">Art of Living</div>
            </div>
            <div>
              <img src={images.MENU_MESSAGES_ICON} alt="" />
            </div>
          </div>
          <hr />
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between align-items-center ">
              <div>
                <div className="common-xs-light-text mb-1">Dates</div>
                <div className="common-sm-dark-text">Jul 15 - Jul 20</div>
              </div>
              <div>
                <StatusTags
                  label={"Confirmed"}
                  backgroundColor="#CEF5DC"
                  color="#398E58"
                  buttonPosition="relative"
                  top="0"
                  left="0"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="common-xs-light-text mb-1">Group size</div>
                <div className="common-sm-dark-text">12</div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="common-xs-light-text mb-1">
                  Reservation Number
                </div>
                <div className="common-sm-dark-text">3486548</div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="common-xs-light-text mb-1">
                  Special requests
                </div>
                <div className="common-sm-dark-text">Yoga mat, yoga blocks</div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="common-xs-light-text">Add-ons</div>
            <div>
              <table className="booked-venue-detail-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {BOOKED_VENUE_DETAIL_TABLE.map((item) => (
                    <tr>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <img src={item.icon} alt="" />
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <span>{item.price}</span>
                      </td>
                      <td>
                        <span>{item.total}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualDateAvailabilitySidebar;
