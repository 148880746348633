import React, { useState } from "react";

//third party
import { LogOut } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

//common component
import SmallText from "../../../UI/SmallText";
import Button from "../../../UI/Button";
import ModalComponent from "../../../UI/ModalComponent";

//redux store
import { RootState } from "../../../../redux/store";
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { createCheckoutSession } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// library
import { numberSeparator } from "../../../../library";

//types
import { FormFooterProps } from "../../../../types/retreat";

// images
import { images } from "../../../../assets/images";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_API_KEY as string
);

const ConfirmCancel: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const { call } = useApiCall();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowingCheckout, setIsShowingCheckout] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [checkoutInfo, setCheckoutInfo] = useState<{
    client_secret: string | null;
  }>();

  const payment = useSelector(
    (state: RootState) => state.cancelVenue.paymentData
  );

  const cancelRoutSelector = useSelector(
    (state: RootState) => state.cancelVenue.lastRoute
  );

  const handleStripeCheckout = async () => {
    setIsLoading(true);
    const dataToSend = {
      amount: payment.laigo_service_free + payment.penalty_fee,
      meta_data: payment.meta_data,
    };

    call(
      () => createCheckoutSession({ data: dataToSend }),
      (res) => {
        setCheckoutInfo({
          client_secret: res.data.data.client_secret,
        });
        setIsShowingCheckout(true);
      },
      (err) => {
        console.error("Error creating checkout session:", err);
      }
    ).finally(() => {
      setIsLoading(false);
    });
  };

  if (isShowingCheckout) {
    return (
      <div className="embedded-checkout">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            clientSecret: checkoutInfo?.client_secret,
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>

        <div className="d-flex align-items-center justify-content-between mt-4">
          <button
            className="exit-btn"
            onClick={() => setIsShowingCheckout(false)}
          >
            <div className="d-flex align-items-center justify-content-center gap-1">
              <LogOut
                color="#2771A3"
                width={16}
                height={16}
                className="reverse-icon"
              />
              <SmallText
                text="Cancel"
                color="#2771A3"
                fontWeight={500}
                fontSize={14}
                lineHeight={16.45}
              />
            </div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-column bg-white p-5 step-box-shadow steps-gap">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-3">
            <SmallText
              text="Are you sure you want to cancel?"
              color="#19191C"
              fontWeight={700}
              fontSize={32}
              lineHeight={37.6}
            />
            <SmallText
              text={
                payment.penalty_fee > 0
                  ? "Your cancellation will not be processed until any applicable cancellation fees are paid and all received payouts have been refunded."
                  : "Upon cancellation, your retreat booking page will be immediately deactivated and guests will be refunded within 10 business days."
              }
              color="#58585B"
              fontWeight={400}
              fontSize={16}
              lineHeight={23}
            />
          </div>

          <div className="d-flex flex-column gap-3 cancel-fees">
            {payment.penalty_fee > 0 ? (
              <>
                <div className="d-flex flex-column gap-2 pb-4 ">
                  <SmallText
                    text="Cancellation Fees:"
                    color="#19191C"
                    fontWeight={700}
                    fontSize={16}
                    lineHeight={18.8}
                  />
                  <div className="d-flex flex-column justify-content-between cancel-fees-detail gap-3 ">
                    <div className="d-flex align-items-center justify-content-between">
                      <SmallText
                        text="Penalty Fee:"
                        color="#19191C"
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={16.45}
                      />
                      <SmallText
                        text={`$ ${numberSeparator(payment.penalty_fee, true)}`}
                        color="#19191C"
                        fontWeight={400}
                        fontSize={16}
                        lineHeight={18.8}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <SmallText
                        text="LAIGO Service Fee:"
                        color="#19191C"
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={16.45}
                      />
                      <SmallText
                        text={`$ ${numberSeparator(
                          payment.laigo_service_free,
                          true
                        )}`}
                        color="#19191C"
                        fontWeight={400}
                        fontSize={16}
                        lineHeight={18.8}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between cancel-fees-total">
                    <SmallText
                      text="Total Cancellation Fee:"
                      color="#39393D"
                      fontWeight={700}
                      fontSize={16}
                      lineHeight={18.8}
                    />
                    <SmallText
                      text={`$ ${numberSeparator(
                        payment.laigo_service_free + payment.penalty_fee,
                        true
                      )}`}
                      color="#19191C"
                      fontWeight={400}
                      fontSize={16}
                      lineHeight={18.8}
                    />
                  </div>
                </div>
                <div>
                  <SmallText
                    text="Payment options are available to assist you with the cancellation fee. Please review options, under"
                    color="#949397"
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={16.45}
                  />
                  <SmallText
                    text=" Make Payment."
                    color="#949397"
                    fontWeight={700}
                    fontSize={14}
                    lineHeight={16.45}
                  />
                </div>
              </>
            ) : (
              <div>
                <SmallText
                  text="You do not owe any cancellation fees."
                  color="#2771A3"
                  fontWeight={700}
                  fontSize={16}
                  lineHeight={18.8}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <button className="exit-btn">
            <div
              className="d-flex align-items-center justify-content-center gap-1"
              onClick={() => navigate(cancelRoutSelector)}
            >
              <LogOut
                color="#2771A3"
                width={16}
                height={16}
                className="reverse-icon"
              />
              <SmallText
                text="Exit"
                color="#2771A3"
                fontWeight={500}
                fontSize={14}
                lineHeight={16.45}
              />
            </div>
          </button>
          <div className="d-flex gap-4">
            {payment.penalty_fee > 0 ? (
              <button
                className="back-btn"
                onClick={handlePreviousStep}
                style={{ width: "102px" }}
              >
                Back
              </button>
            ) : null}

            <button
              className="submit-btn"
              onClick={() => {
                if (payment.penalty_fee > 0) {
                  handleStripeCheckout();
                } else {
                  dispatch(
                    setShowModal({
                      modalName: "cancel-success-modal",
                      visible: true,
                    })
                  );
                }
              }}
              disabled={isLoading}
            >
              {isLoading
                ? "Loading..."
                : payment.penalty_fee > 0
                ? "Make Payment"
                : "Cancel Reservation"}
            </button>
            <ModalComponent
              modalName={"cancel-success-modal"}
              className="reservation-success-modal"
            >
              <div
                className="reservation-success-container my-5"
                style={{ gap: "8px" }}
              >
                <div className="d-flex flex-column">
                  <img src={images.TICK} alt="" height={96} width={96} />
                  <p className="title common-xl-bold-dark-text p-0">
                    Reservation Successfully Canceled
                  </p>
                </div>
                <div className="mb-4">
                  <p className="description common-sm-light-grey-text text-center">
                    The retreat host will be notified of the cancellation.
                  </p>
                </div>
                <div className="dashboard-button">
                  <Button
                    type="button"
                    label="Go to Dashboard"
                    onClick={() => {
                      dispatch(
                        setShowModal({
                          modalName: "cancel-success-modal",
                          visible: false,
                        })
                      );

                      navigate("/venues/home");
                    }}
                  />
                </div>
              </div>
            </ModalComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmCancel;
