import React from "react";

//component
import PaymentDetail from "./PaymentDetail";

//common component
import SmallText from "../../../../UI/SmallText";
import Input from "../../../../UI/InputElement/Input";

//images
import { images } from "../../../../../assets/images";

const CheckoutInfo = () => {
  return (
    <div className="checkout-modal-content">
      <div className="checkout-section-detail d-flex flex-column gap-4">
        <div className="checkout-title">
          <img src={images.PERSON_ICON} alt="icon" width={28} height={28} />
          <SmallText
            text="Guest info"
            color="#2F206A"
            lineHeight={21.15}
            fontSize={18}
            fontWeight={400}
          />
        </div>
        <div className="overflow-y-auto d-flex flex-column gap-4">
          <div>
            <h3 className="m-0">Hello and welcome to our Yoga retreat!</h3>
            <p className="m-0">
              We are delighted that you have chosen to join us on this inspiring
              journey. This retreat has been carefully planned to offer you a
              serene and stimulating environment in which to explore and expand
              your creativity. With a series of activities and workshops led by
              expert instructors, we are sure it will be an enriching and
              transformative experience. To make sure we have all the
              information we need, please fill in the form below with your name
              and e-mail address.
            </p>
          </div>
          <form>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex gap-3">
                <div className="d-flex flex-column gap-1">
                  <Input label="First Name" placeholder="Jane" maxLength={32} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Input label="Last Name" placeholder="Doe" maxLength={32} />
                </div>
              </div>
              <div>
                <div className="d-flex flex-column gap-1">
                  <Input label="Email" type="email" />
                </div>
              </div>
              <div>
                <div className="d-flex flex-column gap-1">
                  <label htmlFor="">
                    <SmallText
                      text="Notes"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                  </label>
                  <textarea
                    placeholder="enter your notes here"
                    rows={5}
                    className="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PaymentDetail />
    </div>
  );
};

export default CheckoutInfo;
