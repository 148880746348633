import React from "react";

// types
import { SmallTextType } from "../../../types";

// styles
import "./style.css";

const SmallText: React.FC<SmallTextType> = ({
  text,
  className,
  fontSize,
  fontWeight,
  color,
  lineHeight,
  fontStyle,
  fontFamily,
}) => {
  return (
    <span
      className={`${className} greenText`}
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color,
        lineHeight: `${lineHeight}px`,
        fontStyle: fontStyle,
        fontFamily: fontFamily,
      }}
    >
      {text}
    </span>
  );
};

export default SmallText;
