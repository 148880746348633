/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Custom component
import MainLayout from "../../../MainLayout";
import PageTitle from "../PageTitle";
import Loader from "../../../../UI/Loader";
import AddVenueDetails from "./AddVenueDetails";

// redux
import {
  setLoading,
  setCreateVenueFormStep,
} from "../../../../../redux/features/common-slice";
import { RootState } from "../../../../../redux/store";

// network
import {
  fetchCreateVenueOptionsData,
  fetchVenue,
} from "../../../../../network/index";

// redux
import { setFetchedVenueData } from "../../../../../redux/features/fetchedVenueSlice";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import {
  highlights,
  languages,
  propertyTypes,
  retreatsHostTypes,
} from "../../../../../types/property-manager";

// styles
import "../../../../../assets/css/create-venue.css";

const CreateVenueFormStep1: React.FC<{
  venue_id?: string;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}> = ({ venue_id, handleNextStep, handlePreviousStep }) => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.commonReducer.loading
  );

  const [fetchHighlightOption, setFetchHighlightOption] = useState<
    highlights[]
  >([]);
  const [fetchLanguageOption, setFetchLanguageOption] = useState<languages[]>(
    []
  );
  const [fetchPropertyTypeOption, setFetchPropertyTypeOption] = useState<
    propertyTypes[]
  >([]);
  const [fetchData, setFetchedData] = useState<any>();
  const [fetchRetreatsHostTypeOption, setFetchRetreatsHostTypeOption] =
    useState<retreatsHostTypes[]>([]);

  const [venueDetailsLoader, setVenueDetailsLoader] = useState(true);

  const [venueOptionDataLoader, setVenueOptionDataLoader] = useState(true);

  const { call } = useApiCall();

  const fetchVenueDetails = async () => {
    venue_id
      ? call(
          () => fetchVenue(venue_id),
          (res) => {
            const {
              data: { data },
            } = res;
            setFetchedData(data);
            dispatch(setCreateVenueFormStep(data.form_state.step));
            dispatch(setFetchedVenueData(data));
            setVenueDetailsLoader(false);
          },
          (err) => {
            setVenueDetailsLoader(false);
            toast.error(
              err?.response?.data?.message || "Failed to fetch venue details: "
            );
          }
        )
      : setVenueDetailsLoader(false);
  };

  const fetchCreateVenueOptions = async () => {
    call(
      () => fetchCreateVenueOptionsData(),
      (res) => {
        setFetchHighlightOption(res.data.data.venue_highlights);
        setFetchLanguageOption(res.data.data.languages);
        setFetchPropertyTypeOption(res.data.data.property_type);
        setFetchRetreatsHostTypeOption(res.data.data.retreat_hosts);
        setVenueOptionDataLoader(false);
      },
      (err) => {
        toast.error(err.message || "Failed to fetch retreats host types");
        setVenueOptionDataLoader(false);
      }
    );
  };

  useEffect(() => {
    fetchCreateVenueOptions();
    fetchVenueDetails();
  }, []);

  useEffect(() => {
    if (!venueDetailsLoader && !venueOptionDataLoader) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }
  }, [venueDetailsLoader, venueOptionDataLoader]);

  return loading ? (
    <Loader />
  ) : (
    <MainLayout>
      <PageTitle
        title="About Venue"
        subtitle="Share your venue’s description, photos, and key details to help retreat hosts connect with its unique spirit."
      />
      <AddVenueDetails
        propertyTypes={fetchPropertyTypeOption}
        highlights={fetchHighlightOption}
        languages={fetchLanguageOption}
        retreatsHostTypes={fetchRetreatsHostTypeOption}
        venueData={fetchData}
        venueIdExisting={venue_id ? venue_id : undefined}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    </MainLayout>
  );
};

export default CreateVenueFormStep1;
