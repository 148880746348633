/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//third party
import { Plus } from "lucide-react";
import { Link } from "react-router-dom";

//common component
import SmallText from "../../../UI/SmallText";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//api call
import { createStripeAccount, userProfileData } from "../../../../network";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

const PayoutDetail: React.FC = () => {
  const { call } = useApiCall();
  const [accountId, setAccountId] = useState<boolean>(true);

  //get payment detail
  const getPayoutDetail = () => {
    call(
      () => userProfileData(),
      (res) => {
        setAccountId(res.data.data.is_onboarding_complete);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  //handle create account
  const handleStripeAccount = () => {
    call(
      () => createStripeAccount(),
      (res) => {
        window.location.href = `${res.data.data.url}`;
      },
      (err) => {
        ToastFail(err.response.data.message);
      }
    );
  };

  useEffect(() => {
    getPayoutDetail();
  }, []);

  return (
    <div className="d-flex flex-column gap-3 user-payout">
      <div
        className="d-flex bg-white p-4 gap-4 flex-column common-border"
        style={{ maxWidth: "770px" }}
      >
        <SmallText
          text="Payout Method"
          color="#19191C"
          lineHeight={21.15}
          fontWeight={500}
          fontSize={18}
        />
        
        <button className="submit-btn" onClick={() => handleStripeAccount()} disabled={accountId}>
          <div className="d-flex gap-1">
            <Plus width={16} height={16} />
            Add Bank Account
          </div>
        </button>
      </div>
      <div
        className="d-flex bg-white p-4 gap-3 flex-column common-border"
        style={{ maxWidth: "770px" }}
      >
        <SmallText
          text="Payout Schedule"
          color="#19191C"
          lineHeight={21.15}
          fontWeight={500}
          fontSize={18}
        />
        <p className="m-0 detail">
          A 15% deposit will be paid 15 days before the check-in date. The final
          payout with the remaining 85% of the total reservation amount will be
          paid 24 hours after the check-in date. 
         
        </p>
        <p className="m-0 detail"> See our <Link to="#">Payouts Policy</Link> for further details. </p>
      </div>
    </div>
  );
};

export default PayoutDetail;
