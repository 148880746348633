/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// components
import Header from "../../Header";
import SingleVenuePageHeader from "./VenuePageHeader";
import VenueSpaces from "./VenueSpaces";
import AddOnServices from "./AddOnServices";
import AboutOwner from "./AboutOwner";
import InfoNeedToKnow from "./InfoNeedToKnow";
import LocationComponent from "./VenueLocation";
import AboutSection from "../../RetreatHost/VenueDetail/AboutSection";
import VenuePageTitle from "../../RetreatHost/VenueDetail/VenuePageTitle";
import DetailsNotFound from "../../../UI/DetailsNotFound";
import Loader from "../../../UI/Loader";
import ImageComponent from "../../../UI/Images/ImageComponent";
import SectionDivide from "../../../UI/Divider/SectionDivide";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { fetchRoomDetails, fetchVenue } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  AddOnServiceType,
  FetchedSpacesType,
  FetchedVenueDetailsType,
  HouseRuleType,
  IconType,
  VenueDetailsDataType,
} from "../../../../types/property-manager";

// styles
import "../../../../assets/css/venue-page.css";
import "../../../../assets/css/header.css";

const VenuePage: React.FC<{
  venueId?: string;
  showHeader?: boolean;
  fetchedVenueData?: FetchedVenueDetailsType;
}> = ({ venueId, showHeader = true, fetchedVenueData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [venue, setVenue] = useState<any>();
  const [venueDetails, setVenueDetails] = useState<VenueDetailsDataType>({
    venueDetail: {
      venueType: "",
      sleeps: 0,
      bedRooms: 0,
      bathRooms: 0,
    },
    venueHighlights: [],
    aboutVenue: "",
    customParagraph: [],
    typesOfRetreats: [],
  });
  const [amenities, setAmenities] = useState<IconType[]>([]);
  const [addOnServices, setAddOnServices] = useState<AddOnServiceType[]>([]);
  const [houseRule, setHouseRule] = useState<HouseRuleType>({
    checkInOut: [],
    quiteHour: [],
  });

  const [venueSpaces, setVenueSpaces] = useState<FetchedSpacesType>({
    rooms: [],
    common_spaces: [],
  });
  const [images, setImages] = useState<
    {
      id: string;
      image_url: string;
    }[]
  >([]);

  const [venueLoading, setVenueLoading] = useState(true);
  const [venueOwnerData, setVenueOwnerData] = useState<any>({});

  const { call } = useApiCall();

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const fetchVenueDetails = async (venueId: string) => {
    setVenueLoading(true);
    call(
      () => fetchVenue(venueId),
      (res) => {
        const venueData = res.data?.data;

        setVenue(venueData);
        setVenueDetails({
          venueDetail: {
            venueType: venueData.venue_type?.name,
            sleeps: venueData.sleeps,
            bedRooms: venueData.number_of_rooms,
            bathRooms: venueData.number_of_bathrooms,
          },
          venueHighlights: venueData.highlights,
          aboutVenue: venueData.property_description,
          customParagraph: venueData.custom_paragraphs,
          typesOfRetreats: venueData.recommended_retreats,
        });
        setImages(res.data.data.venue_images ?? []);
        setAmenities(venueData.amenities.amenities);
        setHouseRule({
          checkInOut: [venueData.check_in_time, venueData.check_in_out],
          quiteHour: [venueData.quite_hours_start, venueData.quite_hours_end],
        });
        setAddOnServices(venueData.add_on_services);
        setVenueOwnerData({
          id: venueData.owner_id,
          name: venueData.owner_name,
          email: venueData.owner_email,
          profile_picture: venueData.profile_picture,
        });
        setVenueLoading(false);
      },
      (err) => {
        setVenueLoading(false);
      }
    );
  };

  const fetchVenueSpaces = async (venueId: string) => {
    call(
      () => fetchRoomDetails(venueId),
      (res) => {
        setVenueSpaces(res.data.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (fetchedVenueData?.venue_id) {
      setVenue(fetchedVenueData);
      setVenueDetails({
        venueDetail: {
          venueType: fetchedVenueData.venue_type?.name,
          sleeps: fetchedVenueData.sleeps,
          bedRooms: fetchedVenueData.number_of_rooms,
          bathRooms: fetchedVenueData.number_of_bathrooms,
        },
        venueHighlights: fetchedVenueData.highlights,
        aboutVenue: fetchedVenueData.property_description,
        customParagraph: fetchedVenueData.custom_paragraphs,
        typesOfRetreats: fetchedVenueData.recommended_retreats,
      });
      setImages(fetchedVenueData.venue_images ?? []);
      setAmenities(fetchedVenueData.amenities.amenities);
      setHouseRule({
        checkInOut: [
          fetchedVenueData.check_in_time,
          fetchedVenueData.check_in_out,
        ],
        quiteHour: [
          fetchedVenueData.quite_hours_start,
          fetchedVenueData.quite_hours_end,
        ],
      });
      setAddOnServices(fetchedVenueData.add_on_services);
      setVenueOwnerData({
        id: fetchedVenueData.owner_id,
        name: fetchedVenueData.owner_name,
        email: fetchedVenueData.owner_email,
        profile_picture: fetchedVenueData.profile_picture,
      });
      setVenueLoading(false);
    } else {
      fetchVenueDetails(venueId || (id as string));
    }
    fetchVenueSpaces(venueId || (id as string));
  }, [id, fetchedVenueData?.venue_id]);

  return venueLoading ? (
    <Loader />
  ) : !venue ? (
    <DetailsNotFound />
  ) : (
    <div
      className="position-relative venue-page-container"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      {showHeader && (
        <Header containerClassName="container">
          <SingleVenuePageHeader venueData={venue} />
        </Header>
      )}
      <div className="detail-section">
        <div className="container d-flex gap-4 flex-column mt-4 pb-5">
          {images.length > 0 && <ImageComponent images={images} />}
          <VenuePageTitle
            title={venue?.venue_title || ""}
            city={venue?.city || ""}
            country={venue?.country || ""}
          />
          <SectionDivide />
          <div className="d-flex flex-column gap-5">
            <AboutSection
              aboutTitle="About the venue"
              aboutDescription={venueDetails.aboutVenue}
              venueType={venueDetails.venueHighlights}
              sectionId="venue-details"
              isThereContent={Boolean(
                venueDetails?.venueHighlights?.length > 0 || 0
              )}
            />
            <AboutSection
              aboutTitle="Types of retreats we host"
              venueType={venueDetails.typesOfRetreats}
              onlyAmenities={true}
              isThereContent={Boolean(
                venueDetails?.typesOfRetreats?.length > 0 || 0
              )}
            />
            {venueDetails?.customParagraph?.map((item, index) => {
              return (
                <AboutSection
                  key={`about-section-${index}`}
                  aboutTitle={item.title}
                  aboutDescription={item.content}
                  isThereContent={Boolean(
                    venueDetails?.customParagraph?.length > 0 || 0
                  )}
                />
              );
            })}
          </div>
          <SectionDivide />
          <VenueSpaces spacesData={venueSpaces} />
          <AboutSection
            aboutTitle={"Amenities"}
            onlyAmenities={true}
            venueType={amenities}
            sectionId="amenities"
            isThereContent={Boolean(amenities?.length > 0 || 0)}
          />
          <AddOnServices
            openModal={openModal}
            addOnServicesData={addOnServices}
          />
          <LocationComponent postalCode={venue?.zip_code} />
          <AboutOwner ownerData={venueOwnerData} showMessageButton={false} />
          <InfoNeedToKnow openModal={openModal} houseRule={houseRule} />
        </div>
      </div>
    </div>
  );
};

export default VenuePage;
