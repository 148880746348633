import React from "react";

// Third Party
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//common component
import Button from "../../../UI/Button";

// Redux
import { setPropertyId } from "../../../../redux/features/retreatVenue";

// images
import { images } from "../../../../assets/images";

interface ownerDataProps {
  ownerData?: {
    id: string;
    name: string;
    email: string;
    profile_picture: string;
  };
  showMessageButton?: boolean;
}

const AboutOwner: React.FC<ownerDataProps> = ({
  ownerData,
  showMessageButton = true,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMessageRedirection = (email: string) => {
    if (email) {
      dispatch(setPropertyId(email));
      navigate("/admin/messages");
    }
  };

  return (
    <section className="about-owner-container">
      <div className="venue-details-title">About the owner</div>
      <div
        className="about-owner bg-white p-3  rounded-3 "
        style={{ border: "1px solid #E8E7EC", width: "max-content" }}
      >
        <div className="about-owner-card">
          <div className="about-owner-detail w-100">
            <img
              src={ownerData?.profile_picture || images.PERSON_AVATAR}
              alt=""
              height="40px"
            />
            <div className="w-75 overflow-hidden">
              <span className="owner-name text-center text-truncate w-100">
                {ownerData?.name}
              </span>
              <span className="owner-title">Property manager</span>
            </div>
          </div>
          {showMessageButton && ownerData?.email && (
            <div>
              <Button
                type="button"
                label="Send message to owner"
                className="back-btn"
                onClick={() => handleMessageRedirection(ownerData?.email || "")}
              />
            </div>
          )}
        </div>
        {/* TODO : Below informations are static, Need to update when Details available in API */}
        {/* <SectionDivide />
        <div className="owner-info">
          {/* TODO : Below informations are static, Need to update when Details available in API */}
        {/* <div className="owner-verification">
            <div>
              <span className="owner-name">{ownerData?.name} is verified</span>
            </div>
            <div className="verification-info">
              <img src={images.VERIFICATION_ICON} alt="" height="24px" />{" "}
              <span>
                {ownerData?.name} verified information such as identity, e-mail
                address and telephone number
              </span>
            </div>
          </div> */}

        {/* TODO : This is not required as details are not available in API */}
        {/* <div className="owner-about-more">
            <div>
              <span className="owner-name">About More</span>
            </div>
            <div className="owner-about-more-response">
              <img src={images.THUMBS_UP_ICON} alt="" height="24px" />
              <span>Response rate: 100%</span>
            </div>
            <div className="owner-about-more-response">
              <img src={images.CHAT_ICON} alt="" height="24px" />
              <span>Replies within 1 hour</span>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutOwner;
