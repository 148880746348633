import React, { useState } from "react";

// third party
import { Input, Select, Radio, Checkbox, Space } from "antd";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

// custom component
import FormLabel from "../../../UI/FormLabel";
import Button from "../../../UI/Button";
import SelectOption from "../../../UI/SelectOption";
import SmallText from "../../../UI/SmallText";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { createTax, deleteTax, updateTax } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  AddTaxFormType,
  AddTaxFormValues,
} from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const AddTaxForm: React.FC<AddTaxFormType> = ({
  setTaxForm,
  taxFormValues,
  prefilledValues,
  edit,
  fetchTax,
  venueId,
}) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm<AddTaxFormValues>({
    defaultValues: {
      accommodationTaxRegNumber:
        prefilledValues?.accommodationTaxRegistrationNumber || "",
      taxType: prefilledValues?.taxType || "",
      businessTaxId: prefilledValues?.businessTaxId || "",
      chargeType: prefilledValues?.typeOfCharge || "",
      exemptions: prefilledValues?.exemptionsForLongTermStays || false,
      termsAccepted: prefilledValues?.termsAgreed || false,
      taxAmount: prefilledValues?.taxAmount || 0,
    },
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { call } = useApiCall();
  const onFinish = (values: AddTaxFormValues) => {
    const dataToSend = {
      tax_type: values.taxType,
      type_of_charge: values.chargeType,
      tax_amount: values.taxAmount,
      tax_for: "Venue",
      business_tax_id: values.businessTaxId,
      accomodation_tax_registration_number: values.accommodationTaxRegNumber,
      exemptions_for_long_term_stays: values.exemptions,
      terms_agreed: values.termsAccepted,
    };

    if (!edit) {
      call(
        () => createTax({ venueId: venueId as string, data: dataToSend }),
        (res) => {
          reset();
          dispatch(setShowModal({ modalName: "addTaxModal", visible: false }));
          fetchTax && fetchTax();
          toast.success(res?.data?.message);
        },
        (err) => {
          toast.error(err?.response?.data?.message || "Failed to add tax");
        }
      ).finally(() => setSubmitting(false));
    } else {
      call(
        () =>
          updateTax({ taxId: prefilledValues?.id as string, data: dataToSend }),
        (res) => {
          reset();
          dispatch(
            setShowModal({
              modalName: `edit-tax-${prefilledValues?.id}`,
              visible: false,
            })
          );
          fetchTax!();
          toast.success(res?.data?.message);
        },
        (err) => {
          toast.error(err?.response?.data?.message || "Failed to update tax");
        }
      ).finally(() => setSubmitting(false));
    }

    setTaxForm?.([...taxFormValues, values]);
  };

  const handleDeleteDiscount = async () => {
    setSubmitting(true);
    call(
      () => deleteTax({ taxId: prefilledValues?.id as string }),
      (res) => {
        dispatch(
          setShowModal({
            modalName: `edit-tax-${prefilledValues?.id}`,
            visible: false,
          })
        );
        fetchTax && fetchTax();
        toast.success(res?.data?.message);
        setSubmitting(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to delete tax");
        setSubmitting(false);
      }
    );
  };

  return (
    <div className="room-modal-info-container add-taxes-form">
      <div className="room-modal-subtitle">
        This tax will be collected for any new reservations. You’re responsible
        for submitting, paying, and reporting the correct amount to the tax
        authorities.
      </div>
      <form
        className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="form-white-bg w-100">
          <div className="form-group">
            <FormLabel label="Tax Type" />
            <Controller
              name="taxType"
              control={control}
              rules={{
                required: "Please select the tax type.",
              }}
              render={({ field: { value, onChange } }) => (
                <SelectOption
                  value={value}
                  onChange={onChange}
                  options={[
                    { value: "Occupancy", label: "Occupancy" },
                    { value: "Tourist", label: "Tourist" },
                    { value: "Hotel", label: "Hotel" },
                    { value: "Lodging", label: "Lodging" },
                    { value: "GST", label: "GST" },
                    { value: "VAT", label: "VAT" },
                    { value: "Sales", label: "Sales" },
                  ]}
                />
              )}
            />
            {errors.taxType && (
              <span className="error">{errors.taxType.message}</span>
            )}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Type of charge" />
            </div>
            <Controller
              name="chargeType"
              control={control}
              rules={{
                required: "Please select the charge type.",
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    trigger("chargeType");
                  }}
                  options={[
                    { value: "Per_Booking", label: "Per booking" },
                    { value: "Per_Night", label: "Per night" },
                    { value: "Per_Guest", label: "Per guest" },
                    { value: "Per_Day", label: "Per day" },
                  ]}
                />
              )}
            />
          </div>
          <div className="form-group">
            <FormLabel label="Tax amount" />
            <Controller
              name="taxAmount"
              control={control}
              rules={{
                required: "Please provide the tax amount.",
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  type="number"
                  prefix="$"
                  className="mt-2 input-component"
                  min={0}
                  onChange={(value) => {
                    onChange(value);
                    trigger("taxAmount");
                  }}
                  value={value}
                />
              )}
            />
            {errors.taxAmount && (
              <span className="error">{errors.taxAmount?.message}</span>
            )}
          </div>

          <div className="form-group">
            <FormLabel
              label="Business Tax ID"
              subLabel="You can find this number on your tax registration document"
            />
            <Controller
              name="businessTaxId"
              rules={{
                required: "Please provide the business tax ID.",
                validate: (value) => {
                  if (value.trim() === "") {
                    return "Please provide the business tax ID.";
                  }
                },
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  type="text"
                  className="input-component"
                  min={0}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    trigger("businessTaxId");
                  }}
                />
              )}
            />
            {errors.businessTaxId && (
              <span className="error">{errors.businessTaxId?.message}</span>
            )}
          </div>

          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel
                label="Accommodation tax registration number"
                subLabel="You can find this number on your tax registration document"
              />
            </div>
            <Controller
              name="accommodationTaxRegNumber"
              rules={{
                required:
                  "Please provide the accommodation tax registration number.",
                validate: (value) => {
                  if (value?.trim() === "") {
                    return "Please provide the accommodation tax registration number.";
                  }
                },
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  type="text"
                  className="input-component"
                  min={0}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    trigger("accommodationTaxRegNumber");
                  }}
                />
              )}
            />
            {errors.accommodationTaxRegNumber && (
              <span className="error">
                {errors.accommodationTaxRegNumber.message}
              </span>
            )}
          </div>

          <div className="form-group accommodation-tax">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel
                label="Does your region provide exemptions for long-term stays?"
                subLabel="Tax may not apply to reservations longer than a certain number of days."
              />
            </div>
            <Controller
              name="exemptions"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical" className="w-100">
                    <Radio value={true} className="d-flex">
                      Yes
                    </Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
            {errors.exemptions && (
              <span className="error">{errors.exemptions.message}</span>
            )}
          </div>

          <div className="form-group accommodation-tax">
            <Controller
              name="termsAccepted"
              control={control}
              rules={{
                required: "You must accept the terms before proceeding.",
                validate: (value) =>
                  value === true || "You must accept the terms.",
              }}
              render={({ field: { value, onChange } }) => (
                <Space direction="vertical" className="w-100">
                  <Checkbox
                    checked={!!value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                    value={value}
                  >
                    <FormLabel label="Terms for adding taxes" />I confirm this
                    tax amount is correct, and I will pay the tax once remitted
                    to me by LAIGO. I grant LAIGO permission to disclose all
                    tax-related and transaction information (such as listing
                    address, tax amount, and tax ID) to the relevant tax
                    authorities.
                  </Checkbox>
                </Space>
              )}
            />
            {errors.termsAccepted && (
              <span className="error">{errors.termsAccepted.message}</span>
            )}
          </div>
        </div>

        <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-between">
          {edit && (
            <div>
              <button
                disabled={submitting}
                type="button"
                className="bordered-button border-0 bg-transparent p-0 d-flex align-items-center gap-2 "
                style={{ width: "max-content" }}
                onClick={handleDeleteDiscount}
              >
                <img src={images.TRASH_RED} alt="" height={16} />
                <SmallText text="Delete Tax" color="#F63030" />
              </button>
            </div>
          )}
          <div className="d-flex gap-3 w-100 align-items-center justify-content-end">
            <div>
              <Button
                type="button"
                label="Cancel"
                style={{
                  borderRadius: "4px",
                  border: "1px solid var(--deep-sea)",
                  backgroundColor: "var(--pure-white)",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "var(--deep-sea)",
                  fontWeight: "500",
                  padding: "12px 16px",
                  textDecoration: "none",
                }}
                onClick={() => {
                  !edit &&
                    reset({
                      businessTaxId: "",
                      accommodationTaxRegNumber: "",
                      exemptions: null,
                      termsAccepted: null,
                      taxAmount: 0,
                      taxType: "",
                      chargeType: "",
                    });
                  dispatch(
                    setShowModal({
                      modalName: !edit
                        ? "addTaxModal"
                        : `edit-tax-${prefilledValues?.id}`,
                      visible: false,
                    })
                  );
                }}
              />
            </div>
            <div>
              <Button
                type="submit"
                label={"Save"}
                onClick={() => onFinish}
                disabled={!isValid || submitting || !getValues().termsAccepted}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTaxForm;
