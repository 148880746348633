/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party
import {
  Form,
  Space,
  UploadProps,
  Upload,
  UploadFile,
  Image,
  FormProps,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Dragger from "antd/es/upload/Dragger";

// custom components
import FormLabel from "../../../../UI/FormLabel";
import InputComponent from "../../../../UI/InputComponent";
import SelectOption from "../../../../UI/SelectOption";
import TextareaComponent from "../../../../UI/TextareaComponent";
import Button from "../../../../UI/Button";
import SmallText from "../../../../UI/SmallText";

// redux
import { RootState } from "../../../../../redux/store";
import { setShowModal } from "../../../../../redux/features/component-slice";

// network
import {
  createRoom,
  fetchRoomAmenities,
  fetchBedTypes,
  uploadImage,
} from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// helpers
import { allowKeys, getBase64 } from "../../../../../library";

// types
import { FileType } from "../../../../../types";
import {
  RoomModalFormTypes,
  RoomModalType,
} from "../../../../../types/property-manager";

// constants
import { DEFAULT_NUMBER_OPTION } from "../../../../../constants/property-manager";

// styles
import { images } from "../../../../../assets/images";

const RoomInfoModal: React.FC<RoomModalType> = ({ venueId, fetchDetails }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [recommended_retreats, setRecommendedRetreats] = useState(0);
  const [amenities, setAmenities] = useState<string[]>([]);
  const [rooms_quantity, setRoomsQuantity] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [roomAmenities, setRoomAmenities] = useState<
    {
      id: string;
      name: string;
      icon_id: string;
    }[]
  >([]);
  const [beds, setBeds] = useState<{ bed_type: string; bed_id: string }[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const { call } = useApiCall();
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const imageUploadProps: UploadProps = {
    name: "images",
    multiple: true,
    fileList: fileList,
    listType: "picture-card",
    accept: "image/*",
    beforeUpload(file) {
      const isImage = file.type && file.type.startsWith("image/");
      if (!isImage) {
        toast.error("You can only upload image files.");
        return Upload.LIST_IGNORE;
      }

      setFileList((prevFileList) => [...prevFileList, file]);
      return false; // Prevents upload by default
    },

    onChange({ fileList: newFileList }) {
      const filteredFileList = newFileList.filter(
        (file) => file.type && file.type.startsWith("image/")
      );
      setFileList(filteredFileList);
      // Manually set images in the form
      form.setFieldsValue({
        images: filteredFileList.map((file) => file.originFileObj),
      });

      form
        .validateFields(["roomImages"])
        .then(() => {
          form.setFields([
            {
              name: "roomImages",
              errors: [],
            },
          ]);
        })
        .catch(() => {});
    },

    onRemove(file) {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      // Update form after file removal
      form.setFieldsValue({
        images: newFileList.map((file) => file.originFileObj),
      });
    },

    onPreview: async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <img src={images.UPLOAD_BUTTON_ICON} alt="add_photo_icon" />
    </button>
  );

  const handleFinalSubmit = async (
    values: RoomModalFormTypes,
    images: string[]
  ) => {
    values.images = images;
    values.amenities = amenities;
    values.rooms_quantity = rooms_quantity;

    const data = {
      name: values.name,
      rooms_quantity: values.rooms_quantity,
      room_description: values.room_description,
      beds: values.beds,
      images: images,
      amenities: values.amenities,
      space_type: "Room",
    };

    call(
      () =>
        createRoom({
          venue_id: venueId as string,
          data: data,
        }),
      (res) => {
        form.resetFields();
        setFileList([]);
        dispatch(
          setShowModal({
            modalName: "room-info",
            visible: false,
          })
        );
        toast.success(res.data?.message || "Room created successfully");
        setSubmitting(false);
        fetchDetails();
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to create room");
        setSubmitting(false);
      }
    );
  };

  const onFinish: FormProps<RoomModalFormTypes>["onFinish"] = async (
    values
  ) => {
    setSubmitting(true);
    const formData = new FormData();
    fileList.map(async (file) => {
      formData.append("files", file.originFileObj as any);
    });
    call(
      () => uploadImage(formData),
      (res) => {
        handleFinalSubmit(values, res.data.data);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to upload images");
      }
    );
  };

  const getAmenities = async () => {
    call(
      () => fetchRoomAmenities(),
      (res) => {
        setRoomAmenities(res.data.data);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch room amenities"
        );
      }
    );
  };

  const getBeds = async () => {
    call(
      () => fetchBedTypes(),
      (res) => {
        setBeds(res.data.data);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch bed types"
        );
      }
    );
  };

  const handleFormChange = () => {
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    const isTouched = form.isFieldsTouched(true);
    setIsFormValid(!hasErrors && isTouched); // Disable if errors or fields aren't touched
  };

  useEffect(() => {
    getAmenities();
    getBeds();
  }, []);

  useEffect(() => {
    if (
      fileList.length > 0 &&
      form.getFieldValue("name") &&
      form.getFieldValue("rooms_quantity") &&
      form.getFieldValue("room_description") &&
      form.getFieldValue("beds") &&
      form.getFieldValue("amenities")
    ) {
      setIsFormValid(true);
    }
    if (fileList.length === 0) {
      setIsFormValid(false);
    }
  }, [form.getFieldsValue()]);

  return (
    <div className="room-modal-info-container">
      <div className="room-modal-subtitle">
        Add and edit accommodation details 
      </div>
      <Form
        form={form}
        className="room-modal-form-container"
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
      >
        <div className="room-modal-form">
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <div className="d-flex flex-column gap-1">
                    <FormLabel label="Room Name" />
                    <InputComponent
                      type="text"
                      placeholder="Enter name"
                      width={"100%"}
                      onKeyDown={(e) => {
                        allowKeys(e);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
              <div style={{ minWidth: "112px" }}>
                <div className="d-flex flex-column gap-1">
                  <FormLabel label="Quantity" />
                  <Form.Item
                    name="rooms_quantity"
                    rules={[
                      {
                        // if rooms quantity is less null or 0 then show error
                        required: true,
                        message: "Quantity is required",
                      },
                    ]}
                  >
                    <SelectOption
                      onChange={(value) => {
                        setRoomsQuantity(value);
                      }}
                      options={DEFAULT_NUMBER_OPTION}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <Form.Item
                  name="room_description"
                  rules={[
                    { required: true, message: "Description is required" },
                  ]}
                >
                  <div className="d-flex flex-column gap-1">
                    <FormLabel label="Room Description" />
                    <TextareaComponent
                      placeholder="Enter description"
                      onKeyDown={(e) => {
                        allowKeys(e);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="typeOfBedContainer w-100">
              <div className="d-flex gap-2 w-100">
                <div className="flex-grow-1">
                  <FormLabel label="Bed Type" />
                </div>
                <div style={{ minWidth: "112px" }}>
                  <FormLabel label="Quantity" />
                </div>
                <div style={{ width: "44px" }}></div>
              </div>
              <Form.List
                name="beds"
                initialValue={[{ bed_type: "", quantity: null }]}
                rules={[
                  {
                    validator(value) {
                      if (value.len === 0) {
                        return Promise.reject(new Error("Missing bed type"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <div className="d-flex gap-2 w-100">
                          <div className="flex-grow-1">
                            <Form.Item
                              {...restField}
                              name={[name, "bed_type"]}
                              rules={[
                                { required: true, message: "Missing bed type" },
                              ]}
                            >
                              <SelectOption
                                placeholder="Select bed type"
                                options={beds?.map((item) => {
                                  return {
                                    value: item.bed_type,
                                    label: item.bed_type,
                                  };
                                })}
                              />
                            </Form.Item>
                          </div>
                          <div style={{ minWidth: "112px" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "quantity"]}
                              rules={[
                                { required: true, message: "Missing quantity" },
                              ]}
                            >
                              <SelectOption options={DEFAULT_NUMBER_OPTION} />
                            </Form.Item>
                          </div>
                          <button
                            type="button"
                            onClick={() => remove(name)}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            disabled={fields.length === 1}
                          >
                            <img src={images.BIN_ICON} alt="delete_icon" />
                          </button>
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <button
                        type="button"
                        onClick={() => add()}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <SmallText text="+ Add More" />
                      </button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100 create-venue-file-upload">
              <div className="flex-grow-1">
                <div className="d-flex flex-column gap-1 spaces-image-upload">
                  <FormLabel label="Upload Room Photos" />
                  <Form.Item
                    name="images"
                    rules={[
                      {
                        validator(_, value) {
                          if (fileList.length === 0) {
                            return Promise.reject(
                              "Please upload at least one photo."
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    {fileList.length === 0 && (
                      <Dragger {...imageUploadProps} defaultFileList={[]}>
                        <p className="ant-upload-text">
                          <img
                            src={images.UPLOAD_BLACK_ICON}
                            alt="upload icon"
                            height={24}
                          />
                          <br />
                          Upload a photo or{" "}
                          <SmallText text="click here" fontSize={16} />
                        </p>
                      </Dragger>
                    )}
                    {fileList.length > 0 && (
                      <Upload
                        {...imageUploadProps}
                        style={{ marginTop: "12px !important" }}
                      >
                        {fileList.length >= 24 ? null : uploadButton}
                      </Upload>
                    )}
                    {previewImage && (
                      <>
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      </>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <div className="d-flex flex-column gap-1">
                  <FormLabel label="Room Amenities" />
                  <div
                    className={`d-flex flex-column gap-1 recommended_retreats ${
                      recommended_retreats ? "selected" : ""
                    }`}
                  >
                    <Form.Item
                      name="amenities"
                      rules={[
                        { required: true, message: "Amenities are required" },
                      ]}
                    >
                      <SelectOption
                        showSearch={false}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Write or select"
                        onChange={(value) => {
                          setRecommendedRetreats(value.length);
                          setAmenities(value);
                        }}
                        options={roomAmenities?.map((item) => {
                          const icon = icons?.find(
                            (icon) => icon.id === item.icon_id
                          );

                          return {
                            value: item.id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    icon?.icon_url ||
                                    images.AIR_CONDITIONING_BLUE_ICON
                                  }
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "8px" }}>
                                  {item.name}
                                </span>
                              </div>
                            ),
                          };
                        })}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
            <div>
              <Button
                type="button"
                label="Cancel"
                className="bordered-button"
                onClick={() => {
                  dispatch(
                    setShowModal({ modalName: "room-info", visible: false })
                  );
                  form.resetFields();
                  setIsFormValid(false);
                  setFileList([]);
                }}
              />
            </div>
            <div>
              <Button
                type="submit"
                label={submitting === true ? "Saving..." : "Save"}
                onClick={() => onFinish}
                disabled={submitting || !isFormValid}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RoomInfoModal;
