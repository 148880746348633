import React from "react";
//third-party
import { useDispatch, useSelector } from "react-redux";
//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
//redux store
import { RootState } from "../../../../redux/store";
import { setShowModal } from "../../../../redux/features/component-slice";

//images
import { demo_images } from "../../../../assets/images/demo-images";
import { images as iconImage } from "../../../../assets/images";
const VenueImages: React.FC = () => {
  const dispatch = useDispatch();
  const { retreatPropertyDetail } = useSelector(
    (state: RootState) => state.retreatVenue
  );

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  return (
    <>
      {retreatPropertyDetail &&
      retreatPropertyDetail?.venue_images.length > 0 ? (
        <section className="venueDetail position-relative venue-gallery-container">
          <div
            className="w-100"
            style={{
              height: "506px",
              borderRadius: "16px",
              overflow: "hidden",
            }}
          >
            {retreatPropertyDetail?.venue_images.length === 1 && (
              <img
                src={retreatPropertyDetail?.venue_images[0]}
                alt="not found"
                className="img-fluid"
                style={{ height: "100%", width: "100%" }}
              />
            )}
            {retreatPropertyDetail?.venue_images.length === 2 && (
              <div className="d-flex gap-1 h-100">
                <div className="w-50 h-100">
                  <img
                    src={retreatPropertyDetail?.venue_images[0]}
                    alt="not found"
                    className="img-fluid"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div className="w-50 h-100">
                  <img
                    src={retreatPropertyDetail?.venue_images[1]}
                    alt="not found"
                    className="img-fluid"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
              </div>
            )}
            {retreatPropertyDetail?.venue_images.length === 3 && (
              <div className="d-flex gap-1 h-100">
                <div className="w-50 h-100">
                  <img
                    src={retreatPropertyDetail?.venue_images[0]}
                    alt="not found"
                    className="img-fluid"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div className="w-50 h-100">
                  <div className="d-flex flex-column gap-1 h-100">
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[1]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[2]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {retreatPropertyDetail?.venue_images.length === 4 && (
              <div className="d-flex gap-1 h-100">
                <div className="w-50 h-100">
                  <div className="d-flex flex-column gap-1 h-100">
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[0]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[1]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-50 h-100">
                  <div className="d-flex flex-column gap-1 h-100">
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[2]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[3]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {retreatPropertyDetail?.venue_images.length === 5 && (
              <div className="d-flex gap-1 h-100">
                <div className="w-50 h-100">
                  <img
                    src={retreatPropertyDetail?.venue_images[0]}
                    alt="not found"
                    className="img-fluid"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div className="w-25 h-100">
                  <div className="d-flex flex-column gap-1 h-100">
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[1]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[2]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-25 h-100">
                  <div className="d-flex flex-column gap-1 h-100">
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[3]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="h-50 w-100">
                      <img
                        src={retreatPropertyDetail?.venue_images[4]}
                        alt="not found"
                        className="img-fluid"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="gallery-button">
            <button
              style={{
                backgroundColor: "#FFFFFFCC",
                color: "#158098",
                borderRadius: "4px",
                padding: "12px 8px",
                border: "none",
              }}
              onClick={() => openModal("img-gallery")}
            >
              <div className="d-flex gap-1 align-items-center">
                <img
                  src={iconImage.IMG_GALLERY}
                  className="img-fluid"
                  alt=""
                  style={{ height: "11px", width: "13px" }}
                />
                <SmallText
                  text="See all photos"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
              </div>
            </button>
          </div>
          <ModalComponent modalName="img-gallery" title="Gallery">
            <div className="row gy-2">
              <div className="col-12">
                <img
                  src={demo_images.DEMO_IMAGE_1}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-6">
                <img
                  src={demo_images.DEMO_IMAGE_2}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-6">
                <img
                  src={demo_images.DEMO_IMAGE_3}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-12">
                <img
                  src={demo_images.DEMO_IMAGE_4}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-6">
                <img
                  src={demo_images.DEMO_IMAGE_5}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="d-flex"></div>
            <div className="d-flex"></div>
          </ModalComponent>
        </section>
      ) : (
        <div className="mt-3"></div>
      )}
    </>
  );
};

export default VenueImages;
