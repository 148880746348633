/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third Party
import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";
import PayoutListingTable from "./PayoutListingTable";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { adminPayoutsList } from "../../../../network";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Styles
import "../../../../assets/css/admin-payout.css";

// Utility
import { convertToTitleCase } from "../../../../library";

const PayoutListing: React.FC = () => {
  // Set initial Table Limit Value
  const initialLimitValue = 10;

  const { call } = useApiCall();

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const [payouts, setPayouts] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );

  const [loading, setLoading] = useState(false);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    return;
  };

  // APIs
  const getPayoutData = async (date: string) => {
    call(
      () => adminPayoutsList({ queryParamsObj: { date: date } }),
      (res) => {
        setPayouts(res.data.data);
        setCurrentPage(1);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    getPayoutData(selectedDate);
  }, [selectedDate]);

  return (
    <AdminDashboardLayout
      pageTitle={`Welcome,  ${convertToTitleCase(
        userProfileData.first_name
      )} 👋`}
      additionalClassNames="mt-4"
    >
      <div className="payouts user-home-table admin-users bg-white">
        <div className="d-flex gap-3 justify-content-between">
          <div className="common-xl-medium-dark-text">Payouts</div>
          <div className="d-flex gap-2 justify-content-end ">
            <div className="d-flex flex-column date-picker-width-300">
              <span>Date</span>
              <Form.Item name={"filterDate"}>
                <DatePicker
                  placeholder="Select or Write"
                  disabledDate={(day) => day.isAfter(dayjs())}
                  onChange={(date, dateString) => {
                    setSelectedDate(dateString.toString() || "");
                  }}
                  defaultValue={dayjs()}
                  allowClear={false}
                  disabled={loading}
                  value={selectedDate ? dayjs(selectedDate) : dayjs()}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <PayoutListingTable
          payoutData={payouts}
          totalItems={payouts.length || 0}
          currentPage={currentPage}
          pageSize={initialLimitValue || 0}
          onPageChange={(page, pageSize) => handlePageChange(page, pageSize)}
          loading={loading}
        />
      </div>
    </AdminDashboardLayout>
  );
};

export default PayoutListing;
