/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// third party
import { Collapse, CollapseProps, Input, Switch } from "antd";

// custom component
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import FormLabel from "../../../UI/FormLabel";
import Button from "../../../UI/Button";
import ModalComponent from "../../../UI/ModalComponent";
import UpcomingReservationDetailModal from "../Home/UpcomingReservationDetailModal";
import ReservationDetails from "./ReservationDetails";
import SmallText from "../../../UI/SmallText";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { getReservationRequestDetails } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import { EditVenueSidebarProps } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const IndividualDateAvailabilitySidebar: React.FC<EditVenueSidebarProps> = ({
  date,
  event,
  onSave,
  defaultRate,
  setIsSidebarOpen,
  createVenue = false,
  requests,
  reserved,
  basePrice,
  setMonthAvailability,
}) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const [availability, setAvailability] = useState<boolean>(true);
  const [price, setPrice] = useState<number>(defaultRate || 0);
  const [showInput, setShowInput] = useState<boolean>(true);
  const [reservationDetails, setReservationDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <span className="common-sm-dark-text">
          {requests?.length || 0} reservation requests
        </span>
      ),
      children: (
        <div className="d-flex flex-column gap-3">
          {requests?.map((request, index) => (
            <div className="d-flex flex-column gap-3" key={`requests${index}`}>
              <div className="reservation-host-name d-flex align-items-center gap-1">
                <img src={images.PERSON_AVATAR} alt="" height={"40px"} />
                <div className="name">
                  <p className="common-sm-dark-text tex-overflow-handle">
                    Jane Doe
                  </p>
                  <span className="common-xs-light-text">
                    From {dayjs(request?.check_in_date).format("MMM, DD, YYYY")}{" "}
                    to {dayjs(request?.check_out_date).format("MMM, DD, YYYY")}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <div>
                  <div className="common-sm-medium-light-text mb-1">
                    Group size:
                  </div>
                  <div className="common-sm-dark-text">
                    {request?.group_size}
                  </div>
                </div>
                {/* TODO: Add on services */}
                {/* {request?.add_on_services && (
                  <div>
                    <div className="common-sm-medium-light-text mb-1">
                      Add on services:
                    </div>
                    <div className="common-sm-dark-text">
                      {Object.keys(request?.add_on_services).map(
                        (key) =>
                          request?.add_on_services[key].name +
                          `${
                            // it is last key then don't add comma
                            Object.keys(request?.add_on_services).length - 1 ===
                            Object.keys(request?.add_on_services).indexOf(key)
                              ? ""
                              : ", "
                          }`
                      )}
                    </div>
                  </div>
                )} */}
              </div>
              <div className="common-lg-medium-dark-text">
                $ {request?.total_price}
              </div>
              <div className="d-flex gap-2">
                <Button
                  label="See Details"
                  className="bordered-button"
                  style={{ width: "166px" }}
                  onClick={() =>
                    dispatch(
                      setShowModal({
                        modalName: "reservation-details",
                        visible: true,
                      })
                    )
                  }
                />
                <Button
                  label="Approve"
                  style={{ width: "166px" }}
                  onClick={() => {
                    fetchUpcomingReservationDetails(request?.id).then(() => {
                      dispatch(
                        setShowModal({
                          modalName: "reservation-details",
                          visible: true,
                        })
                      );
                    });
                  }}
                />
                <ModalComponent modalName="reservation-details">
                  {
                    <UpcomingReservationDetailModal
                      reservationDetails={reservationDetails}
                    />
                  }
                </ModalComponent>
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const handleSave = (newPrice: number, availability: string) => {
    const newPriceValue =
      newPrice === 0 ? defaultRate || basePrice || 0 : newPrice;
    setPrice(newPrice);
    onSave(date, availability, newPriceValue);
  };

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });

  const fetchUpcomingReservationDetails = async (reservationId: string) => {
    setLoading(true);
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        setReservationDetails(res?.data?.data);
        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservation details"
        );
        setLoading(false);
      }
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (event?.type === "closed") {
      setAvailability(false);
    } else {
      setAvailability(true);
    }
    setPrice(event?.price || defaultRate || basePrice || 0);
  }, [event]);

  useEffect(() => {
    reserved &&
      reserved.length > 0 &&
      fetchUpcomingReservationDetails(reserved[0]?.id);
    if (reserved && reserved.length > 0) {
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  }, [reserved && reserved[0]?.id]);

  return (
    <div
      className="default-availability-container"
      style={{ maxWidth: createVenue ? "322px" : "400px" }}
    >
      <div>
        <h3>{`${formattedDate}`}</h3>
        <FormLabel
          label={`${date.toLocaleDateString("en-US", { weekday: "long" })}`}
        />
      </div>
      {showInput && (
        <div className="d-flex flex-column" style={{ gap: "24px" }}>
          <div className="d-flex justify-content-between">
            <FormLabel label="Accepting Reservations" />
            <Switch
              checked={availability}
              onChange={(checked) => {
                setAvailability(checked);
                handleSave(price, checked ? "open" : "closed");
                checked &&
                  setMonthAvailability &&
                  setMonthAvailability((prevStatus) => ({
                    ...prevStatus,
                    [dayjs(date.toDateString()).format("YYYY-MM")]: "open",
                  }));
              }}
            />
          </div>
          {availability && (
            <label>
              <SmallText text="Default Nightly Rate" color="#58585B" />
              <Input
                type="number"
                prefix="$"
                value={price}
                onChange={(e) => {
                  const inputValue = parseInt(e.target.value);
                  if (isNaN(inputValue)) {
                    e.target.value = "0";
                  }
                  setPrice(parseFloat(e.target.value));
                  handleSave(
                    isNaN(inputValue) ? 0 : inputValue,
                    availability ? "open" : "closed"
                  );
                }}
                className="mt-2"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </label>
          )}
        </div>
      )}

      {requests && requests?.length > 0 && (
        <div>
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            accordion={false}
            expandIcon={({ isActive }) =>
              isActive ? (
                <img src={images.DOWN_ARROW_ICON} alt="" height={16} />
              ) : (
                <img src={images.UP_ARROW_ICON} alt="" height={16} />
              )
            }
            className="edit-individual-availability-collapse"
          />
        </div>
      )}

      {reserved && reserved?.length > 0 && (
        <ReservationDetails
          reservationDetails={reservationDetails}
          loading={loading}
        />
      )}
    </div>
  );
};

export default IndividualDateAvailabilitySidebar;
