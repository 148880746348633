/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { InputNumber, Switch } from "antd";
import { toast } from "react-toastify";

// custom component
import FormLabel from "../../../../UI/FormLabel";
import InputComponent from "../../../../UI/InputComponent";
import SelectOption from "../../../../UI/SelectOption";
import TextareaComponent from "../../../../UI/TextareaComponent";
import Button from "../../../../UI/Button";
import ClickableTags from "../../../../UI/ClickableTags";
import ModalComponent from "../../../../UI/ModalComponent";

//  redux
import { RootState } from "../../../../../redux/store";
import { setShowModal } from "../../../../../redux/features/component-slice";

// network
import { deleteAddOnService, updateAddOnService } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import {
  TagListType,
  RoomModalType,
  AddOnServicesFormTypes,
} from "../../../../../types/property-manager";

// Constants
import {
  DEFAULT_NUMBER_OPTION,
  PRICE_LABELS,
} from "../../../../../constants/property-manager";

// images
import { images } from "../../../../../assets/images";

const EditAddOnServiceModal: React.FC<RoomModalType> = ({
  venueId,
  fetchDetails,
  addOnServices,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [ticketsEnable, setTicketsEnable] = useState(
    addOnServices?.tickets && addOnServices?.tickets > 1 ? true : false
  );
  const [priceEnable, setPriceEnable] = useState(
    addOnServices?.charges && addOnServices?.charges > 0 ? true : false
  );
  const [deleting, setDeleting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<AddOnServicesFormTypes>({
    defaultValues: {
      description: addOnServices?.description || "",
      externalUrl: addOnServices?.external_link || "",
      price: addOnServices?.charges || null,
      priceLabel: addOnServices?.service_type || "Free",
      tickets: addOnServices?.tickets || 1,
      name: addOnServices?.name || "",
      icons: addOnServices?.icon_id || "",
      priceEnable: addOnServices?.charges ? true : false,
    },
    mode: "onChange", // Make validation trigger on field change
  });

  const { call } = useApiCall();
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const allIcons: TagListType[] = [];

  icons.forEach((icon) => {
    allIcons.push({
      id: icon.id,
      name: "",
      iconImage: icon.icon_url,
    });
  });

  const onFinish = async (data: AddOnServicesFormTypes) => {
    setSubmitting(true);

    if (!ticketsEnable) {
      data.tickets = null;
    }

    if (!priceEnable) {
      data.price = 0;
      data.priceLabel = "Free";
    }
    const dataToSend = {
      icon_id: data.icons === "" ? undefined : data.icons,
      name: data.name,
      description: data.description,
      external_link: data.externalUrl,
      charges: data.price,
      service_type: data.priceLabel,
      type: "Optional",
      tickets: data.tickets,
    };

    call(
      () =>
        updateAddOnService({
          id: addOnServices?.id as string,
          data: dataToSend,
        }),
      (res) => {
        dispatch(
          setShowModal({
            modalName: `edit-add-on-services-${addOnServices?.id}`,
            visible: false,
          })
        );
        toast.success(
          res.data?.message || "Add-on services updated successfully"
        );
        fetchDetails();

        setSubmitting(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to create add-on services"
        );
        setSubmitting(false);
      }
    );
  };

  const removeAddOnService = async (id: string) => {
    setDeleting(true);
    call(
      () => deleteAddOnService({ id }),
      (res) => {
        dispatch(
          setShowModal({
            modalName: `delete-service-${addOnServices?.id}`,
            visible: false,
          })
        );
        fetchDetails();
        toast.success("Service delete successfully");
        setDeleting(false);
      },
      (err) => {
        toast.error("Failed to delete");
        setDeleting(false);
      }
    );
  };

  useEffect(() => {
    if (!priceEnable) {
      setValue("price", null);
      setValue("priceLabel", "");
      clearErrors(["price", "priceLabel"]);
    }
  }, [priceEnable, setValue, clearErrors]);

  return (
    <div className="room-modal-info-container edit-space-info">
      <div className="room-modal-subtitle">
        Create your add on and customize it as you wish
      </div>
      <form
        className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="form-white-bg w-100">
          <div className="form-group">
            <FormLabel label="Add-On Service Name" />
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Please provide the venue name.",
                validate: (value) => {
                  if (value?.trim() === "") {
                    return "Please provide the venue name.";
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  type="text"
                  placeholder="Write the name here"
                  style={{ width: "100%" }}
                  value={value || ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                    trigger("name");
                  }}
                />
              )}
            />
            {errors.name && (
              <span className="error">{errors.name.message}</span>
            )}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Number of Tickets Available" />
              <Switch
                checked={ticketsEnable}
                onChange={() => {
                  setTicketsEnable(!ticketsEnable);
                  setValue("tickets", null);
                }}
              />
            </div>
            <Controller
              name="tickets"
              control={control}
              rules={{
                required: !ticketsEnable ? "Please provide tickets." : false,
              }}
              disabled={!ticketsEnable}
              render={({ field: { value, onChange } }) => (
                <SelectOption
                  disabled={!ticketsEnable}
                  options={DEFAULT_NUMBER_OPTION}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger("tickets");
                  }}
                />
              )}
            />
          </div>
          <div className="form-group">
            <FormLabel label="Add-On Service Description" />
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Please provide a description.",
              }}
              render={({ field: { value, onChange } }) => (
                <TextareaComponent
                  placeholder="enter your text here"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    trigger("description");
                  }}
                />
              )}
            />
            {errors.description && (
              <span className="error">{errors.description?.message}</span>
            )}
          </div>

          <div className="form-group">
            <FormLabel label="External URL (Optional)" />
            <Controller
              name="externalUrl"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  placeholder="enter URL here"
                  style={{ width: "100%" }}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
          </div>

          <div className="form-group add-on-service-price">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Price" />
              <Controller
                name="priceEnable"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    value={value}
                    checked={priceEnable}
                    onChange={(value) => {
                      onChange(value);
                      setPriceEnable(!priceEnable);
                    }}
                  />
                )}
              />
            </div>
            <Controller
              name="price"
              control={control}
              rules={{
                required: priceEnable ? "Please provide a price." : false,
                pattern: {
                  value: /^[0-9]+$/, // Only numbers allowed
                  message: "Price should contain only numbers.",
                },
              }}
              disabled={!priceEnable}
              render={({ field: { value, onChange } }) => (
                <InputNumber
                  changeOnBlur={false}
                  changeOnWheel={false}
                  controls={false}
                  disabled={!priceEnable}
                  placeholder="0.00"
                  prefix="$"
                  value={value as number}
                  onChange={(e) => {
                    const inputValue = e; // Extract the value
                    onChange(inputValue);
                    trigger("price");
                  }}
                  onKeyPress={(e) => {
                    // Prevent non-numeric characters
                    if (!/^[0-9]$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  style={{ width: "100%", height: "42px" }}
                />
              )}
            />
            {errors.price && (
              <span className="error">{errors.price.message}</span>
            )}
            {priceEnable && (
              <>
                <Controller
                  name="priceLabel"
                  control={control}
                  rules={{ required: "Please select a type of venue." }}
                  render={({ field: { value, onChange } }) => (
                    <ClickableTags
                      selectionMode="single"
                      tagList={PRICE_LABELS}
                      property_id={value}
                      onChange={(value) => {
                        onChange(value);
                        trigger("priceLabel");
                      }}
                    />
                  )}
                />
                {errors.priceLabel && (
                  <span className="error">{errors.priceLabel.message}</span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="form-white-bg w-100">
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Icons (optional)" />
            </div>
            <Controller
              name="icons"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ClickableTags
                  selectionMode="single"
                  tagList={allIcons}
                  property_id={value}
                  onChange={(value) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-between">
          <div>
            <Button
              type="button"
              label="Cancel"
              style={{
                borderRadius: "4px",
                border: "1px solid var(--deep-sea)",
                backgroundColor: "var(--pure-white)",
                fontSize: "14px",
                lineHeight: "16.45px",
                color: "var(--deep-sea)",
                fontWeight: "500",
                padding: "12px 16px",
                textDecoration: "none",
              }}
              onClick={() => {
                dispatch(
                  setShowModal({
                    modalName: `edit-add-on-services-${addOnServices?.id}`,
                    visible: false,
                  })
                );
              }}
            />
          </div>
          <div className="d-flex gap-2">
            <Button
              type="button"
              label="Delete"
              style={{
                border: "1px solid red",
                color: "red",
              }}
              className="bordered-button"
              onClick={() => {
                dispatch(
                  setShowModal({
                    modalName: `delete-service-${addOnServices?.id}`,
                    visible: true,
                  })
                );
              }}
            />

            <ModalComponent
              modalName={`delete-service-${addOnServices?.id}`}
              title=""
              className="modal-space-info"
            >
              <div className="delete-modal d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                  <img
                    src={images.DELETE_IMG}
                    alt="Delete Icon"
                    height={"96px"}
                  />
                  <h2 className="common-xl-bold-dark-text">
                    Delete Add on Service
                  </h2>
                  <p className="common-sm-light-grey-text">
                    Are you sure you want to delete this add on service?
                  </p>
                  <p
                    className="common-sm-bold-dark-text"
                    style={{
                      color: "#F63030",
                    }}
                  >
                    This action is irreversible.
                  </p>
                </div>
                <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
                  <Button
                    type="button"
                    label="Cancel"
                    className="bordered-button"
                    onClick={() => {
                      dispatch(
                        setShowModal({
                          modalName: `delete-service-${addOnServices?.id}`,
                          visible: false,
                        })
                      );
                    }}
                  />

                  <Button
                    type="submit"
                    label={
                      deleting === true ? "Deleting space..." : "Delete space"
                    }
                    onClick={() => {
                      removeAddOnService(addOnServices?.id as string);
                    }}
                    disabled={submitting === true}
                    className={"btn-delete"}
                  />
                </div>
              </div>
            </ModalComponent>

            <div>
              <Button
                type="submit"
                label={submitting === true ? "Saving..." : "Save"}
                onClick={handleSubmit(onFinish)}
                disabled={submitting || !isValid}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAddOnServiceModal;
