import React from "react";

// third party
import { ButtonGroupProps, CarouselInternalState } from "react-multi-carousel";

// images
import { images } from "../../../assets/images";

interface ButtonGroupCustomProps extends ButtonGroupProps {
  carouselState: CarouselInternalState;
  next: () => void;
  previous: () => void;
  className?: string;
}

const ButtonGroup: React.FC<ButtonGroupCustomProps> = ({
  next,
  previous,
  carouselState,
  className,
}) => {
  const { totalItems, currentSlide, slidesToShow } = carouselState;

  const isLastSlide = currentSlide + slidesToShow >= totalItems;

  return (
    <div className={` ${className} carousel-button-group `}>
      <button
        type="button"
        disabled={currentSlide === 0}
        className={`${currentSlide === 0 ? "disable" : ""} prev-button`}
        onClick={previous}
      >
        <img src={images.LEFT_ARROW_ICON} alt="" height={"16px"} />
      </button>
      <button
        type="button"
        disabled={isLastSlide}
        className={`${isLastSlide ? "disable" : ""} next-button`}
        onClick={next}
      >
        <img src={images.RIGHT_ARROW_ICON} alt="" height={"16px"} />
      </button>
    </div>
  );
};

export default ButtonGroup;
