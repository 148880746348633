import React from "react";
//third party
import { Select, SelectProps } from "antd";
// style
import "./style.css";

const SelectOption: React.FC<SelectProps<any>> = ({
  value,
  defaultValue,
  onChange,
  options,
  placeholder,
  ...props
}) => {
  return (
    <Select
      defaultValue={defaultValue}
      style={{ width: "100%", height: "42px" }}
      onChange={onChange}
      options={options}
      value={value}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default SelectOption;
