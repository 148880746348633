import React from "react";
// third party
import { ChevronRight } from "lucide-react";
import { useDispatch } from "react-redux";

//redux-store
import { setShowModal } from "../../../../redux/features/component-slice";

// common components
import ModalComponent from "../../../UI/ModalComponent";
import MapComponent from "../../../UI/MapComponent/MapComponent";
import SmallText from "../../../UI/SmallText";
import NoDataFound from "../../../UI/NoDataFound";

// constants
import { NEARBY_LOCATION } from "../../../../constants/property-manager";

interface LocationComponentProps {
  postalCode?: string;
}

const LocationComponent: React.FC<LocationComponentProps> = ({
  postalCode = "",
}) => {
  const dispatch = useDispatch();

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  return postalCode ? (
    <>
      <div className="d-flex flex-column  gap-3" id="location">
        <div className="venue-details-title">Location</div>
        <div
          className="d-flex flex-column gap-3 p-3 bg-white  rounded-3"
          style={{ border: "1px solid #E8E7EC" }}
        >
          <div className="location-map">
            <MapComponent
              apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
              postalCodeName={postalCode}
            />
          </div>

          {/* TODO : Display is hidden for below content as location nearby data is not provided by API */}
          <div className="d-none">
            <div className="location-info d-none">
              <div className="location-desc d-none">
                Interlaken, situated between the beautiful lakes Thun and
                Brienz, is a charming destination in the Swiss Alps. With a
                wealth of cultural and shopping activities on offer, this town
                is perfect for both adventurers and those seeking relaxation in
                the midst of nature.
              </div>
              <div className="nearby-location-container">
                <div className="title">Nearby:</div>
                {NEARBY_LOCATION.map((location, index) => (
                  <div className="location" key={`location-${index}`}>
                    <div className="nearby-location-name">
                      <img src={location.icon} alt="" height={"16px"} />{" "}
                      <span>{location.title}</span>
                    </div>
                    <div className="location-desc">{location.tagLine}</div>
                    <div className="location-desc">{location.time}</div>
                  </div>
                ))}
              </div>
              <div id="location-overly"></div>
            </div>
            <div className="venueIcon">
              <button
                className="modal-open-button"
                style={{
                  border: "none",
                  background: "transparent",
                  padding: 0,
                }}
                type="button"
                onClick={() => openModal("locationModal")}
              >
                <SmallText text="Read more" />
                <ChevronRight color="#2771A3" width={16} height={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent modalName="locationModal" title="Location Detail">
        <div className="location-info">
          <div className="location-desc">
            {/* TODO : Below content is static as location nearby data is not provided by API */}
            Interlaken, situated between the beautiful lakes Thun and Brienz, is
            a charming destination in the Swiss Alps. With a wealth of cultural
            and shopping activities on offer, this town is perfect for both
            adventurers and those seeking relaxation in the midst of nature.
          </div>
          <div className="nearby-location-container">
            <div className="title">Nearby:</div>
            {NEARBY_LOCATION.map((location, index) => (
              <div className="location" key={`location-${index}`}>
                <div className="nearby-location-name">
                  <img src={location.icon} alt="" height={"16px"} />{" "}
                  <span>{location.title}</span>
                </div>
                <div className="location-desc">{location.tagLine}</div>
                <div className="location-desc">{location.time}</div>
              </div>
            ))}
          </div>
        </div>
      </ModalComponent>
    </>
  ) : (
    <>
      <section className="about-owner-container">
        <div className="venue-details-title">Location</div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column bg-white venue-selection">
            <NoDataFound
              boxStyle={{ height: 120 }}
              iconSize={32}
              text="No Data"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default LocationComponent;
