/* eslint-disable react/jsx-no-target-blank */
import React from "react";

// Third party
import { File } from "lucide-react";

// Component
import SmallText from "../../../../UI/SmallText";

// Types
import {
  DocumentDisplayBoxProps,
  DocumentDisplayProps,
} from "../../../../../types/admin";

// Styles
import "../../../../../assets/css/admin-venues.css";
import NoDataFound from "../../../../UI/NoDataFound";

const DocumentDisplayBox = ({ document }: DocumentDisplayBoxProps) => {
  return (
    <div className="d-flex UploadCard bg-white py-4 px-3 flex-column">
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="d-flex align-items-center justify-content-between">
          <SmallText
            text={document.document_type}
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
        </div>
        <a
          href={document.document_url}
          target="_blank"
          className="remove-a-tag-underline"
        >
          <div className="file-upload d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <div className="file-icon d-flex align-items-center justify-content-center">
                <File width={16.5} height={16.5} color="#2771A3" />
              </div>
              <div className="d-flex flex-column ">
                <SmallText
                  text={document.document_name}
                  color="#000000"
                  fontSize={16}
                  fontWeight={400}
                  lineHeight={18.8}
                />
                <SmallText
                  text={`Uploaded on ${document.uploaded_on}`}
                  color="#787878"
                  fontSize={14}
                  fontWeight={400}
                  lineHeight={16.45}
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

const DocumentDisplay = ({ documentList }: DocumentDisplayProps) => {
  return (
    <>
      {Array.isArray(documentList) && documentList.length > 0 ? (
        <section className="about-owner-container">
          <div className="venue-details-title">Venue Documents</div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column bg-white venue-selection">
              <div className="d-flex w-100 flex-wrap" style={{ gap: "20px" }}>
                {documentList.map((document) => (
                  <DocumentDisplayBox
                    key={document.reference_id}
                    document={document}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className="about-owner-container">
            <div className="venue-details-title">User Documents</div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column bg-white venue-selection">
                <NoDataFound
                  boxStyle={{ height: 120 }}
                  iconSize={32}
                  text="No documents"
                />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default DocumentDisplay;
