/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third Party
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MoveDown, MoveUp } from "lucide-react";

// Components
import StatisticCard from "../../../UI/StatisticCard";
import AdminDashboardLayout from "../AdminDashboardLayout";

// Constants
import { USER_TYPE_ROLE } from "../../../../constants/Admin";
import Loader from "../../../UI/Loader";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { adminDashboardStatistics } from "../../../../network";

// Utils
import {
  convertToTitleCase,
  formatNumberWithCommas,
} from "../../../../library";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Styles
import "../../../../assets/css/admin-dashboard.css";

interface cardDataTypes {
  count: number;
  change_percentage: number;
}

interface dashboardDataTypes {
  users: cardDataTypes;
  venue_manager: cardDataTypes;
  retreat_host: cardDataTypes;
  retreat_guest: cardDataTypes;
  venues: cardDataTypes;
  reservations: cardDataTypes;
  retreats: cardDataTypes;
  bookings: cardDataTypes;
  pending_approvals: cardDataTypes;
  pending_deactivations: cardDataTypes;
}

const Dashboard: React.FC = () => {
  const { call } = useApiCall();
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const [dashboardData, setDashboardData] = useState<dashboardDataTypes>({
    users: {
      count: 0,
      change_percentage: 0,
    },
    venue_manager: {
      count: 0,
      change_percentage: 0,
    },
    retreat_host: {
      count: 0,
      change_percentage: 0,
    },
    retreat_guest: {
      count: 0,
      change_percentage: 0,
    },
    venues: {
      count: 0,
      change_percentage: 0,
    },
    reservations: {
      count: 0,
      change_percentage: 0,
    },
    retreats: {
      count: 0,
      change_percentage: 0,
    },
    bookings: {
      count: 0,
      change_percentage: 0,
    },
    pending_approvals: {
      count: 0,
      change_percentage: 0,
    },
    pending_deactivations: {
      count: 0,
      change_percentage: 0,
    },
  });
  const [loading, setLoading] = useState<boolean>(true);

  const UserCardDetails = [
    {
      url: "/admin/users",
      title: "Users",
      count: dashboardData.users?.count.toString(),
      changePercentage: dashboardData.users?.change_percentage,
    },
    {
      url: `/admin/users?type=${USER_TYPE_ROLE.VENUE_MANAGER.key}`,
      title: "Managers",
      count: dashboardData.venue_manager?.count.toString(),
      changePercentage: dashboardData.venue_manager?.change_percentage,
    },
    {
      url: `/admin/users?type=${USER_TYPE_ROLE.RETREAT_HOST.key}`,
      title: "Hosts",
      count: dashboardData.retreat_host?.count.toString(),
      changePercentage: dashboardData.retreat_host?.change_percentage,
    },
    {
      url: `/admin/users?type=${USER_TYPE_ROLE.RETREAT_GUEST.key}`,
      title: "Guests",
      count: dashboardData.retreat_guest?.count.toString(),
      changePercentage: dashboardData.retreat_guest?.change_percentage,
    },
  ];

  const OtherCardDetails = [
    {
      url: "/admin/venues",
      title: "Venues",
      count: dashboardData.venues?.count.toString(),
      changePercentage: dashboardData.venues?.change_percentage,
    },
    {
      url: "/admin/reservations",
      title: "Reservations",
      count: dashboardData.reservations?.count.toString(),
      changePercentage: dashboardData.reservations?.change_percentage,
    },
    {
      url: "/admin/retreats",
      title: "Retreats",
      count: dashboardData.retreats?.count.toString(),
      changePercentage: dashboardData.retreats?.change_percentage,
    },
    {
      url: "/admin/bookings",
      title: "Bookings",
      count: dashboardData.bookings?.count.toString(),
      changePercentage: dashboardData.bookings?.change_percentage,
    },
  ];

  // Card Details for Approval
  const ApprovalCardDetails = [
    {
      url: "/admin/venues?tab=pending_approval",
      title: "Venue Listing Approval",
      count: dashboardData.pending_approvals?.count.toString(),
      changePercentage: dashboardData.pending_approvals?.change_percentage,
    },
    {
      url: "/admin/venues?tab=pending_deactivation",
      title: "Venue Deactivation",
      count: dashboardData.pending_deactivations?.count.toString(),
      changePercentage: dashboardData.pending_deactivations?.change_percentage,
    },
  ];

  // APIs
  const fetchCountStatistics = async () => {
    call(
      () => adminDashboardStatistics(),
      (res) => {
        setDashboardData(res.data?.data);
        setLoading(false);
      },
      (err) => {
        toast.error("Failed to retrieve dashboard data.");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchCountStatistics();
  }, []);

  return (
    <>
      <AdminDashboardLayout
        pageTitle={`Welcome,  ${convertToTitleCase(
          userProfileData.first_name
        )} 👋`}
        additionalClassNames="mt-4"
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="admin-daashboard">
            <div>
              {/* Section Title */}
              <div>
                <div className="section-title">Users</div>
                <hr className="mb-4 horizontal-line"></hr>
              </div>
              {/* Cards */}
              <div>
                <Row gutter={32} justify={"start"} className="gap-3">
                  {UserCardDetails.map((card) => {
                    return (
                      <Col
                        key={card.title}
                        span={5}
                        style={{ minWidth: "300px" }}
                      >
                        <Link to={card.url} className="text-decoration-none">
                          <StatisticCard
                            title={card.title}
                            totalCount={formatNumberWithCommas(card.count)}
                            percentageChange={
                              <span className="d-flex align-items-center w-100">
                                {card.changePercentage >= 0 ? (
                                  <MoveUp color="#14CB74" size={12} />
                                ) : (
                                  <MoveDown color="#F63030" size={12} />
                                )}
                                <b>
                                  {Math.abs(card.changePercentage).toFixed(1)}%
                                </b>
                              </span>
                            }
                            color={
                              card.changePercentage >= 0 ? "#14CB74" : "#F63030"
                            }
                            changeDescription="from last month"
                            isAdmin={true}
                          />
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
            <br></br>
            <div>
              <div>
                <div className="section-title">Others</div>
                <hr className="mb-4 horizontal-line"></hr>
              </div>
              <div>
                <Row gutter={32} justify={"start"} className="gap-3">
                  {OtherCardDetails.map((card) => {
                    return (
                      <Col
                        key={card.title}
                        span={5}
                        style={{ minWidth: "300px" }}
                      >
                        <Link to={card.url} className="text-decoration-none">
                          <StatisticCard
                            title={card.title}
                            totalCount={formatNumberWithCommas(card.count)}
                            percentageChange={
                              <span className="d-flex align-items-center">
                                {card.changePercentage >= 0 ? (
                                  <MoveUp color="#14CB74" size={12} />
                                ) : (
                                  <MoveDown color="#F63030" size={12} />
                                )}
                                <b>
                                  {Math.abs(card.changePercentage).toFixed(1)}%
                                </b>
                              </span>
                            }
                            color={
                              card.changePercentage >= 0 ? "#14CB74" : "#F63030"
                            }
                            changeDescription="from last month"
                            isAdmin={true}
                          />
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
            <br></br>
            <div>
              <div>
                <div className="section-title">Approvals</div>
                <hr className="mb-4 horizontal-line"></hr>
              </div>
              <div>
                <Row gutter={32} justify={"start"} className="gap-3">
                  {ApprovalCardDetails.map((card) => {
                    return (
                      <Col
                        key={card.title}
                        span={5}
                        style={{ minWidth: "300px" }}
                      >
                        <Link to={card.url} className="text-decoration-none">
                          <StatisticCard
                            title={card.title}
                            totalCount={formatNumberWithCommas(card.count)}
                            percentageChange={
                              <span className="d-flex align-items-center">
                                {card.changePercentage >= 0 ? (
                                  <MoveUp color="#14CB74" size={12} />
                                ) : (
                                  <MoveDown color="#F63030" size={12} />
                                )}
                                <b>
                                  {Math.abs(card.changePercentage).toFixed(1)}%
                                </b>
                              </span>
                            }
                            color={
                              card.changePercentage >= 0 ? "#14CB74" : "#F63030"
                            }
                            changeDescription="from last month"
                            isAdmin={true}
                          />
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        )}
      </AdminDashboardLayout>
    </>
  );
};

export default Dashboard;
