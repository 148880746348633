import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// export interface CreateRetreat {
//   id : string;
//   venue_id: string;
//   check_in_date: string;
//   check_out_date: string;
//   group_size: number;
//   total_price: number;
//   add_on_services_id ?: string[];
//   special_request ?: string,
//   desired_premium ?: {
//     is_corporate_retreat: boolean;
//     premium_per_guest: number;
//     premium_currency: string;
//   };
//   add_ons ?: string[];
//   guest_payment_plans ?: string | null;
//   guest_cancellation_policy ?: string | null;
//   step ?: number;
//   draft?: boolean;
//   accepted?: boolean;
// }

export interface CreateRetreat {
  host_id?: string;
  id: string;
  venue_id: string;
  check_in_date: string;
  check_out_date: string;
  group_size: number;
  total_price: number;
  add_on_services_id ?: string[];
  special_request?: string;
  desired_premium?: {
    is_corporate_retreat: string;
    premium_per_guest: number;
    premium_currency: string;
  };
  retreat_pricing?: {
    room_id: string;
    price_markup: number;
  }[];
  retreat_add_on_services?: string[];
  guest_payment_plan?: string | number;
  guest_cancellation_policy?: string;
  is_share_option?: boolean;
  third_party_marketing_sites?: string[];
  early_bird_discount_details?: {
    amount: number;
    unit: string;
    early_bird_deadline_date: string;
  };
  discount_code_details?: {
    amount: number;
    unit: string;
    code_usage_limit: number;
  };
  form_step ?: string;
}

// Initial state
const initialState: CreateRetreat = {
  id: "",
  venue_id: "",
  check_in_date: "",
  check_out_date: "",
  group_size: 0,
  total_price: 0,
  add_on_services_id: [],
  special_request: "",
  desired_premium: {
    is_corporate_retreat: "",
    premium_per_guest: 0,
    premium_currency: "",
  },
  retreat_pricing: [
    {
      room_id: "",
      price_markup: 0,
    },
  ],
  retreat_add_on_services : [],
  guest_payment_plan: undefined,
  guest_cancellation_policy: "",
  is_share_option: false,
  third_party_marketing_sites : [],
  early_bird_discount_details : {
    amount: 0,
    unit: "",
    early_bird_deadline_date: "",
  },
  discount_code_details : {
    amount: 0,
    unit: "",
    code_usage_limit: 0,
  },

  form_step: "",

};

// Creating the slice
export const createRetreatSlice = createSlice({
  name: "createRetreat",
  initialState,
  reducers: {
    setRetreat(state, action: PayloadAction<CreateRetreat>) {
      return action.payload;
    },
  },
});

export const { setRetreat } = createRetreatSlice.actions;
export default createRetreatSlice.reducer;
