import React from "react";
//component
import SectionDivide from "../../../../UI/Divider/SectionDivide";
//common component
import SmallText from "../../../../UI/SmallText";
//images
import { demo_images } from "../../../../../assets/images/demo-images";

const PaymentDetail = () => {
  return (
    <div className="checkout-payment">
      <div>
        <img src={demo_images.DEMO_IMAGE_1} alt="venue_img" className="img-fluid" />
        <div
          className="d-flex flex-column"
          style={{ padding: "12px 24px", gap: "6px" }}
        >
          <SmallText
            text="Shared Triple Room: Aurora"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <SmallText
            text="Shared room and bathroom with 2 others people. Perfect for couples and friends"
            color="#58585B"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
            className="text-truncate"
          />
        </div>
      </div>
      <div
        className="d-flex flex-column gap-3"
        style={{ padding: "12px 24px" }}
      >
        <div className="d-flex flex-column gap-3">
          <SmallText
            text="Coupon"
            color="#19191C"
            lineHeight={18.8}
            fontSize={16}
            fontWeight={500}
          />
          <div className="d-flex" style={{ gap: "9px" }}>
            <input type="text" className="form-control" placeholder="Coupon name"/>
            <button className="back-btn">Apply</button>
          </div>
        </div>
        <SectionDivide />
        <div className="d-flex gap-3 align-items-center">
          <SmallText
            text="Shared Triple Room: Aurora "
            color="#58585B"
            lineHeight={18.8}
            fontWeight={400}
            fontSize={16}
          />

          <SmallText
            text="$1,320"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={400}
            fontSize={16}
            className="flex-grow-1 text-end "
          />
        </div>
        <SectionDivide />
        <div className="d-flex gap-3 flex-column">
          <SmallText
            text="Add-ons"
            color="#19191C"
            lineHeight={18.8}
            fontSize={16}
            fontWeight={500}
          />
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-3 align-items-center">
              <SmallText
                text="One-on-One Yoga"
                color="#58585B"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
              />

              <SmallText
                text="$300"
                color="#19191C"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
                className="flex-grow-1 text-end "
              />
            </div>
            <div className="d-flex gap-3 align-items-center">
              <SmallText
                text="One-on-One Yoga"
                color="#58585B"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
              />

              <SmallText
                text="$300"
                color="#19191C"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
                className="flex-grow-1 text-end "
              />
            </div>
          </div>
        </div>
        <SectionDivide />
        <div className="d-flex gap-3 flex-column">
          <SmallText
            text="Discounts"
            color="#19191C"
            lineHeight={18.8}
            fontSize={16}
            fontWeight={500}
          />
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-3 align-items-center">
              <SmallText
                text="Early bird discount"
                color="#58585B"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
              />

              <SmallText
                text="- $200"
                color="#19191C"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
                className="flex-grow-1 text-end "
              />
            </div>
          </div>
        </div>
        <SectionDivide />
        <div className="d-flex gap-3 align-items-center">
          <SmallText
            text="Total"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={700}
            fontSize={16}
          />

          <SmallText
            text="$2,160"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={700}
            fontSize={16}
            className="flex-grow-1 text-end "
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetail;
