/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

// third party
import { ConfigProvider, Input, Tabs, TabsProps } from "antd";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import {
  adminRequestList,
  adminVenueList,
  getPendingDocumentVenues,
} from "../../../../network";

// images
import { images } from "../../../../assets/images";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";
import VenueListingTable from "./VenueListingTable";

// Constants
import { AdminRequestTypes } from "../../../../constants/Admin";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Utility
import { convertToTitleCase } from "../../../../library";
import { GetQueryParamsFromURL } from "../../../../library";

const VenueListing: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Set initial Table Limit Value
  const initialLimitValue = 6;
  const queryParamsFromURL = GetQueryParamsFromURL();
  const { call } = useApiCall();

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const venueValidTabs = [
    "all",
    "approved",
    "draft",
    "pending_approval",
    "pending_deactivation",
    "pending_documents",
    "declined",
  ];

  const [venueList, setVenueList] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<string>(
    queryParamsFromURL.get("tab") || "all"
  );
  const [queryParams, setQueryParams] = useState<any>({
    limit: initialLimitValue,
    offset: 0,
    search: "",
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  if (!venueValidTabs.includes(selectedTab)) {
    setSelectedTab("all");
  }

  const venueStatusListTabs: TabsProps["items"] = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "approved",
      label: "Approved",
    },
    {
      key: "draft",
      label: "Draft",
    },
    {
      key: "pending_approval",
      label: "Pending Approval",
    },
    {
      key: "pending_deactivation",
      label: "Pending Deactivation",
    },
    {
      key: "pending_documents",
      label: "Pending Documents",
    },
    {
      key: "declined",
      label: "Declined",
    },
  ];

  // APIs
  const getVenueList = async () => {
    call(
      () => adminVenueList({ queryParamsObj: queryParams }),
      (res) => {
        setVenueList(res.data.data);
        setTotalItems(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error("Failed to retrieve the venue listing.");
        setLoading(false);
      }
    );
  };

  const fetchVenuePendingApprovals = async () => {
    call(
      () =>
        adminRequestList({
          queryParamsObj: {
            ...queryParams,
            request_type: AdminRequestTypes.venueApprovalRequest,
          },
        }),
      (res) => {
        setVenueList(
          res.data?.data?.map((data: any) => {
            return {
              id: data.data.venue_id,
              name: data.data.name,
              city: data.data.city,
              country: data.data.country,
              base_price: data.data.base_price,
            };
          })
        );
        setTotalItems(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(err.response.data?.message);
        setLoading(false);
      }
    );
  };

  const fetchVenueDeactivationApprovals = async () => {
    call(
      () =>
        adminRequestList({
          queryParamsObj: {
            ...queryParams,
            request_type: AdminRequestTypes.venueDeactivationRequest,
          },
        }),
      (res) => {
        // Get proper data from response
        setVenueList(
          res.data?.data?.map((data: any) => {
            return {
              id: data.data.venue_id,
              name: data.data.name,
              city: data.data.city,
              country: data.data.country,
              base_price: data.data.base_price,
            };
          })
        );
        setTotalItems(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(err.response.data?.message);
        setLoading(false);
      }
    );
  };

  // Handle search with debounce
  const handleSearch = useCallback(
    debounce((value: string) => {
      setQueryParams((prev: any) => ({
        ...prev,
        search: value,
        limit: initialLimitValue,
        offset: 0,
      }));
      setCurrentPage(1);
    }, 1000),
    []
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setQueryParams((prev: any) => ({
      ...prev,
      offset: (page - 1) * queryParams.limit,
    }));
  };

  // Handle tab change
  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);

    switch (tab) {
      case "all":
        setQueryParams((prev: any) => ({
          limit: initialLimitValue,
          offset: 0,
        }));
        break;

      case "approved":
        setQueryParams((prev: any) => ({
          ...prev,
          limit: initialLimitValue,
          offset: 0,
          approval_status: "Approved",
          is_draft: null,
        }));
        break;

      case "draft":
        setQueryParams((prev: any) => ({
          ...prev,
          limit: initialLimitValue,
          offset: 0,
          approval_status: null,
          is_draft: true,
        }));
        break;

      case "pending_approval":
        setQueryParams((prev: any) => ({
          ...prev,
          limit: initialLimitValue,
          offset: 0,
          request_type: AdminRequestTypes.venueApprovalRequest,
        }));
        break;

      case "pending_deactivation":
        setQueryParams((prev: any) => ({
          ...prev,
          limit: initialLimitValue,
          offset: 0,
          request_type: AdminRequestTypes.venueDeactivationRequest,
        }));
        break;

      case "pending_documents":
        fetchPendingDocumentsVenues();
        break;

      case "declined":
        setQueryParams((prev: any) => ({
          ...prev,
          limit: initialLimitValue,
          offset: 0,
          approval_status: "Rejected",
        }));
        break;

      default:
        setQueryParams(() => ({
          limit: initialLimitValue,
          offset: 0,
          search: "",
        }));
        break;
    }

    setCurrentPage(1);

    const { pathname } = location;
    navigate(pathname, { replace: true });
  };

  const fetchPendingDocumentsVenues = () => {
    setVenueList(null);
    setLoading(true);
    call(
      () => getPendingDocumentVenues(),
      (res) => {
        const {
          data: { data },
        } = res;
        setVenueList(data);
        setTotalItems(res.data.count);
      },
      (err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      }
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    if (["all", "approved", "draft", "declined"].includes(selectedTab)) {
      getVenueList();
      return;
    } else if (selectedTab === "pending_approval") {
      fetchVenuePendingApprovals();
      return;
    } else if (selectedTab === "pending_deactivation") {
      fetchVenueDeactivationApprovals();
      return;
    }
  }, [queryParams]);

  return (
    <>
      <AdminDashboardLayout
        pageTitle={`Welcome,  ${convertToTitleCase(
          userProfileData.first_name
        )} 👋`}
        additionalClassNames="mt-4"
      >
        <div className="user-home-table admin-users bg-white">
          <div className="d-flex gap-3 justify-content-between">
            <div className="common-xl-medium-dark-text">Venues</div>
            <div>
              <Input
                placeholder="Search"
                prefix={<img src={images.SEARCH_ICON} height={"24px"} alt="" />}
                className="common-sm-dark-text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  itemSelectedColor: "var(--deep-sea) !important",
                  inkBarColor: "var(--deep-sea) !important",
                  itemHoverColor: "var(--deep-sea) !important",
                },
              },
            }}
          >
            <Tabs
              defaultActiveKey={selectedTab}
              items={venueStatusListTabs}
              onChange={(selected) => handleTabChange(selected)}
            />
          </ConfigProvider>

          <VenueListingTable
            selectedTab={selectedTab}
            data={venueList}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            loading={loading}
            pageSize={queryParams.limit}
          />
        </div>
      </AdminDashboardLayout>
    </>
  );
};

export default VenueListing;
