import React from 'react'

//types
import { FormFooterProps } from '../../../../../types/retreat'

//css
import "../../../../../assets/css/CheckoutModal.css"
import "../../../../../assets/css/createRetreat.css"
const CheckoutModalFooter  : React.FC<FormFooterProps>= ({
  handleNextStep,
  handlePreviousStep
}) => {
  return (
    <div className='checkout-modal-footer'>
        <button className='submit-btn' onClick={handleNextStep}>Continue</button>
        <button className='back-btn' onClick={handlePreviousStep}>back</button>
    </div>
  )
}

export default CheckoutModalFooter
