/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Input, Spin } from "antd";
import { toast } from "react-toastify";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

// custom component
import ModalComponent from "../../../UI/ModalComponent";
import Button from "../../../UI/Button";
import ClickableTags from "../../../UI/ClickableTags";

// redux
import { RootState } from "../../../../redux/store";
import { setShowModal } from "../../../../redux/features/component-slice";
import {
  setInitialAmenitiesData,
  setAmenitiesData,
} from "../../../../redux/features/venueDataSlice";
import {
  setEnableEditButton,
  setFetchAmenities,
} from "../../../../redux/features/editVenueOperationsSlice";

// network
import {
  fetchAmenities,
  fetchVenueAmenities,
  postVenueAmenities,
  updateAmenities,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  AddAmenitiesTypes,
  GroupedAmenities,
  VenueAmenities,
} from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const Amenities: React.FC<{
  venueId: string;
}> = ({ venueId }) => {
  const dispatch = useDispatch();

  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const amenitiesDataSelector = useSelector(
    (state: RootState) => state.venueData
  );
  const [roomAmenities, setRoomAmenities] = useState<
    {
      id: string;
      name: string;
      icon_id: string;
      type_of_aminity: string;
    }[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalAmenitiesLoading, setModalAmenitiesLoading] = useState(false);
  const [preSelectedBasicAmenities, setPreSelectedBasicAmenities] = useState<
    string[]
  >([]);

  const [amenities, setAmenities] = useState<VenueAmenities[]>([]);

  const [amenitiesToShow, setAmenitiesToShow] = useState<GroupedAmenities>({});
  const [groupAmenities, setGroupAmenities] = useState<GroupedAmenities>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const fetchAmenitiesSelector = useSelector(
    (state: RootState) => state.editVenueOperations.fetchAmenities
  );

  const { call } = useApiCall();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<AddAmenitiesTypes>({
    defaultValues: {
      amenities: amenities?.map((amenity) => amenity.id) || [],
    },
  });
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName: "edit-amenities", visible: true }));
  };

  const removeAmenity = (amenity_id: string, type_of_amenity: string) => {
    const updatedAmenities = amenitiesDataSelector.amenitiesData.filter(
      (amenity) => amenity.id !== amenity_id
    );

    // Update Redux store
    dispatch(setAmenitiesData(updatedAmenities));

    // Update amenities to show in the local state
    const updatedAmenitiesToShow = {
      ...amenitiesToShow,
      [type_of_amenity]: amenitiesToShow[type_of_amenity].filter(
        (amenity) => amenity.id !== amenity_id
      ),
    };
    setAmenitiesToShow(updatedAmenitiesToShow);

    // Also update basic amenities
    // setBasicAmenities(updatedAmenities);
  };

  const debouncedSearch = debounce((value: string) => {
    const filteredAmenities = roomAmenities.filter((amenity) =>
      amenity.name.toLowerCase().includes(value.toLowerCase())
    );

    const groupedAmenities = filteredAmenities.reduce((acc, amenity) => {
      const category = amenity.type_of_aminity || "Comfort and convenience";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(amenity);
      return acc;
    }, {} as GroupedAmenities);

    setGroupAmenities(groupedAmenities);
  }, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const onFinish = async (data: AddAmenitiesTypes) => {
    setSubmitting(true);
    if (amenities?.length === 0) {
      call(
        () => postVenueAmenities({ venue_id: venueId, data: data.amenities }),
        (res) => {
          dispatch(
            setShowModal({
              modalName: "edit-amenities",
              visible: false,
            })
          );
          setSubmitting(false);
          toast.success(res.data?.message || "Amenities added successfully");
          fetchAmenitiesDetails();
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to add amenities"
          );
          setSubmitting(false);
        }
      );
    } else {
      call(
        () => updateAmenities({ venue_id: venueId, data: data.amenities }),
        (res) => {
          toast.success(res.data?.message || "Amenities updated successfully");
          dispatch(
            setShowModal({
              modalName: "edit-amenities",
              visible: false,
            })
          );
          setSubmitting(false);
          fetchAmenitiesDetails();
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to update amenities"
          );

          setSubmitting(false);
        }
      );
    }
  };

  const fetchAmenitiesDetails = async () => {
    setLoading(true);
    call(
      () => fetchVenueAmenities(venueId),
      (res) => {
        const {
          data: { data },
        } = res;
        setAmenities(data?.amenities);
        dispatch(setInitialAmenitiesData(data?.amenities));
        dispatch(setAmenitiesData(data?.amenities));
        dispatch(setFetchAmenities(false));
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getAmenities = async () => {
    setModalAmenitiesLoading(true);
    call(
      () => fetchAmenities(),
      (res) => {
        const data: {
          id: string;
          name: string;
          icon_id: string;
          type_of_amenity: string;
        }[] = res.data.data;

        setRoomAmenities([]);
        data.map((item) => {
          return setRoomAmenities((prev) => [
            ...prev,
            {
              id: item.id,
              name: item.name,
              icon_id: item.icon_id,
              type_of_aminity: item.type_of_amenity,
            },
          ]);
        });

        setModalAmenitiesLoading(false);
      },
      (err) => {
        setModalAmenitiesLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchAmenitiesDetails();
    getAmenities();
  }, [fetchAmenitiesSelector]);

  useEffect(() => {
    const groupedAmenities = amenities.reduce((acc, amenity) => {
      const category = amenity.type_of_aminity || "Comfort and convenience"; // Default to 'Other' if no category is provided
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(amenity);
      return acc;
    }, {} as GroupedAmenities);

    setAmenitiesToShow(groupedAmenities);
    amenities &&
      setPreSelectedBasicAmenities(amenities.map((amenity) => amenity.id));
  }, [amenities]);

  useEffect(() => {
    if (amenitiesDataSelector.amenitiesData.length === 0) {
      dispatch(setEnableEditButton(false));
    } else if (
      amenitiesDataSelector.initialAmenitiesData ===
      amenitiesDataSelector.amenitiesData
    ) {
      dispatch(setEnableEditButton(false));
    } else {
      dispatch(setEnableEditButton(true));
    }
  }, [amenitiesDataSelector]);

  useEffect(() => {
    const groupedAmenities = roomAmenities.reduce((acc, amenity) => {
      const category = amenity.type_of_aminity || "Comfort and convenience"; // Default to 'Other' if no category is provided
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(amenity);
      return acc;
    }, {} as GroupedAmenities);

    setGroupAmenities(groupedAmenities);
  }, [roomAmenities]);

  useEffect(() => {
    reset({
      amenities: amenitiesDataSelector.amenitiesData.map(
        (amenity) => amenity.id
      ),
    });
  }, [amenitiesDataSelector.amenitiesData, reset]);

  return (
    <div className="form-white-bg">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between gap-2 align-items-center ">
          <span className="editTabTitles">Amenities</span>
          <div className="d-flex gap-2">
            <button
              type="button"
              onClick={() => openModal("edit-amenities")}
              className="custom-button"
            >
              <img
                src={images.PLUS_WHITE}
                alt=""
                width={"16px"}
                height={"16px"}
              />
            </button>
            <ModalComponent
              modalName="edit-amenities"
              title="Amenities"
              className="modal-space-info"
              outsideClickClosable={false}
            >
              <div className="room-modal-info-container">
                <div className="room-modal-subtitle">
                  Select amenities for your venue.
                </div>
                <form
                  className="room-modal-form-container amenities-form-modal w-100"
                  onSubmit={handleSubmit(onFinish)}
                >
                  <div className="w-100">
                    <Input
                      prefix={
                        <SearchOutlined
                          style={{
                            color: "#E8E7EC",
                            height: "16px",
                            width: "16px",
                          }}
                        />
                      }
                      placeholder="Search amenity"
                      className="input-component w-100"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div
                    className="form-group d-flex flex-column gap-3 room-modal-form"
                    style={{ paddingBottom: "7rem" }}
                  >
                    {modalAmenitiesLoading ? (
                      <Spin />
                    ) : (
                      <>
                        {Object.keys(groupAmenities).map((category, index) => (
                          <div
                            className="form-white-bg w-100"
                            key={`amenities-category-${index}`}
                          >
                            <div key={category} className="amenities-category">
                              <p className="common-xs-medium-grey-text mb-2">
                                {category.split("_").join(" ")}
                              </p>
                              <div className="amenities-list">
                                <Controller
                                  name="amenities"
                                  control={control}
                                  rules={{
                                    required: "Please select amenities.",
                                  }}
                                  render={({ field: { value, onChange } }) => (
                                    <ClickableTags
                                      selectionMode="multiple"
                                      tagList={groupAmenities[category]}
                                      property_id={value}
                                      onChange={(selectedValue) => {
                                        onChange(selectedValue);
                                        trigger("amenities");
                                      }}
                                      preSelectedTypes={
                                        preSelectedBasicAmenities
                                      }
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {errors.amenities && (
                      <span className="error">{errors.amenities.message}</span>
                    )}
                  </div>

                  <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end pb-1">
                    <div>
                      <Button
                        type="button"
                        label="Cancel"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid var(--deep-sea)",
                          backgroundColor: "var(--pure-white)",
                          fontSize: "14px",
                          lineHeight: "16.45px",
                          color: "var(--deep-sea)",
                          fontWeight: "500",
                          padding: "12px 16px",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          reset({
                            amenities: preSelectedBasicAmenities || [],
                          });
                          dispatch(
                            setShowModal({
                              modalName: "edit-amenities",
                              visible: false,
                            })
                          );
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        label={submitting === true ? "Saving..." : "Save"}
                        onClick={() => onFinish}
                        disabled={!isValid || submitting === true}
                        className={
                          submitting || !isValid ? "disabled-button" : ""
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </ModalComponent>
          </div>
        </div>
        <div>
          {loading ? (
            <div className="w-100 d-flex justify-content-center mt-5">
              <Spin />
            </div>
          ) : (
            <div
              className={`flex-grow-1 d-flex  ${
                amenities?.length === 0
                  ? `flex-column justify-content-center`
                  : `justify-content-start flex-wrap mt-3 gap-4`
              }`}
            >
              {amenities?.length === 0 && (
                <div className="empty-spaces d-flex flex-column align-items-center">
                  <img src={images.ADD_ONS_BACKGROUND_IMAGE} alt="" />
                  <div className="text-center">
                    <h3>You haven't added any spaces yet</h3>
                    <p>
                      Your spaces are here, click on Add space to add a new{" "}
                    </p>
                  </div>
                </div>
              )}
              {Object.keys(amenitiesToShow).map((category, index) => (
                <div
                  className="d-flex flex-column w-100 gap-3"
                  key={`category-${index}`}
                >
                  <div className="spaces-form-sub-title">
                    {category.split("_").join(" ")}
                  </div>
                  <div className="d-flex flex-wrap gap-3 w-100 align-items-start">
                    {amenitiesToShow[category].map((item, index) => {
                      const icon = icons?.find(
                        (icon) => icon.id === item.icon_id
                      );
                      return (
                        <div
                          className="select-tag"
                          style={{
                            backgroundColor: "#E9F1F6",
                            border: "1px solid var(--deep-sea)",
                          }}
                          key={`basicAmenities-${index}`}
                        >
                          {icon ? (
                            <img src={icon?.icon_url} alt={item.icon_id} />
                          ) : (
                            <img
                              src={images.AIR_CONDITIONING_ICON}
                              alt={item.icon_id}
                            />
                          )}
                          {item.name}
                          <button
                            type="button"
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={() => {
                              removeAmenity(item.id, item.type_of_aminity);
                            }}
                          >
                            x
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Amenities;
