/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

// third party
import {
  onValue,
  ref,
  push,
  runTransaction,
  update,
  get,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

// custom components
import MessageSidebar from "./MessageSidebar";
import ChatContent from "./ChatContent";
import ReservationDetailsSidebar from "./ReservationDetailsSidebar";

// redux
import { RootState } from "../../../redux/store";

// network
import { db } from "../../../network/firebaseConfig";
import {
  getReservationRequestDetails,
  getUpcomingReservationRequest,
} from "../../../network";

// hooks
import { useApiCall } from "../../../hooks/useApiCall";

// types
import { MessageType, FirebaseUser } from "../../../types";

// constants
import { userRoles } from "../../../constants";

// styles
import "../../../assets/css/message.css";

const Message: React.FC = () => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );
  const { call } = useApiCall();

  const [conversations, setConversations] = useState<MessageType>({});

  const [users, setUsers] = useState<FirebaseUser[]>([]);
  const [rawUsers, setRawUsers] = useState<FirebaseUser[]>([]);

  const [selectedUser, setSelectedUser] = useState<FirebaseUser | null>(null);

  const [lastMessages, setLastMessages] = useState<{
    [key: string]: {
      text: string;
      sender: string;
      timestamp: number;
      seen: boolean;
    };
  }>({});

  const [unseenCounts, setUnseenCounts] = useState<{ [key: string]: number }>(
    {}
  );

  const [isChatWindowActive, setIsChatWindowActive] = useState<{
    [key: string]: boolean;
  }>({});

  const [reservationRequestDetailsLoader, setReservationRequestDetailsLoader] =
    useState<boolean>(true);

  const [requestToShow, setRequestToShow] = useState<any>();
  const [chatsLoading, setChatsLoading] = useState<boolean>(false);

  const handleSendMessage = (text: string) => {
    const chatId = [userProfileData.user_id, selectedUser?.uid]
      .sort()
      .join("_");
    const messageRef = ref(db, `Chats/${chatId}/messages`);
    const message = {
      text: text,
      sender: userProfileData.user_id || "",
      timestamp: Date.now(),
      seen: false,
    };

    push(messageRef, message);

    runTransaction(
      ref(db, `Chats/${chatId}/unseenCount/${selectedUser?.uid}`),
      (currentCount) => {
        return {
          last_message_timestamp: Date.now(),
          count: 0,
        };
      }
    );

    incrementUnseenCount(chatId, userProfileData.user_id || "");
  };

  const incrementUnseenCount = (chatId: string, recipientId: string) => {
    runTransaction(
      ref(db, `Chats/${chatId}/unseenCount/${recipientId}`),
      (currentCount) => {
        return {
          count: (currentCount?.count || 0) + 1,
          last_message_timestamp: Date.now(),
        };
      }
    );

    fetchUsersData();
  };

  const markMessagesAsSeen = (chatId: string, currentUserId: string) => {
    const messagesRef = ref(db, `Chats/${chatId}/messages`);
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const updates: { [key: string]: any } = {};

        Object.keys(data).forEach((key) => {
          if (!data[key].seen && data[key].sender !== currentUserId) {
            updates[`Chats/${chatId}/messages/${key}/seen`] = true;
          }
        });

        const unseenCountRef = ref(
          db,
          `Chats/${chatId}/unseenCount/${currentUserId}`
        );

        onValue(unseenCountRef, (snapshot) => {
          const unseenCount = snapshot.val();

          updates[`Chats/${chatId}/unseenCount/${currentUserId}`] = {
            ...unseenCount,
            count: 0,
          };

          update(ref(db), updates);
        });

        update(ref(db), updates);
      }
    });
  };

  const fetchLastMessagesAndUnseenCounts = (users: FirebaseUser[]) => {
    const userUnseenCounts: { [key: string]: number } = {};

    users.forEach((user) => {
      const chatId = [userProfileData.user_id, user.uid].sort().join("_");
      const messagesRef = ref(db, `Chats/${chatId}/messages`);
      const unseenCountRef = ref(
        db,
        `Chats/${chatId}/unseenCount/${userProfileData.user_id}`
      );

      // Fetch unseen counts
      onValue(unseenCountRef, (snapshot) => {
        const unseenCount = snapshot.val();
        userUnseenCounts[user.uid] = unseenCount || 0;

        // Update the unseen counts state
        setUnseenCounts((prevState) => ({
          ...prevState,
          [user.uid]: unseenCount || 0,
        }));
      });

      // Fetch last message
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messages = Object.keys(data).map((key) => data[key]);
          const lastMessage = messages[messages.length - 1];

          // Update last message state incrementally
          setLastMessages((prevState) => ({
            ...prevState,
            [user.uid]: lastMessage,
          }));
        }
      });
    });
  };

  const fetchReservationRequests = () => {
    setReservationRequestDetailsLoader(true);
    setRequestToShow(null);
    call(
      () =>
        getUpcomingReservationRequest({
          approval_status: "Approved",
          host_id: selectedUser?.uid,
        }),
      (res) => {
        const {
          data: { data },
        } = res;
        data[0]?.id && fetchReservationRequestsDetails(data[0]?.id);
        setReservationRequestDetailsLoader(false);
      },
      (err) => {
        toast.error(
          err.data?.message || "Failed to fetch reservation requests"
        );
        setReservationRequestDetailsLoader(false);
      }
    );
  };

  const fetchReservationRequestsDetails = async (reservationId: string) => {
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        setRequestToShow(res?.data?.data);
      },
      (err) => {}
    );
  };

  const fetchUsersData = () => {
    const usersRef = ref(db, "users/");
    const retreatHostQuery = query(
      usersRef,
      orderByChild("userRole"),
      equalTo(userRoles.RETREAT_HOST)
    );
    const adminQuery = query(
      usersRef,
      orderByChild("userRole"),
      equalTo(userRoles.ADMIN)
    );
    Promise.all([get(adminQuery), get(retreatHostQuery)]).then(
      ([adminSnapshot, RetreatHostSnapshot]) => {
        let fetchedUsers: Record<string, Omit<FirebaseUser, "uid">> = {};
        if (adminSnapshot.exists())
          fetchedUsers = { ...fetchedUsers, ...adminSnapshot.val() };
        if (RetreatHostSnapshot.exists())
          fetchedUsers = { ...fetchedUsers, ...RetreatHostSnapshot.val() };

        if (Object.keys(fetchedUsers).length) {
          const usersData = Object.keys(fetchedUsers).map((key) => ({
            uid: key,
            ...fetchedUsers[key],
          })) as FirebaseUser[];
          // Process each user's chat data with real-time updates
          const usersWithChats: any[] = [];
          usersData.forEach((user) => {
            const chatId = [userProfileData.user_id, user.uid].sort().join("_");
            const messagesRef = ref(db, `Chats/${chatId}/messages`);
            const unseenCountRef = ref(
              db,
              `Chats/${chatId}/unseenCount/${user.uid}`
            );

            // Set up real-time listeners for messages and unseen count
            onValue(messagesRef, (messagesSnapshot) => {
              if (messagesSnapshot.exists()) {
                const messages = Object.values(messagesSnapshot.val());
                const lastMessage = messages[messages.length - 1] || {};

                onValue(unseenCountRef, (unseenCountSnapshot) => {
                  const unseenCount = unseenCountSnapshot.val()?.count || 0;
                  const lastMessageTimestamp =
                    unseenCountSnapshot.val()?.last_message_timestamp || 0;

                  const userWithChatData = {
                    ...user,
                    count: unseenCount,
                    lastMessage,
                    lastMessageTimestamp,
                  };

                  // Update usersWithChats with the latest data for this user
                  const existingUserIndex = usersWithChats.findIndex(
                    (u) => u.uid === user.uid
                  );
                  if (existingUserIndex !== -1) {
                    usersWithChats[existingUserIndex] = userWithChatData;
                  } else {
                    usersWithChats.push(userWithChatData);
                  }

                  // Sort users by last message timestamp and update state
                  const sortedUsers = [...usersWithChats].sort(
                    (a, b) => b?.lastMessageTimestamp - a?.lastMessageTimestamp
                  );

                  const initialActiveState = sortedUsers.reduce((acc, user) => {
                    return { ...acc, [user.uid]: false };
                  }, {});

                  if (!isChatWindowActive && sortedUsers.length > 0) {
                    setIsChatWindowActive({
                      ...initialActiveState,
                      [selectedUser?.uid || sortedUsers[0].uid]: true,
                    });
                  }

                  setUsers(sortedUsers);
                  setRawUsers(sortedUsers);
                  setSelectedUser(sortedUsers[0]);
                });
              }
            });
          });
        }
      }
    );
  };

  const handleSearch = useCallback(
    debounce((value: string, rawUsers: FirebaseUser[]) => {
      const filteredRawUsers = rawUsers.reduce((acc, user) => {
        const duplicate = acc.find((item) => item.uid === user.uid);
        if (!duplicate) {
          acc.push(user);
        }
        return acc;
      }, [] as FirebaseUser[]);

      if (!value) {
        setUsers(filteredRawUsers);
      } else {
        const filteredUsers = filteredRawUsers.filter((user) => {
          return (
            user?.name?.toLowerCase().includes(value.toLowerCase()) ||
            user?.email?.toLowerCase().includes(value.toLowerCase())
          );
        });
        setUsers(filteredUsers);
      }
      setChatsLoading(false);
    }, 700),
    []
  );

  useEffect(() => {
    fetchUsersData();
  }, []);

  useEffect(() => {
    if (!users.length) return;

    const chatId = [userProfileData.user_id, users[0].uid].sort().join("_");

    const messagesRef = ref(db, `Chats/${chatId}/messages`);
    onValue(messagesRef, (snapshot) => {
      const messageData = snapshot.val();
      if (messageData) {
        const messageArray = Object.keys(messageData).map(
          (key) => messageData[key]
        );
        setConversations({
          [users[0].uid as string]: messageArray,
        } as MessageType);
      }
    });
    fetchLastMessagesAndUnseenCounts(users);
  }, [users]);

  const fetchMessageForSelectedUser = (user: FirebaseUser | null) => {
    setSelectedUser(user);
    if (!user) return;

    const chatId = [userProfileData.user_id, user.uid].sort().join("_");
    const messagesRef = ref(db, `Chats/${chatId}/messages`);

    // Fetch messages for the selected user
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageArray = Object.keys(data).map((key) => data[key]);
        setConversations({
          [user.uid as string]: messageArray,
        } as MessageType);
      }
    });

    if (isChatWindowActive[user.uid]) {
      markMessagesAsSeen(chatId, user.uid || "");
    }
  };

  useEffect(() => {
    fetchMessageForSelectedUser(selectedUser);
  }, []);

  useEffect(() => {
    if (selectedUser?.userRole === userRoles.RETREAT_HOST) {
      fetchReservationRequests();
    }
  }, [selectedUser?.uid]);

  return (
    <div className="chat-app">
      <MessageSidebar
        newUserList={users}
        users={users}
        lastMessages={lastMessages}
        unseenCounts={unseenCounts}
        selectedChatUserId={selectedUser?.uid}
        setSelectedUser={setSelectedUser}
        setIsChatWindowActive={setIsChatWindowActive}
        fetchMessageForSelectedUser={fetchMessageForSelectedUser}
        onSearch={(value) => {
          setChatsLoading(true);
          handleSearch(value, rawUsers);
        }}
        chatsLoading={chatsLoading}
      />
      <ChatContent
        selectedUser={selectedUser}
        conversations={conversations}
        handleSendMessage={handleSendMessage}
      />
      {selectedUser?.userRole === userRoles.RETREAT_HOST && (
        <ReservationDetailsSidebar
          loading={reservationRequestDetailsLoader}
          reservationDetails={requestToShow}
        />
      )}
    </div>
  );
};

export default Message;
