/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Radio, Input, Form, Switch, Skeleton } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// Custom component
import FormLabel from "../../../UI/FormLabel";
import SelectOption from "../../../UI/SelectOption";
import ClickableTags from "../../../UI/ClickableTags";
import Button from "../../../UI/Button";
import ModalComponent from "../../../UI/ModalComponent";
import AddTaxForm from "../EditVenue/AddTaxForm";
import CreateDiscountModal from "../EditVenue/CreateDiscountModal";
import SmallText from "../../../UI/SmallText";
import InputComponent from "../../../UI/InputComponent";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import { setPriceAndAvailability } from "../../../../redux/features/venueDataSlice";
import { RootState } from "../../../../redux/store";

// helpers
import {
  blockInvalidChar,
  preventScroll,
} from "../../../../utils/filterObject";

// types
import {
  AddTaxFormValues,
  CalendarSettingsType,
  DiscountFormValues,
} from "../../../../types/property-manager";

// constants
import {
  ACCEPT_BOOKING_TIME,
  ACCEPT_BOOKING_TIME_TYPE,
  ADVANCE_BOOKING_OPTION,
} from "../../../../constants/property-manager";

// styles
import "react-big-calendar/lib/css/react-big-calendar.css";

// images
import { images } from "../../../../assets/images";

const CalendarSettings: React.FC<CalendarSettingsType> = ({
  onAvailabilityChange,
  onRateChange,
  basePrice,
  month,
  availabilityStatus,
  setAcceptReservations,
  acceptReservations,
  createVenue = false,
  defaultBasePrice,
  setDefaultBasePrice,
  setAdvanceBooking,
  advanceBooking,
  venueId,
  discountLoading,
  fetchedDiscountCodes,
  fetchDiscountCodes,
  fetchTax,
  fetchedTax,
  taxLoading,
  bookedDates,
  onSave,
}) => {
  const [form] = Form.useForm();
  const { control } = useForm();
  const dispatch = useDispatch();
  const [availability, setAvailability] = useState<string>(
    availabilityStatus || "open"
  );
  const [defaultNightlyRate, setDefaultNightlyRate] = useState<{
    [key: string]: number;
  }>({});

  const [taxForm, setTaxForm] = useState<AddTaxFormValues[]>([]);

  const [createDiscountForm, setCreateDiscountForm] = useState<
    DiscountFormValues[]
  >([]);
  const [acceptForNext, setAcceptForNext] = useState<string>(
    ACCEPT_BOOKING_TIME_TYPE.year
  );
  const [showBookingError, setShowBookingError] = useState<boolean>(false);

  const priceAvailabilitySelector = useSelector(
    (state: RootState) => state.venueData.priceAvailabilityData
  );

  const handleAvailabilityChange = (e: any) => {
    const selectedAvailability = e.target.value;
    setAvailability(selectedAvailability);
    onAvailabilityChange(selectedAvailability);
  };
  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rate = parseFloat(e.target.value);
    setDefaultNightlyRate({ ...defaultNightlyRate, [month]: rate });
    onRateChange({ [month]: rate });
  };

  const onAdvanceBookingChange = (
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    let inputValue = parseInt(value.target.value);

    if (inputValue < 3 || isNaN(inputValue)) {
      inputValue = 3;
      setShowBookingError(true);
    } else {
      setShowBookingError(false);
    }

    setAdvanceBooking!({
      timeFormat: advanceBooking?.timeFormat || "month",
      value: inputValue,
    });
    dispatch(
      setPriceAndAvailability({
        field: "advanceBooking",
        value: {
          timeFormat: advanceBooking?.timeFormat || "month",
          value: inputValue,
        },
      })
    );
  };

  useEffect(() => {
    basePrice &&
      setDefaultNightlyRate({
        ...defaultNightlyRate,
        [month]: basePrice,
      });
  }, [basePrice]);

  useEffect(() => {
    // Reset availability to "open" if the calendar status isn't "closed"
    setAvailability(availabilityStatus || "open");
  }, [month]);

  useEffect(() => {
    setAcceptForNext(
      priceAvailabilitySelector.acceptBookingForNext ||
        ACCEPT_BOOKING_TIME_TYPE.year
    );
  }, [priceAvailabilitySelector.acceptBookingForNext]);

  return (
    <div
      className="default-availability-container"
      style={{
        maxWidth: createVenue ? "322px" : "400px",
      }}
    >
      <div className="d-flex flex-column gap-1">
        <div className="default-availability-title d-flex align-items-center gap-2">
          <img src={images.GLOBE_ICON} alt="globe icon" height={20} /> Global
          Settings
        </div>
        <span className="common-xs-medium-grey-text">
          Set pricing and availability across all months.
        </span>
      </div>
      <Form layout="vertical" form={form} className="default-availability-form">
        <Form.Item>
          <div className="common-sm-medium-dark-text mb-2">Availability</div>
          <Radio.Group
            onChange={handleAvailabilityChange}
            value={availability}
            defaultValue={availabilityStatus}
          >
            <div
              className="default-availability"
              style={{
                marginBottom: "16px",
                border: "1px solid",
                borderColor:
                  availability === "open" ? "var(--deep-sea)" : "#d9d9d9",
                backgroundColor:
                  availability === "open" ? "#D4E3ED" : "var(--pure-white)",
              }}
            >
              <Radio value="open">
                <strong>Open calendar</strong>
                <p>
                  All dates will be available by default. Click on individual
                  dates in the calendar to adjust pricing and availability.
                </p>
              </Radio>
            </div>

            <div
              className="default-availability"
              style={{
                border: "1px solid",
                borderColor:
                  availability !== "open" ? "var(--deep-sea)" : "#d9d9d9",
                backgroundColor:
                  availability !== "open" ? "#D4E3ED" : "var(--pure-white)",
              }}
            >
              <Radio value="closed">
                <strong>Closed calendar</strong>
                <p>
                  All dates will be blocked by default except for booked ones.
                  Click on individual dates in the calendar to change pricing
                  and availability.
                </p>
              </Radio>
            </div>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <div className="d-flex flex-column gap-1 justify-content-between">
            <FormLabel label="Advance booking time" />
            <div className="d-flex gap-2">
              <InputComponent
                type="number"
                defaultValue={3}
                onChange={onAdvanceBookingChange}
                min={3}
                onKeyDown={blockInvalidChar}
                onWheel={(e) => preventScroll(e)}
              />
              <SelectOption
                options={ADVANCE_BOOKING_OPTION}
                defaultValue={advanceBooking?.timeFormat || "month"}
                onChange={(value) => {
                  setAdvanceBooking!({
                    timeFormat: value,
                    value: advanceBooking?.value || 3,
                  });
                  dispatch(
                    setPriceAndAvailability({
                      field: "advanceBooking",
                      value: {
                        timeFormat: value,
                        value: advanceBooking?.value || 3,
                      },
                    })
                  );
                }}
              />
            </div>
            {showBookingError && (
              <span className="error">
                Advance booking time must be at least 3 Months or 90 Days
              </span>
            )}
          </div>
        </Form.Item>

        {!createVenue && (
          <Form.Item className="">
            <div className="advance-booking-tags d-flex flex-column gap-1 justify-content-between">
              <FormLabel label="Accepting bookings for next" />
              <Controller
                name="priceLabel"
                control={control}
                rules={{ required: "Please select a type of venue." }}
                render={({ field: { value, onChange } }) => (
                  <ClickableTags
                    selectionMode="single"
                    tagList={ACCEPT_BOOKING_TIME}
                    preSelectedTypes={[acceptForNext]}
                    property_id={value}
                    onChange={(value) => {
                      onChange(value);
                      dispatch(
                        setPriceAndAvailability({
                          field: "acceptBookingForNext",
                          value,
                        })
                      );
                    }}
                  />
                )}
              />
            </div>
          </Form.Item>
        )}

        <Form.Item>
          <div className="common-sm-medium-dark-text mb-2">
            Default Nightly Price
          </div>
          <div className="common-xs-light-text mb-2">
            Set the default nightly price across all months. Go to the Month
            Settings section below to revise pricing in specific months.
          </div>
          <Input
            type="number"
            prefix="$"
            value={defaultBasePrice}
            className="mt-2"
            onScroll={(e) => e.preventDefault()}
            onWheel={(e) => e.preventDefault()}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            onBlur={(e) => {
              e.preventDefault();
            }}
            min={0}
            onChange={(e) => {
              const rate = parseFloat(e.target.value) || 0;
              setDefaultBasePrice!(rate);
              dispatch(
                setPriceAndAvailability({
                  field: "defaultBasePrice",
                  value: rate,
                })
              );
            }}
          />
        </Form.Item>

        <div className="default-availability-title d-flex align-items-center gap-2">
          <img src={images.CALENDAR} alt="" height={20} /> Settings for November
          2024
        </div>
        <Form.Item>
          <div className="d-flex justify-content-between">
            <span className="common-sm-medium-dark-text">
              Accepting Reservations
            </span>
            <Switch
              onChange={(value) =>
                setAcceptReservations!({
                  ...acceptReservations,
                  [month]: value,
                })
              }
              checked={acceptReservations && acceptReservations[month]}
            />
          </div>
          <span className="common-xs-medium-grey-text" style={{ fontSize: 12 }}>
            Are you accepting reservations for this month?
          </span>
        </Form.Item>

        <Form.Item>
          <div className="common-sm-medium-dark-text mb-2">
            Default Nightly Price
          </div>
          <div className="common-xs-light-text mb-2">
            Select individual dates on the calendar to change the price for a
            specific date.
          </div>
          <Input
            type="number"
            prefix="$"
            value={defaultNightlyRate[month] || basePrice}
            onChange={handleRateChange}
            className="mt-2"
            min={0}
          />
        </Form.Item>
        {!createVenue && (
          <>
            <div className="d-flex flex-column gap-2">
              <div className="default-availability-title">Tax Collection</div>
              {taxLoading ? (
                <Skeleton active={taxLoading} />
              ) : (
                fetchedTax &&
                Object.keys(fetchedTax).length > 0 && (
                  <div className="d-flex flex-column gap-2">
                    {
                      <div
                        className="tax-container"
                        key={`tax-${fetchedTax.id}`}
                      >
                        <div className="d-flex justify-content-between gap-2">
                          <div className="discount-access">
                            {fetchedTax.taxType}
                          </div>
                          <div className="d-flex gap-2">
                            <button
                              type="button"
                              className="bg-transparent border-0 p-0"
                              onClick={() =>
                                dispatch(
                                  setShowModal({
                                    modalName: `edit-tax-${fetchedTax.id}`,
                                    visible: true,
                                  })
                                )
                              }
                            >
                              <img src={images.EDIT_ICON} alt="" height={16} />
                            </button>
                            {/* <button
                        type="button"
                        className="bg-transparent border-0 p-0"
                      >
                        <img src={images.TRASH_ICON} alt="" height={16} />
                      </button> */}
                            <ModalComponent
                              modalName={`edit-tax-${fetchedTax.id}`}
                              title="Custom Discount Code"
                              className="add-tax-modal"
                              outsideClickClosable={false}
                            >
                              <AddTaxForm
                                setTaxForm={setTaxForm}
                                taxFormValues={taxForm}
                                prefilledValues={{
                                  id: fetchedTax.id,
                                  referenceId: fetchedTax.referenceId,
                                  taxType: fetchedTax.taxType,
                                  typeOfCharge: fetchedTax.typeOfCharge,
                                  accommodationTaxRegistrationNumber:
                                    fetchedTax.accommodationTaxRegistrationNumber,
                                  businessTaxId: fetchedTax.businessTaxId,
                                  exemptionsForLongTermStays:
                                    fetchedTax.exemptionsForLongTermStays,
                                  taxAmount: fetchedTax.taxAmount,
                                  taxFor: fetchedTax.taxFor,
                                  termsAgreed: fetchedTax.termsAgreed,
                                }}
                                fetchTax={fetchTax}
                                edit={true}
                                venueId={venueId}
                              />
                            </ModalComponent>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between gap-2">
                          <div className="common-sm-light-grey-text">
                            Type of Charge
                          </div>
                          <div className="common-sm-medium-dark-text ">
                            {fetchedTax?.typeOfCharge?.split("_").join(" ")}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between gap-2 ">
                          <div className="common-sm-light-grey-text">
                            Tax Amount
                          </div>
                          <div className="common-sm-medium-dark-text ">
                            {fetchedTax.taxAmount}%
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                )
              )}
              <Button
                label="Add Tax "
                className="bordered-button"
                style={{ maxWidth: "100%", width: "100%" }}
                type="button"
                onClick={() =>
                  dispatch(
                    setShowModal({ modalName: "addTaxModal", visible: true })
                  )
                }
              />
              <ModalComponent
                modalName="addTaxModal"
                title="Custom Discount Code"
                className="add-tax-modal"
                outsideClickClosable={false}
              >
                <AddTaxForm
                  setTaxForm={setTaxForm}
                  taxFormValues={taxForm}
                  venueId={venueId}
                  fetchTax={fetchTax}
                />
              </ModalComponent>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="default-availability-title">Discount Code</div>
              {discountLoading ? (
                <Skeleton active={discountLoading} />
              ) : (
                fetchedDiscountCodes &&
                fetchedDiscountCodes.length > 0 && (
                  <div className="d-flex flex-column gap-2">
                    {fetchedDiscountCodes?.map((item, index) => (
                      <div className="tax-container" key={`discount-${index}`}>
                        <div className="d-flex justify-content-between gap-2">
                          <div className="discount-access">
                            {item.isPublic ? "Public" : "Private"}
                          </div>
                          <div className="d-flex gap-2">
                            <button
                              type="button"
                              className="bg-transparent border-0 p-0"
                              onClick={() =>
                                dispatch(
                                  setShowModal({
                                    modalName: `edit-discount-${item.id}`,
                                    visible: true,
                                  })
                                )
                              }
                            >
                              <img src={images.EDIT_ICON} alt="" height={16} />
                            </button>
                            {/* TODO */}
                            {/* <button
                        type="button"
                        className="bg-transparent border-0 p-0"
                      >
                        <img src={images.TRASH_ICON} alt="" height={16} />
                      </button> */}
                            <ModalComponent
                              modalName={`edit-discount-${item.id}`}
                              title="Custom Discount Code"
                              className="add-tax-modal create-discount-modal"
                              outsideClickClosable={false}
                            >
                              <CreateDiscountModal
                                setDiscountForm={setCreateDiscountForm}
                                discountFormValues={createDiscountForm}
                                prefilledValues={{
                                  isPublic: item.isPublic,
                                  discountValue: item.discountValue,
                                  discountCode: item.discountCode,
                                  discountType: item.discountType,
                                  expiryDate: item.expiryDate,
                                  discountFor: item.discountFor,
                                  id: item.id,
                                  referenceId: item.referenceId,
                                }}
                                fetchDiscountCodes={fetchDiscountCodes}
                                edit={true}
                                venueId={venueId}
                              />
                            </ModalComponent>
                          </div>
                        </div>
                        <div>
                          <SmallText
                            text={`Discount Code: ${item.discountCode}`}
                            fontSize={16}
                          />
                        </div>
                        <div className="d-flex justify-content-between gap-2">
                          <div className="common-sm-light-grey-text">
                            Discounted Value
                          </div>
                          <div className="common-sm-medium-dark-text ">
                            {item.discountValue}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between gap-2 ">
                          <div className="common-sm-light-grey-text">
                            Expiry Date
                          </div>
                          <div className="common-sm-medium-dark-text ">
                            {item.expiryDate}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
              <Button
                label="Create Discount Code "
                className="bordered-button"
                style={{ maxWidth: "100%", width: "100%" }}
                type="button"
                onClick={() =>
                  dispatch(
                    setShowModal({ modalName: "discountModal", visible: true })
                  )
                }
              />
              <ModalComponent
                modalName="discountModal"
                title="Custom Discount Code"
                className="add-tax-modal create-discount-modal"
                outsideClickClosable={false}
              >
                <CreateDiscountModal
                  setDiscountForm={setCreateDiscountForm}
                  discountFormValues={createDiscountForm}
                  venueId={venueId}
                  fetchDiscountCodes={fetchDiscountCodes}
                />
              </ModalComponent>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default CalendarSettings;
