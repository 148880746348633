/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Space } from "antd";

// custom component
import ClickableTags from "../../../UI/ClickableTags";
import FormLabel from "../../../UI/FormLabel";
import SelectOption from "../../../UI/SelectOption";
import InputComponent from "../../../UI/InputComponent";
import TextareaComponent from "../../../UI/TextareaComponent";
import Button from "../../../UI/Button";
import VideoPreview from "../CreateVenue/CreateVenueFormStep1/VideoPreview";

// redux
import { RootState } from "../../../../redux/store";
import { setVenueData } from "../../../../redux/features/venueDataSlice";
import { setEnableEditButton } from "../../../../redux/features/editVenueOperationsSlice";

// types
import { FormValues, Step1Props } from "../../../../types/property-manager";

// constants
import { countryOptions } from "../../../../constants";
import { DEFAULT_NUMBER_OPTION } from "../../../../constants/property-manager";

// images
import { images } from "../../../../assets/images";

const Description: React.FC<Step1Props> = ({
  highlights,
  languages,
  propertyTypes,
  retreatsHostTypes,
  venueData,
}) => {
  const {
    control,
    trigger,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      type_of_property_id: "",
      number_of_rooms: 1,
      number_of_bathrooms: 1,
      sleeps: 1,
      custom_paragraphs: [],
      venue_highlights_id: [],
      recommended_retreats: [],
      language_id: [],
      zip_code: "",
      country: "",
      property_name: "",
      city: "",
      state: "",
      address: "",
      property_description: "",
      base_price: 500,
      media_url: "",
    },
  });
  const videoUrl = watch("media_url");

  const dispatch = useDispatch();
  const venueDataSelector = useSelector((state: RootState) => state.venueData);

  const [venue_highlights_id, setVenueHighlights] = useState(0);
  const [recommended_retreats, setRecommendedRetreats] = useState(0);
  const [language_id, setLanguage] = useState(0);

  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const [defaultRecommendedRetreats, setDefaultRecommendedRetreats] = useState<
    string[]
  >([]);

  const [defaultVenueHighlights, setDefaultVenueHighlights] = useState<
    string[]
  >([]);

  const [defaultLanguages, setDefaultLanguages] = useState<string[]>([]);

  const [defaultPropertyTypes, setDefaultPropertyTypes] = useState<string[]>(
    []
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "custom_paragraphs",
  });

  useEffect(() => {
    if (!isValid) {
      dispatch(setEnableEditButton(false));
    } else if (
      JSON.stringify(venueDataSelector.venueData) ===
      JSON.stringify(venueDataSelector.initialVenueData)
    ) {
      dispatch(setEnableEditButton(false));
    } else {
      dispatch(setEnableEditButton(true));
    }
  }, [venueDataSelector.venueData, reset, isValid]);

  useEffect(() => {
    const retreat_host_type: string[] = [];
    const venueHighlights: string[] = [];
    const preLanguages: string[] = [];
    const prePropertyTypes: string[] = [];

    venueData?.recommended_retreats?.forEach((retreat) => {
      retreatsHostTypes?.forEach((type) => {
        if (retreat.name === type.name) {
          retreat_host_type.push(type.id);
        }
      });
    });

    setDefaultRecommendedRetreats(retreat_host_type);

    venueData?.highlights?.forEach(
      (highlight: { icon_id: string; name: string }) => {
        highlights?.forEach((type) => {
          if (highlight?.name === type.name) {
            venueHighlights.push(type.id);
          }
        });
      }
    );

    setDefaultVenueHighlights(venueHighlights);

    venueData?.languages?.forEach((lang) => {
      languages?.forEach((item) => {
        if (item.name === lang) {
          preLanguages.push(item.id);
        }
      });
    });

    setDefaultLanguages(preLanguages);

    if (venueData?.venue_type?.name) {
      propertyTypes?.forEach((item) => {
        if (item.name === venueData?.venue_type?.name) {
          prePropertyTypes.push(item.id);
        }
      });
      setDefaultPropertyTypes(prePropertyTypes);
    }

    if (venueData) {
      reset({
        type_of_property_id: venueData?.venue_type?.id || "",
        number_of_rooms: venueData?.number_of_rooms || 1,
        number_of_bathrooms: venueData?.number_of_bathrooms || 1,
        sleeps: venueData?.sleeps || 1,
        custom_paragraphs: venueData?.custom_paragraphs || [],
        venue_highlights_id: (venueHighlights as any) || [],
        recommended_retreats: (retreat_host_type as any) || [],
        language_id: (preLanguages as any) || [],
        zip_code: venueData?.zip_code || "",
        country: venueData?.country || "",
        property_name: venueData?.venue_title || "",
        city: venueData?.city || "",
        state: venueData?.state || "",
        address: venueData?.address || "",
        property_description: venueData?.property_description || "",
        media_url: venueData?.media_url || "",
      });
    }
  }, [venueData, reset]);

  return (
    <section className="create-venue-form edit-form">
      <form>
        <div className="form-white-bg">
          <div className="form-group">
            <FormLabel
              label="Type of venue"
              subLabel="Please select only one type of venue"
            />

            <Controller
              name="type_of_property_id"
              control={control}
              rules={{
                required: defaultPropertyTypes.length
                  ? false
                  : "Property type is required",
              }}
              render={({ field: { value, onChange } }) => (
                <ClickableTags
                  selectionMode="single"
                  tagList={propertyTypes ? propertyTypes : []}
                  property_id={value}
                  onChange={(value) => {
                    onChange(value);
                    dispatch(
                      setVenueData({ field: "type_of_property_id", value })
                    );
                    trigger("type_of_property_id");
                  }}
                  preSelectedTypes={defaultPropertyTypes}
                />
              )}
            />

            {errors.type_of_property_id && (
              <span className="error">
                {errors.type_of_property_id.message}
              </span>
            )}
          </div>
        </div>
        <div className="form-white-bg">
          <div
            className={`form-group recommended_retreats ${
              venue_highlights_id || defaultVenueHighlights.length > 0
                ? "selected"
                : ""
            }`}
          >
            <FormLabel
              label="Venue highlights"
              subLabel="Please select 3-5 categories that best describe your venue"
            />

            <div>
              <Controller
                name="venue_highlights_id"
                control={control}
                rules={{
                  required: "Please select at least one venue highlight.",
                }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    showSearch={true}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="search or create a general characteristics about your location"
                    defaultValue={value}
                    value={value || defaultVenueHighlights}
                    onChange={(value) => {
                      onChange(value);
                      setVenueHighlights(value.length);
                      dispatch(
                        setVenueData({ field: "venue_highlights_id", value })
                      );
                      trigger("venue_highlights_id");
                    }}
                    options={highlights?.map((item) => {
                      const icon = icons?.find(
                        (icon) => icon.id === item.icon_id
                      );

                      return {
                        value: item.id,
                        label: (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={icon?.icon_url}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {item.name}
                            </span>
                          </div>
                        ),
                      };
                    })}
                  />
                )}
              />
              {errors.venue_highlights_id && (
                <span className="error">
                  {errors.venue_highlights_id.message}
                </span>
              )}
            </div>
          </div>

          <div
            className={`form-group recommended_retreats ${
              recommended_retreats || defaultRecommendedRetreats.length > 0
                ? "selected"
                : ""
            }`}
          >
            <FormLabel
              label="Type of Retreats You Host"
              subLabel="Select from the many types of retreats your venue can accommodate."
            />
            <Controller
              name="recommended_retreats"
              control={control}
              rules={{
                required: "Please select at least one venue retreat.",
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectOption
                    showSearch={false}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="search recommended retreats for your location"
                    defaultValue={value}
                    value={value || defaultRecommendedRetreats}
                    onChange={(value) => {
                      onChange(value);
                      setRecommendedRetreats(value.length);
                      dispatch(
                        setVenueData({ field: "recommended_retreats", value })
                      );
                      trigger("recommended_retreats");
                    }}
                    options={retreatsHostTypes?.map((item) => {
                      const icon = icons?.find(
                        (icon) => icon.id === item.icon_id
                      );

                      return {
                        value: item.id,
                        label: (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={icon?.icon_url}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {item.name}
                            </span>
                          </div>
                        ),
                      };
                    })}
                  />
                );
              }}
            />
            {errors.recommended_retreats && (
              <span className="error">
                {errors.recommended_retreats?.message}
              </span>
            )}
          </div>
        </div>

        <div className="form-white-bg">
          <div className="form-group row">
            <div className="col">
              <FormLabel label="Sleeps" />
              <Controller
                name="sleeps"
                control={control}
                rules={{
                  required: "Please select the number of people it sleeps.",
                }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    options={DEFAULT_NUMBER_OPTION}
                    defaultValue={
                      venueData?.sleeps?.toString() ? venueData?.sleeps : "1"
                    }
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                      dispatch(setVenueData({ field: "sleeps", value }));
                    }}
                  />
                )}
              />
              {errors.sleeps && (
                <span className="error">{errors.sleeps.message}</span>
              )}
            </div>

            {/* Number of Bedrooms */}
            <div className="col">
              <FormLabel label="Number of Bedrooms" />
              <Controller
                name="number_of_rooms"
                control={control}
                rules={{ required: "Please select the number of bedrooms." }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    options={DEFAULT_NUMBER_OPTION}
                    defaultValue={venueData?.number_of_rooms?.toString() || "1"}
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                      dispatch(
                        setVenueData({ field: "number_of_rooms", value })
                      );
                    }}
                  />
                )}
              />
              {errors.number_of_rooms && (
                <span className="error">{errors.number_of_rooms.message}</span>
              )}
            </div>

            {/* Number of Bathrooms */}
            <div className="col">
              <FormLabel label="Number of Bathrooms" />
              <Controller
                name="number_of_bathrooms"
                control={control}
                rules={{ required: "Please select the number of bathrooms." }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    options={DEFAULT_NUMBER_OPTION}
                    defaultValue={
                      venueData?.number_of_bathrooms?.toString() || "1"
                    }
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                      dispatch(
                        setVenueData({ field: "number_of_bathrooms", value })
                      );
                    }}
                  />
                )}
              />
              {errors.number_of_bathrooms && (
                <span className="error">
                  {errors.number_of_bathrooms.message}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <FormLabel label="Name of venue" />
            <Controller
              name="property_name"
              control={control}
              rules={{
                required: "Please provide the venue name.",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters long.",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  type="text"
                  placeholder="Write the name here"
                  style={{ width: "100%" }}
                  value={value}
                  onChange={(e) => {
                    const inputValue = e.target.value; // Extract the value
                    onChange(inputValue);
                    dispatch(
                      setVenueData({
                        field: "property_name",
                        value: inputValue,
                      })
                    );
                    trigger("property_name");
                  }}
                />
              )}
            />
            {errors.property_name && (
              <span className="error">{errors.property_name.message}</span>
            )}
          </div>

          {/* Description */}
          <div className="form-group">
            <FormLabel label="Describe your venue" />
            <Controller
              name="property_description"
              control={control}
              rules={{
                required: "Please describe your venue.",
              }}
              render={({ field: { value, onChange } }) => (
                <TextareaComponent
                  placeholder="Talk about your venue"
                  value={value}
                  onChange={(e) => {
                    const inputValue = e.target.value; // Extract the value
                    onChange(inputValue);
                    dispatch(
                      setVenueData({
                        field: "property_description",
                        value: inputValue,
                      })
                    );
                    trigger("property_description");
                  }}
                />
              )}
            />
            {errors.property_description && (
              <span className="error">
                {errors.property_description?.message}
              </span>
            )}
          </div>
        </div>
        <div className="form-white-bg">
          {/* Custom Paragraphs */}
          <div className="form-group">
            <FormLabel
              label="Custom paragraph"
              subLabel="Include detailed information tailored to your audience or objective."
            />
            {fields.map((field, index) => (
              <Space
                key={`custom-paragraph-${index}`}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 custom-paragraph-title">
                      Custom Paragraph {index < 9 ? `0${index + 1}` : index + 1}
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        remove(index);
                        const updatedParagraphs = [
                          ...(venueDataSelector.venueData?.custom_paragraphs ||
                            []),
                        ];

                        // Remove the paragraph at the specific index
                        updatedParagraphs.splice(index, 1);

                        // Dispatch the updated paragraphs array to Redux
                        dispatch(
                          setVenueData({
                            field: "custom_paragraphs",
                            value: updatedParagraphs,
                          })
                        );
                      }}
                      className="remove-custom-paragraph"
                    >
                      <img src={images.BIN_ICON} alt="remove" />
                    </button>
                  </div>
                  <div className="mb-2">
                    <FormLabel label="Title" />
                    <Controller
                      name={`custom_paragraphs.${index}.title`}
                      control={control}
                      rules={{ required: "Please provide a title." }}
                      render={({ field: { value, onChange } }) => (
                        <InputComponent
                          style={{ width: "100%", marginTop: "4px" }}
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value; // Extract the value
                            onChange(inputValue);
                            const updatedParagraphs = [
                              ...(venueDataSelector.venueData
                                ?.custom_paragraphs || []),
                            ];

                            // Keep the content intact, update only the title
                            updatedParagraphs[index] = {
                              ...updatedParagraphs[index], // Keep the existing content field
                              title: inputValue,
                              content: updatedParagraphs[index]?.content,
                            };

                            // Dispatch the updated custom paragraphs array
                            dispatch(
                              setVenueData({
                                field: "custom_paragraphs",
                                value: updatedParagraphs,
                              })
                            );
                            trigger(`custom_paragraphs.${index}.title`);
                          }}
                        />
                      )}
                    />
                    {errors.custom_paragraphs?.[index]?.title && (
                      <span className="error">
                        {errors.custom_paragraphs[index]?.title?.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <FormLabel label="Description" />
                    <Controller
                      name={`custom_paragraphs.${index}.content`}
                      control={control}
                      rules={{ required: "Please provide a description." }}
                      render={({ field: { value, onChange } }) => (
                        <TextareaComponent
                          style={{ width: "100%", marginTop: "4px" }}
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value; // Extract the value
                            onChange(inputValue);
                            const updatedParagraphs = [
                              ...(venueDataSelector.venueData
                                ?.custom_paragraphs || []),
                            ];

                            // Keep the title intact, update only the content
                            updatedParagraphs[index] = {
                              ...updatedParagraphs[index],
                              title: updatedParagraphs[index]?.title,
                              content: inputValue,
                            };

                            // Dispatch the updated custom paragraphs array
                            dispatch(
                              setVenueData({
                                field: "custom_paragraphs",
                                value: updatedParagraphs,
                              })
                            );
                            trigger(`custom_paragraphs.${index}.content`);
                          }}
                        />
                      )}
                    />
                    {errors.custom_paragraphs?.[index]?.content && (
                      <span className="error">
                        {errors.custom_paragraphs[index]?.content?.message}
                      </span>
                    )}
                  </div>
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="button"
                onClick={() => append({ title: "", content: "" })}
                label="+ New custom paragraph"
                style={{
                  backgroundColor: "var(--pure-white)",
                  border: "1px solid var(--deep-sea)",
                  color: "var(--deep-sea)",
                  marginTop: "12px",
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-white-bg">
          <div className="form-group location">
            <FormLabel label="Location" />
            <div className="col">
              <FormLabel label="Address" />
              <Controller
                name="address"
                control={control}
                rules={{
                  required: "Please provide the address.",
                }}
                render={({ field: { value, onChange } }) => (
                  <InputComponent
                    style={{ width: "100%", marginTop: "4px !important" }}
                    placeholder="Main Avenue"
                    value={value}
                    onChange={(e) => {
                      const inputValue = e.target.value; // Extract the value
                      onChange(inputValue);
                      dispatch(
                        setVenueData({ field: "address", value: inputValue })
                      );
                      trigger("address");
                    }}
                  />
                )}
              />
              {errors.address && (
                <span className="error">{errors.address.message}</span>
              )}
            </div>
            <div className="row">
              <div className="col">
                <FormLabel label="City/Town" />
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: "Please provide the City.",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputComponent
                      placeholder="Enter city name"
                      style={{ width: "100%", marginTop: "4px !important" }}
                      value={value}
                      onChange={(e) => {
                        const inputValue = e.target.value; // Extract the value
                        onChange(inputValue);
                        dispatch(
                          setVenueData({ field: "city", value: inputValue })
                        );
                        trigger("city");
                      }}
                    />
                  )}
                />
                {errors.city && (
                  <span className="error">{errors.city.message}</span>
                )}
              </div>
              <div className="col">
                <FormLabel label="State/Province" />
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: "Please provide the City.",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputComponent
                      placeholder="Enter state/province"
                      style={{ width: "100%", marginTop: "4px !important" }}
                      value={value}
                      onChange={(e) => {
                        const inputValue = e.target.value; // Extract the value
                        onChange(inputValue);

                        trigger("state");
                      }}
                    />
                  )}
                />
                {errors.state && (
                  <span className="error">{errors.state.message}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormLabel label="Country" />
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: "Please select at country.",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SelectOption
                      options={countryOptions}
                      defaultValue="United States"
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                        dispatch(
                          setVenueData({ field: "country", value: value })
                        );
                        trigger("country");
                      }}
                      showSearch
                    />
                  )}
                />
                {errors.country && (
                  <span className="error">{errors.country.message}</span>
                )}
              </div>
              <div className="col">
                <FormLabel label="Zipcode" />
                <Controller
                  name="zip_code"
                  control={control}
                  rules={{
                    required: "Please provide the Zipcode.",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputComponent
                      style={{ marginTop: "4px !important" }}
                      placeholder="12345"
                      maxLength={12}
                      minLength={4}
                      value={value}
                      onChange={(e) => {
                        const inputValue = e.target.value; // Extract the value
                        onChange(inputValue);
                        dispatch(
                          setVenueData({ field: "zip_code", value: inputValue })
                        );
                        trigger("zip_code");
                      }}
                    />
                  )}
                />
                <div>
                  {errors.zip_code && (
                    <span className="error">{errors.zip_code.message}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-white-bg">
          <div
            className={`form-group recommended_retreats ${
              language_id || defaultLanguages.length > 0 ? "selected" : ""
            }`}
          >
            <FormLabel
              label="Languages"
              subLabel="If the host needs help in your venue, what languages can people communicate in?"
            />
            <Controller
              name="language_id"
              control={control}
              rules={{
                required: "Please select at least one language.",
              }}
              render={({ field: { value, onChange } }) => (
                <SelectOption
                  showSearch={false}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  defaultValue={value}
                  value={value || defaultLanguages}
                  placeholder="Please select languages"
                  options={languages?.map((item) => ({
                    value: item.id,
                    label: (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        key={item.id}
                      >
                        <span>{item.name}</span>
                      </div>
                    ),
                  }))}
                  onChange={(value) => {
                    onChange(value);
                    setLanguage(value.length);
                    dispatch(
                      setVenueData({ field: "language_id", value: value })
                    );
                    trigger("language_id");
                  }}
                />
              )}
            />
            {errors.language_id && (
              <span className="error">{errors.language_id?.message}</span>
            )}
          </div>
        </div>
        <div className="form-white-bg media">
          <FormLabel label="Media" />
          <div className="form-group">
            <FormLabel label="Video URL" />
            <Controller
              name="media_url"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  type="text"
                  placeholder="Enter Video URL"
                  style={{ width: "100%" }}
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const inputValue = e.target.value;
                    onChange(inputValue);
                    dispatch(
                      setVenueData({ field: "media_url", value: inputValue })
                    );
                    trigger("media_url");
                  }}
                />
              )}
            />
            {videoUrl && <VideoPreview url={videoUrl} />}
          </div>
        </div>
      </form>
    </section>
  );
};

export default Description;
