import React from "react";
import { useDispatch, useSelector } from "react-redux";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
import { RootState } from "../../../../redux/store";

//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";

//image
import { images } from "../../../../assets/images";

const AboutNotInclude: React.FC = () => {
  const { retreatVenueDetail } = useSelector(
    (state: RootState) => state.retreatVenue
  );
  const dispatch = useDispatch();
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">What’s not included?</p>
      <div className=" d-flex flex-column gap-3">
        <p className="section-description">
          {retreatVenueDetail?.not_included}{" "}
        </p>

        <div className="venueIcon">
          <button
            className="modal-open-button"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("aboutNotInclude")}
          >
            <SmallText text="Read more" />
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        </div>
      </div>
      <ModalComponent modalName="aboutNotInclude" title="What’s not included?">
        <p className="section-description">
          {retreatVenueDetail?.not_included}{" "}
        </p>
      </ModalComponent>
    </div>
  );
};

export default AboutNotInclude;
