/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Form, Image, Spin, Upload, UploadFile, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useForm } from "antd/es/form/Form";
import { toast } from "react-toastify";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

// custom component
import Button from "../../../UI/Button";
import ModalComponent from "../../../UI/ModalComponent";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import {
  setPhotosData,
  setInitialPhotosData,
} from "../../../../redux/features/venueDataSlice";
import { RootState } from "../../../../redux/store";
import { setEnableEditButton } from "../../../../redux/features/editVenueOperationsSlice";

// network
import {
  fetchReferenceImages,
  uploadImage,
  uploadVenueImage,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// helpers
import { getBase64 } from "../../../../library";

// types
import { FileType } from "../../../../types";

// images
import { images } from "../../../../assets/images";

const UploadPhotos: React.FC<{ venueId: string }> = ({ venueId }) => {
  const dispatch = useDispatch();
  const photosSelector = useSelector((state: RootState) => state.venueData);
  const [form] = useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [venueImages, setVenueImages] = useState<
    {
      id: string;
      image_url: string;
    }[]
  >([]);

  const [hoveredImage, setHoveredImage] = useState<number | null>(null);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const { call } = useApiCall();

  const imageUploadProps: UploadProps = {
    name: "images",
    multiple: true,
    fileList: fileList,
    listType: "picture-card",
    accept: "image/*",
    beforeUpload(file) {
      const isImage = file.type && file.type.startsWith("image/");
      if (!isImage) {
        toast.error("You can only upload image files.");
        return Upload.LIST_IGNORE;
      }
      // Prevent upload action
      setFileList([...fileList, file]);
      return false;
    },
    onChange({ fileList: newFileList }) {
      const filteredFileList = newFileList.filter(
        (file) => file.type && file.type.startsWith("image/")
      );
      setFileList(filteredFileList);
      // if there is error then remove remove error
      form
        .validateFields(["images"])
        .then(() => {
          form.setFields([
            {
              name: "images",
              errors: [],
            },
          ]);
        })
        .catch(() => {});
    },
    onRemove(file) {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    onPreview: async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <img src={images.UPLOAD_BUTTON_ICON} alt="add_photo_icon" />
    </button>
  );

  const handleMouseEnter = (index: number) => {
    setHoveredImage(index);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const handleStoreImagesToDelete = (id: string) => {
    setImagesToDelete([...imagesToDelete, id]);
    setVenueImages(venueImages.filter((image) => image.id !== id));
    const images = [...imagesToDelete, id];
    dispatch(
      setPhotosData(
        photosSelector.initialPhotosData.filter((image) =>
          images.includes(image.id)
        )
      )
    );
  };

  const handleFinalSubmit = async (images: string[]) => {
    call(
      () =>
        uploadVenueImage({
          reference_id: venueId,
          data: images,
        }),
      (res) => {
        toast.success("Images uploaded successfully");
        setSubmitting(false);
        dispatch(
          setShowModal({
            modalName: "upload-photos",
            visible: false,
          })
        );
        setFileList([]);
        fetchVenueImages();
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to update venue");
        setSubmitting(false);
      }
    );
  };

  const onFinish = () => {
    setSubmitting(true);
    const formData = new FormData();
    fileList.map(async (file) => {
      formData.append("files", file.originFileObj as any);
    });

    call(
      () => uploadImage(formData),
      (res) => {
        handleFinalSubmit(res.data.data);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to upload images");
      }
    );
  };

  const fetchVenueImages = async () => {
    setLoading(true);
    call(
      () => fetchReferenceImages({ reference_id: venueId }),
      (res) => {
        setVenueImages(res.data.data);
        dispatch(setPhotosData(res.data.data));
        dispatch(setInitialPhotosData(res.data.data));
        setLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch images");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchVenueImages();
  }, []);

  useEffect(() => {
    if (photosSelector.photosData.length === 0) {
      dispatch(setEnableEditButton(false));
    } else if (photosSelector.photosData === photosSelector.initialPhotosData) {
      dispatch(setEnableEditButton(false));
    } else {
      dispatch(setEnableEditButton(true));
    }
  }, [photosSelector.photosData]);

  return (
    <div className="form-white-bg">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between gap-2">
          <span className="editTabTitles">Your Photos</span>
          <div className="d-flex gap-2">
            <button
              type="button"
              onClick={() => openModal("upload-photos")}
              className="custom-button"
            >
              <img
                src={images.PLUS_WHITE}
                alt=""
                width={"16px"}
                height={"16px"}
              />
            </button>
            <ModalComponent
              modalName="upload-photos"
              title="Upload photos"
              className="modal-space-info"
            >
              <Form
                form={form}
                className="room-modal-form-container"
                onFinish={onFinish}
              >
                <div className="room-modal-form">
                  <div className="d-flex gap-2 w-100 create-venue-file-upload edit-upload-photos">
                    <div className="flex-grow-1">
                      <div className="d-flex flex-column gap-1">
                        <Form.Item
                          name="images"
                          rules={[
                            {
                              validator(_, value) {
                                if (fileList.length === 0) {
                                  return Promise.reject(
                                    "Please upload at least one photo."
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          {fileList.length === 0 && (
                            <Dragger {...imageUploadProps} defaultFileList={[]}>
                              <p className="ant-upload-text">
                                <img src={images.UPLOAD_BUTTON_ICON} alt="" />
                                <br />
                                Upload a photo or click here
                              </p>
                            </Dragger>
                          )}
                          {fileList.length > 0 && (
                            <Upload
                              {...imageUploadProps}
                              style={{ marginTop: "12px !important" }}
                            >
                              {fileList.length >= 24 ? null : uploadButton}
                            </Upload>
                          )}
                          {previewImage && (
                            <>
                              <Image
                                wrapperStyle={{ display: "none" }}
                                preview={{
                                  visible: previewOpen,
                                  onVisibleChange: (visible) =>
                                    setPreviewOpen(visible),
                                  afterOpenChange: (visible) =>
                                    !visible && setPreviewImage(""),
                                }}
                                src={previewImage}
                              />
                            </>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="modal-form-footer edit-upload d-flex gap-3 w-100 align-items-center justify-content-end">
                    <div>
                      <Button
                        type="button"
                        label="Cancel"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid var(--deep-sea)",
                          backgroundColor: "var(--pure-white)",
                          fontSize: "14px",
                          lineHeight: "16.45px",
                          color: "var(--deep-sea)",
                          fontWeight: "500",
                          padding: "12px 16px",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          dispatch(
                            setShowModal({
                              modalName: "upload-photos",
                              visible: false,
                            })
                          );
                          form.resetFields();
                          setFileList([]);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        label={submitting === true ? "Saving..." : "Save"}
                        onClick={() => onFinish}
                        disabled={submitting || fileList.length === 0}
                        className={submitting ? "disabled-button" : ""}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </ModalComponent>
          </div>
        </div>
        <div>
          {loading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          ) : (
            <div className="d-flex gap-3 flex-wrap">
              {venueImages?.map((image, index) => (
                <div
                  className="image-cards"
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img src={image.image_url} alt="" className="upload-image" />
                  {hoveredImage === index && (
                    <div className="image-card-hover">
                      <button
                        type="button"
                        onClick={() => {
                          setPreviewImage(image.image_url);
                          setPreviewOpen(true);
                        }}
                      >
                        <EyeOutlined style={{ color: "#fff" }} />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleStoreImagesToDelete(image.id)}
                      >
                        <DeleteOutlined style={{ color: "#fff" }} />
                      </button>
                    </div>
                  )}
                </div>
              ))}
              {previewImage && (
                <>
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadPhotos;
