import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type editVenueOperationType = {
  activeTab: string;
  enableEditButton: boolean;
  fetchAmenities: boolean;
};

const initialState: editVenueOperationType = {
  activeTab: "1",
  enableEditButton: false,
  fetchAmenities: false,
};

export const editVenueOperationsSlice = createSlice({
  name: "activeTab",
  initialState: initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },

    setEnableEditButton: (state, action: PayloadAction<boolean>) => {
      state.enableEditButton = action.payload;
    },
    setFetchAmenities: (state, action: PayloadAction<boolean>) => {
      state.fetchAmenities = action.payload;
    },
  },
});

export const { setActiveTab, setEnableEditButton, setFetchAmenities } =
  editVenueOperationsSlice.actions;

export default editVenueOperationsSlice.reducer;
