/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

// custom component
import MainLayout from "../../../MainLayout";
import BookingTermsForm from "./BookingTermsForm";
import PageTitle from "../PageTitle";

// redux
import { RootState } from "../../../../../redux/store";
import { setLoading } from "../../../../../redux/features/common-slice";

// network
import { fetchBookingTerms } from "../../../../../network";

// helpers

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// styles
import "../../../../../assets/css/create-venue.css";

const CreateVenueFormStep5: React.FC<{
  venue_id: string;
  handleNextStep: (query?: string) => void;
  handlePreviousStep: () => void;
}> = ({ handleNextStep, handlePreviousStep, venue_id }) => {
  const [bookingTerms, setBookingTerms] = React.useState<{
    check_in_date: string;
    check_out_date: string;
    guest_number: number;
    night_stays: number;
    quiet_hours_end: string;
    quiet_hours_start: string;
    venue_id: string;
    id: string;
    form_state?: {
      step: number;
      completed_status: boolean;
    };
  }>({
    check_in_date: "",
    check_out_date: "",
    guest_number: 1,
    night_stays: 1,
    quiet_hours_end: "",
    quiet_hours_start: "",
    venue_id: "",
    id: "",
  });

  const dispatch = useDispatch();
  const { call } = useApiCall();
  const loading = useSelector(
    (state: RootState) => state.commonReducer.loading
  );

  useEffect(() => {
    dispatch(setLoading(true));
    call(
      () => fetchBookingTerms(venue_id),
      (res) => {
        setBookingTerms(res.data.data);
        dispatch(setLoading(false));
      },
      (err) => {
        dispatch(setLoading(false));
      }
    );
  }, []);

  return loading ? (
    <Spin fullscreen />
  ) : (
    <div className="add-spaces">
      <MainLayout>
        <PageTitle
          title="Terms & Conditions"
          subtitle="Share policies like check-in/out times, quiet hours, and shared space guidelines to set clear expectations."
        />
        <BookingTermsForm
          venueId={venue_id}
          bookingTermsData={bookingTerms}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </MainLayout>
    </div>
  );
};

export default CreateVenueFormStep5;
