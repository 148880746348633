import { PositionType } from "antd/es/image/style";
import React from "react";

const StatusTags: React.FC<{
  label?: string;
  color?: string;
  backgroundColor?: string;
  buttonPosition?: PositionType;
  top?: string;
  left?: string;
}> = ({ label, color, backgroundColor, buttonPosition, top, left }) => {
  return (
    <p
      className="my-venues-card-tags"
      style={{
        position: buttonPosition || "absolute",
        top: top || "16px",
        left: left || "16px",
        background: backgroundColor,
        padding: "6px 12px",
        borderRadius: "80px",
        color: color,
        fontSize: "14px",
        fontWeight: "500",
        width: "fit-content",
      }}
    >
      {label}
    </p>
  );
};

export default StatusTags;
