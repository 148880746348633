import React from "react";

// third party
import dayjs from "dayjs";

// custom components
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";

// constants
import { CancelationPolicy } from "../../../../constants/property-manager";

// types
import { InfoYouNeedToKnowType } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const InfoNeedToKnow: React.FC<InfoYouNeedToKnowType> = ({
  openModal,
  houseRule,
}) => {
  const checkInOutTitle = ["Check in at", "Check out at"];
  const quiteHoursTitle = ["Quiet hours from", "Quiet hours until"];

  return (
    <section className="info-need-to-know-container">
      <div className="venue-details-title">Info You Need to Know</div>
      <div className="info-need-to-know">
        <div
          className="info-need-to-know-card p-3 gap-3 bg-white rounded-3 "
          style={{ border: "1px solid #E8E7EC" }}
        >
          <div className="info-title">House rules</div>
          <div className="house-rules-container">
            {houseRule?.checkInOut?.map((rule, index) => (
              <div
                key={`house-rule-check-in-out-${index}`}
                className="house-rule"
              >
                <img src={images.CLOCK_ICON2} alt="" height={"16px"} />
                <SmallText
                  text={`${checkInOutTitle[index]} ${dayjs(
                    rule,
                    "HH:mm:ss"
                  ).format("hh:mm a")}`}
                  color="var(--fuscous-greyish)"
                />
              </div>
            ))}
            {houseRule?.quiteHour?.map((rule, index) =>
              rule !== "" ? (
                <div
                  key={`house-rule-quiet-hour-${index}`}
                  className="house-rule"
                >
                  <img src={images.CLOCK_ICON2} alt="" height={"16px"} />
                  <SmallText
                    text={`${quiteHoursTitle[index]} ${dayjs(
                      rule,
                      "HH:mm:ss"
                    ).format("hh:mm a")}`}
                    color="var(--fuscous-greyish)"
                  />
                </div>
              ) : null
            )}
          </div>
          <div>
            <button
              className="modal-button"
              type="button"
              onClick={() => openModal("houseRules")}
            >
              <SmallText text="See all" />
              <img src={images.RIGHT_ARROW_ICON} alt="" />
            </button>
            <ModalComponent modalName="houseRules" title="House Rules">
              <div className="info-need-to-know-modal">
                <div className="house-rule-container">
                  <div className="rule-title">Check in and Check out</div>
                  <div className="rule-container">
                    {houseRule?.checkInOut?.map((time, index) => (
                      <div key={`house-rule-check-in-out-${index}`}>
                        <img src={images.CLOCK_ICON2} alt="" height={"28px"} />
                        <SmallText
                          text={`${checkInOutTitle[index]} ${time}`}
                          color="#2F206A"
                          fontSize={18}
                          fontWeight={400}
                          lineHeight={21.15}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="house-rule-container">
                  {houseRule?.quiteHour?.filter((time) => time === "")
                    .length !== 2 && (
                    <div className="rule-title">Quiet hours</div>
                  )}
                  <div className="rule-container">
                    {houseRule?.quiteHour?.map((time, index) =>
                      time !== "" ? (
                        <div key={`house-rule-quiet-hour-${index}`}>
                          <img
                            src={images.CLOCK_ICON2}
                            alt=""
                            height={"28px"}
                          />
                          <SmallText
                            text={`${quiteHoursTitle[index]} ${time}`}
                            color="#2F206A"
                            fontSize={18}
                            fontWeight={400}
                            lineHeight={21.15}
                          />
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </ModalComponent>
          </div>
        </div>
        <div
          className="info-need-to-know-card p-3 gap-3 bg-white rounded-3"
          style={{ border: "1px solid #E8E7EC" }}
        >
          <div className="info-title">Cancellation policy</div>
          <div className="info-description">
            If you cancel your reservation before July 30, 11:59 PM, you will
            receive a 50% refund of the amount paid, minus the service charge.
            Times are based on the property's local time.
          </div>
          <div>
            <button
              className="modal-button"
              type="button"
              onClick={() => openModal("cancellationPolicy")}
            >
              <SmallText text="See all" />
              <img src={images.RIGHT_ARROW_ICON} alt="" />
            </button>
            <ModalComponent
              modalName="cancellationPolicy"
              title="Cancellation policy"
            >
              <div className="info-need-to-know-modal">
                {CancelationPolicy.map((policy, index) => (
                  <div
                    key={`cancellation-policy-${index}`}
                    className="policy-container"
                  >
                    <div
                      className="policy-sub-container policy-time-container"
                      style={{ width: "80px" }}
                    >
                      <div className="policy-time">{policy.time}</div>
                      <div className="policy-time-desc">{policy.timeDesc}</div>
                    </div>
                    <div className="policy-sub-container">
                      <div className="policy-title">{policy.title}</div>
                      <div className="policy-desc">{policy.desc}</div>
                    </div>
                  </div>
                ))}
              </div>
            </ModalComponent>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoNeedToKnow;
