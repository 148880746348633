/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import {
  ConfigProvider,
  Dropdown,
  MenuProps,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

// custom components
import PayoutsTable from "./PayoutsTable";
import SmallText from "../../../UI/SmallText";

// redux
import { RootState } from "../../../../redux/store";
import { setCurrentPage } from "../../../../redux/features/paginationSlice";
import { setPayoutStatus } from "../../../../redux/features/queryParamSlice";
import { resetPagination } from "../../../../redux/features/payoutsPaginationSlice";

// network
import { fetchPayouts } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import { fetchPayoutsType } from "../../../../types/property-manager";

// styles
import "../../../../assets/css/property-manager-payout.css";

// images
import { images } from "../../../../assets/images";

const Payouts: React.FC = () => {
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const paginationSelector = useSelector(
    (state: RootState) => state.payoutsPagination
  );
  const payoutStatusSelector = useSelector(
    (state: RootState) => state.queryParam.payoutStatus
  );

  const [selectedChatFilter, setSelectedChatFilter] = useState<
    "Current year" | "Past year" | "Past 2 year" | "All years"
  >("Current year");

  const [queryParams, setQueryParams] = useState<fetchPayoutsType>({});

  const [payouts, setPayouts] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [totalItems, setTotalItems] = useState(0); // To store the total count of items
  const [offset, setOffset] = useState(0);
  const initialLimit = 5;

  const payoutsYearFilter: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Current year")}
        >
          <SmallText text="Current year" />
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past year")}
        >
          <SmallText text="Past year" />
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past 2 year")}
        >
          <SmallText text="Past 2 year" />
        </button>
      ),
    },
    {
      key: "4",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("All years")}
        >
          <SmallText text="All years" />
        </button>
      ),
    },
  ];

  const venueStatusListTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "All",
      children: <PayoutsTable loading={loading} payouts={payouts} />,
    },
    {
      key: "2",
      label: "Pending Payout",
      children: <PayoutsTable loading={loading} payouts={payouts} />,
    },
    {
      key: "3",
      label: "Completed Payout",
      children: <PayoutsTable loading={loading} payouts={payouts} />,
    },
    {
      key: "4",
      label: "Failed Payout",
      children: <PayoutsTable loading={loading} payouts={payouts} />,
    },
  ];

  const getPayoutData = async ({
    status,
    start_date,
    end_date,
    limit,
    offset,
  }: fetchPayoutsType) => {
    setLoading(true);
    setOffset(offset || 0);
    call(
      () =>
        fetchPayouts({
          status,
          start_date,
          end_date,
          limit,
          offset,
        }),
      (res) => {
        const {
          data: { data },
        } = res;
        setPayouts(data.payouts);
        setTotalItems(res.data.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch payouts");
        setLoading(false);
      }
    );
  };

  const onTabChange = (key: string) => {
    dispatch(setPayoutStatus(null));
    dispatch(setCurrentPage(1));
    if (key === "1") {
      const { status, ...queryParamsNew } = queryParams;
      getPayoutData({ ...queryParamsNew, offset: 0 });
      setQueryParams({ ...queryParamsNew, offset: 0 });
    } else if (key === "2") {
      const { status, ...queryParamsNew } = queryParams;
      getPayoutData({ ...queryParamsNew, status: "pending", offset: 0 });
      setQueryParams({ ...queryParamsNew, status: "pending", offset: 0 });
    } else if (key === "3") {
      const { status, ...queryParamsNew } = queryParams;
      getPayoutData({ ...queryParamsNew, status: "paid", offset: 0 });
      setQueryParams({ ...queryParamsNew, status: "paid", offset: 0 });
    } else if (key === "4") {
      const { status, ...queryParamsNew } = queryParams;
      getPayoutData({ ...queryParamsNew, status: "failed", offset: 0 });
      setQueryParams({ ...queryParamsNew, status: "failed", offset: 0 });
    }
  };

  const handleNext = () => {
    if (!hasNextPage) return; // Prevent further requests if no more pages
    const filters: Record<string, any> = {
      "4": { status: "failed" },
      "2": { status: "pending" },
      "3": { status: "paid" },
      "1": {},
    };
    const statusFilter = filters[payoutStatusSelector || "1"];
    const filterDate: { start_date?: string; end_date?: string } | null =
      selectedChatFilter === "Current year"
        ? {
            start_date: dayjs().startOf("year").format("YYYY-MM-DD"),
            end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
          }
        : selectedChatFilter === "Past 2 year"
        ? {
            start_date: dayjs().subtract(2, "year").format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
          }
        : selectedChatFilter === "Past year"
        ? {
            start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
          }
        : {};
    const queryParamsNew: fetchPayoutsType = {
      account_id: "acct_1PiG4CRrWhavRK9Y",
      limit: initialLimit,
      offset: paginationSelector.currentPage * initialLimit, // Calculate the next offset
      ...statusFilter,
      ...filterDate,
    };

    dispatch(setCurrentPage(paginationSelector.currentPage + 1));
    getPayoutData({ ...queryParams, ...queryParamsNew });
    setQueryParams({ ...queryParams, ...queryParamsNew });
  };

  const handlePrevious = () => {
    if (paginationSelector.currentPage === 1) return; // Prevent going back from page 1

    const filters: Record<string, any> = {
      "4": { status: "failed" },
      "2": { status: "pending" },
      "3": { status: "paid" },
      "1": {},
    };
    const statusFilter = filters[payoutStatusSelector || "1"];
    const filterDate: { start_date?: string; end_date?: string } | null =
      selectedChatFilter === "Current year"
        ? {
            start_date: dayjs().startOf("year").format("YYYY-MM-DD"),
            end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
          }
        : selectedChatFilter === "Past 2 year"
        ? {
            start_date: dayjs().subtract(2, "year").format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
          }
        : selectedChatFilter === "Past year"
        ? {
            start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
            end_date: dayjs().format("YYYY-MM-DD"),
          }
        : {};

    const queryParamsNew: fetchPayoutsType = {
      limit: initialLimit,
      offset: (paginationSelector.currentPage - 2) * initialLimit, // Calculate the previous offset
      ...statusFilter,
      ...filterDate,
    };

    dispatch(setCurrentPage(paginationSelector.currentPage - 1));
    getPayoutData({ ...queryParams, ...queryParamsNew });
    setQueryParams({ ...queryParams, ...queryParamsNew });
  };
  const getFilterDateRange = (
    filter: string
  ): { start_date?: string; end_date?: string } => {
    switch (filter) {
      case "Current year":
        return {
          start_date: dayjs().startOf("year").format("YYYY-MM-DD"),
          end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
        };
      case "Past 2 year":
        return {
          start_date: dayjs().subtract(2, "year").format("YYYY-MM-DD"),
          end_date: dayjs().format("YYYY-MM-DD"),
        };
      case "Past year":
        return {
          start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
          end_date: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {};
    }
  };

  const fetchInitialData = async () => {
    dispatch(setPayoutStatus(null));

    const filterDate = getFilterDateRange(selectedChatFilter);

    const { limit, offset, start_date, end_date, ...newQueryParams } =
      queryParams;

    const finalQueryParams = {
      ...newQueryParams,
      limit: initialLimit,
      offset: 0,
      ...filterDate,
    };

    getPayoutData(finalQueryParams);

    dispatch(resetPagination());
    setQueryParams(finalQueryParams);
  };

  useEffect(() => {
    if (payoutStatusSelector === "pending") {
      onTabChange("2");
    } else {
      fetchInitialData();
    }
  }, [selectedChatFilter]);

  useEffect(() => {
    if (offset >= totalItems - 1 && offset !== 0) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }
  }, [offset]);

  return (
    <div className="payouts user-home-table admin-users bg-white">
      <div className="d-flex gap-3 justify-content-between">
        <div className="common-xl-medium-dark-text">Payouts</div>
        <div className="d-flex gap-2 justify-content-end ">
          <Dropdown
            menu={{
              items: payoutsYearFilter,
              selectable: true,
              defaultSelectedKeys: ["1"],
            }}
            trigger={["click"]}
            className="chart-filter text-dark"
            onOpenChange={() => dispatch(setCurrentPage(1))}
          >
            <Typography.Link>
              <span>{selectedChatFilter}</span>
              <DownOutlined />
            </Typography.Link>
          </Dropdown>
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "var(--deep-sea) !important",
              inkBarColor: "var(--deep-sea) !important",
              itemHoverColor: "var(--deep-sea) !important",
            },
          },
        }}
      >
        <Tabs
          items={venueStatusListTabs}
          defaultActiveKey={payoutStatusSelector === "pending" ? "2" : "1"}
          onChange={onTabChange}
        />
      </ConfigProvider>
      <div className="d-flex justify-content-between w-100 mt-3">
        <button
          type="button"
          className="bordered-button mr-2 pagination-button"
          onClick={handlePrevious}
          disabled={paginationSelector.currentPage === 1} // Disable when on the first page
        >
          <img src={images.PAGINATION_ARROW_LEFT} alt="" height={"20px"} />{" "}
          Previous
        </button>
        <button
          type="button"
          className="bordered-button pagination-button"
          onClick={handleNext}
          disabled={!hasNextPage}
        >
          Next
          <img src={images.PAGINATION_ARROW_RIGHT} alt="" height={"20px"} />
        </button>
      </div>
    </div>
  );
};

export default Payouts;
