import React from "react";
//component
import PaymentDetail from "./PaymentDetail";
import PackageSelect, { ImageNameType } from "./PackageSelect";

//common component
import SmallText from "../../../../UI/SmallText";

//constant
import { checkoutModalPackage } from "../../../../../constants/RetreatHost";

//images
import { images } from "../../../../../assets/images";

//css
import "../../../../../assets/css/CheckoutModal.css";

const SelectPackage = () => {
  return (
    <div className="checkout-modal-content">
      <div className="checkout-section-detail d-flex flex-column gap-4">
        <div className="checkout-title">
          <img src={images.Package} alt="icon" width={28} height={28} />
          <SmallText
            text="Select package"
            color="#2F206A"
            lineHeight={21.15}
            fontSize={18}
            fontWeight={400}
          />
        </div>
        <div className="overflow-y-auto d-flex flex-column gap-3">
          {checkoutModalPackage.map((packageData, index) => (
            <PackageSelect
              title={packageData.title}
              description={packageData.description}
              price={packageData.price}
              actualPrice={packageData.actualPrice}
              imageName={packageData.imageName as ImageNameType}
              index={index}
            />
          ))}
        </div>
      </div>
      <PaymentDetail />
    </div>
  );
};

export default SelectPackage;
