import DEMO_IMAGE_1 from "./photo.png";
import DEMO_IMAGE_2 from "./photo (1).png";
import DEMO_IMAGE_3 from "./photo (2).png";
import DEMO_IMAGE_4 from "./photo (3).png";
import DEMO_IMAGE_5 from "./photo (4).png";
import DEMO_IMAGE_6 from "./photo-1.png";
import DEMO_IMAGE_7 from "./photo-2.png";
import DEMO_IMAGE_8 from "./photo-3.png";
import DEMO_IMAGE_9 from "./photo-4.png";
import DEMO_IMAGE_10 from "./photo-5.png";
import DEMO_IMAGE_11 from "./photo-6.png";
import OWNER_IMAGE from "./owner_img.png";

export const demo_images = {
  DEMO_IMAGE_6,
  DEMO_IMAGE_7,
  DEMO_IMAGE_8,
  DEMO_IMAGE_9,
  DEMO_IMAGE_10,
  DEMO_IMAGE_11,
  DEMO_IMAGE_1,
  DEMO_IMAGE_2,
  DEMO_IMAGE_3,
  DEMO_IMAGE_4,
  DEMO_IMAGE_5,
  OWNER_IMAGE,
};
