// Third Party
import { toast } from "react-toastify";

// Components
import { handleLogout } from "../components/core/Authentication/Logout";

export const handle400Error = (data: any) => {
  toast.error(`Bad Request: ${data.message || "Invalid data."}`);
};

export const handle401Error = () => {
  toast.error("Unauthorized: Please login again.");
};

export const handle403Error = () => {
  toast.error("Access Denied.");
};

export const handle404Error = () => {
  toast.error("Not Found: The requested resource could not be found.");
};

export const handle500Error = () => {
  toast.error("Server Error: Please try again later.");
};

interface ApiResponse<T = any> {
  status: number;
  data: T;
}

interface ErrorResponse {
  response?: {
    status: number;
    data: any;
    message?: string;
  };
}

export const useApiCall = () => {
  const call = async <T = any>(
    apiCall: () => Promise<ApiResponse<T>>,
    onSuccess: (response: ApiResponse<T>) => void,
    onError?: (err?: any) => void
  ) => {
    try {
      const response = await apiCall();

      if (!response) {
        handleLogout();
        return;
      }

      const { status, data } = response;

      switch (status) {
        case 200:
        case 201:
        case 202:
          onSuccess(response);
          break;

        case 401:
        case 403:
          handleLogout();
          handle400Error(data);
          if (onError) onError(data);
          break;
        case 400:
          handle400Error(data);
          if (onError) onError(data);
          break;

        case 402:
          handle401Error();
          if (onError) onError(data);
          break;

        case 404:
          handle404Error();
          if (onError) onError(data);
          break;

        case 409:
          handle404Error();
          if (onError) onError(data);
          break;

        case 500:
          handle500Error();
          if (onError) onError(data);
          break;

        default:
          if (onError) {
            onError(data);
          } else {
            toast.error("An unexpected error occurred.");
          }
          break;
      }
    } catch (err: unknown) {
      const error = err as ErrorResponse;

      // Handle error with response object
      if (error) {
        // const { status, data } = error.response;

        switch (error.response?.status) {
          case 401:
            handleLogout();
            handle401Error();
            if (onError) onError(error);
            break;

          default:
            if (onError) {
              onError(error);
            } else {
              toast.error(
                error.response?.message || "An unexpected error occurred."
              );
            }
            break;
        }
      }
    }
  };

  return { call };
};
