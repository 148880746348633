import React from "react";

// types
import { CardProps } from "../../../types";

const CardComponent: React.FC<CardProps> = ({ image, title, description }) => {
  return (
    <div className="card">
      <img src={image} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <div className="card-text">{description}</div>
      </div>
    </div>
  );
};

export default CardComponent;
