// third party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReservationForCancellation } from "../../types/property-manager";

type CancelReservationSliceType = {
  paymentData: ReservationForCancellation;

  cancelReason: {
    reason: string;
    message: string;
  };

  lastRoute: string;
};

const initialState: CancelReservationSliceType = {
  paymentData: {
    laigo_service_free: 0,
    penalty_fee: 0,
    cancellation_data: {
      message_to_retreat_host: "",
      reason: "",
      retreat_host_email: "",
      venue_manager: "",
      venue_manager_email: "",
    },
    meta_data: {
      venue_name: "",
      venue_address: "",
      country: "",
      state: "",
      city: "",
      number_of_rooms: 0,
      number_of_bathrooms: 0,
      sleeps: 0,
      group_size: 0,
      check_in_date: "",
      check_out_date: "",
      reservation_id: "",
      retreat_name: "",
      total_price: 0,
      venue_manager: "",
      days: 0,
      images: "",
      retreat_host_email: "",
      venue_id: "",
      reservation_number: "",
      reason: "",
      message_to_retreat_host: "",
      venue_manager_email: "",
    },
  },

  cancelReason: {
    reason: "",
    message: "",
  },
  lastRoute: "",
};

export const CancelReservationSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setPaymentData: (
      state,
      action: PayloadAction<ReservationForCancellation>
    ) => {
      state.paymentData = action.payload;
    },

    setCancelReason: <
      K extends keyof CancelReservationSliceType["cancelReason"]
    >(
      state: CancelReservationSliceType,
      action: PayloadAction<{
        field: K;
        value: CancelReservationSliceType["cancelReason"][K];
      }>
    ) => {
      state.cancelReason[action.payload.field] = action.payload.value;
    },
    setLastRoute: (state, action: PayloadAction<string>) => {
      state.lastRoute = action.payload;
    },
  },
});

export const { setPaymentData, setCancelReason, setLastRoute } =
  CancelReservationSlice.actions;

export default CancelReservationSlice.reducer;
