import React, { useState } from "react";

// third party
import { Card, Popover, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// custom components
import NoVenues from "./NoVenues";
import ModalComponent from "../../../UI/ModalComponent";
import VenueActionModal from "./VenueActionModal";
import StatusTags from "../../../UI/StatusTags";
import NoDataFound from "../../../UI/NoDataFound";

// redux
import {
  setCreateVenueFormStep,
  setHomeActionLoading,
} from "../../../../redux/features/common-slice";
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import {
  deactivateVenue,
  deleteVenue,
  duplicateVenue,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import { FetchedVenue } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const AllVenues: React.FC<{
  AllVenueList: FetchedVenue[];
  showNoVenueImage?: boolean;
  fetchVenues?: () => void;
  allVenues?: boolean;
}> = ({
  AllVenueList,
  showNoVenueImage = true,
  fetchVenues,
  allVenues = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { call } = useApiCall();

  const [deactivateLoading, setDeactivateLoading] = useState(false);

  const handleVenueDuplicate = async ({ venueId }: { venueId: string }) => {
    call(
      () => duplicateVenue({ venueId }),
      (res) => {
        toast.success(res.data?.message || "Venue duplicated successfully");
        fetchVenues && fetchVenues();
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to duplicate venue"
        );
      }
    );
  };

  const handleVenueDelete = async ({ venueId }: { venueId: string }) => {
    dispatch(setHomeActionLoading(true));
    call(
      () => deleteVenue({ venueId }),
      (res) => {
        toast.success(res.data?.message || "Venue duplicated successfully");
        dispatch(setShowModal({ modalName: "delete-venues", visible: false }));
        fetchVenues && fetchVenues();
        dispatch(setHomeActionLoading(false));
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to duplicate venue"
        );
        dispatch(setHomeActionLoading(false));
      }
    );
  };

  const handleVenueDeactivate = async ({ venueId }: { venueId: string }) => {
    dispatch(setHomeActionLoading(true));
    call(
      () => deactivateVenue({ venueId }),
      (res) => {
        toast.success(
          res.data?.message || "Venue deactivate request sent successfully"
        );
        fetchVenues && fetchVenues();
        dispatch(setHomeActionLoading(false));
        dispatch(
          setShowModal({ modalName: "deactivate-venue", visible: false })
        );
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to send deactivate request"
        );
        dispatch(setHomeActionLoading(false));
      }
    );
  };

  const getStatusDetails = (venue: any) => {
    const isPublished =
      venue.is_approved === "Approved" &&
      !venue.is_deleted &&
      venue.is_available;
    const isPendingApproval =
      venue.is_approved === "Pending" &&
      venue.form_state.completed_status &&
      !venue.is_deleted &&
      venue.is_available;
    const isDeleted = venue.is_deleted;
    const isDeactivated = !venue.is_available;
    const isDeclined =
      venue.is_approved === "Rejected" &&
      venue.form_state.completed_status &&
      !venue.is_deleted;

    const label = isPublished
      ? "Published"
      : isPendingApproval
      ? "Pending Approval"
      : isDeleted
      ? "Deleted"
      : isDeactivated
      ? "Deactivated"
      : isDeclined
      ? "Declined"
      : "Draft";

    const color = isPublished
      ? "#398E58"
      : isPendingApproval
      ? "#5D3FD3"
      : isDeleted
      ? "#F22007"
      : isDeactivated
      ? "#DC7618"
      : isDeclined
      ? "#fd7e14"
      : "#19191C";

    const backgroundColor = isPublished
      ? "#CEF5DC"
      : isPendingApproval
      ? "#EFECFB"
      : isDeleted
      ? "#FDA9A0"
      : isDeactivated
      ? "#F7ECE2"
      : isDeclined
      ? "#ffdfc5"
      : "#FFFFFF";

    return { label, color, backgroundColor };
  };

  return AllVenueList.length === 0 ? (
    showNoVenueImage ? (
      <NoVenues />
    ) : (
      <div>
        <NoDataFound />
      </div>
    )
  ) : (
    <div className="venue-card-container d-flex flex-wrap gap-4">
      {AllVenueList.map((venue: FetchedVenue, index: number) => {
        const content = (
          <div className={`actions-container venue-cards-action-container`}>
            <button
              type="button"
              className="border-0 bg-white"
              onClick={() => {
                venue.form_state.step &&
                  dispatch(setCreateVenueFormStep(venue.form_state?.step));

                venue.form_state?.completed_status
                  ? navigate(`/venues/my-venues/edit-venue/${venue.id}`)
                  : navigate(
                      `/venues/create-venue/venue-details?venue_id=${venue.id}`
                    );
              }}
            >
              <img src={images.EYE_ICON} alt="" height={"24px"} />
              <span>Edit</span>
            </button>
            {venue.is_approved === "Rejected" &&
            venue.form_state.completed_status &&
            !venue.is_deleted ? null : (
              <button
                type="button"
                className="border-0 bg-white"
                onClick={() => {
                  setDeactivateLoading(true);
                  handleVenueDuplicate({ venueId: venue.id });
                }}
              >
                <img src={images.DUPLICATE_ICON} alt="" height={"24px"} />
                <span>Duplicate</span>
              </button>
            )}
            {venue.is_approved === "Approved" &&
              venue.form_state.completed_status &&
              !venue.is_deleted &&
              venue.is_available && (
                <button
                  type="button"
                  className="border-0 bg-white"
                  onClick={() => {
                    dispatch(
                      setShowModal({
                        modalName: "deactivate-venue",
                        visible: true,
                      })
                    );
                  }}
                >
                  <img src={images.PAUSE_ICON} alt="" height={"24px"} />
                  <span>Deactivate</span>
                </button>
              )}
            <ModalComponent
              modalName={"deactivate-venue"}
              className="reservation-success-modal"
            >
              <VenueActionModal
                modalName="deactivate-venue"
                message="Are you sure you want to deactivate this venue?"
                action={async () => {
                  handleVenueDeactivate({ venueId: venue.id });
                }}
                buttonLabel={"Deactivate"}
                disabled={deactivateLoading}
              />
            </ModalComponent>
            <button
              type="button"
              className="border-0 bg-white"
              onClick={() => {
                dispatch(
                  setShowModal({ modalName: "delete-venues", visible: true })
                );
              }}
            >
              <img src={images.TRASH_ICON} alt="" height={"24px"} />
              <span>Delete</span>
            </button>
            <ModalComponent
              modalName={"delete-venues"}
              className="reservation-success-modal"
            >
              <VenueActionModal
                modalName="delete-venues"
                message="Are you sure you want to delete this venue?"
                action={() => handleVenueDelete({ venueId: venue.id })}
                buttonLabel={"Delete"}
                disabled={false}
              />
            </ModalComponent>
          </div>
        );
        return (
          <Card
            cover={
              <img
                alt={venue.name}
                src={venue?.venue_images?.[0] || images.NO_IMAGE}
                style={{
                  height: "249px",
                  width: "300px",
                  objectFit: "cover",
                }}
              />
            }
            style={{
              width: "300px",
              height: "350px",
              filter:
                venue.is_deleted || !venue.is_available
                  ? "brightness(0.5)"
                  : "none",
            }}
            key={`venue-${index}`}
          >
            <div
              className="d-flex flex-column justify-content-between h-100"
              style={{ gap: "12px" }}
            >
              <div>
                {allVenues &&
                  (() => {
                    const { label, color, backgroundColor } =
                      getStatusDetails(venue);
                    return (
                      <StatusTags
                        label={label}
                        color={color}
                        backgroundColor={backgroundColor}
                      />
                    );
                  })()}

                <div className="common-md-medium-dark-text d-flex align-items-center justify-content-between gap-2 mb-1 position-relative">
                  <Tooltip title={venue.name}>
                    <span className="tex-overflow-handle">{venue.name} </span>
                  </Tooltip>
                  <Popover
                    content={content}
                    trigger="click"
                    rootClassName="popups"
                  >
                    <button
                      type="button"
                      className="bg-white border-0"
                      disabled={venue.is_deleted || !venue.is_available}
                    >
                      <img
                        src={images.THREE_VERTICAL_DOT_BLACK}
                        alt=""
                        height={"20px"}
                      />
                    </button>
                  </Popover>
                </div>
                <p className="common-xs-light-text tex-overflow-handle">{`${
                  venue.city !== null ? venue.city + "," : ""
                } ${venue.country !== null ? venue.country : ""}`}</p>
              </div>
              <div className="d-flex justify-content-between gap-2 common-xs-light-text">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={images.BED_ICON_BLUE}
                    alt=""
                    height={"12px"}
                    width={"14px"}
                  />
                  <span>{venue.number_of_bed_rooms} Beds </span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={images.BATH_ICON}
                    alt=""
                    height={"12px"}
                    width={"14px"}
                  />
                  <span>{venue.number_of_bath_rooms} Baths </span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={images.TWO_PEOPLE_ICON}
                    alt=""
                    height={"12px"}
                    width={"14px"}
                  />
                  <span>{venue.sleeps} Guests </span>
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default AllVenues;
