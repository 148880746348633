import React from "react";
import { useDispatch } from "react-redux";
import { setShowModal } from "../../../../redux/features/component-slice";
//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
//images
import { images } from "../../../../assets/images";

const ScheduleDetail : React.FC = () => {
  const dispatch = useDispatch();
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">Sample Schedule</p>
      <div className="d-flex flex-column gap-3 ">
        <div>
          <strong className="section-description-title">
            Day 1: Arrival and Accommodation
          </strong>
          <p className="section-description">
            2:00 PM - 4:00 PM: Participant arrival and check-in
          </p>
          <p className="section-description">
            4:30 PM - 5:30 PM: Welcome session and retreat introduction
          </p>
          <p className="section-description">6:00 PM - 7:00 PM: Light dinner</p>
          <p className="section-description">
            7:30 PM - 8:30 PM: Guided meditation and relaxation
          </p>
          <br />
          <strong className="section-description-title">
            Day 2: Connection with Nature
          </strong>
          <p className="section-description">
            7:00 AM - 8:00 AM: Morning yoga session
          </p>
          <p className="section-description">8:30 AM - 9:30 AM: Breakfast...</p>
        </div>

        <div className="venueIcon">
          <button
            className="modal-open-button"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("schedule")}
          >
            <SmallText text="Read more" />
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        </div>
      </div>
      <ModalComponent modalName="schedule" title="Sample Schedule">
        <div className="d-flex flex-column gap-3 ">
          <div>
            <strong className="section-description-title">
              Day 1: Arrival and Accommodation
            </strong>
            <p className="section-description">
              2:00 PM - 4:00 PM: Participant arrival and check-in
            </p>
            <p className="section-description">
              4:30 PM - 5:30 PM: Welcome session and retreat introduction
            </p>
            <p className="section-description">
              6:00 PM - 7:00 PM: Light dinner
            </p>
            <p className="section-description">
              7:30 PM - 8:30 PM: Guided meditation and relaxation
            </p>
            <br />
            <strong className="section-description-title">
              Day 2: Connection with Nature
            </strong>
            <p className="section-description">
              7:00 AM - 8:00 AM: Morning yoga session
            </p>
            <p className="section-description">
              8:30 AM - 9:30 AM: Breakfast...
            </p>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ScheduleDetail;
