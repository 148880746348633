import React from "react";
import ReservationListing from "../../../components/core/Admin/Reservations/ReservationsListing";

const AdminReservationList: React.FC = () => {
  return (
    <div>
      <ReservationListing />
    </div>
  );
};

export default AdminReservationList;
