/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//third party
import { Link } from "react-router-dom";
import { Form, Select } from "antd";
import { Pencil } from "lucide-react";
import PhoneInput from "react-phone-input-2";
import { RcFile } from "antd/es/upload";
import { AppDispatch, RootState } from "../../../../redux/store";

//component
import DeleteAccount from "./DeleteAccount";

//common component
import SmallText from "../../../UI/SmallText";
import Input from "../../../UI/InputElement/Input";
import FormBtn from "../../../UI/InputElement/FormButton";
import ImagePicker from "../../../UI/ImagePicker/ImagePicker";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setLatestUrl } from "../../../../redux/features/user-slice";

//api call
import {
  editDetailById,
  uploadImage,
  userDetailById,
  userProfileData,
} from "../../../../network";
//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//utils
import { ToastFail, ToastSuccess } from "../../../../utils/TostMessage";
import { countryOptions } from "../../../../constants";

// Firebase
import { updateUserDetailFirebase } from "../../../../utils/firebase";

//css
import "react-phone-input-2/lib/style.css";

const BasicDetail: React.FC = () => {
  const userSelector = useSelector((state: RootState) => state.user);

  const { call } = useApiCall();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<RcFile | null>(null);
  const [profileUrl, setProfileUrl] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();
  //set image
  const handleImage = (info: RcFile) => {
    setImageUrl(info);
  };

  //get detail of the user
  const getUserDetail = () => {
    userSelector.userProfile.user_type === "admin"
      ? call(
          () => userDetailById(userSelector.userProfile.user_id),
          (res) => {
            const {
              data: { data },
            } = res;
            setProfileUrl(data.profile_image);
            setFullName(data.full_name);
            setUserType(data.user_type);
            form.setFieldsValue(data);
            form.setFieldValue("website", data.social.website);
            form.setFieldValue("facebook", data.social.facebook);
            form.setFieldValue("instagram", data.social.instagram);
            form.setFieldValue("linkedin", data.social.linkedin);
          },
          (err) => {
            ToastFail(err.response.data.detail);
          }
        )
      : call(
          () => userProfileData(),
          (res) => {
            const {
              data: { data },
            } = res;
            setProfileUrl(data.profile_image);
            setFullName(data.full_name);
            setUserType(data.user_type);
            form.setFieldsValue(data);
            form.setFieldValue("website", data.social.website);
            form.setFieldValue("facebook", data.social.facebook);
            form.setFieldValue("instagram", data.social.instagram);
            form.setFieldValue("linkedin", data.social.linkedin);
          },
          (err) => {
            ToastFail(err.response.data.detail);
          }
        );
  };

  //upload an image
  const handleImageUpload = async (imageUrl: RcFile) => {
    const formData = new FormData();
    formData.append("files", imageUrl);
    try {
      const res = await uploadImage(formData);
      dispatch(setLatestUrl(res?.data?.data[0]));
      return res?.data?.data[0];
    } catch (err: any) {
      ToastFail(err?.response?.data?.detail);
    }
  };

  //edit user detail
  const editUserDetail = async () => {
    setIsLoading(true);
    if (imageUrl) {
      form.setFieldValue(
        "profile_picture_url",
        await handleImageUpload(imageUrl)
      );
    }
    form.validateFields().then((values) => {
      call(
        () =>
          editDetailById({
            ...values,
            social: {
              instagram: values.instagram,
              facebook: values.facebook,
              linkedin: values.linkedin,
              website: values.website,
            },
          }),
        (res) => {
          ToastSuccess(res?.data?.message);

          const updatedFullName = `${values.first_name} ${values.last_name}`;
          if (updatedFullName !== fullName) {
            // Update Full Name in Firebase
            updateUserDetailFirebase(userSelector.userProfile.user_id, {
              name: updatedFullName,
            });
          }

          getUserDetail();
          setIsLoading(false);
        },
        (err) => {
          ToastFail(err.message);
          setIsLoading(false);
        }
      );
    });
  };

  useEffect(() => {
    getUserDetail();
  }, []);
  return (
    <>
      <Form form={form} onFinish={editUserDetail} layout="vertical">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex bg-white p-4 gap-4 flex-column common-border">
            <div className="d-flex gap-5 mb-3 align-items-center">
              <div className="position-relative profile-upload">
                <Form.Item name="profile_picture_url">
                  <ImagePicker
                    handleUpload={handleImage}
                    preview={true}
                    uploadType="image"
                    imageUrl={profileUrl}
                  />
                </Form.Item>
                <div className="position-absolute  bg-white  photo-edit p-1 rounded-circle d-flex align-items-center justify-content-center">
                  <Pencil width={10} height={10} />
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <SmallText
                  text={fullName}
                  color="#000000"
                  lineHeight={37.6}
                  fontWeight={700}
                  fontSize={32}
                />
                <SmallText
                  text={userType
                    ?.split("_")
                    ?.map((item) => item[0]?.toUpperCase() + item.slice(1))
                    .join(" ")}
                  color="#787878"
                  lineHeight={18.8}
                  fontWeight={400}
                  fontSize={16}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex gap-4">
                <div className="flex-grow-1">
                  <SmallText
                    text="Business Name"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="business_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your business name",
                      },
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Input placeholder="enter name" />
                  </Form.Item>
                </div>
                <div className="flex-grow-1">
                  <SmallText
                    text="Job Title"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="job_title"
                    normalize={(value) => value.trimStart()}
                  >
                    <Input placeholder="enter title" />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex gap-4">
                <div className="flex-grow-1">
                  <SmallText
                    text="First name"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name",
                      },
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Input placeholder="enter name" />
                  </Form.Item>
                </div>
                <div className="flex-grow-1">
                  <SmallText
                    text="Last name"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name",
                      },
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Input placeholder="enter name" />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex gap-4">
                <div className="flex-grow-1 w-50">
                  <SmallText
                    text="E-mail"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      { type: "email", message: "Please enter a valid email" },
                    ]}
                  >
                    <Input placeholder="enter email" type="email" disabled />
                  </Form.Item>
                </div>
                <div className="flex-grow-1 d-flex flex-column gap-1 w-50">
                  <SmallText
                    text="Phone numbers"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number",
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"gb"}
                      enableAreaCodes={true}
                      specialLabel={""}
                      buttonClass={"custom-phone-button"}
                      dropdownClass={"custom-dropdown"}
                      searchClass={"search-field"}
                      placeholder={"enter phone number"}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
            <SmallText
              text="About You"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
            <div className="d-flex flex-column gap-1">
              <SmallText
                text="Enter Your Bio (Optional)"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <Form.Item name="bio">
                <textarea
                  rows={4}
                  className="form-control"
                  placeholder="enter here"
                ></textarea>
              </Form.Item>
            </div>
          </div>
          <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
            <SmallText
              text="Digital Sites"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
            <div className="d-flex gap-4">
              <div className="flex-grow-1">
                <SmallText
                  text="Website"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="website"
                  rules={[{ type: "url", message: "Please enter a valid URL" }]}
                >
                  <Input type="url" placeholder="add link" />
                </Form.Item>
              </div>
              <div className="flex-grow-1">
                <SmallText
                  text="Instagram"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="instagram"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL",
                      validator: (_, value) =>
                        value &&
                        !/^(https:\/\/)?(www\.)?instagram\.com/.test(value)
                          ? Promise.reject(
                              new Error("Please enter a valid Facebook URL")
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input type="url" placeholder="add link" />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex gap-4">
              <div className="flex-grow-1">
                <SmallText
                  text="Facebook"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="facebook"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL",
                      validator: (_, value) =>
                        value &&
                        !/^(https:\/\/)?(www\.)?facebook\.com/.test(value)
                          ? Promise.reject(
                              new Error("Please enter a valid Facebook URL")
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input type="url" placeholder="add link" />
                </Form.Item>
              </div>
              <div className="flex-grow-1">
                <SmallText
                  text="Linkedin"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="linkedin"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL",
                      validator: (_, value) =>
                        value &&
                        !/^(https?:\/\/)?(www\.)?linkedin\.com/.test(value)
                          ? Promise.reject(
                              new Error("Please enter a valid Facebook URL")
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input type="url" placeholder="add link" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
            <SmallText
              text="Business Address"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
            <div className="d-flex gap-3">
              <div className="flex-grow-1">
                <SmallText
                  text="Country"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="country"
                  rules={[
                    { required: true, message: "Please select a country" },
                  ]}
                >
                  <Select className="flex-grow-1" showSearch>
                    {countryOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="flex-grow-1">
                <SmallText
                  text="State"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="state"
                  rules={[{ required: true, message: "Please enter a state" }]}
                  normalize={(value) => value.trimStart()}
                >
                  <Input placeholder="select state " />
                </Form.Item>
              </div>
              <div className="flex-grow-1">
                <SmallText
                  text="City"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="city"
                  rules={[{ required: true, message: "Please enter a city" }]}
                  normalize={(value) => value.trimStart()}
                >
                  <Input placeholder="enter city" />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex gap-4">
              <div className="d-flex w-75 flex-column gap-1">
                <SmallText
                  text="Address"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="address"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                  normalize={(value) => value.trimStart()}
                >
                  <textarea
                    rows={1}
                    className="form-control"
                    placeholder="enter street address"
                  ></textarea>
                </Form.Item>
              </div>
              <div className="flex-grow-1">
                <SmallText
                  text="Postal Code"
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={500}
                  fontSize={14}
                />
                <Form.Item
                  name="zip_code"
                  rules={[
                    { required: true, message: "Please enter a ZIP code" },
                  ]}
                  normalize={(value) => value.trimStart()}
                >
                  <Input placeholder="enter postal code" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column bg-white p-4 gap-3 common-border">
            <SmallText
              text="Password"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
            <div className="d-flex gap-3">
              <div className="flex-grow-1 password-input-wrapper">
                <Link
                  to={process.env.REACT_APP_FORGOT_PASSWORD || ""}
                  target="_blank"
                >
                  <SmallText
                    text="Click here for the Change Password"
                    color="#2771A3"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                </Link>
              </div>
            </div>
          </div>
          <DeleteAccount />

          <div className="d-flex align-items-end w-100 justify-content-end pb-3">
            <FormBtn
              className="submit-btn"
              type="submit"
              text="Save"
              isLoading={isLoading}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default BasicDetail;
