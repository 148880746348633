import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./constants/routes";
import PrivateRoute from "./components/core/Authentication/PrivateRoute";

export interface RouteType {
  path: string;
  component: React.FC;
  children?: RouteType[];
  protected: boolean;
  role?: "admin" | "venue_manager" | "retreat_host" | "retreat_guest"; // Role required for the route
}

const AppRoutes: React.FC = () => {
  const renderRoutes = (routes: RouteType[]) => {
    return routes.map((route, index) => {
      const RouteElement = route.protected ? (
        <PrivateRoute role={route.role}>
          <route.component />
        </PrivateRoute>
      ) : (
        <route.component />
      );

      return (
        <Route key={`route-${index}`} path={route.path} element={RouteElement}>
          {route.children && renderRoutes(route.children)} {/* Recursively render child routes */}
        </Route>
      );
    });
  };

  return (
    <div className="app-container">
      <Routes>{renderRoutes(routes)}</Routes>
    </div>
  );
};

export default AppRoutes;
