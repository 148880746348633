// third party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReservationTableType } from "../../types/property-manager";

type ReservationSliceType = {
  data: ReservationTableType;
};

const initialState: ReservationSliceType = {
  data: {
    id: "",
    host: {
      id: "",
      name: "",
      profile_picture_url: "",
      city: "",
      user_type: "",
    },
    venue: {
      id: "",
      name: "",
      city: "",
      country: "",
      state: "",
      type_of_retreats: [],
      images: [],
    },
    check_in_date: "",
    check_out_date: "",
    group_size: 0,
    total_price: 0,
    add_on_services: {},
    special_request: "",
    desired_premium: {
      is_corporate_retreat: false,
      premium_per_guest: 0,
      premium_currency: "",
    },
    retreat_pricing: [],
    included_add_ons: {},
    guest_payment_plan: 0,
    is_share_option: false,
    guest_cancellation_policy: null,
    third_party_marketing_sites: [],
    early_bird_discount_details: {
      amount: 0,
      unit: "",
      early_bird_deadline_date: null,
    },
    discount_code_details: {
      code: "",
      unit: "",
      code_usage_limit: 0,
    },
    form_step: 1,
    status: "",
    is_draft: true,
    is_cancelled: false,
    is_deactivated: false,
    approval_date: "",
    reservation_id: "",
  },
};

export const ReservationSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setReservation: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data;
    },
  },
});

export const { setReservation } = ReservationSlice.actions;

export default ReservationSlice.reducer;
