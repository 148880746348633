import React from "react";

// custom component
import Sidebar from "../../UI/Sidebar";

// types
import { SidebarItem } from "../../../types";
import { images } from "../../../assets/images";

interface sidebarProps {
  dashboardItems: SidebarItem[];
  selected?: number;
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
}

const DashboardLayout: React.FC<sidebarProps> = ({
  dashboardItems,
  selected,
  children,
  footerContent,
}) => {
  return (
    <div className="d-flex flex-nowrap w-100">
      <div>
        <Sidebar
          footerContent={footerContent}
          items={dashboardItems}
          selected={selected || 1}
        />
      </div>
      <div className="d-flex flex-column w-100">
        <div className="venues-header d-flex justify-content-end align-items-center">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div>
              <button type="button" className="border-0 bg-white">
                <img src={images.HOME_BELL} alt="" height={"26px"} />
              </button>
            </div>
            <div>
              <button type="button" className="border-0 bg-white">
                <img src={images.GEAR_ICON} alt="" height={"26px"} />
              </button>
            </div>
          </div>
        </div>
        <div style={{ padding: "24px" }} className="d-flex flex-column w-100">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
