import React from "react";

// custom component
import UploadDocument from "./UploadDocument";

const UploadData: React.FC = () => {
  return (
    <div className="basic-detail  pb-4">
      <UploadDocument />
    </div>
  );
};

export default UploadData;
