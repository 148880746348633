/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// Third party
import { Spin } from "antd";
import { toast } from "react-toastify";

// custom component
import MainLayout from "../../../MainLayout";
import SpacesForm from "./SpacesForm";

//  redux

// network
import { fetchVenueAmenities } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import { VenueAmenities } from "../../../../../types/property-manager";

// styles
import "../../../../../assets/css/create-venue.css";

const CreateVenueFormStep3: React.FC<{
  venue_id: string;
  handleNextStep: (query?: string) => void;
  handlePreviousStep: () => void;
}> = ({ venue_id, handleNextStep, handlePreviousStep }) => {
  const { call } = useApiCall();
  const [venueAmenities, setVenueAmenities] = React.useState<VenueAmenities[]>(
    []
  );
  const [loading, setLoading] = React.useState(false);
  const fetchVenueAmenity = async () => {
    setLoading(true);
    call(
      () => fetchVenueAmenities(venue_id),
      (res) => {
        setVenueAmenities(res?.data?.data?.amenities);
        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch amenities"
        );
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchVenueAmenity();
  }, []);

  return loading ? (
    <Spin fullscreen />
  ) : (
    <div className="add-spaces">
      <MainLayout>
        <SpacesForm
          venueAmenities={venueAmenities}
          venueId={venue_id}
          fetchAmenityDetails={fetchVenueAmenity}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </MainLayout>
    </div>
  );
};

export default CreateVenueFormStep3;
