import React from "react";

// custom components
import ChatWindow from "./ChatWindow";
import MessageInput from "./MessageInput";

// types
import { MessageType, FirebaseUser } from "../../../types";

type ChatContentProps = {
  selectedUser: FirebaseUser | null;
  conversations: MessageType;
  handleSendMessage: (text: string) => void;
};

const ChatContent: React.FC<ChatContentProps> = ({
  selectedUser,
  conversations,
  handleSendMessage,
}) => (
  <div className="chat-content">
    {selectedUser ? (
      <>
        <ChatWindow selectedUser={selectedUser} messages={conversations} />
        <MessageInput onSend={handleSendMessage} />
      </>
    ) : (
      <div className="chat-placeholder">
        <h3>No user selected</h3>
      </div>
    )}
  </div>
);

export default ChatContent;
