import React from "react";
import Loader from "../../../components/UI/Loader";
import { useNavigate } from "react-router-dom";

const PaymentComplete: React.FC = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/venues/home");
  }, 2000);

  return <Loader />;
};

export default PaymentComplete;
