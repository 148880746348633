import React, { useState } from "react";

//third party
import { Select } from "antd";

//component
import PaymentDetail from "./PaymentDetail";
//common component
import CardSelect from "../../../../UI/SelectGroup/CardSelect";
import SmallText from "../../../../UI/SmallText";
import Input from "../../../../UI/InputElement/Input";
//constant
import { countryOptions } from "../../../../../constants";
import { paymentType } from "../../../../../constants/RetreatHost";
//images
import { images } from "../../../../../assets/images";

const CheckoutPayment = () => {
  const [cardValue, setCardValue] = useState<string>("");
  const handleCardValue = (value: string) => {
    setCardValue(value);
  };

  return (
    <div className="checkout-modal-content">
      <div className="checkout-section-detail d-flex flex-column gap-4">
        <div className="checkout-title">
          <img src={images.PAYMENT} alt="icon" width={28} height={28} />
          <SmallText
            text="Payment"
            color="#2F206A"
            lineHeight={21.15}
            fontSize={18}
            fontWeight={400}
          />
        </div>
        <div className="overflow-y-auto ">
          <form className="d-flex flex-column gap-3">
            <CardSelect card={paymentType} handleCard={handleCardValue} />
            <div className="d-flex flex-column gap-1">
              <Input
                label="Card number"
                placeholder="1234 1234 1234 1234"
                maxLength={19}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <Input label="Name on the card" maxLength={19} />
            </div>
            <div className="d-flex gap-3">
              <div className="d-flex flex-column gap-1">
                <Input label="CVV" placeholder="123" maxLength={32} />
              </div>
              <div className="d-flex flex-column gap-1">
                <Input label="Due date" placeholder="12/2024" type="month" />
              </div>
            </div>
            <div className="d-flex flex-column gap-1">
              <SmallText
                text="Country"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                placeholder="Select a country"
              >
                {countryOptions?.map((country, index) => (
                  <Select.Option value={`${country.value}`} key={index}>
                    <div>{country.label}</div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </form>
          {cardValue}
        </div>
      </div>
      <PaymentDetail />
    </div>
  );
};

export default CheckoutPayment;
