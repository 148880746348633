/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//third party
import { Form } from "antd";
import TimezoneSelect, { ITimezone } from "react-timezone-select";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

//common component
import SmallText from "../../../UI/SmallText";
import FormBtn from "../../../UI/InputElement/FormButton";

// redux
import { RootState } from "../../../../redux/store";

//api call
import {
  editDetailById,
  userDetailById,
  userProfileData,
} from "../../../../network";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//utils
import { ToastFail, ToastSuccess } from "../../../../utils/TostMessage";

const Preferences: React.FC = () => {
  const userSelector = useSelector((state: RootState) => state.user);
  const { call } = useApiCall();
  const [form] = Form.useForm();
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [autoDetect, setAutoDetect] = useState<boolean>(false);

  //handle checkbox change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoDetect(e.target.checked);
  };

  //edit detail
  const onFinish = () => {
    form.validateFields().then((values) => {
      call(
        () =>
          editDetailById({
            language: values.language,
            time_zone: values.time_zone,
            currency: values.currency,
          }),
        (res) => {
          ToastSuccess(res.data.message);
          getUserDetail();
        },
        (err) => {
          ToastFail(err.message);
        }
      );
    });
  };

  //get detail
  const getUserDetail = () => {
    userSelector.userProfile.user_type === "admin"
      ? call(
          () => userDetailById(userSelector.userProfile.user_id),
          (res) => {
            form.setFieldsValue(res.data.data);
            form.setFieldValue("time_zone", res.data.data.time_zone);
          },
          (err) => {
            ToastFail(err.response.data.detail);
          }
        )
      : call(
          () => userProfileData(),
          (res) => {
            form.setFieldsValue(res.data.data);
            form.setFieldValue("time_zone", res.data.data.time_zone);
          },
          (err) => {
            ToastFail(err.response.data.detail);
          }
        );
  };

  useEffect(() => {
    if (autoDetect) {
      const detectedTimezone = moment.tz.guess();
      setSelectedTimezone(detectedTimezone);
      form.setFieldsValue({ time_zone: detectedTimezone });
    }
  }, [autoDetect, form]);

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        language: "english",
        currency: "gbp",
      }}
    >
      <div className="d-flex flex-column gap-3">
        <div className="d-flex bg-white p-4 gap-4 flex-column common-border">
          <div className="d-flex flex-column gap-1">
            <SmallText
              text="Preferences"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
            <SmallText
              text="Enter & edit below"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </div>
          <div className="d-flex gap-3">
            <div className="d-flex flex-column gap-2 flex-grow-1 w-50">
              <SmallText
                text="Preferred Language"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <Form.Item name="language">
                <select className="form-select">
                  <option value="english">English, US</option>
                  <option value="french">French</option>
                </select>
              </Form.Item>
            </div>
            <div className="d-flex flex-column gap-2 flex-grow-1 w-50">
              <SmallText
                text="Time Zone"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <Form.Item name="time_zone">
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={(tz) => {
                    setSelectedTimezone(tz.value);
                    form.setFieldsValue({ time_zone: tz.value });
                  }}
                  isDisabled={autoDetect}
                  placeholder="select time zone"
                />
              </Form.Item>

              <Form.Item name="autoDetectTimezone" valuePropName="checked">
                <div className="d-flex gap-1 align-items-center timezone-check-box">
                  <input
                    type="checkbox"
                    className="form-check-input mt-0"
                    checked={autoDetect}
                    onChange={handleCheckboxChange}
                  />
                  <SmallText
                    text="Set time zone automatically"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={400}
                    fontSize={14}
                  />
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="d-flex bg-white p-4 gap-4 flex-column common-border">
          <div className="d-flex flex-column gap-1">
            <SmallText
              text="Currency"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
          </div>
          <div className="d-flex gap-3">
            <div className="d-flex flex-column gap-2 flex-grow-1">
              <SmallText
                text="Currency"
                color="#58585B"
                lineHeight={16.45}
                fontWeight={500}
                fontSize={14}
              />
              <Form.Item name="currency">
                <select className="form-select">
                  <option value="usd">US Dollar (USD)</option>
                  <option value="gbp">British Pound (GBP) </option>
                  <option value="eur">Euro (EUR) </option>
                </select>
              </Form.Item>
            </div>
          </div>
          <div>
          <SmallText
            text="Please note that FX conversion fees will be incurred for payouts in currencies that are not USD."
            color="#949397"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <FormBtn text="Save" className="submit-btn" type="submit" />
        </div>
      </div>
    </Form>
  );
};

export default Preferences;
