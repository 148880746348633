import React, { useState } from "react";

//third party
import { Input, Select } from "antd";
import { LogOut } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";

//common component
import SmallText from "../../../UI/SmallText";

// redux
import { setCancelReason } from "../../../../redux/features/cancelVenueReservation";
import { RootState } from "../../../../redux/store";

//types
import { FormFooterProps } from "../../../../types/retreat";

//constants
import { VENUE_RESERVATION_CANCEL_OPTION } from "../../../../constants/property-manager";
import { useNavigate } from "react-router-dom";

const SelectReason: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const cancelRoutSelector = useSelector(
    (state: RootState) => state.cancelVenue.lastRoute
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOther, setShowOther] = useState(false);
  const [reason, setReason] = useState("");

  const onChange = (value: string) => {
    if (value === "other") {
      setShowOther(true);
      setReason("");
    } else {
      setShowOther(false);
      setReason(value);
      dispatch(
        setCancelReason({
          field: "reason",
          value,
        })
      );
    }
  };

  return (
    <div className="d-flex flex-column bg-white p-5 select-reason step-box-shadow steps-gap">
      <div className="d-flex flex-column">
        <SmallText
          text="Why do you need to cancel?"
          color="#19191C"
          fontWeight={700}
          fontSize={32}
          lineHeight={37.6}
        />
        <div className="d-flex flex-column gap-2">
          <SmallText
            text="Please select a reason"
            color="#58585B"
            fontWeight={500}
            fontSize={14}
            lineHeight={16.45}
          />
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            options={VENUE_RESERVATION_CANCEL_OPTION}
          />
          {showOther && (
            <div className="d-flex flex-column gap-2">
              <SmallText
                text="Other reason"
                color="#58585B"
                fontWeight={500}
                fontSize={14}
                lineHeight={16.45}
              />
              <Input
                name="other"
                onChange={(e) => {
                  const value = e.target.value;

                  if (value.trim()) {
                    setReason(value);
                    dispatch(
                      setCancelReason({
                        field: "reason",
                        value: value,
                      })
                    );
                  } else {
                    setReason("");
                    dispatch(
                      setCancelReason({
                        field: "reason",
                        value: "",
                      })
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="exit-btn"
          onClick={() => navigate(cancelRoutSelector)}
        >
          <div className="d-flex align-items-center justify-content-center gap-1">
            <LogOut
              color="#2771A3"
              width={16}
              height={16}
              rotate={90}
              className="reverse-icon"
            />
            <SmallText
              text="Exit"
              color="#2771A3"
              fontWeight={500}
              fontSize={14}
              lineHeight={16.45}
            />
          </div>
        </button>
        <button
          className="submit-btn"
          style={{ width: "120px" }}
          onClick={handleNextStep}
          disabled={!reason}
        >
          continue
        </button>
      </div>
    </div>
  );
};

export default SelectReason;
