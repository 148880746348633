import React from "react";

// third party
import { Spin } from "antd";

// styles
import "./style.css";

const StatisticCard: React.FC<{
  title?: string;
  totalCount?: string;
  percentageChange?: React.ReactNode;
  color?: string;
  description?: string;
  changeDescription?: string;
  isAdmin?: boolean;
  loading?: boolean;
}> = ({
  title,
  totalCount,
  percentageChange,
  color,
  description,
  changeDescription,
  isAdmin,
  loading = false,
}) => {
  return (
    <div className="statistic-card-container">
      {loading ? (
        <div className="d-flex justify-content-center w-100 h-100 align-items-center">
          <Spin />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="common-sm-dark-text">{title}</div>

            {percentageChange && (
              <div
                className={`common-xs-light-text align-items-center d-flex gap-1 ${
                  isAdmin ? "d-flex gap-1" : ""
                }`}
              >
                <span style={{ color: color }}>{percentageChange}</span>{" "}
                {changeDescription}
              </div>
            )}
          </div>
          <div className="common-xl-medium-dark-text">{totalCount}</div>
          {description && (
            <div className="common-xs-light-text">{description}</div>
          )}
        </>
      )}
    </div>
  );
};

export default StatisticCard;
