import React from "react";
import { useDispatch } from "react-redux";

// redux store
import { setShowModal } from "../../../../redux/features/component-slice";

//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
import CardComponent from "../../../UI/PricingCard/CardComponent";

//constant
import { RetreatAmenitiesPackageIcon } from "../../../../constants/RetreatHost";
//image
import { images } from "../../../../assets/images";
const ExtraAddOn = () => {
  const dispatch = useDispatch();
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  return (
    <div className="d-flex flex-column gap-4 amenities-package">
      <p className="retreat-venue-details-title m-0">Add-on Services</p>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-wrap gap-3">
          {RetreatAmenitiesPackageIcon.map((value, index) => {
            return (
              <CardComponent
                key={index}
                title={value.title}
                description={value.descriptions}
                price={value.extraPrice}
                width="300px"
              />
            );
          })}
        </div>
        <div className="venueIcon">
          <button
            className="modal-open-button"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("add-on-detail")}
          >
            <SmallText text="See all detail" />
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        </div>
      </div>

      <ModalComponent modalName="add-on-detail" title="Overview">
        <div className="d-flex flex-column gap-3">
          {RetreatAmenitiesPackageIcon.map((value, index) => {
            return (
              <CardComponent
                key={index}
                title={value.title}
                description={value.descriptions}
                price={value.extraPrice}
                width="100%"
              />
            );
          })}
        </div>
      </ModalComponent>
    </div>
  );
};

export default ExtraAddOn;
