// Redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// APIs
import { userProfileData } from "../../network";

// Third Party
import { toast } from "react-toastify";

interface UserState {
  userProfile: {
    user_id: string;
    email: string;
    full_name: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    city: string;
    state: string;
    country: string;
    address: string;
    language: string;
    time_zone: string;
    currency: string;
    social: {
      website: string;
      instagram: string;
      facebook: string;
      linkedin: string;
    };
    bio: string;
    user_type: string;
    business_name: string;
    profile_image: string;
    zip_code: string;
    stripe_account_id: string;
    stripe_bank_account_id: string | null;
    profileApiPending: boolean;
  };
  latestUrl: string;
}

const initialState: UserState = {
  userProfile: {
    user_id: "",
    email: "",
    full_name: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    address: "",
    language: "",
    time_zone: "",
    currency: "",
    social: {
      website: "",
      instagram: "",
      facebook: "",
      linkedin: "",
    },
    bio: "",
    user_type: "",
    business_name: "",
    profile_image: "",
    zip_code: "",
    stripe_account_id: "",
    stripe_bank_account_id: null,
    profileApiPending: true,
  },
  latestUrl: "",
};

// Action to store the latest URL
export const setLatestUrl = createAsyncThunk(
  "user/setLatestUrl",
  async (url: string) => {
    return url; // Just return the URL to be stored
  }
);

// New async thunk that accepts a URL argument
export const fetchUserProfileFromUrl = createAsyncThunk(
  "user/fetchUserProfileFromUrl",
  async (url: string) => {
    const response = await fetch(url); // Make an API call to the passed URL
    const data = await response.json(); // Assume the response is JSON
    return { ...data, profileApiPending: false }; // Add the flag for pending state
  }
);

export const fetchUserProfileThunk = createAsyncThunk(
  "userProfile",
  async () => {
    const res = await userProfileData();
    return { ...res.data.data, profileApiPending: false };
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLatestUrl.fulfilled, (state, action) => {
      state.userProfile.profile_image = action.payload; // Update the latest URL in the state
    });
    builder.addCase(fetchUserProfileThunk.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(fetchUserProfileThunk.rejected, (state) => {
      const pathName = window.location.pathname;
      pathName !== "/auth" && toast.error("Failed to fetch user profile");
    });
  },
});
export const { setUserProfile } = userSlice.actions;
export default userSlice.reducer;
