import React, { useState } from "react";

//common component
import SmallText from "../SmallText";

//images
import { images } from "../../../assets/images";
//css
import "./index.css";
type ImageNameType = keyof typeof images;
type StepFormAccordianProps = {
  title: string;
  icon_id?: string;
  children: React.ReactNode;
  atStart: boolean;
  IsAdd: boolean;
  ImageName: ImageNameType;
  handleModal?: () => void;
  isCheckBox?: boolean;
  optional ?: string;
};
const StepFormAccordian: React.FC<StepFormAccordianProps> = ({
  title,
  icon_id,
  children,
  atStart,
  IsAdd,
  ImageName,
  handleModal,
  isCheckBox,
  optional
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(atStart);
  return (
    <div className="step-form-accordian d-flex flex-column gap-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex algin-items-center justify-content-center gap-2 ">
          <div>
            <img src={images[ImageName]} alt="icon" width={28} height={28} />
          </div>
          <div className="d-flex align-items-center gap-2">
            <SmallText
              text={title}
              fontWeight={400}
              fontSize={18}
              lineHeight={21.15}
              color="#2F206A"
            />
             <SmallText
              text={optional || ""}
              fontWeight={400}
              fontSize={16}
              lineHeight={18.8}
              color="#949397"
            />
          </div>

          <div className="align-self-center">
            <img src={images.ToolTip} alt="icon" width={13} height={13} />
          </div>
        </div>
        {IsAdd ? (
          <div onClick={handleModal}>
            <img src={images.PLUS} alt="plus" width={24} height={24} />
          </div>
        ) : isCheckBox ? (
          <div className="form-check form-switch">
            <input type="checkbox" className="form-check-input" 
            defaultChecked={isOpen} onClick={() => setIsOpen(!isOpen)}/>
          </div>
        ) : (
          <div
            className={`img-container ${isOpen ? "active" : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={images.UP} alt="up" className="img-fluid" />
          </div>
        )}
      </div>
      <div className={` ${isOpen ? "d-block" : "d-none"}`}>{children}</div>
    </div>
  );
};

export default StepFormAccordian;
