import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//component
import Header from "../../Header";
import VenueDetailHeader from "./VenueDetailHeader";
import MainLayout from "../../MainLayout";
import VenuePageTitle from "./VenuePageTitle";
import VenueImages from "./VenueImages";
import AboutVenue from "./AboutVenue";
import SectionDivide from "../../../UI/Divider/SectionDivide";
import AccommodationPackage from "./AccommodationPackage";
import NonRefundPolicy from "./NonRefundPolicy";
import RetreatAmenitiesPackage from "./RetreatAmenitiesPackage";
import RetreatLocation from "./RetreatLocation";
import AboutRetreatHost from "./AboutRetreatHost";
import InstructorDetail from "./InstructorDetail";
import RetreatFAQ from "./RetreatFAQ";
import ExtraAddOn from "./ExtraAddOn";
import AboutTheRetreatVenue from "./AboutTheRetreatVenue";

//common component
import Loader from "../../../UI/Loader";
import { ToastFail } from "../../../../utils/TostMessage";

//static data
import { fetchRetreatVenue, fetchVenue } from "../../../../network";

//use API call
import { useApiCall } from "../../../../hooks/useApiCall";

//redux store
import { RootState } from "../../../../redux/store";
import {
  setPropertyId,
  setRetreatPropertyDetail,
  setRetreatVenueDetail,
} from "../../../../redux/features/retreatVenue";

//css
import "../../../../assets/css/header.css";
import "../../../../assets/css/hostVenueDetail.css";

const VenueDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  let { retreatId } = useParams();
  const { retreatVenueDetail, retreatPropertyDetail, propertyId } = useSelector(
    (state: RootState) => state.retreatVenue
  );

  const getRetreatVenue = async () => {
    call(
      () => fetchRetreatVenue(retreatId!),
      (res) => {
        dispatch(setRetreatVenueDetail(res.data.data));
        dispatch(setPropertyId(res.data.data.venue_id));
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };
  const getVenueDetail = async () => {
    call(
      () => fetchVenue(propertyId),
      (res) => {
        dispatch(setRetreatPropertyDetail(res.data.data));
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };
  useEffect(() => {
    getRetreatVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (propertyId) {
      getVenueDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  return (
    <>
      {retreatVenueDetail && retreatPropertyDetail ? (
        <>
          <Header>
            <VenueDetailHeader />
          </Header>
          <MainLayout>
            <VenuePageTitle
              title={retreatVenueDetail.title}
              city={retreatPropertyDetail?.city}
              country={retreatPropertyDetail?.country}
            />
            <VenueImages />
            <div className="d-flex flex-column gap-4">
              <AboutVenue />
              <SectionDivide />
              <AccommodationPackage />
              <SectionDivide />
              <NonRefundPolicy />
              <SectionDivide />
              <RetreatAmenitiesPackage />
              <SectionDivide />
              <ExtraAddOn />
              <SectionDivide />
              <AboutTheRetreatVenue />
              <SectionDivide />
              <RetreatLocation />
              <SectionDivide />
              <AboutRetreatHost />
              <SectionDivide />
              <InstructorDetail />
              <SectionDivide />
              <RetreatFAQ />
            </div>
          </MainLayout>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};

export default VenueDetail;
