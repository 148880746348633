import React from "react";

//component
import VenueDetail from "../../../components/core/RetreatHost/VenueDetail";

const VenueDetailPage : React.FC = () => {
  return <VenueDetail />;
};

export default VenueDetailPage;
