import React from "react";

// Component
import { LogoutComponent } from "../../components/core/Authentication/Logout";

const Logout: React.FC = () => {
  return <LogoutComponent />;
};

export default Logout;
