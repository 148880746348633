import React from "react";
import VenueDetail from "../../../components/core/Admin/Venues/VenueDetail";

const AdminVenueDetail: React.FC = () => {
    return (
        <div>
            <VenueDetail />
        </div>
    );
};

export default AdminVenueDetail;
