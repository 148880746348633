/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

// Third Party
import { ConfigProvider, Input, Tabs, TabsProps } from "antd";
import { debounce } from "lodash";
import { toast } from "react-toastify";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";
import ReservationListingTable from "./ReservationsListingTable";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { adminReservationList } from "../../../../network";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Utility
import { convertToTitleCase } from "../../../../library";

// Constants
import { ReservationQueryParams } from "../../../../types/admin";
import { RESERVATIONS_FILTER_TAB_LIST } from "../../../../constants/Admin";

// images
import { images } from "../../../../assets/images";

const ReservationListing: React.FC = () => {
  const { call } = useApiCall();

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  // NOTE : This is the total number of items
  // NOTE : Static as currently it is not provided from the backend
  const initialLimitValue = 6;

  const [reservations, setReservations] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [queryParams, setQueryParams] = useState<ReservationQueryParams>({
    limit: initialLimitValue,
    offset: 0,
    search: "",
    status: "",
  });

  const [loading, setLoading] = useState(true);

  const reservationFilterTabs: TabsProps["items"] = [
    { key: "", label: "All" },
    {
      key: RESERVATIONS_FILTER_TAB_LIST.APPROVED.key,
      label: RESERVATIONS_FILTER_TAB_LIST.APPROVED.label,
    },
    { key: "Completed", label: "Completed" },
    {
      key: RESERVATIONS_FILTER_TAB_LIST.REJECTED.key,
      label: RESERVATIONS_FILTER_TAB_LIST.REJECTED.label,
    },
    {
      key: RESERVATIONS_FILTER_TAB_LIST.PENDING.key,
      label: RESERVATIONS_FILTER_TAB_LIST.PENDING.label,
    },
  ];

  // APIs
  const getReservationsData = async () => {
    setLoading(true);
    call(
      () => adminReservationList({ queryParamsObj: queryParams }),
      (res) => {
        setReservations(res.data.data);
        setTotalItemCount(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      }
    );
  };

  // Handle search with debounce
  const handleSearch = useCallback(
    debounce((value: string) => {
      setQueryParams((prev: any) => ({
        ...prev,
        search: value,
        limit: initialLimitValue,
        offset: 0,
      }));
      setCurrentPage(1);
    }, 1000),
    []
  );
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setQueryParams((prev) => ({
      ...prev,
      offset: (page - 1) * initialLimitValue,
    }));
  };

  // Handle tab change
  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName);

    if (tabName === "Completed") {
      setQueryParams((prev: any) => ({
        limit: initialLimitValue,
        offset: 0,
        is_completed: true,
        search: prev?.search || "",
      }));
    } else {
      setQueryParams((prev: any) => ({
        status: tabName,
        limit: initialLimitValue,
        offset: 0,
        search: prev?.search || "",
      }));
    }

    setCurrentPage(1);
  };

  useEffect(() => {
    getReservationsData();
  }, [queryParams]);

  return (
    <AdminDashboardLayout
      pageTitle={`Welcome,  ${convertToTitleCase(
        userProfileData.first_name
      )} 👋`}
      additionalClassNames="mt-4"
    >
      <div className="payouts user-home-table admin-users bg-white">
        <div className="d-flex gap-3 justify-content-between">
          <div className="common-xl-medium-dark-text">Reservation</div>
          <div className="d-flex gap-2 justify-content-end ">
            <div>
              <Input
                placeholder="Search"
                prefix={<img src={images.SEARCH_ICON} height={"24px"} alt="" />}
                className="common-sm-dark-text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <ConfigProvider>
          <Tabs
            defaultActiveKey={selectedTab}
            items={reservationFilterTabs}
            onChange={handleTabChange}
          />
        </ConfigProvider>

        <ReservationListingTable
          retreatData={reservations}
          totalItems={totalItemCount}
          currentPage={currentPage}
          pageSize={initialLimitValue}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </div>
    </AdminDashboardLayout>
  );
};

export default ReservationListing;
