import { useEffect, useRef, useState } from "react";

//third party
import { Loader, LoaderOptions } from "@googlemaps/js-api-loader";
import axios from "axios";

interface IMapComponentProps {
  apiKey: string;
  postalCodeName: string;
}

const MapComponent: React.FC<IMapComponentProps> = ({
  apiKey,
  postalCodeName,
}) => {
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [isLocationUpdated, setIsLocationUpdated] = useState<boolean>(false);
  const mapRef = useRef<HTMLDivElement | null>(null);

  const getPostalCodeDetails = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCodeName}&key=${apiKey}`
      );

      if (response.data.results.length > 0) {
        const result = response.data.results[0];
        const { lat, lng } = result.geometry.location;
        setLocation({ lat, lng });
        setIsLocationUpdated(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isLocationUpdated && mapRef.current) {
      const loaderOptions: LoaderOptions = {
        apiKey,
        version: "weekly",
      };

      const loader = new Loader(loaderOptions);
      loader.load().then((google) => {
        if (mapRef?.current) {
          const map = new google.maps.Map(mapRef.current, {
            center: location,
            zoom: 8,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
          });

          new google.maps.Marker({
            position: location,
            map,
          });
        }
      });
    }
  }, [isLocationUpdated, location, apiKey]);

  useEffect(() => {
    if (postalCodeName) {
      getPostalCodeDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCodeName]);

  return (
    <div ref={mapRef} id="map" style={{ height: "296px", width: "100%" }}></div>
  );
};

export default MapComponent;
