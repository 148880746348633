import React, { useState } from "react";

// third party
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// custom component
import Button from "../../../UI/Button";
import ModalComponent from "../../../UI/ModalComponent";

// redux
import { RootState } from "../../../../redux/store";
import { setReservation } from "../../../../redux/features/reservationSlice";
import { setLastRoute } from "../../../../redux/features/cancelVenueReservation";
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { approveReservationRequest } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  MessageUserReservationDetails,
  UserDetailsAddOnService,
} from "../../../../types";

// images
import { demo_images } from "../../../../assets/images/demo-images";
import { images } from "../../../../assets/images";

const UpcomingReservationDetailModal: React.FC<{
  reservationDetails?: any;
  reservationPage?: boolean;
  isAdmin?: boolean;
  handleReservation?: () => void;
}> = ({ reservationDetails, reservationPage, isAdmin, handleReservation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { call } = useApiCall();
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const check_in_date = new Date(reservationDetails?.check_in_date);
  const check_out_date = new Date(reservationDetails?.check_out_date);

  const handleApproveDeclineReservation = (type: string) => {
    setSubmitLoading(true);
    call(
      () =>
        approveReservationRequest({
          reservationId: reservationDetails?.id,
          data: { status: type },
        }),
      (res) => {
        setSubmitLoading(false);
        dispatch(
          setShowModal({ modalName: "reservation-details", visible: false })
        );

        dispatch(
          setShowModal({
            modalName: "reservation-success-modal",
            visible: true,
          })
        );
        handleReservation && handleReservation();
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to approve request"
        );
        setSubmitLoading(false);
      }
    );
  };

  const calculateAddOnPrice = (
    service: UserDetailsAddOnService,
    checkInDate: Date,
    checkOutDate: Date,
    groupSize?: number
  ): number | "Free" => {
    const daysDiff = Math.max(
      0,
      checkOutDate.getDate() - checkInDate.getDate()
    );

    switch (service.service_type) {
      case "Per day":
        return service.charges * daysDiff;
      case "Per guest":
        return service.charges * (service.tickets || 1);
      case "Per group":
        return service.charges * groupSize!;
      default:
        return "Free";
    }
  };

  const renderAddOnPrice = (
    key: string,
    reservationDetails: MessageUserReservationDetails,
    checkInDate: Date,
    checkOutDate: Date
  ): string => {
    const service = reservationDetails.add_on_services![key];
    if (!service) return "N/A"; // Handle case where service doesn't exist

    const price = calculateAddOnPrice(
      service,
      checkInDate,
      checkOutDate,
      reservationDetails.group_size
    );

    return typeof price === "number" ? `$${price.toFixed(2)}` : price;
  };

  return (
    <div className="upcoming-reservation-detail-modal">
      <div className="title d-flex gap-2 align-items-center">
        <img
          src={
            (reservationDetails?.venue?.images.length &&
              reservationDetails?.venue?.images[0]) ||
            demo_images.DEMO_IMAGE_2
          }
          alt=""
          height={"40px"}
          width={"40px"}
        />
        <div>
          <p className="common-md-bold-dark-text">
            {reservationDetails?.venue?.name}
          </p>
          <span className="common-xs-light-text">
            {reservationDetails?.venue?.city}
            {", " + reservationDetails?.venue?.country}
          </span>
        </div>
      </div>
      <div className="upcoming-reservation-detail">
        <div className="about-retreat-container">
          <div className="common-sm-medium-dark-text mb-3">
            About Reservation
          </div>
          <div className="about-retreat d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.PERSON_ICON_BLACK} alt="" height={"16px"} />
              Retreat Host
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {reservationDetails?.host?.name || "-"}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.USERS_THREE} alt="" height={"16px"} />
              <span>Group Size</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {reservationDetails?.group_size}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
              <span>Retreat Dates</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {dayjs(check_in_date).format("MMM DD")} -{" "}
              {dayjs(check_out_date).format("DD")},{" "}
              {dayjs(check_in_date).format("YYYY")}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.RESERVATION_TAG} alt="" height={"16px"} />
              <span>Reservation Number</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {reservationDetails?.reservation_id}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              <img src={images.CALENDAR_CHECK} alt="" height={"16px"} />
              <span>Approval Date</span>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              {dayjs(reservationDetails?.created_at).format("MMM DD, YYYY")}
            </div>
          </div>
        </div>
        {Object.keys(reservationDetails?.add_on_services || {}).length > 0 && (
          <div className="add-on-service-container">
            <div className="common-sm-medium-dark-text mb-3">Add-ons</div>
            <table className="booked-venue-detail-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(reservationDetails?.add_on_services || {})
                  .length ? (
                  Object.keys(reservationDetails.add_on_services).map((key) => {
                    const icon = icons?.find(
                      (icon) =>
                        icon.id ===
                        reservationDetails.add_on_services[key].icon_id
                    );
                    return (
                      <tr>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={icon?.icon_url} alt="" />
                            {reservationDetails.add_on_services[key].name}
                          </div>
                        </td>
                        <td>
                          <span>
                            {reservationDetails.add_on_services[key].charges}
                          </span>
                        </td>
                        <td>
                          <span>
                            {renderAddOnPrice(
                              key,
                              reservationDetails,
                              check_in_date,
                              check_out_date
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan={3}>No Add-on Data</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        )}

        {reservationDetails?.special_request && (
          <div className="add-on-service-container">
            <div className="common-sm-medium-dark-text mb-3">
              Special requests
            </div>
            <div className="common-sm-light-grey-text">
              {reservationDetails?.special_request}
            </div>
          </div>
        )}

        {/* TODO : Static data as below details are not available in API */}
        {/* <div className="payment-details about-retreat-container">
          <div className=" common-sm-medium-dark-text mb-3">
            Payment Details
          </div>
          <div className=" d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              Accommodation
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              $ 12,260
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              Add-on-Services
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              $ {totalAddOnsPrice}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              Taxes
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              $ 120
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              Discount Code Applied
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              -$ {reservationDetails?.discount_code_details?.amount}
            </div>
          </div>
          <div className="d-flex ">
            <div className="about-retreat-title common-sm-light-grey-text">
              LAIGO Service Fee
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              -$ 1,023.50
            </div>
          </div>
          <div className="d-flex">
            <div className="about-retreat-title common-sm-bold-dark-text">
              Total
            </div>
            <div className="about-retreat-data common-sm-bold-dark-text">
              $ {reservationDetails?.total_price}
            </div>
          </div>
        </div> */}

        <div className="add-on-service-container">
          <div className="common-sm-medium-dark-text mb-3">
            Cancellation Policy
          </div>
          <div className="common-sm-light-grey-text">
            {/* TODO : Static data as below details are not available in API */}
            No data
          </div>
        </div>
      </div>
      {!isAdmin && (
        <div>
          <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
            {reservationDetails?.status !== "Rejected" ? (
              !reservationPage && reservationDetails?.status === "Pending" ? (
                <div className="d-flex gap-2">
                  <Button
                    type="button"
                    label="Decline"
                    className="bordered-button"
                    onClick={() => handleApproveDeclineReservation("reject")}
                    disabled={submitLoading}
                  />
                  <Button
                    type="button"
                    label={"Approve"}
                    onClick={() => handleApproveDeclineReservation("approve")}
                    disabled={submitLoading}
                  />
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <Button
                    type="button"
                    className="bordered-button"
                    label={"Cancel Reservation"}
                    onClick={() => {
                      dispatch(setReservation({ data: reservationDetails }));
                      dispatch(setLastRoute("/venues/home"));
                      navigate(`/venues/cancel`);
                      dispatch(
                        setShowModal({
                          modalName: "reservation-details",
                          visible: false,
                        })
                      );
                    }}
                    disabled={submitLoading}
                  />
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <ModalComponent
        modalName={"reservation-success-modal"}
        className="reservation-success-modal"
      >
        <div className="reservation-success-container">
          <div className="d-flex flex-column">
            <img src={images.TICK} alt="" height={96} width={96} />
            <p className="title common-xl-bold-dark-text p-0">
              Reservation Approved
            </p>
          </div>
          <div>
            <p className="description common-sm-light-grey-text text-center">
              Congratulations! <br />
              Your reservation is now approved, and the retreat host will be
              notified.
            </p>
          </div>
          <div className="dashboard-button">
            <Button
              type="button"
              label="Go to Dashboard"
              onClick={() =>
                dispatch(
                  setShowModal({
                    modalName: "reservation-success-modal",
                    visible: false,
                  })
                )
              }
            />
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default UpcomingReservationDetailModal;
