import React, { useEffect, useState } from "react";
//third party
import { useDispatch, useSelector } from "react-redux";

//component
import VenueCard from "./VenueCard";
//common component
import Loader from "../../../UI/Loader";
//redux store
import { setVenues } from "../../../../redux/features/venue-slice";
import { RootState } from "../../../../redux/store";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//api call
import { fetchVenues, highlightedVenue } from "../../../../network";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

const AvailableVenue: React.FC = () => {
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const { properties } = useSelector((state: RootState) => state.allVenue);
  const queryParams = useSelector(
    (state: RootState) => state.filteredVenue.queryParams
  );

  const getAllVenue = () => {
    setLoading(true);
    call(
      () => fetchVenues({}),
      (res) => {
        dispatch(setVenues(res.data.data));
      },
      (err) => {
        ToastFail(err.message);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    getAllVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      setLoading(true);
      dispatch(setVenues([]));
      call(
        () => highlightedVenue(queryParams),
        (res) => {
          dispatch(setVenues(res.data.data));
        },
        (err) => {
          ToastFail(err.message);
        }
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : !loading && properties.length > 0 ? (
        <VenueCard properties={properties} />
      ) : (
        <div className="text-center">Venues are not found!</div>
      )}
    </>
  );
};

export default AvailableVenue;
