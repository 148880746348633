import React from "react";

//common component
import SmallText from "../../../UI/SmallText";

//constant
import { AccommodationImage } from "../../../../constants/RetreatHost";

const AccommodationPackage: React.FC = () => {
  return (
    <div className="d-flex flex-column gap-4 accommodation">
      <p className="retreat-venue-details-title">Accommodation Package</p>
      <div
        className="d-flex gap-4 flex-xl-nowrap flex-md-wrap 
      f flex-sm-wrap flex-wrap flex-lg-nowrap"
      >
        {AccommodationImage.map((item, index) => (
          <div className="card  flex-grow-1 gap-2" key={index}>
            <div className="position-relative img-container">
              <img
                src={item.image}
                className="img-fluid d-block h-100 w-100 rounded"
                alt="..."
              />
              <div id="overlay"></div>
            </div>

            <div className="card-body gap-1">
              <h5 className=" section-description-title m-0">{item.title}</h5>
              <p className="section-description">{item.description}</p>
            </div>
            <div className="d-flex gap-2">
              <span>
                <SmallText
                  text={item.discountPrice}
                  fontSize={20}
                  lineHeight={23.5}
                  color="#19191C"
                  fontWeight={500}
                ></SmallText>
              </span>

              <del>
                <SmallText
                  text={item.actualPrice}
                  fontSize={16}
                  lineHeight={18.8}
                  color="#949397"
                  fontWeight={400}
                ></SmallText>
              </del>
            </div>
            <div>
              <button className="accommodation-btn w-100">Select this package</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccommodationPackage;
