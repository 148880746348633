import React, { useState } from "react";

// third party
import { Input, Select, DatePicker, Space, Radio } from "antd";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// custom component
import FormLabel from "../../../UI/FormLabel";
import Button from "../../../UI/Button";
import SmallText from "../../../UI/SmallText";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import {
  createDiscount,
  deleteDiscountCode,
  updateDiscountCode,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  DiscountFormValues,
  FetchedDiscountType,
} from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const CreateDiscountModal: React.FC<{
  setDiscountForm?: (formValues: DiscountFormValues[]) => void;
  discountFormValues: DiscountFormValues[];
  prefilledValues?: FetchedDiscountType;
  edit?: boolean;
  venueId?: string;
  fetchDiscountCodes?: () => void;
}> = ({
  setDiscountForm,
  discountFormValues,
  prefilledValues,
  edit = false,
  venueId,
  fetchDiscountCodes,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    trigger,

    formState: { errors, isValid },
  } = useForm<DiscountFormValues>({
    defaultValues: {
      access: prefilledValues?.isPublic || true,
      discountAmount: prefilledValues?.discountValue || null,
      expiryDate: prefilledValues?.expiryDate || "",
      discountCode: prefilledValues?.discountCode || "",
      discountType: prefilledValues?.discountType || "",
    },
  });

  const { call } = useApiCall();

  const [discountValueType, setDiscountValueType] =
    useState<string>("percentage");

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onFinish = (values: DiscountFormValues) => {
    setSubmitting(true);
    const dataToSend = {
      discount_code: values.discountCode,
      discount_type: values.discountType,
      discount_for: "Venue",
      discount_value: values.discountAmount,
      expiry_date: values.expiryDate,
      is_public: values.access,
    };

    if (!edit) {
      call(
        () =>
          createDiscount({
            venueId: venueId as string,
            data: dataToSend,
          }),
        (res) => {
          reset();
          dispatch(
            setShowModal({ modalName: "discountModal", visible: false })
          );
          fetchDiscountCodes!();
          toast.success(res?.data?.message);
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to add discount code"
          );
        }
      ).finally(() => setSubmitting(false));
    } else {
      call(
        () =>
          updateDiscountCode({
            discountCodeId: prefilledValues?.id as string,
            data: dataToSend,
          }),
        (res) => {
          dispatch(
            setShowModal({
              modalName: `edit-discount-${prefilledValues?.id}`,
              visible: false,
            })
          );
          toast.success(res?.data?.message);
          fetchDiscountCodes && fetchDiscountCodes();
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to update discount"
          );
        }
      ).finally(() => setSubmitting(false));
    }
  };

  const handleDeleteDiscount = async () => {
    setSubmitting(true);
    call(
      () =>
        deleteDiscountCode({ discountCodeId: prefilledValues?.id as string }),
      (res) => {
        dispatch(
          setShowModal({
            modalName: `edit-discount-${prefilledValues?.id}`,
            visible: false,
          })
        );
        fetchDiscountCodes && fetchDiscountCodes();
        toast.success(res?.data?.message);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to delete discount"
        );
      }
    ).finally(() => setSubmitting(false));
  };

  return (
    <div className="room-modal-info-container add-taxes-form">
      <div className="room-modal-subtitle">
        Enter all the necessary discount details
      </div>
      <form
        className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="form-white-bg w-100">
          <div className="form-group accommodation-tax">
            <Controller
              name="access"
              defaultValue={prefilledValues?.isPublic || true}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="horizontal" className="w-100">
                    <Radio value={true}>Public discount</Radio>
                    <Radio value={false}>Private discount</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
            {errors.access && (
              <span className="error">{errors.access.message}</span>
            )}
          </div>
          <div className="form-group">
            <FormLabel label="Discount Code" />
            <Controller
              name="discountCode"
              defaultValue={prefilledValues?.discountCode || ""}
              rules={{
                required: "Please provide the discount code.",
                validate: (value) => {
                  if (value?.trim() === "") {
                    return "Please provide the discount code.";
                  }
                },
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  type="text"
                  className="input-component"
                  min={0}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    trigger("discountCode");
                  }}
                />
              )}
            />
            {errors.discountCode && (
              <span className="error">{errors.discountCode?.message}</span>
            )}
          </div>
          <div className="form-group d-flex flex-row gap-1">
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-center gap-2 mb-1">
                <FormLabel label="Discounted Value" />
              </div>
              <Controller
                name="discountType"
                control={control}
                defaultValue={
                  prefilledValues?.discountType || discountValueType
                }
                rules={{
                  required: "Please select the discounted value type.",
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    className="w-100"
                    value={value}
                    defaultValue={"Percentage"}
                    onChange={(value) => {
                      onChange(value);
                      setDiscountValueType(value);
                      trigger("discountType");
                    }}
                    options={[
                      {
                        value: "Percentage",
                        label: "Percentage",
                      },
                      { value: "Amount", label: "Amount" },
                    ]}
                  />
                )}
              />
              {errors.discountType && (
                <span className="error">{errors.discountType?.message}</span>
              )}
            </div>
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-center gap-2 mb-1">
                <FormLabel label="Tax amount" />
              </div>
              <Controller
                name="discountAmount"
                defaultValue={prefilledValues?.discountValue || null}
                control={control}
                rules={{
                  required: "Please provide the discount amount.",
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="number"
                    prefix={discountValueType === "Amount" ? "$" : ""}
                    suffix={discountValueType === "Percentage" ? "%" : ""}
                    className="input-component"
                    min={0}
                    onChange={(value) => {
                      onChange(value);
                      trigger("discountAmount");
                    }}
                    value={value?.toString()}
                  />
                )}
              />
              {errors.discountAmount && (
                <span className="error">{errors.discountAmount?.message}</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <FormLabel label="Expiration Date" />
            <Controller
              name="expiryDate"
              control={control}
              defaultValue={prefilledValues?.expiryDate || ""}
              rules={{
                required: "Please provide the expiration date.",
              }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? dayjs(value) : null}
                  onChange={(date) => {
                    onChange(date ? date.format("YYYY-MM-DD") : "");
                    trigger("expiryDate");
                  }}
                  disabledDate={(date) => date.isBefore(dayjs())}
                  showNow={false}
                />
              )}
            />
            {errors.expiryDate && (
              <span className="error">{errors.expiryDate?.message}</span>
            )}
          </div>
        </div>

        <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-between">
          {edit && (
            <div>
              <button
                disabled={submitting}
                type="button"
                className="bordered-button border-0 bg-transparent p-0 d-flex align-items-center gap-2 "
                style={{ width: "max-content" }}
                onClick={handleDeleteDiscount}
              >
                <img src={images.TRASH_RED} alt="" height={16} />
                <SmallText text="Delete Discount" color="#F63030" />
              </button>
            </div>
          )}
          <div className="d-flex gap-3 w-100 align-items-center justify-content-end">
            <div>
              <Button
                type="button"
                label="Cancel"
                className="bordered-button"
                onClick={() => {
                  !edit &&
                    reset({
                      discountCode: "",
                      discountType: "percentage",
                      discountAmount: null,
                      expiryDate: "",
                    });
                  dispatch(
                    setShowModal({
                      modalName: !edit
                        ? "discountModal"
                        : `edit-discount-${prefilledValues?.id}`,
                      visible: false,
                    })
                  );
                }}
              />
            </div>
            <div>
              <Button
                type="submit"
                label={"Save"}
                disabled={!isValid || submitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateDiscountModal;
