import React from "react";

// Components
import SmallText from "../../../../UI/SmallText";
import Input from "../../../../UI/InputElement/Input";

const GlobalPreferencesTabContent = ({ userData }: { userData: any }) => {
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex bg-white p-4 gap-4 flex-column common-border">
        <div className="d-flex flex-column gap-1">
          <SmallText
            text="Language"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <SmallText
            text="Language and time zone preferences"
            color="#58585B"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
          />
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-column gap-2 flex-grow-1">
            <Input
              label="Language"
              disabled={true}
              value={userData?.language}
            />
          </div>
          <div className="d-flex flex-column gap-2 flex-grow-1">
            <Input
              label="TIme Zone"
              disabled={true}
              value={userData?.time_zone}
            />
          </div>
        </div>
      </div>
      <div className="d-flex bg-white p-4 gap-4 flex-column common-border">
        <div className="d-flex flex-column gap-1">
          <SmallText
            text="Currency"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <SmallText
            text="Preferred currency"
            color="#58585B"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
          />
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-column gap-2 flex-grow-1">
            <Input
              label="Currency"
              disabled={true}
              value={userData?.currency.toUpperCase()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPreferencesTabContent;
