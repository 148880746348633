/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Dropdown, MenuProps } from "antd";

// custom component
import ModalComponent from "../../../UI/ModalComponent";
import Loader from "../../../UI/Loader";
import RoomInfoModal from "../CreateVenue/CreateVenueFormStep2/RoomInfoModal";
import CustomizeInfoModal from "../CreateVenue/CreateVenueFormStep2/CustomizeInfoModal";
import EditRoomInfo from "../CreateVenue/CreateVenueFormStep2/EditRoomInfo";
import EditCommonSpace from "../CreateVenue/CreateVenueFormStep2/EditCommonSpace";

// redux
import { RootState } from "../../../../redux/store";
import { setLoading } from "../../../../redux/features/common-slice";
import { setShowModal } from "../../../../redux/features/component-slice";

// network
import { fetchRoomDetails } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import { FetchedSpacesType } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

const ManageSpaces: React.FC<{ venueId?: string }> = ({ venueId }) => {
  const [roomDetails, setRoomDetails] = React.useState<FetchedSpacesType>({
    rooms: [],
    common_spaces: [],
  });
  const param = useParams();
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const loading = useSelector(
    (state: RootState) => state.commonReducer.loading
  );

  const venue_id = param.venue_id as string;

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const fetchRoomDetail = async () => {
    dispatch(setLoading(true));
    call(
      () => fetchRoomDetails(venue_id),
      (res) => {
        setRoomDetails(res.data.data);
        dispatch(setLoading(false));
      },
      (err) => {
        dispatch(setLoading(false));
      }
    );
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <button
            className="dropdown-item"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("room-info")}
          >
            <img src={images.GREEN_BED_ICON} alt="" height={"16px"} /> Bedroom
          </button>
          <ModalComponent
            modalName="room-info"
            title="Accommodations"
            className="modal-space-info"
            outsideClickClosable={false}
          >
            <RoomInfoModal venueId={venue_id} fetchDetails={fetchRoomDetail} />
          </ModalComponent>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <button
            className="dropdown-item"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("customize-space-info")}
          >
            <img src={images.PAINT_BRUSH_ICON} alt="" height={"16px"} /> Shared
          </button>
          <ModalComponent
            modalName="customize-space-info"
            title="Shared Space "
            className="modal-space-info"
            outsideClickClosable={false}
          >
            <CustomizeInfoModal
              venueId={venue_id}
              fetchDetails={fetchRoomDetail}
            />
          </ModalComponent>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchRoomDetail();
  }, []);

  return (
    <div className="form-white-bg">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between gap-2">
          <span className="editTabTitles">Manage spaces</span>
          <div className="d-flex gap-2 flex-grow-1 justify-content-end">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <button type="button" className="custom-button">
                <img
                  src={images.PLUS_WHITE}
                  alt=""
                  width={"16px"}
                  height={"16px"}
                />{" "}
              </button>
            </Dropdown>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div
              className={`flex-grow-1 d-flex  ${
                roomDetails.rooms.length === 0 &&
                roomDetails.common_spaces.length === 0
                  ? `flex-column justify-content-center`
                  : `justify-content-start flex-wrap mt-3 gap-4 align-items-start`
              }`}
            >
              {roomDetails.rooms.length === 0 &&
                roomDetails.common_spaces.length === 0 && (
                  <div className="empty-spaces d-flex flex-column align-items-center">
                    <img src={images.SPACES_BACKGROUND_IMAGE} alt="" />
                    <div className="text-center">
                      <h3>You haven't added any spaces yet</h3>
                      <p>
                        Your spaces are here, click on Add space to add a new{" "}
                      </p>
                    </div>
                  </div>
                )}

              <>
                {roomDetails.rooms.map((item) => (
                  <div key={`room-detail-${item.id}`}>
                    <Card
                      hoverable
                      cover={
                        item?.image_urls.length > 0 ? (
                          <img
                            src={item?.image_urls[0].image_url}
                            alt="..."
                            style={{
                              height: "208px",
                              width: "322px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={images.NO_IMAGE}
                            alt="..."
                            style={{
                              height: "208px",
                              width: "322px",
                              objectFit: "cover",
                            }}
                          />
                        )
                      }
                      style={{ width: "322px", height: "283px" }}
                      onClick={() => openModal(item.id)}
                    >
                      <div className="card-body">
                        <div className="common-md-medium-dark-text mb-1 tex-overflow-handle">
                          {item.name}{" "}
                          <span className="common-sm-light-text">
                            {" "}
                            x{item.quantity}
                          </span>
                        </div>
                        <div className="card-text tex-overflow-handle">
                          {item.beds?.map((bed, index) => (
                            <span className="common-sm-light-grey-text">
                              {bed.quantity} {bed.bed_type}{" "}
                              {item.beds && index < item.beds.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </div>
                      </div>
                    </Card>
                    <ModalComponent
                      modalName={item.id}
                      title="Bedroom Info"
                      className="modal-space-info"
                      outsideClickClosable={false}
                    >
                      <EditRoomInfo
                        venueId={venue_id}
                        fetchDetails={fetchRoomDetail}
                        roomData={item}
                      />
                    </ModalComponent>
                  </div>
                ))}
                {roomDetails.common_spaces.map((item) => (
                  <div key={`space-detail-${item.id}`}>
                    <Card
                      hoverable
                      cover={
                        item?.image_urls.length > 0 ? (
                          <img
                            src={item?.image_urls[0].image_url}
                            alt="..."
                            style={{
                              height: "208px",
                              width: "322px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={images.NO_IMAGE}
                            alt="..."
                            style={{
                              height: "208px",
                              width: "322px",
                              objectFit: "cover",
                            }}
                          />
                        )
                      }
                      style={{ width: "322px", height: "283px" }}
                      onClick={() => openModal(item.id)}
                    >
                      <div className="card-body">
                        <div className="common-md-medium-dark-text mb-1 tex-overflow-handle">
                          {item.name}{" "}
                          <span className="common-sm-light-text">
                            {" "}
                            x{item.quantity}
                          </span>
                        </div>
                      </div>
                    </Card>
                    <ModalComponent
                      modalName={item.id}
                      title="Shared Space Info"
                      className="modal-space-info"
                      outsideClickClosable={false}
                    >
                      <EditCommonSpace
                        venueId={venue_id}
                        fetchDetails={fetchRoomDetail}
                        roomData={item}
                      />
                    </ModalComponent>
                  </div>
                ))}
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageSpaces;
