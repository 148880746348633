import React from "react";
//types
import { WelcomePageProps } from "../../../types";

//images
import { images } from "../../../assets/images";

//css
import "./Welcome.css";
import Header from "../../core/Header";

const Welcome: React.FC<WelcomePageProps> = ({
  title,
  description,
  steps,
  handleClick,

  logoRequired = true,
  cancelButtonRequired,
  imgSrc,
}) => {
  return (
    <div className="welcome-page position-relative ">
      <Header>
        <div className="welcome-header">
          <img src={images.LAIGO_LOGO} alt="" />
        </div>
      </Header>
      <div className="container">
        <div className="d-flex m-auto align-items-center justify-content-between gap-4 h-100 py-3">
          <div
            className="welcome-logo-container d-flex flex-column"
            style={{ gap: "59px" }}
          >
            <div className="welcome-img">
              <img
                src={imgSrc || images.CreateRetreat}
                alt="retreat-img"
                className="img-fluid"
              />
            </div>
            <div className="d-flex flex-column gap-2 w-75">
              <h1 className="m-0 welcome-title">{title}</h1>
              {description.map((item, index) => (
                <>
                  <p className="welcome-description" key={`desc-${index}`}>
                    {item}
                  </p>
                  <br />
                </>
              ))}
            </div>
          </div>

          <div className="welcome-steps-container d-flex flex-column gap-3">
            {steps.map((step, index) => (
              <div className="welcome-steps d-flex gap-3 align-items-center">
                {step.icon && <img src={step.icon} alt="" height={"60px"} />}
                <div className="d-flex flex-column gap-2" key={`step-${index}`}>
                  <p className="m-0 step-title">{step.title}</p>
                  <p className="m-0 step-description">{step.stepDescription}</p>
                </div>
              </div>
            ))}
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="welcome-button"
                style={{ backgroundColor: "var(--deep-sea)" }}
                onClick={handleClick ? () => handleClick() : () => null}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
