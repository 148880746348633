import React from "react";
import { MailOutlined } from "@ant-design/icons";

//common component
import SmallText from "../../../UI/SmallText";

//image
import { images as iconImage } from "../../../../assets/images";
import { demo_images } from "../../../../assets/images/demo-images";

const AboutRetreatHost: React.FC = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">About the Retreat Host</p>
      <div className="d-xl-flex d-lg-flex d-md-flex d-sm-block gap-4">
        <div className="pb-xl-0 pb-lg-0 pb-md-0 pb-sm-3 pb-3 ">
          <div className="host-intro p-4 d-flex flex-column gap-3 ">
            <div className="text-center d-flex flex-column gap-2 align-items-center">
              <img src={demo_images.DEMO_IMAGE_1} alt="" width={56} height={56} />
              <div className="d-flex flex-column gap-1">
                <SmallText
                  text="Agatha Martínez"
                  lineHeight={18.8}
                  fontSize={16}
                  fontWeight={500}
                  color="#19191C"
                />
                <SmallText
                  text="Art of Living"
                  lineHeight={16.45}
                  fontSize={14}
                  fontWeight={400}
                  color="#949397"
                />
              </div>
            </div>
            <div className="d-flex gap-2 ">
              <button className="host-venue-btn">Download brochure</button>
              <button className="host-venue-btn">
                <MailOutlined />
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4">
          <p className="section-description">
            Agatha is an experienced retreat host who specializes in creating
            wellness events that promote personal transformation and inner
            reconnection. With a passion for welcoming and inspiring
            environments, she uses her expertise in yoga, meditation and
            personal development to offer unique experiences. Known for
            connecting people and guiding them on journeys of self-discovery,
            Agatha is dedicated to ensuring that each retreat is a safe and
            enriching space, allowing participants to return refreshed and
            balanced.
          </p>
          <div className="about-venue-label d-flex align-items-center">
            <img src={iconImage.BUTTERFLY_ICON} alt="" />
            <SmallText
              text="Languages spoken:  English, German and French"
              color="#2F206A"
              fontSize={18}
              fontWeight={400}
              lineHeight={21.15}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRetreatHost;
