import React, { useEffect, useRef } from "react";

// third party
import { useSelector } from "react-redux";

// types
import { ChatWindowProps } from "../../../types";

// images
import { images } from "../../../assets/images";

// Redux
import { RootState } from "../../../redux/store";

const ChatWindow: React.FC<ChatWindowProps> = ({ selectedUser, messages }) => {
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages]);

  return (
    <div className="chat-window">
      <div className="chat-window-header">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <img src={images.PERSON_AVATAR} alt="" width={"60px"} />
          <div>
            <h3 className="m-0">{selectedUser?.name}</h3>
          </div>
        </div>
      </div>
      <div className="messages-container">
        <div className="messages">
          {messages[selectedUser.uid]?.map((msg, index) => (
            <div
              key={index}
              className={`message ${
                msg.sender === userProfileData.user_id ? "sent" : "received"
              }`}
              ref={
                index === messages[selectedUser.uid].length - 1
                  ? lastMessageRef
                  : null
              }
            >
              <div className="message-content">
                <p>{msg.text}</p>
                <span className="common-xs-light-text">
                  {new Date(msg.timestamp).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
