/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

// third party
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import { Info, LogOut, Settings } from "lucide-react";

// custom component
import Sidebar from "../../UI/Sidebar";
import SmallText from "../../UI/SmallText";

// redux
import { RootState } from "../../../redux/store";

// constants
import { DASHBOARD_SIDEBAR_ITEM } from "../../../constants/property-manager";

// images
import { images } from "../../../assets/images";

// styles
import "../../../assets/css/user-home.css";

const PropertyManagerDashboardLayout: React.FC = () => {
  const navigate = useNavigate();
  const [openPopOver, setOpenPopOver] = useState<boolean>(false);
  const userSelector = useSelector((state: RootState) => state.user);

  const hide = () => {
    setOpenPopOver(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopOver(newOpen);
  };

  const footerContent = (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex gap-2 align-items-center p-2">
        <img src={images.QUESTION_ICON} alt="" height={"24px"} />
        <span className="common-sm-light-text" style={{ color: "#58585B" }}>
          Support
        </span>
      </div>
      <div className="d-flex gap-2 align-items-center p-2">
        <img
          src={userSelector.userProfile.profile_image || images.New_Avatar}
          alt=""
          height={"40px"}
        />
        <div className="d-flex flex-column">
          <p className="common-sm-dark-text">
            {userSelector.userProfile.full_name}
          </p>
          <span className="common-xs-light-text">
            {userSelector?.userProfile?.user_type
              .split("_")
              .map((value) => value[0]?.toUpperCase() + value?.slice(1))
              .join(" ")}
          </span>
        </div>
      </div>
    </div>
  );

  const accountDropDownContent = (
    <div
      className={`d-flex flex-column align-items-start justify-content-start gap-2 account-dropdown-container`}
    >
      <div
        className="d-flex gap-2 px-4 py-3"
        style={{ borderBottom: "1px solid #E8E7EC" }}
      >
        <img
          src={
            userSelector.userProfile.profile_image
              ? userSelector.userProfile.profile_image
              : images.New_Avatar
          }
          alt="profile"
          width={40}
          height={40}
        />
        <div className="d-flex flex-column gap-1" style={{ maxWidth: "141px" }}>
          <SmallText
            text={userSelector.userProfile.full_name}
            color="#19191C"
            fontWeight={400}
            fontSize={16}
            lineHeight={18.8}
            className="text-truncate"
          />
          <SmallText
            text={
              userSelector?.userProfile?.user_type
                .split("_")
                .map((value) => value[0]?.toUpperCase() + value?.slice(1))
                .join(" ") || ""
            }
            color="#949397"
            fontWeight={400}
            fontSize={14}
            lineHeight={16.45}
          />
        </div>
      </div>
      <button
        type="button"
        className="border-0 bg-white d-flex gap-4 align-items-center "
        onClick={() => {
          navigate("/venues/profile");
          hide();
        }}
        style={{ padding: "12px 24px" }}
      >
        <Settings color="#2771A3" width={24} height={24} />
        <SmallText
          text="Account Settings"
          color="#2771A3"
          lineHeight={18.8}
          fontWeight={400}
          fontSize={16}
        />
      </button>
      <button
        type="button"
        className="border-0 bg-white d-flex gap-4 align-items-center"
        style={{ padding: "12px 24px" }}
        onClick={() => {
          hide();
        }}
      >
        <Info color="#2771A3" width={24} height={24} />
        <SmallText
          text="Help Center"
          color="#2771A3"
          lineHeight={18.8}
          fontWeight={400}
          fontSize={16}
        />
      </button>
      <div style={{ borderTop: "1px solid #E8E7EC" }} className="w-100">
        <button
          type="button"
          className="logout-button border-0 bg-white d-flex gap-4 align-items-center px-4 py-3"
          onClick={() => {
            navigate("/logout");
            hide();
          }}
        >
          <LogOut
            color="#2771A3"
            width={24}
            height={24}
            style={{ rotate: "180deg" }}
          />
          <SmallText
            text="Logout"
            color="#2771A3"
            lineHeight={18.8}
            fontWeight={400}
            fontSize={16}
          />
        </button>
      </div>
    </div>
  );

  return (
    <div className="d-flex vh-100 overflow-hidden">
      <Sidebar items={DASHBOARD_SIDEBAR_ITEM} footerContent={footerContent} />

      <div className="d-flex flex-column w-100">
        <div className="venues-header d-flex justify-content-between align-items-center">
          <div></div>
          <div className="d-flex align-items-center gap-4">
            <div>
              {/* hide for the future development */}
              {/* <button type="button" className="border-0 bg-white p-0">
              <Bell color="#2771A3"  width={26} height={25.04}/>
              </button> */}
            </div>
            <Popover
              content={accountDropDownContent}
              trigger="click"
              rootClassName="popups"
              placement="bottomLeft"
              open={openPopOver}
              onOpenChange={handleOpenChange}
            >
              <button
                type="button"
                className="account-dropdown bg-white w-100 "
              >
                <img
                  src={
                    userSelector.userProfile.profile_image ||
                    images.PERSON_AVATAR
                  }
                  alt=""
                  height={"26px"}
                  className="avatar-img"
                />
                <img src={images.DOWN_ARROW_ICON} alt="" />
              </button>
            </Popover>
          </div>
        </div>
        <div style={{ padding: "24px 32px" }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PropertyManagerDashboardLayout;
