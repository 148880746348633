// MessageInput.tsx
import React, { useEffect, useRef, useState } from "react";

// custom component
import InputComponent from "../../UI/InputComponent";

// images
import { SendHorizontal } from "lucide-react";

type Props = {
  onSend: (text: string) => void;
};

const MessageInput: React.FC<Props> = ({ onSend }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState("");
  const [IsButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isMultiline, setIsMultiline] = useState(false);

  const handleSend = () => {
    if (text.trim()) {
      onSend(text.replace(/\n/g, " \\n "));
      setText("");
      setIsButtonDisabled(true);
      setIsMultiline(false);
    }
  };

  const handleInputChange = (event: any) => {
    const textInput = event.target.value;

    setIsButtonDisabled(Boolean(textInput.trim()));
    if (!textInput.includes("\n")) {
      setIsMultiline(false);
    }

    setText(textInput);
  };

  const handleKeyDownEvent = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    } else if (event.key === "Enter" && event.shiftKey) {
      setIsMultiline(true);
    }
  };

  useEffect(() => {
    // Focus on the appropriate input when switching between single-line and multi-line
    if (isMultiline && textareaRef.current) {
      textareaRef.current?.focus();
      textareaRef.current?.setSelectionRange(text.length, text.length);
    } else if (!isMultiline && inputRef.current) {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(text.length, text.length);
    }
  }, [isMultiline, text]);

  return (
    <div style={{ padding: "24px" }}>
      <div className="message-input">
        {/* NOTES : This might be useful in FUTURE */}
        {/* <button type="button" className="border-0 p-0 bg-white">
          {" "}
          <img src={images.ATTACHMENT_ICON} alt="" height={"42px"} />
        </button> */}
        {isMultiline ? (
          <textarea
            ref={textareaRef}
            value={text}
            className="input-component"
            style={{ height: "40px" }}
            onChange={(e) => handleInputChange(e)}
            onKeyDown={(event) => {
              handleKeyDownEvent(event);
            }}
            placeholder="Type a message..."
            rows={1}
          />
        ) : (
          <InputComponent
            itemRef={inputRef as any}
            style={{ height: "40px" }}
            value={text}
            onInput={(e) => handleInputChange(e)}
            placeholder="Enter your message"
            onKeyDown={(event) => {
              handleKeyDownEvent(event);
            }}
          />
        )}
        <button
          onClick={handleSend}
          className="send-message-button"
          disabled={!IsButtonDisabled}
          style={{
            backgroundColor: `${IsButtonDisabled ? "#2771A3" : "#E8E7EC"}`,
          }}
        >
          <SendHorizontal
            width={16}
            height={16}
            color={`${IsButtonDisabled ? "white" : "#B4B3B7"}`}
          />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
