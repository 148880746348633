import React from "react";

import { BellOutlined, SearchOutlined } from "@ant-design/icons";
import DashboardLayout from "../../DashboardLayout";
import { ADMIN_SIDEBAR_ITEM } from "../../../../constants/Admin";

function AdminBookings() {
    return (
        <DashboardLayout dashboardItems={ADMIN_SIDEBAR_ITEM} selected={1}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3 className="venue-listing-title">Bookings</h3>
                <div className="d-flex flex-nowrap align-items-center">
                    <button
                        style={{
                            marginRight: "8px",
                            width: "40px",
                        }}
                        className="bordered-buttons"
                    >
                        <SearchOutlined />
                    </button>
                    <button
                        style={{
                            marginRight: "8px",
                            width: "40px",
                        }}
                        className="bordered-buttons"
                    >
                        <BellOutlined />
                    </button>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default AdminBookings;
