import React from "react";

// Third Party
import { LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";

// custom component
import Sidebar from "../../UI/Sidebar";
import SmallText from "../../UI/SmallText";

// constants
import { ADMIN_SIDEBAR_ITEM } from "../../../constants/Admin";

// images
import { images } from "../../../assets/images";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// Utility
import { convertToTitleCase } from "../../../library";

// styles
import "../../../assets/css/admin-dashboard.css";

interface AdminDashboardLayoutProps {
  children?: React.ReactNode;
  pageTitle?: string;
  additionalClassNames?: string;
}

const AdminDashboardLayout: React.FC<AdminDashboardLayoutProps> = ({
  children,
  pageTitle,
  additionalClassNames = "",
}) => {
  const navigate = useNavigate();
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const footerContent = (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex gap-2 align-items-center">
        <img src={images.QUESTION_ICON} alt="" height={"24px"} />
        <span className="common-sm-light-text">Support</span>
      </div>
      <div className="d-flex gap-2 align-items-center ">
        <img
          src={userProfileData.profile_image || images.PERSON_AVATAR}
          alt=""
          height={"40px"}
        />
        <div className="d-flex flex-column">
          <p className="common-sm-dark-text">
            {userProfileData.full_name &&
              convertToTitleCase(userProfileData.full_name)}
          </p>
          {userProfileData.user_type && (
            <span className="common-xs-light-text">
              {convertToTitleCase(userProfileData.user_type)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <div className="d-flex vh-100 overflow-hidden">
      <div>
        <Sidebar items={ADMIN_SIDEBAR_ITEM} footerContent={footerContent} />
      </div>

      <div className="admin-dashboard-layout">
        <div className="venues-header d-flex justify-content-between align-items-center">
          <div>
            <div className="header-title">
              <h3 className="venue-listing-title">{pageTitle}</h3>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div>
              <button
                type="button"
                className="border-0 bg-white d-flex gap-2 align-items-center"
                onClick={() => navigate("/logout")}
              >
                <LogOut
                  color="#2771A3"
                  width={24}
                  height={24}
                  style={{ rotate: "180deg" }}
                />
                <SmallText
                  text="Logout"
                  color="#2771A3"
                  lineHeight={18.8}
                  fontWeight={400}
                  fontSize={16}
                />
              </button>
            </div>
          </div>
        </div>
        <div className={`main-content-div ${additionalClassNames}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardLayout;
