import React, { TextareaHTMLAttributes } from "react";

// styles
import "./style.css";

const TextareaComponent: React.FC<
  TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ placeholder, ...props }) => {
  return (
    <textarea
      className="textarea-component"
      placeholder={placeholder}
      {...props}
    />
  );
};

export default TextareaComponent;
