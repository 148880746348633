/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import dayjs from "dayjs";
import { Typography } from "antd";

// custom components
import SmallText from "../../../UI/SmallText";
import StatusTags from "../../../UI/StatusTags";

// images
import { images } from "../../../../assets/images";

const { Text } = Typography;

const ReservationList: React.FC<{
  status?: string;
  onClick: (reservationId: string) => void;
  reservationRequestList: any;
}> = ({ status, onClick, reservationRequestList }) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const filteredReservationList = reservationRequestList?.filter(
    (item: any) => !status || item.status === status
  );
  const statusStyles: {
    [key: string]: {
      backgroundColor: string;
      color: string;
    };
  } = {
    Approved: {
      backgroundColor: "#E9F1F6",
      color: "#3089AF",
    },
    Pending: {
      backgroundColor: "#DFD9F6",
      color: "#5D3FD3",
    },
    Complete: {
      backgroundColor: "#8BD0A4",
      color: "#398E58",
    },
    Default: {
      backgroundColor: "#F7ECE2",
      color: "#DC7618",
    },
  };

  useEffect(() => {
    if (filteredReservationList?.length > 0) {
      setSelectedId(filteredReservationList[0].id);
    }
  }, [selectedId]);

  return reservationRequestList?.length === 0 ? (
    <div className="text-center">No reservations to show</div>
  ) : (
    <div className="reservation-request-cards-container d-flex flex-column gap-3">
      {filteredReservationList?.map((item: any, index: number) => {
        const currentStatus =
          statusStyles[item?.status] || statusStyles.Default;
        const isSelected = selectedId === item.id;
        return (
          <div
            className="reservation-request-cards"
            key={`reservation-${index}`}
            onClick={() => {
              setSelectedId(item.id);
              onClick(item.id);
            }}
            style={{
              backgroundColor: isSelected ? "#e9f1f6" : "",
            }}
          >
            <div className="venue-name">
              <Text
                ellipsis={{ tooltip: item?.venue_name }}
                style={{ width: 352 }}
              >
                <SmallText text={`${item?.venue_name}`} />
              </Text>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <img
                  src={
                    item?.retreat_host?.profile_picture_url ||
                    images.PERSON_AVATAR
                  }
                  alt=""
                  height={"40px"}
                />
                <div>
                  <p className="common-sm-dark-text">
                    {item?.retreat_host?.name}
                  </p>
                </div>
              </div>
              <div>
                <div className="mb-1">
                  <StatusTags
                    top="0"
                    left="0"
                    buttonPosition="relative"
                    label={
                      item?.status === "Pending" ? "Requested" : item?.status
                    }
                    backgroundColor={currentStatus.backgroundColor}
                    color={currentStatus.color}
                  />
                </div>
                <div className="common-xs-light-text">
                  {dayjs(item.check_in_date).format("MMM DD")}-{" "}
                  {dayjs(item.check_out_date).format("MMM DD")}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReservationList;
