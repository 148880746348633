import { images } from "../../assets/images";
import { TagListType } from "../../types/property-manager";

export const CREATE_VENUE_STEPS = [
  {
    title: "Venue Details & Photos",
    content: "First-content",
  },
  {
    title: "Spaces",
    content: "Second-content",
  },
  {
    title: "Amenities",
    content: "Last-content",
  },
  {
    title: "Add-on Services",
    content: "Last-content",
  },
  {
    title: "Terms & Conditions",
    content: "Last-content",
  },
  {
    title: "Pricing & Availability",
    content: "Last-content",
  },
  {
    title: "Preview",
    content: "Last-content",
  },
];

export const CHECK_IN_OUT_TITLES = ["Check in at", "Check out at"];
export const QUIET_HOURS_TITLES = ["Quiet hours from", "Quiet hours until"];

export const PRICE_LABELS: TagListType[] = [
  {
    id: "Per_guest",
    name: "Per Guest",
    iconImage: images.PERSON_BLUE_ICON,
  },
  {
    id: "Per_day",
    name: "Per day",
    iconImage: images.SUN_ICON,
  },
  {
    id: "Fixed",
    name: "Fixed",
    iconImage: images.PIN_ICON,
  },
];

export const NEARBY_LOCATION = [
  {
    icon: images.CAR_ICON,
    title: "Interlaken Museum ",
    tagLine: "Discover the local history and the development of the city.",
    time: "5 min drive ",
  },
];

export const AMENITIES = {
  basicFacilities: [
    {
      icon: images.AIR_CONDITIONING_ICON,
      title: "Air Conditioning",
    },
    {
      icon: images.SECURITY_CAMERA_ICON,
      title: "Security Cameras",
    },
    {
      icon: images.WIFI_ICON,
      title: "WiFi",
    },
    {
      icon: images.PARKING_ICON,
      title: "Parking",
    },
    {
      icon: images.HEATER_ICON,
      title: "Heater",
    },
  ],
  accommodation: [
    {
      icon: images.BED_ICON,
      title: "Full bedding",
    },
    {
      icon: images.HAIR_DRYER_ICON,
      title: "Hair dryer",
    },
    {
      icon: images.TOWEL_ICON,
      title: "Towels",
    },
    {
      icon: images.WASHING_MACHINE_ICON,
      title: "Washing machine",
    },
    {
      icon: images.DRESSER_ICON,
      title: "Dresser",
    },
  ],
  kitchenAndDinning: [
    {
      icon: images.COFFEE_ICON,
      title: "Coffee",
    },
    {
      icon: images.OVEN_ICON,
      title: "Oven",
    },
  ],
  commonAreas: [
    {
      icon: images.LIVING_ROOM_ICON,
      title: "Living room",
    },
    {
      icon: images.YOGA_ICON,
      title: "Meditation/yoga room",
    },
    {
      icon: images.CONFERENCE_ROOM_ICON,
      title: "Conference room",
    },
    {
      icon: images.CAMPFIRE_ICON,
      title: "Campfire area",
    },
    {
      icon: images.GARDEN_ICON,
      title: "Garden",
    },
  ],
  recreationAndLeisure: [
    {
      icon: images.POOL_ICON,
      title: "Pool",
    },
  ],
  technologyAndEntertainment: [
    {
      icon: images.PROJECTOR_ICON,
      title: "Projector and screen",
    },
    {
      icon: images.TV_ICON,
      title: "TV",
    },
  ],
  pets: [
    {
      icon: images.PET_ICON,
      title: "Pets Friendly",
    },
  ],
};

export const CancelationPolicy = [
  {
    time: "Within",
    timeDesc: "48 hours of booking",
    title: "Partial refund",
    desc: "If you cancel your reservation within 48 hours of booking, you will receive a 50% refund of the amount paid, minus the service charge. Times are based on the property's local time.",
  },
  {
    time: "Aug. 12",
    timeDesc: "after 17:00",
    title: "No refunds",
    desc: "This reservation is non-refundable.",
  },
];

// Create Venue
export const STEPS_OF_CREATE_VENUE = [
  {
    title: "Venue ",
    stepDescription:
      "Share a description of your space, along with photos and essential details for retreat hosts to connect with the spirit of your venue.",
    icon: images.VENUE_LOCATION,
  },
  {
    title: "Spaces",
    stepDescription:
      "Highlight accommodation details and shared areas, allowing retreat hosts to personalize their experience and design the perfect retreat.",
    icon: images.VENUE_SPACES,
  },
  {
    title: "Amenities",
    stepDescription:
      "Showcase the unique features and amenities of your venue, giving hosts a clear vision of what they can offer their guests.",
    icon: images.VENUE_AMENITIES,
  },
  {
    title: "Add-on Services",
    stepDescription:
      "Allow guests to enrich their retreat experience by adding special services. Display your venue’s additional offerings here.",
    icon: images.VENUE_ADDONS,
  },
  {
    title: "Terms & Conditions",
    stepDescription:
      "Communicate your venue’s policies, including check-in/check-out times, quiet hours, and guidelines for shared spaces, helping guests align with your retreat’s atmosphere.",
    icon: images.VENUE_TERMS,
  },
  {
    title: "Pricing & Availability",
    stepDescription:
      "Easily manage your venue’s pricing and availability—by day or month—to ensure seamless bookings.",
    icon: images.VENUE_PRICING,
  },
];

export const TITLE_OF_CREATE_VENUE = "Add Venue";
export const SUBTITLE_OF_CREATE_VENUE = [
  "Create a venue listing in 6 easy steps.",
];

export const DEFAULT_NUMBER_OPTION = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
];

export const DASHBOARD_SIDEBAR_ITEM = [
  {
    icon: images.MENU_HOME_ICON,
    label: "My Dashboard",
    url: "/venues/home",
  },
  {
    icon: images.SIDEBAR_BUILDING,
    label: "My Venues",
    url: "/venues/my-venues",
  },
  {
    icon: images.RESERVATION_ICON,
    label: "Reservations",
    url: "/venues/reservations",
  },
  {
    icon: images.PAYOUT_ICON,
    label: "Payouts",
    url: "/venues/payouts",
  },
  {
    icon: images.MENU_MESSAGES_ICON,
    label: "Messages",
    url: "/venues/messages",
  },
];

export const reservationRequestList = [
  {
    venue: "Garden of Silence Villa ",
    avatar: images.PERSON_AVATAR,
    name: "Kristin Watson",
    userType: "Property Manager",
    status: "Approved",
    date: "Jun 20 - Jun 24",
  },
  {
    venue: "Garden of Silence Villa ",
    avatar: images.PERSON_AVATAR,
    name: "Kristin Watson",
    userType: "Property Manager",
    status: "Complete",
    date: "Jun 20 - Jun 24",
  },
  {
    venue: "Garden of Silence Villa ",
    avatar: images.PERSON_AVATAR,
    name: "Kristin Watson",
    userType: "Property Manager",
    status: "Pending",
    date: "Jun 20 - Jun 24",
  },
  {
    venue: "Garden of Silence Villa ",
    avatar: images.PERSON_AVATAR,
    name: "Kristin Watson",
    userType: "Canceled",
    status: "Canceled",
    date: "Jun 20 - Jun 24",
  },
];

export const CHART_LABEL = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const DATA_POINTS = [
  600, 700, 900, 1000, 850, 700, 1200, 1100, 950, 800, 750, 650,
];

export const MONTHS_SELECT_OPTION = [
  { value: "Jan", label: "January" },
  { value: "Feb", label: "February" },
  { value: "Mar", label: "March" },
  { value: "Apr", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "Aug", label: "August" },
  { value: "Sep", label: "September" },
  { value: "Oct", label: "October" },
  { value: "Nov", label: "November" },
  { value: "Dec", label: "December" },
];

export const ACCEPT_BOOKING_TIME_TYPE = {
  month3: "3_months",
  month6: "6_months",
  year: "1_year",
};

export const ACCEPT_BOOKING_TIME: TagListType[] = [
  {
    id: ACCEPT_BOOKING_TIME_TYPE.month3,
    name: "3 months",
  },
  {
    id: ACCEPT_BOOKING_TIME_TYPE.month6,
    name: "6 months",
  },
  {
    id: ACCEPT_BOOKING_TIME_TYPE.year,
    name: "1 year",
  },
];

export const ADVANCE_BOOKING_OPTION = [
  { value: "month", label: "Month" },
  { value: "day", label: "Day" },
];
export const GROUP_SIZE_SELECT_OPTION = [
  { value: "all", label: "All" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export const RESERVATION_STATUS_SELECT_OPTION = [
  { value: "all", label: "All" },
  { value: "Pending", label: "Requests" },
  { value: "Approved", label: "Approved" },
  { value: "Completed", label: "Completed" },
  { value: "Cancelled", label: "Canceled" },
  { value: "Rejected", label: "Declined" },
];

export const ADDITIONAL_DOCUMENT: string = "additional_documents";
export const PROOF_OF_OWNERSHIP: string = "proof_of_ownership";
export const PROOF_OF_LIABILITY: string = "proof_of_liability_insurance";

export const proof_of_liability_insurance = "proof_of_liability_insurance";

export const VENUE_RESERVATION_CANCEL_OPTION = [
  { value: "hurricane", label: "Hurricane" },
  { value: "heavy rainfall", label: "Heavy Rainfall" },
  { value: "tsunami", label: "Tsunami" },
  { value: "other", label: "Other" },
];
