import React from "react";

const PageTitle: React.FC<{
  title: string;
  subtitle?: string;
}> = ({ title, subtitle }) => {
  return (
    <section className="create-venue-page-title">
      <h1 className="title">{title}</h1>
      <h4 className="subtitle">{subtitle}</h4>
    </section>
  );
};

export default PageTitle;
