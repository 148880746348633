/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

// third party
import { EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// custom components
import ModalComponent from "../../../UI/ModalComponent";
import TableComponent from "../../../UI/TableComponent";
import UpcomingReservationDetailModal from "../../PropertyManager/Home/UpcomingReservationDetailModal";
import Loader from "../../../UI/Loader";
import StatusTags from "../../../UI/StatusTags";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";

// images
import { images } from "../../../../assets/images";

// APIs
import { getReservationRequestDetails } from "../../../../network";
import { useApiCall } from "../../../../hooks/useApiCall";

// Types
import { adminReservationTableColumnType } from "../../../../types/admin";

// Utility
import { convertDateFormat, formatNumberWithCommas } from "../../../../library";

interface ReservationListingTableProps {
  retreatData?: any[];
  totalItems: number;
  currentPage: number;
  pageSize?: number;
  onPageChange: (page: number, pageSize: number, type?: string) => void;
  loading?: boolean;
}

const ReservationListingTable: React.FC<ReservationListingTableProps> = ({
  retreatData,
  totalItems,
  currentPage,
  pageSize = 10,
  onPageChange,
  loading = false,
}) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  // const reservationsData: payoutTableColumnType[] = [];
  const [reservationsData, setReservationsData] = useState<
    adminReservationTableColumnType[]
  >([]);
  const [reservationDetails, setReservationDetails] = useState<any>({});
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const handleModal = (itemId: any) => {
    setModalLoading(true);
    openModal(`reservation-details`);
    handleReservationDetails(itemId);
  };

  const getStatusDetails = (venue: any) => {
    const isApproved = venue.status === "Approved";
    const isPending = venue.status === "Pending";
    const isDeactivated = venue.status === "Rejected";

    const label = isApproved
      ? "Published"
      : isPending
      ? "Pending"
      : isDeactivated
      ? "Rejected"
      : "Draft";

    const color = isApproved
      ? "#398E58"
      : isPending
      ? "#5D3FD3"
      : isDeactivated
      ? "#DC7618"
      : "#19191C";

    const backgroundColor = isApproved
      ? "#CEF5DC"
      : isPending
      ? "#EFECFB"
      : isDeactivated
      ? "#F7ECE2"
      : "#FFFFFF";

    return { label, color, backgroundColor };
  };

  const reservationsColumns: ColumnsType<adminReservationTableColumnType> = [
    {
      key: "retreatHost",
      dataIndex: "retreatHost",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.USER} alt="icon" height={"16px"} />
          <span>Reservation Host</span>
        </div>
      ),
      width: 200,
      render: (retreatHost: any) => {
        return retreatHost?.name ? (
          <div className="d-flex gap-2 py-2 pe-2">
            <img
              src={retreatHost?.profile_picture_url || images.PERSON_AVATAR}
              alt="profile"
              width={40}
              height={40}
              className="rounded"
            />
            {(retreatHost?.name || retreatHost?.city) && (
              <div className="m-auto">
                {retreatHost?.name && (
                  <p className="host-name-title">{retreatHost?.name}</p>
                )}
                {retreatHost?.city && (
                  <p className="host-city">{retreatHost?.city}</p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      key: "groupSize",
      dataIndex: "groupSize",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.USERS_THREE} alt="" height={"16px"} />
          <span>Group Size</span>
        </div>
      ),
      width: 120,
      render: (_, { groupSize }) => `${groupSize}`,
    },
    {
      key: "checkIn",
      dataIndex: "checkIn",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span>Check In</span>
        </div>
      ),
      width: 120,
    },
    {
      key: "checkOut",
      dataIndex: "checkOut",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span>Check Out</span>
        </div>
      ),
      width: 120,
    },
    {
      key: "status",
      dataIndex: "status",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.STAR} alt="" height={"16px"} />
          <span>Status</span>
        </div>
      ),
      width: 100,
      render: (_, res) =>
        (() => {
          const { label, color, backgroundColor } = getStatusDetails(res);
          return (
            <StatusTags
              label={label}
              color={color}
              backgroundColor={backgroundColor}
            />
          );
        })(),
    },
    {
      key: "payout",
      dataIndex: "payout",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.COIN} alt="" height={"16px"} />
          <span>Price</span>
        </div>
      ),
      width: 120,
      render: (_, { payout }) => `$ ${payout}`,
    },
    {
      key: "venueName",
      dataIndex: "venueName",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.HOUSE_ICON} alt="" height={"16px"} />
          <span>Venue Name</span>
        </div>
      ),
      width: 200,
      render: (_, { venueName }) => `${venueName}`,
    },
    {
      key: "reservationId",
      dataIndex: "reservationId",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.ID_ICON} alt="" height={"16px"} />
          <span>Reservation Id</span>
        </div>
      ),
      width: 200,
      render: (_, { reservationId }) => `${reservationId}`,
    },
    {
      key: "action",
      dataIndex: "action",
      className: "action-button",
      width: 100,
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <span>Action</span>
        </div>
      ),
      render: (_, item) => {
        return (
          <div className="payout-details-button">
            <button
              type="button"
              className="bordered-button detail-button"
              onClick={(e) => handleModal(item.key)}
            >
              <EyeOutlined /> Details
            </button>
          </div>
        );
      },
    },
  ];

  const handleReservationDetails = (reservationId: any) => {
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        const data = res?.data?.data;
        const formattedData = { ...data, amount: data?.total_price };

        setReservationDetails(formattedData);
        setModalLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message);
      }
    );
  };

  useEffect(() => {
    if (retreatData) {
      const formattedData = retreatData.map((item, index: number) => ({
        key: item?.id,
        retreatHost: item?.retreat_host || {},
        groupSize: item?.group_size || "-",
        checkIn: convertDateFormat(item?.check_in_date) || "-",
        checkOut: convertDateFormat(item?.check_out_date) || "-",
        status: item?.approval_status || "-",
        payout: formatNumberWithCommas(item?.total_price) || "-",
        retreatName: item?.title || "-",
        venueName: item?.venue_name || "-",
        reservationId: item?.reservation_id || "-",
        action: item,
      }));
      setReservationsData(formattedData);
    }
  }, [retreatData]);

  return (
    <>
      <TableComponent
        columns={reservationsColumns}
        data={reservationsData}
        pageSize={pageSize}
        scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
        tableLayout="fixed"
        paginationData={{
          onChange: onPageChange,
          total: totalItems,
          current: currentPage,
        }}
        loading={loading}
      />
      <ModalComponent modalName={"reservation-details"}>
        {modalLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <UpcomingReservationDetailModal
            reservationDetails={reservationDetails}
            isAdmin={true}
          />
        )}
      </ModalComponent>
    </>
  );
};

export default ReservationListingTable;
