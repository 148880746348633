import React from "react";

// types
import { HeaderProps } from "../../../types";

// styles
import "../../../assets/css/header.css";

const Header: React.FC<HeaderProps> = ({ children, containerClassName }) => {
  return (
    <header>
      <div className={`${containerClassName || ""} w-100`}>{children}</div>
    </header>
  );
};

export default Header;
