import React, { useEffect, useState } from "react";
//third party
import { Trash, UploadIcon } from "lucide-react";
import { useDispatch } from "react-redux";
import { DatePicker, Form } from "antd";
import dayjs from "dayjs";

//common component
import SmallText from "../../../../UI/SmallText";
import ImagePicker from "../../../../UI/ImagePicker/ImagePicker";
import ModalComponent from "../../../../UI/ModalComponent";
import Input from "../../../../UI/InputElement/Input";

//redux
import { setShowModal } from "../../../../../redux/features/component-slice";

//api call
import {
  deleteDocumentByID,
  editDocumentByID,
  uploadDocument,
} from "../../../../../network";

//utils
import { ToastFail, ToastSuccess } from "../../../../../utils/TostMessage";
import { blockInvalidChar } from "../../../../../utils/filterObject";

//custom hook
import { useApiCall } from "../../../../../hooks/useApiCall";

//css
import "./Upload.css";

interface IUploadFileProps {
  documentType: string;
  reference_id: string;
  uploadedFile: any;
}
const UploadFile: React.FC<IUploadFileProps> = ({
  documentType,
  reference_id,
  uploadedFile,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const [fileList, setFileList] = useState<any>([]); //type any cause api response will be updated
  const [sizeOfFile, setSizeOfFile] = useState<string>("");
  const [isEditDocument, setIsEditDocument] = useState<boolean>(false);
  const [editDocumentId, setEditDocumentId] = useState<string>("");
  // const [editFileName, setEditFileName] = useState<string>("");
  // const [editUrl, setEditUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // This function will run every time the form values change

  //handle open modal
  const openModal = (modalName: string) => {
    form.resetFields();
    dispatch(setShowModal({ modalName, visible: true }));
  };

  //handle close modal
  const closeModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: false }));
  };

  //set image in form
  const handleImage = (e: any) => {
    form.setFieldsValue({ document: e });
  };
  const handleModal = (id: string) => {
    if (reference_id) {
      setIsEditDocument(false);
      openModal(id);
    } else {
      ToastFail("Please select a venue");
    }
  };

  // const handleEditDocument = (id: string, title: string) => {
  //   setEditDocumentId(id);

  //   const additionalData = fileList.filter((file: any) => file.id === id)[0]
  //     .additional_data;

  //   setEditFileName(
  //     fileList.filter((file: any) => file.id === id)[0].document_name
  //   );
  //   setSizeOfFile(
  //     fileList.filter((file: any) => file.id === id)[0].document_size
  //   );
  //   setEditUrl(fileList.filter((file: any) => file.id === id)[0].document_url);
  //   setIsEditDocument(true);
  //   openModal(title);
  //   form.setFieldsValue(additionalData);
  //   form.setFieldValue(
  //     "expiration_date",
  //     dayjs(additionalData.expiration_date)
  //   );
  //   form.setFieldValue("ownership_date", dayjs(additionalData.expiration_date));
  //   form.setFieldValue(
  //     "name_of_insurance_policy",
  //     documentType === "proof_of_liability_insurance"
  //       ? additionalData.name_of_insurance_policy
  //       : additionalData.property_manager
  //   );
  // };
  const handleDelete = () => {
    if (fileList.length === 1) {
      ToastFail("Please upload at least one document");
      return;
    }
    call(
      () => deleteDocumentByID(editDocumentId),
      (res) => {
        ToastSuccess(res.data.message);
        setFileList(fileList.filter((file: any) => file.id !== editDocumentId));
        closeModal(documentType);
      },
      (err) => {
        ToastFail(err.response.data.message);
      }
    );
  };

  const handleEdit = () => {
    form.validateFields().then((values) => {
      const formData = new FormData();
      const requestBody = {
        reference_id: reference_id,
        document_type: documentType,
        document_for: "venue",
        additional_data:
          documentType === "proof_of_liability_insurance"
            ? {
                name_of_insurance_policy: values.name_of_insurance_policy,
                expiration_date: values.expiration_date.format("YYYY-MM-DD"),
                coverage_amount: parseFloat(values.coverage_amount),
              }
            : {
                property_manager: values.name_of_insurance_policy,
                city: values.city,
                state: values.state,
                country: values.country,
                ownership_date: values.ownership_date.format("YYYY-MM-DD"),
              },
        document_size: sizeOfFile,
      };

      formData.append("request_body", JSON.stringify(requestBody));

      values.document && formData.append("document", values.document);
      call(
        () => editDocumentByID(editDocumentId, formData),
        (res) => {
          ToastSuccess(res.data.message);
          setFileList((prevFileList: any[]) =>
            prevFileList.map((file) =>
              file.id === editDocumentId ? { ...res.data.data } : file
            )
          );
          form.resetFields();
          setEditDocumentId("");
          setIsEditDocument(false);
          closeModal(documentType);
        },
        (err) => {
          ToastFail(err.response.data.message);
        }
      );
    });
  };
  const handleCancel = (documentType: string) => {
    setEditDocumentId("");
    setIsEditDocument(false);
    closeModal(documentType);
  };
  //upload fil
  const handleSubmit = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      const formData = new FormData();
      // Build the JSON data for request_body
      const requestBody = {
        reference_id: reference_id,
        document_type: documentType,
        document_for: "venue",

        additional_data:
          documentType === "proof_of_liability_insurance"
            ? {
                name_of_insurance_policy: values.name_of_insurance_policy,
                expiration_date: values.expiration_date.format("YYYY-MM-DD"),
                coverage_amount: parseFloat(values.coverage_amount),
              }
            : {
                property_manager: values.name_of_insurance_policy,
                city: values.city,
                state: values.state,
                country: values.country,
                ownership_date: values.ownership_date.format("YYYY-MM-DD"),
              },
        document_size: sizeOfFile,
      };

      formData.append("request_body", JSON.stringify(requestBody));

      formData.append("document", values.document);
      call(
        () => uploadDocument(formData),
        (res) => {
          ToastSuccess(res.data.message);
          setFileList([
            ...uploadedFile,
            { ...res.data.data, document_url: res.data.data.document },
          ]);
          form.resetFields();
          setLoading(false);
          closeModal(documentType);
        },
        (err) => {
          ToastFail(err.response.data.message);
        }
      );
    });
  };
  useEffect(() => {
    setFileList([]);
    if (uploadedFile.length > 0) {
      setFileList(uploadedFile);
    }
  }, [uploadedFile, reference_id]);

  return (
    <>
      <div className="d-flex UploadCard1 flex-grow-1 bg-white py-4 px-3 flex-column">
        <div className="d-flex flex-column  h-100" style={{ gap: "20px" }}>
          <div className="d-flex align-items-center justify-content-between">
            <SmallText
              text={
                documentType === "proof_of_liability_insurance"
                  ? "Proof of Liability Insurance"
                  : documentType === "proof_of_ownership"
                  ? "Proof of Ownership"
                  : "Additional Documents"
              }
              color="#19191C"
              lineHeight={18.8}
              fontWeight={500}
              fontSize={16}
            />
          </div>

          {fileList.length > 0 ? (
            <>
              {/* type any cause api response will be update */}
              {fileList
                ?.filter((item: any) => item.document_type === documentType)
                .map((document: any) => (
                  <div
                    className="common-border bg-white d-flex flex-column"
                    style={{ padding: "16px 20px", gap: "18px" }}
                    key={document.id}
                  >
                    <div
                      className="d-flex flex-column "
                      style={{ gap: "12px" }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <SmallText
                          text={
                            document.additional_data.name_of_insurance_policy ||
                            document.additional_data.property_manager
                          }
                          color="#19191C"
                          lineHeight={16.45}
                          fontSize={14}
                          fontWeight={500}
                        />
                        {/* <SquarePen
                          width={16}
                          height={16}
                          color="#2771A3"
                          onClick={() =>
                            handleEditDocument(document.id, documentType)
                          }
                        /> */}
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex gap-2">
                          <SmallText
                            text={`${
                              documentType === "proof_of_liability_insurance"
                                ? "Expiration dates: "
                                : "Location:"
                            }`}
                            color="#606E80"
                            lineHeight={16.94}
                            fontSize={14}
                            fontWeight={400}
                          />
                          <SmallText
                            text={`${
                              documentType === "proof_of_liability_insurance"
                                ? document.additional_data.expiration_date
                                : document.additional_data.city +
                                  ", " +
                                  document.additional_data.state +
                                  ", " +
                                  document.additional_data.country
                            }`}
                            color="#19191C"
                            lineHeight={16.94}
                            fontSize={14}
                            fontWeight={500}
                          />
                        </div>
                        <div className="d-flex gap-2">
                          <SmallText
                            text={`${
                              documentType === "proof_of_liability_insurance"
                                ? "Coverage Amount: "
                                : "Ownership Date:"
                            }`}
                            color="#606E80"
                            lineHeight={16.94}
                            fontSize={14}
                            fontWeight={400}
                          />
                          <SmallText
                            text={`${
                              documentType === "proof_of_liability_insurance"
                                ? document.additional_data.coverage_amount
                                : document.additional_data.ownership_date
                            }`}
                            color="#19191C"
                            lineHeight={16.94}
                            fontSize={14}
                            fontWeight={500}
                          />
                        </div>
                      </div>
                    </div>
                    <ImagePicker
                      handleUpload={handleImage}
                      imageUrl={document.document_url}
                      nameOfFile={document.document_name}
                      sizeOfFile={document.document_size}
                    />
                  </div>
                ))}

              <button
                className="select-btn"
                onClick={() => handleModal(documentType)}
              >
                <div className="d-flex align-items-center gap-3">
                  <UploadIcon color="#606E80" width={22} height={22} />
                  <SmallText
                    text="select file"
                    color="#19191C"
                    lineHeight={16.94}
                    fontSize={14}
                    fontWeight={500}
                  />
                </div>
              </button>
            </>
          ) : (
            <div
              className="img-upload-container p-3  d-flex  justify-content-center"
              onClick={() => {
                handleModal(documentType);
              }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "12px" }}
                >
                  <UploadIcon color="#606E80" width={32} height={32} />
                  <SmallText
                    text="or drop files to upload"
                    color="#6B6B6F"
                    fontWeight={400}
                    lineHeight={16.94}
                    fontSize={14}
                  />
                </div>
                <button className="select-btn">Select file</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalComponent
        modalName={documentType}
        title={`${isEditDocument ? "Edit" : "Upload"} ${
          documentType === "proof_of_liability_insurance"
            ? "Proof of Liability Insurance"
            : documentType === "proof_of_ownership"
            ? "Proof of Ownership"
            : "Additional Documents"
        }`}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={isEditDocument ? handleEdit : handleSubmit}
          name="form"
        >
          <div
            className="upload-modal d-flex flex-column gap-3 bg-white"
            style={{ padding: "20px" }}
          >
            <div>
              <Form.Item
                name="name_of_insurance_policy"
                rules={[
                  {
                    required: true,
                    message: "Please enter insurance policy name",
                  },
                ]}
              >
                <Input
                  placeholder="Enter name of insurance policy"
                  label={`${
                    documentType === "proof_of_liability_insurance"
                      ? "Name of Insurance Policy"
                      : documentType === "proof_of_ownership"
                      ? "Property Manager"
                      : "Name of  Document"
                  }`}
                  maxLength={32}
                />
              </Form.Item>
            </div>

            {documentType === "proof_of_liability_insurance" ? (
              <div className="d-flex w-100" style={{ gap: "12px" }}>
                <div className="w-50 d-flex flex-column gap-1">
                  <SmallText
                    text="Expiration Date"
                    color="#58585B"
                    lineHeight={16.45}
                    fontWeight={500}
                    fontSize={14}
                  />
                  <Form.Item
                    name="expiration_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select the expiration date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="13 July, 2024"
                      format="DD MMM, YYYY"
                      disabledDate={(current) =>
                        current && current < dayjs().add(-1, "day")
                      }
                    />
                  </Form.Item>
                </div>

                <div className="w-50">
                  <Form.Item
                    name="coverage_amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the coverage amount",
                      },
                    ]}
                  >
                    <Input
                      label="Coverage Amount"
                      placeholder="Enter amount"
                      type="number"
                      onKeyDown={blockInvalidChar}
                    />
                  </Form.Item>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column gap-3">
                <div className="d-flex w-100" style={{ gap: "12px" }}>
                  <div className="w-50 ">
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the city",
                        },
                      ]}
                    >
                      <Input
                        label="City"
                        placeholder="Enter city"
                        className="w-100"
                      />
                    </Form.Item>
                  </div>
                  <div className="w-50">
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the state",
                        },
                      ]}
                    >
                      <Input label="State" placeholder="Enter state" />
                    </Form.Item>
                  </div>
                </div>
                <div className="d-flex w-100" style={{ gap: "12px" }}>
                  <div className=" w-50">
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the country",
                        },
                      ]}
                    >
                      <Input label="Country" placeholder="Enter country" />
                    </Form.Item>
                  </div>
                  <div className="w-50 d-flex flex-column gap-1">
                    <SmallText
                      text="Ownership Date"
                      color="#58585B"
                      lineHeight={16.45}
                      fontWeight={500}
                      fontSize={14}
                    />
                    <Form.Item
                      name="ownership_date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the ownership date ",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="13 July, 2024"
                        format="DD MMM, YYYY"
                        disabledDate={(current) =>
                          current && current > dayjs().add(-1, "day")
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}

            <Form.Item
              name="document"
              rules={[
                {
                  required: isEditDocument ? false : true,
                  message: "Please upload the insurance document",
                },
              ]}
            >
              <ImagePicker
                buttonChange={true}
                handleUpload={handleImage}
                setSizeOfFile={setSizeOfFile}
                isDelete={true}
              />
            </Form.Item>
            {isEditDocument && (
              <ImagePicker
                handleUpload={handleImage}
                // imageUrl={editUrl}
                // nameOfFile={editFileName}
                sizeOfFile={sizeOfFile}
              />
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center gap-3 mt-4">
            <div>
              {isEditDocument && (
                <div className="d-flex ">
                  <button className="btn" type="button" onClick={handleDelete}>
                    <div className="d-flex" style={{ gap: "6px" }}>
                      <Trash color="#F63030" width={18} height={18} />
                      <SmallText
                        text="Delete"
                        color="#F63030"
                        fontSize={16}
                        lineHeight={19.36}
                        fontWeight={400}
                      />
                    </div>
                  </button>
                </div>
              )}
            </div>
            <div className="d-flex gap-3">
              <button
                className="back-btn"
                type="button"
                onClick={() => handleCancel(documentType)}
              >
                Cancel
              </button>

              <button
                className={`${true ? "submit-btn" : "disable-submit-btn"}`}
                disabled={loading}
              >
                Upload
              </button>
            </div>
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};

export default UploadFile;
