import React from "react";

//component

import SectionDivide from "../../../UI/Divider/SectionDivide";
import AboutInclude from "./AboutInclude";
import AboutNotInclude from "./AboutNotInclude";
import ScheduleDetail from "./ScheduleDetail";
import HighlightedDetail from "./HighlightedDetail";
import AboutSection from "./AboutSection";

//css
import "../../../../assets/css/hostVenueDetail.css";

const AboutPackage: React.FC = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <HighlightedDetail />
      <AboutSection aboutTitle={"sdf"} aboutDescription={"dfg"} />
      <SectionDivide />
      <AboutInclude />
      <SectionDivide />
      <AboutNotInclude />
      <SectionDivide />
      <ScheduleDetail />
    </div>
  );
};

export default AboutPackage;
