import React, { useState } from "react";

//common component
import { CardSelectProps } from "../../../types";
//css
import "./index.css";

const CardSelect: React.FC<CardSelectProps> = ({
  card,
  icons,
  handleIcon,
  handleCard,
}) => {
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [cardValue, setCardValue] = useState<string>("");
  const handleSelectedIcon = (value: string) => {
    setCardValue(value);
    handleCard(value);
  };

  const handleSelectedCard = (value: string) => {
    setSelectedCard(value);
    handleCard(value);
  };

  return (
    <div className="card-selector d-flex gap-2 flex-wrap w-100">
      {card?.map((cardDetail, index) => (
        <div
          key={index}
          className={`price-card card ${
            selectedCard === cardDetail.value ? "selected" : ""
          } d-flex flex-row align-items-center`}
          onClick={() => handleSelectedCard(cardDetail.value!)}
        >
          {cardDetail.image && (
            <img src={cardDetail.image} alt="img" width={16} height={16} />
          )}
          <div>{cardDetail.title}</div>
        </div>
      ))}
      {icons &&
        icons.map((icon, index) => (
          <div
            key={index}
            className={`card ${
              cardValue === icon.id ? "selected" : ""
            } d-flex flex-row align-items-center`}
            onClick={() => handleSelectedIcon(icon.id!)}
          >
            <img src={icon.icon_url} alt="img" width={16} height={16} />
          </div>
        ))}
    </div>
  );
};

export default CardSelect;
