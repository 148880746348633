// third party
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllIcons } from "../../network";

type CommonSliceTypes = {
  modals: Record<string, boolean>;
  loading: boolean;
  icons: { icon_url: string; id: string }[];
  createVenueFormStep: number;
  cancelReservationId?: string;
  homeActionModal?: boolean;
};

const initialState: CommonSliceTypes = {
  modals: {},
  loading: true,
  homeActionModal: false,
  icons: [],
  createVenueFormStep: 0,
  cancelReservationId: "",
};

// Async thunk to fetch icons
export const fetchIconsThunk = createAsyncThunk(
  "common/fetchIcons",
  async () => {
    const res = await fetchAllIcons();
    return res.data.data;
  }
);

export const CommonSlice = createSlice({
  name: "commonSlice",
  initialState: initialState,
  reducers: {
    setShowModal: (state, action) => {
      const { modalName, visible } = action.payload;
      state.modals[modalName] = visible;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateVenueFormStep: (state, action) => {
      state.createVenueFormStep = action.payload;
    },
    setCancelReservationId: (state, action) => {
      state.cancelReservationId = action.payload;
    },
    setHomeActionLoading: (state, action) => {
      state.homeActionModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIconsThunk.fulfilled, (state, action) => {
      state.icons = action.payload;
    });
  },
});

export const {
  setShowModal,
  setLoading,
  setCreateVenueFormStep,
  setCancelReservationId,
  setHomeActionLoading,
} = CommonSlice.actions;

export default CommonSlice.reducer;
