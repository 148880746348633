import React from "react";

// Custom component
import MainLayout from "../../../MainLayout";
import PageTitle from "../PageTitle";
import AvailabilityCalender from "./AvailabilityCalender";

// styles
import "../../../../../assets/css/create-venue.css";

const CreateVenueFormStep6: React.FC<{
  venue_id: string;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}> = ({ handleNextStep, handlePreviousStep, venue_id }) => {
  return (
    <div className="add-spaces price-and-availability">
      <MainLayout>
        <PageTitle
          title="Price and Availability"
          subtitle="Simplify bookings by managing your pricing and availability in one place."
        />
        <AvailabilityCalender
          venueId={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </MainLayout>
    </div>
  );
};

export default CreateVenueFormStep6;
