import { useEffect, useState } from "react";

// Third Party
import { toast } from "react-toastify";
import { ColumnsType } from "antd/es/table";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";

// Component
import TableComponent from "../../../UI/TableComponent";
import ModalComponent from "../../../UI/ModalComponent";
import SmallText from "../../../UI/SmallText";
import FormBtn from "../../../UI/InputElement/FormButton";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import {
  adminVenueApproval,
  adminVenueDeactivateApproval,
} from "../../../../network";

// Redux
import { useDispatch } from "react-redux";
import { setShowModal } from "../../../../redux/features/component-slice";

// Utils
import { formatNumberWithCommas } from "../../../../library";

// Images
import { images } from "../../../../assets/images";

// Style
import "../../../../assets/css/admin-users.css";
import "../../../../assets/css/admin-venues.css";

interface venueTableType {
  key: React.Key; // Add key property
  name: React.ReactNode;
  price: React.ReactNode;
  city: React.ReactNode;
  country: React.ReactNode;
}

const columnsArray: ColumnsType<venueTableType> = [
  {
    key: "name",
    dataIndex: "name",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.USER} alt="" height={"16px"} />
        <span>Name</span>
      </div>
    ),
    width: 250,
  },
  {
    key: "city",
    dataIndex: "city",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.BUILDING_ICON} alt="" height={"16px"} />
        <span>City</span>
      </div>
    ),
    width: 200,
  },
  {
    key: "country",
    dataIndex: "country",
    title: (
      <div className=" column-title d-flex gap-2 align-items-center">
        <img src={images.AERO_PLANE} alt="" height={"16px"} />
        <span>Country</span>
      </div>
    ),
    width: 200,
  },
  {
    key: "price",
    dataIndex: "price",
    title: (
      <div className="column-title d-flex gap-2 align-items-center">
        <img src={images.CURRENCY_DOLLAR_CIRCLE} alt="" height={"16px"} />
        <span>Price</span>
      </div>
    ),
    width: 100,
  },
];

const VenueListingTable = ({
  selectedTab,
  data,
  totalItems,
  currentPage,
  pageSize = 10,
  onPageChange,
  loading = false,
}: {
  selectedTab?: string;
  data: any;
  totalItems: number;
  currentPage: number;
  pageSize?: number;
  onPageChange: (page: number, pageSize: number) => void;
  loading?: boolean;
}) => {
  const { call } = useApiCall();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<any>(data);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [clickedItemData, setClickedItemData] = useState<{
    name: string;
    id: string;
  }>({ name: "", id: "" });
  const [rejectionReason, setRejectionReason] = useState<string>("");

  // Modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
    setRejectionReason("");
  };

  // APIs
  const updateVenueApprovalAPI = async (venueId: string, payload: any) => {
    call(
      () => adminVenueApproval(venueId, payload),
      (res) => {
        toast(res.data.message);

        const index: number = data.findIndex(
          (item: any) => item?.id === venueId
        );

        // Check the index and if found, then remove that item from array
        if (index !== -1) {
          data.splice(index, 1);
          setTableData(data);
        }

        setTableLoading(false);
      },
      (err) => {
        toast.error(err.response.data?.message);
        setTableLoading(false);
      }
    );
  };

  const updateVenueDeactivateApprovalAPI = async (venueId: string) => {
    call(
      () => adminVenueDeactivateApproval(venueId),
      (res) => {
        const index: number = data.findIndex(
          (item: any) => item?.id === venueId
        );

        // Check the index and if found, then remove that item from array
        if (index !== -1) {
          data.splice(index, 1);
          setTableData(data);
        }

        toast(res.data.message);
        setTableLoading(false);
      },
      (err) => {
        toast.error(err.response.data?.message);
        setTableLoading(false);
      }
    );
  };

  const handleApprovalConfirmedClick = (
    venueId: string,
    approvalType: string
  ) => {
    // Start loading the table
    setTableLoading(true);

    if (approvalType === "approval") {
      updateVenueApprovalAPI(venueId || "", { status: "Approved" });
      dispatch(setShowModal({ modalName: "approve", visible: false }));
    } else if (approvalType === "rejection") {
      updateVenueApprovalAPI(venueId || "", {
        status: "Rejected",
        reason_if_rejected: rejectionReason.toString(),
      });
      dispatch(setShowModal({ modalName: "reject", visible: false }));
      setRejectionReason("");
    }
  };

  const handleDeactivateBtnClick = (venueId: string) => {
    setTableLoading(true);
    dispatch(setShowModal({ modalName: "deactivate", visible: false }));
    updateVenueDeactivateApprovalAPI(venueId);
  };

  // Table data to be shown
  const dataToShow: venueTableType[] = data?.map((item: any, index: number) => {
    // Dropdown List Data
    const dropDownContent = (
      <div
        key={`venue-listing-table-${index}`}
        className={`actions-container venue-cards-action-container`}
      >
        <button
          className="border-0 bg-white"
          onClick={() => {
            navigate(`/admin/venues/${item?.id}`);
          }}
        >
          <img src={images.EYE_ICON} alt="" height={"24px"} />
          <span>Details</span>
        </button>
        {selectedTab === "pending_approval" ? (
          <>
            <button
              type="button"
              className="border-0 bg-white"
              onClick={() => openModal("approve")}
            >
              <img src={images.DUPLICATE_ICON} alt="" height={"24px"} />
              <span>Approve</span>
            </button>
            <button
              type="button"
              className="border-0 bg-white"
              onClick={() => {
                openModal("reject");
              }}
            >
              <img src={images.PAUSE_ICON} alt="" height={"24px"} />
              <span>Reject</span>
            </button>
          </>
        ) : (
          <></>
        )}
        {selectedTab === "pending_deactivation" ? (
          <>
            <button
              type="button"
              className="border-0 bg-white"
              onClick={() => {
                openModal("deactivate");
              }}
            >
              <img src={images.DUPLICATE_ICON} alt="" height={"24px"} />
              <span>Deactivate</span>
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    );

    return {
      key: item?.id,
      name: (
        <a
          href={`/admin/venues/${item?.id}`}
          className="remove-a-tag-underline tex-overflow-handle"
        >
          {item?.name}
        </a>
      ),
      city: <p className="tex-overflow-handle">{item?.city}</p>,
      country: item?.country,
      price: (
        <div className="d-flex justify-content-between me-3">
          <p>
            {item?.base_price
              ? `$ ${formatNumberWithCommas(item?.base_price)}`
              : "-"}
          </p>
          <div>
            <Popover
              content={dropDownContent}
              trigger="click"
              rootClassName="popups"
            >
              <button
                type="button"
                className="bg-white border-0"
                onClick={() => {
                  setClickedItemData({ id: item?.id, name: item?.name });
                }}
              >
                <img
                  src={images.THREE_VERTICAL_DOT_BLACK}
                  alt=""
                  height={"20px"}
                />
              </button>
            </Popover>
          </div>
        </div>
      ),
    };
  });

  useEffect(() => {}, [tableData]);

  return (
    <>
      {/* Modal for Approvals */}
      <ModalComponent modalName="approve" title="Venue Listing Approval">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-2">
            <SmallText
              text="Venue Name :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={clickedItemData.name}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div>
            <SmallText
              text="Are you sure you want to approve this Venue?"
              color="#949397"
              fontSize={18}
              lineHeight={50}
              fontWeight={400}
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <FormBtn
              text="Approve"
              className="submit-btn"
              onClick={() => {
                handleApprovalConfirmedClick(clickedItemData.id, "approval");
              }}
            />
          </div>
        </div>
      </ModalComponent>

      {/* Modal for Rejections */}
      <ModalComponent modalName="reject" title="Venue Listing Rejection">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-2">
            <SmallText
              text="Venue Name :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={clickedItemData.name}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div>
            <SmallText
              text="Are you sure you want to reject this venue?"
              color="#949397"
              fontSize={18}
              lineHeight={50}
              fontWeight={400}
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <SmallText
              text="Reason of Rejection :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <textarea
              rows={3}
              className="form-control"
              placeholder="Write your reason for rejection"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            ></textarea>
          </div>
          <div className="d-flex justify-content-end gap-3">
            <FormBtn
              text="Reject"
              className="submit-btn"
              onClick={() => {
                handleApprovalConfirmedClick(clickedItemData.id, "rejection");
              }}
            />
          </div>
        </div>
      </ModalComponent>

      {/* Modal for Deactivation */}
      <ModalComponent modalName="deactivate" title="Venue Deactivation">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-2">
            <SmallText
              text="Venue Name :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={clickedItemData.name}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div>
            <SmallText
              text="Are you sure you want to deactivate this venue?"
              color="#949397"
              fontSize={18}
              lineHeight={50}
              fontWeight={400}
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <FormBtn
              text="Deactivate"
              className="submit-btn"
              onClick={() => {
                handleDeactivateBtnClick(clickedItemData.id);
              }}
            />
          </div>
        </div>
      </ModalComponent>

      <TableComponent
        data={dataToShow}
        columns={columnsArray}
        showPagination={true}
        pageSize={pageSize}
        paginationData={{
          current: currentPage,
          total: totalItems,
          onChange: onPageChange,
        }}
        loading={loading || tableLoading}
        scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
        tableLayout="fixed"
      />
    </>
  );
};

export default VenueListingTable;
