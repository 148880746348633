import React from "react";
//third party
import { RootState } from "../../../../redux/store";
//component
import SectionDivide from "../../../UI/Divider/SectionDivide";

//redux
import { useSelector } from "react-redux";
//css
import "../../../../assets/css/createRetreat.css";

const PriceSection : React.FC = () => {
  const { retreatPropertyDetail } = useSelector(
    (state: RootState) => state.retreatVenue
  );
  const createRetreat = useSelector((state: RootState) => state.createRetreat);

  return (
    <div className="add-on-cost">
      <div>
        {retreatPropertyDetail?.venue_images.length!  > 0 ? (
          <div>
            <img
              src={
                retreatPropertyDetail?.venue_images[0] 
              }
              alt="venue"
              className="w-100"
              height={150}
            />
          </div>
        ) : ""}

        <div className="venue-detail">
          <h1 className="m-0">{retreatPropertyDetail?.venue_title}</h1>
          <p className="m-0">
            {retreatPropertyDetail?.city} {retreatPropertyDetail?.country}
          </p>
        </div>
      </div>
      <div className="cost-detail">
        <div className="d-flex gap-4 title">
          <div style={{ width: "200px" }}>Description</div>
          <div style={{ width: "107.5px" }}>Per guest</div>
          <div className="flex-grow-1">Total</div>
        </div>
        <SectionDivide />
        <div className="d-flex flex-column gap-1">
          <div className="d-flex gap-4 description">
            <div style={{ width: "200px" }}>Venue rental</div>
            <div style={{ width: "107.5px" }}>
              ${retreatPropertyDetail?.base_price}
            </div>
            <div className="flex-grow-1">${createRetreat?.total_price}</div>
          </div>
          <div className="d-flex gap-4 description">
            <div style={{ width: "200px" }}>Service fee</div>
            <div style={{ width: "107.5px" }}>$8,30</div>
            <div className="flex-grow-1">$100</div>
          </div>
        </div>
        <SectionDivide />
        <div className="d-flex flex-column gap-3">
        </div>
        <SectionDivide />
        <div className="d-flex gap-4 total">
          <div style={{ width: "200px" }}>Base Price</div>
          <div style={{ width: "107.5px" }}>${}</div>
          <div className="flex-grow-1">${0 * 2}</div>
        </div>
      </div>
    </div>
  );
};

export default PriceSection;
