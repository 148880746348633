import React from "react";
import { useDispatch } from "react-redux";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
//common component
import ModalComponent from "../../../UI/ModalComponent";
import SmallText from "../../../UI/SmallText";
//constant
import {
  RetreatInstructor,
  RetreatInstructor1,
} from "../../../../constants/RetreatHost";
const InstructorDetail: React.FC = () => {
  const dispatch = useDispatch();

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">Meet the instructors</p>
      <div className="d-flex gap-4 flex-wrap instructor">
        {RetreatInstructor.map((value, index) => {
          return (
            <div className="d-flex flex-column text-start gap-2" key={index}>
              <div className="position-relative img-container">
                <img
                  src={value.image}
                  className="img-fluid w-100 h-100 rounded"
                  alt="instructor"
                />
                <div id="overlay"></div>
              </div>

              <div className="d-flex flex-column gap-1">
                <SmallText
                  text={value.name}
                  lineHeight={21.15}
                  fontSize={18}
                  fontWeight={500}
                  color="#19191C"
                />

                <div className="venueIcon">
                  <button
                    className="modal-open-button"
                    style={{
                      border: "none",
                      background: "transparent",
                      padding: 0,
                    }}
                    type="button"
                    onClick={() => openModal("instructor")}
                  >
                    <SmallText text="See all" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ModalComponent modalName="instructor" title="About instructors">
        {RetreatInstructor1.map((value, index) => {
          return (
            <div className="d-flex flex-column text-start gap-2" key={index}>
              <div className="position-relative img-container">
                <img
                  src={value.image}
                  className="img-fluid rounded"
                  alt="instructor"
                  style={{ width: "56px", height: "56px" }}
                />
              </div>

              <div className="d-flex flex-column gap-1">
                <SmallText
                  text={value.name}
                  lineHeight={21.15}
                  fontSize={18}
                  fontWeight={500}
                  color="#19191C"
                />
              </div>
              <p>
                Joana Miler is a passionate and dedicated yoga instructor with
                over a decade of experience in helping her students find balance
                and well-being through the practice of yoga. With a deep
                knowledge of meditation and mindfulness, she inspires her
                students to explore new dimensions of self-knowledge and
                relaxation. Joana has also led and participated in several
                retreats around the world, enriching her practice and her
                ability to offer transformative experiences. Her welcoming and
                personalized style makes each student feel supported and
                motivated on their personal wellness journey.
              </p>
            </div>
          );
        })}
      </ModalComponent>
    </div>
  );
};

export default InstructorDetail;
