import React from "react";

// styles
import "./style.css";

const FormLabel = ({
  label,
  subLabel,
}: {
  label: string;
  subLabel?: string;
}) => {
  return (
    <label>
      <div className="label">{label}</div>
      {subLabel && <div className="sub-label">{subLabel}</div>}
    </label>
  );
};

export default FormLabel;
