import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useId,
  useState,
} from "react";
//third party
import { Eye, EyeOff } from "lucide-react";
//common component
import SmallText from "../SmallText";
//css
import "./index.css";
interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = ({
  label,
  type = "text",
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const id = useId();
  return (
    <>
      {label && (
        <label className="mb-1" htmlFor={id}>
          <SmallText
            text={label}
            color="#58585B"
            lineHeight={16.45}
            fontWeight={500}
            fontSize={14}
          />
        </label>
      )}
      <input
        id={id}
        type={isPasswordVisible ? "text" : type}
        {...props}
        className="form-control"
      />
      {type === "password" && (
        <p
          className="password-icon"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          {isPasswordVisible ? (
            <Eye color="#19191C" width={16} height={16} />
          ) : (
            <EyeOff color="#19191C" width={16} height={16} />
          )}
        </p>
      )}
    </>
  );
};

Input.displayName = "Input";

export default Input;
