/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";

// Third Party
import { ConfigProvider, Input, Tabs, TabsProps } from "antd";
import { toast } from "react-toastify";
import { debounce } from "lodash";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";
import UserListingTable from "./UserListingTable";

// Constants
import { AdminRequestTypes, USER_TYPE_ROLE } from "../../../../constants/Admin";

// Utility
import { convertToTitleCase, GetQueryParamsFromURL } from "../../../../library";

// APIs
import { adminRequestList, usersList } from "../../../../network";
import { useApiCall } from "../../../../hooks/useApiCall";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Images
import { images } from "../../../../assets/images";

const UserListing: React.FC = () => {
  // Set initial Table Limit Value
  const initialLimitValue = 6;
  const queryParamsFromURL = GetQueryParamsFromURL();

  const { call } = useApiCall();

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  const [role, setRole] = useState<string>(
    queryParamsFromURL.get("type") || ""
  );

  const [userData, setUserData] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<any>({
    limit: initialLimitValue,
    offset: 0,
    search: "",
    user_type: role,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const userRolesListTabs: TabsProps["items"] = [
    { key: "", label: "All" },
    {
      key: USER_TYPE_ROLE.VENUE_MANAGER.key,
      label: USER_TYPE_ROLE.VENUE_MANAGER.label,
    },
    {
      key: USER_TYPE_ROLE.RETREAT_HOST.key,
      label: USER_TYPE_ROLE.RETREAT_HOST.label,
    },
    {
      key: USER_TYPE_ROLE.RETREAT_GUEST.key,
      label: USER_TYPE_ROLE.RETREAT_GUEST.label,
    },
    {
      key: "is_deactivation",
      label: "Pending Deactivation",
    },
  ];

  //APIs
  const getUserList = async () => {
    setLoading(true);
    call(
      () => usersList({ queryParamsObj: queryParams }),
      (res) => {
        setUserData(res.data.data);
        setTotalItems(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error("Failed to retrieve the user listing.");
        setLoading(false);
      }
    );
  };

  const getPendingDeactivations = async () => {
    setLoading(true);
    call(
      () => adminRequestList({ queryParamsObj: queryParams }),
      (res) => {
        const formattedData = res.data.data.map((item: any) => ({
          user_id: item.reference_id,
          email: item.data.email,
          full_name: item.data.full_name,
          phone_number: item.data["phone_number"],
          city: item.data.city,
          user_type: convertToTitleCase(item.requested_by),
          reason: item.data.reason,
        }));

        setUserData(formattedData);
        setTotalItems(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(err.response.data?.message);
        setLoading(false);
      }
    );
  };

  // Handle search with debounce
  const handleSearch = useCallback(
    debounce((value: string) => {
      setQueryParams((prev: any) => ({
        ...prev,
        search: value,
        limit: initialLimitValue,
        offset: 0,
      }));
      setCurrentPage(1);
    }, 1000),
    []
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setQueryParams((prev: any) => ({
      ...prev,
      offset: (page - 1) * queryParams.limit,
    }));
  };

  // Handle tab change
  const handleTabChange = (user_type: string) => {
    setRole(user_type);

    if (user_type === "is_deactivation") {
      setQueryParams((prev: any) => ({
        ...prev,
        user_type,
        limit: initialLimitValue,
        offset: 0,
        request_type: AdminRequestTypes.userDeactivationRequest,
      }));
    } else {
      setQueryParams((prev: any) => ({
        ...prev,
        user_type,
        limit: initialLimitValue,
        offset: 0,
      }));
    }

    setCurrentPage(1);
  };

  useEffect(() => {
    role === "is_deactivation" ? getPendingDeactivations() : getUserList();
  }, [queryParams]);

  return (
    <AdminDashboardLayout
      pageTitle={`Welcome,  ${convertToTitleCase(
        userProfileData.first_name
      )} 👋`}
      additionalClassNames="mt-4"
    >
      <div className="user-home-table admin-users bg-white">
        <div className="d-flex gap-3 justify-content-between">
          <div className="common-xl-medium-dark-text">Users</div>
          <div>
            <Input
              placeholder="Search"
              prefix={<img src={images.SEARCH_ICON} height={"24px"} alt="" />}
              className="common-sm-dark-text"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>

        <ConfigProvider>
          <Tabs
            defaultActiveKey={role}
            items={userRolesListTabs}
            onChange={handleTabChange}
          />
        </ConfigProvider>

        <UserListingTable
          role={role}
          userData={userData}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          loading={loading}
          pageSize={queryParams.limit}
        />
      </div>
    </AdminDashboardLayout>
  );
};

export default UserListing;
