import React from "react";

// third party
import { useDispatch, useSelector } from "react-redux";

// custom component
import Button from "../../../UI/Button";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import { RootState } from "../../../../redux/store";

// images
import { images } from "../../../../assets/images";

const VenueActionModal: React.FC<{
  message?: string;
  image?: string;
  modalName: string;
  action?: () => Promise<void>;
  // loading?: boolean;
  buttonLabel: string;
  disabled: boolean;
}> = ({ message, image, modalName, action, buttonLabel, disabled }) => {
  const dispatch = useDispatch();
  const loadingSelector = useSelector(
    (state: RootState) => state.commonReducer.homeActionModal
  );

  return (
    <div className="reservation-success-container">
      <div className="d-flex flex-column mt-5">
        <img src={images.WARNING_IMG} alt="" height={96} width={96} />
        <p className="title common-xl-bold-dark-text p-0">{message}</p>
      </div>
      <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
        <div className="d-flex gap-2">
          <Button
            type="button"
            label="Cancel"
            className="bordered-button"
            onClick={() =>
              dispatch(setShowModal({ modalName, visible: false }))
            }
          />
          <Button
            type="button"
            label={loadingSelector ? "submitting..." : buttonLabel}
            onClick={action}
            disabled={loadingSelector}
          />
        </div>
      </div>
    </div>
  );
};

export default VenueActionModal;
