import React, { useEffect, useState } from "react";

//third party
import { Form, Select, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//component
import StepFormFooter from "./StepFormFooter";
import PriceSection from "./PriceSection";

//common component
import StepFormHeader from "../../../UI/StepForm/StepFormHeader";
import StepFormAccordian from "../../../UI/StepForm/StepFormAccordian";
import SmallText from "../../../UI/SmallText";
import RadioCard from "../../../UI/SelectGroup/RadioCard";
import ModalComponent from "../../../UI/ModalComponent";
import CardSelect from "../../../UI/SelectGroup/CardSelect";
import FormBtn from "../../../UI/InputElement/FormButton";
import CardComponent from "../../../UI/PricingCard/CardComponent";

//api
import {
  allAddonService,
  allRooms,
  createRetreatAPI,
  handleRetreatStep,
  postAddOnServices,
} from "../../../../network";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
import { setRetreat } from "../../../../redux/features/createRetreat-slice";
import { RootState } from "../../../../redux/store";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//constant
import {
  cardDetail,
  columns,
  DataType,
  paymentsPlan,
  policyPlans,
  retreatTypeOptions,
} from "../../../../constants/RetreatHost";

//types
import {
  AddonServiceProps,
  CreateAddonProps,
  FormFooterProps,
  RoomProps,
} from "../../../../types/retreat";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

//images
import { demo_images } from "../../../../assets/images/demo-images";
import { images } from "../../../../assets/images";

//css
import "../../../../assets/css/retreatPackage.css";
import Loader from "../../../UI/Loader";

const RetreatPackage: React.FC<FormFooterProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addOnService, setAddOnService] = useState<AddonServiceProps>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>("GBP");
  const [isCorpRetreat, setIsCorpRetreat] = useState<string>("");
  const [isPaymentPlan, setIsPaymentPlan] = useState<string>("");
  const [isPolicyPlan, setIsPolicyPlan] = useState<string>("");
  const [hostPremium, setHostPremium] = useState<number>(0);
  const [rooms, setRooms] = useState<RoomProps[]>([]);
  const [markupValues, setMarkupValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [finalValues, setFinalValues] = useState<{ [key: string]: number }>({});
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);
  const [isPriceDisabled, setIsPriceDisabled] = useState<boolean>(false);
  const [cardValue, setCardValue] = useState<string>("");

  const { propertyId } = useSelector((state: RootState) => state.retreatVenue);
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const retreat = useSelector((state: RootState) => state.createRetreat);

  //set a card type value
  const handleCardValue = (value: string) => {
    setCardValue(value);
    form.setFieldsValue({ cardType: value });
  };

  //handle modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  //handle close modal
  const closeModal = (modalName: string) => {
    form.resetFields();
    dispatch(setShowModal({ modalName, visible: false }));
  };
  //handle icon id
  const handleIconValue = (id: string) => {
    form.setFieldsValue({ icon: id });
  };

  // Handle markup change
  const handleMarkupChange = (roomId: string, markup: number) => {
    if (markup > 100) {
      setMarkupValues((prev) => ({ ...prev, [roomId]: 100 }));
    } else {
      const room = rooms.find((room) => room.id === roomId);
      if (room) {
        const basePrice = room.price;
        const finalPrice = basePrice + (basePrice * markup) / 100;
        setMarkupValues((prev) => ({ ...prev, [roomId]: markup }));
        setFinalValues((prev) => ({ ...prev, [roomId]: finalPrice }));
      }
    }
  };
  //block invalid characters
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  //handle new add on services
  const handleNewAddOn = (addOnValue: CreateAddonProps) => {
    const addOnDetail = {
      icon_id: addOnValue.icon,
      name: addOnValue.name,
      charges: addOnValue.price ? addOnValue.price : 0,
      description: addOnValue.description,
      service_type:
        addOnValue.cardType && addOnValue.price > 0
          ? addOnValue.cardType
          : "Free",
      tickets: addOnValue.tickets !== 0 ? addOnValue.tickets : null,
      external_link: addOnValue.link ? addOnValue.link : "",
      type: "Optional",
    };
    //filter a key which has empty value
    const filteredAddOnDetail = Object.fromEntries(
      Object.entries(addOnDetail).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
    call(
      () =>
        postAddOnServices({ venue_id: propertyId, data: filteredAddOnDetail }),
      (res) => {
        //reset form filed and close modal
        form.resetFields();
        getAddonService();
        closeModal("addon");
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  //api call for get all rooms
  const getAllRooms = () => {
    call(
      () => allRooms(propertyId),
      (res) => {
        setRooms(res.data.data);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  //get add in services
  const getAddonService = () => {
    setIsLoading(true);
    call(
      () => allAddonService(propertyId),
      (res) => {
        setAddOnService(res.data.data);
        setIsLoading(false);
      },
      (err) => {
        ToastFail(err.message);
        setIsLoading(false);
      }
    );
  };

  const retreatPricing = rooms.map((room) => ({
    room_id: room.id,
    price_markup: markupValues[room.id] || 0,
  }));

  //api call for store data in create retreat
  const handleRetreatPackage = async (isPartial: boolean) => {
    const retreatValues = {
      host_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      venue_id: retreat.venue_id,
      check_in_date: retreat.check_in_date,
      check_out_date: retreat.check_out_date,
      group_size: retreat.group_size,
      total_price: retreat.total_price,
      desired_premium: {
        is_corporate_retreat: isCorpRetreat ? "true" : "false",
        premium_per_guest: isCorpRetreat === "true" ? hostPremium : 0,
        premium_currency: isCorpRetreat === "true" ? selectedCurrency : "",
      },
      retreat_pricing: retreatPricing,
      retreat_add_on_services: addOnService?.add_on_services
        .filter((card) => card.type === "Optional")
        .map((card) => card.id),
      guest_payment_plan:
        isPaymentPlan === "30" ||
        isPaymentPlan === "14" ||
        isPaymentPlan === "7"
          ? Number(isPaymentPlan)
          : isPaymentPlan,
      guest_cancellation_policy: isPolicyPlan,
      partial: isPartial,
      form_step: "retreat_package",
    };
    call(
      () => createRetreatAPI(retreatValues),
      (res) => {
        getDetailOfRetreat();
        if (isPartial) {
          navigate("/host/my-retreat");
        } else {
          handleNextStep();
        }
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  //get previously selected retreat detail
  const getDetailOfRetreat = () => {
    call(
      () => handleRetreatStep("80f8c511-a295-455e-9903-e25ce03aa21e"),
      (res) => {
        dispatch(
          setRetreat({
            id: res.data.data.id,
            venue_id: res.data.data.venue_id,
            check_in_date: res.data.data.check_in_date,
            check_out_date: res.data.data.check_out_date,
            group_size: res.data.data.group_size,
            total_price: res.data.data.total_price,
            desired_premium: {
              is_corporate_retreat:
                res.data.data.desired_premium.is_corporate_retreat,
              premium_per_guest:
                res.data.data.desired_premium.premium_per_guest,
              premium_currency: res.data.data.desired_premium.premium_currency,
            },
            retreat_pricing: res.data.data.retreat_pricing.map(
              (room: { room_id: string; price_markup: number }) => ({
                room_id: room.room_id,
                price_markup: room.price_markup,
              })
            ),

            guest_payment_plan: res.data.data.guest_payment_plan,
            guest_cancellation_policy: res.data.data.guest_cancellation_policy,
          })
        );
        setHostPremium(res.data.data.desired_premium.premium_per_guest);

        setIsCorpRetreat(
          res.data.data.desired_premium.is_corporate_retreat ? "true" : "false"
        );
        setIsPaymentPlan(
          typeof retreat.guest_payment_plan === "string"
            ? retreat.guest_payment_plan
            : retreat.guest_payment_plan!.toString()
        );
        setIsPolicyPlan(res.data.data.guest_cancellation_policy);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  // display all rooms
  const data: DataType[] = rooms.map((room, index) => ({
    key: `room-${room.id})}`,
    room: (
      <div className="tableRoom">
        <div>
          <img
            src={demo_images.DEMO_IMAGE_1}
            alt="room"
            width={52}
            height={52}
            className="rounded"
          />
        </div>
        <div className="d-flex flex-column gap-1">
          <h1>{room.name}</h1>
          {room.beds.map((bed) => (
            <p key={bed.bed_id}>
              {bed.quantity} bed {bed.bed_name}
            </p>
          ))}
        </div>
      </div>
    ),
    basePrice: (
      <div className="d-flex align-items-start">
        <SmallText
          text={`$ ${room.price} `}
          color="#949397"
          lineHeight={18.8}
          fontWeight={400}
          fontSize={16}
        />
      </div>
    ),
    markup: (
      <div className="mx-1 d-flex gap-1 align-items-center">
        <input
          type="number"
          className="form-control w-75"
          min={0}
          value={markupValues[room.id] ?? 0}
          onChange={(e) => handleMarkupChange(room.id, Number(e.target.value))}
          onKeyDown={blockInvalidChar}
        />
        <SmallText
          text="%"
          color="#949397"
          lineHeight={18.8}
          fontWeight={400}
          fontSize={16}
        />
      </div>
    ),
    value: (
      <div className="m-1">
        <input
          type="number"
          className="form-control w-100"
          min={0}
          value={finalValues[room.id] || room.price}
          readOnly
        />
      </div>
    ),
    tickets: (
      <SmallText
        text={`${room.tickets}`}
        color="#949397"
        lineHeight={18.8}
        fontWeight={400}
        fontSize={16}
      />
    ),
  }));

  useEffect(() => {
    getAllRooms();
    getAddonService();
    getDetailOfRetreat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className="container retreat-package"
        style={{ marginTop: "40px", overflowY: "hidden" }}
      >
        <div className="d-flex flex-column gap-5">
          <div>
            <StepFormHeader
              title="Retreat packages"
              description="Customize Your Retreat Pricing and Offers"
            />
          </div>
          <div className="d-flex gap-4">
            <div className="d-flex gap-4 flex-column flex-grow-1 accordian-section ">
              <StepFormAccordian
                title="Desired Premium"
                atStart={true}
                IsAdd={false}
                ImageName="LABEL"
              >
                <div className="d-flex flex-column gap-3">
                  <RadioCard
                    title="Is this a corporate retreat?"
                    options={retreatTypeOptions}
                    onSelect={setIsCorpRetreat}
                    selectedOption={isCorpRetreat}
                    cardWidth="350px"
                  />
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-1">
                      <SmallText
                        text="Specify your desired premium per guest?"
                        color="#19191C"
                        lineHeight={18.8}
                        fontWeight={400}
                        fontSize={16}
                      />
                      <SmallText
                        text="This is the Host's take home earnings per guest over and above all costs (e.g., $500 per person)."
                        color="#58585B"
                        lineHeight={18.8}
                        fontWeight={400}
                        fontSize={16}
                      />
                    </div>
                    <div
                      className="d-flex flex-column gap-1"
                      style={{ width: "484px" }}
                    >
                      <SmallText
                        text="Host Premium"
                        color="#58585B"
                        lineHeight={16.45}
                        fontSize={14}
                        fontWeight={500}
                      />
                      <div className="d-flex gap-2 flex-wrap">
                        <input
                          type="number"
                          placeholder="$0.00"
                          className="form-control w-50"
                          disabled={isCorpRetreat === "true" ? false : true}
                          min={0}
                          value={hostPremium === 0 ? "" : hostPremium}
                          onChange={(e) =>
                            setHostPremium(Number(e.target.value))
                          }
                          onKeyDown={blockInvalidChar}
                        />

                        <select
                          className="form-select"
                          disabled={isCorpRetreat === "true" ? false : true}
                          value={
                            retreat.desired_premium
                              ? retreat.desired_premium.premium_currency
                              : selectedCurrency
                          }
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                        >
                          <option value={"GBP"}>GBP</option>
                          <option value={"USD"}>USD</option>
                          <option value={"EUR"}>EUR</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </StepFormAccordian>

              <StepFormAccordian
                title="Retreat Pricing"
                atStart={false}
                IsAdd={false}
                ImageName="RetreatCoin"
              >
                <Table columns={columns} dataSource={data} />
              </StepFormAccordian>

              <StepFormAccordian
                title="Add-on"
                atStart={true}
                IsAdd={true}
                handleModal={() => openModal("addon")}
                ImageName="STAR"
              >
                <div
                  className={`${
                    addOnService && addOnService?.add_on_services.length > 0
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {addOnService &&
                      addOnService?.add_on_services.length > 0 ? (
                        <div className="d-flex gap-3 flex-wrap">
                          {addOnService?.add_on_services
                            .filter((card) => card.type === "Optional")
                            .map((card, index) => (
                              <CardComponent
                                key={`card ${index}`}
                                title={card.name}
                                description={card.description}
                                price={card.charges}
                                service_type={card.service_type}
                                width="280px"
                              />
                            ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </StepFormAccordian>
              <StepFormAccordian
                title="Guest Payment Plans"
                atStart={false}
                IsAdd={false}
                ImageName="WALLET"
              >
                <div className="d-flex flex-column gap-3">
                  <RadioCard
                    options={paymentsPlan}
                    onSelect={setIsPaymentPlan}
                    selectedOption={isPaymentPlan}
                    cardWidth="350px"
                  />
                  <div className="d-flex justify-content-between form-check form-switch p-0">
                    <SmallText
                      text="Enable waitlist when sold out?"
                      fontWeight={400}
                      lineHeight={18.8}
                      color="black"
                      fontSize={16}
                    />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      defaultChecked
                    ></input>
                  </div>
                </div>
              </StepFormAccordian>
              <StepFormAccordian
                title="Guest Cancellation Policy"
                atStart={false}
                IsAdd={false}
                ImageName="CANCEL"
              >
                <RadioCard
                  options={policyPlans}
                  onSelect={setIsPolicyPlan}
                  selectedOption={isPolicyPlan}
                  cardWidth="100%"
                />
              </StepFormAccordian>
            </div>

            <PriceSection />
          </div>
        </div>
      </div>
      <ModalComponent modalName="addon" title="Add-on Service">
        <SmallText
          text="Create your add on and customize it as you wish"
          lineHeight={21.15}
          fontSize={18}
          fontWeight={400}
          color="#717171"
        />
        <Form
          name="book-venue"
          form={form}
          onFinish={handleNewAddOn}
          layout="vertical"
        >
          <div className="d-flex flex-column gap-5 mt-4 addon-add ">
            <div>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please select a check-in date",
                  },
                ]}
              >
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  placeholder="enter add-on Name"
                />
              </Form.Item>
            </div>
            <div>
              <div className="d-flex justify-content-between form-check form-switch p-0">
                <SmallText
                  text="Tickets"
                  fontWeight={500}
                  lineHeight={16.45}
                  color="#58585B"
                  fontSize={14}
                />
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  defaultChecked
                  onChange={(e) => {
                    setIsSelectDisabled(!e.target.checked);
                  }}
                />
              </div>
              <Form.Item name="tickets" initialValue={0}>
                <Select
                  options={[
                    { value: "0", label: "0" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                  ]}
                  suffixIcon={<img src={images.DOWN_ARROW_ICON} alt="" />}
                  disabled={isSelectDisabled}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter a description",
                  },
                ]}
              >
                <textarea
                  className="form-control"
                  placeholder="enter your text here"
                ></textarea>
              </Form.Item>
            </div>
            <div>
              <div className="d-flex ">
                <SmallText
                  text="External link"
                  fontWeight={500}
                  lineHeight={16.45}
                  color="#58585B"
                  fontSize={14}
                />
                <SmallText
                  text="(optional)"
                  fontWeight={400}
                  lineHeight={16.45}
                  color="#949397"
                  fontSize={14}
                />
              </div>
              <Form.Item name="link">
                <input
                  type="url"
                  name="url"
                  id="url"
                  className="form-control"
                  placeholder="enter URL here"
                />
              </Form.Item>
            </div>
            <div>
              <div className="d-flex flex-column gap-3">
                <div>
                  <div className="d-flex justify-content-between form-check form-switch p-0">
                    <SmallText
                      text="Price"
                      fontWeight={500}
                      lineHeight={16.45}
                      color="#58585B"
                      fontSize={14}
                    />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      defaultChecked
                      onChange={(e) => {
                        setIsPriceDisabled(!e.target.checked);
                      }}
                    />
                  </div>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value && cardValue && !isPriceDisabled) {
                            return Promise.reject(
                              new Error("Please enter a price ")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="form-control"
                      placeholder="enter URL here"
                      min={0}
                      disabled={isPriceDisabled}
                      onKeyDown={blockInvalidChar}
                    />
                  </Form.Item>
                </div>
                <div className="icon-error">
                  <CardSelect card={cardDetail} handleCard={handleCardValue} />
                  <Form.Item
                    name="cardType"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            !value &&
                            form.getFieldValue("price") > 0 &&
                            !isPriceDisabled
                          ) {
                            return Promise.reject(
                              new Error("Please Select any one option")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input type="hidden" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex ">
                <SmallText
                  text="Icon"
                  fontWeight={500}
                  lineHeight={16.45}
                  color="#58585B"
                  fontSize={14}
                />
                <SmallText
                  text="(optional)"
                  fontWeight={400}
                  lineHeight={16.45}
                  color="#949397"
                  fontSize={14}
                />
              </div>

              <CardSelect
                icons={icons}
                handleCard={handleIconValue}
                handleIcon={handleIconValue}
              />
              <div className="icon-error">
                <Form.Item
                  name="icon"
                  rules={[
                    {
                      required: true,
                      message: "Please select an icon",
                    },
                  ]}
                >
                  <input type="hidden" />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div className="d-flex gap-3">
              <button className="back-btn" onClick={() => closeModal("addon")}>
                Cancel
              </button>
              <FormBtn
                type="submit"
                text="Save"
                className="w-100 mb-2 custom-button"
              />
            </div>
          </div>
        </Form>
      </ModalComponent>
      <StepFormFooter
        handleExit={() => handleRetreatPackage(true)}
        handleNextStep={() => handleRetreatPackage(false)}
        handlePreviousStep={handlePreviousStep}
        buttonText="continue"
      />
    </>
  );
};

export default RetreatPackage;
