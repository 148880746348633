import React, { useState } from "react";

// Third Party
import { toast } from "react-toastify";

//  custom components
import Button from "../../../UI/Button";
import ModalComponent from "../../../UI/ModalComponent";
import FormBtn from "../../../UI/InputElement/FormButton";

// constants
import { headerTab } from "../../../../constants";
import SmallText from "../../../UI/SmallText";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { adminVenueApproval } from "../../../../network";

// Redux
import { setShowModal } from "../../../../redux/features/component-slice";
import { useDispatch } from "react-redux";

interface HeaderProps {
  admin?: boolean;
  venueData?: any;
}

const SingleVenuePageHeader: React.FC<HeaderProps> = ({ admin, venueData }) => {
  const { call } = useApiCall();
  const dispatch = useDispatch();

  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [approvalBtnDisabled, setApprovalBtnDisabled] =
    useState<boolean>(false);

  // Modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
    setRejectionReason("");
  };

  // Handle Approval Btn Click in Modal
  const handleApprovalConfirmedClick = (
    venueId: string,
    approvalType: "approval" | "rejection"
  ) => {
    switch (approvalType) {
      case "approval":
        updateVenueApprovalAPI(venueId || "", { status: "Approved" });
        dispatch(setShowModal({ modalName: "approve", visible: false }));
        break;

      case "rejection":
        updateVenueApprovalAPI(venueId || "", {
          status: "Rejected",
          reason_if_rejected: rejectionReason.toString(),
        });
        dispatch(setShowModal({ modalName: "reject", visible: false }));
        setRejectionReason("");
        break;

      default:
        break;
    }
  };

  // APIs
  const updateVenueApprovalAPI = async (venueId: string, payload: any) => {
    call(
      () => adminVenueApproval(venueId, payload),
      (res) => {
        toast(res.data.message);
        setApprovalBtnDisabled(true);
      },
      (err) => {
        toast.error(err?.response?.data?.message);
      }
    );
  };

  const {
    form_state,
    is_deactivation_request,
    is_venue_approval_requested,
    is_approved,
  } = venueData;

  return (
    <>
      <div className="header-container d-flex">
        <div className="tabs-container d-flex flex-grow-1 ">
          {headerTab.map((tab, index) => (
            <div
              className="tab d-flex align-items-center"
              key={`tab-${index}`}
              style={{ width: "max-content" }}
            >
              <a href={`#${tab.link}`} className="text-decoration-none">
                {tab.title}
              </a>
            </div>
          ))}
        </div>

        <div className="select-venue-container d-flex align-items-center">
          <div>
            <div className="select-venue-price">
              <span>$ {venueData?.base_price}</span>
            </div>
            <div className="select-venue-label">
              <span>per night</span>
            </div>
          </div>
          {/* {admin ? (
            venueData?.form_state?.completed_status &&
            (venueData.is_deactivation_request ? (
              <div>
                <Button
                  type="button"
                  label="Deactivate"
                  onClick={() => openModal("deactivate")}
                  disabled={approvalBtnDisabled}
                />
              </div>
            ) : (
              venueData.is_venue_approval_requested && (
                <>
                  <div>
                    <Button
                      type="button"
                      label="Approve"
                      onClick={() => openModal("approve")}
                      disabled={approvalBtnDisabled}
                    />
                  </div>
                  {venueData.is_approved === null && (
                    <div>
                      <Button
                        type="button"
                        label="Reject"
                        onClick={() => openModal("reject")}
                        disabled={approvalBtnDisabled}
                      />
                    </div>
                  )}
                </>
              )
            ))
          ) : (
            <div>
              <Button type="button" label="Select this venue" />
            </div>
          )} */}

          {admin ? (
            form_state?.completed_status ? (
              is_deactivation_request ? (
                <div>
                  <Button
                    type="button"
                    label="Deactivate"
                    onClick={() => openModal("deactivate")}
                    disabled={approvalBtnDisabled}
                  />
                </div>
              ) : is_venue_approval_requested ? (
                <>
                  <div>
                    <Button
                      type="button"
                      label="Approve"
                      onClick={() => openModal("approve")}
                      disabled={approvalBtnDisabled}
                    />
                  </div>
                  {is_approved === null && (
                    <div>
                      <Button
                        type="button"
                        label="Reject"
                        onClick={() => openModal("reject")}
                        disabled={approvalBtnDisabled}
                      />
                    </div>
                  )}
                </>
              ) : null
            ) : null
          ) : (
            <div>
              <Button type="button" label="Select this venue" />
            </div>
          )}
        </div>
      </div>

      {/* Modal for Approvals */}
      <ModalComponent modalName="approve" title="Venue Listing Approval">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-2">
            <SmallText
              text="Venue Name :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={venueData.venue_title}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div>
            <SmallText
              text="Are you sure you want to approve this Venue?"
              color="#949397"
              fontSize={18}
              lineHeight={50}
              fontWeight={400}
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <FormBtn
              text="Approve"
              className="submit-btn"
              onClick={() => {
                handleApprovalConfirmedClick(venueData.venue_id, "approval");
              }}
            />
          </div>
        </div>
      </ModalComponent>

      {/* Modal for Rejections */}
      <ModalComponent modalName="reject" title="Venue Listing Rejection">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-2">
            <SmallText
              text="Venue Name :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <SmallText
              text={venueData.venue_title}
              color="#19191C"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
          </div>
          <div>
            <SmallText
              text="Are you sure you want to reject this venue?"
              color="#949397"
              fontSize={18}
              lineHeight={50}
              fontWeight={400}
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <SmallText
              text="Reason of Rejection :"
              color="#949397"
              fontSize={18}
              lineHeight={21.15}
              fontWeight={400}
            />
            <textarea
              rows={3}
              className="form-control"
              placeholder="Write your reason for rejection"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            ></textarea>
          </div>
          <div className="d-flex justify-content-end gap-3">
            <FormBtn
              text="Reject"
              className="submit-btn"
              onClick={() => {
                handleApprovalConfirmedClick(venueData.venue_id, "rejection");
              }}
            />
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default SingleVenuePageHeader;
