/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Third party
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Dropdown, MenuProps } from "antd";

// custom component
import ModalComponent from "../../../../UI/ModalComponent";
import RoomInfoModal from "./RoomInfoModal";
import CustomizeInfoModal from "./CustomizeInfoModal";
import CreateVenueFooter from "../CreateVenueFooter";
import EditRoomInfo from "./EditRoomInfo";
import EditCommonSpace from "./EditCommonSpace";

//  redux
import { setShowModal } from "../../../../../redux/features/component-slice";

// types
import { SpaceFormProps } from "../../../../../types/property-manager";

// images
import { images } from "../../../../../assets/images";

const SpacesForm: React.FC<SpaceFormProps> = ({
  roomDetails,
  venueId,
  fetchRoomDetails,
  handleNextStep,
  handlePreviousStep,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <button
            className="dropdown-item"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("room-info")}
          >
            <img src={images.GREEN_BED_ICON} alt="" height={"16px"} /> Bedroom
          </button>
          <ModalComponent
            modalName="room-info"
            title="Accommodations"
            className="modal-space-info"
            outsideClickClosable={false}
          >
            <RoomInfoModal venueId={venueId} fetchDetails={fetchRoomDetails} />
          </ModalComponent>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <button
            className="dropdown-item"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("customize-space-info")}
          >
            <img src={images.PAINT_BRUSH_ICON} alt="" height={"16px"} /> Shared
          </button>
          <ModalComponent
            modalName="customize-space-info"
            title="Shared Space "
            className="modal-space-info"
            outsideClickClosable={false}
          >
            <CustomizeInfoModal
              venueId={venueId}
              fetchDetails={fetchRoomDetails}
            />
          </ModalComponent>
        </div>
      ),
    },
  ];

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const saveDetails = () => {
    navigate("/venues/my-venues");
  };

  return (
    <div className="add-spaces-cards-container d-flex flex-column h-100 mb-5">
      <div className="spaces-dropdown-container d-flex justify-content-between align-items-center gap-2 position-relative">
        <section className="create-venue-page-title">
          <h1 className="title">Add Spaces</h1>
          <h4 className="subtitle">
            Showcase accommodations and shared areas to help retreat hosts
            design the perfect experience.
          </h4>
        </section>
        {(roomDetails.rooms.length !== 0 ||
          roomDetails.common_spaces.length !== 0) && (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <button type="button" className="custom-button">
              <img
                src={images.PLUS_WHITE}
                alt=""
                width={"16px"}
                height={"16px"}
              />{" "}
              New space
            </button>
          </Dropdown>
        )}
      </div>
      <div
        className={`spaces-cards-container flex-grow-1 d-flex  ${
          roomDetails.rooms.length === 0 &&
          roomDetails.common_spaces.length === 0
            ? `flex-column mt-5`
            : `justify-content-start flex-wrap mt-4 gap-4 align-items-start`
        }`}
      >
        {roomDetails.rooms.length === 0 &&
          roomDetails.common_spaces.length === 0 && (
            <div className="empty-spaces d-flex flex-column align-items-center">
              <img src={images.SPACES_BACKGROUND_IMAGE} alt="" />
              <div className="text-center">
                <h3 className="common-lg-bold-dark-text">
                  Add Your Venue Spaces
                </h3>
              </div>
              <Dropdown menu={{ items }} className="mt-4">
                <button
                  type="button"
                  style={{ width: "268px" }}
                  className="custom-button d-flex align-items-center justify-content-center gap-1"
                >
                  <img src={images.PLUS_WHITE} alt="" height={16} />
                  New space
                </button>
              </Dropdown>
            </div>
          )}

        <>
          {roomDetails.rooms.map((item) => (
            <div key={`room-detail-${item.id}`}>
              <Card
                hoverable
                cover={
                  item?.image_urls.length > 0 ? (
                    <img
                      src={item?.image_urls[0]?.image_url}
                      alt="..."
                      style={{
                        height: "208px",
                        width: "322px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={images.NO_IMAGE}
                      alt="..."
                      style={{
                        height: "208px",
                        width: "322px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
                style={{ width: "322px", height: "283px" }}
                onClick={() => openModal(item.id)}
              >
                <div className="card-body">
                  <div className="common-md-medium-dark-text mb-1 tex-overflow-handle">
                    {item.name}{" "}
                    <span className="common-sm-light-text">
                      {" "}
                      x{item.quantity}
                    </span>
                  </div>
                  <div className="card-text tex-overflow-handle">
                    {item.beds?.map((bed, index) => (
                      <span
                        className="common-sm-light-grey-text"
                        key={`bed-${index}`}
                      >
                        {bed.quantity} {bed.bed_type}{" "}
                        {item.beds && index < item.beds.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </div>
                </div>
              </Card>
              <ModalComponent
                modalName={item.id}
                title="Accommodations"
                className="modal-space-info"
                outsideClickClosable={false}
              >
                <EditRoomInfo
                  venueId={venueId}
                  fetchDetails={fetchRoomDetails}
                  roomData={item}
                />
              </ModalComponent>
            </div>
          ))}
          {roomDetails.common_spaces.map((item) => {
            return (
              <div key={`space-detail-${item.id}`}>
                <Card
                  hoverable
                  cover={
                    item?.image_urls.length > 0 ? (
                      <img
                        src={item?.image_urls[0].image_url || images.NO_IMAGE}
                        alt="..."
                        style={{
                          height: "208px",
                          width: "322px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img
                        src={images.NO_IMAGE}
                        alt="..."
                        style={{
                          height: "208px",
                          width: "322px",
                          objectFit: "cover",
                        }}
                      />
                    )
                  }
                  style={{ width: "322px", height: "283px" }}
                  onClick={() => openModal(item.id)}
                >
                  <div className="card-body">
                    <div className="common-md-medium-dark-text mb-1 tex-overflow-handle">
                      {item.name}{" "}
                      <span className="common-sm-light-text">
                        {" "}
                        x{item.quantity}
                      </span>
                    </div>
                  </div>
                </Card>
                <ModalComponent
                  modalName={item.id}
                  title="Shared Space "
                  className="modal-space-info"
                  outsideClickClosable={false}
                >
                  <EditCommonSpace
                    venueId={venueId}
                    fetchDetails={fetchRoomDetails}
                    roomData={item}
                  />
                </ModalComponent>
              </div>
            );
          })}
        </>
      </div>
      <CreateVenueFooter
        continueButtonType="button"
        disabled={
          roomDetails.rooms.length === 0
            ? roomDetails.common_spaces.length === 0
            : false
        }
        saveVenueDetails={saveDetails}
        continueOnclick={() => handleNextStep(venueId)}
        handleBackButton={handlePreviousStep}
      />
    </div>
  );
};

export default SpacesForm;
