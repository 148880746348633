/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Form, UploadProps, Upload, UploadFile, Image, FormProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// custom component
import FormLabel from "../../../../UI/FormLabel";
import InputComponent from "../../../../UI/InputComponent";
import SelectOption from "../../../../UI/SelectOption";
import TextareaComponent from "../../../../UI/TextareaComponent";
import Button from "../../../../UI/Button";
import SmallText from "../../../../UI/SmallText";

// redux
import { RootState } from "../../../../../redux/store";
import { setShowModal } from "../../../../../redux/features/component-slice";

// network
import {
  createRoom,
  fetchCommonSpaceAmenities,
  uploadImage,
} from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// helpers
import { getBase64 } from "../../../../../library";

// types
import { FileType } from "../../../../../types";
import {
  CustomizeInfoModalProps,
  CustomSpaceTypes,
} from "../../../../../types/property-manager";

// constants
import { DEFAULT_NUMBER_OPTION } from "../../../../../constants/property-manager";

// images
import { images } from "../../../../../assets/images";

const CustomizeInfoModal: React.FC<CustomizeInfoModalProps> = ({
  venueId,
  fetchDetails,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [amenities, setAmenities] = useState<string[]>([]);
  const [rooms_quantity, setRoomsQuantity] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [roomAmenities, setRoomAmenities] = useState<
    {
      id: string;
      name: string;
      icon_id: string;
    }[]
  >([]);

  const { call } = useApiCall();

  const imageUploadProps: UploadProps = {
    name: "images",
    multiple: true,
    fileList: fileList,
    listType: "picture-card",
    accept: "image/*",
    beforeUpload(file) {
      const isImage = file.type && file.type.startsWith("image/");
      if (!isImage) {
        toast.error("You can only upload image files.");
        return Upload.LIST_IGNORE;
      }

      setFileList((prevFileList) => [...prevFileList, file]);
      return false; // Prevents upload by default
    },

    onChange({ fileList: newFileList }) {
      const filteredFileList = newFileList.filter(
        (file) => file.type && file.type.startsWith("image/")
      );
      setFileList(filteredFileList);
      // Manually set images in the form
      form.setFieldsValue({
        images: filteredFileList.map((file) => file.originFileObj),
      });

      form
        .validateFields(["roomImages"])
        .then(() => {
          form.setFields([
            {
              name: "roomImages",
              errors: [],
            },
          ]);
        })
        .catch(() => {});
    },

    onRemove(file) {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      // Update form after file removal
      form.setFieldsValue({
        images: newFileList.map((file) => file.originFileObj),
      });
    },

    onPreview: async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
  };

  const uploadButton = (
    <button className="border-0 bg-white" type="button">
      <img src={images.UPLOAD_BUTTON_ICON} alt="add_photo_icon" />
    </button>
  );

  const handleFinalSubmit = async (
    values: CustomSpaceTypes,
    images: string[]
  ) => {
    values.images = images;
    values.amenities = amenities;
    values.quantity = rooms_quantity;

    const data = {
      name: values.name,
      quantity: values.quantity,
      description: values.description,
      images: images,
      amenities: values.amenities,
      space_type: "Common",
    };

    call(
      () =>
        createRoom({
          venue_id: venueId as string,
          data: data,
        }),
      (res) => {
        form.resetFields();
        setFileList([]);
        dispatch(
          setShowModal({
            modalName: "customize-space-info",
            visible: false,
          })
        );
        fetchDetails();
        toast.success(res.data?.message || "Room created successfully");
        setSubmitting(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to create room");
        setSubmitting(false);
      }
    );
  };

  const handleOnFinish: FormProps<CustomSpaceTypes>["onFinish"] = async (
    values
  ) => {
    const formData = new FormData();
    fileList.map(async (file) => {
      formData.append("files", file.originFileObj as any);
    });

    setSubmitting(true);
    call(
      () => uploadImage(formData),
      (res) => {
        handleFinalSubmit(values, res.data.data);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to upload images");
        setSubmitting(false);
      }
    );
  };

  const getAmenities = async () => {
    call(
      () => fetchCommonSpaceAmenities(),
      (res) => {
        setRoomAmenities(res.data.data);
      },
      (err) => {
        toast.error("Failed to fetch room amenities");
      }
    );
  };

  useEffect(() => {
    if (
      fileList.length > 0 &&
      form.getFieldValue("name") &&
      form.getFieldValue("quantity") &&
      form.getFieldValue("description") &&
      form.getFieldValue("amenities")
    ) {
      setIsFormValid(true);
    }
    if (fileList.length === 0) {
      setIsFormValid(false);
    }
  }, [form.getFieldsValue()]);

  useEffect(() => {
    getAmenities();
  }, []);

  const handleFormChange = () => {
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    const isTouched = form.isFieldsTouched(true);
    setIsFormValid(!hasErrors && isTouched); // Disable if errors or fields aren't touched
  };

  return (
    <div className="room-modal-info-container">
      <div className="room-modal-subtitle">
        Add and edit details about shared spaces
      </div>
      <Form
        form={form}
        className="room-modal-form-container"
        onFinish={handleOnFinish}
        onFieldsChange={handleFormChange}
      >
        <div className="room-modal-form">
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <div className="d-flex flex-column gap-1">
                    <FormLabel label="Name of Space" />
                    <InputComponent
                      type="text"
                      placeholder="Enter space name"
                      width={"100%"}
                    />
                  </div>
                </Form.Item>
              </div>
              <div style={{ minWidth: "112px" }}>
                <div className="d-flex flex-column gap-1">
                  <FormLabel label="Quantity" />
                  <Form.Item
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required",
                      },
                    ]}
                  >
                    <SelectOption
                      onChange={(value) => {
                        setRoomsQuantity(value);
                      }}
                      options={DEFAULT_NUMBER_OPTION}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <Form.Item
                  name="description"
                  rules={[
                    { required: true, message: "Description is required" },
                  ]}
                >
                  <div className="d-flex flex-column gap-1">
                    <FormLabel label="Space Description" />
                    <TextareaComponent placeholder="Enter description" />
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100 create-venue-file-upload">
              <div className="flex-grow-1">
                <div className="d-flex flex-column gap-1 spaces-image-upload">
                  <FormLabel label="Upload Space Photos" />
                  <Form.Item
                    name="images"
                    rules={[
                      {
                        required: true,
                        validator(_, value) {
                          if (fileList.length === 0) {
                            return Promise.reject(
                              "Please upload at least one photo."
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {fileList.length === 0 && (
                      <Dragger {...imageUploadProps} defaultFileList={[]}>
                        <p className="ant-upload-text">
                          <img
                            src={images.UPLOAD_BLACK_ICON}
                            alt="upload icon"
                            height={24}
                          />
                          <br />
                          Upload a photo or{" "}
                          <SmallText text="click here" fontSize={16} />
                        </p>
                      </Dragger>
                    )}
                    {fileList.length > 0 && (
                      <Upload
                        {...imageUploadProps}
                        style={{ marginTop: "12px !important" }}
                      >
                        {fileList.length >= 24 ? null : uploadButton}
                      </Upload>
                    )}
                    {previewImage && (
                      <>
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      </>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="form-white-bg w-100">
            <div className="d-flex gap-2 w-100">
              <div className="flex-grow-1">
                <div className="d-flex flex-column gap-1">
                  <FormLabel label="Space Amenities" />
                  <div
                    className={`d-flex flex-column gap-1 recommended_retreats`}
                  >
                    <Form.Item
                      name="amenities"
                      rules={[
                        { required: true, message: "Amenities are required" },
                      ]}
                    >
                      <SelectOption
                        showSearch={false}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Write or select"
                        onChange={(value) => {
                          setAmenities(value);
                        }}
                        options={roomAmenities?.map((item) => {
                          const icon = icons?.find(
                            (icon) => icon.id === item.icon_id
                          );

                          return {
                            value: item.id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    icon?.icon_url ||
                                    images.AIR_CONDITIONING_BLUE_ICON
                                  }
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <span style={{ marginLeft: "8px" }}>
                                  {item.name}
                                </span>
                              </div>
                            ),
                          };
                        })}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end"
            style={{ bottom: "0px" }}
          >
            <div>
              <Button
                type="button"
                label="Cancel"
                className="bordered-button"
                onClick={() => {
                  dispatch(
                    setShowModal({
                      modalName: "customize-space-info",
                      visible: false,
                    })
                  );
                  form.resetFields();
                  setFileList([]);
                }}
              />
            </div>
            <div>
              <Button
                label={submitting === true ? "Saving..." : "Save"}
                disabled={submitting || !isFormValid}
                onClick={() => handleOnFinish(form.getFieldsValue())}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CustomizeInfoModal;
