import React from "react";
import { useDispatch } from "react-redux";
import { setShowModal } from "../../../../redux/features/component-slice";

//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";
import MapComponent from "../../../UI/MapComponent/MapComponent";

//images
import { images } from "../../../../assets/images";

const RetreatLocation: React.FC = () => {
  const dispatch = useDispatch();
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">Location</p>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
          <MapComponent
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
            postalCodeName={"388001"}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column  gap-3 map-description">
          <p className="section-description ">
            Interlaken, situated between the beautiful lakes Thun and Brienz, is
            a charming destination in the Swiss Alps. With a wealth of cultural
            and shopping activities on offer, this town is perfect for both
            adventurers and those seeking relaxation in the midst of nature.
          </p>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-2">
              <SmallText
                text="Nearby:"
                color="#949397"
                lineHeight={18.8}
                fontSize={16}
                fontWeight={500}
              />
              <div>
                <p className="section-description-title">Interlaken Museum </p>
                <p className="section-description ">
                  Discover the local history and the development of the city.
                </p>
                <SmallText
                  text="5 min drive"
                  fontWeight={400}
                  lineHeight={16.45}
                  color="#949397"
                  fontSize={14}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <SmallText
                text="Nearby:"
                color="#949397"
                lineHeight={18.8}
                fontSize={16}
                fontWeight={500}
              />
              <div>
                <p className="section-description-title">Interlaken Museum </p>
                <p className="section-description ">
                  Discover the local history and the development of the city.
                </p>

                <SmallText
                  text="5 min drive"
                  fontWeight={400}
                  lineHeight={16.45}
                  color="#949397"
                  fontSize={14}
                />
              </div>
            </div>
            <div className="venueIcon">
              <button
                className="modal-open-button"
                style={{
                  border: "none",
                  background: "transparent",
                  padding: 0,
                }}
                type="button"
                onClick={() => openModal("location")}
              >
                <SmallText text="Read more" />
                <img src={images.RIGHT_ARROW_ICON} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent modalName="location" title="Location">
        <p className="section-description ">
          Interlaken, situated between the beautiful lakes Thun and Brienz, is a
          charming destination in the Swiss Alps. With a wealth of cultural and
          shopping activities on offer, this town is perfect for both
          adventurers and those seeking relaxation in the midst of nature.
        </p>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column gap-2">
            <SmallText
              text="Nearby:"
              color="#949397"
              lineHeight={18.8}
              fontSize={16}
              fontWeight={500}
            />
            <div>
              <p className="section-description-title">Interlaken Museum </p>
              <p className="section-description ">
                Discover the local history and the development of the city.
              </p>
              <SmallText
                text="5 min drive"
                fontWeight={400}
                lineHeight={16.45}
                color="#949397"
                fontSize={14}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <SmallText
              text="Nearby:"
              color="#949397"
              lineHeight={18.8}
              fontSize={16}
              fontWeight={500}
            />
            <div>
              <p className="section-description-title">Interlaken Museum </p>
              <p className="section-description ">
                Discover the local history and the development of the city.
              </p>

              <SmallText
                text="5 min drive"
                fontWeight={400}
                lineHeight={16.45}
                color="#949397"
                fontSize={14}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default RetreatLocation;
