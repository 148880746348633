import React from "react";

//common component
import SmallText from "../SmallText";

//types
import { CardComponentProps } from "../../../types";

//images
import { images } from "../../../assets/images";

//css
import "./index.css";

const CardComponent: React.FC<CardComponentProps> = ({
  icon,
  title,
  description,
  price,
  width,
  service_type,
}) => {
  return (
    <div
      className="d-flex flex-column gap-2 card-detail"
      style={{ width: width }}
    >
      <div className="d-flex gap-2 align-items-center">
        <img src={images.STAR} alt="" width={28} height={28} />
        <SmallText
          text={title}
          color="#2F206A"
          lineHeight={21.15}
          fontSize={18}
          fontWeight={400}
        />
      </div>
      <div>
        <SmallText
          text={description}
          color="#58585B"
          lineHeight={16.45}
          fontSize={14}
          fontWeight={400}
        />
      </div>
      <div className="d-flex flex-grow-1 align-items-end">
        {price && price  ? (
          <SmallText
            text={`$${price}`}
            color="#19191C"
            lineHeight={23.5}
            fontSize={20}
            fontWeight={500}
          />
        ) : "Free"}
        {service_type && price ? (
          <SmallText
            text={`/${service_type}`}
            color="#58585B"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
          />
        ) : ""}
      </div>
    </div>
  );
};

export default CardComponent;
