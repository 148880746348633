import React, { CSSProperties } from "react";
// Third Party
import { HardDrive } from "lucide-react";

// Styles
import "./style.css";

interface NoDataFoundProps {
  boxStyle?: CSSProperties;
  textStyle?: CSSProperties;
  iconSize?: string | number;
  text?: string;
}

const NoDataFound: React.FC<NoDataFoundProps> = ({
  boxStyle,
  textStyle = { fontSize: 18 },
  iconSize = 48,
  text = "No Data",
}) => {
  return (
    <div className="no-data-container" style={{ ...boxStyle }}>
      <div className="no-data-icon">
        <HardDrive size={iconSize} />
      </div>
      <div className="no-data-message">
        <p style={{ ...textStyle }}>{text}</p>
      </div>
    </div>
  );
};

export default NoDataFound;
