// Third Party
import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import Cookies from "js-cookie";

// Component
import { handleLogout } from "../components/core/Authentication/Logout";

const createApiInstance = (baseUrl: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    responseType: "json",
  });

  // Request interceptor for token handling
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      try {
        const token = Cookies.get("userToken");
        config.headers = config.headers || {};
        config.headers.Authorization = token ? `Bearer ${token}` : "";

        return config;
      } catch (error) {
        console.error("Error in request interceptor:", error);
        return config;
      }
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor for error handling
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      // Handle 403 Forbidden
      if (error.response?.status === 403 || error.response?.status === 401) {
        handleLogout();
        return;
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createApiInstance;
