/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

// Third Party
import { Input } from "antd";
import { debounce } from "lodash";
import { toast } from "react-toastify";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";
import RetreatListingTable from "./RetreatListingTable";

// APIs
import { useApiCall } from "../../../../hooks/useApiCall";
import { adminRetreatList } from "../../../../network";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Constants
import { RetreatQueryParams } from "../../../../types/admin";

// Utility
import { convertToTitleCase } from "../../../../library";

// images
import { images } from "../../../../assets/images";

const RetreatListing: React.FC = () => {
  const { call } = useApiCall();

  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );

  // NOTE : This is the total number of items
  // NOTE : Static as currently it is not provided from the backend
  const initialLimitValue = 6;

  const [retreats, setRetreats] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [queryParams, setQueryParams] = useState<RetreatQueryParams>({
    limit: initialLimitValue,
    offset: 0,
    search: "",
  });

  const [loading, setLoading] = useState(true);

  const getRetreatsData = async () => {
    setLoading(true);
    call(
      () => adminRetreatList({ queryParamsObj: queryParams }),
      (res) => {
        setRetreats(res.data.data);
        setTotalItemCount(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      }
    );
  };

  // Handle search with debounce
  const handleSearch = useCallback(
    debounce((value: string) => {
      setQueryParams((prev: any) => ({
        ...prev,
        search: value,
        limit: initialLimitValue,
        offset: 0,
      }));
      setCurrentPage(1);
    }, 1000),
    []
  );
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setQueryParams((prev) => ({
      ...prev,
      offset: (page - 1) * initialLimitValue,
    }));
  };

  useEffect(() => {
    getRetreatsData();
  }, [queryParams]);

  return (
    <AdminDashboardLayout
      pageTitle={`Welcome,  ${convertToTitleCase(
        userProfileData.first_name
      )} 👋`}
      additionalClassNames="mt-4"
    >
      <div className="payouts user-home-table admin-users bg-white">
        <div className="d-flex gap-3 justify-content-between">
          <div className="common-xl-medium-dark-text">Retreat</div>
          <div className="d-flex gap-2 justify-content-end ">
            <div>
              <Input
                placeholder="Search"
                prefix={<img src={images.SEARCH_ICON} height={"24px"} alt="" />}
                className="common-sm-dark-text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <RetreatListingTable
          retreatData={retreats}
          totalItems={totalItemCount}
          currentPage={currentPage}
          pageSize={initialLimitValue}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </div>
    </AdminDashboardLayout>
  );
};

export default RetreatListing;
