import React from "react";

// custom components
import ChatList from "./ChatList";

// types
import { FirebaseUser } from "../../../types";

type MessageSidebarProps = {
  newUserList?: any[];

  users: FirebaseUser[];
  lastMessages: any;
  unseenCounts: any;
  selectedChatUserId?: string;
  setSelectedUser: (user: FirebaseUser) => void;
  setIsChatWindowActive: (activeState: { [key: string]: boolean }) => void;
  fetchMessageForSelectedUser: (user: FirebaseUser | null) => void;
  onSearch: (value: string) => void;
  chatsLoading?: boolean;
};

const MessageSidebar: React.FC<MessageSidebarProps> = ({
  newUserList = [],
  users,
  lastMessages,
  unseenCounts,
  selectedChatUserId,
  setSelectedUser,
  setIsChatWindowActive,
  fetchMessageForSelectedUser,
  onSearch,
  chatsLoading,
}) => {
  return (
    <div className="chat-sidebar">
      <ChatList
        newUsersList={newUserList}
        usersList={users}
        setSelectedUser={setSelectedUser}
        lastMessages={lastMessages}
        unseenCounts={unseenCounts}
        selectedChatUserId={selectedChatUserId}
        onFocus={setIsChatWindowActive}
        onSearch={onSearch}
        fetchMessageForSelectedUser={fetchMessageForSelectedUser}
        chatsLoading={chatsLoading}
      />
    </div>
  );
};

export default MessageSidebar;
