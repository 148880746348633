/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Link, useSearchParams } from "react-router-dom";
import { Steps } from "antd";

// custom components
import CreateVenueFormStep1 from "./CreateVenueFormStep1";
import CreateVenueFormStep2 from "./CreateVenueFormStep2";
import CreateVenueFormStep3 from "./CreateVenueFormStep3";
import CreateVenueFormStep4 from "./CreateVenueFormStep4";
import CreateVenueFormStep5 from "./CreateVenueFormStep5";
import CreateVenueFormStep6 from "./CreateVenueFormStep6";
import CreateVenueFormStep7 from "./CreateVenueFormStep7";
import Header from "../../Header";
import Loader from "../../../UI/Loader";

// redux
import { RootState } from "../../../../redux/store";

// hooks
import { useSelector } from "react-redux";

const CreateVenue: React.FC = () => {
  const formStepSelector = useSelector(
    (state: RootState) => state.commonReducer.createVenueFormStep
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true); // New state for loading
  const [searchParams, setSearchParams] = useSearchParams();

  const venue_id = searchParams.get("venue_id") as string;

  const handleNextStep = (query?: string) => {
    if (query) {
      searchParams.set("venue_id", query);
      setSearchParams(searchParams);
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      key: "1",
      title: "Venue",
      content: (
        <CreateVenueFormStep1
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      key: "2",
      title: "Spaces",
      content: (
        <CreateVenueFormStep2
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      key: "3",
      title: "Amenities",
      content: (
        <CreateVenueFormStep3
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      key: "4",
      title: "Add-on Services",
      content: (
        <CreateVenueFormStep4
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      key: "5",
      title: "Terms & Conditions",
      content: (
        <CreateVenueFormStep5
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      key: "6",
      title: "Pricing & Availability",
      content: (
        <CreateVenueFormStep6
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      key: "7",
      title: "Preview",
      content: (
        <CreateVenueFormStep7
          venue_id={venue_id}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  useEffect(() => {
    setLoading(true);
    if (venue_id) {
      setCurrentStep(formStepSelector);
      setLoading(false);
    } else {
      setLoading(false); // No venue_id, stop loading
    }
  }, [formStepSelector]);

  if (loading) {
    return <Loader />; // Show a loading message while fetching
  }

  return (
    <div className="create-venue">
      <Header>
        <div className="d-flex gap-3">
          <div className="step-header">
            <div></div>
            <div className="step-container">
              <Steps
                current={currentStep}
                items={items}
                labelPlacement="vertical"
              />
            </div>
          </div>
          <button className="preview-button" style={{ marginRight: "40px" }}>
            <Link
              to={`/venues/${venue_id ? `${venue_id}` : ""}`}
              className=""
              target="_blank"
            >
              Preview Listing
            </Link>
          </button>
        </div>
      </Header>
      <div className="steps-content">{steps[currentStep].content}</div>
    </div>
  );
};

export default CreateVenue;
