import React, { InputHTMLAttributes } from "react";

// styles
import "./style.css";

const InputComponent: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  placeholder,
  type,
  defaultValue,
  itemRef,
  ...props
}) => {
  return (
    <input
      ref={itemRef}
      type={type}
      placeholder={placeholder}
      className="input-component"
      defaultValue={defaultValue}
      maxLength={255}
      {...props}
    />
  );
};

export default InputComponent;
