/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import {
  Controller,
  useFieldArray,
  useForm,
  UseFormGetValues,
} from "react-hook-form";
import { Form, Image, Space, Upload, UploadProps } from "antd";
import type { UploadFile } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

// custom component
import ClickableTags from "../../../../UI/ClickableTags";
import FormLabel from "../../../../UI/FormLabel";
import SelectOption from "../../../../UI/SelectOption";
import InputComponent from "../../../../UI/InputComponent";
import TextareaComponent from "../../../../UI/TextareaComponent";
import Button from "../../../../UI/Button";
import CreateVenueFooter from "../CreateVenueFooter";
import SmallText from "../../../../UI/SmallText";
import VideoPreview from "./VideoPreview";

// redux
import { RootState } from "../../../../../redux/store";

// network
import {
  deleteBulkImages,
  updateVenue,
  uploadImage,
} from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// helpers
import { getBase64 } from "../../../../../library";

// types
import { FormValues, Step1Props } from "../../../../../types/property-manager";
import { FileType } from "../../../../../types";

// constants
import { countryOptions } from "../../../../../constants";
import { DEFAULT_NUMBER_OPTION } from "../../../../../constants/property-manager";

// images
import { images } from "../../../../../assets/images";

const AddVenueDetails: React.FC<Step1Props> = ({
  highlights,
  languages,
  propertyTypes,
  retreatsHostTypes,
  venueData,
  venueIdExisting,
  handleNextStep,
}) => {
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const navigate = useNavigate();

  const { call } = useApiCall();
  const {
    getValues,
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      type_of_property_id: "",
      number_of_rooms: 1,
      number_of_bathrooms: 1,
      sleeps: 1,
      custom_paragraphs: [],
      venue_highlights_id: [],
      recommended_retreats: [],
      language_id: [],
      zip_code: "",
      country: "",
      property_name: "",
      city: "",
      state: "",
      address: "",
      property_description: "",
      base_price: 500,
    },
  });

  const videoUrl = watch("media_url");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [venue_highlights_id, setVenueHighlights] = useState(0);
  const [recommended_retreats, setRecommendedRetreats] = useState(0);
  const [language_id, setLanguage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formStep, setFormStep] = useState<number>(0);
  const [defaultRecommendedRetreats, setDefaultRecommendedRetreats] = useState<
    string[]
  >([]);
  const [defaultVenueHighlights, setDefaultVenueHighlights] = useState<
    string[]
  >([]);
  const [defaultLanguages, setDefaultLanguages] = useState<string[]>([]);
  const [defaultPropertyTypes, setDefaultPropertyTypes] = useState<string[]>(
    []
  );
  const [localVenueHighlights, setLocalVenueHighlights] = useState<
    { icon_id: string; id: string; name: string }[]
  >([]);

  const [venueImages, setVenueImages] = useState<
    {
      id: string;
      image_url: string;
    }[]
  >([]);

  const [initialValues, setInitialValues] = useState<any>();
  const [hoveredImage, setHoveredImage] = useState<number | null>(null);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "custom_paragraphs",
  });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <button className="border-0 bg-transparent" type="button">
      <img src={images.UPLOAD_BUTTON_ICON} alt="add_photo_icon" />
    </button>
  );

  const handleMouseEnter = (index: number) => {
    setHoveredImage(index);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const handleStoreImagesToDelete = (id: string) => {
    setImagesToDelete([...imagesToDelete, id]);
    setVenueImages(venueImages.filter((image) => image.id !== id));
  };

  const handleDeleteImage = async () => {
    call(
      () => deleteBulkImages({ reference_ids: imagesToDelete }),
      (res) => {
        setImagesToDelete([]);
      }
    );
  };

  const finalSubmit = ({
    data,
    images,
    partial,
    url,
  }: {
    data: FormValues;
    images: string[];
    partial?: boolean;
    url?: string;
  }) => {
    data = {
      ...data,
      images: images,
      partial: partial || false,
      base_price: 500,
    };
    if (imagesToDelete.length > 0) {
      handleDeleteImage();
    }
    call(
      () => updateVenue({ data }),
      (res) => {
        setSubmitting(false);
        url ? navigate(url) : handleNextStep!(res.data?.data?.venue_id);
        toast.success(res.data?.message || "Venue created successfully");
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to create venue");
        setSubmitting(false);
      }
    );
  };

  const finalUpdateDetailsWithImage = ({
    data,
    imagesUpload,
    imagesList,
    url,
    partial,
  }: {
    data: FormValues;
    imagesUpload: boolean;
    imagesList?: string[];
    url?: string;
    partial?: boolean;
  }) => {
    try {
      data = {
        ...data,
      };

      if (data.property_name) {
        data = { ...data, partial: partial || false, base_price: 500 };
        if (imagesUpload === true) {
          data = { ...data, images: imagesList };
        }
        if (imagesToDelete.length > 0) {
          handleDeleteImage();
        }
        call(
          () => updateVenue({ data, venue_id: venueIdExisting }),
          (res) => {
            setSubmitting(false);
            url ? navigate(url) : handleNextStep!(res.data?.data?.venue_id);
            toast.success(res.data?.message || "Details saved successfully");
            setDisableSaveButton(false);
          },
          (err) => {
            toast.error(
              err?.response?.data?.message || "Saving details failed"
            );
            setSubmitting(false);
            setDisableSaveButton(false);
          }
        );
      } else {
        trigger("property_name");
        setSubmitting(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const onSubmit = async (data: FormValues) => {
    const isFormChanged =
      JSON.stringify(initialValues) !== JSON.stringify(getValues());

    const formData = new FormData();
    fileList.map(async (file) => {
      formData.append("files", file.originFileObj as any);
    });

    if (formStep === 0 && !venueData) {
      setSubmitting(true);
      call(
        () => uploadImage(formData),
        (res) => {
          finalSubmit({ data: data, images: res.data.data });
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to upload images"
          );
        }
      );
    } else {
      if (isFormChanged) {
        setSubmitting(true);
        let data: any = getValues();

        for (const key in data) {
          if (
            (Array.isArray(data[key]) && data[key].length === 0) ||
            (typeof data[key] === "string" && data[key].trim() === "") ||
            (typeof data[key] === "object" &&
              Object.keys(data[key]).length === 0)
          ) {
            delete data[key];
          }
        }

        if (fileList.length > 0) {
          call(
            () => uploadImage(formData),
            (res) => {
              finalUpdateDetailsWithImage({
                data,
                imagesUpload: true,
                imagesList: res.data.data,
              });
            },
            (err) => {
              toast.error(
                err?.response?.data?.message || "Failed to upload images"
              );
            }
          );
        } else {
          finalUpdateDetailsWithImage({ data, imagesUpload: false });
        }
      } else {
        handleNextStep!();
      }
    }
  };

  const saveData = async (getValues: UseFormGetValues<FormValues>) => {
    if (disableSaveButton) return;

    setDisableSaveButton(true);
    let data: any = getValues();
    const formData = new FormData();
    fileList.map(async (file) => {
      formData.append("files", file.originFileObj as any);
    });
    for (const key in data) {
      if (
        (Array.isArray(data[key]) && data[key].length === 0) ||
        (typeof data[key] === "string" && data[key].trim() === "") ||
        (typeof data[key] === "object" && Object.keys(data[key]).length === 0)
      ) {
        delete data[key];
      }
    }

    if (fileList.length > 0) {
      call(
        () => uploadImage(formData),
        (res) => {
          finalUpdateDetailsWithImage({
            data,
            imagesUpload: true,
            url: "/venues/my-venues",
            partial: true,
          });
        },
        (err) => {
          toast.error(
            err?.response?.data?.message || "Failed to upload images"
          );
        }
      );
    } else {
      finalUpdateDetailsWithImage({
        data,
        imagesUpload: false,
        url: "/venues/my-venues",
        partial: true,
      });
    }
  };

  const handleAssignInitialValues = () => {
    const retreat_host_type: string[] = [];
    const venueHighlights: string[] = [];
    const preLanguages: string[] = [];
    const prePropertyTypes: string[] = [];
    let localPropertyType: string = "";

    const localRetreatHostType: {
      icon_id: string;
      id: string;
      name: string;
    }[] = [];

    const locaHighlights: {
      icon_id: string;
      id: string;
      name: string;
    }[] = [];

    venueData?.recommended_retreats?.forEach((retreat) => {
      retreatsHostTypes?.forEach((type) => {
        if (retreat.name === type.name) {
          retreat_host_type.push(type.id);
          localRetreatHostType.push({
            icon_id: type.icon_id,
            id: type.id,
            name: type.name,
          });
        }
      });
    });

    setDefaultRecommendedRetreats(retreat_host_type);

    venueData?.highlights?.forEach(
      (highlight: { icon_id: string; name: string }) => {
        highlights?.forEach((type) => {
          if (highlight?.name === type.name) {
            venueHighlights.push(type.id);
            locaHighlights.push({
              icon_id: type.icon_id,
              id: type.id,
              name: type.name,
            });
          }
        });
      }
    );

    setLocalVenueHighlights(locaHighlights);
    setDefaultVenueHighlights(venueHighlights);

    venueData?.languages?.forEach((lang) => {
      languages?.forEach((item) => {
        if (item.name === lang) {
          preLanguages.push(item.id);
        }
      });
    });

    setDefaultLanguages(preLanguages);

    if (venueData?.venue_type?.name) {
      propertyTypes?.forEach((item) => {
        if (item.name === venueData?.venue_type?.name) {
          prePropertyTypes.push(item.id);
          localPropertyType = item.name;
        }
      });
      setDefaultPropertyTypes(prePropertyTypes);
    }

    if (venueData) {
      reset({
        type_of_property_id: venueData?.venue_type?.id || "",
        number_of_rooms: venueData?.number_of_rooms || 1,
        number_of_bathrooms: venueData?.number_of_bathrooms || 1,
        sleeps: venueData?.sleeps || 1,
        custom_paragraphs: venueData?.custom_paragraphs || [],
        venue_highlights_id: (venueHighlights as any) || [],
        recommended_retreats: (retreat_host_type as any) || [],
        language_id: (preLanguages as any) || [],
        zip_code: venueData?.zip_code || "",
        country: venueData?.country || "",
        property_name: venueData?.venue_title || "",
        city: venueData?.city || "",
        state: venueData?.state || "",
        address: venueData?.address || "",
        property_description: venueData?.property_description || "",
        media_url: venueData?.media_url || "",
      });

      setFormStep(venueData.form_state ? venueData.form_state.step : 0);
    }

    const defaultValuesFormValues = {
      type_of_property_id: localPropertyType || "",
      number_of_rooms: venueData?.number_of_rooms || 1,
      number_of_bathrooms: venueData?.number_of_bathrooms || 1,
      sleeps: venueData?.sleeps || 1,
      custom_paragraphs: venueData?.custom_paragraphs || [],
      venue_highlights_id: localVenueHighlights || [],
      recommended_retreats: localRetreatHostType || [],
      language_id: preLanguages || [],
      zip_code: venueData?.zip_code || "",
      country: venueData?.country || "",
      property_name: venueData?.venue_title || "",
      city: venueData?.city || "",
      state: venueData?.state || "",
      address: venueData?.address || "",
      property_description: venueData?.property_description || "",
      media_url: venueData?.media_url || "",
    };

    setInitialValues(defaultValuesFormValues);

    localStorage.setItem(
      "step1FormData",
      JSON.stringify(defaultValuesFormValues)
    );
  };

  useEffect(() => {
    handleAssignInitialValues();
  }, [venueData, reset]);

  useEffect(() => {
    setVenueImages(venueData?.venue_images || []);
  }, [venueData]);

  return (
    <section className="create-venue-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-white-bg">
          <div className="form-group">
            <FormLabel
              label="Type of venue"
              subLabel="Please select only one"
            />

            <Controller
              name="type_of_property_id"
              control={control}
              rules={{
                required: defaultPropertyTypes.length
                  ? false
                  : "Property type is required",
              }}
              render={({ field: { value, onChange } }) => (
                <ClickableTags
                  selectionMode="single"
                  tagList={propertyTypes ? propertyTypes : []}
                  property_id={value}
                  onChange={(value) => {
                    onChange(value);

                    trigger("type_of_property_id");
                  }}
                  preSelectedTypes={defaultPropertyTypes}
                />
              )}
            />

            {errors.type_of_property_id && (
              <span className="error">
                {errors.type_of_property_id.message}
              </span>
            )}
          </div>
        </div>
        <div className="form-white-bg">
          <div className="form-group row">
            <div className="col">
              <FormLabel label="Sleeps" />
              <Controller
                name="sleeps"
                control={control}
                rules={{
                  required: "Please select the number of people it sleeps.",
                }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    options={DEFAULT_NUMBER_OPTION}
                    defaultValue={
                      venueData?.sleeps?.toString() ? venueData?.sleeps : "1"
                    }
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              {errors.sleeps && (
                <span className="error">{errors.sleeps.message}</span>
              )}
            </div>

            {/* Number of Bedrooms */}
            <div className="col">
              <FormLabel label="Number of Bedrooms" />
              <Controller
                name="number_of_rooms"
                control={control}
                rules={{ required: "Please select the number of bedrooms." }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    options={DEFAULT_NUMBER_OPTION}
                    defaultValue={venueData?.number_of_rooms?.toString() || "1"}
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              {errors.number_of_rooms && (
                <span className="error">{errors.number_of_rooms.message}</span>
              )}
            </div>

            {/* Number of Bathrooms */}
            <div className="col">
              <FormLabel label="Number of Bathrooms" />
              <Controller
                name="number_of_bathrooms"
                control={control}
                rules={{ required: "Please select the number of bathrooms." }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    options={DEFAULT_NUMBER_OPTION}
                    defaultValue={
                      venueData?.number_of_bathrooms?.toString() || "1"
                    }
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              {errors.number_of_bathrooms && (
                <span className="error">
                  {errors.number_of_bathrooms.message}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <FormLabel label="Name of a Venue" />
            <Controller
              name="property_name"
              control={control}
              rules={{
                required: "Please provide the venue name.",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters long.",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  type="text"
                  placeholder="Write the name here"
                  value={value}
                  onChange={(e) => {
                    const inputValue = e.target.value; // Extract the value
                    onChange(inputValue);

                    trigger("property_name");
                  }}
                />
              )}
            />
            {errors.property_name && (
              <span className="error">{errors.property_name.message}</span>
            )}
          </div>
          {/* Description */}
          <div className="form-group">
            <FormLabel label="Venue Description" />
            <Controller
              name="property_description"
              control={control}
              rules={{
                required: "Please describe your venue.",
              }}
              render={({ field: { value, onChange } }) => (
                <TextareaComponent
                  placeholder="Tell us about your venue"
                  value={value}
                  onChange={(e) => {
                    const inputValue = e.target.value; // Extract the value
                    onChange(inputValue);

                    trigger("property_description");
                  }}
                />
              )}
            />
            {errors.property_description && (
              <span className="error">
                {errors.property_description?.message}
              </span>
            )}
          </div>
        </div>
        <div className="form-white-bg">
          {/* Custom Paragraphs */}
          <div className="form-group">
            <FormLabel
              label=" Additional Venue Information"
              subLabel="Highlight your venues offerings or special amenities that would be of interest"
            />
            {fields.map((field, index) => (
              <Space
                key={`custom-paragraph-${index}`}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 custom-paragraph-title">
                      Additional Venue Information
                    </div>
                    <button
                      type="button"
                      onClick={() => remove(index)}
                      className="remove-custom-paragraph"
                    >
                      <img src={images.BIN_ICON} alt="remove" />
                    </button>
                  </div>
                  <div className="mb-2">
                    <FormLabel label="Title" />
                    <Controller
                      name={`custom_paragraphs.${index}.title`}
                      control={control}
                      rules={{ required: "Please provide a title." }}
                      render={({ field: { value, onChange } }) => (
                        <InputComponent
                          style={{ width: "100%", marginTop: "4px" }}
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value; // Extract the value
                            onChange(inputValue);

                            trigger(`custom_paragraphs.${index}.title`);
                          }}
                        />
                      )}
                    />
                    {errors.custom_paragraphs?.[index]?.title && (
                      <span className="error">
                        {errors.custom_paragraphs[index]?.title?.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <FormLabel label="Description" />
                    <Controller
                      name={`custom_paragraphs.${index}.content`}
                      control={control}
                      rules={{ required: "Please provide a description." }}
                      render={({ field: { value, onChange } }) => (
                        <TextareaComponent
                          style={{ width: "100%", marginTop: "4px" }}
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value; // Extract the value
                            onChange(inputValue);

                            trigger(`custom_paragraphs.${index}.content`);
                          }}
                        />
                      )}
                    />
                    {errors.custom_paragraphs?.[index]?.content && (
                      <span className="error">
                        {errors.custom_paragraphs[index]?.content?.message}
                      </span>
                    )}
                  </div>
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="button"
                onClick={() => append({ title: "", content: "" })}
                label="+ Add Custom Paragraph"
                style={{
                  backgroundColor: "var(--pure-white)",
                  border: "1px solid var(--deep-sea)",
                  color: "var(--deep-sea)",
                  marginTop: "12px",
                }}
              />
            </Form.Item>
          </div>
        </div>

        {/* venue highlights */}
        <div className="form-white-bg">
          <div
            className={`form-group recommended_retreats ${
              venue_highlights_id || defaultVenueHighlights.length > 0
                ? "selected"
                : ""
            }`}
          >
            <FormLabel
              label="Venue Features"
              subLabel="Please select 3-5 categories that best describe your venue"
            />

            <div>
              <Controller
                name="venue_highlights_id"
                control={control}
                rules={{
                  required: "Please select at least one venue features.",
                }}
                render={({ field: { value, onChange } }) => (
                  <SelectOption
                    showSearch={false}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="search or create special characteristics about your venue"
                    defaultValue={value}
                    value={value || defaultVenueHighlights}
                    onChange={(value) => {
                      onChange(value);
                      setVenueHighlights(value.length);

                      trigger("venue_highlights_id");
                    }}
                    options={highlights?.map((item) => {
                      const icon = icons?.find(
                        (icon) => icon.id === item.icon_id
                      );

                      return {
                        value: item.id,
                        label: (
                          <div className="d-flex align-items-center">
                            <img
                              src={icon?.icon_url}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {item.name}
                            </span>
                          </div>
                        ),
                      };
                    })}
                  />
                )}
              />
              {errors.venue_highlights_id && (
                <span className="error">
                  {errors.venue_highlights_id.message}
                </span>
              )}
            </div>
          </div>
          <div
            className={`form-group recommended_retreats ${
              recommended_retreats || defaultRecommendedRetreats.length > 0
                ? "selected"
                : ""
            }`}
          >
            <FormLabel
              label="Type of Retreats "
              subLabel="Select as many types of retreat the venue can accommodate"
            />
            <Controller
              name="recommended_retreats"
              control={control}
              rules={{
                required: "Please select at least one venue retreat.",
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectOption
                    showSearch={false}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="search for types of retreats"
                    defaultValue={value}
                    value={value || defaultRecommendedRetreats}
                    onChange={(value) => {
                      onChange(value);
                      setRecommendedRetreats(value.length);

                      trigger("recommended_retreats");
                    }}
                    options={retreatsHostTypes?.map((item) => {
                      const icon = icons?.find(
                        (icon) => icon.id === item.icon_id
                      );

                      return {
                        value: item.id,
                        label: (
                          <div className="d-flex align-items-center">
                            <img
                              src={icon?.icon_url}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {item.name}
                            </span>
                          </div>
                        ),
                      };
                    })}
                  />
                );
              }}
            />
            {errors.recommended_retreats && (
              <span className="error">
                {errors.recommended_retreats?.message}
              </span>
            )}
          </div>
        </div>
        <div className="form-white-bg">
          <div className="form-group location">
            <FormLabel label="Location" />
            <div className="col">
              <FormLabel label="Address" />
              <Controller
                name="address"
                control={control}
                rules={{
                  required: "Please provide the address.",
                }}
                render={({ field: { value, onChange } }) => (
                  <InputComponent
                    style={{ width: "100%", marginTop: "4px !important" }}
                    value={value}
                    placeholder="Enter address"
                    onChange={(e) => {
                      const inputValue = e.target.value; // Extract the value
                      onChange(inputValue);

                      trigger("address");
                    }}
                  />
                )}
              />
              {errors.address && (
                <span className="error">{errors.address.message}</span>
              )}
            </div>
            <div className="row">
              <div className="col">
                <FormLabel label="City/Town" />
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <InputComponent
                      placeholder="Enter city/town"
                      style={{ width: "100%", marginTop: "4px !important" }}
                      value={value}
                      onChange={(e) => {
                        const inputValue = e.target.value; // Extract the value
                        onChange(inputValue);
                        trigger("city");
                      }}
                    />
                  )}
                />
                {errors.city && (
                  <span className="error">{errors.city.message}</span>
                )}
              </div>
              <div className="col">
                <FormLabel label="State/Province" />
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: "Please provide the City.",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputComponent
                      placeholder="Enter state/province"
                      style={{ width: "100%", marginTop: "4px !important" }}
                      value={value}
                      onChange={(e) => {
                        const inputValue = e.target.value; // Extract the value
                        onChange(inputValue);

                        trigger("state");
                      }}
                    />
                  )}
                />
                {errors.state && (
                  <span className="error">{errors.state.message}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormLabel label="Country" />
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: "Please select at country.",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SelectOption
                      options={countryOptions}
                      placeholder="Select one"
                      defaultActiveFirstOption
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                        trigger("country");
                      }}
                      showSearch
                    />
                  )}
                />
                {errors.country && (
                  <span className="error">{errors.country.message}</span>
                )}
              </div>
              <div className="col">
                <FormLabel label="Zipcode" />
                <Controller
                  name="zip_code"
                  control={control}
                  rules={{
                    required: "Please provide the Zipcode.",
                    pattern: {
                      value: /^[0-9]+$/, // Only numbers allowed
                      message: "Zipcode should contain only numbers.",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputComponent
                      style={{ marginTop: "4px !important" }}
                      placeholder="Enter postal code"
                      value={value}
                      onChange={(e) => {
                        const inputValue = e.target.value; // Extract the value
                        onChange(inputValue);
                        trigger("zip_code");
                      }}
                      maxLength={12}
                      minLength={4}
                    />
                  )}
                />
                <div>
                  {errors.zip_code && (
                    <span className="error">{errors.zip_code.message}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-white-bg locations">
          <div
            className={`form-group recommended_retreats ${
              language_id || defaultLanguages.length > 0 ? "selected" : ""
            }`}
          >
            <FormLabel
              label="Language Assistance Offered"
              subLabel="If the host requires assistance at your venue, which languages are available "
            />
            <Controller
              name="language_id"
              control={control}
              rules={{
                required: "Please select at least one language.",
              }}
              render={({ field: { value, onChange } }) => (
                <SelectOption
                  showSearch={false}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  defaultValue={value}
                  value={value || defaultLanguages}
                  placeholder="search language"
                  options={languages?.map((item) => ({
                    value: item.id,
                    label: (
                      <div className="d-flex align-items-center" key={item.id}>
                        <span>{item.name}</span>
                      </div>
                    ),
                  }))}
                  onChange={(value) => {
                    onChange(value);
                    setLanguage(value.length);

                    trigger("language_id");
                  }}
                />
              )}
            />
            {errors.language_id && (
              <span className="error">{errors.language_id?.message}</span>
            )}
          </div>
        </div>
        <div className="form-white-bg media">
          <FormLabel label="Media" />
          <div className="form-group">
            <FormLabel label="Video URL" />
            <Controller
              name="media_url"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  type="text"
                  placeholder="Enter Video URL"
                  style={{ width: "100%" }}
                  value={value}
                  onChange={({ target: { value: inputValue } }) => {
                    onChange(inputValue);
                    trigger("media_url");
                  }}
                />
              )}
            />
            {videoUrl && <VideoPreview url={videoUrl} />}
          </div>
          <div className="form-group create-venue-file-upload spaces-image-upload">
            <FormLabel
              label="Upload photos"
              subLabel="Upload up to 24 high-quality / professional images with no watermarks or filters that distort natural colors."
            />
            <Controller
              name="images"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  {fileList.length === 0 && venueImages.length === 0 && (
                    <Dragger
                      accept="image/*"
                      beforeUpload={(file) => {
                        const isImage =
                          file.type && file.type.startsWith("image/");

                        if (!isImage) {
                          toast.error("You can only upload image files.");
                          return Upload.LIST_IGNORE;
                        }
                        setFileList([...fileList, file]);
                        return false;
                      }}
                      multiple
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      onRemove={(file) => {
                        const index = fileList.indexOf(file);
                        const newFileList = fileList.slice();
                        newFileList.splice(index, 1);
                        setFileList(newFileList);
                      }}
                      defaultFileList={[]}
                    >
                      <p className="common-xs-light-text">
                        <img
                          src={images.UPLOAD_BLACK_ICON}
                          alt=""
                          height={"24px"}
                        />
                        <br />
                        Drag and drop <SmallText text=" or click here" />
                      </p>
                    </Dragger>
                  )}
                  {(fileList.length > 0 || venueImages.length > 0) && (
                    <Upload
                      accept="image/*"
                      beforeUpload={(file) => {
                        const isImage =
                          file.type && file.type.startsWith("image/");

                        if (!isImage) {
                          toast.error("You can only upload image files.");
                          return Upload.LIST_IGNORE;
                        }
                        setFileList([...fileList, file]);

                        return false;
                      }}
                      multiple
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      onRemove={(file) => {
                        const index = fileList.indexOf(file);
                        const newFileList = fileList.slice();
                        newFileList.splice(index, 1);
                        setFileList(newFileList);
                      }}
                      style={{ marginTop: "12px !important" }}
                    >
                      {fileList.length >= 24 ? null : uploadButton}
                    </Upload>
                  )}
                  {previewImage && (
                    <>
                      <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    </>
                  )}
                </>
              )}
            />
            <div className="d-flex gap-3 flex-wrap">
              {venueImages?.map((image, index) => (
                <div
                  className="image-cards"
                  key={`image-${index}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img src={image.image_url} alt="" className="upload-image" />
                  {hoveredImage === index && (
                    <div className="image-card-hover">
                      <button
                        type="button"
                        onClick={() => {
                          setPreviewImage(image.image_url);
                          setPreviewOpen(true);
                        }}
                      >
                        <EyeOutlined style={{ color: "#fff" }} />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleStoreImagesToDelete(image.id)}
                      >
                        <DeleteOutlined style={{ color: "#fff" }} />
                      </button>
                    </div>
                  )}
                </div>
              ))}
              {previewImage && (
                <>
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <CreateVenueFooter
          error={getValues()}
          disabled={
            !isValid ||
            (venueImages.length > 0 ? false : fileList.length === 0) ||
            submitting
          }
          submitting={submitting}
          saveVenueDetails={() => saveData(getValues)}
          handleBackButton={() => navigate("/venues/my-venues")}
          showBackButton={false}
        />
      </form>
    </section>
  );
};

export default AddVenueDetails;
