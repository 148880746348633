// third party
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: {} as Record<string, boolean>,
};

export const ModalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setShowModal: (state, action) => {
      const { modalName, visible } = action.payload;
      state.modals[modalName] = visible;
    },
  },
});

export const { setShowModal } = ModalSlice.actions;

export default ModalSlice.reducer;
