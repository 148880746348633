/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Components
import AdminMessage from "../../Message/AdminMessage";
import AdminDashboardLayout from "../AdminDashboardLayout";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Utility
import { convertToTitleCase } from "../../../../library";

const Messages: React.FC = () => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );
  return (
    <>
      <AdminDashboardLayout
        pageTitle={`Welcome,  ${convertToTitleCase(
          userProfileData.first_name
        )} 👋`}
        additionalClassNames="admin-msg"
      >
        <AdminMessage  />
      </AdminDashboardLayout>
    </>
  );
};

export default Messages;
