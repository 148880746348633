/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Radio, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

// Custom component
import FormLabel from "../../../UI/FormLabel";
import GuestCounter from "../../../UI/GuestCounter";

// redux
import {
  setBookingTerms,
  setInitialBookingTerms,
} from "../../../../redux/features/venueDataSlice";
import { setEnableEditButton } from "../../../../redux/features/editVenueOperationsSlice";

// network
import { fetchBookingTerms } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// helpers
import { changeUTC, convert24To12, handleEnterKey } from "../../../../library";

// types
import {
  BookingTermDataType,
  BookingTermsType,
} from "../../../../types/property-manager";

dayjs.extend(utc);

const EditBookingTerms: React.FC<{ venueId: string }> = ({ venueId }) => {
  const dispatch = useDispatch();
  const [bookingTermsData, setBookingTermsData] = useState<BookingTermDataType>(
    {
      quiet_hours_start: "",
      quiet_hours_end: "",
      guest_number: 0,
      night_stays: 0,
      id: "",
      venue_id: "",
      check_in_date: "",
      check_out_date: "",
      form_state: {
        completed_status: false,
        step: 0,
      },
    }
  );
  const [loading, setLoading] = useState(true);
  const [checkInPeriod, setCheckInPeriod] = useState<"AM" | "PM">("AM");
  const [checkOutPeriod, setCheckOutPeriod] = useState<"AM" | "PM">("AM");
  const [quietHoursStartPeriod, setQuietHoursStartPeriod] = useState<
    "AM" | "PM"
  >("AM");
  const [quietHoursEndPeriod, setQuietHoursEndPeriod] = useState<"AM" | "PM">(
    "AM"
  );

  const { call } = useApiCall();

  const {
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<BookingTermsType>({
    defaultValues: {
      check_in_period: checkInPeriod,
      check_out_period: checkOutPeriod,
      quiet_hours_start_period: quietHoursStartPeriod,
      quiet_hours_end_period: quietHoursEndPeriod,
    },
  });

  const fetchBookingTermsData = async () => {
    setLoading(true);
    call(
      () => fetchBookingTerms(venueId),
      (res) => {
        setBookingTermsData(res.data.data);
        const fetchedData = res.data.data;

        const dispatchBookingTerm = (field: any, value: any) => {
          dispatch(setBookingTerms({ field, value }));
          dispatch(setInitialBookingTerms({ field, value }));
        };

        // Helper function to determine AM/PM
        const getPeriod = (date: string) =>
          dayjs(`1970-01-01 ${date}`).hour() >= 12 ? "PM" : "AM";

        // Set date-related terms
        dispatchBookingTerm("check_in", fetchedData.check_in_date);
        dispatchBookingTerm("check_out", fetchedData.check_out_date);
        dispatchBookingTerm("quiet_hours_start", fetchedData.quiet_hours_start);
        dispatchBookingTerm("quiet_hours_end", fetchedData.quiet_hours_end);

        // Set other booking terms
        dispatchBookingTerm("guest_number", fetchedData.guest_number);
        dispatchBookingTerm("night_stays", fetchedData.night_stays);

        // Set periods (AM/PM)
        dispatchBookingTerm(
          "check_in_period",
          getPeriod(fetchedData.check_in_date)
        );
        dispatchBookingTerm(
          "check_out_period",
          getPeriod(fetchedData.check_out_date)
        );
        dispatchBookingTerm(
          "quiet_hours_start_period",
          getPeriod(fetchedData.quiet_hours_start)
        );
        dispatchBookingTerm(
          "quiet_hours_end_period",
          getPeriod(fetchedData.quiet_hours_end)
        );

        dispatch(setEnableEditButton(false));
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchBookingTermsData();
  }, []);

  useEffect(() => {
    setValue(
      "check_in",
      convert24To12(bookingTermsData?.check_in_date, setCheckInPeriod)
    );
    setValue(
      "check_out",
      convert24To12(bookingTermsData?.check_out_date, setCheckOutPeriod)
    );
    bookingTermsData?.quiet_hours_start !== ""
      ? setValue(
          "quiet_hours_start",
          convert24To12(
            bookingTermsData?.quiet_hours_start,
            setQuietHoursStartPeriod
          )
        )
      : setValue("quiet_hours_start", null);

    bookingTermsData?.quiet_hours_end !== ""
      ? setValue(
          "quiet_hours_end",
          convert24To12(
            bookingTermsData?.quiet_hours_end,
            setQuietHoursEndPeriod
          )
        )
      : setValue("quiet_hours_end", null);
  }, [bookingTermsData, setValue]);

  useEffect(() => {
    // Set values explicitly to make sure they are recognized for validation
    setValue("night_stays", bookingTermsData?.night_stays || 1);
    setValue("guest_number", bookingTermsData?.guest_number || 1);

    // Trigger validation after setting values
    trigger(["night_stays", "guest_number"]);
  }, [bookingTermsData, setValue, trigger]);

  useEffect(() => {
    dispatch(
      setBookingTerms({
        field: "check_in",
        value: changeUTC(getValues("check_in"), checkInPeriod)
          .split(".")[0]
          .toString(),
      })
    );
  }, [
    checkInPeriod,
    checkOutPeriod,
    quietHoursStartPeriod,
    quietHoursEndPeriod,
  ]);

  return loading ? (
    <div className="form-white-bg w-100">
      <Spin />
    </div>
  ) : (
    <div>
      <form className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4">
        <div className="form-white-bg w-100">
          <div className="booking-time-container">
            <div className="form-group">
              <FormLabel label="Check in" />
              <div className="time-block-container d-flex">
                <Controller
                  name="check_in"
                  control={control}
                  rules={{
                    required: "Please select check-in time",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        minuteStep={30}
                        showSecond={false}
                        suffixIcon={false}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => {
                          onChange(time);
                          trigger("check_in");

                          dispatch(
                            setBookingTerms({
                              field: "check_in",
                              value: changeUTC(time, checkInPeriod)
                                ?.split(".")[0]
                                .toString(),
                            })
                          );
                        }}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                        type="object"
                      />
                    );
                  }}
                />
                <Controller
                  name="check_in_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setCheckInPeriod(e.target.value);
                        dispatch(
                          setBookingTerms({
                            field: "check_in_period",
                            value: e.target.value,
                          })
                        );
                      }}
                      value={checkInPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.check_in && (
                <span className="error">{errors.check_in.message}</span>
              )}
            </div>
            <div className="form-group">
              <FormLabel label="Check out" />
              <div className="time-block-container d-flex">
                <Controller
                  name="check_out"
                  control={control}
                  rules={{
                    required: "Please select check-out time",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        minuteStep={30}
                        showSecond={false}
                        suffixIcon={false}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => {
                          onChange(time);
                          dispatch(
                            setBookingTerms({
                              field: "check_out",
                              value: changeUTC(time, checkOutPeriod)
                                ?.split(".")[0]
                                .toString(),
                            })
                          );
                        }}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                      />
                    );
                  }}
                />
                <Controller
                  name="check_out_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setCheckOutPeriod(e.target.value);
                        dispatch(
                          setBookingTerms({
                            field: "check_out_period",
                            value: e.target.value,
                          })
                        );
                      }}
                      value={checkOutPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.check_out && (
                <span className="error">{errors.check_out.message}</span>
              )}
            </div>
            <div className="form-group">
              <FormLabel label="Quiet hours from" />
              <div className="time-block-container d-flex">
                <Controller
                  name="quiet_hours_start"
                  control={control}
                  rules={{
                    required: "Please select quiet hours start time",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        minuteStep={30}
                        showSecond={false}
                        suffixIcon={false}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => {
                          onChange(time);
                          dispatch(
                            setBookingTerms({
                              field: "quiet_hours_start",
                              value: changeUTC(time, quietHoursStartPeriod)
                                ?.split(".")[0]
                                .toString(),
                            })
                          );
                        }}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                      />
                    );
                  }}
                />
                <Controller
                  name="quiet_hours_start_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setQuietHoursStartPeriod(e.target.value); // Set AM/PM
                        dispatch(
                          setBookingTerms({
                            field: "quiet_hours_start_period",
                            value: e.target.value,
                          })
                        );
                      }}
                      value={quietHoursStartPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.quiet_hours_start && (
                <span className="error">
                  {errors.quiet_hours_start.message}
                </span>
              )}
            </div>
            <div className="form-group">
              <FormLabel label="Quiet hours until" />
              <div className="time-block-container d-flex">
                <Controller
                  name="quiet_hours_end"
                  control={control}
                  rules={{
                    required: "Please select quiet hours end time",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TimePicker
                        value={value}
                        minuteStep={30}
                        showSecond={false}
                        suffixIcon={false}
                        changeOnScroll
                        needConfirm={false}
                        onChange={(time) => {
                          onChange(time);
                          dispatch(
                            setBookingTerms({
                              field: "quiet_hours_end",
                              value: changeUTC(time, quietHoursEndPeriod)
                                ?.split(".")[0]
                                .toString(),
                            })
                          );
                        }}
                        onKeyDown={handleEnterKey}
                        use12Hours
                        format="h:mm"
                        popupClassName="venue-time-picker-modal"
                      />
                    );
                  }}
                />
                <Controller
                  name="quiet_hours_end_period"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Radio.Group
                      onChange={(e) => {
                        onChange(e.target.value);
                        setQuietHoursEndPeriod(e.target.value); // Set AM/PM
                        dispatch(
                          setBookingTerms({
                            field: "quiet_hours_end_period",
                            value: e.target.value,
                          })
                        );
                      }}
                      value={quietHoursEndPeriod}
                    >
                      <div className="meridian-container">
                        <Radio.Button value="AM">AM</Radio.Button>
                        <Radio.Button value="PM">PM</Radio.Button>
                      </div>
                    </Radio.Group>
                  )}
                />
              </div>
              {errors.quiet_hours_end && (
                <span className="error">{errors.quiet_hours_end.message}</span>
              )}
            </div>
            <div className="form-group">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <FormLabel label="Minimum number of nights" />
                </div>
                <div className="time-block-container d-flex">
                  <Controller
                    name="night_stays"
                    control={control}
                    defaultValue={bookingTermsData?.night_stays || 1}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <GuestCounter
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                            dispatch(
                              setBookingTerms({
                                field: "night_stays",
                                value: value,
                              })
                            );
                          }}
                          maxGuests={50}
                          className="justify-content-between"
                          defaultValue={bookingTermsData?.night_stays || 1}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <FormLabel label="Max guests quantity" />
                </div>
                <div className="time-block-container d-flex">
                  <Controller
                    name="guest_number"
                    control={control}
                    defaultValue={bookingTermsData?.guest_number || 1}
                    render={({ field: { value, onChange } }) => (
                      <GuestCounter
                        value={value}
                        onChange={(value) => {
                          onChange(value);
                          dispatch(
                            setBookingTerms({
                              field: "guest_number",
                              value: value,
                            })
                          );
                        }}
                        maxGuests={50}
                        className="justify-content-between"
                        defaultValue={bookingTermsData?.guest_number || 1}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-white-bg w-100">
          <div>
            <p className="common-sm-medium-dark-text m-0">
              Cancellation Policy
            </p>
            <p className="common-sm-light-grey-text mt-2">
              All retreat hosts will adhere to our global cancellation policy
            </p>
            <p
              className="common-sm-light-grey-text mt-2"
              style={{ color: "#949397" }}
            >
              Venue Managers canceling a confirmed booking on LAIGO may face
              penalties affecting past and future listings. It's recommended to
              discuss changes with Retreat Hosts via the site's chat feature
              before canceling. A valid payment method is required, and fees may
              apply depending on the timing of the cancellation.
            </p>
            {/* <p className="mt-3">
              <Link to={"#"} style={{ color: "var(--deep-sea)" }}>
                <SmallText text="Review Cancellation Policy" />
              </Link>
            </p> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditBookingTerms;
