import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VenueQueryParams } from '../../types/retreat';

interface VenueState {
  queryParams: VenueQueryParams;
}

const initialState: VenueState = {
  queryParams: {},
};

export const venueFilter = createSlice({
  name: 'selectedVenue',
  initialState,
  reducers: {
    setQueryParams(state, action: PayloadAction<VenueQueryParams>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    clearQueryParams(state) {
      state.queryParams = {};
    },
  },
});

export const { setQueryParams, clearQueryParams } = venueFilter.actions;

export default venueFilter.reducer;
