import React from "react";
//common component
import SmallText from "../SmallText";

//types
import { MultiSelectCheckboxProps } from "../../../types";

//images
import { demo_images } from "../../../assets/images/demo-images";

//css
import "./index.css";

const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = ({
  options,
  selectedOptions,
  onSelect,
  onDeselect,
  cardWidth = "350px",
  verticalCardProps,
}) => {
  const handleSelection = (value: string) => {
    if (selectedOptions.includes(value)) {
      onDeselect(value);
    } else {
      onSelect(value);
    }
  };

  return (
    <div
      className={`d-flex flex-wrap ${verticalCardProps ? "gap-3" : "gap-4"}`}
    >
      {options &&
        options.map((option, index) => (
          <div
            key={index}
            className={`multi-select-checkbox d-flex  ${
              selectedOptions.includes(option.value) ? "selected" : ""
            }`}
            style={{ maxWidth: `${cardWidth}`, width: `${cardWidth}` }}
            onClick={() => handleSelection(option.value)}
          >
            <div>
              <input
                type="checkbox"
                id={`option-${index}`}
                className="form-check-input"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleSelection(option.value)}
              />
            </div>

            <div className="d-flex flex-column gap-2">
              <label htmlFor={`option-${index}`} className="m-0">
                <SmallText
                  text={`${option.label}`}
                  color="#19191C"
                  lineHeight={18.8}
                  fontWeight={500}
                  fontSize={16}
                />
              </label>
              <SmallText
                text={`${option.description}`}
                color="#58585B"
                lineHeight={18.8}
                fontWeight={400}
                fontSize={16}
              />
            </div>
          </div>
        ))}
      {verticalCardProps &&
        verticalCardProps.map((option, index) => (
          <div
            key={index}
            className={`multi-select-room align-items-center       d-flex  ${
              selectedOptions.includes(option.id) ? "selected" : ""
            }`}
            style={{ maxWidth: `${cardWidth}`, width: `${cardWidth}` }}
            onClick={() => handleSelection(option.id)}
          >
            <div>
              <img
                src={demo_images.DEMO_IMAGE_1}
                alt="img"
                width={80}
                height={80}
                className="rounded"
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: "9px" }}>
              <div className="d-flex flex-column gap-2">
                <SmallText
                  text={option.name}
                  color="#19191C"
                  lineHeight={18.8}
                  fontWeight={500}
                  fontSize={16}
                />
                <SmallText
                  text={option.description}
                  color="#58585B"
                  lineHeight={16.45}
                  fontWeight={400}
                  fontSize={14}
                />
              </div>
              <div className="d-flex gap-2">
                <SmallText
                  text={`$${option.price}`}
                  color="#19191C"
                  fontWeight={500}
                  lineHeight={23.5}
                  fontSize={20}
                />
                <del>
                  <SmallText
                    text="$730"
                    color="#949397"
                    fontWeight={400}
                    lineHeight={18.8}
                    fontSize={16}
                  />
                </del>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MultiSelectCheckbox;
