/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { InputNumber, Switch } from "antd";
import { toast } from "react-toastify";

// custom component
import FormLabel from "../../../../UI/FormLabel";
import InputComponent from "../../../../UI/InputComponent";
import SelectOption from "../../../../UI/SelectOption";
import TextareaComponent from "../../../../UI/TextareaComponent";
import Button from "../../../../UI/Button";
import ClickableTags from "../../../../UI/ClickableTags";

//  redux
import { RootState } from "../../../../../redux/store";
import { setShowModal } from "../../../../../redux/features/component-slice";

// network
import { postAddOnServices } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import {
  TagListType,
  RoomModalType,
  AddOnServicesFormTypes,
} from "../../../../../types/property-manager";

// Constants
import {
  DEFAULT_NUMBER_OPTION,
  PRICE_LABELS,
} from "../../../../../constants/property-manager";

const selectedIcon = process.env
  .REACT_APP_ADD_ON_SERVICE_SELECTED_ICON as string;

const AddOnServicesModal: React.FC<RoomModalType> = ({
  venueId,
  fetchDetails,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [ticketsEnable, setTicketsEnable] = useState(false);
  const [priceEnable, setPriceEnable] = useState(false);
  const [addOnIcons, setAddonIcons] = useState<TagListType[]>([]);

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<AddOnServicesFormTypes>({
    defaultValues: {
      description: "",
      externalUrl: "",
      price: null,
      priceLabel: "",
      tickets: 1,
    },
    mode: "onChange", // Make validation trigger on field change
  });

  const { call } = useApiCall();
  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const onFinish = async (data: AddOnServicesFormTypes) => {
    setSubmitting(true);

    if (!ticketsEnable) {
      data.tickets = null;
    }
    if (!priceEnable) {
      data.price = 0;
      data.priceLabel = "Free";
    }
    const dataToSend = {
      icon_id: data.icons === "" ? undefined : data.icons,
      name: data.name,
      description: data.description,
      external_link: data.externalUrl,
      charges: data.price,
      service_type: data.priceLabel,
      type: "Optional",
      tickets: data.tickets,
    };

    call(
      () =>
        postAddOnServices({
          venue_id: venueId as string,
          data: dataToSend,
        }),
      (res) => {
        reset({
          name: "",
          description: "",
          externalUrl: "",
          price: null,
          priceLabel: "",
        });
        dispatch(
          setShowModal({
            modalName: "add-on-services",
            visible: false,
          })
        );
        toast.success(
          res.data?.message || "Add-on services created successfully"
        );
        fetchDetails();
        reset({
          name: null,
          description: "",
          externalUrl: "",
          price: null,
          priceLabel: "",
        });
        setSubmitting(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to create add-on services"
        );
        setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    const allIcons: TagListType[] = [];

    icons.forEach((icon) => {
      allIcons.push({
        icon_id: icon.id,
        id: icon.id,
        name: "",
        iconImage: icon.icon_url,
      });
    });

    setAddonIcons(allIcons);

    if (allIcons.length) {
      setValue("icons", selectedIcon);
    }
  }, []);

  useEffect(() => {
    if (!priceEnable) {
      setValue("price", null);
      setValue("priceLabel", "");
      clearErrors(["price", "priceLabel"]);
    }
  }, [priceEnable, setValue, clearErrors]);

  return (
    <div className="room-modal-info-container edit-space-info">
      <div className="room-modal-subtitle">
        Create your add on and customize it as you wish
      </div>
      <form
        className="w-100 create-venue-form add-on-services-form d-flex flex-column gap-4"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="form-white-bg w-100">
          <div className="form-group">
            <FormLabel label="Add-On Service Name" />
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Please provide the venue name.",
                validate: (value) => {
                  if (value?.trim() === "") {
                    return "Please provide the venue name.";
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  type="text"
                  placeholder="Write the name here"
                  style={{ width: "100%" }}
                  value={value || ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                    trigger("name");
                  }}
                />
              )}
            />
            {errors.name && (
              <span className="error">{errors.name.message}</span>
            )}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Number of Tickets Available" />
              <Switch
                checked={ticketsEnable}
                onChange={() => setTicketsEnable(!ticketsEnable)}
              />
            </div>
            <Controller
              name="tickets"
              control={control}
              rules={{
                required: !ticketsEnable ? "Please provide tickets." : false,
              }}
              disabled={!ticketsEnable}
              render={({ field: { value, onChange } }) => (
                <SelectOption
                  disabled={!ticketsEnable}
                  options={DEFAULT_NUMBER_OPTION}
                />
              )}
            />
          </div>
          <div className="form-group">
            <FormLabel label="Add-On Service Description" />
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Please provide a description.",
              }}
              render={({ field: { value, onChange } }) => (
                <TextareaComponent
                  placeholder="enter your text here"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    trigger("description");
                  }}
                />
              )}
            />
            {errors.description && (
              <span className="error">{errors.description?.message}</span>
            )}
          </div>

          <div className="form-group">
            <FormLabel label="External URL (Optional)" />
            <Controller
              name="externalUrl"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputComponent
                  placeholder="enter URL here"
                  style={{ width: "100%" }}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
          </div>

          <div className="form-group add-on-service-price">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Price" />
              <Controller
                name="priceEnable"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    value={value}
                    checked={priceEnable}
                    onChange={(value) => {
                      onChange(value);
                      setPriceEnable(!priceEnable);
                    }}
                  />
                )}
              />
            </div>
            <Controller
              name="price"
              control={control}
              rules={{
                required: priceEnable ? "Please provide a price." : false,
                pattern: {
                  value: /^[0-9]+$/, // Only numbers allowed
                  message: "Price should contain only numbers.",
                },
              }}
              disabled={!priceEnable}
              render={({ field: { value, onChange } }) => (
                <InputNumber
                  changeOnBlur={false}
                  changeOnWheel={false}
                  controls={false}
                  disabled={!priceEnable}
                  placeholder="0.00"
                  prefix="$"
                  value={value as number}
                  onChange={(e) => {
                    const inputValue = e; // Extract the value
                    onChange(inputValue);
                    trigger("price");
                  }}
                  onKeyPress={(e) => {
                    // Prevent non-numeric characters
                    if (!/^[0-9]$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  style={{ width: "100%", height: "42px" }}
                />
              )}
            />
            {errors.price && (
              <span className="error">{errors.price.message}</span>
            )}
            {priceEnable && (
              <>
                <Controller
                  name="priceLabel"
                  control={control}
                  rules={{ required: "Please select a type of venue." }}
                  render={({ field: { value, onChange } }) => (
                    <ClickableTags
                      selectionMode="single"
                      tagList={PRICE_LABELS}
                      property_id={value}
                      onChange={(value) => {
                        onChange(value);
                        trigger("priceLabel");
                      }}
                    />
                  )}
                />
                {errors.priceLabel && (
                  <span className="error">{errors.priceLabel.message}</span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="form-white-bg w-100">
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <FormLabel label="Icons (optional)" />
            </div>
            <Controller
              name="icons"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ClickableTags
                  selectionMode="single"
                  tagList={addOnIcons}
                  property_id={value}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  preSelectedTypes={
                    addOnIcons[0]?.id ? [addOnIcons[0]?.id] : []
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
          <div>
            <Button
              type="button"
              label="Cancel"
              style={{
                borderRadius: "4px",
                border: "1px solid var(--deep-sea)",
                backgroundColor: "var(--pure-white)",
                fontSize: "14px",
                lineHeight: "16.45px",
                color: "var(--deep-sea)",
                fontWeight: "500",
                padding: "12px 16px",
                textDecoration: "none",
              }}
              onClick={() => {
                reset({
                  name: "",
                  description: "",
                  externalUrl: "",
                  price: null,
                  priceLabel: "",
                  icons: selectedIcon,
                });
                setPriceEnable(false);
                dispatch(
                  setShowModal({ modalName: "add-on-services", visible: false })
                );
              }}
            />
          </div>
          <div>
            <Button
              type="submit"
              label={submitting === true ? "Saving..." : "Save"}
              onClick={handleSubmit(onFinish)}
              disabled={submitting || !isValid}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddOnServicesModal;
