/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//third party
import { Select } from "antd";
import { Trash } from "lucide-react";

//component
import UploadFile from "./UploadDocument/UploadFile";

//common component
import SmallText from "../../../UI/SmallText";

//api call
import { useApiCall } from "../../../../hooks/useApiCall";

//custom hook
import { getDocumentById } from "../../../../network";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

// custom components
import {
  ADDITIONAL_DOCUMENT,
  PROOF_OF_LIABILITY,
  PROOF_OF_OWNERSHIP,
} from "../../../../constants/property-manager";

interface UploadSectionProps {
  venueOption: any;
  handleVenueId: (e: string, value: number) => void;
  indexValue: number;
  isSecond: boolean;
  handleDocumentSection?: (id: string) => void;
}
const UploadSection: React.FC<UploadSectionProps> = ({
  venueOption,
  handleVenueId,
  indexValue,
  isSecond,
  handleDocumentSection,
}) => {
  const { call } = useApiCall();
  const [venueId, setVenueId] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<any>([{}]);

  const onChange = (value: string) => {
    setVenueId(value);
    handleVenueId(value, indexValue);
  };

  //get venues document
  const handleDocument = () => {
    call(
      () => getDocumentById(venueId),
      (res) => {
        setUploadedFile(res.data.data);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  const filteredFiles = (type: string) =>
    uploadedFile.filter((item: any) => item.document_type === type);
  useEffect(() => {
    if (venueId) {
      handleDocument();
    }
  }, [venueId]);

  return (
    <div className="d-flex flex-column bg-white venue-document-selection">
      <div className="d-flex justify-content-between align-items-center">
        <SmallText
          text="Select venue"
          lineHeight={18.8}
          fontSize={16}
          fontWeight={500}
          color="#000000"
        />
        {isSecond && handleDocumentSection && (
          <button
            className="btn"
            type="button"
            onClick={() => handleDocumentSection(venueId)}
          >
            <Trash color="#F63030" width={18} height={18} />
          </button>
        )}
      </div>

      <Select
        showSearch
        placeholder="Select venue listing"
        optionFilterProp="label"
        onChange={onChange}
        options={venueOption}
      />
      <div className="d-flex w-100 " style={{ gap: "20px" }}>
        <UploadFile
          documentType={PROOF_OF_LIABILITY}
          reference_id={venueId}
          uploadedFile={filteredFiles(PROOF_OF_LIABILITY)}
        />
        <UploadFile
          documentType={PROOF_OF_OWNERSHIP}
          reference_id={venueId}
          uploadedFile={filteredFiles(PROOF_OF_OWNERSHIP)}
        />
        <UploadFile
          documentType={ADDITIONAL_DOCUMENT}
          reference_id={venueId}
          uploadedFile={filteredFiles(ADDITIONAL_DOCUMENT)}
        />
      </div>
    </div>
  );
};

export default UploadSection;
