/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//third party
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { File, Trash, UploadIcon } from "lucide-react";
import { Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload/interface";

//common component
import SmallText from "../SmallText";

//constant
import { validTypes } from "../../../constants";

//images
import { images } from "../../../assets/images";

//css
import "./index.css";

interface ImagePickerProps {
  handleUpload?: (imageFile: RcFile ) => void;
  buttonChange?: boolean;
  preview?: boolean;
  uploadType?: string;
  imageUrl?: string;
  nameOfFile?: string;
  setSizeOfFile?: React.Dispatch<React.SetStateAction<string>>;
  sizeOfFile?: string;
  isDelete?: boolean;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  handleUpload,
  buttonChange = false,
  preview = false,
  uploadType,
  imageUrl,
  nameOfFile,
  setSizeOfFile,
  sizeOfFile,
  isDelete = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [fileError, setFileError] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileSize, setFileSize] = useState<string>("");

  //check Image type
  const beforeUpload = (file: RcFile) => {
    if (uploadType === "image") {
      const isImage = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImage) {
        setFileError("You can only upload JPG/PNG files!");
        return false;
      }
    }

    if (!validTypes.includes(file.type)) {
      setFileError("You can only upload JPG/PNG, PDF, or DOC/DOCX files!");
      return false;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      setFileError("File must be smaller than 3MB!");
      return false;
    }

    return true;
  };
  // Handle file size

  const formatFileSize = (size: number) => {
    return size < 1024 * 1024
      ? `${(size / 1024).toFixed(2)} KB`
      : `${(size / 1024 / 1024).toFixed(2)} MB`;
  };

  //set and return an image
  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      setFileError("");
      return;
    }
    if (info.file.status === "done" || info.file.originFileObj) {
      const selectedFile = info.file.originFileObj as RcFile;
      setFileUrl(URL.createObjectURL(selectedFile));
      setFileSize(formatFileSize(selectedFile.size));
      setFileName(selectedFile.name);
      handleUpload!(selectedFile);
      setSizeOfFile && setSizeOfFile(formatFileSize(selectedFile.size));
      setLoading(false);
    }
  };

  const handleDelete =() =>{
    setFileUrl("");
    setFileSize("");
    setFileName("");
    handleUpload && handleUpload( "" as any);

  }
  //static buttons
  const uploadButton = (
    <div style={{ border: 0, background: "none", cursor: "pointer" }}>
      {loading ? <LoadingOutlined /> : <UploadOutlined />}
      <div style={{ marginTop: 8 }} className="d-flex flex-column text-center">
        <SmallText
          text="Drag and drop"
          color="#19191C"
          fontSize={14}
          fontWeight={400}
          lineHeight={16.45}
        />
        <SmallText
          text="or click here"
          color="#2771A3"
          fontSize={14}
          fontWeight={400}
          lineHeight={16.45}
        />
      </div>
    </div>
  );
  //static buttons
  const uploadButton2 = (
    <div className="p-3  d-flex  justify-content-center">
      <div
        style={{ border: 0, background: "none", cursor: "pointer" }}
        className="d-flex flex-column text-center"
      >
        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
          <div className="d-flex align-items-center" style={{ gap: "12px" }}>
            <UploadIcon color="#606E80" width={32} height={32} />
            <SmallText
              text="or drop files to upload"
              color="#6B6B6F"
              fontWeight={400}
              lineHeight={16.94}
              fontSize={14}
            />
          </div>
          <button className="select-btn" type="button">
            Select file
          </button>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (imageUrl) {
      setFileUrl(imageUrl);
    }
    if (nameOfFile) {
      setFileName(nameOfFile);
    }
    if (sizeOfFile) {
      setFileSize(sizeOfFile);
    }
  }, [imageUrl]);
  return (
    <div className="image-picker-component d-flex flex-column flex-wrap create-venue-file-upload w-100">
      {fileUrl && !preview ? (
        <div className="file-upload1 d-flex  align-items-center flex-grow-1 justify-content-between w-100">
          <div className="d-flex gap-2 align-items-center">
            <div className="file-icon d-flex align-items-center justify-content-center">
              <File width={16.5} height={16.5} color="#2771A3" />
            </div>
            <div className="d-flex flex-column ">
              <SmallText
                text={`${fileName}`}
                color="#000000"
                fontSize={16}
                fontWeight={400}
                lineHeight={18.8}
              />
              <SmallText
                text={fileSize}
                color="#787878"
                fontSize={14}
                fontWeight={400}
                lineHeight={16.45}
              />
            </div>
          </div>
          {isDelete && (
            <button className="btn" type="button" onClick={handleDelete}>
              <Trash color="#F63030" width={18} height={18} />
            </button>
          )}
        </div>
      ) : preview ? (
        <div className="image-picker">
          <Upload
            name="file"
            listType="picture-card"
            className="file-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept=".jpg,.jpeg,.png"
            customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
          >
            <img
              src={true ? fileUrl : images.USER}
              alt="preview_img"
              className="img-fluid"
            />
          </Upload>
          {fileError && (
            <SmallText
              text={fileError}
              color="red"
              lineHeight={12}
              fontSize={12}
              fontWeight={300}
            />
          )}
        </div>
      ) : (
        <div className="image-picker">
          <Upload
            name="file"
            listType="picture-card"
            className="file-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept=".jpg,.jpeg,.png,application/pdf,.doc,.docx"
            customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
          >
            {buttonChange ? uploadButton2 : uploadButton}
          </Upload>
          {fileError && (
            <SmallText
              text={fileError}
              color="red"
              lineHeight={14}
              fontSize={12}
              fontWeight={300}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
