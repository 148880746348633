import React from "react";

// third party
import dayjs from "dayjs";
import { useSelector } from "react-redux";

// custom component
import StatusTags from "../../../UI/StatusTags";
import Loader from "../../../UI/Loader";

// redux
import { RootState } from "../../../../redux/store";

// helpers
import {
  calculateTotalAddOnsPrice,
  renderAddOnPrice,
} from "../../../../library";

// images
import { images } from "../../../../assets/images";

interface ReservationDetailsProps {
  reservationDetails?: any;
  loading?: boolean;
}

const ReservationDetails: React.FC<ReservationDetailsProps> = ({
  reservationDetails,
  loading,
}) => {
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const check_in_date = new Date(reservationDetails?.check_in_date);
  const check_out_date = new Date(reservationDetails?.check_out_date);

  const totalAddOnsPrice = calculateTotalAddOnsPrice(
    reservationDetails,
    check_in_date,
    check_out_date
  );

  return loading ? (
    <Loader />
  ) : (
    <div className="edit-venue-reservation upcoming-reservation-detail-modal">
      <div className="title d-flex gap-2 align-items-center">
        <img src={images.PERSON_AVATAR} alt="" height={"40px"} width={"40px"} />
        <div>
          <p className="common-md-bold-dark-text">
            {reservationDetails?.host?.name}
          </p>
          <span className="common-xs-light-text">
            {reservationDetails?.venue?.name}
          </span>
        </div>
      </div>
      <hr />
      <div className="upcoming-reservation-detail">
        <div className="about-retreat-container">
          <div className="d-flex gap-2 justify-content-between w-100">
            <div>
              <div className="common-sm-medium-light-text mb-1">Dates</div>
              <div className="common-sm-dark-text">
                {dayjs(check_in_date).format("MMM DD") +
                  " - " +
                  dayjs(check_out_date).format("MMM DD")}
              </div>
            </div>
            <div className="about-retreat-data common-sm-medium-dark-text">
              <StatusTags
                top="0"
                left="0"
                buttonPosition="relative"
                label="Approved"
                backgroundColor={"#CEF5DC"}
                color={"#398E58"}
              />
            </div>
          </div>
          <div>
            <div className="common-sm-medium-light-text mb-1">Group size:</div>
            <div className="common-sm-dark-text">
              {reservationDetails?.group_size}
            </div>
          </div>
          <div>
            <div className="common-sm-medium-light-text mb-1">
              Reservation Number
            </div>
            <div className="common-sm-dark-text">
              {reservationDetails?.reservation_id}
            </div>
          </div>
          <div>
            <div className="common-sm-medium-light-text mb-1">
              Special Requests
            </div>
            <div className="common-sm-dark-text">
              {reservationDetails?.special_request || "None"}
            </div>
          </div>
        </div>
        <hr />
        <div className="add-on-service-container">
          <div className="common-sm-bold-dark-text mb-2">Included Add-ons</div>
          <table className="booked-venue-detail-table">
            <thead>
              <tr>
                <th className="service-name">Services</th>
                <th className="price">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {reservationDetails?.add_on_services &&
                Object.keys(reservationDetails.add_on_services).map((key) => {
                  const icon = icons?.find(
                    (icon) =>
                      icon.id ===
                      reservationDetails.add_on_services[key].icon_id
                  );
                  return (
                    <tr key={`service-${key}`}>
                      <td>
                        <div className="d-flex gap-2 align-items-center common-sm-dark-text">
                          <img src={icon?.icon_url} alt="" />
                          {reservationDetails.add_on_services[key].name}
                        </div>
                      </td>
                      <td>
                        <span className="common-sm-dark-text">
                          {renderAddOnPrice(
                            key,
                            reservationDetails,
                            check_in_date,
                            check_out_date
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <hr />
        <div className="payment-details about-retreat-container">
          <div className="common-sm-bold-dark-text">Payment Details</div>
          <div className="d-flex flex-column gap-2 ">
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                Accomodation
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $ 12,260
              </div>
            </div>
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                Add-on-Services
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $ {totalAddOnsPrice}
              </div>
            </div>
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title common-sm-light-grey-text">
                Taxes
              </div>
              <div className="about-retreat-data common-sm-medium-dark-text">
                $ 120
              </div>
            </div>
            <hr />
            <div className="payment-details-card d-flex ">
              <div className="about-retreat-title payment-details-total">
                Total Payout
              </div>
              <div className="about-retreat-data payment-details-total ">
                $ {reservationDetails?.total_price}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetails;
