import React from "react";

// third party
import { ConfigProvider, Tabs, TabsProps } from "antd";

const TabsContainer: React.FC<{
  reservationRequestListTabs: TabsProps["items"];
  modalEvents?: boolean;
  onTabChange: (key: string) => void;
}> = ({ reservationRequestListTabs, onTabChange, modalEvents }) => {
  return (
    <div className="reservation-request-list edit-overview-requests position-relative">
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "var(--deep-sea) !important",
              inkBarColor: "var(--deep-sea) !important",
              itemHoverColor: "var(--deep-sea) !important",
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={reservationRequestListTabs}
          onChange={!modalEvents ? undefined : onTabChange}
        />
      </ConfigProvider>
    </div>
  );
};

export default TabsContainer;
