import React from "react";
import { Collapse } from "antd";
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
//constant
import { dropDownItems } from "../../../../constants/RetreatHost";


const RetreatFAQ : React.FC= () => {
 
  const CustomExpandIcon = ({
    isActive,
  }: {
    isActive: boolean | undefined;
  }) => {
    return <>{isActive ? <DownOutlined /> : <UpOutlined />}</>;
  };
  return (
    <div className="d-flex flex-column gap-3">
      <p className="retreat-venue-details-title">FAQ</p>
      <Collapse
        expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive} />}
        items={dropDownItems}
        expandIconPosition="end"
        ghost
      />
    </div>
  );
};

export default RetreatFAQ;
