//third party
import { ArrowRight, CalendarCheck } from "lucide-react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

//common component
import SmallText from "../../../UI/SmallText";

// redux
import { RootState } from "../../../../redux/store";

//images
import { images } from "../../../../assets/images";

const CancelVenueDetail = () => {
  const reservationData = useSelector(
    (state: RootState) => state.reservation.data
  );

  return (
    <div className="d-flex bg-white flex-column gap-3 step-box-shadow p-5 w-100">
      <div className="d-flex gap-2 align-items-start detail-header">
        <img
          src={reservationData?.venue?.images[0] || images.NO_VENUES_IMG}
          width={60}
          height={60}
          className="rounded"
          alt="venue_img"
        />
        <div className="d-flex gap-2 flex-column">
          <SmallText
            text={`${reservationData?.venue?.name}`}
            color="#19191C"
            fontWeight={700}
            fontSize={18}
            lineHeight={21.15}
          />
          <SmallText
            text={`${reservationData?.venue?.city}, ${reservationData?.venue.country}`}
            color="#58585B"
            fontWeight={400}
            fontSize={14}
            lineHeight={16.45}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-2 detail-header">
        <div>
          <div className="py-1">
            <SmallText
              text="Reservation Summary"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={700}
              fontSize={16}
            />
          </div>
          <div className="py-2 pe-2">
            <div className="d-flex gap-2 align-items-start ">
              <img
                src={
                  reservationData?.host?.profile_picture_url ||
                  images.PERSON_AVATAR
                }
                width={40}
                height={40}
                className="rounded"
                alt="venue_img"
              />
              <div className="d-flex gap-1 flex-column">
                <SmallText
                  text={`${reservationData?.host?.name || ""}`}
                  color="#19191C"
                  fontWeight={400}
                  fontSize={16}
                  lineHeight={18.8}
                />
                <SmallText
                  text={`${reservationData?.host?.city || ""}`}
                  color="#949397"
                  fontWeight={400}
                  fontSize={14}
                  lineHeight={16.45}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <CalendarCheck color="#58585B" width={16} height={16} />
          <div className="d-flex  align-items-center" style={{ gap: "12px" }}>
            <SmallText
              text={`${dayjs(reservationData?.check_in_date).format(
                "MMM DD YYYY"
              )}`}
              color="#19191C"
              lineHeight={18.8}
              fontWeight={400}
              fontSize={16}
            />
            <ArrowRight width={16} height={16} color="#39393D" />
            <SmallText
              text={`${dayjs(reservationData?.check_out_date).format(
                "MMM DD YYYY"
              )}`}
              color="#19191C"
              lineHeight={18.8}
              fontWeight={400}
              fontSize={16}
            />
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <img src={images.GROUP} alt="icon" width={16} height={16} />

          <SmallText
            text="Group Size:"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={400}
            fontSize={16}
          />

          <SmallText
            text={`${reservationData?.group_size}`}
            color="#19191C"
            lineHeight={18.8}
            fontWeight={400}
            fontSize={16}
          />
        </div>
        <div className="d-flex gap-2 align-items-center">
          <img src={images.CALENDAR4} alt="icon" width={16} height={16} />
          <SmallText
            text={`${dayjs(reservationData?.check_in_date).diff(
              dayjs(),
              "days"
            )} days until retreat starts`}
            color="#19191C"
            lineHeight={18.8}
            fontWeight={400}
            fontSize={16}
          />
        </div>
      </div>
      {/* TODO: PAYMENT DETAIL */}
      {/* <div className="d-flex flex-column gap-3">
        <SmallText
          text="Payment Details"
          color="#19191C"
          lineHeight={18.8}
          fontWeight={700}
          fontSize={16}
        />
        <div
          className="d-flex flex-column  payment-detail"
          style={{ gap: "2px" }}
        >
          <div>
            <SmallText
              text="Accomodation"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
            <SmallText
              text="$ 12,260"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </div>
          <div>
            <SmallText
              text="Add-on-Services"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
            <SmallText
              text="$ 120"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </div>
          <div>
            <SmallText
              text="Service Fee"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
            <SmallText
              text="$ 12,260"
              color="#58585B"
              lineHeight={16.45}
              fontWeight={400}
              fontSize={14}
            />
          </div>
          <div>
            <SmallText
              text="Total Payout"
              color="#19191C"
              lineHeight={18.8}
              fontWeight={600}
              fontSize={16}
            />
            <SmallText
              text={`$ ${reservationData?.total_price}`}
              color="#19191C"
              lineHeight={18.8}
              fontWeight={600}
              fontSize={16}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CancelVenueDetail;
