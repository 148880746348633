/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

// third party
import { DatePicker, Form, FormProps, Popover, Radio, Space, Spin } from "antd";
import { useSelector } from "react-redux";

// custom component
import Button from "../../UI/Button";
import InputComponent from "../../UI/InputComponent";

// redux
import { RootState } from "../../../redux/store";

// helpers
import { convertToTitleCase, getMessagePromises } from "../../../library";

// types
import { UserListProps, MessageFilterFormTypes } from "../../../types";

// images
import { images } from "../../../assets/images";

const ChatList: React.FC<UserListProps> = ({
  newUsersList = [],
  setSelectedUser,
  usersList,
  selectedChatUserId,
  lastMessages,
  unseenCounts,
  onFocus,
  onSearch,
  chatsLoading,
  fetchMessageForSelectedUser,
}) => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );
  const [form] = Form.useForm();

  const [openPopover, setOpenPopover] = useState(false);
  const [filters, setFilters] = useState<MessageFilterFormTypes>({});
  const [filteredUsers, setFilteredUsers] = useState<any[]>(newUsersList);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    selectedChatUserId || null
  );

  const onApplyFilter: FormProps<MessageFilterFormTypes>["onFinish"] = (
    values
  ) => {
    setFilters(values);
    setOpenPopover(false);
  };

  const filterContent = (
    <Form form={form} onFinish={onApplyFilter}>
      <div className="reservation-filter-box">
        <div className="d-flex justify-content-between gap-2">
          <span className="flex-grow-1 common-md-medium-dark-text">
            Filter by
          </span>
          <button
            type="button"
            className="bg-transparent border-0 p-0"
            onClick={() => setOpenPopover(false)}
          >
            <img src={images.CROSS_ICON_BLACK} alt="" height={24} />
          </button>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>Start Date</span>
          <Form.Item name={"startDate"}>
            <DatePicker placeholder="Select or Write" />
          </Form.Item>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>End Date</span>
          <Form.Item name={"endDate"}>
            <DatePicker placeholder="Select or Write" />
          </Form.Item>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>Status</span>
          <Form.Item name={"read"}>
            <Radio.Group>
              <Space direction="vertical" className="w-100">
                <Radio value={"all"} className="d-flex">
                  All
                </Radio>
                <Radio value={"read"}>Read</Radio>
                <Radio value={"unread"}>Unread</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="d-flex gap-3 w-100 align-items-center justify-content-end">
          <div className="d-flex gap-2">
            <Button
              type="button"
              label="Clear"
              className="bordered-button"
              onClick={() => {
                form.resetFields();
                setFilters({});
                setOpenPopover(false);
              }}
            />
            <Button type="submit" label={"Apply"} />
          </div>
        </div>
      </div>
    </Form>
  );

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
  };

  const handleChatWindowFocus = (userId: string) => {
    const initialActiveState = usersList.reduce(
      (acc, user) => ({ ...acc, [user.uid]: false }),
      {}
    );
    onFocus({
      ...initialActiveState,
      [userId]: true,
    });
  };

  const filterMessages = useCallback(async () => {
    const currentUserUid = userProfileData.user_id;
    if (!currentUserUid) return;

    let tempNewUsersList = newUsersList;

    if (filters.startDate || filters.endDate) {
      const startTimestamp = filters.startDate
        ? new Date(filters.startDate).setHours(0, 0, 0, 0)
        : 0;
      const endTimestamp = filters.endDate
        ? new Date(filters.endDate).setHours(23, 59, 59, 999)
        : Date.now();

      const messageResults = await getMessagePromises(
        tempNewUsersList,
        currentUserUid,
        startTimestamp,
        endTimestamp
      );

      tempNewUsersList = messageResults.filter((result) => result.hasMessages);
    }
    if (filters.read === "read" || filters.read === "unread") {
      tempNewUsersList = tempNewUsersList.filter((user) => {
        return filters.read === "read" ? user.count === 0 : user.count > 0;
      });
    }

    const findSelectedUser = tempNewUsersList.find(
      (user) => user.uid === selectedChatUserId
    );

    fetchMessageForSelectedUser(findSelectedUser || tempNewUsersList[0]);
    setFilteredUsers(tempNewUsersList);
  }, [filters, newUsersList]);

  useEffect(() => {
    selectedChatUserId && setSelectedUserId(selectedChatUserId);
  }, [selectedChatUserId]);

  useEffect(() => {
    filterMessages();
  }, [filterMessages]);

  useEffect(() => {
    setFilteredUsers(newUsersList);
  }, [newUsersList]);

  return (
    <div className="chat-list-container">
      <div className="d-flex justify-content-between align-items-center gap-2">
        {/* Todo : search */}
        {/* <Input
          placeholder="Search"
          prefix={<img src={images.SEARCH_GREY} height={"16px"} alt="" />}
          className="common-sm-light-text"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ height: "40px", padding: "8px 8px" }}
        /> */}
        <InputComponent
          placeholder="Search"
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
        <Popover
          content={filterContent}
          trigger={"click"}
          placement="bottomRight"
          rootClassName="message-filter-popover"
          open={openPopover}
          onOpenChange={handleOpenChange}
        >
          <button className="border-0 background-transparent p-0">
            <img src={images.FILTER_ICON} alt="" height={"40px"} />
          </button>
        </Popover>

        {/* TODO : This might be useful in FUTURE */}
        {/* <button className="border-0 background-transparent p-0">
          <img src={images.FILTER_ICON} alt="" height={"40px"} />
        </button> */}
      </div>
      <div className="chat-list">
        {chatsLoading ? (
          <>
            <Spin />
          </>
        ) : filteredUsers.length > 0 ? (
          filteredUsers.map((user, index) => (
            <div>
              <div key={`chat-item-${index}`}>
                <div
                  className={`chat-item ${
                    user?.uid === selectedUserId ? "active" : ""
                  }`}
                  onClick={() => {
                    fetchMessageForSelectedUser(user);
                    handleChatWindowFocus(user?.uid);
                    setSelectedUserId(user?.uid);
                  }}
                >
                  <img src={images.PERSON_AVATAR} alt={user?.name} />
                  <div
                    className="d-flex flex-column"
                    style={{ width: "calc(100% - 68px)" }}
                  >
                    <div className=" d-flex ">
                      <div className="chat-name common-sm-medium-dark-text flex-grow-1">
                        {user?.name}{" "}
                        <p className="common-xs-light-text m-0">
                          {convertToTitleCase(user?.userRole) || ""}
                        </p>
                      </div>
                      <div className="chat-time common-xs-light-text m-0">
                        {user?.last_message_timestamp &&
                          new Date(
                            user.last_message_timestamp
                          ).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                          })}
                      </div>
                    </div>
                    <div className="chat-last-message w-100">
                      <div className=" overflow-hidden text-truncate ">
                        <p className=" text-truncate">
                          {user?.lastMessage?.text || ""}
                        </p>{" "}
                      </div>
                      <div
                        className=" d-flex flex-end"
                        style={{ width: "24px" }}
                      >
                        {user?.count > 0 ? (
                          <span className="unseen-count">{user?.count}</span>
                        ) : (
                          user?.lastMessage?.sender ===
                            userProfileData.user_id &&
                          (user?.lastMessage?.seen ? (
                            <img
                              src={images.MESSAGE_SEEN}
                              alt=""
                              height={"24px"}
                            />
                          ) : (
                            <img
                              src={images.MESSAGE_SENT}
                              alt=""
                              height={"24px"}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          ))
        ) : (
          <>
            <div>
              <h6>No Users</h6>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatList;
