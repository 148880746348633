import React, { useState } from "react";

//third party
import { useDispatch } from "react-redux";
import { ChevronRight } from "lucide-react";
import { Form } from "antd";

//common component
import SmallText from "../../../UI/SmallText";
import FormBtn from "../../../UI/InputElement/FormButton";
import ModalComponent from "../../../UI/ModalComponent";

//custom hook
import { useApiCall } from "../../../../hooks/useApiCall";

//redux
import { setShowModal } from "../../../../redux/features/component-slice";

//api call
import { deleteUserAccount } from "../../../../network";

//utils
import { ToastFail, ToastSuccess } from "../../../../utils/TostMessage";

const DeleteAccount = () => {
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //handle open modal
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  //handle close modal
  const closeModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: false }));
  };

  //handle delete account
  const handleRequest = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);
      call(
        () => deleteUserAccount(values.reason),
        (res) => {
          ToastSuccess(res.data.message);
          form.resetFields();
          setIsLoading(false);
          closeModal("deleteAccount");
        },
        (err) => {
          ToastFail(err.response.data.message);
          form.resetFields();
          setIsLoading(false);
          closeModal("deleteAccount");
        }
      );
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-white p-4 common-border">
        <div className="d-flex flex-column  gap-1 ">
          <SmallText
            text="Deactivate Account"
            color="#19191C"
            lineHeight={18.8}
            fontWeight={500}
            fontSize={16}
          />
          <div style={{height:"32px"}} className="d-flex align-items-center">

          <SmallText
            text="Warning! All venues will be unpublished and not be able to be booked"
            color="#949397"
            lineHeight={16.45}
            fontWeight={400}
            fontSize={14}
            />
            </div>
        </div>
        <div
          onClick={() => openModal("deleteAccount")}
          style={{ cursor: "pointer" }}
        >
          <ChevronRight color="#19191C" width={20} height={20} />
        </div>
      </div>

      <ModalComponent modalName="deleteAccount" title="Delete Account">
        <Form form={form} onFinish={handleRequest} layout="vertical">
          <div className="p-4  bg-white d-flex flex-column gap-4">
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please enter a reason",
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <div className="d-flex flex-column gap-1">
                <SmallText
                  text="Write specific reason to delete an account"
                  color="#58585B"
                  lineHeight={18.8}
                  fontWeight={700}
                  fontSize={16}
                />
                <textarea className="form-control" rows={3} maxLength={235} />
              </div>
            </Form.Item>
            <div className="d-flex justify-content-end">
              <div className="d-flex gap-3">
                <button
                  className="back-btn"
                  onClick={() => closeModal("deleteAccount")}
                >
                  Cancel
                </button>
                <FormBtn
                  text="Send Request"
                  className="submit-btn"
                  type="submit"
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};

export default DeleteAccount;
