import React, { useState } from "react";

//third party
import { Steps } from "antd";

//component
import SelectReason from "./SelectReason";
import SendMessage from "./SendMessage";
import ConfirmCancel from "./ConfirmCancel";
import CancelVenueDetail from "./CancelVenueDetail";
import Policy from "./Policy";

const { Step } = Steps;
const CancelSteps = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const steps = [
    {
      title: "Step 1:  Select reason",
      content: (
        <SelectReason
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      title: "Step 2: Send message",
      content: (
        <SendMessage
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
    {
      title: "Step 3: Confirm cancellation",
      content: (
        <ConfirmCancel
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
    },
  ];

  return (
    <div className="container overflow-hidden cancel-steps">
      <Steps current={currentStep} labelPlacement="vertical">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="d-flex gap-5 step-view">
        <div className="steps-content flex-grow-1">
          {steps[currentStep].content}
        </div>
        <div className="d-flex flex-column gap-4 cancel-venue-detail overflow-auto">
          <CancelVenueDetail />
          <Policy />
        </div>
      </div>
    </div>
  );
};

export default CancelSteps;
