import React from "react";
import { useDispatch, useSelector } from "react-redux";

//redux store
import { RootState } from "../../../../redux/store";
import { setShowModal } from "../../../../redux/features/component-slice";

//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";

//images
import { images } from "../../../../assets/images";

const AboutTheRetreatVenue: React.FC = () => {
  const dispatch = useDispatch();
  const { retreatPropertyDetail } = useSelector(
    (state: RootState) => state.retreatVenue
  );

  const icons = useSelector((state: RootState) => state.commonReducer.icons);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  return (
    <div className="d-flex flex-column gap-4">
      <p className="retreat-venue-details-title">About the venue</p>
      <div className="about-venue-label-container d-flex flex-wrap">
        {retreatPropertyDetail &&
          retreatPropertyDetail.highlights.map((label, index) => (
            <div
              className="about-venue-label d-flex align-items-center"
              key={index}
            >
              <img
                src={icons.find((icon) => icon.id === label.icon_id)?.icon_url}
                alt=""
              />
              <SmallText
                text={label.name}
                color="#2F206A"
                fontSize={18}
                fontWeight={500}
                lineHeight={21.15}
              />
            </div>
          ))}
      </div>
      <div className="d-flex flex-column gap-3">
        <p className="section-description">
          {retreatPropertyDetail?.property_description}
        </p>
        <div className="venueIcon">
          <button
            className="modal-open-button"
            style={{ border: "none", background: "transparent", padding: 0 }}
            type="button"
            onClick={() => openModal("retreatVenueDetail")}
          >
            <SmallText text="Read more" />
            <img src={images.RIGHT_ARROW_ICON} alt="" />
          </button>
        </div>
      </div>

      <ModalComponent modalName="retreatVenueDetail" title="About the venue">
        <div className="about-venue-label-container d-flex flex-wrap">
        {retreatPropertyDetail &&
          retreatPropertyDetail.highlights.map((label, index) => (
            <div
              className="about-venue-label d-flex align-items-center"
              key={index}
            >
              <img
                src={icons.find((icon) => icon.id === label.icon_id)?.icon_url}
                alt=""
              />
              <SmallText
                text={label.name}
                color="#2F206A"
                fontSize={18}
                fontWeight={500}
                lineHeight={21.15}
              />
            </div>
          ))}
        </div>
        <p className="section-description">
          {retreatPropertyDetail?.property_description}
        </p>
      </ModalComponent>
    </div>
  );
};

export default AboutTheRetreatVenue;
