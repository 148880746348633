
//images
import { demo_images as images } from "../../assets/images/demo-images";

export const allVenue = [
  {
    image: images.DEMO_IMAGE_1,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_2,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_3,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_4,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_5,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_1,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_2,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_3,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_4,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_5,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_1,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_2,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_3,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_4,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
  {
    image: images.DEMO_IMAGE_5,
    name: "Serenity Meadows",
    description: "8 BR - 12 beds - 6 baths - Sleeps 16",
    address: "Tuscany, Italy",
  },
];

export const regions = [
  {
    option: "Flexible search",
  },
  {
    option: "Europe",
  },
  {
    option: "Asia",
  },
  {
    option: "North America",
  },
  {
    option: "South America",
  },
  {
    option: "Caribbean",
  },
];

export const cards = [
  {
    title : "Airport Transfer",
    description : "We offer private transportation to and from Bern Airport, ensuring a smooth journey to your accommodation.",
    price :"Free",
    perPrice : ""
  },
  {
    title : "Tour Guide",
    description : "Enjoy a personalized experience, tailored to your preferences and interests, while discovering the local history and culture",
    price :"$100",
    perPrice : "/day"
  },
  {
    title : "Room Breakfast",
    description : "We offer various options to suit all tastes and dietary preferences, guaranteeing an invigorating morning.",
    price :"$120",
    perPrice : "/guest"
  },
  {
    title : "Extra Cleaning",
    description : "Your space is always spotless with our extra cleaning service, ideal for long stays or special events.",
    price :"$312",
    perPrice : "/guest"
  },
  {
    title : "Extra Key",
    description : "We offer various options to suit all tastes and dietary preferences, guaranteeing an invigorating morning.",
    price :"$120",
    perPrice : "/guest"
  },
  {
    title : "Extra Bed",
    description : "We offer various options to suit all tastes and dietary preferences, guaranteeing an invigorating morning.",
    price :"$120",
    perPrice : "/guest"
  },
  {
    title : "Welcome Kit",
    description : "We offer various options to suit all tastes and dietary preferences, guaranteeing an invigorating morning.",
    price :"$120",
    perPrice : "/guest"
  },
  {
    title : "Extra Parking",
    description : "We offer various options to suit all tastes and dietary preferences, guaranteeing an invigorating morning.",
    price :"$120",
    perPrice : "/guest"
  },
]