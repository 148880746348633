import React from "react";

//third party
import { useNavigate } from "react-router-dom";

//common component
import Welcome from "../../../UI/WelcomePage/Welcome";

//constant
import {
  descriptionOfRetreatPage,
  stepsOfRetreatHost,
  titleOfRetreatPage,
} from "../../../../constants/RetreatHost";

const WelcomePage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/host/retreat/create");
  };
  return (
    <Welcome
      title={titleOfRetreatPage}
      description={descriptionOfRetreatPage}
      steps={stepsOfRetreatHost}
      handleClick={handleClick}
      image={false}
    />
  );
};

export default WelcomePage;
