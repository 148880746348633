import React from 'react'

const HostExplore = () => {
  return (
    <div>
      Explore section.
    </div>
  )
}

export default HostExplore
