import React from "react";

// types
import { ButtonProps } from "../../../types";

// styles
import "./style.css";


const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  className,
  ...props
}) => {
  return (
    <button
      className={`custom-button ${className}`}
      onClick={onClick}
      {...props}
    >
      {label}
    </button>
  );
};

export default Button;
