// CommonTable.tsx
import React from "react";

// third party
import { PaginationProps, Table } from "antd";
import type { TableProps } from "antd/es/table";
import type { ColumnsType } from "antd/es/table";
import { useDispatch } from "react-redux";

// redux
import { setCurrentPage } from "../../../redux/features/paginationSlice";

// images
import { images } from "../../../assets/images";

// styles
import "./style.css";

// Define types for the props
interface CommonTableProps<T> extends TableProps<T> {
  data: T[];
  columns: ColumnsType<T>;
  showPagination?: boolean;
  pageSize?: number;
  paginationData?: PaginationObjType;
}

interface PaginationObjType {
  current?: number;
  total?: number;
  onChange?: (page: number, pageSize: number) => void;
}

// Define a type for the data item
interface DataType {
  key: React.Key;
  [key: string]: any; // You can adjust this based on your data structure
}

// CommonTable component
const TableComponent = <T extends DataType>({
  data,
  columns,
  showPagination = true,
  pageSize = 10,
  paginationData: paginationObj,
  ...props
}: CommonTableProps<T>) => {
  const dispatch = useDispatch();
  // const paginationSelector = useSelector(
  //   (state: RootState) => state.pagination
  // );
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <button
          type="button"
          className="bordered-button"
          style={{
            height: "36px",
            border: "1px solid #E9F1F6",
            display: "flex",
            alignItems: "center",
            padding: "8px 14px",
            gap: "8px",
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
        >
          {" "}
          <img src={images.PAGINATION_ARROW_LEFT} alt="" height={"20px"} />{" "}
          Previous
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          type="button"
          className="bordered-button"
          style={{
            height: "36px",
            border: "1px solid #E9F1F6",
            padding: "8px 14px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
        >
          Next{" "}
          <img src={images.PAGINATION_ARROW_RIGHT} alt="" height={"20px"} />
        </button>
      );
    }
    return originalElement;
  };

  const onPageChange = (page: number, pageSize?: number) => {
    dispatch(setCurrentPage(page));
  };

  return (
    <Table
      columns={columns}
      
      dataSource={data}
      showSorterTooltip={true}
      pagination={
        showPagination
          ? {
              pageSize: pageSize,
              itemRender: itemRender,
              align: "center",
              position: ["bottomCenter"],
              showSizeChanger: false,
              onChange(page, pageSize) {
                if (paginationObj?.onChange) {
                  paginationObj?.onChange(page, pageSize);
                } else {
                  onPageChange(page, pageSize);
                }
              },
              total: paginationObj?.total,
              current: paginationObj?.current,
              hideOnSinglePage: true,
            }
          : false
      }
      {...props}
      className="table-component"
    />
  );
};

export default TableComponent;
