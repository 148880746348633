/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

//third party
import { useDispatch, useSelector } from "react-redux";
import { ChevronRight } from "lucide-react";

//redux store
import { RootState } from "../../../../redux/store";
import { setShowModal } from "../../../../redux/features/component-slice";

//common component
import SmallText from "../../../UI/SmallText";
import ModalComponent from "../../../UI/ModalComponent";

//types
import { IconType } from "../../../../types/property-manager";

//image
import { images } from "../../../../assets/images";
import NoDataFound from "../../../UI/NoDataFound";

interface AboutSectionProps {
  aboutTitle: string;
  aboutDescription?: string;
  venueType?: IconType[];
  onlyAmenities?: boolean;
  sectionId?: string;
  isThereContent?: boolean;
}
const AboutSection: React.FC<AboutSectionProps> = ({
  aboutTitle,
  aboutDescription,
  venueType,
  onlyAmenities,
  sectionId,
  isThereContent,
}) => {
  const dispatch = useDispatch();
  const icons = useSelector((state: RootState) => state.commonReducer.icons);
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  return (
    <>
      <div className="d-flex flex-column gap-3" id={sectionId}>
        <SmallText
          text={aboutTitle}
          color="#949397"
          lineHeight={21.15}
          fontWeight={500}
          fontSize={18}
        />
        <div
          className="d-flex flex-column gap-3 bg-white p-3"
          style={{ border: "1px solid #E8E7EC", borderRadius: "8px" }}
        >
          {isThereContent ? (
            onlyAmenities ? (
              <>
                <div
                  className="d-flex gap-2 flex-wrap"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {venueType?.map((type, index) => (
                    <div
                      className="d-flex gap-2 align-items-center flex-grow-1"
                      key={`icon-${index}`}
                      style={{ maxWidth: "220px" }}
                    >
                      <img
                        src={
                          icons.find((icon) => icon.id === type.icon_id)
                            ?.icon_url ?? images.STAR
                        }
                        alt="icon"
                        width={28}
                        height={28}
                      />
                      <SmallText
                        text={type.name}
                        color="#2F206A"
                        fontSize={18}
                        lineHeight={21.15}
                        fontWeight={400}
                        fontFamily="var(--public-sans)"
                      />
                    </div>
                  ))}
                </div>
                <div className="venueIcon">
                  <button
                    className="modal-open-button"
                    style={{
                      border: "none",
                      background: "transparent",
                      padding: 0,
                    }}
                    type="button"
                    onClick={() => openModal(aboutTitle)}
                  >
                    <SmallText text="See all" />
                    <ChevronRight color="#2771A3" width={16} height={16} />
                  </button>
                </div>
              </>
            ) : (
              <>
                {venueType ? (
                  <div className="d-flex flex-wrap gap-4">
                    {venueType.map((type, index) => (
                      <div
                        className="d-flex gap-2 align-items-center"
                        key={`icon-${index}`}
                      >
                        <img
                          src={
                            icons.find((icon) => icon.id === type.icon_id)
                              ?.icon_url
                          }
                          alt="icon"
                          width={28}
                          height={28}
                        />
                        <SmallText
                          text={type.name}
                          color="#2F206A"
                          fontSize={18}
                          lineHeight={21.15}
                          fontWeight={400}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
                {aboutDescription ? (
                  <div
                    style={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 4,
                    }}
                  >
                    <SmallText
                      text={aboutDescription}
                      color="#58585B"
                      fontSize={16}
                      lineHeight={18.8}
                      fontWeight={400}
                    />
                  </div>
                ) : (
                  <NoDataFound
                    boxStyle={{ height: 100 }}
                    iconSize={32}
                    text="No Data"
                  />
                )}

                <div className="venueIcon">
                  <button
                    className="modal-open-button"
                    style={{
                      border: "none",
                      background: "transparent",
                      padding: 0,
                    }}
                    type="button"
                    onClick={() => openModal(aboutTitle)}
                  >
                    <SmallText text="Read more" />
                    <ChevronRight color="#2771A3" width={16} height={16} />
                  </button>
                </div>
              </>
            )
          ) : (
            <NoDataFound
              boxStyle={{ height: 120 }}
              iconSize={32}
              text="No Data"
            />
          )}
        </div>
      </div>
      <ModalComponent modalName={aboutTitle} title={aboutTitle}>
        {onlyAmenities ? (
          <div
            className="d-flex gap-2 flex-wrap "
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            {venueType?.map((type, index) => (
              <div
                className="d-flex gap-2 align-items-center flex-grow-1"
                key={`icon-${index}}`}
                style={{ maxWidth: "220px" }}
              >
                <img
                  src={
                    icons.find((icon) => icon.id === type.icon_id)?.icon_url ??
                    images.STAR
                  }
                  alt="icon"
                  width={28}
                  height={28}
                />
                <SmallText
                  text={type.name}
                  color="#2F206A"
                  fontSize={18}
                  lineHeight={21.15}
                  fontWeight={400}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex flex-column gap-3 ">
            {venueType ? (
              <div className="d-flex flex-wrap gap-4">
                {venueType?.map((type, index) => (
                  <div
                    className="d-flex gap-2 align-items-center"
                    key={`icon-${index}}`}
                  >
                    <img
                      src={
                        icons.find((icon) => icon.id === type.icon_id)?.icon_url
                      }
                      alt="icon"
                      width={28}
                      height={28}
                    />
                    <SmallText
                      text={type.name}
                      color="#2F206A"
                      fontSize={18}
                      lineHeight={21.15}
                      fontWeight={400}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            {aboutDescription && (
              <SmallText
                text={aboutDescription}
                color="#58585B"
                fontSize={16}
                lineHeight={18.8}
                fontWeight={400}
              />
            )}
          </div>
        )}
      </ModalComponent>
    </>
  );
};

export default AboutSection;
