/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

// third party
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

// custom components
import TableComponent from "../../../UI/TableComponent";

// images
import { images } from "../../../../assets/images";

// Types
import { adminRetreatTableColumnType } from "../../../../types/admin";
import { demo_images } from "../../../../assets/images/demo-images";

// Utility
import { convertDateFormat, formatNumberWithCommas } from "../../../../library";

const RetreatListingTable = ({
  retreatData,
  totalItems,
  currentPage,
  pageSize = 10,
  onPageChange,
  loading = false,
}: {
  retreatData?: any;
  totalItems: number;
  currentPage: number;
  pageSize?: number;
  onPageChange: (page: number, pageSize: number, type?: string) => void;
  loading?: boolean;
}) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [retreatsData, setRetreatsData] = useState<
    adminRetreatTableColumnType[]
  >([]);

  // TODO: This might be useful in future
  // const [clickedItemId, setClickedItemId] = useState<string>("");
  // const openModal = (modalName: string) => {
  //   dispatch(setShowModal({ modalName, visible: true }));
  // };

  // const handleModal = (itemId: any) => {
  //   setClickedItemId(itemId);
  //   openModal(`retreat-details`);
  // };

  const retreatsColumns: ColumnsType<adminRetreatTableColumnType> = [
    {
      key: "retreatHost",
      dataIndex: "retreatHost",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.USERS_THREE} alt="" height={"16px"} />
          <span>Retreat Host</span>
        </div>
      ),
      width: 150,
      render: (hostObject: any) => {
        return hostObject?.name ? (
          <div className="d-flex gap-2 py-2 pe-2">
            <img
              src={hostObject?.profile_picture_url || demo_images.DEMO_IMAGE_1}
              alt=""
              width={40}
              height={40}
              className="rounded"
            />
            {(hostObject?.name || hostObject?.city) && (
              <div className="m-auto">
                {hostObject?.name && (
                  <p className="host-name-title">{hostObject?.name}</p>
                )}
                {hostObject?.city && (
                  <p className="host-city">{hostObject?.city}</p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      key: "retreatName",
      dataIndex: "retreatName",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.Retreat} alt="" height={"16px"} />
          <span>Name</span>
        </div>
      ),
      width: 200,
      render: (_, { retreatName }) => `${retreatName}`,
    },
    {
      key: "retreatCheckIn",
      dataIndex: "retreatCheckIn",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span>Check In</span>
        </div>
      ),
      width: 100,
    },
    {
      key: "retreatCheckOut",
      dataIndex: "retreatCheckOut",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.CALENDAR_DOT} alt="" height={"16px"} />
          <span>Check Out</span>
        </div>
      ),
      width: 100,
    },
    {
      key: "price",
      dataIndex: "price",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.COIN} alt="" height={"16px"} />
          <span>Price</span>
        </div>
      ),
      width: 70,
      render: (_, { price }) => {
        return price ? `$ ${price}` : `-`;
      },
    },
    {
      key: "venueName",
      dataIndex: "venueName",
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <img src={images.HOUSE_ICON} alt="" height={"16px"} />
          <span>Venue Name</span>
        </div>
      ),
      width: 250,
      render: (_, { venueName }) => `${venueName}`,
    },
    {
      key: "action",
      dataIndex: "action",
      className: "action-button",
      width: 100,
      title: (
        <div className="d-flex flex-row align-items-center gap-1 ">
          <span>Action</span>
        </div>
      ),
      render: (_, item) => {
        return (
          <div className="payout-details-button">
            <button
              type="button"
              className="bordered-button detail-button"
              onClick={(e) => navigate(`/admin/retreats/${item.key}`)}
            >
              <EyeOutlined /> Details
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (retreatData) {
      const formattedData = retreatData.map((item: any, index: number) => ({
        key: item?.id,
        retreatName: item?.title || "-",
        retreatHost: item?.retreat_host || "-",
        retreatCheckIn: convertDateFormat(item?.check_in_date) || "-",
        retreatCheckOut: convertDateFormat(item?.check_out_date) || "-",
        price: formatNumberWithCommas(item?.total_price) || "-",
        venueName: item?.venue_name || "-",
        action: item,
      }));
      setRetreatsData(formattedData);
    }
  }, [retreatData]);

  return (
    <>
      <TableComponent
        columns={retreatsColumns}
        data={retreatsData}
        pageSize={pageSize}
        scroll={{ x: 1200, y: "calc(100vh - 346px)" }}
        tableLayout="fixed"
        paginationData={{
          onChange: onPageChange,
          total: totalItems,
          current: currentPage,
        }}
        loading={loading}
      />
      {/* This might be useful in future */}
      {/* <ModalComponent modalName={"retreat-details"}>
        <PayoutDetail payoutId={clickedItemId as string} />
      </ModalComponent> */}
    </>
  );
};

export default RetreatListingTable;
