/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// Third party
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

// custom component
import MainLayout from "../../../MainLayout";
import SpacesForm from "./SpacesForm";

//  redux
import { RootState } from "../../../../../redux/store";
import { setLoading } from "../../../../../redux/features/common-slice";

// network
import { fetchAddOnServices } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import { AddOnServicesTypes } from "../../../../../types/property-manager";

// styles
import "../../../../../assets/css/create-venue.css";

const CreateVenueFormStep4: React.FC<{
  venue_id: string;
  handleNextStep: (query?: string) => void;
  handlePreviousStep: () => void;
}> = ({ venue_id, handleNextStep, handlePreviousStep }) => {
  const [addOnServices, setAddOnServices] = React.useState<
    AddOnServicesTypes[]
  >([]);
  const [formStep, setFormStep] = React.useState(0);
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const loading = useSelector(
    (state: RootState) => state.commonReducer.loading
  );

  const fetchAddOnService = async () => {
    dispatch(setLoading(true));
    call(
      () => fetchAddOnServices(venue_id),
      (res) => {
        setAddOnServices(res.data.data.add_on_services);
        setFormStep(res.data.data.form_state.step);
        dispatch(setLoading(false));
      },
      (err) => {
        dispatch(setLoading(false));
      }
    );
  };

  useEffect(() => {
    fetchAddOnService();
  }, []);

  return loading ? (
    <Spin fullscreen />
  ) : (
    <div className="add-spaces">
      <MainLayout>
        <SpacesForm
          addOnServices={addOnServices}
          venueId={venue_id}
          fetchAddOnServices={fetchAddOnService}
          formStep={formStep}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </MainLayout>
    </div>
  );
};

export default CreateVenueFormStep4;
