/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, MenuProps, Spin, TabsProps, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { MoveDown, MoveUp } from "lucide-react";

// custom components
import Button from "../../../UI/Button";
import StatisticCard from "../../../UI/StatisticCard";
import SmallText from "../../../UI/SmallText";
import TotalRevenueChart from "../Home/TotalRevenueChart";
import ModalComponent from "../../../UI/ModalComponent";
import UpcomingReservationDetailModal from "../Home/UpcomingReservationDetailModal";
import ReservationList from "./ReservationList";
import TabsContainer from "./TabsContainer";

// redux
import { setShowModal } from "../../../../redux/features/component-slice";
import { RootState } from "../../../../redux/store";

// network
import {
  getReservationRequestDetails,
  getUpcomingReservationRequest,
  getVenueChartDetails,
  getVenueReservationStatistics,
  getVenueStatistics,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// helpers
import { getAddOnServiceNames, numberSeparator } from "../../../../library";

// types
import { fetchReservationTypes } from "../../../../types/property-manager";

// images
import { images } from "../../../../assets/images";

// styles
import "../../../../assets/css/user-home.css";

interface OverviewProps {
  venueId: string;
}

const Overview: React.FC<OverviewProps> = ({ venueId }) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const showModal = useSelector(
    (state: RootState) => state.modal.modals["reservation-request-list-modal"]
  );

  const [selectedChatFilter, setSelectedChatFilter] =
    useState<string>("Past 3 months");

  const [reservationRequests, setReservationRequest] = useState<any>();
  const [requestToShow, setRequestToShow] = useState<any>();
  const [reservationRequestDetailsLoader, setReservationRequestDetailsLoader] =
    useState<boolean>(false);

  const [totalReservations, setTotalReservations] = useState<number>(0);

  const [totalRevenue, setTotalRevenue] = useState<number>(0);

  const [avgLengthStay, setAvgLengthStay] = useState<number>(0);
  const [revenuePercentageChange, setRevenuePercentageChange] =
    useState<number>(0);

  const [availableDates, setAvailableDates] = useState<number | null>(null);

  const [reservationLoader, setReservationLoader] = useState<boolean>(true);

  const [revenueLoader, setRevenueLoader] = useState<boolean>(true);

  const [chartData, setChartData] = useState<{
    labels: string[];
    dataPoints: number[];
  }>();
  const [currentYearDates, setCurrentYearDates] = useState<number>(1);
  const [totalLengthStay, setTotalLengthStay] = useState<number>(0);
  const [yearStayLength, setYearStayLength] = useState<number>(0);
  const [longestStay, setLongestStay] = useState<number>(0);
  const [pendingReservationCount, setPendingReservationCount] = useState(0);
  const [queryParam, setQueryParam] = useState<fetchReservationTypes>({});
  const [requestLoader, setRequestLoader] = useState<boolean>(true);
  const [showAllRequests, setShowAllRequests] = useState<boolean>(false);

  const chatFilterOptions = [
    { key: "1", label: "Past 3 months" },
    { key: "2", label: "Past 6 months" },
    { key: "3", label: "Past year" },
    { key: "4", label: "All years" },
  ];

  const items: MenuProps["items"] = chatFilterOptions.map((option) => ({
    key: option.key,
    label: (
      <button
        className="dropdown-item no-border transparent-bg p-0"
        type="button"
        onClick={() => setSelectedChatFilter(option.label)}
      >
        <SmallText text={option.label} />
      </button>
    ),
  }));

  const reservationRequestListTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "All",
      children: (
        <ReservationList
          reservationRequestList={
            showAllRequests
              ? reservationRequests
              : reservationRequests?.slice(0, 5)
          }
          onClick={(id) => fetchReservationRequestsDetails(id)}
          loading={requestLoader}
        />
      ),
    },
    {
      key: "2",
      label: "Pending",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Pending"
          onClick={(id) => fetchReservationRequestsDetails(id)}
          loading={requestLoader}
        />
      ),
    },
    {
      key: "3",
      label: "Approved",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Approved"
          onClick={(id) => fetchReservationRequestsDetails(id)}
          loading={requestLoader}
        />
      ),
    },
    {
      key: "4",
      label: "Completed",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Complete"
          onClick={(id) => fetchReservationRequestsDetails(id)}
          loading={requestLoader}
        />
      ),
    },
    {
      key: "5",
      label: "Canceled",
      children: (
        <ReservationList
          reservationRequestList={reservationRequests}
          status="Rejected"
          onClick={(id) => fetchReservationRequestsDetails(id)}
          loading={requestLoader}
        />
      ),
    },
  ];

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const fetchReservationRequests = ({
    check_in_date,
    check_out_date,
    group_size,
    approval_status,
    end_date,
    limit,
    max_amount,
    min_amount,
    offset,
    start_date,
    venue_id,
    property_name,
    is_cancelled,
    is_completed,
  }: fetchReservationTypes) => {
    setRequestLoader(true);
    call(
      () =>
        getUpcomingReservationRequest({
          check_in_date,
          check_out_date,
          group_size,
          approval_status,
          end_date,
          limit,
          max_amount,
          min_amount,
          offset,
          start_date,
          venue_id,
          property_name,
          is_cancelled,
          is_completed,
        }),
      (res) => {
        const {
          data: { data },
        } = res;
        setPendingReservationCount(res.data.count);
        setReservationRequest(data);
        data[0]?.id && fetchReservationRequestsDetails(data[0]?.id);
        setRequestLoader(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch requests");
        setRequestLoader(false);
      }
    );
  };

  const fetchReservationRequestsDetails = async (reservationId: string) => {
    setReservationRequestDetailsLoader(true);
    call(
      () => getReservationRequestDetails(reservationId),
      (res) => {
        setRequestToShow(res?.data?.data);
        setReservationRequestDetailsLoader(false);
      },
      (err) => {
        setReservationRequestDetailsLoader(false);
      }
    );
  };

  const fetchHomeStatistics = async () => {
    call(
      () => getVenueStatistics({ venueId }),
      (res) => {
        const data = res.data.data;
        setAvailableDates(data.available_dates);
        setCurrentYearDates(
          data.current_year_available_dates > 0
            ? data.current_year_available_dates
            : 1
        );
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservation details"
        );
      }
    );
  };

  const fetchReservationStatistics = async () => {
    setReservationLoader(true);
    call(
      () =>
        getVenueReservationStatistics({
          venueId,
        }),
      (res) => {
        const data = res.data.data;
        setTotalReservations(data.total_reservations);
        setAvgLengthStay(data.average_stay_length);
        setTotalLengthStay(data.sum_of_stay_length);
        setYearStayLength(
          data.current_year_stay_length && data.current_year_stay_length > 0
            ? data.current_year_stay_length
            : 0
        );
        setLongestStay(data.longest_stay_length || 0);
        setReservationLoader(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservation details"
        );
        setReservationLoader(false);
      }
    ).finally(() => setReservationLoader(false));
  };

  const getChartDetails = async ({
    startDate,
    endDate,
    chartFilter,
  }: {
    startDate?: string;
    endDate?: string;
    chartFilter?: string;
  }) => {
    setRevenueLoader(true);
    call(
      () =>
        getVenueChartDetails({
          venueId,
          start_date: startDate,
          end_date: endDate,
        }),
      (res) => {
        const payouts = res?.data?.data?.payouts || [];
        const selectedTimeFilter = chartFilter;

        // Prepare an object to sum revenue for each period (month/year)
        let revenueData: { [key: string]: number } = {};

        switch (selectedTimeFilter) {
          case "Past 3 months":
            // create array of last three month from current month
            const last3Months = Array.from({ length: 3 }, (_, index) =>
              dayjs().subtract(index, "month").format("MMM")
            ).reverse();

            // Initialize revenueData for each of the last 3 months
            last3Months.forEach((monthLabel) => {
              revenueData[monthLabel] = 0;
            });

            // Sum revenue for each month in the last 3 months
            payouts.forEach((payout: any) => {
              const payoutDate = dayjs(payout.date);
              const monthYear = payoutDate.format("MMM");

              if (revenueData[monthYear] !== undefined) {
                revenueData[monthYear] += payout.amount;
              }
            });

            break;
          case "Past 6 months":
            const last6Months = Array.from({ length: 6 }, (_, index) =>
              dayjs().subtract(index, "month").format("MMM")
            ).reverse();

            // Initialize revenueData for each of the last 6 months
            last6Months.forEach((monthLabel) => {
              revenueData[monthLabel] = 0;
            });

            // Sum revenue for each month in the last 6 months
            payouts.forEach((payout: any) => {
              const payoutDate = dayjs(payout.date);
              const monthYear = payoutDate.format("MMM");

              if (revenueData[monthYear] !== undefined) {
                revenueData[monthYear] += payout.amount;
              }
            });
            break;
          case "Past year":
            const last12Months = Array.from({ length: 12 }, (_, index) =>
              dayjs().subtract(index, "month").format("MMM YYYY")
            ).reverse();

            // Initialize revenueData for each of the last 12 months
            last12Months.forEach((monthLabel) => {
              revenueData[monthLabel] = 0;
            });

            // Sum revenue for each month in the last 12 months
            payouts.forEach((payout: any) => {
              const payoutDate = dayjs(payout.date);
              const monthYear = payoutDate.format("MMM YYYY");

              if (revenueData[monthYear] !== undefined) {
                revenueData[monthYear] += payout.amount;
              }
            });
            break;
          case "All years":
            payouts.forEach((payout: any) => {
              const payoutDate = dayjs(payout.date);
              const year = payoutDate.format("YYYY");
              if (!revenueData[year]) {
                revenueData[year] = 0;
              }

              revenueData[year] += payout.amount;
            });
            break;
          default:
            break;
        }

        // Labels and data points for the chart
        const labels = Object.keys(revenueData); // Sort the labels (months or years)
        const dataPoints = labels.map((label) => revenueData[label]);

        // Set total revenue and percentage change
        setTotalRevenue(res?.data?.data?.total_revenue);
        setRevenuePercentageChange(res?.data?.data?.percentage_change);

        // Set the data for the chart
        setChartData({
          dataPoints: dataPoints,
          labels: labels,
        });

        setRevenueLoader(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch revenue details"
        );
        setRevenueLoader(false);
      }
    );
  };

  const onTabChange = (key: string) => {
    const filters: Record<string, any> = {
      "2": { approval_status: "Pending" },
      "3": { approval_status: "Approved" },
      "4": { is_completed: "true" },
      "5": { is_cancelled: "true" },
    };

    const { is_cancelled, is_completed, approval_status, ...baseQueryParams } =
      queryParam;
    let updatedQueryParams = baseQueryParams;

    switch (key) {
      case "1":
        // No additional filters needed for key "1", use baseQueryParams
        break;
      case "4":
        updatedQueryParams = { ...baseQueryParams, ...filters[key] };
        break;
      case "5":
        updatedQueryParams = { ...baseQueryParams, ...filters[key] };
        break;
      default:
        updatedQueryParams = {
          ...baseQueryParams,
          ...filters[key],
        };
        break;
    }

    fetchReservationRequests(updatedQueryParams);
    setQueryParam(updatedQueryParams);
  };
  useEffect(() => {
    fetchReservationRequests({});
    fetchHomeStatistics();
  }, []);

  useEffect(() => {
    if (availableDates === null) {
      return;
    }

    fetchReservationStatistics();
  }, [availableDates]);

  useEffect(() => {
    switch (selectedChatFilter) {
      case "Past 3 months":
        getChartDetails({
          startDate: dayjs().subtract(3, "month").format("YYYY-MM-DD"),
          endDate: dayjs().format("YYYY-MM-DD"),
          chartFilter: "Past 3 months",
        });
        break;
      case "Past 6 months":
        getChartDetails({
          startDate: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
          endDate: dayjs().format("YYYY-MM-DD"),
          chartFilter: "Past 6 months",
        });
        break;
      case "Past year":
        getChartDetails({
          startDate: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
          endDate: dayjs().format("YYYY-MM-DD"),
          chartFilter: "Past year",
        });
        break;
      case "All years":
        getChartDetails({
          chartFilter: "All years",
        });
        break;
      default:
        break;
    }
  }, [selectedChatFilter]);

  useEffect(() => {
    !showModal && setShowAllRequests(false);
  }, [showModal]);

  return (
    <div className="edit-venue-overview user-home-container d-flex flex-column gap-3">
      <div className="user-home-content d-flex mb-2 justify-content-between">
        <div className="user-home-statistics d-flex flex-column gap-3 w-100">
          <div
            className="user-home-statistic-card-container d-flex flex-wrap"
            style={{ gap: "14px" }}
          >
            <StatisticCard
              title="Total Reservations"
              totalCount={`${totalReservations}`}
              description={`${pendingReservationCount} year-to-date`}
              loading={reservationLoader}
            />
            <StatisticCard
              title="Total Revenue"
              totalCount={`$ ${numberSeparator(totalRevenue, true)}`}
              changeDescription="YoY"
              description={` year-to-date`}
              percentageChange={
                <span className="d-flex align-items-center">
                  {revenuePercentageChange >= 0 ? (
                    <MoveUp color="#14CB74" size={12} />
                  ) : (
                    <MoveDown color="#F63030" size={12} />
                  )}
                  <b>{Math.abs(revenuePercentageChange).toFixed(1)}%</b>
                </span>
              }
              color="#14CB74"
              loading={reservationLoader}
            />
            <StatisticCard
              title="Occupancy Rate"
              totalCount={`${
                isNaN(totalLengthStay / (availableDates || 0)) ||
                totalLengthStay / (availableDates || 0) < 0
                  ? 0
                  : ((totalLengthStay / (availableDates || 0)) * 100).toFixed(2)
              }%`}
              loading={reservationLoader}
              description={`${(
                (yearStayLength / currentYearDates) *
                100
              ).toFixed(2)}% year to-date`}
            />

            <StatisticCard
              title="Average Length of Stay"
              totalCount={`${avgLengthStay}`}
              loading={reservationLoader}
              description={`Longest stay is ${longestStay} days`}
            />
          </div>
          <div className="user-home-graph-tables ">
            <div className="chart-container">
              <div className="w-100 d-flex justify-content-between">
                <p className="common-md-medium-dark-text m-0">Total Revenue</p>
                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: ["1"],
                  }}
                  trigger={["click"]}
                  className="chart-filter text-dark"
                  prefixCls="chart-filter-dropdown"
                >
                  <Typography.Link>
                    <span>{selectedChatFilter}</span>
                    <DownOutlined />
                  </Typography.Link>
                </Dropdown>
              </div>
              <div>
                <TotalRevenueChart
                  dataPoints={chartData?.dataPoints as number[]}
                  labels={chartData?.labels as string[]}
                  loading={revenueLoader}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="user-home-reservation-sidebar">
            <div>
              <p className="common-md-medium-dark-text m-0">
                Reservation Requests
              </p>
              {/* TODO: Left for future implementation */}
              {/* <span className="common-xs-light-text">1 new request</span> */}
            </div>
            <div className="">
              {requestToShow && (
                <div className="request-details">
                  {reservationRequestDetailsLoader ? (
                    <Spin />
                  ) : (
                    <>
                      <div className="d-flex flex-column gap-3">
                        <div className="reservation-host-name d-flex align-items-center gap-1">
                          <img
                            src={images.PERSON_AVATAR}
                            alt="avatar"
                            height={"40px"}
                          />
                          <div className="name">
                            <p className="common-sm-dark-text tex-overflow-handle">
                              {requestToShow?.host?.name}
                            </p>
                            <span className="common-xs-light-text">
                              From{" "}
                              {dayjs(requestToShow?.check_in_date).format(
                                "MMM DD, YYYY"
                              )}{" "}
                              to{" "}
                              {dayjs(requestToShow?.check_out_date).format(
                                "MMM DD, YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <div>
                            <div className="common-sm-medium-light-text mb-1">
                              Group size:
                            </div>
                            <div className="common-sm-dark-text">
                              {requestToShow?.group_size}
                            </div>
                          </div>
                          {requestToShow?.add_on_services && (
                            <div>
                              <div className="common-sm-medium-light-text mb-1">
                                Add on services:
                              </div>
                              <div className="common-sm-dark-text">
                                <div className="common-sm-dark-text">
                                  {getAddOnServiceNames(
                                    requestToShow?.add_on_services
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="common-lg-medium-dark-text">
                          ${" "}
                          {requestToShow?.total_price &&
                            numberSeparator(requestToShow?.total_price, true)}
                        </div>
                        <div className="d-flex gap-2">
                          <Button
                            label="See Details"
                            className="bordered-button"
                            style={{ width: "166px" }}
                            onClick={() => openModal("reservation-details")}
                          />
                          <Button
                            label="Approve"
                            style={{ width: "166px" }}
                            onClick={() => openModal("reservation-details")}
                            disabled={requestToShow?.status !== "Pending"}
                          />
                          <ModalComponent modalName="reservation-details">
                            <UpcomingReservationDetailModal
                              reservationDetails={requestToShow}
                            />
                          </ModalComponent>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div>
              <TabsContainer
                reservationRequestListTabs={reservationRequestListTabs}
                onTabChange={onTabChange}
                modalEvents={showAllRequests ? false : true}
              />
              <div className="view-all-button">
                <button
                  type="button"
                  className="bg-transparent border-0 p-0"
                  onClick={() => {
                    setShowAllRequests(true);
                    dispatch(
                      setShowModal({
                        modalName: "reservation-request-list-modal",
                        visible: true,
                      })
                    );
                  }}
                >
                  <SmallText text="View all" color="#5D3FD3" />
                </button>
                <ModalComponent
                  modalName="reservation-request-list-modal"
                  className="reservation-request-list-modal"
                >
                  <div className="reservation-request-list-modal-container">
                    <TabsContainer
                      onTabChange={onTabChange}
                      reservationRequestListTabs={reservationRequestListTabs}
                      modalEvents={showAllRequests ? true : false}
                    />
                  </div>
                </ModalComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
