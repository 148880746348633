import React, { useState } from "react";

// third party
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Custom component
import MainLayout from "../../../MainLayout";
import PageTitle from "../PageTitle";
import CreateVenueFooter from "../CreateVenueFooter";
import ModalComponent from "../../../../UI/ModalComponent";
import Button from "../../../../UI/Button";
import VenuePage from "../../VenuePage";

// redux
import { setShowModal } from "../../../../../redux/features/component-slice";
import { RootState } from "../../../../../redux/store";

// network
import { publishVenue } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// styles
import "../../../../../assets/css/create-venue.css";

// images
import { images } from "../../../../../assets/images";

const CreateVenueFormStep7: React.FC<{
  venue_id: string;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}> = ({ venue_id, handlePreviousStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const venueDataSelector = useSelector(
    (state: RootState) => state.fetchedVenue
  );
  const { call } = useApiCall();
  const [loading, setLoading] = useState(false);

  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };

  const handlePublishVenue = () => {
    setLoading(true);
    call(
      () => publishVenue(venue_id),
      (res) => {
        openModal("create-venue");
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to publish venue");
      }
    ).finally(() => setLoading(false));
  };

  return (
    <div className="add-spaces preview-step-container">
      <MainLayout>
        <PageTitle
          title="Preview"
          subtitle="Review and Confirm: your listing"
        />
        <div className="preview-venue-page">
          <VenuePage
            venueId={venue_id}
            showHeader={false}
            fetchedVenueData={venueDataSelector}
          />
        </div>
        <CreateVenueFooter
          continueOnclick={handlePublishVenue}
          handleBackButton={handlePreviousStep}
          saveVenueDetails={() => {
            navigate("/venues/my-venues");
          }}
          continueButtonLabel={loading ? "Publishing..." : "Publish"}
          disabled={loading}
        />
        <ModalComponent
          modalName="create-venue"
          title=""
          className="modal-space-info"
        >
          <div className="delete-modal d-flex flex-column">
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
              <img src={images.SUCCESS_ICON} alt="Delete Icon" />
              <h2>Venue created successfully</h2>
              <div className="text-center d-flex flex-column gap-3">
                <span className="common-sm-light-grey-text">
                  Please send all necessary documents, including:
                </span>

                <div
                  className="common-sm-bold-dark-text"
                  style={{ color: "#2771A3", fontWeight: "500" }}
                >
                  <ul style={{ textAlign: "left" }} className="m-0">
                    <li>Certificate of Insurance Authorization </li>
                    <li>Proof of Right To Lease Property</li>
                  </ul>
                </div>

                <span className="common-sm-light-grey-text">
                  Your listing will be approved and published <br />
                  after the LAIGO team reviews all documents.
                </span>
              </div>
            </div>
            <div className="modal-form-footer d-flex gap-3 w-100 align-items-center justify-content-end">
              <Button
                type="button"
                label="Send it later"
                className="bordered-button"
                onClick={() => {
                  navigate("/venues/my-venues");
                  dispatch(
                    setShowModal({
                      modalName: "create-venue",
                      visible: false,
                    })
                  );
                }}
              />

              <Button
                type="button"
                label={"Go to upload documents"}
                onClick={() => {
                  navigate("/venues/profile#legal-documents");
                }}
              />
            </div>
          </div>
        </ModalComponent>
      </MainLayout>
    </div>
  );
};

export default CreateVenueFormStep7;
