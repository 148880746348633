/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

//third party
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//component
import CancelSteps from "./CancelSteps";

//redux
import { RootState } from "../../../../redux/store";

//images
import { images } from "../../../../assets/images";

//css
import "../../../../assets/css/cancelVenue.css";

const CancelVenue: React.FC = () => {
  const navigate = useNavigate();

  const reservationData = useSelector(
    (state: RootState) => state.reservation.data
  );

  useEffect(() => {
    if (!reservationData.id) {
      navigate("/venues/home");
    }
  }, [reservationData]);

  return (
    <div className="overflow-hidden vh-100 cancel-venue">
      <header className="bg-white">
        <Link to="/">
          <img src={images.LAIGO_LOGO} alt="logo" height={48} />
        </Link>
      </header>
      <div className="custom-steps">
        <CancelSteps />
      </div>
    </div>
  );
};

export default CancelVenue;
