/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// third party
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

// custom components
import MainLayout from "../../../MainLayout";
import SpacesForm from "./SpacesForm";

// redux
import { RootState } from "../../../../../redux/store";
import { setLoading } from "../../../../../redux/features/common-slice";

// network
import { fetchRoomDetails } from "../../../../../network";

// hooks
import { useApiCall } from "../../../../../hooks/useApiCall";

// types
import { FetchedSpacesType } from "../../../../../types/property-manager";

// styles
import "../../../../../assets/css/create-venue.css";

const CreateVenueFormStep2: React.FC<{
  venue_id: string;
  handleNextStep: (query?: string) => void;
  handlePreviousStep: () => void;
}> = ({ venue_id, handleNextStep, handlePreviousStep }) => {
  const [roomDetails, setRoomDetails] = React.useState<FetchedSpacesType>({
    rooms: [],
    common_spaces: [],
  });

  const dispatch = useDispatch();
  const { call } = useApiCall();
  const loading = useSelector(
    (state: RootState) => state.commonReducer.loading
  );

  const fetchRoomDetail = async () => {
    dispatch(setLoading(true));
    call(
      () => fetchRoomDetails(venue_id),
      (res) => {
        setRoomDetails(res.data.data);

        dispatch(setLoading(false));
      },
      (err) => {
        dispatch(setLoading(false));
      }
    );
  };
  useEffect(() => {
    fetchRoomDetail();
  }, []);

  return loading ? (
    <Spin fullscreen />
  ) : (
    <div className="add-spaces">
      <MainLayout>
        <SpacesForm
          roomDetails={roomDetails}
          venueId={venue_id}
          fetchRoomDetails={fetchRoomDetail}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </MainLayout>
    </div>
  );
};

export default CreateVenueFormStep2;
