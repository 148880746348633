import React from "react";

//third party
import { Outlet } from "react-router-dom";

//
import Sidebar from "../../../../UI/Sidebar";
import { retreat_dashboard } from "../../../../../constants/RetreatHost";

const HostLayout = () => {
  return (
    <div className="d-flex vh-100 overflow-hidden">
      <Sidebar items={retreat_dashboard} selected={1} />
      <div className="host-dashboard ">
        <Outlet />
      </div>
    </div>
  );
};

export default HostLayout;
