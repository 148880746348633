import React, { useState } from "react";
//third party
import dayjs from "dayjs";
import { DatePicker, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

//common component
import FormBtn from "../../../UI/InputElement/FormButton";

//redux store
import { RootState } from "../../../../redux/store";

//common component
import SmallText from "../../../UI/SmallText";

//images
import { images } from "../../../../assets/images";
import { useApiCall } from "../../../../hooks/useApiCall";
import { ToastFail, ToastSuccess } from "../../../../utils/TostMessage";
import { createRetreatAPI } from "../../../../network";
import { CreateRetreat } from "../../../../types/retreat";
import { setRetreat } from "../../../../redux/features/createRetreat-slice";

interface RetreatFormValues {
  check_in: string;
  check_out: string;
  group_size: number;
}

interface RetreatPriceProps {
  id: string;
  handleNextStep: () => void;
}
const RetreatPrice: React.FC<RetreatPriceProps> = ({ id, handleNextStep }) => {
  const [form] = Form.useForm();
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { retreatPropertyDetail } = useSelector(
    (state: RootState) => state.retreatVenue
  );
 
  const dateDifference =
    startDate && endDate ? dayjs(endDate).diff(dayjs(startDate), "day") : 1;
  const totalPrice = retreatPropertyDetail?.base_price! * dateDifference;

  const submitHandler = async (values: RetreatFormValues) => {
  
    const isValidForm = await form.validateFields();
    if (isValidForm) {
      const retreatValue = {
        host_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        venue_id: id,
        check_in_date: startDate,
        check_out_date: endDate,
        group_size : values.group_size,
        total_price: totalPrice,
        partial: false,
        form_step: "venue",
      };
      handleCreateRetreat(retreatValue);
      setIsLoading(false);
      handleNextStep();
    }
  };

  const handleCreateRetreat = (values: CreateRetreat) => {
    call(
      () => createRetreatAPI(values),
      (res) => {
        console.log(res)
        ToastSuccess(res.data.message);
        dispatch(setRetreat({
          id : res.data.data.id,
          venue_id: res.data.data.venue_id,
          check_in_date: res.data.data.check_in_date,
          check_out_date: res.data.data.check_out_date,
          group_size: res.data.data.group_size,
          total_price: res.data.data.total_price,
        }
        ))
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  return (
    <div
      className="venue-details-booking-container retreat-price-container"
      style={{ maxWidth: "fit-content" }}
    >
      <div className="book-venue d-flex flex-column">
        <div>
          <div className="book-venue-price-container">
            <span className="book-venue-price">
              ${retreatPropertyDetail?.base_price}
            </span>
            <span className="price-label">per night</span>
          </div>
          <div className="book-venue-subtext">
            <span>(including tax and fees)</span>
          </div>
        </div>
        <div>
          <Form
            name="book-venue"
            form={form}
            onFinish={submitHandler}
            layout="vertical"
          >
            <div className="book-venue-form">
              <div className="data-input-container d-flex">
                <Form.Item
                  label="Check in"
                  name="check_in"
                  rules={[
                    {
                      required: true,
                      message: "Please select a check-in date",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || !endDate || value.isBefore(endDate)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Check-in date must be before check-out date"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    placeholder="Check in"
                    format={"YYYY-MM-DD"}
                    suffixIcon={
                      <img src={images.CALENDAR_ICON} alt="calendar icon" />
                    }
                    onChange={(e) =>
                      setStartDate(e ? e.format("YYYY-MM-DD") : "")
                    }
                    minDate={dayjs(new Date())}
                  />
                </Form.Item>
                <Form.Item
                  label="Check Out"
                  name="check_out"
                  rules={[
                    {
                      required: true,
                      message: "Please select a check-out date",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || !startDate || value.isAfter(startDate)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Check-out date must be after check-in date"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    placeholder="Check Out"
                    format="YYYY-MM-DD"
                    suffixIcon={
                      <img src={images.CALENDAR_ICON} alt="calendar icon" />
                    }
                    onChange={(e) =>
                      setEndDate(e ? e.format("YYYY-MM-DD") : "")
                    }
                    minDate={dayjs(startDate)}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Group Size"
                  name="group_size"
                  initialValue={1}
                  rules={[
                    {
                      required: true,
                      message: "Please select a group size",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { value: "1", label: "1" },
                      { value: "2", label: "2" },
                      { value: "3", label: "3" },
                      { value: "4", label: "4" },
                      { value: "5", label: "5" },
                      { value: "6", label: "6" },
                      { value: "7", label: "7" },
                      { value: "8", label: "8" },
                      { value: "9", label: "9" },
                      { value: "10", label: "10" },
                    ]}
                    suffixIcon={<img src={images.DOWN_ARROW_ICON} alt="" />}
                  />
                </Form.Item>
              </div>
              <div>
                <FormBtn
                  type="submit"
                  text="Book this venue"
                  className="w-100 mb-2 custom-button"
                  isLoading={isLoading}
                />
                <div className="text-center">
                  <SmallText text="You won't be charged yet" color="#58585B" />
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="d-flex justify-content-between">
          <SmallText
            text="Total"
            color="#58585B"
            lineHeight={18.8}
            fontSize={16}
            fontWeight={400}
          />
          <SmallText
            text={`$${totalPrice}`}
            color="#19191C"
            lineHeight={18.8}
            fontSize={16}
            fontWeight={500}
          />
        </div>
      </div>
      <div className="contact-owner">
        <SmallText text="Contact owner" />
      </div>
    </div>
  );
};

export default RetreatPrice;
