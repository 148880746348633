/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

// third party
import {
  ConfigProvider,
  DatePicker,
  Dropdown,
  Form,
  FormProps,
  MenuProps,
  Popover,
  Select,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Filter } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";

// custom components
import ReservationTable from "./ReservationTable";
import SmallText from "../../../UI/SmallText";
import Button from "../../../UI/Button";
import SelectOption from "../../../UI/SelectOption";

// redux
import { setCurrentPage } from "../../../../redux/features/paginationSlice";
import { RootState } from "../../../../redux/store";
import { setReservationStatus } from "../../../../redux/features/queryParamSlice";

// network
import {
  getUpcomingReservationRequest,
  getVenuesList,
} from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  fetchReservationTypes,
  Reservation,
} from "../../../../types/property-manager";

// constants
import {
  GROUP_SIZE_SELECT_OPTION,
  RESERVATION_STATUS_SELECT_OPTION,
} from "../../../../constants/property-manager";

// images
import { images } from "../../../../assets/images";

// styles
import "../../../../assets/css/property-manager-payout.css";
import "../../../../assets/css/venue-reservation.css";

export type reservationFilterFormTypes = {
  filterDate: Dayjs;
  filterVenue: string;
  filterAmount: number[];
  filterGroupSize: string;
  filterStatus: string;
};

const ReservationTab: React.FC = () => {
  const { call } = useApiCall();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const venueRef = useRef<any>();

  const paginationSelector = useSelector(
    (state: RootState) => state.pagination
  );
  const reservationStatusSelector = useSelector(
    (state: RootState) => state.queryParam.reservationStatus
  );

  const [selectedChatFilter, setSelectedChatFilter] =
    useState<string>("All years");

  // TODO: get total reservations and status
  // const [status, setStatus] = useState<string>("venue");
  const [totalReservations, setTotalReservations] = useState(0);

  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [queryParams, setQueryParams] = useState<fetchReservationTypes>({});
  const [loading, setLoading] = useState(false);
  const [venueFilter, setVenueFilter] = useState<string>("");
  const [openPopover, setOpenPopover] = useState(false);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [venueList, setVenueList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([
    {
      value: "",
      label: "All",
    },
  ]);

  const payoutsYearFilter: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Current year")}
        >
          <SmallText text="Current year" />
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past year")}
        >
          <SmallText text="Past year" />
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("Past 2 year")}
        >
          <SmallText text="Past 2 year" />
        </button>
      ),
    },
    {
      key: "4",
      label: (
        <button
          className="dropdown-item"
          style={{ border: "none", background: "transparent", padding: 0 }}
          type="button"
          onClick={() => setSelectedChatFilter("All years")}
        >
          <SmallText text="All years" />
        </button>
      ),
    },
  ];

  const onApplyFilter: FormProps<reservationFilterFormTypes>["onFinish"] = (
    values
  ) => {
    dispatch(setCurrentPage(1));
    // Build new queryParams based on form values
    const newQueryParams: fetchReservationTypes = {
      ...queryParams,
      check_in_date: values.filterDate
        ? dayjs(values.filterDate).format("YYYY-MM-DD")
        : undefined,
      venue_id: values.filterVenue || undefined,
      group_size:
        (values.filterGroupSize !== "all" &&
          parseInt(values.filterGroupSize)) ||
        undefined,
      approval_status:
        (values.filterStatus === "Completed" ||
        values.filterStatus === "Cancelled"
          ? undefined
          : values.filterStatus !== "all" && values.filterStatus) || undefined,

      is_completed: values.filterStatus === "Completed" ? "true" : undefined,
      is_cancelled: values.filterStatus === "Cancelled" ? "true" : undefined,
    };

    Object.keys(newQueryParams).forEach(
      (key) =>
        newQueryParams[key as keyof fetchReservationTypes] === undefined &&
        delete newQueryParams[key as keyof fetchReservationTypes]
    );

    // If needed, you can update or make API calls with newQueryParams here
    setQueryParams(newQueryParams);
    setOpenPopover(false);
    fetchReservationRequests(newQueryParams);
  };

  const onResetFilter = () => {
    dispatch(setCurrentPage(1));
    setVenueFilter("");
    form.resetFields();
    const data = form.getFieldValue("filterDate");
    const venue = form.getFieldValue("filterVenue");
    const groupSize = form.getFieldValue("filterGroupSize");
    const status = form.getFieldValue("filterStatus");
    const values = {
      filterDate: data,
      filterVenue: venue,
      filterGroupSize: groupSize,
      filterStatus: status,
    };
    onApplyFilter(values as reservationFilterFormTypes);
    setActiveKey("1");
  };

  const filterContent = (
    <Form form={form} onFinish={onApplyFilter}>
      <div className="reservation-filter-box">
        <div className="d-flex justify-content-between gap-2">
          <span className="flex-grow-1 common-md-medium-dark-text">
            Filter by
          </span>
          <button
            type="button"
            className="bg-transparent border-0 p-0"
            onClick={() => setOpenPopover(false)}
          >
            <img src={images.CROSS_ICON_BLACK} alt="" height={24} />
          </button>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>Date</span>
          <Form.Item name={"filterDate"}>
            <DatePicker placeholder="Select or Write" />
          </Form.Item>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>Venue</span>
          <Form.Item name={"filterVenue"}>
            <SelectOption placeholder="Select venue" options={venueList} />
          </Form.Item>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>Group Size</span>
          <Form.Item name={"filterGroupSize"}>
            <SelectOption
              options={GROUP_SIZE_SELECT_OPTION}
              defaultValue={"all"}
            />
          </Form.Item>
        </div>
        <div className="d-flex gap-1 flex-column">
          <span>Status</span>
          <Form.Item name={"filterStatus"}>
            <SelectOption
              options={RESERVATION_STATUS_SELECT_OPTION}
              defaultValue={"all"}
            />
          </Form.Item>
        </div>
        <div className="d-flex gap-3 w-100 align-items-center justify-content-end">
          <div className="d-flex gap-2">
            <Button
              type="button"
              label="Cancel"
              className="bordered-button"
              onClick={() => {
                form.resetFields();
                setOpenPopover(false);
              }}
            />
            <Button type="submit" label={"Apply"} />
          </div>
        </div>
      </div>
    </Form>
  );

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
  };

  const fetchReservationRequests = ({
    check_in_date,
    check_out_date,
    group_size,
    approval_status,
    end_date,
    limit,
    max_amount,
    min_amount,
    offset,
    start_date,
    venue_id,
    property_name,
    is_cancelled,
    is_completed,
  }: fetchReservationTypes) => {
    setLoading(true);
    setReservations([]);
    setTotalReservations(0);
    call(
      () =>
        getUpcomingReservationRequest({
          check_in_date,
          check_out_date,
          group_size,
          limit: 10,
          offset: offset && (offset - 1) * 10,
          approval_status,
          start_date,
          end_date,
          venue_id,
          min_amount,
          max_amount,
          property_name,
          is_cancelled,
          is_completed,
        }),
      (res) => {
        setReservations(res.data.data);
        setTotalReservations(res.data.count);
        setLoading(false);
      },
      (err) => {
        toast.error(
          err?.response?.data?.message || "Failed to fetch reservations"
        );
        setLoading(false);
      }
    );
  };

  const fetchVenueLists = () => {
    call(
      () => getVenuesList(),
      (res) => {
        const data: { id: string; name: string }[] = res.data.data;
        // set option to such id does not exist in venueList label
        const options = data
          .filter((item) => !venueList.some((venue) => venue.value === item.id))
          .map((item) => ({ label: item.name, value: item.id }));

        setVenueList([...venueList, ...options]);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch venues");
      }
    );
  };

  const handleOnVenueFilter = (value: string) => {
    if (value === "all") {
      fetchReservationRequests(queryParams);
    } else {
      const queryParamsNew = {
        ...queryParams,
        venue_id: value,
        limit: 10,
        offset: 1,
      };
      fetchReservationRequests(queryParamsNew);
    }
    setVenueFilter(value);
  };

  const onTabChange = (key: string) => {
    setActiveKey(key);
    dispatch(setReservationStatus(null));
    dispatch(setCurrentPage(1));

    const filters: Record<string, any> = {
      "2": { approval_status: "Pending" },
      "3": { approval_status: "Approved" },
      "4": { is_completed: "true" },
      "5": { is_cancelled: "true" },
      "6": { approval_status: "Rejected" },
    };

    const { is_cancelled, is_completed, approval_status, ...baseQueryParams } =
      queryParams;
    let updatedQueryParams = baseQueryParams;

    switch (key) {
      case "1":
        // No additional filters needed for key "1", use baseQueryParams
        break;
      case "4":
        updatedQueryParams = { ...baseQueryParams, ...filters[key] };
        break;
      case "5":
        updatedQueryParams = { ...baseQueryParams, ...filters[key] };
        break;
      default:
        updatedQueryParams = {
          ...baseQueryParams,
          ...filters[key],
          limit: 10,
          offset: 1,
        };
        break;
    }
    fetchReservationRequests(updatedQueryParams);
    setQueryParams(updatedQueryParams);
  };
  const renderReservationTable = () => (
    <ReservationTable
      reservations={reservations}
      loading={loading}
      totalReservations={totalReservations}
    />
  );

  const venueStatusListTabs: TabsProps["items"] = [
    { key: "1", label: "All", children: renderReservationTable() },
    { key: "2", label: "Requests", children: renderReservationTable() },
    { key: "3", label: "Approved", children: renderReservationTable() },
    { key: "4", label: "Completed", children: renderReservationTable() },
    { key: "5", label: "Canceled", children: renderReservationTable() },
    { key: "6", label: "Declined", children: renderReservationTable() },
  ];

  useEffect(() => {
    dispatch(setReservationStatus(null));
    const filterDate: { start_date?: string; end_date?: string } | null =
      (() => {
        switch (selectedChatFilter) {
          case "Current year":
            return {
              start_date: dayjs().startOf("year").format("YYYY-MM-DD"),
              end_date: dayjs().endOf("year").format("YYYY-MM-DD"),
            };
          case "Past 2 year":
            return {
              start_date: dayjs().subtract(2, "year").format("YYYY-MM-DD"),
              end_date: dayjs().format("YYYY-MM-DD"),
            };
          case "Past year":
            return {
              start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
              end_date: dayjs().format("YYYY-MM-DD"),
            };
          case "All years":
            return {};
          default:
            return {};
        }
      })();

    if (Object.keys(filterDate).length === 0) {
      const { start_date, end_date, offset, ...queryParamsNew } = queryParams;
      setQueryParams({
        ...queryParamsNew,
        offset: paginationSelector.currentPage,
      });
      fetchReservationRequests({
        ...queryParamsNew,
        offset: paginationSelector.currentPage,
      });
    } else {
      setQueryParams({
        ...queryParams,
        ...filterDate,
        offset: paginationSelector.currentPage,
      });
      fetchReservationRequests({
        ...queryParams,
        ...filterDate,
        offset: paginationSelector.currentPage,
      });
    }
  }, [paginationSelector.currentPage, selectedChatFilter]);

  useEffect(() => {
    fetchVenueLists();
  }, []);

  useEffect(() => {
    if (reservationStatusSelector === "Pending") {
      onTabChange("2");
    }
  }, []);
  return (
    <div className="payouts user-home-table flex-wrap admin-users bg-white">
      <div className="d-flex gap-3 justify-content-between">
        <div className="common-xl-medium-dark-text">Reservations</div>
        <div className="d-flex gap-2 justify-content-end ">
          <button
            type="button"
            className="bg-transparent border-0 d-flex gap-1 align-items-center justify-content-center"
            onClick={onResetFilter}
          >
            <img src={images.CROSS_ICON} alt="" height={16} />
            <SmallText text="Clear filter" />
          </button>
          <Dropdown
            menu={{
              items: payoutsYearFilter,
              selectable: true,
              defaultSelectedKeys: ["1"],
            }}
            trigger={["click"]}
            className="chart-filter text-dark"
          >
            <Typography.Link>
              <span>{selectedChatFilter}</span>
              <DownOutlined />
            </Typography.Link>
          </Dropdown>

          <Select
            placeholder="Venues"
            options={venueList}
            className="chart-filter text-dark"
            onChange={handleOnVenueFilter}
            defaultValue="all"
            ref={venueRef}
            value={venueFilter}
          />

          <Popover
            content={filterContent}
            trigger={"click"}
            placement="bottomRight"
            rootClassName="reservation-filter-popover"
            open={openPopover}
            onOpenChange={handleOpenChange}
          >
            <button className="d-flex  gap-1 back-btn">
              <Filter color="#2771A3" width={16} height={16} />
              <SmallText
                text="Filters"
                color="#2771A3"
                fontWeight={500}
                lineHeight={16.45}
                fontSize={14}
              />
            </button>
          </Popover>
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "var(--deep-sea) !important",
              inkBarColor: "var(--deep-sea) !important",
              itemHoverColor: "var(--deep-sea) !important",
            },
          },
        }}
      >
        <Tabs
          items={venueStatusListTabs}
          defaultActiveKey={reservationStatusSelector === "Pending" ? "2" : "1"}
          activeKey={activeKey}
          onChange={onTabChange}
        />
      </ConfigProvider>
    </div>
  );
};

export default ReservationTab;
