import React from 'react'

const HostMetrics = () => {
  return (
    <div>
      Metrics section.
    </div>
  )
}

export default HostMetrics
