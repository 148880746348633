import React from "react";
//common component
import SmallText from "../../../UI/SmallText";

const Policy = () => {
  return (
    <div
      className="d-flex bg-white flex-column p-5 step-box-shadow"
      style={{ gap: "13px" }}
    >
      <SmallText
        text="Cancellation Policy:"
        color="#19191C"
        lineHeight={18.8}
        fontWeight={700}
        fontSize={16}
      />
      <SmallText
        text="See our cancellation policy for more details on applicable fees."
        color="#58585B"
        lineHeight={23}
        fontWeight={400}
        fontSize={16}
      />
      <SmallText
        text="Learn More"
        color="#2771A3"
        lineHeight={23}
        fontWeight={400}
        fontSize={16}
      />
    </div>
  );
};

export default Policy;
