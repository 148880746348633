import React, { useRef, useEffect, useState } from "react";

// third party
import { Line } from "react-chartjs-2";
import { Spin } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
  ChartData,
  Chart,
  TooltipModel,
  Scale,
  CoreScaleOptions,
  Tick,
} from "chart.js";

// types
import { LineChartProps } from "../../../../types/property-manager";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface TooltipContext {
  chart: Chart;
  tooltip: TooltipModel<"line">;
}

const TotalRevenueChart: React.FC<LineChartProps> = ({
  dataPoints,
  labels,
  loading = false,
}) => {
  const chartRef = useRef<Chart<"line"> | null>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const canvas = chartRef.current?.canvas;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const gradient = ctx.createLinearGradient(0, 0, 0, 256);
        gradient.addColorStop(0, "rgba(99, 102, 241, 0.8)");
        gradient.addColorStop(1, "rgba(127, 99, 241, 0)");

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Revenue",
              data: dataPoints,
              fill: true,
              borderColor: "#6366F1",
              backgroundColor: gradient,
              pointBackgroundColor: "#6366F1",
              pointBorderColor: "#6366F1",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#6366F1",
              pointRadius: 0,
              pointHoverRadius: 6,
              tension: 0.5,
            },
          ],
        });
      }
    }
  }, [dataPoints, labels]);

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context: TooltipContext) => {
          const { chart, tooltip } = context;

          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create tooltip element if it doesn't exist
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set tooltip content
          if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(
              (b: { lines: string[] }) => b.lines
            );
            const value = bodyLines[0];

            const tooltipContent = `
              <div class="tooltip-content">
                <div class="text-white text-sm font-medium">${titleLines[0]} </div>
                <ul>
                    <li>
                        <div class="text-white text-lg font-semibold">${value}</div>
                    </li>
                </ul>
              </div>
            `;

            tooltipEl.innerHTML = tooltipContent;

            // Position tooltip and make it visible
            const position = chart.canvas.getBoundingClientRect();
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left +
              window.pageXOffset +
              tooltip.caretX -
              tooltipEl.offsetWidth / 2 +
              "px";
            tooltipEl.style.top = position.top + window.pageYOffset + 9 + "px";
            tooltipEl.style.pointerEvents = "none";
          }
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
        border: {
          display: false,
        },
        ticks: {
          callback: function (
            this: Scale<CoreScaleOptions>,
            tickValue: number | string,
            index: number,
            ticks: Tick[]
          ): string {
            const value = Number(tickValue);
            return `$${new Intl.NumberFormat("en-US").format(value)}`;
          },
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index" as const,
    },
    hover: {
      intersect: false,
      mode: "index" as const,
    },
  };

  return (
    <div className="relative h-64">
      <style>
        {`
          #chartjs-tooltip {
            background-color: #4F46E5;
            border-radius: 0.5rem;
            color: black;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            transition: all .1s ease;
            z-index: 50;
            height: 58px;
            width: 148px;
            padding: 8px 12px;
          }
        `}
      </style>
      <Spin spinning={loading}>
        {
          <Line
            ref={chartRef}
            data={chartData}
            options={options}
            className="home-line-chart"
          />
        }
      </Spin>
    </div>
  );
};

export default TotalRevenueChart;
