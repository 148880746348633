import React, { useEffect, useState } from "react";

//third party
import { Tabs, TabsProps } from "antd";
import { useDispatch, useSelector } from "react-redux";

//redux store
import { setQueryParams } from "../../../../../redux/features/venue-filter";
import { RootState } from "../../../../../redux/store";

//custom hook
import { useApiCall } from "../../../../../hooks/useApiCall";

//api call
import { getAllRetreat } from "../../../../../network";

//utils
import { ToastFail } from "../../../../../utils/TostMessage";

//constant
import { retreatTab } from "../../../../../constants/RetreatHost";

const RetreatTabs = () => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const [activeKey, setActiveKey] = useState<string>("all");

  const queryParams = useSelector(
    (state: RootState) => state.filteredVenue.queryParams
  );

  //handle tab change and query params
  const handleTabChange = (id: string) => {
    setActiveKey(id);
    if (id === "true" || id === "false") {
      dispatch(
        setQueryParams({
          draft: id === "true" ? true : false,
          status: undefined,
        })
      );
    } else if (id === "Pending" || id === "Approved" || id === "Rejected") {
      dispatch(setQueryParams({ status: id, draft: undefined }));
    } else {
      dispatch(setQueryParams({ status: undefined, draft: undefined }));
    }
  };

  //get retreat based on query params
  const handleParams = () => {
    call(
      () => getAllRetreat("3fa85f64-5717-4562-b3fc-2c963f66afa6", queryParams),
      (res) => {},
      (err) => {
        ToastFail(err.response.data.message);
      }
    );
  };

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      handleParams();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const items: TabsProps["items"] = retreatTab.map((tab) => ({
    key: tab.id,
    label: (
      <div className="d-flex align-items-center gap-2">
        <div>{tab.name}</div>
      </div>
    ),
  }));
  
  return (
    <div>
      <Tabs items={items} activeKey={activeKey} onChange={handleTabChange} />
    </div>
  );
};

export default RetreatTabs;
