import React from 'react'
import CreateRetreat from '../../../components/core/RetreatHost/CreateRetreat'

const CreateRetreatPage : React.FC = () => {
  return (
    <CreateRetreat/>
  )
}

export default CreateRetreatPage
