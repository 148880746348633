/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// APIs
import { useApiCall } from "../../../../../hooks/useApiCall";
import { fetchDocumentsByRefId } from "../../../../../network";

// Components
import Loader from "../../../../UI/Loader";
import UserDocumentDisplay from "./UserDocumentDisplay";

const LegalDocumentsTabContent = ({ userData }: { userData: any }) => {
  const { call } = useApiCall();

  const [documentList, setDocumentList] = useState<any>([]);
  const [documentLoading, setDocumentLoading] = useState(true);

  useEffect(() => {
    if (userData.user_id) {
      fetchUserDocuments(userData.user_id);
    }
  }, []);

  const fetchUserDocuments = async (userId: string) => {
    call(
      () => fetchDocumentsByRefId(userId),
      (res) => {
        setDocumentList(res.data.data);
        setDocumentLoading(false);
      },
      (err) => {
        setDocumentLoading(false);
      }
    );
  };
  return (
    <>
      {documentLoading ? (
        <Loader />
      ) : (
        <>
          <UserDocumentDisplay documentList={documentList} />
        </>
      )}
    </>
  );
};

export default LegalDocumentsTabContent;
