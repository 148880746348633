/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Components
import AdminDashboardLayout from "../AdminDashboardLayout";

// Styles
import "../../../../assets/css/admin-users.css";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

// Utility
import { convertToTitleCase } from "../../../../library";

const RetreatDetail: React.FC = () => {
  const userProfileData = useSelector(
    (state: RootState) => state.user.userProfile
  );
  return (
    <>
      <AdminDashboardLayout
        pageTitle={`Welcome, ${convertToTitleCase(
          userProfileData.first_name
        )} 👋`}
      >
        <div>
          <h1>Retreat Detail Page</h1>
        </div>
      </AdminDashboardLayout>
    </>
  );
};

export default RetreatDetail;
