import React, { useEffect, useState } from "react";
//third party
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

//component
import PageTitle from "../../PropertyManager/PreviewPage/PageTitle";
import VenueSpaces from "../../PropertyManager/VenuePage/VenueSpaces";
import SectionDivide from "../../../UI/Divider/SectionDivide";
import VenueLocation from "../../PropertyManager/VenuePage/VenueLocation";
import AboutOwner from "../../PropertyManager/VenuePage/AboutOwner";
import InfoNeedToKnow from "../../PropertyManager/VenuePage/InfoNeedToKnow";
import VenueImages from "../VenueDetail/VenueImages";
import HighlightedDetail from "../VenueDetail/HighlightedDetail";
import AboutTheRetreatVenue from "../VenueDetail/AboutTheRetreatVenue";
import RetreatPrice from "./RetreatPrice";

//common component
import Loader from "../../../UI/Loader";
import SmallText from "../../../UI/SmallText";

//redux store
import { setShowModal } from "../../../../redux/features/component-slice";
import {
  setPropertyId,
  setRetreatPropertyDetail,
} from "../../../../redux/features/retreatVenue";
import { RootState } from "../../../../redux/store";

//api call
import {
  fetchRoomDetails,
  fetchVenue,
  handleRetreatStep,
} from "../../../../network";

//hooks
import { useApiCall } from "../../../../hooks/useApiCall";

//type
import { FetchedSpacesType } from "../../../../types/property-manager";

//utils
import { ToastFail } from "../../../../utils/TostMessage";

const VenuePreview = ({
  handleNextStep,
  setCurrentStep,
}: {
  handleNextStep: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const dispatch = useDispatch();
  const { call } = useApiCall();
  const [searchParams, setSearchParams] = useSearchParams();
  const [spaces, setSpaces] = useState<FetchedSpacesType>({
    rooms: [],
    common_spaces: [],
  });
  const id = searchParams.get("id");
  const openModal = (modalName: string) => {
    dispatch(setShowModal({ modalName, visible: true }));
  };
  const { retreatPropertyDetail } = useSelector(
    (state: RootState) => state.retreatVenue
  );
  const handleRetreat = async () => {
    call(
      () => handleRetreatStep("8c0179f6-f6de-49dc-a629-97224587cdef"),
      (res) => {
        setSearchParams("");
        setCurrentStep(res.data.data.form_step);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  const getVenueDetail = async () => {
    dispatch(setPropertyId(id!));
    call(
      () => fetchVenue(id!),
      (res) => {
        dispatch(setRetreatPropertyDetail(res.data.data));
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  const venueSpaces = () => {
    call(
      () => fetchRoomDetails(id!),
      (res) => {
        setSpaces(res.data.data);
      },
      (err) => {
        ToastFail(err.message);
      }
    );
  };

  useEffect(() => {
    getVenueDetail();
    setCurrentStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleRetreat();
    venueSpaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {retreatPropertyDetail ? (
        <div className="container" style={{ marginTop: "40px" }}>
          <PageTitle
            venueName={retreatPropertyDetail?.venue_title}
            venueCountry={retreatPropertyDetail?.country}
            venueType={retreatPropertyDetail?.venue_type}
            venueCity={retreatPropertyDetail?.city}
          />
          <VenueImages />
          <div className="d-flex flex-lg-row flex-md-column gap-4">
            <div className="flex-grow-1">
              <div className="d-flex gap-4 flex-column">
                <SmallText
                  text="Venue details"
                  color="#949397"
                  fontSize={18}
                  fontWeight={500}
                  lineHeight={21.15}
                />
                <HighlightedDetail />
                <AboutTheRetreatVenue />
              </div>
            </div>
            <div>
              <RetreatPrice id={id!} handleNextStep={handleNextStep} />
            </div>
          </div>
          <SectionDivide />
          <VenueSpaces spacesData={spaces} />
          <SectionDivide />

          {/* <VenueAmenities openModal={openModal} venueAmenities={ " " as any} /> */}
          <SectionDivide />
          {/* working on it */}
          {/* <AddOnServices
          openModal={openModal}
          addOnServicesData={" " as any}
        /> */}
          <SectionDivide />
          <VenueLocation />
          <SectionDivide />
          <AboutOwner />
          <SectionDivide />
          <InfoNeedToKnow openModal={openModal} houseRule={" " as any} />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default VenuePreview;
