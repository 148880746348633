import React from 'react'

const HostHome = () => {
  return (
    <div>
      This is home page.
    </div>
  )
}

export default HostHome
