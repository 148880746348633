/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// third party
import { Link } from "react-router-dom";
import {
  ConfigProvider,
  Pagination,
  PaginationProps,
  Tabs,
  TabsProps,
} from "antd";
import { toast } from "react-toastify";

// custom component
import Loader from "../../../UI/Loader";
import AllVenues from "./AllVenues";

// network
import { getOwnerVenues } from "../../../../network";

// hooks
import { useApiCall } from "../../../../hooks/useApiCall";

// types
import {
  fetchAllVenuesType,
  FetchedVenue,
} from "../../../../types/property-manager";

// styles
import "../../../../assets/css/my-venues.css";

// images
import { images } from "../../../../assets/images";

const VenueList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [venues, setVenues] = useState<FetchedVenue[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalVenues, setTotalVenues] = useState(0);
  const [queryParams, setQueryParams] = useState<fetchAllVenuesType>();

  const { call } = useApiCall();

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <button
          type="button"
          className="bordered-button"
          style={{
            height: "36px",
            border: "1px solid #E9F1F6",
            display: "flex",
            alignItems: "center",
            padding: "8px 14px",
            gap: "8px",
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
        >
          {" "}
          <img src={images.PAGINATION_ARROW_LEFT} alt="" height={"20px"} />{" "}
          Previous
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          type="button"
          className="bordered-button"
          style={{
            height: "36px",
            border: "1px solid #E9F1F6",
            padding: "8px 14px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
        >
          Next{" "}
          <img src={images.PAGINATION_ARROW_RIGHT} alt="" height={"20px"} />
        </button>
      );
    }
    return originalElement;
  };

  const fetchAllVenues = async ({
    limit = 10,
    offset = 1,
    is_draft,
    approval_status,
    is_deleted,
    is_active,
  }: fetchAllVenuesType) => {
    setLoading(true);

    call(
      () =>
        getOwnerVenues({
          limit: limit,
          offset: (offset - 1) * 10,
          is_draft,
          approval_status,
          is_deleted,
          is_active,
        }),
      (res) => {
        const fetchedVenues = res.data?.data || [];
        setVenues(fetchedVenues);

        setTotalVenues(res.data?.count || 0); // This must be the correct value from the response

        setLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to fetch venues");
        setLoading(false);
        setTotalVenues(0);
        setVenues([]);
      }
    );
  };

  const onPageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
  };

  const onTabChange = (key: string) => {
    setTotalVenues(0);
    setCurrentPage(1);

    const filters: Record<string, any> = {
      "2": { approval_status: "Pending", is_draft: "false" },
      "3": { approval_status: "Approved", is_active: "true" },
      "4": { is_active: "false" },
      "5": { is_draft: "true" },
      "6": { is_deleted: "true" },
      "7": { approval_status: "Rejected", is_draft: "false" },
    };

    const queryParamsNew = { limit: 10, page: 1, ...filters[key] };
    fetchAllVenues(queryParamsNew);
    setQueryParams(queryParamsNew);
  };

  useEffect(() => {
    fetchAllVenues({ limit: 10, offset: currentPage, ...queryParams }); // fetch venues based on current page and page size
  }, [currentPage]);

  const tabConfig = [
    {
      key: "1",
      label: "All",
      allVenues: true,
      showNoVenueImage: true,
      fetchVenues: () => fetchAllVenues({ limit: 10, offset: 1 }),
    },
    { key: "2", label: "Pending Approval", showNoVenueImage: false },
    { key: "3", label: "Published", showNoVenueImage: false },
    { key: "4", label: "Deactivated", showNoVenueImage: false },
    { key: "5", label: "Draft", showNoVenueImage: false },
    { key: "6", label: "Deleted", showNoVenueImage: false },
    { key: "7", label: "Declined", showNoVenueImage: false },
  ];

  const items: TabsProps["items"] = tabConfig.map(
    ({ key, label, allVenues, fetchVenues, showNoVenueImage }) => ({
      key,
      label,
      children: loading ? (
        <Loader />
      ) : (
        <AllVenues
          AllVenueList={venues}
          fetchVenues={fetchVenues}
          showNoVenueImage={showNoVenueImage}
          allVenues={allVenues}
        />
      ),
    })
  );

  return (
    <div className="my-venues">
      <div className=" d-flex justify-content-between align-items-center">
        <h3 className="venue-listing-title">My Venues</h3>

        <div className="d-flex flex-nowrap align-items-center">
          <Link to="/venues/create-venue" style={{ marginRight: "8px" }}>
            <button type="button" className="custom-button">
              <img
                src={images.PLUS_WHITE}
                alt=""
                width={"16px"}
                height={"16px"}
              />{" "}
              Add Venue
            </button>
          </Link>

          {/* Left for future implementation */}
          {/* <button
            className="border-0 bg-transparent"
            style={{ marginRight: "8px" }}
          >
            <img src={images.FILTER_ICON} alt="" width={"40px"} />
          </button>
          <button
            style={{
              marginRight: "8px",
              width: "40px",
            }}
            className="bordered-buttons"
          >
            <BellOutlined />
          </button> */}
        </div>
      </div>

      <div className="venue-listing-container flex-grow-1">
        <div className="d-flex flex-wrap gap-4 h-100 w-100">
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  itemSelectedColor: "var(--deep-sea) !important",
                  inkBarColor: "var(--deep-sea) !important",
                  itemHoverColor: "var(--deep-sea) !important",
                },
              },
            }}
          >
            <Tabs
              defaultActiveKey="1"
              items={items}
              style={{ width: "100%" }}
              onChange={(value) => {
                onTabChange(value);
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      {venues.length > 0 && (
        <div className="pagination-container">
          <Pagination
            current={currentPage}
            pageSize={10}
            total={totalVenues}
            onChange={onPageChange}
            itemRender={itemRender}
            hideOnSinglePage
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default VenueList;
