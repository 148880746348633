import React from "react";

// third party
import dayjs from "dayjs";

// custom component
import StatusTags from "../../UI/StatusTags"; // Adjust the import based on your project structure
import SmallText from "../../UI/SmallText";

type AboutRetreatProps = {
  title: string;
  icon: string;
  data?: string | number;
  isDateRange?: boolean;
  checkInDate?: string;
  checkOutDate?: string;
  isStatusTag?: boolean;
  statusLabel?: string;
  statusBackgroundColor?: string;
  statusColor?: string;
};

const AboutRetreatSection: React.FC<AboutRetreatProps> = ({
  title,
  icon,
  data,
  isDateRange = false,
  checkInDate,
  checkOutDate,
  isStatusTag = false,
  statusLabel,
  statusBackgroundColor,
  statusColor,
}) => {
  const formatDate = (date: string) => dayjs(date).format("MMM DD");

  return (
    <div className="d-flex w-100">
      <div className="about-retreat-title">
        <img src={icon} alt="" height="16px" />
        <SmallText text={title} color="#58585B" fontSize={14} />
      </div>
      <div className="about-retreat-data">
        {isDateRange && checkInDate && checkOutDate ? (
          <>
            {formatDate(checkInDate)} - {formatDate(checkOutDate)}
          </>
        ) : isStatusTag && statusLabel ? (
          <StatusTags
            top="0"
            left="0"
            buttonPosition="relative"
            label={statusLabel}
            backgroundColor={statusBackgroundColor}
            color={statusColor}
          />
        ) : (
          <SmallText text={`${data}`} color="#19191C" fontSize={14} />
        )}
      </div>
    </div>
  );
};

export default AboutRetreatSection;
